import React, { useContext } from "react"
import { TutorMatchingForm } from "../../forms/tutors_matching_filters"
import { DataContextActions } from "../../../../contexts"

export const StudentModalListFilters = () => {
  const { updateState } = useContext(DataContextActions)
  const submit = data => {
    updateState("modalStudentsListFilters", data)
  }
  return <TutorMatchingForm onSubmit={submit} />
}
