import { Link } from "@mui/material"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { Text } from "./styles"

const getActionItemLink = (assignmentType, assignmentId, actionItemLink, scheduleId, id) => {
  switch (assignmentType) {
    case "Lesson":
      return `/schedules/${scheduleId}/curriculum?assignment_id=${assignmentId}`
    case "ProgramAssessment":
      return actionItemLink
    default:
      return `/schedules/${scheduleId}?edit_modal=true&session_id=${id}`
  }
}

const ActionItem = ({ assignment, assignment_type, assignmentActionItems, scheduleId, id }) => {
  const { action_item_link, action_item } = assignmentActionItems || {}

  const actionItemLink = useMemo(
    () => getActionItemLink(assignment_type, assignment?.id, action_item_link, scheduleId, id),
    [assignment_type, assignment?.id, action_item_link, scheduleId, id]
  )

  return (
    <Text variant="body2" component="div">
      <strong>Action Item: </strong>
      <Link
        variant="body2"
        href={actionItemLink}
        target={assignment_type === "Lesson" || assignment_type === "ProgramAssessment" ? "_blank" : undefined}
        rel={
          assignment_type === "Lesson" || assignment_type === "ProgramAssessment" ? "noopener noreferrer" : undefined
        }
        aria-label="Action item link"
      >
        {action_item}
      </Link>
    </Text>
  )
}

ActionItem.propTypes = {
  assignment: PropTypes.shape({
    id: PropTypes.string,
  }),
  assignment_type: PropTypes.string.isRequired,
  assignmentActionItems: PropTypes.shape({
    action_item_link: PropTypes.string,
    action_item: PropTypes.string.isRequired,
    agenda: PropTypes.string.isRequired,
  }).isRequired,
  scheduleId: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}

export default React.memo(ActionItem)
