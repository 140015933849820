import React, { useState, useEffect } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Collapse from "react-bootstrap/Collapse"
import { difference, union } from "lodash"

const toggleId = (ids, id, setIds) => {
  if (ids.findIndex(el => el === id) > -1) {
    setIds(ids.filter(el => el !== id))
  } else {
    setIds([id].concat(ids))
  }
}

const toggleIds = (ids, newIds, setIds) => {
  let allNewSelected = difference(newIds, ids).length === 0

  if (allNewSelected) {
    setIds(difference(ids, newIds))
  } else {
    setIds(union(ids, newIds))
  }
}

const AddProgramsModal = ({ show, selectedIds, ignoredProgramIds, hierarchy, programsHash, onSelect, onHide }) => {
  const [ids, setIds] = useState(selectedIds)
  const [openGrades, setOpenGrades] = useState([])

  useEffect(() => {
    setIds(selectedIds)
  }, [selectedIds])

  const addToProfile = () => {
    onSelect(ids)
    onHide()
  }

  const toggleOpenGrades = division => {
    if (divisionIsOpen(division)) {
      setOpenGrades(openGrades.filter(el => el !== division))
    } else {
      setOpenGrades([division].concat(openGrades))
    }
  }

  const divisionIsOpen = division => openGrades.findIndex(el => el === division) > -1

  return (
    <Modal animation={false} size="xl" scrollable={true} show={show} onHide={onHide} dialogClassName="programs-modal">
      <Modal.Header className="align-items-center">
        <Modal.Title>Please pick programs you want to teach</Modal.Title>
        <Button variant="outline-primary" className="float-right" onClick={addToProfile}>
          Add to profile
        </Button>
      </Modal.Header>
      <Modal.Body>
        {hierarchy.map(division => (
          <div className="row form-group" key={division.division}>
            <h6
              className="col-lg-24 cursor-pointer"
              onClick={() => toggleOpenGrades(division.division)}
              aria-expanded={divisionIsOpen(division.division)}
            >
              {!divisionIsOpen(division.division) ? <span className="arrow-right-icon mr-3"></span> : null}
              {divisionIsOpen(division.division) ? <span className="arrow-down-icon mr-3"></span> : null}
              {division.division}
            </h6>
            <Collapse in={divisionIsOpen(division.division)}>
              <div className="col-lg-24">
                {division.domains.map(domain => (
                  <div className="row my-3" key={domain.domain}>
                    <div className="col-lg-6">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => toggleIds(ids, domain.programs, setIds)}
                      >
                        {domain.domain}
                      </Button>
                    </div>
                    <div className="col-lg-18">
                      {difference(domain.programs, ignoredProgramIds).map(id => (
                        <Button
                          key={id}
                          size="sm"
                          variant="warning-light"
                          className={`mb-2 mr-2 ${ids.findIndex(el => el === id) > -1 && "selected"}`}
                          onClick={() => toggleId(ids, id, setIds)}
                        >
                          {programsHash[id].name}
                        </Button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  )
}

export default AddProgramsModal
