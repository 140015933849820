import React from "react"
import { Col, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { isNumeric } from "../../../../helpers/validators"
import { formatStrToFloatWithRound } from "../../../../helpers/formatters"
import { formatDate } from "../../../../helpers/dates"

import FormRow from "../../../blocks/FormRow"
import { Timetable } from "../timetable"

export const Overview = ({ opening, setOpening }) => {
  const trimText = ({ target: { name } }) => {
    setOpening({ [name]: opening[name]?.trim() })
  }

  const updateText = ({ target: { name, value } }) => {
    setOpening({ [name]: value })
  }

  const updateDecimal = ({ target: { name, value } }) => {
    if (value === "") {
      setOpening({ [name]: "" })
    } else if (isNumeric(value)) {
      setOpening({ [name]: parseFloat(value) })
    }
  }

  const updateInt =
    (initial = "") =>
    ({ target: { name, value } }) => {
      if (value === "" || value < 0) {
        setOpening({ [name]: initial })
      } else if (isNumeric(value)) {
        setOpening({ [name]: parseInt(value, 10) })
      }
    }

  const blurDecimal = ({ target: { name, value } }) => {
    if (value !== "" && isNumeric(value)) {
      setOpening({ [name]: formatStrToFloatWithRound(value) })
    }
  }

  const setNotify = ({ target: { checked, name } }) => {
    setOpening({ [name]: checked })
  }

  const updateTime = name => date => {
    setOpening({ [name]: date === null ? date : formatDate(date, "MM/DD/YYYY") })
  }

  return (
    <Row className="mb-3 p-4">
      <Col xs={24} lg={16}>
        <FormRow LabelProps={{ lg: 7, htmlFor: "school_rate" }} ColProps={{ lg: 17 }} label="School rate" required>
          <Row>
            <Col lg={11}>
              <input
                type="number"
                value={opening.school_rate}
                name="school_rate"
                placeholder="0.00 $"
                pattern="^\d+(?:\.\d{1,2})?$"
                className={`form-control fullwidth ${opening.tutor_rate > opening?.school_rate ? "error" : ""}`}
                onChange={updateDecimal}
                onBlur={blurDecimal}
              />
            </Col>
            {opening.tutor_rate > opening?.school_rate && (
              <Col lg={13} className="d-flex align-items-center justify-content-center">
                <span className="text-danger text-small font-weight-semibold">
                  School rate should be greater than tutor rate
                </span>
              </Col>
            )}
          </Row>
        </FormRow>
        <FormRow LabelProps={{ lg: 7, htmlFor: "tutor_rate" }} ColProps={{ lg: 8 }} label="Tutor hourly rate" required>
          <input
            type="number"
            value={opening.tutor_rate}
            name="tutor_rate"
            placeholder="0.00 $"
            pattern="^\d+(?:\.\d{1,2})?$"
            className={`form-control fullwidth ${opening.tutor_rate > opening?.school_rate ? "error" : ""}`}
            onChange={updateDecimal}
            onBlur={blurDecimal}
          />
        </FormRow>
        <FormRow LabelProps={{ lg: 7, htmlFor: "max_sessions" }} ColProps={{ lg: 8 }} label="Max amount of sessions">
          <input
            type="number"
            value={opening.max_sessions}
            name="max_sessions"
            placeholder="Set max sessions count"
            pattern="\d+"
            className="form-control fullwidth"
            onChange={updateInt()}
          />
        </FormRow>
        <FormRow
          LabelProps={{ lg: 7, htmlFor: "max_paid_noshows" }}
          ColProps={{ lg: 8 }}
          label="Max paid no shows"
          required
        >
          <input
            type="number"
            value={opening.max_paid_noshows}
            name="max_paid_noshows"
            placeholder="Set max paid no shows"
            pattern="\d+"
            className="form-control fullwidth"
            onChange={updateInt()}
          />
        </FormRow>
        <FormRow LabelProps={{ lg: 7, htmlFor: "max_duration" }} ColProps={{ lg: 8 }} label="Max duration, minutes">
          <input
            type="number"
            value={opening.max_duration}
            name="max_duration"
            placeholder="Set max session duration"
            pattern="\d+"
            className="form-control fullwidth"
            onChange={updateInt()}
          />
        </FormRow>
        <FormRow LabelProps={{ lg: 7 }} ColProps={{ lg: undefined }} label="Assignment lifetime">
          <Row className="mb-2">
            <Col className="pr-2">
              <DatePicker
                placeholderText="Start *"
                wrapperClassName="fullwidth"
                selected={opening.ttg_start_at ? new Date(opening.ttg_start_at) : null}
                onChange={updateTime("ttg_start_at")}
                dateFormat="MM/dd/yyyy"
                customInput={<input className="form-control fullwidth" />}
              />
            </Col>
            <Col xs={1} />
            <Col>
              <DatePicker
                placeholderText="End"
                wrapperClassName="fullwidth"
                selected={opening.ttg_end_at ? new Date(opening.ttg_end_at) : null}
                onChange={updateTime("ttg_end_at")}
                dateFormat="MM/dd/yyyy"
                customInput={<input className="form-control fullwidth" />}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <span>Make sure that the assigned schedule does not conflict with the assignment lifetime</span>
            </Col>
          </Row>
        </FormRow>
        <Row className="mb-3">
          <Col className="h3">Additional information</Col>
        </Row>
        <Row className="mb-5">
          <textarea
            value={opening.additionalInformation}
            name="additionalInformation"
            className="form-control"
            rows="5"
            placeholder="Any information that helps tutor better understand your need"
            onChange={updateText}
            onBlur={trimText}
          />
        </Row>
        <FormRow
          ColProps={{ lg: undefined }}
          LabelProps={{ lg: 7, htmlFor: "additionalEmails" }}
          label="Send reports to"
        >
          <Row className="mx-0">
            <input
              type="text"
              value={opening.additionalEmails}
              name="additionalEmails"
              placeholder="Enter emails separated with a comma"
              className="form-control fullwidth"
              onChange={updateText}
              onBlur={trimText}
            />
          </Row>
          <Row className="custom-control custom-checkbox mt-3 mx-0">
            <Col>
              <input
                type="checkbox"
                className="custom-control-input"
                id="notifyParents"
                checked={!!opening.sessionsNotify}
                value={opening.sessionsNotify}
                name="sessionsNotify"
                onChange={setNotify}
              />
              <label className="custom-control-label cursor-pointer" htmlFor="notifyParents">
                Notify parents about upcoming sessions
              </label>
            </Col>
          </Row>
        </FormRow>
        <Timetable opening={opening} />
      </Col>
    </Row>
  )
}
