import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const TooltipOverlay = React.forwardRef(({ children, ...props }, ref) => (
  <Tooltip ref={ref} {...props}>
    <div className="text-left">{children}</div>
  </Tooltip>
))

TooltipOverlay.displayName = "TooltipOverlay"

const HintTooltip = ({ placement = "bottom", children }) => (
  <OverlayTrigger placement={placement} delay={{ hide: 200 }} overlay={<TooltipOverlay>{children}</TooltipOverlay>}>
    <div className="hint-tooltip_question-mark">?</div>
  </OverlayTrigger>
)

export default HintTooltip
