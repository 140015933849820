import LoadingSpinner from "@/common/loading"
import { DataContext } from "@/contexts"
import BRTThemeProvider from "@/providers/theme"
import { Typography } from "@mui/material"
import React, { useContext } from "react"
import { useSearchParams } from "react-router-dom"
import CollapsibleTable from "./collapsible_table"
import useFetchScheduleProgram from "./hooks/useFetchScheduleProgram"

const CurriculumPage = () => {
  const { schedule } = useContext(DataContext)
  const { id: scheduleId } = schedule
  const { program, loading } = useFetchScheduleProgram({ scheduleId })
  const [params] = useSearchParams()
  const assignmentId = params.get("assignment_id")

  if (loading) return <LoadingSpinner />

  return (
    <BRTThemeProvider>
      <div className="card session-information">
        <h6 className="font-weight-semibold mb-0 flex-grow-1">{program?.name || ""}</h6>
        <Typography variant="body2" color="text.primary">
          {program?.description || ""}
        </Typography>

        <hr className="divider fullwidth soft-color" />
        <div className="w-100">
          <CollapsibleTable
            programLevels={program?.program_levels || []}
            preAssessments={program?.pre_assessments || []}
            assignmentId={assignmentId}
          />
        </div>
      </div>
    </BRTThemeProvider>
  )
}

export default CurriculumPage
