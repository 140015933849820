import React from "react"
import { Alert } from "react-bootstrap"
import { formatMoney } from "../../helpers/formatters"

const HourRateInfo = ({ hourRate = "-", editMode }) => {
  return (
    <Alert variant="info" className="rate-info">
      <div className="rate-info_title">{formatMoney(hourRate)} per hour rate</div>
      {editMode && (
        <>
          <div className="rate-info_text">
            You will be charged upon submission of the session report for each meeting.
            <br /> You can cancel at any time.
          </div>
          {/*<a href="#" target="_blank" className="rate-info_link">*/}
          {/*  Learn more about billing*/}
          {/*</a>*/}
        </>
      )}
    </Alert>
  )
}

export default HourRateInfo
