import SessionCalendar from "@/components/calendar/SessionCalendar"
import BRTThemeProvider from "@/providers/theme"
import { Box, Paper, useMediaQuery, useTheme } from "@mui/material"
import { styled } from "@mui/system"
import React from "react"

const CalendarWrapper = styled(Paper)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}))

const CalendarContent = styled(Box)({
  flexGrow: 1,
  overflow: "auto",
})

const AdminCalendar = ({ calendarData }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <BRTThemeProvider>
      <Box sx={{ height: "100%", p: { xs: 1, sm: 2, md: 3 } }}>
        <CalendarWrapper elevation={3}>
          <CalendarContent>
            <SessionCalendar calendarData={calendarData} isMobile={isMobile} />
          </CalendarContent>
        </CalendarWrapper>
      </Box>
    </BRTThemeProvider>
  )
}

export default AdminCalendar
