import React, { useState } from "react"
import { Form } from "react-final-form"
import FormFields from "./FormFields"
import { putAndRedirect } from "../../../common/apiClient"
import ProfileValidationProvider from "../providers/ProfileValidation"

const PersonalInfo = ({ dataSources, admin }) => {
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false)

  const onSubmit = profile => {
    putAndRedirect(dataSources.urls.save, dataSources.urls.next, { profile })
  }

  const validate = values => {
    const errors = {}
    const userHadAvatar = !!dataSources.user.profile.photo_url
    const noBlobId = !values.photo_blob_id
    const imageWasReset = "photo_blob_id" in values && noBlobId

    if ((userHadAvatar && imageWasReset) || (!userHadAvatar && noBlobId)) {
      errors.photo_blob_id = "Required"
    }
    return errors
  }

  return (
    <Form onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, submitting, form, errors }) => (
        <form onSubmit={handleSubmit} className="container">
          <div className="row mb-2">
            <h4 className="col-sm-24">Personal Information</h4>
            <p className="col-sm-24">Please fill in your personal details</p>
          </div>
          <ProfileValidationProvider errors={errors}>
            <FormFields
              dataSources={dataSources}
              isLoadingPhoto={isLoadingPhoto}
              setIsLoadingPhoto={setIsLoadingPhoto}
              admin={admin}
              form={form}
            />
          </ProfileValidationProvider>

          <button
            type="submit"
            className="btn btn-primary mt-4"
            disabled={Object.keys(errors).length > 0 || isLoadingPhoto || submitting}
          >
            Next
          </button>
        </form>
      )}
    </Form>
  )
}

export default PersonalInfo
