import { useCallback, useEffect, useState } from "react"
import { showErrors } from "../../../common/alerter"
import apiClient from "../../../common/apiClient"

export const useGetScheduleDetails = ({ scheduleId }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState("")

  const fetchScheduleDetails = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await apiClient.get(`/api/schedules/${scheduleId}`)
      setData(data || "")
    } catch (e) {
      showErrors(e)
    } finally {
      setLoading(false)
    }
  }, [scheduleId])

  useEffect(() => {
    if (scheduleId) {
      fetchScheduleDetails()
    }
  }, [fetchScheduleDetails, scheduleId])

  const students = data?.educatable?.students || []
  const studentNames = students?.map(student => `${student.first_name} ${student.last_name}`)
  const domain = data?.program?.name || ""

  return { loading, data, students, studentNames, domain }
}
