import React, { useContext, useState } from "react"
import { DataContext } from "../../../contexts"
import { EducatableTypes } from "../constants"
import { StatelessTable } from "../../table"
import { columns } from "./columns"
import { ChangeEducatableDialog } from "./change_educatable_dialog"
import LessonRecommendation from "./lesson_recommendation"
import ButtonSkeletonLoader from "../../../common/skeleton_loaders/ButtonSkeletonLoader"
import useFetchScheduleProgram from "../../opening_module/pages/curriculum/hooks/useFetchScheduleProgram"

export const Students = () => {
  const { schedule } = useContext(DataContext)
  const [changeDialogOpen, setChangeDialogOpen] = useState(false)
  const isGroup = schedule.educatable.type === EducatableTypes.Group
  const data = isGroup ? schedule.educatable.students : [schedule.educatable]

  return (
    <div className="card p-4 align-items-start">
      <h6>
        <span className="text-gray-chat">{isGroup ? "Group" : "Student"}</span>{" "}
        {schedule.educatable.url ? (
          <a href={schedule.educatable.url}>{schedule.educatable.name}</a>
        ) : (
          schedule.educatable.name
        )}
      </h6>

      <StatelessTable
        columns={columns}
        data={data}
        count={data.length}
        hideFooter
        headerCellClasses="text-gray-chat font-weight-medium font-size-small"
        noResultText={
          <div className="text-left">
            <div className="mt-5">No students in the group yet</div>
            {schedule.educatable.url ? (
              <a className="font-weight-semibold" href={schedule.educatable.url}>
                Add students
              </a>
            ) : <ButtonSkeletonLoader height={50} width={50} variant="rectangular"/>}
          </div>
        }
      />

      {schedule ? <button className="btn btn-primary" onClick={() => setChangeDialogOpen(true)}>
        Change {isGroup ? "group" : "student"}
      </button> : <ButtonSkeletonLoader height={30} width={102} variant="rounded"/>}

      <ChangeEducatableDialog show={changeDialogOpen} onHide={() => setChangeDialogOpen(false)} />
    </div>
  )
}

export const StudentsView = () => {
  const { schedule, currentUser } = useContext(DataContext)
  const { feature_flags } = currentUser
  const { isCurriculumProgram } = useFetchScheduleProgram({ scheduleId: schedule?.id })
  const showLessonRecommendation = feature_flags["curriculum_enabled"] && isCurriculumProgram

  return (
    <div className="d-flex flex-column">
      <Students />
      {showLessonRecommendation && <LessonRecommendation />}
    </div>
  )
}
