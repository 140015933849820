import React, { useCallback, useContext } from "react"
import { EditScheduleForm } from "../../../schedule_module/forms/create_schedule"
import { UpdateScheduleDataProvider } from "../../providers/students_page"
import { QueryContext } from "../../../../contexts"
import InitialLoadingSpinner from "../../../blocks/InitialLoadingSpinner"
import { updateSchedule } from "../../../schedule_module/configurations/domain"
import { useBalkError } from "../../../../hooks/useBalkError"

const UpdateScheduleContent = ({ schedules, onCancel, onUpdate }) => {
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(updateSchedule)
  const handler = useBalkError({
    onUpdate,
    successMessage: "Schedules successfully updated",
    buildErrorMessage: error => {
      const record = schedules.find(schedule => error.record.id === schedule.educatable.id)
      if (!record) return
      return `Schedule of ${record.educatable.name} wasn't updated`
    },
  })
  const submit = useCallback(
    values => {
      request({
        data: {
          ids: schedules.map(({ id }) => id),
          ...values,
        },
        onSuccess: handler,
      })
    },
    [request, schedules, handler]
  )
  return <EditScheduleForm schedules={schedules} loading={loading} onSubmit={submit} onCancel={onCancel} />
}

export const UpdateSchedule = ({ schedules, onCancel, onUpdate }) => {
  return (
    <UpdateScheduleDataProvider schedules={schedules}>
      <InitialLoadingSpinner>
        <UpdateScheduleContent onCancel={onCancel} onUpdate={onUpdate} schedules={schedules} />
      </InitialLoadingSpinner>
    </UpdateScheduleDataProvider>
  )
}
