import React from "react"
import styled from "styled-components"

const StyledCloseButton = styled.span`
  & > a {
    color: black;
    font-weight: bold;
  }

  & > a:hover {
    text-decoration: none;
  }
`

const SmallCloseButton = ({ onClose, className }) => {
  const handleClick = e => {
    e.preventDefault()
    onClose()
  }

  return (
    <StyledCloseButton className={className}>
      <a href="#" onClick={handleClick}>
        ✕
      </a>
    </StyledCloseButton>
  )
}

export const RedSmallCloseButton = styled(SmallCloseButton)`
  & a {
    color: red;
  }
`

export default SmallCloseButton
