import { legacy_createStore as createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import reportReducer from "../../../../session_report/store/reportReducer"

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })

const readOnlyMode = false

const buildStore = ({
  loading = false,
  copyToAllStudents = false,
  showCopyToRestStudents = false,
  programProgress: list = [],
  selectedSubskills = [],
  programId,
  progressId,
  onCancel,
  onEdit,
}) =>
  createStore(
    reportReducer,
    {
      loading,
      copyToAllStudents,
      showCopyToRestStudents,
      list,
      selectedSubskills: selectedSubskills || {},
      progressId: progressId,
      programId: programId,
      readOnlyMode: readOnlyMode,
      onCancel,
      onEdit,
    },
    composeEnhancers()
  )

export default buildStore
