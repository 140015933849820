import React, { useMemo } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { getNavigationConfig } from "./configurations/routing"
import { routerOptions } from "./constants"

const ScheduleModule = ({ currentUser, directUploadUrl, serializedCurrentUser }) => {
  const userData = useMemo(() => ({ ...currentUser, ...serializedCurrentUser }), [currentUser, serializedCurrentUser])
  const { role } = userData

  const config = useMemo(
    () => getNavigationConfig({ role, directUploadUrl, currentUser: userData }),
    [directUploadUrl, role, userData]
  )

  const router = useMemo(() => createBrowserRouter(config, routerOptions), [config])
  return <RouterProvider router={router} />
}

export default ScheduleModule
