import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Col, Row } from "react-bootstrap"
import {
  ConfirmationModalContext,
  ContextMenuContext,
  DataContext,
  QueryContext,
  TableSelectionContext,
} from "../../../../contexts"
import { StatefulTable } from "../../../table"
import { removeScheduleToOpening, getOpening, getOpeningStudents } from "../../configurations/domain"
import { Entities } from "../../../../constants"
import { GroupName, Tutor, ScheduleDateAndNote, StudentsNames, StudentsGrades, StudentsSpecialNeeds } from "./columns"
import Dialog from "../../../blocks/Dialog"
import { TutorsModalContent } from "./tutors_modal_content"
import useViewport from "../../../../hooks/useViewport"
import { className } from "../../../../helpers/className"
// import { ListFilters } from "./list_filters"
import { ListActions } from "./list_actions"
import { StudentListActions } from "./students_list_actions"
import { ContextMenuNodeTypes } from "../../../blocks/ContextMenu"
import { useDialogState } from "../../../../hooks/useDialogState"
import { UpdateSchedule } from "./update_schedule"
import { ReactComponent as Calendar } from "../../../../assets/images/calendar1.svg"
import { ReactComponent as Note } from "../../../../assets/images/report-submitted.svg"
import { AvailableStudentsList } from "./available_students_list"
import { ScheduleAssistant } from "../../../schedule_module/schedule/schedule_assistant"
import { EditConnectionForm } from "../../../schedule_module/forms/edit_connection"
import Alerter from "../../../../common/alerter"
import { CreateEmptyGroups } from "../../forms/create_empty_groups"
import apiClient from "../../../../common/apiClient"

const pageColumns = [GroupName, Tutor, ScheduleDateAndNote, StudentsNames, StudentsGrades, StudentsSpecialNeeds]

export const StatelessStudentList = ({ data, columns, loading, handlersMap }) => {
  if (data.length === 0)
    return (
      <div className="d-flex align-items-center justify-content-center flex-grow-1 min-h-400-px">
        <h1 className="text-gray text-center mb-0 font-weight-normal">No students in the opening</h1>
      </div>
    )

  return (
    <StatefulTable
      data={data}
      columns={columns}
      count={data.length}
      loading={loading}
      handlersMap={handlersMap}
      className="opening-students__list"
      headerCellClasses="text-black-50 font-weight-medium font-size-small"
      hideFooter
    />
  )
}

const DialogTypes = Object.freeze({
  MatchWithTutor: "matchWithTutor",
  UpdateSchedule: "updateSchedule",
  AddStudents: "addStudents",
  EditSessions: "editSessions",
  EditConnections: "editConnections",
  ScheduleSettings: "scheduleSettings",
  CreateEmptyGroups: "createEmptyGroups",
})

export const StudentsList = () => {
  const { isPhoneViewport } = useViewport()
  const { dialogState, closeDialog, openDialog } = useDialogState()
  const { openingId, opening, groups, studentsListFilters } = useContext(DataContext)
  const { openContextMenu } = useContext(ContextMenuContext)
  const { openConfirmationModal } = useContext(ConfirmationModalContext)
  const { getQueryState } = useContext(QueryContext)
  const { request: requestStudents, loading: studentsLoading } = getQueryState(getOpeningStudents)
  const { request: reloadOpening } = getQueryState(getOpening)
  const { request: removeScheduleRequest } = getQueryState(removeScheduleToOpening)
  const { selected = [], resetSelection, updateSelected } = useContext(TableSelectionContext)
  const [sessionsLoading, setSessionsLoading] = useState(false)

  const handlersMap = useMemo(() => {
    const map = new Map()
    map.set(Tutor, {
      onPickTutor: (_, data) => openDialog([data], DialogTypes.MatchWithTutor),
    })
    map.set(ScheduleDateAndNote, {
      // todo - add context block about schedule
      // eslint-disable-next-line no-console
      onRangeClick: (event, data) => {
        openContextMenu(event, [
          {
            nodeType: ContextMenuNodeTypes.NativeLink,
            title: "Go to schedule",
            href: `/schedules/${data.id}`,
            icon: <Note />,
            iconClassName: "text-primary",
            target: "_blank",
            "data-test": `go-to-schedule-${data.id}`,
          },
          {
            nodeType: ContextMenuNodeTypes.Button,
            title: "Edit schedule settings",
            icon: <Calendar />,
            iconClassName: "text-primary",
            onClick: () => {
              openDialog([data], DialogTypes.UpdateSchedule)
            },
          },
        ])
      },
    })
    return map
  }, [openContextMenu, openDialog])

  const onPickTutors = useCallback(() => {
    openDialog(selected, DialogTypes.MatchWithTutor)
  }, [openDialog, selected])

  const onRemoveFromOpening = useCallback(() => {
    openConfirmationModal({
      title: `Remove groups`,
      content: `Remove ${selected.length} group${selected.length > 1 ? "s" : ""} from this opening?`,
      onConfirm: () =>
        removeScheduleRequest({
          entitiesIds: {
            [Entities.Openings]: openingId,
          },
          data: {
            educatables: selected.map(({ educatable }) => educatable.id),
          },
          onSuccess: resetSelection,
        }),
      buttonClassName: "btn btn-danger",
    })
  }, [openConfirmationModal, selected, removeScheduleRequest, openingId, resetSelection])

  const openSchedulesDialog = useCallback(() => {
    openDialog(selected, DialogTypes.UpdateSchedule)
  }, [openDialog, selected])

  const fetchSessions = useCallback(async schedules => {
    setSessionsLoading(true)
    try {
      const sessionsPromises = schedules.map(schedule => apiClient.get(`/api/schedules/${schedule.id}/sessions`))

      const responses = await Promise.all(sessionsPromises)
      const allSessions = responses.flatMap(response => response.data)

      return allSessions
    } catch (e) {
      Alerter.error(e)
    } finally {
      setSessionsLoading(false)
    }
  }, [])

  const onEditSessions = useCallback(async () => {
    const list = selected.filter(schedule => Boolean(schedule.max_sessions && schedule.max_duration))

    if (list.length > 0) {
      // Open dialog immediately with empty sessions (to see loading)
      openDialog({ schedules: list, sessions: [] }, DialogTypes.EditSessions)

      const fetchedSessions = await fetchSessions(list)
      // Update dialog data with fetched sessions
      openDialog({ schedules: list, sessions: fetchedSessions }, DialogTypes.EditSessions)
    } else {
      Alerter.error("Max sessions or max duration aren't set in all schedules")
    }
  }, [openDialog, selected, fetchSessions])

  const updateStudentsList = useCallback(() => {
    requestStudents({
      entitiesIds: {
        [Entities.Openings]: openingId,
      },
      params: studentsListFilters,
      onSuccess: groups => {
        const list = []
        for (const item of selected) {
          const updatedItem = groups.find(({ id }) => id === item.id)
          list.push(updatedItem || item)
        }
        updateSelected(list)
      },
    })
  }, [openingId, requestStudents, selected, studentsListFilters, updateSelected])

  const refreshOpening = useCallback(() => {
    reloadOpening({
      entitiesIds: {
        [Entities.Openings]: openingId,
      },
    })
  }, [openingId, reloadOpening])

  const onEditConnections = useCallback(() => {
    const list = []
    for (const { connections } of selected) {
      list.push(...connections)
    }
    if (list.length > 0) openDialog(list, DialogTypes.EditConnections)
    else Alerter.error("To edit connections, select groups that have a connection")
  }, [openDialog, selected])

  const updateListAndCloseModal = useCallback(() => {
    closeDialog()
    updateStudentsList()
    refreshOpening()
  }, [closeDialog, updateStudentsList, refreshOpening])

  useEffect(() => {
    if (studentsListFilters) updateStudentsList()
  }, [studentsListFilters, requestStudents, openingId, updateStudentsList])

  return (
    <Row>
      <Col xs="24" className="d-flex flex-column gap-1 position-static">
        {selected.length > 0 ? (
          <StudentListActions
            selected={selected}
            onCancelSelection={resetSelection}
            onPickTutor={onPickTutors}
            onEditSessions={onEditSessions}
            onRemove={onRemoveFromOpening}
            onEditConnections={onEditConnections}
            onEditSchedules={openSchedulesDialog}
          />
        ) : (
          <Row className="align-items-center">
            <Col xs="auto">{/* <ListFilters /> */}</Col>
            <Col xs="auto" className="flex-grow-1" />
            <Col xs="auto">
              <ListActions
                onAddGroupClick={() => openDialog(null, DialogTypes.AddStudents)}
                onCreateGroups={() => openDialog(null, DialogTypes.CreateEmptyGroups)}
              />
            </Col>
          </Row>
        )}
        <div className={isPhoneViewport ? "px-3" : ""}>
          <div className={className("mx-n3 px-3", !isPhoneViewport && "overflow-x-auto")}>
            <StatelessStudentList
              data={groups}
              loading={studentsLoading}
              handlersMap={handlersMap}
              columns={pageColumns}
            />
            <Dialog
              size="xl"
              title="Match group with a tutor"
              open={dialogState.open === DialogTypes.MatchWithTutor}
              onClose={closeDialog}
              centered={false}
            >
              <TutorsModalContent groups={dialogState.data} onMatch={updateListAndCloseModal} />
            </Dialog>
            <Dialog
              size="lg"
              title={
                dialogState.data?.length > 1
                  ? `Schedule settings for ${dialogState.data?.length} groups`
                  : "Update schedule"
              }
              open={dialogState.open === DialogTypes.UpdateSchedule}
              onClose={closeDialog}
              centered={false}
            >
              <UpdateSchedule schedules={dialogState.data} onCancel={closeDialog} onUpdate={updateListAndCloseModal} />
            </Dialog>
            <Dialog
              size="xl"
              title={`Pick groups from ${opening.school.name} School`}
              open={dialogState.open === DialogTypes.AddStudents}
              onClose={closeDialog}
              centered={false}
            >
              <AvailableStudentsList onStudentsAdd={updateListAndCloseModal} />
            </Dialog>
            <Dialog
              className="opening-edit-sessions-modal"
              title={`Edit sessions for ${dialogState.data?.schedules?.length} group${
                dialogState.data?.schedules?.length > 1 ? "s" : ""
              }`}
              open={dialogState.open === DialogTypes.EditSessions}
              onClose={closeDialog}
            >
              <div className="m-n4">
                {sessionsLoading ? (
                  <div className="d-flex align-items-center justify-content-center min-h-600-px">
                    <div className="spinner-border text-primary" />
                  </div>
                ) : (
                  <ScheduleAssistant schedules={dialogState.data?.schedules} sessions={dialogState.data?.sessions} />
                )}
              </div>
            </Dialog>
            <Dialog
              size="lg"
              title={`Edit connection settings for ${dialogState.data?.length} group${
                dialogState.data?.length > 1 ? "s" : ""
              }`}
              open={dialogState.open === DialogTypes.EditConnections}
              onClose={closeDialog}
            >
              <EditConnectionForm
                connections={dialogState.data}
                onSave={updateListAndCloseModal}
                onCancel={closeDialog}
                buttonsClassName="btrust-dialog__actions align-items-center"
              />
            </Dialog>
            <Dialog
              size="sm"
              title="Add empty groups to the Opening"
              className="opening_create_empty_group_dialog"
              open={dialogState.open === DialogTypes.CreateEmptyGroups}
              onClose={closeDialog}
            >
              <CreateEmptyGroups openingId={openingId} onCreate={updateListAndCloseModal} />
            </Dialog>
          </div>
        </div>
      </Col>
    </Row>
  )
}
