import React, { useRef } from "react"
import { Provider } from "react-redux"
import useViewport from "../../../../hooks/useViewport"
import SkillsList from "../../../../session_report/SkillsList"
import Confirmation from "./Confirmation"
import Loading from "./Loading"
import buildStore from "./store"

const Report = params => {
  const store = useRef(buildStore(params))
  const { isMobileViewport } = useViewport()
  return (
    <Provider store={store.current}>
      <Loading>
        {params.title && <h4 className="mb-4">{params.title}</h4>}
        <div className="row report-row">
          <div className="report-row_col col-xl-16">
            <div className={`panel ${isMobileViewport ? "mb-4" : "mb-0"}`}>
              <div className="panel_body -no-vertical-indent overflow-hidden">
                <SkillsList />
              </div>
            </div>
          </div>
          <div className="report-row_col -confirmation col-xl-8">
            <Confirmation />
          </div>
        </div>
      </Loading>
    </Provider>
  )
}

export default Report
