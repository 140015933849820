import Alerter from "@/common/alerter"
import { validateEmail } from "@/helpers/validators"
import useFetchSchools from "@/hooks/useFetchSchools"
import useViewport from "@/hooks/useViewport"
import SearchIcon from "@mui/icons-material/Search"
import { Alert, InputAdornment, ListSubheader, MenuItem, Select, TextField } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"

const CreateSchoolAdminPageWrapper = ({ children, title, titleAffix }) => {
  const { isMobileViewport } = useViewport()

  const cardPadding = isMobileViewport ? "px-3 py-4" : "px-4 py-5"

  return (
    <div className="container p-0 d-flex flex-column flex-grow-1">
      <Row>
        <Col className="p-0">
          <div className="container users-navbar_container -white justify-content-between">
            <h2 className="users-navbar_title m-0">{title}</h2>
            {titleAffix}
          </div>
          <div className="container mt-4 px-0">
            <Row className="mx-n2 px-2 mb-4">
              <Col as="main" xs={24}>
                <Card className={cardPadding}>{children}</Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const SchoolSelectWithSearch = ({ selectedSchool, onSchoolChange, schoolOptions }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const inputRef = useRef(null)

  const filteredSchoolOptions = useMemo(() => {
    if (schoolOptions) {
      if (searchTerm === "") return schoolOptions
      return schoolOptions.filter(option => option.name.toLowerCase().includes(searchTerm.toLowerCase()))
    }
    return []
  }, [schoolOptions, searchTerm])

  const handleOpen = useCallback(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 100)
  }, [])

  useEffect(() => {
    handleOpen()
  }, [searchTerm, handleOpen])

  return (
    <Select
      id="selectedSchool"
      value={selectedSchool}
      className="mb-0 mt-n1 form-input-align form-control flex-column form-group col-lg-16 col-24 form-control"
      label=""
      style={{
        paddingRight: 0,
        paddingLeft: "2em",
        lineHeight: "0.4375em",
        borderRadius: "40px",
      }}
      onClose={() => setSearchTerm("")}
      onOpen={handleOpen}
      MenuProps={{
        PaperProps: {
          onScroll: () => {
            if (inputRef.current) {
              inputRef.current.focus()
            }
          },
        },
      }}
      onChange={onSchoolChange}
    >
      <ListSubheader>
        <TextField
          size="small"
          autoFocus
          placeholder="Type to search for a school..."
          fullWidth
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyDown={e => {
            if (e.key !== "Escape") {
              e.stopPropagation()
            }
          }}
        />
      </ListSubheader>
      {filteredSchoolOptions.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  )
}

const CreateOrUpdateForm = ({ title, onSubmit, initialValues }) => {
  const { schools, loading, error } = useFetchSchools()

  const schoolAdminInit = initialValues?.schoolAdmin

  const [firstName, setFirstName] = useState(schoolAdminInit?.first_name || "")
  const [lastName, setLastName] = useState(schoolAdminInit?.last_name || "")
  const [school, setSchool] = useState(schoolAdminInit?.school_id || "")
  const [email, setEmail] = useState(schoolAdminInit?.email || "")
  const [receiveSessions, setReceiveSessions] = useState(false)

  const [emailValid, setValidateEmail] = useState(undefined)

  const handleOnChange = useCallback(e => {
    e.preventDefault()
    const emailValue = e.target.value
    setEmail(emailValue)
    const isValid = validateEmail(emailValue)
    setValidateEmail(isValid)
  }, [])

  const handleOnSubmit = useCallback(
    async event => {
      event.preventDefault()
      try {
        await onSubmit({
          event,
          email,
          firstName,
          lastName,
          school,
          receiveSessions,
        })
      } catch (error) {
        Alerter.error(error.response.data.errors)
      }
    },
    [onSubmit, email, firstName, lastName, receiveSessions, school]
  )

  const handleOnChangeSelect = e => {
    e.preventDefault()

    const schoolValue = e.target.value
    setSchool(schoolValue)
  }

  const disableSubmit = (email, firstName, lastName, school, emailValid, schoolList) => {
    return !email || !firstName || !lastName || (schoolList && !school) || !emailValid
  }

  return (
    <CreateSchoolAdminPageWrapper title={title}>
      <Row>
        <Col xs={24} lg={20} xl={14}>
          <div className="mb-4 form-group">
            <div className="px-2 form-row">
              <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">Email</span>
              <Form.Control
                type="email"
                style={{
                  width: "100%",
                  padding: "12px 20px",
                  margin: "8px 0",
                  boxSizing: "border-box",
                }}
                className="mb-0 mt-n1 form-input-align flex-column form-group col-lg-16 col-24 control-label"
                value={email}
                isValid={emailValid}
                required
                onChange={e => handleOnChange(e)}
              />
              <Form.Control.Feedback type="invalid">Please choose a first name.</Form.Control.Feedback>
            </div>
          </div>

          <div className="mb-4 form-group">
            <div className="px-2 form-row">
              <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">First Name</span>
              <Form.Control
                type="text"
                required
                style={{
                  width: "100%",
                  padding: "12px 20px",
                  margin: "8px 0",
                  boxSizing: "border-box",
                }}
                className="mb-0 mt-n1 form-input-align flex-column form-group col-lg-16 col-24"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="mb-4 form-group">
            <div className="px-2 form-row">
              <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">Last Name</span>
              <Form.Control
                type="text"
                required
                style={{
                  width: "100%",
                  padding: "12px 20px",
                  margin: "8px 0",
                  boxSizing: "border-box",
                }}
                className="mb-0 mt-n1 form-input-align flex-column form-group col-lg-16 col-24"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value)
                }}
              />
            </div>
          </div>
          {!loading && error && <Alert variant="danger">{error}</Alert>}
          {loading && <Alert variant="info">Loading...</Alert>}
          {!loading && !error && schools?.length > 0 && (
            <div className="mb-4 form-group">
              <div className="px-2 form-row">
                <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">School</span>
                <SchoolSelectWithSearch
                  selectedSchool={school}
                  schoolOptions={schools}
                  onSchoolChange={handleOnChangeSelect}
                />
              </div>
            </div>
          )}

          <div className="px-2 form-row">
            <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">
              Receive Student Session Reports
            </span>

            <Form.Check
              name="receiveSessions"
              type="radio"
              className="mr-3"
              id="yes"
              onChange={() => {
                setReceiveSessions(true)
              }}
              checked={receiveSessions === true}
              value={true}
              label="Yes"
              custom
            />
            <Form.Check
              name="receiveSessions"
              type="radio"
              className="mr-3"
              id="no"
              onChange={() => {
                setReceiveSessions(false)
              }}
              checked={receiveSessions === false}
              value={false}
              label="No"
              custom
            />
          </div>
          <div className="mt-4 form-group">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={handleOnSubmit}
              disabled={disableSubmit(email, firstName, lastName, school, !emailValid, schools)}
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </CreateSchoolAdminPageWrapper>
  )
}

export default CreateOrUpdateForm
