import React from "react"
import cn from "classnames"

const LabeledProgress = ({ label, value, maxValue = 5, height = "5px", variant = "green", className }) => (
  <div className={className}>
    <div className="d-flex justify-content-between">
      <div className={cn(!value && "text-muted")}>{label}</div>
      <div className={cn(!value && "text-muted")}>{value || "-.-"}</div>
    </div>
    <div className="progress" style={{ height }}>
      <div
        className={`progress-bar progress-bar progress-bar-striped bg-${variant}`}
        style={{ width: `${(value * 100) / maxValue}%` }}
      />
    </div>
  </div>
)

export default LabeledProgress
