import React from "react"
import { Field, useFormState } from "react-final-form"
import { mustBeGreaterOrEqual } from "../../../../helpers/validators"
import { positiveFloatNumber } from "../../../../helpers/formatters"
import { Col, Form, FormControl } from "react-bootstrap"
import { showFieldError } from "../../../../helpers/forms"
import { labelSize } from "../FormFields"

const TutorPayKind = ({ isFormDisabled }) => {
  const half1ColSize = { sm: 17, md: 14, lg: 10, xl: 8 }

  const { values } = useFormState()

  return (
    <Form.Group>
      <Form.Row>
        <Form.Label column {...labelSize}>
          Tutor pay kind<sup>*</sup>
        </Form.Label>

        <Form.Group as={Col} {...half1ColSize}>
          <Field name="tutor_pay_kind" type="radio" value="percent" initialValue="percent">
            {({ input, meta }) => (
              <div className="form-check">
                <FormControl
                  {...input}
                  id="tutor_pay_kind-percent"
                  className="form-check-input radio_buttons"
                  isInvalid={showFieldError(meta)}
                  disabled={isFormDisabled}
                />
                <label className="collection_radio_buttons" htmlFor="tutor_pay_kind-percent">
                  Percent
                </label>
              </div>
            )}
          </Field>
          <Field name="tutor_pay_kind" type="radio" value="absolute">
            {({ input, meta }) => {
              return (
                <div className="form-check">
                  <FormControl
                    {...input}
                    id="tutor_pay_kind-absolute"
                    className="form-check-input radio_buttons"
                    isInvalid={showFieldError(meta)}
                    disabled={isFormDisabled}
                  />
                  <label className="collection_radio_buttons" htmlFor="tutor_pay_kind-absolute">
                    Absolute
                  </label>
                </div>
              )
            }}
          </Field>
        </Form.Group>
      </Form.Row>
      {values.tutor_pay_kind === "absolute" && (
        <Form.Row>
          <Form.Label column {...labelSize}>
            Tutor pay<sup>*</sup>
          </Form.Label>

          <Field name="tutor_pay" validate={mustBeGreaterOrEqual(0)} parse={positiveFloatNumber}>
            {({ input, meta }) => {
              return (
                <Form.Group as={Col} {...half1ColSize}>
                  <FormControl {...input} isInvalid={showFieldError(meta)} disabled={isFormDisabled} />
                  <FormControl.Feedback type="invalid">{meta.error || meta.submitError}</FormControl.Feedback>
                </Form.Group>
              )
            }}
          </Field>
        </Form.Row>
      )}
    </Form.Group>
  )
}

export default TutorPayKind
