import React from "react"
import { PatternFormat } from "react-number-format"
import { OuterLink } from "./outerLink"

export const PhoneLink = ({ value, ...rest }) => {
  return (
    <OuterLink {...rest} to={`tel:${value}`} target="_blank">
      <PatternFormat
        value={value?.substring(1) || rest.empty || "-"}
        format="(###)###-####"
        allowEmptyFormatting
        mask=" "
        type="tel"
        displayType="text"
        valueIsNumericString
        placeholder="Enter phone number"
      />
    </OuterLink>
  )
}
