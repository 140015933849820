import React from "react"
import { DomainProvider } from "../../../providers"
import useBaseHandlersBuilder from "../../../hooks/useBaseHandlersBuilder"
import { useApiInitialPath } from "../../../hooks/useApiPath"
import ReportValidationProvider from "./report_validation"
import { report, schedule, primary, newSchedule } from "../configurations/domain"
import ConfirmationModalProvider from "../../blocks/ConfirmationModal"

export const CommonProviders = ({ initial, children }) => {
  return (
    <DomainProvider
      initialStorageState={initial}
      config={primary}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      {children}
    </DomainProvider>
  )
}

export const ScheduleProviders = ({ initial, children }) => {
  return (
    <DomainProvider
      connectToParentStore
      initialStorageState={initial}
      config={schedule}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <ConfirmationModalProvider>{children}</ConfirmationModalProvider>
    </DomainProvider>
  )
}

export const ReportProviders = ({ initial, children }) => {
  return (
    <DomainProvider
      connectToParentStore
      initialStorageState={initial}
      config={report}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <ReportValidationProvider>{children}</ReportValidationProvider>
    </DomainProvider>
  )
}

export const NewScheduleProvider = ({ children }) => {
  return (
    <DomainProvider
      config={newSchedule}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      {children}
    </DomainProvider>
  )
}
