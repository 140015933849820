import React, { useContext, useState, useEffect, useCallback, useMemo, Fragment, useRef, useReducer } from "react"
import { Form, Modal, Row, Col, Tab, Nav, Popover, OverlayTrigger } from "react-bootstrap"
import { cloneDeep } from "lodash"
import moment from "moment"
import { DataContext, ValidationContext, QueryContext, DataContextActions } from "../../../contexts"
import FormRow from "../../../components/blocks/FormRow"
import RowWarning from "../../blocks/RowWarning"
import { selectProgramSubskills, getTabIcon, getTabIconClassName, daysInStatus } from "../helpers"
import { PropsInjector } from "../../PropsInjector"
import Switch from "../../blocks/Switch"
import useViewport from "../../../hooks/useViewport"
import EditableSkillList from "./editable_skill_list"
import { UploadFileField } from "../../UploadFileField"
import { ValidationNames, cancelledStatuses, reportValidationStatuses, ReportStatuses } from "../constants"
import { Entities, Roles } from "../../../constants"
import {
  updateReportAgenda,
  updateReportNextSteps,
  updateStudentReport,
  updateStudentReportStudentStrengths,
  updateCustomProgram,
  getStudentProgramProgress,
  getNewStudentProgramProgress,
  deleteStudentProgramProgress,
  createStudentProgramProgress,
  updateStudentProgramProgress,
  getReport,
  sendReport,
  restoreReport,
  updateProgramProgressForAllStudents,
  changeSessionDocuments,
  getUserPrograms,
} from "../configurations/domain"
import PartialEntityTextUpdate from "../../../components/blocks/PartialEntityTextUpdate"
import { ProgramProgressBars } from "../../blocks/ProgramProgressBars"
import { urlBuilder } from "../../../hooks/useQueryList"
import { ReactComponent as EmptyBox } from "../../../assets/images/empty_box.svg"
import { ReactComponent as Pencil } from "../../../assets/images/pencil.svg"
import { ReactComponent as ReportOverdue } from "../../../assets/images/report-overdue.svg"
import { ReactComponent as Delete } from "../../../assets/images/delete.svg"
import { ReactComponent as Copy } from "../../../assets/images/copy.svg"
import { className } from "../../../helpers/className"
import { useApiInitialPath } from "../../../hooks/useApiPath"
import Dialog from "../../blocks/Dialog"
import noop from "../../../helpers/noop"
import { useParams } from "react-router-dom"
import { NeedAction } from "../schedule/week_schedule"

const baseTabs = ["Progress", "Documents"]

const RevertProgressButton = () => {
  const { scheduleId, report_date } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(restoreReport)

  const sendReportRestore = async () =>
    await request({
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
      },
    })

  return (
    <div className={`d-flex ${loading ? "align-items-center" : "align-items-baseline"}`}>
      <span className="school-session-report-reverse-text">Report submitted and will be sent on Sunday.</span>
      {loading ? (
        <div className="spinner-border text-primary ml-2" />
      ) : (
        <button className="btn btn-link ml-2" onClick={sendReportRestore}>
          Edit report
        </button>
      )}
    </div>
  )
}

const SendReportButton = ({ popoverPlacement = "bottom", buttonClassName = "", position }) => {
  const { isMobileViewport } = useViewport()
  const { scheduleId, report_date } = useContext(DataContext)
  const { getErrorList } = useContext(ValidationContext)
  const errorList = useMemo(() => getErrorList(), [getErrorList])
  const isInvalid = useMemo(() => errorList.length > 0, [errorList.length])
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(sendReport)

  const changeReportStatus = useCallback(
    async () =>
      await request({
        entitiesIds: {
          [Entities.Schedules]: scheduleId,
          [Entities.Reports]: report_date,
        },
      }),
    [request, scheduleId, report_date]
  )

  const ErrorListPopover = useMemo(
    () => (
      <Popover className="school-session-report">
        {errorList.length > 0 && (
          <Popover.Content>
            <div className="school-session-report_error-tip mb-2">You need to fill in the following data:</div>
            <ul className="school-session-report_error-list">
              {errorList.map((error, idx) => (
                <li key={idx} className="school-session-report_error-list-item">
                  <span className="school-session-report_error-text">{error}</span>
                </li>
              ))}
            </ul>
          </Popover.Content>
        )}
      </Popover>
    ),
    [errorList]
  )

  const RequestButton = useMemo(
    () => (
      <button
        onClick={isInvalid ? noop : changeReportStatus}
        tabIndex={isInvalid ? -1 : 1}
        className={`btn btn-outline-primary session-report-submit ${buttonClassName} ${
          isInvalid ? "cursor-default disabled" : ""
        }`}
        data-test={`send-report-${position}`}
      >
        <div className="d-flex align-items-center justify-content-center">
          <span className={!isMobileViewport && isInvalid ? "ml-n2" : ""}>Send Report</span>
          {isInvalid && (
            <div className="school-session-report-error-icon ml-2">
              <ReportOverdue />
            </div>
          )}
        </div>
      </button>
    ),
    [buttonClassName, changeReportStatus, isInvalid, isMobileViewport, position]
  )

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  if (errorList.length === 0) return RequestButton
  else
    return (
      <OverlayTrigger
        trigger={isMobileViewport ? ["click"] : ["hover", "focus"]}
        placement={popoverPlacement}
        overlay={ErrorListPopover}
        popperConfig={{ enabled: isInvalid }}
      >
        {RequestButton}
      </OverlayTrigger>
    )
}

const DisplaySendReportButton = (report, is_footer) => {
  const validStatuses = ["in_progress", "report_overdue"]
  if (report.readonly) {
    return false
  }

  if (validStatuses.includes(report.status)) {
    if (is_footer) {
      return <SendReportButton popoverPlacement="top" position="footer" />
    } else {
      return <SendReportButton position="header" />
    }
  }
}

const DisplayEditButton = report => {
  const validStatuses = ["report_submitted", "report_sent", "report_abandoned"]
  if (report.readonly) {
    return false
  }

  if (validStatuses.includes(report.status)) {
    return <RevertProgressButton />
  }
}
const Header = () => {
  const { report } = useContext(DataContext)
  const { isPhoneViewport } = useViewport()

  return (
    <div className={`d-flex ${isPhoneViewport ? "flex-column" : "align-items-center"}`}>
      <div className={`flex-grow-1 ${isPhoneViewport ? "mb-3" : "mr-3"}`}>
        <h4 className="session-report-header">
          <span>Weekly&nbsp;Report</span>
        </h4>
      </div>
      {DisplaySendReportButton(report, false)}
      {DisplayEditButton(report)}
    </div>
  )
}

const Footer = () => {
  const { report } = useContext(DataContext)
  const { getValidationValueByName } = useContext(ValidationContext)
  const { isPhoneViewport } = useViewport()

  return (
    <div className={`d-flex ${isPhoneViewport ? "flex-column" : "align-items-center"}`}>
      {DisplaySendReportButton(report, true)}
      {getValidationValueByName(ValidationNames.CommonInvalid) && (
        <span className={`session-report_submit-tip ${isPhoneViewport ? "mt-4 text-center" : "ml-4"}`}>
          All fields required
        </span>
      )}
    </div>
  )
}

const formRowProps = {
  LabelProps: { sm: 24, lg: 7, className: "v-2" },
  ColProps: { sm: 24, lg: 17, className: "mb-0" },
  RowProps: {},
  className: "mb-0",
}

const SessionTab = ({ studentReport }) => {
  const {
    report: { status },
  } = useContext(DataContext)
  const { getValidationValueByName } = useContext(ValidationContext)
  const { student, report_attendance, default_attendance, sessions } = studentReport

  const canValidate = useMemo(() => reportValidationStatuses.includes(status), [status])
  const isAbsent = report_attendance ? !default_attendance : sessions.every(({ attendance }) => !attendance)
  const isInvalid = getValidationValueByName([ValidationNames.GroupProgressInfo, student.id])
  const iconClass = useMemo(() => getTabIconClassName(isAbsent, !isInvalid), [isAbsent, isInvalid])
  const iconClassName = className("session-report_tab-icon", "ml-1", iconClass)
  const Icon = useMemo(() => getTabIcon(isAbsent, !isInvalid), [isAbsent, isInvalid])

  return (
    <Nav.Item className="brain-trust-tab">
      <Nav.Link eventKey={student.id} as="button" role="tab" className="brain-trust-tab-content">
        <span>
          {student.first_name} {student.last_name}
        </span>
        {canValidate && (
          <div className={iconClassName}>
            <Icon />
          </div>
        )}
      </Nav.Link>
    </Nav.Item>
  )
}

const SkillListWrapper = ({ studentId, showCopyToRestStudents, programProgressId, editableProgramId, onCancel }) => {
  const { report, report_date, scheduleId } = useContext(DataContext)
  const [fullProgramProgress, setFullProgramProgress] = useState(null)
  const selectedSubskills = useMemo(() => {
    const studentReport = report.student_reports.find(el => el.student.id === studentId)
    if (!studentReport) return []
    const progress = studentReport.program_progresses.find(el => el.id === programProgressId)
    return selectProgramSubskills(progress?.skills || [])
  }, [report, programProgressId, studentId])

  const { getHookState } = useContext(QueryContext)
  const { loading, error, request } = getHookState(
    programProgressId === "new" ? getNewStudentProgramProgress : getStudentProgramProgress
  )

  const studentProgramProgressRequest = useCallback(
    async () =>
      await request({
        params: programProgressId === "new" ? { program_id: editableProgramId } : null,
        entitiesIds: {
          [Entities.Schedules]: scheduleId,
          [Entities.Reports]: report_date,
          [Entities.StudentReports]: studentId,
          [Entities.ProgramProgresses]: programProgressId === "new" ? null : programProgressId,
        },
        onSuccess: setFullProgramProgress,
      }),
    [editableProgramId, programProgressId, report_date, request, scheduleId, studentId]
  )

  const { request: programProgressRequest } = getHookState(
    programProgressId === "new" ? createStudentProgramProgress : updateStudentProgramProgress
  )

  const { request: progressForAllStudents } = getHookState(updateProgramProgressForAllStudents)

  const onEdit = async ({ copyToAllStudents, ...program_progress }) => {
    if (copyToAllStudents) {
      await progressForAllStudents({
        data: { program_progress },
        entitiesIds: {
          [Entities.Schedules]: scheduleId,
          [Entities.Reports]: report_date,
        },
      })
    } else
      await programProgressRequest({
        data: { program_progress },
        entitiesIds: {
          [Entities.Schedules]: scheduleId,
          [Entities.Reports]: report_date,
          [Entities.StudentReports]: studentId,
          [Entities.ProgramProgresses]: programProgressId === "new" ? null : programProgressId,
        },
      })
  }

  useEffect(() => {
    if (loading || error) return
    if (programProgressId !== null && fullProgramProgress === null) studentProgramProgressRequest()
  }, [studentProgramProgressRequest, programProgressId, loading, fullProgramProgress, error])

  if (fullProgramProgress === null || loading) {
    return (
      <div className="h-400-px d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  return (
    <EditableSkillList
      programId={editableProgramId}
      progressId={studentId}
      selectedSubskills={selectedSubskills}
      programProgress={fullProgramProgress.skills}
      title={fullProgramProgress.program?.name}
      onCancel={onCancel}
      onEdit={onEdit}
      showCopyToRestStudents={showCopyToRestStudents}
    />
  )
}

const SkillsModal = ({ show, showCopyToRestStudents, studentId, programProgressId, editableProgramId, onHide }) => {
  const { isMobileViewport } = useViewport()

  return (
    <Dialog size="xl" open={show} onClose={onHide} className="skill-list" centered={false}>
      <div className={className("py-4", isMobileViewport ? "py-5" : "px-5")}>
        <SkillListWrapper
          editableProgramId={editableProgramId}
          studentId={studentId}
          programProgressId={programProgressId}
          onCancel={onHide}
          showCopyToRestStudents={showCopyToRestStudents}
        />
      </div>
    </Dialog>
  )
}

const SkillStatuses = {
  Mastered: "mastered",
  WorkingOn: "working_on",
  StudiedEarlier: "studied_earlier",
}

const skillStatusesOrder = [SkillStatuses.Mastered, SkillStatuses.WorkingOn, SkillStatuses.StudiedEarlier]

const SkillStatusTitles = {
  [SkillStatuses.Mastered]: "Skills mastered",
  [SkillStatuses.WorkingOn]: "Working on",
  [SkillStatuses.StudiedEarlier]: "Studied earlier",
}

const SkillStatusStyles = {
  [SkillStatuses.Mastered]: "-mastered",
  [SkillStatuses.WorkingOn]: "-working-on",
  [SkillStatuses.StudiedEarlier]: "-studied-earlier",
}

const ProgramProgress = ({ visibleSkillStatus, skills, className }) => {
  return (
    <FormRow
      label={SkillStatusTitles[visibleSkillStatus]}
      {...formRowProps}
      LabelProps={{ ...formRowProps.LabelProps, className: "school-report_skill-status" }}
      className={className}
    >
      <div className="max-w-400-px d-flex flex-column align-items-stretch my-2">
        {skills.map(({ id, name, subskills }) => {
          return (
            <Fragment key={id}>
              <span className="school-report_subskill-name">{name}</span>
              {subskills.map(({ id, name, mastered_status: status }) => (
                <div key={id} className={`skill v-2 color ${SkillStatusStyles[status] || ""}`} title={name}>
                  {name}
                </div>
              ))}
            </Fragment>
          )
        })}
      </div>
    </FormRow>
  )
}

const ProgramTitle = ({ name, programId, programProgressId, readonly, onEdit, loading, onDelete }) => {
  const deleteProgress = () => {
    onDelete({ programProgressId })
  }

  return (
    <div className="d-flex align-items-start justify-content-between mb-3">
      <div className="d-flex">
        <h4 className="school-report_program-title">{name}</h4>
        {!readonly && (
          <>
            {!loading ? (
              <button onClick={deleteProgress} className="school-report_program-action -delete ml-2">
                <Delete />
              </button>
            ) : (
              <div className="school-report_program-action-spinner spinner-border text-primary ml-2" />
            )}
          </>
        )}
      </div>
      {!readonly && (
        <button
          disabled={loading}
          onClick={() => onEdit({ programId, programProgressId })}
          className="school-report_program-action -add ml-4"
        >
          <Pencil />
        </button>
      )}
    </div>
  )
}

const ProgramProgressList = ({
  studentId,
  showCopyToRestStudents,
  defaultProgramId,
  studentName,
  readonly,
  studentPrograms,
  onEdit,
}) => {
  const { scheduleId, report_date } = useContext(DataContext)
  const removingProgressIdsRef = useRef(new Set())
  const filterProgressByStatus = useCallback(([visibleSkillStatus, program]) => {
    let list = cloneDeep(program.skills)
    for (const skill of list) {
      skill.subskills = skill.subskills.filter(({ mastered_status }) => mastered_status === visibleSkillStatus)
    }
    list = list.filter(skill => skill.subskills.length)
    return {
      status: visibleSkillStatus,
      list,
    }
  }, [])
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(deleteStudentProgramProgress)

  const deleteProgress = async ({ programProgressId }) => {
    removingProgressIdsRef.current.add(programProgressId)
    await request({
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
        [Entities.StudentReports]: studentId,
        [Entities.ProgramProgresses]: programProgressId,
      },
      onComplete: () => {
        removingProgressIdsRef.current.delete(programProgressId)
      },
    })
  }

  return (
    <>
      {studentPrograms
        .sort(progress => (progress.program.id === defaultProgramId ? -1 : 1))
        .map(progress => {
          return (
            <Fragment key={progress.id}>
              <ProgramTitle
                name={progress.program.name}
                programId={progress.program.id}
                programProgressId={progress.id}
                readonly={readonly}
                onEdit={onEdit}
                onDelete={deleteProgress}
                loading={loading && removingProgressIdsRef.current.has(progress.id)}
              />
              {skillStatusesOrder
                .map(status => [status, progress])
                .map(filterProgressByStatus)
                .filter(({ list }) => list.length)
                .map(({ status, list }, idx, arr) => (
                  <ProgramProgress
                    key={status}
                    visibleSkillStatus={status}
                    skills={list}
                    className={idx === arr.length - 1 ? "mb-4" : ""}
                  />
                ))}
              {showCopyToRestStudents && !readonly && <CopySkillsToAllStudents programProgress={progress} />}
              <OverallProgress studentName={studentName} studentId={studentId} programProgressId={progress.id} />
            </Fragment>
          )
        })}
    </>
  )
}

const ProgramSelector = ({ defaultProgram, availablePrograms, studentPrograms, hasCustomProgram, onAdd }) => {
  const programs = useMemo(() => {
    let list = cloneDeep(availablePrograms || [])
    if (studentPrograms.length)
      list = list.filter(
        program => !studentPrograms.find(progressProgram => `${progressProgram.program.id}` === program.value)
      )
    if (hasCustomProgram) list = list.filter(program => program.value !== "other")
    const defaultProgramIdx = list.findIndex(el => el.value === `${defaultProgram.id}`)
    if (defaultProgramIdx !== -1) list[defaultProgramIdx].title = `${list[defaultProgramIdx].title} (default)`
    return list
  }, [availablePrograms, defaultProgram.id, studentPrograms, hasCustomProgram])

  const getDefaultValue = useCallback(
    () => programs.find(el => el.value === `${defaultProgram.id}`)?.value || "",
    [defaultProgram.id, programs]
  )

  const [selectedProgramId, setSelectedProgramId] = useState(getDefaultValue)
  const updateSelectedProgramId = ({ target: { value } }) => setSelectedProgramId(value)

  const onAddSkills = () => {
    const selectedProgram = availablePrograms.find(program => program.value === selectedProgramId)
    onAdd(selectedProgram)
  }

  useEffect(() => {
    if (selectedProgramId !== void 0 && !programs.some(el => el.value === selectedProgramId))
      setSelectedProgramId(getDefaultValue())
  }, [getDefaultValue, programs, selectedProgramId])

  return (
    <div className="max-w-400-px d-flex align-items-stretch mt-n1">
      <Form.Control
        as="select"
        className="v-2"
        value={selectedProgramId}
        onChange={updateSelectedProgramId}
        data-test="program-select"
      >
        <option hidden disabled value="" />
        {programs.map(({ title, value }) => (
          <option key={value} value={value}>
            {title}
          </option>
        ))}
      </Form.Control>
      <button className="partial-entity-text-add-btn flex-shrink-0 ml-2" onClick={onAddSkills} data-test="add-skills">
        Add skills
      </button>
    </div>
  )
}

const CopySkillsToAllStudentsModal = ({ show, onConfirm, onCancel }) => {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onCancel}
      backdrop="static"
      keyboard={false}
      dialogClassName="copy-to-all-students-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Apply to all students?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col>You’ll overwrite the skills progression of all students</Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <button className="btn btn-primary" onClick={onConfirm}>
              Confirm
            </button>
          </Col>
          <Col className="d-flex">
            <button className="btn btn-link" onClick={onCancel}>
              Cancel
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

const CopySkillsToAllStudents = ({ programProgress }) => {
  const { getHookState } = useContext(QueryContext)
  const { scheduleId, report_date } = useContext(DataContext)

  const [{ loading, open }, dispatch] = useReducer((state, newState) => ({ ...state, ...newState }), {
    open: false,
    loading: false,
  })

  const { request } = getHookState(updateProgramProgressForAllStudents)

  const confirm = () => {
    dispatch({ loading: true, open: false })
    const {
      kind,
      program: { id: program_id },
      skills,
    } = programProgress
    const new_sub_skills = skills.reduce((list, { subskills }) => {
      const subskillsList = subskills.map(({ id: subskill_id, status }) => ({ subskill_id, status }))
      list.push(...subskillsList)
      return list
    }, [])
    const program_progress = { program_id, kind, new_sub_skills }
    request({
      data: { program_progress },
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
      },
      onSuccess: () => dispatch({ loading: false }),
    })
  }

  return (
    <FormRow label=" " {...formRowProps}>
      <hr className="mt-0 mb-4 soft-color" />
      <div className="d-flex align-items-center">
        <button
          onClick={() => dispatch({ open: true })}
          disabled={loading}
          className="btn btn-link d-flex align-items-center mr-4"
        >
          <span className="mr-2">
            <Copy className="icon-16" />
          </span>
          <span>Copy these skills to all students</span>
        </button>
        <div className={`spinner-border text-primary ${!loading ? "invisible" : ""}`} />
      </div>
      <hr className="mt-4 mb-4 soft-color" />
      <CopySkillsToAllStudentsModal show={open} onCancel={() => dispatch({ open: false })} onConfirm={confirm} />
    </FormRow>
  )
}

const UpdateCustomProgramText = ({ onClear, children }) => {
  const { scheduleId, report, report_date } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(updateCustomProgram)

  const onChange = async data => {
    await request({
      data,
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
      },
      onSuccess: onClear,
    })
    // onClear()
  }

  const props = {
    loading,
    onChange,
    readonly: report.readonly,
  }

  return <PropsInjector props={props}>{children}</PropsInjector>
}

const OtherProgramTitle = ({ readonly, onChange, loading }) => {
  const onClick = () => onChange({ custom_program: "" })

  return (
    <div className="d-flex">
      <h4 className="school-report_program-title mb-2">Custom program</h4>
      {!readonly && (
        <button onClick={onClick} disabled={loading} className="school-report_program-action -delete ml-2">
          <Delete />
        </button>
      )}
    </div>
  )
}

const OverallProgressModalContent = ({ studentId, programProgressId }) => {
  const { scheduleId, report_date } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { loading, request, failed } = getHookState(getStudentProgramProgress)
  const [progress, setProgress] = useState(null)

  useEffect(() => {
    if (progress || loading || failed) return
    request({
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
        [Entities.StudentReports]: studentId,
        [Entities.ProgramProgresses]: programProgressId,
      },
      onSuccess: data => setProgress(data),
    })
  })

  if (loading || failed) {
    return (
      <Col>
        <div className="min-h-200-px">
          <div className="spinner-wrapper">
            <div className="spinner-border text-primary" />
          </div>
        </div>
      </Col>
    )
  }

  return <Col>{progress && <ProgramProgressBars progress={progress} />}</Col>
}

const OverallProgressModal = ({ show, onHide, studentName, studentId, programProgressId }) => {
  return (
    <Dialog
      title={`${studentName} — Overall progress`}
      size="xl"
      open={show}
      onClose={onHide}
      className="skill-list"
      centered={false}
    >
      <OverallProgressModalContent studentId={studentId} programProgressId={programProgressId} />
    </Dialog>
  )
}

const OverallProgress = ({ studentName, studentId, programProgressId }) => {
  const [open, setOpen] = useState(false)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)
  return (
    <FormRow label=" " {...formRowProps}>
      <button onClick={openModal} className="btn btn-link d-flex align-items-center">
        <span>Overall progress</span>
      </button>
      <hr className="mt-4 mb-4 soft-color" />
      <OverallProgressModal
        show={open}
        onHide={closeModal}
        studentName={studentName}
        studentId={studentId}
        programProgressId={programProgressId}
      />
    </FormRow>
  )
}

const StudentProgress = ({
  showCopyToRestStudents,
  readonly,
  studentId,
  defaultProgramId,
  studentName,
  programProgresses,
}) => {
  const inputRef = useRef(null)
  const [editableProgram, setEditableProgram] = useState(null)
  const hideModal = () => setEditableProgram(null)
  const [customProgramEditing, setCustomProgramEditing] = useState(false)
  const { schedule, report, availablePrograms } = useContext(DataContext)

  const onProgramAdd = program => {
    if (program.value.toLowerCase() === "other") {
      setCustomProgramEditing(true)
      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus()
      }, 4)
    } else setEditableProgram({ programId: parseInt(program.value, 10), programProgressId: "new" })
  }

  const onProgramEdit = ({ programId, programProgressId }) => {
    setEditableProgram({ programId, programProgressId })
  }

  return (
    <>
      {!readonly &&
        (report.actual_connections.length > 0 ? (
          <>
            <div className="school-report_subject-tip mb-4">Choose subject AND add skills below</div>
            <RowWarning
              className="px-4 mx-n4 mb-4"
              name={[ValidationNames.GroupProgressInfo, studentId, ValidationNames.StudentProgramProgresses]}
            >
              <FormRow label="Subject" {...formRowProps}>
                <ProgramSelector
                  defaultProgram={schedule.program}
                  availablePrograms={availablePrograms}
                  studentPrograms={programProgresses}
                  hasCustomProgram={Boolean(report.custom_program)}
                  onAdd={onProgramAdd}
                />
              </FormRow>
            </RowWarning>
            <FormRow
              label=" "
              {...{ ...formRowProps, LabelProps: { ...formRowProps.LabelProps, className: "p-0 v-2" } }}
              className="mt-0 mb-4"
            >
              <hr className="my-0 soft-color" />
            </FormRow>
          </>
        ) : (
          <div className="school-report_subject-tip mb-4">
            <NeedAction>No tutors assigned for the report date range</NeedAction>
          </div>
        ))}
      {(report.custom_program || customProgramEditing) && (
        <div className="mb-4">
          <UpdateCustomProgramText onClear={() => setCustomProgramEditing(false)}>
            <OtherProgramTitle />
            <PartialEntityTextUpdate
              placeholder="Add custom program information"
              className="mb-3"
              initialButtonClassName="max-w-400-px"
              readonlyPlaceholder="—"
              name="custom_program"
              onCancel={() => setCustomProgramEditing(false)}
              initialEditState={customProgramEditing}
              ref={inputRef}
              value={report.custom_program}
            />
          </UpdateCustomProgramText>
        </div>
      )}
      <ProgramProgressList
        studentId={studentId}
        defaultProgramId={defaultProgramId}
        studentName={studentName}
        readonly={readonly}
        studentPrograms={programProgresses}
        onEdit={onProgramEdit}
        showCopyToRestStudents={showCopyToRestStudents}
      />
      {!readonly && (
        <SkillsModal
          show={editableProgram !== null}
          showCopyToRestStudents={showCopyToRestStudents}
          studentId={studentId}
          editableProgramId={editableProgram?.programId || null}
          programProgressId={editableProgram?.programProgressId || null}
          onHide={hideModal}
        />
      )}
    </>
  )
}

const UpdateReport = ({ children, config }) => {
  const { scheduleId, report_date, report } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(config)

  const onChange = async data =>
    await request({
      data,
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
      },
    })

  const props = {
    loading,
    onChange,
    readonly: report.readonly,
  }

  return <PropsInjector props={props}>{children}</PropsInjector>
}

const UpdateStrengthsAndChallenges = ({ studentId, children }) => {
  const { report, scheduleId, report_date } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)

  const { loading, request } = getHookState(updateStudentReportStudentStrengths)

  const onChange = async data =>
    await request({
      data,
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
        [Entities.StudentReports]: studentId,
      },
    })

  const props = {
    loading,
    onChange,
    readonly: report.readonly,
  }

  return <PropsInjector props={props}>{children}</PropsInjector>
}

const WeekAttendanceSwitch = ({ value, loading, onChange, disabled }) => {
  return (
    <div className="d-flex align-items-center">
      <Switch
        name="attended"
        label="Attended the week"
        wrapperClassName="flex-grow-1"
        checked={value}
        disabled={loading || disabled}
        onChange={onChange}
      />
    </div>
  )
}

const AttendanceTypeSwitch = ({ loading, reportAttendance, onClick }) => {
  const change = () => {
    onClick(!reportAttendance)
  }

  let text = "Switch to attendance by week"
  if (reportAttendance) text = "Switch to attendance by day"

  return (
    <button className="btn btn-link" onClick={change} disabled={loading}>
      {text}
    </button>
  )
}

const Attendance = props => {
  const { scheduleId, report_date, report, scheduleSessions } = useContext(DataContext)
  const disabledByStatus = report.status === "planned"

  const { studentId, readonly, reportAttendance, defaultAttendance, sessions, psAbsentInfo } = props
  const { isMobileViewport } = useViewport()

  const { getHookState } = useContext(QueryContext)

  const { loading: updateStudentLoading, request: updateStudentRequest } = getHookState(updateStudentReport)

  const updateStudent = async data =>
    await updateStudentRequest({
      data,
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
        [Entities.StudentReports]: studentId,
      },
    })

  const markAbsent = async () => {
    const sessions = psAbsentInfo.map(absentInfo => ({
      session_id: absentInfo.group_session_student_id,
      attendance: true,
    }))
    updateStudent({ sessions })
  }

  const updateWeeklyAttendance = newValue => {
    const data = { report_attendance: newValue }
    updateStudent(data)
  }

  const updateWeeklyLateCancelation = newValue => {
    const data = { default_attendance: newValue }
    updateStudent(data)
  }

  const updateLateCancelationSchedule = (attendance, session_id) => {
    const sessions = [{ session_id, attendance }]
    updateStudent({ sessions })
  }

  const buildDate = useCallback(date => moment(date).format("ddd D"), [])

  const attendantSessions = useMemo(() => sessions.filter(({ attendance }) => attendance), [sessions])

  return (
    <>
      {readonly && (
        <FormRow label="Attendance" {...formRowProps} className="my-0 px-4 mx-n4">
          {attendantSessions.length > 0 ? (
            <div className="my-2 font-weight-medium">
              Student was present on {attendantSessions.map(({ started_on }) => buildDate(started_on)).join(", ")}
            </div>
          ) : (
            <div className="my-2 font-weight-medium">
              {scheduleSessions.length === 0 ? "N/A" : "Student was absent from all held sessions"}
            </div>
          )}
        </FormRow>
      )}
      {!readonly && (
        <>
          <FormRow label="Attendance" {...formRowProps} className="my-0 px-4 mx-n4">
            <Row className="align-items-center">
              <Col className="col-auto">
                <WeekAttendanceSwitch
                  disabled={!reportAttendance || updateStudentLoading || disabledByStatus}
                  value={defaultAttendance}
                  onChange={({ target: { checked } }) => updateWeeklyLateCancelation(checked)}
                  loading={updateStudentLoading}
                />
              </Col>
              <Col className="col-auto">
                <Row>
                  <Col className="col-auto py-1 px-0">
                    <hr className="horizontal thin" />
                  </Col>
                  <Col className="d-flex">
                    <AttendanceTypeSwitch
                      reportAttendance={reportAttendance}
                      onClick={updateWeeklyAttendance}
                      loading={updateStudentLoading}
                    />
                  </Col>
                  {updateStudentLoading && (
                    <Col>
                      <div className="spinner-border text-primary ml-2" />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            {!reportAttendance && (
              <Row className={`mt-4 mb-2 ${isMobileViewport ? "flex-column" : ""}`}>
                <Col className={`col-auto ${isMobileViewport ? "mr-2" : ""}`}>
                  <Row>
                    {sessions.map(day => (
                      <Col xs="auto" key={day.session_id}>
                        <Form.Check
                          custom
                          id={`session_id_${day.session_id}`}
                          onChange={({ target: { checked } }) => updateLateCancelationSchedule(checked, day.session_id)}
                          checked={day.attendance}
                          className="v-2 text-nowrap text-uppercase"
                          label={buildDate(day.started_on)}
                          disabled={updateStudentLoading || disabledByStatus}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
            {psAbsentInfo.length > 0 && (
              <Row className="mt-4">
                <Col className="col-auto">
                  <Row className="school-session-report-attendance-warning align-items-baseline">
                    <Col>
                      <span className="font-weight-semibold mr-4">
                        Pencil Spaces missed classes:{" "}
                        {psAbsentInfo
                          .map(({ date }) => date)
                          .map(buildDate)
                          .join(", ")}
                      </span>
                    </Col>
                    <Col className="col-auto">
                      <button className="btn btn-link fullwidth" onClick={markAbsent}>
                        Apply
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </FormRow>
        </>
      )}
    </>
  )
}

const StudentReportTabContent = ({
  readonly,
  studentId,
  defaultProgramId,
  studentName,
  reportAttendance,
  defaultAttendance,
  sessions,
  programProgresses,
  strengthsChallenges,
  psAbsentInfo,
  single,
}) => {
  return (
    <>
      {!single && (
        <Attendance
          studentId={studentId}
          readonly={readonly}
          reportAttendance={reportAttendance}
          defaultAttendance={defaultAttendance}
          sessions={sessions}
          psAbsentInfo={psAbsentInfo}
        />
      )}
      {sessions.some(({ attendance }) => attendance) ? (
        <>
          {!single && <hr className="mt-3 mb-4 mx-n4 soft-color" />}
          <StudentProgress
            readonly={readonly}
            studentName={studentName}
            studentId={studentId}
            defaultProgramId={defaultProgramId}
            programProgresses={programProgresses}
            showCopyToRestStudents={!single}
          />
          <RowWarning
            className="px-4 mx-n4"
            name={[ValidationNames.GroupProgressInfo, studentId, ValidationNames.StudentStrengthsChallenges]}
          >
            <FormRow label="Strengths/Challenges" {...formRowProps}>
              <UpdateStrengthsAndChallenges studentId={studentId}>
                <PartialEntityTextUpdate
                  className="my-1 py-2"
                  initialButtonClassName="max-w-400-px mt-n1"
                  placeholder="Add strengths and challenges"
                  name="strengths_challenges"
                  readonlyPlaceholder="—"
                  value={strengthsChallenges}
                />
              </UpdateStrengthsAndChallenges>
            </FormRow>
          </RowWarning>
        </>
      ) : null}
    </>
  )
}

const DocumentUploadTab = ({ report, readonly }) => {
  const { scheduleId, report_date, directUploadUrl } = useContext(DataContext)
  const { updateState } = useContext(DataContextActions)
  const apiBase = useApiInitialPath()
  const url = useMemo(
    () =>
      urlBuilder({
        ...changeSessionDocuments,
        apiBase,
        entitiesIds: {
          [Entities.Schedules]: scheduleId,
          [Entities.Reports]: report_date,
        },
      }),
    [apiBase, report_date, scheduleId]
  )

  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(getReport)

  const onChange = async () =>
    await request({
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
      },
    })

  return (
    <UploadFileField
      submitUrl={url}
      removeUrl={url}
      directUploadUrl={directUploadUrl}
      files={report.documents}
      setFiles={getNewFiles => {
        const documents = getNewFiles(report.documents)
        updateState(getReport, { ...report, documents })
      }}
      uploadEnabled={!readonly || loading}
      onChange={onChange}
    />
  )
}

const StudentReportContent = ({ report, single, readonly }) => {
  return (
    <StudentReportTabContent
      readonly={readonly}
      studentId={report.student.id}
      defaultProgramId={report.default_program_id}
      studentName={`${report.student.first_name} ${report.student.last_name}`}
      reportAttendance={report.report_attendance}
      defaultAttendance={report.default_attendance}
      sessions={report.sessions}
      programProgresses={report.program_progresses}
      strengthsChallenges={report.strengths_challenges}
      psAbsentInfo={report.absent_ps || []}
      single={single}
    />
  )
}

const TutorProgramsRequest = ({ children }) => {
  const { report_date: date } = useParams()
  const { commonInvalid } = useContext(ValidationContext)
  const { scheduleId, role, report_date, report, availablePrograms } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, failed, loading } = getHookState(getUserPrograms)

  useEffect(() => {
    if (
      date !== report_date ||
      commonInvalid ||
      availablePrograms ||
      role === Roles.Parent ||
      report?.actual_connections?.length === 0 ||
      [ReportStatuses.Submitted, ReportStatuses.Sent].includes(report.status) ||
      !request ||
      loading ||
      failed
    )
      return
    request({
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: report_date,
      },
    })
  }, [availablePrograms, commonInvalid, date, failed, loading, report, report_date, request, role, scheduleId])

  if (!report || !request || loading)
    return (
      <div className="min-h-180-px d-flex flex-column align-items-center justify-content-center">
        <div className="spinner-border text-primary" />
      </div>
    )

  return children
}

const ReportContent = () => {
  const invalidStatuses = ["no_sessions", "planned"]
  const { report, scheduleSessions } = useContext(DataContext)
  const { getValidationValueByName } = useContext(ValidationContext)
  const [reportTabTitle, documentsTabTitle] = baseTabs
  const dynamicDocumentsTabTitle = useMemo(
    () => `${documentsTabTitle}${report.documents.length > 0 ? ` (${report.documents.length})` : ""}`,
    [documentsTabTitle, report.documents.length]
  )

  const GetNoReportText = () => {
    if (report.status === "no_sessions") {
      return "Currently, there are no sessions scheduled for this report."
    } else {
      return (
        "This report is for a date in the future.  The report information will show when the dates for this" +
        " report are current"
      )
    }
  }

  const emptySessions = getValidationValueByName(ValidationNames.EmptySessions)

  const allSessionsCancelled = useMemo(() => daysInStatus(cancelledStatuses, scheduleSessions), [scheduleSessions])

  if (invalidStatuses.includes(report.status)) {
    return (
      <>
        <h4 className="session-report_title mb-4">{GetNoReportText()}</h4>
      </>
    )
  } else {
    return (
      <>
        <h4 className="session-report_title mb-4">Agenda</h4>
        <RowWarning className="px-4 mx-n4 mb-4" name={ValidationNames.Agenda}>
          <FormRow label="Weekly agenda" {...formRowProps}>
            <UpdateReport config={updateReportAgenda}>
              <PartialEntityTextUpdate
                placeholder="Add agenda"
                name="agenda"
                readonlyPlaceholder="—"
                className="my-1 py-2"
                initialButtonClassName="max-w-400-px mt-n1"
                value={report.agenda}
              />
            </UpdateReport>
          </FormRow>
        </RowWarning>
        <RowWarning className="px-4 mx-n4" name={ValidationNames.NextSteps}>
          <FormRow label="Next week steps" {...formRowProps}>
            <UpdateReport config={updateReportNextSteps}>
              <PartialEntityTextUpdate
                placeholder="Add next steps"
                name="next_steps"
                readonlyPlaceholder="—"
                className="my-1 py-2"
                initialButtonClassName="max-w-400-px mt-n1"
                value={report.next_steps}
              />
            </UpdateReport>
          </FormRow>
        </RowWarning>
        <hr className="mx-n4 bold soft-color" />
        <Tab.Container defaultActiveKey={reportTabTitle}>
          <Nav className="d-flex align-items-center mb-4">
            <Nav.Item>
              <Nav.Link eventKey={reportTabTitle} as="button" role="tab" className="brain-trust-flat-tab">
                {reportTabTitle}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="ml-5">
              <Nav.Link eventKey={documentsTabTitle} as="button" role="tab" className="brain-trust-flat-tab">
                {dynamicDocumentsTabTitle}
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {emptySessions ? (
            <p>No sessions in the report</p>
          ) : allSessionsCancelled ? (
            <p>No student progress because all sessions are canceled</p>
          ) : (
            <Tab.Content>
              <Tab.Pane key={reportTabTitle} eventKey={reportTabTitle} unmountOnExit>
                <Tab.Container defaultActiveKey={report.student_reports[0]?.student?.id}>
                  <RowWarning name={ValidationNames.GroupProgressInfoInvalid} className="mx-n4 px-4 mb-4">
                    <div className="brain-trust-tabs-wrapper mx-n4 mb-4">
                      <Nav variant="tabs" className="brain-trust-tabs px-4" as="nav">
                        {report.student_reports.map(report => (
                          <SessionTab key={report.student.id} studentReport={report} />
                        ))}
                      </Nav>
                    </div>
                  </RowWarning>
                  <Tab.Content>
                    {report.student_reports.map(student_report => (
                      <Tab.Pane key={student_report.student.id} eventKey={student_report.student.id} unmountOnExit>
                        <StudentReportContent
                          readonly={report.readonly}
                          report={student_report}
                          single={student_report.student_reports?.length === 1}
                        />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
                {!report.readonly && (
                  <>
                    <hr className="mx-n4 bold soft-color mt-4" />
                    <Footer />
                  </>
                )}
              </Tab.Pane>
              <Tab.Pane key={documentsTabTitle} eventKey={documentsTabTitle} unmountOnExit>
                <DocumentUploadTab report={report} readonly={report.readonly} />
              </Tab.Pane>
            </Tab.Content>
          )}
        </Tab.Container>
      </>
    )
  }
}

const NoReportData = () => {
  return (
    <div className="session-report_no-data mt-3">
      <span className="session-report_no-data-icon">
        <EmptyBox />
      </span>
      <span className="session-report_no-data-title mt-3">Report has not been sent yet</span>
      <span className="session-report_no-data-subtitle">Check the page on Sunday</span>
    </div>
  )
}

const Report = () => {
  const { report } = useContext(DataContext)
  const { canShowReport } = useContext(ValidationContext)

  if (report === void 0 || report.empty) return null
  const showReport = canShowReport()
  return (
    <div className="card p-4">
      <Header />
      <hr className="my-4 soft-color" />
      {showReport ? (
        <TutorProgramsRequest>
          <ReportContent />
        </TutorProgramsRequest>
      ) : (
        <NoReportData />
      )}
    </div>
  )
}

export default Report
