import React from "react"
import cn from "classnames"
import { Field, useFormState } from "react-final-form"
import { Form, Button } from "react-bootstrap"

import MultipleChoice from "../blocks/MultipleChoice"
import HintTooltip from "../blocks/HintTooltip"
import { isExecutiveFunctionProgram } from "../../helpers/programsHierarchy"

const ButtonOption = ({ children, selected, onClick }) => (
  <Button
    type="button"
    size="sm"
    variant="warning-light"
    className={cn("mb-2 mr-2 text-nowrap", selected && "selected")}
    onClick={onClick}
  >
    {children}
  </Button>
)

const CheckboxOption = ({ id, children, selected, onClick }) => (
  <Form.Check
    custom
    id={id}
    label={children}
    className="text-nowrap mb-2 mr-2"
    type="checkbox"
    checked={selected}
    onChange={onClick}
  />
)

const SpecialTreatmentControl = ({ optionType = "button", options, inline = false, className }) => {
  const Option = optionType === "button" ? ButtonOption : CheckboxOption

  const { values } = useFormState()
  const showExecutiveFunction = !isExecutiveFunctionProgram(values.program_ids, options.programs)

  return (
    <div className={cn(inline && "d-flex align-items-center flex-wrap", className)}>
      <Field name="special_treatments">
        {({ input }) => <MultipleChoice {...input} optionType={optionType} options={options.specialTreatments} />}
      </Field>
      {showExecutiveFunction && (
        <Field name="executive_function">
          {({ input }) => (
            <div className="d-flex align-items-center flex-nowrap">
              <Option id="executive_function" selected={input.value} onClick={() => input.onChange(!input.value)}>
                Executive function skills support
              </Option>
              <div className="mb-2">
                <HintTooltip>
                  Executive function skills can be described as the management system of the brain. Students use these
                  skills to pay attention; organize, plan, and prioritize tasks; maintain focus as they complete a task;
                  think flexibly about tasks; regulate emotions; and self-monitor
                </HintTooltip>
              </div>
            </div>
          )}
        </Field>
      )}
    </div>
  )
}

export default SpecialTreatmentControl
