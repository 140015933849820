import React, { useContext } from "react"
import { Table, Card } from "react-bootstrap"
import { OpeningContext } from "./context"
import { remove } from "lodash"

const Group = ({ group }) => {
  const { opening, setOpening } = useContext(OpeningContext)

  const onRemoveGroup = () => {
    const changedOpening = { ...opening }
    remove(changedOpening.groupIds, id => id === group.id)
    setOpening(changedOpening)
  }

  return (
    <Card className="mb-3 flat sm col-24">
      <Card.Header>
        <b>{group.name}</b>
        <button className="float-right btn btn-sm btn-link btn-link-primary" onClick={onRemoveGroup}>
          Remove
        </button>
      </Card.Header>
      <Card.Body>
        <Table className="grid-table" size="sm">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Grade</th>
              <th>Learning difference</th>
              <th>Parent email</th>
            </tr>
          </thead>
          <tbody>
            {group.students.map(ogs => (
              <tr key={ogs.id}>
                <td>{ogs.first_name}</td>
                <td>{ogs.last_name}</td>
                <td>{ogs.grade}</td>
                <td>{ogs.special_treatments}</td>
                <td>{ogs.parent_email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default Group
