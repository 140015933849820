import React, { useCallback, useContext, useRef, useState } from "react"
import { Form } from "react-bootstrap"
import { Field } from "react-final-form"
import Dropdown from "../../../blocks/Dropdown"
import { autocompleteEducatables } from "../../configurations/domain"
import { QueryContext } from "../../../../contexts"
import { FormFieldNames } from "../create_schedule/constants"
import { EducatableTypes } from "../../constants"
import { className } from "../../../../helpers/className"

const DEBOUNCE_TIMEOUT = 300

const buildOption = (option, active) => {
  if (!option) {
    return ""
  }

  if (option.type === "group" || EducatableTypes.Group) {
    if (!option.students || option.students.length === 0) {
      return option.name || option.title
    }

    const studentsString = option.students
      .map(student => {
        if (typeof student === "string") {
          return student.replace(/ /g, "\u00A0")
        }

        return `${student.first_name} ${student.last_name}`.replace(/ /g, "\u00A0")
      })
      .join(", ")

    return (
      <>
        <div>{option.name || option.title}</div>
        <div className={className("font-size-extra-small font-weight-medium", "text-gray-chat" && !active)}>
          {studentsString}
        </div>
      </>
    )
  }

  return option.title
}

export const EducatableField = ({ disabled, showGroupStudents = true, school }) => {
  const timerRef = useRef(null)
  const [options, setOptions] = useState()
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState({
    ...autocompleteEducatables,
    skipStorage: true,
  })

  const onSearch = useCallback(
    ({ target: { value } }) => {
      if (value.length <= 2) return
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        request({
          params: { q: value, school_id: school?.value },
          onSuccess: data => setOptions(data.items),
        })
      }, DEBOUNCE_TIMEOUT)
    },
    [request, school]
  )

  const onChange = useCallback((event, change) => {
    change(event)
    setOptions(void 0)
  }, [])

  return (
    <Field type="text" name={FormFieldNames.Educatable}>
      {({ input, meta }) => (
        <>
          <Dropdown
            onChange={e => onChange(e, input.onChange)}
            selected={input.value}
            placeholder="Select group or student"
            error={meta.touched ? meta.error : null}
            buildLabel={option => option.name || option.title}
            buildValue={JSON.stringify}
            buildOption={buildOption}
            disabled={disabled}
            onSearch={onSearch}
            options={options}
            loading={loading}
          />

          {showGroupStudents && input.value.students && input.value.students.length > 0 ? (
            <Form.Text className="mt-2 text-gray-chat field-subtitle">{input.value.students.join(", ")}</Form.Text>
          ) : null}
        </>
      )}
    </Field>
  )
}
