import React from "react"
import cn from "classnames"

const CouponInfo = ({ text, className }) => {
  return (
    <div className={cn("coupon-info", className)}>
      <div className="coupon-info_circle discount-icon"></div>
      <div className="coupon-info_text">{text}</div>
      <div className="coupon-info_note">Discount will be applied when you are charged for the session</div>
    </div>
  )
}

export default CouponInfo
