import React, { useCallback, useContext, useMemo } from "react"
import { FieldNames, formValidate } from "./constants"
import { DataContext, QueryContext } from "../../../../contexts"
import { createConnection } from "../../configurations/domain"
import { Entities } from "../../../../constants"
import { ConnectionForm } from "./form"

export const CreateConnectionForm = ({ onCancel, onSave, rowsClassName, buttonsClassName }) => {
  const { getHookState } = useContext(QueryContext)
  const { scheduleId } = useContext(DataContext)
  const { request, loading } = getHookState(createConnection)

  const onSubmit = useCallback(
    values => {
      const { tutor, ...rest } = values
      request({
        entitiesIds: {
          [Entities.Schedules]: scheduleId,
        },
        data: {
          tutor_id: parseInt(tutor.value, 10),
          ...rest,
        },
        onSuccess: onSave,
      })
    },
    [onSave, request, scheduleId]
  )

  const initialValues = useMemo(
    () => ({
      [FieldNames.Tutor]: void 0,
      [FieldNames.StartDate]: null,
      [FieldNames.EndDate]: null,
      [FieldNames.MaxPaidNoShows]: "",
      [FieldNames.Rate]: "",
      [FieldNames.Position]: null,
    }),
    []
  )

  return (
    <ConnectionForm
      initialValues={initialValues}
      validate={formValidate({ checkPositionField: true, checkTutorField: true })}
      showTutorField
      showPositionField
      loading={loading}
      rowsClassName={rowsClassName}
      buttonsClassName={buttonsClassName}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}
