import { ConfigurationContext, QueryContext, QueryContextState } from "@/contexts"
import useQueryList from "@/hooks/useQueryList"
import React, { useCallback, useContext } from "react"

export const QueryProvider = ({ children, useHandlersBuilder, connectToParent = false }) => {
  const { current: configs } = useContext(ConfigurationContext)
  const handlers = useHandlersBuilder()

  const [queryListState, getQueryState] = useQueryList({ configs, handlers, connectToParent })

  const getLoading = useCallback(params => getQueryState(params)?.loading, [getQueryState])
  const getRequest = useCallback(params => getQueryState(params)?.request, [getQueryState])
  const getFailed = useCallback(params => getQueryState(params)?.failed, [getQueryState])
  const getRetry = useCallback(params => getQueryState(params)?.retry, [getQueryState])
  const getHookState = useCallback(
    params => {
      const hookState = getQueryState(params)
      if (!hookState || Object.keys(hookState).length === 0) return {}
      else {
        const { loading, request, failed, complete } = hookState
        return { loading, request, failed, complete }
      }
    },
    [getQueryState]
  )

  return (
    <QueryContextState.Provider value={queryListState}>
      <QueryContext.Provider value={{ getQueryState, getHookState, getLoading, getRequest, getFailed, getRetry }}>
        {children}
      </QueryContext.Provider>
    </QueryContextState.Provider>
  )
}
