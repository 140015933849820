import { Outlet, useParams } from "react-router-dom"
import React, { useContext, useEffect, useMemo } from "react"
import moment from "moment/moment"
import { ReportProviders } from "../../providers/providers"
import { useReportRenderReady } from "./use_report_render_ready"
import { DataContextActions } from "../../../../contexts"

export const ReportNavigation = () => {
  const { report_date } = useParams()
  const validReportDate = report_date && moment(report_date).isValid() ? moment(report_date).format("YYYY-MM-DD") : null
  const ready = useReportRenderReady(validReportDate)

  const reportInitial = useMemo(
    () => ({
      report_date: validReportDate,
    }),
    [validReportDate]
  )

  const { updateState } = useContext(DataContextActions)

  useEffect(
    () => () => {
      updateState("report_date", void 0)
    },
    [updateState]
  )

  if (!ready) return null

  return (
    <ReportProviders initial={reportInitial}>
      <Outlet />
    </ReportProviders>
  )
}
