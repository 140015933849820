export const resetSpecificFieldData =
  ({ name, value }) =>
  (_, state, { changeValue }) => {
    changeValue(state, name, () => value)
  }

export const resetFieldData =
  value =>
  ([name], state, { changeValue }) => {
    changeValue(state, name, () => value)
  }

export const resetArray = resetFieldData([])

export const resetString = resetFieldData("")

export const clearFieldData = resetFieldData(undefined)

export const clearFieldsData = ([names], state, { changeValue }) => {
  names.forEach(name => changeValue(state, name, () => undefined))
}

export const setFieldData = ([name, value], state, { changeValue }) => {
  changeValue(state, name, () => value)
}

export const showFieldError = meta => meta.submitFailed && !!(meta.error || meta.submitError)

export const showTouchedFieldError = meta => (meta.touched || meta.submitFailed) && (meta.error || meta.submitError)

export const getSubmitErrors = ({ response }) => (response && response.data && response.data.errors) || {}

export const setFieldTouched = (args, state) => {
  const [name, touched] = args
  const field = state.fields[name]
  if (field) field.touched = !!touched
}

export const buildId = () => {
  let id = null
  while (id === null) {
    const _id = Math.random().toString(16).slice(2)
    const element = document.getElementById(_id)
    if (!element) id = _id
  }
  return id
}
