import React, { useRef, useEffect } from "react"
import { Form, Col } from "react-bootstrap"
import { Field, useFormState } from "react-final-form"
import DatePicker from "react-datepicker"
import moment from "moment"

import Condition from "../Condition"
import {
  parseDate,
  formatDateTimeKeepZone,
  formatStartOfDayKeepZone,
  formatEndOfDayKeepZone,
} from "../../helpers/dates"
import WeeklyScheduleControl from "./WeeklyScheduleControl"
import DurationField from "../blocks/DurationField"
import { valueOrNull } from "../../helpers/formatters"

export const getParentSessionMinTime = value =>
  moment(value || new Date())
    .hour(7)
    .minute(0)
    .toDate()

export const getParentSessionMaxTime = value =>
  moment(value || new Date())
    .hour(22)
    .minute(0)
    .toDate()

const SessionSchedule = ({ timeZone, disabled, onChange, labelClass, endDateRequired, disableRecurrence }) => {
  const changeHandler = useRef(() => {})
  // Temp changes
  const { values } = useFormState({
    onChange: ({ values }) => {
      if (changeHandler.current) {
        return changeHandler.current(values || {})
      }
    },
  })
  // console.log(values)
  useEffect(() => {
    changeHandler.current = onChange
    return () => (changeHandler.current = () => {})
  })

  const labelClassName = labelClass || "col-lg-6"

  return (
    <>
      <Form.Row>
        <Form.Label column className={labelClassName}>
          Repetition
        </Form.Label>
        <Form.Group
          as={Col}
          lg={18}
          className="d-flex align-items-start align-items-md-center mb-0 flex-column flex-md-row"
        >
          <Field
            name="recurrence"
            initialValue={values.recurrence || false}
            type="radio"
            value={false}
            parse={value => (typeof value === "boolean" ? value : value === "true")}
          >
            {({ input }) => {
              return (
                <Form.Check
                  {...input}
                  id="not-recurrence"
                  disabled={disabled || disableRecurrence}
                  className="text-nowrap mr-5"
                  label="Does not repeat"
                  custom
                />
              )
            }}
          </Field>
          <Field
            name="recurrence"
            type="radio"
            value={true}
            parse={value => (typeof value === "boolean" ? value : value === "true")}
          >
            {({ input }) => {
              return (
                <Form.Check
                  {...input}
                  id="weekly"
                  disabled={disabled || disableRecurrence}
                  className="text-nowrap"
                  label="Weekly"
                  custom
                />
              )
            }}
          </Field>
        </Form.Group>
      </Form.Row>
      <hr className="mt-2 mb-3" />

      <Condition when="recurrence" is={true}>
        <WeeklyScheduleControl disabled={disabled} labelClass={labelClass} />
        <hr className="mt-0 mb-3" />
      </Condition>

      <Condition when="recurrence" is={true}>
        <Form.Row>
          <Form.Label column className={labelClassName}>
            Start
          </Form.Label>
          <Form.Group as={Col} lg={7} className="d-flex flex-column">
            <Field name="started_at">
              {({ input }) => (
                <DatePicker
                  placeholderText="Date"
                  selected={parseDate(input.value)}
                  disabled={disabled}
                  onChange={date => input.onChange(formatStartOfDayKeepZone(date))}
                  // minDate={new Date()}
                  dateFormat="MM/dd/yyyy"
                  customInput={<Form.Control />}
                />
              )}
            </Field>
          </Form.Group>
        </Form.Row>
      </Condition>

      <Condition when="recurrence" is={false}>
        <Form.Row>
          <Form.Label column className={labelClassName}>
            Start
          </Form.Label>
          <Form.Group as={Col} lg={7} className="d-flex flex-column">
            <Field name="started_at">
              {({ input }) => (
                <DatePicker
                  placeholderText="Date"
                  selected={parseDate(input.value)}
                  disabled={disabled}
                  onChange={date => input.onChange(formatDateTimeKeepZone(date))}
                  // minDate={new Date()}
                  dateFormat="MM/dd/yyyy"
                  customInput={<Form.Control />}
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group as={Col} lg={5}>
            <Field name="started_at">
              {({ input }) => (
                <div className="-hide-disabled-times">
                  <DatePicker
                    placeholderText="Time"
                    selected={parseDate(input.value)}
                    disabled={disabled}
                    onChange={date => input.onChange(formatDateTimeKeepZone(date))}
                    dateFormat="h:mm aa"
                    customInput={<Form.Control />}
                    showTimeSelect
                    showTimeSelectOnly
                    minTime={getParentSessionMinTime(input.value)}
                    maxTime={getParentSessionMaxTime(input.value)}
                    timeIntervals={15}
                    timeCaption="Time"
                  />
                </div>
              )}
            </Field>
          </Form.Group>
          <Col lg={6} className="align-self-center mb-3">
            {timeZone}
          </Col>
        </Form.Row>
      </Condition>

      <Condition when="recurrence" is={true}>
        <Form.Row>
          <Form.Label column className={labelClassName}>
            End
          </Form.Label>
          <Form.Group as={Col} lg={7} className="d-flex flex-column">
            <Field name="ended_at">
              {({ input }) => (
                <DatePicker
                  placeholderText="Date"
                  selected={parseDate(input.value)}
                  disabled={disabled}
                  required={endDateRequired}
                  onChange={date => input.onChange(formatEndOfDayKeepZone(date))}
                  minDate={values.started_at ? new Date(values.started_at) : new Date()}
                  dateFormat="MM/dd/yyyy"
                  customInput={<Form.Control />}
                />
              )}
            </Field>
          </Form.Group>
          {!endDateRequired && (
            <Col lg={8}>
              <small>It&apos;s good to plan until the end of the school year.</small>
              <br />
              <small>You can cancel later at any time.</small>
            </Col>
          )}
        </Form.Row>
      </Condition>

      <Condition when="recurrence" is={false}>
        <Form.Row>
          <Form.Label column className={labelClassName}>
            Duration
          </Form.Label>
          <Form.Group as={Col} lg={5}>
            <Field name="duration" parse={valueOrNull}>
              {({ input }) => <DurationField {...input} disabled={disabled} placeholder="Pick duration" />}
            </Field>
          </Form.Group>
        </Form.Row>
      </Condition>
    </>
  )
}

export default SessionSchedule
