import React from "react"
import apiClient from "../../../../common/apiClient"
import Alerter from "../../../../common/alerter"
import { RECEIVE_SESSIONS_OPTIONS } from "../../../../common/constants"
import CreateOrUpdateForm from "../actions/create_or_update_form"

export const SchoolAdminEditPage = ({ schoolAdmin, receiveSessions }) => {
  const onSubmit = async (event, id, email, firstName, lastName, receiveSessions) => {
    event.preventDefault()

    const school_admin = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      receive_session_reports: receiveSessions === RECEIVE_SESSIONS_OPTIONS.YES,
    }

    try {
      await apiClient.put(`/api/v2/school_admins/${id}`, school_admin)
      Alerter.success("School admin updated successfully")
      window.location.replace("/school_admin/school_admins")
    } catch (error) {
      Alerter.error(error.response.data.error)
    }
  }

  return (
    <CreateOrUpdateForm
      title={"Edit School Admin"}
      onSubmit={onSubmit}
      initialValues={{
        schoolAdmin,
        receiveSessions,
      }}
    />
  )
}
