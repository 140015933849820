import { useContext, useMemo } from "react"
import { DataContextActions } from "../contexts"

export const useBaseHandlersBuilder = () => {
  const { updateState } = useContext(DataContextActions)
  return useMemo(
    () => ({
      // onComplete, onSuccess, onFailure, onStoreUpdate
      onSuccess: updateState,
    }),
    [updateState]
  )
}

export default useBaseHandlersBuilder
