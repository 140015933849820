import { CardContent, CardHeader, Skeleton } from "@mui/material"
import React from "react"
import { StyledCard } from "./StyledComponents"

export const LoadingSkeleton = () => (
  <StyledCard>
    <CardHeader>
      <Skeleton className="h-8 w-3/4" />
      <Skeleton className="h-6 w-1/2 mt-2" />
    </CardHeader>
    <CardContent>
      <Skeleton className="h-4 w-full mt-4" />
      <Skeleton className="h-4 w-full mt-2" />
      <Skeleton className="h-4 w-full mt-2" />
      <Skeleton className="h-10 w-full mt-4" />
    </CardContent>
  </StyledCard>
)
