import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import { Button, Form } from "react-bootstrap"
import { postAndRedirect } from "../../common/apiClient"
import { BREAK_REASONS } from "../../common/constants"
import Reasons from "../break_modal/Reasons"

const CancelUpcomingModal = ({ url, nextUrl, payload }) => {
  const [show, setShow] = useState(false)
  const [reasonIndex, setReasonIndex] = useState(0)
  const [otherReason, setOtherReason] = useState("")
  const [breakLink, setBreakLink] = useState(false)

  const onRemove = () => {
    const data = payload || {}
    data.reason = reasonIndex !== -1 ? BREAK_REASONS[reasonIndex] : otherReason || "Other"
    data.break_link = breakLink
    postAndRedirect(url, nextUrl, data)
  }

  const closeModal = () => {
    setShow(false)
  }

  return (
    <>
      <Button
        variant="outline-danger"
        block
        onClick={() => {
          setShow(true)
        }}
      >
        Cancel upcoming sessions
      </Button>

      <Modal
        animation={false}
        size="md"
        scrollable={true}
        show={show}
        onHide={closeModal}
        dialogClassName="programs-modal"
      >
        <Modal.Header className="justify-content-center" closeButton>
          <Modal.Title className="flex-grow-1 text-center">Cancel tutoring sessions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>No fees applied. Please select reason why you want to cancel the sessions.</p>
          <Reasons
            reasonIndex={reasonIndex}
            setReasonIndex={setReasonIndex}
            otherReason={otherReason}
            setOtherReason={setOtherReason}
          />

          <Form.Check
            type="checkbox"
            id="cancel-upcoming"
            className={reasonIndex === 0 ? "invisible" : "visible"}
            custom
          >
            <Form.Check.Input
              type="checkbox"
              checked={breakLink}
              onChange={() => {
                setBreakLink(!breakLink)
              }}
            />
            <Form.Check.Label className="pl-2">
              <div className="font-weight-medium">⚠️ I don’t want to work with this tutor anymore</div>
              <div>Tutor will no longer be able to create sessions for your student</div>
            </Form.Check.Label>
          </Form.Check>
        </Modal.Body>
        <Modal.Footer className="justify-content-around">
          <Button variant="outline-primary" onClick={onRemove}>
            <span className="d-none d-md-block">Cancel upcoming sessions</span>
            <span className="d-block d-md-none">Submit</span>
          </Button>
          <Button variant="link" className="btn-link-primary px-3" onClick={closeModal}>
            Back to session
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CancelUpcomingModal
