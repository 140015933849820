import { find, findIndex, remove } from "lodash"
import React, { useState } from "react"
import { Col, Form } from "react-bootstrap"
import AsyncAutocompleteField from "../AsyncAutocompleteField"
import { RedSmallCloseButton } from "../SmallCloseButton"

const InlineStudentsList = ({
  initList,
  labelValue,
  labelClass,
  modelName,
  autoCompleteSource,
  autoCompleteParams,
  canEdit = true,
}) => {
  const [list, setList] = useState(initList)

  const onSelect = newEl => {
    if (findIndex(list, exEl => exEl.value === newEl.value) === -1) {
      const newList = [...list, newEl]
      setList(newList)
    }
  }

  const deleteEl = el => {
    const exEl = find(list, exEl => exEl.value === el.value)
    if (exEl.id) {
      exEl._destroy = 1
    } else {
      remove(list, exEl => exEl.value === el.value)
    }
    setList([...list])
  }

  return (
    <React.Fragment>
      <Form.Row>
        <Form.Label column className={labelClass}>
          {labelValue}
        </Form.Label>
        <Form.Group
          as={Col}
          sm={17}
          md={14}
          lg={10}
          xl={8}
          className="d-flex align-items-center mb-1 flex-wrap col-form-input"
        >
          {canEdit && (
            <AsyncAutocompleteField
              source={autoCompleteSource}
              externalParams={autoCompleteParams}
              onSelect={onSelect}
              placeholder="Select student to add"
              clearAfterSelect={true}
            />
          )}
          {list.map(el => {
            if (el._destroy) {
              return (
                <>
                  <input type="hidden" name={`${modelName}[id]`} value={el.id} />
                  <input type="hidden" name={`${modelName}[_destroy]`} value="1" />
                </>
              )
            } else {
              return (
                <p key={el.value} className="form-control-plaintext">
                  <input type="hidden" name={`${modelName}[id]`} value={el.id} />
                  <input type="hidden" name={`${modelName}[student_id]`} value={el.value} />
                  {el.title}
                  {canEdit && (
                    <RedSmallCloseButton
                      className="float-right"
                      onClose={() => {
                        deleteEl(el)
                      }}
                    />
                  )}
                </p>
              )
            }
          })}
        </Form.Group>
      </Form.Row>
    </React.Fragment>
  )
}

export default InlineStudentsList
