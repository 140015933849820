import { ConfigurationContext } from "@/contexts"
import React, { useContext, useMemo } from "react"

const getInitialConfig = config => config.filter(({ initialParamsBuilder }) => Boolean(initialParamsBuilder))

export const ConfigurationProvider = ({ config = [], children }) => {
  const { common = [], current: parent = [] } = useContext(ConfigurationContext)
  const initialConfig = useMemo(() => getInitialConfig(config), [config])
  const contextValue = useMemo(
    () => ({
      common: [...common, ...config],
      initial: initialConfig,
      parent,
      current: config,
    }),
    [common, config, initialConfig, parent]
  )

  return <ConfigurationContext.Provider value={contextValue}>{children}</ConfigurationContext.Provider>
}
