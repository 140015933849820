import React from "react"
import { requiredClass } from "../helpers/profile_form"

const Input = ({ input, meta, label, isRequired = false, children, type = "text" }) => {
  return (
    <>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">
          {label}
          {isRequired && <sup>*</sup>}
        </label>
        <div className="col-lg-9">
          <div className="input-group">
            <input {...input} type={type} className={`form-control ${requiredClass(meta)}`} />
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default Input
