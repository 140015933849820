import React from "react"
import { DomainProvider } from "../../providers"
import { useApiInitialPath } from "../../hooks/useApiPath"
import useBaseHandlersBuilder from "../../hooks/useBaseHandlersBuilder"
import { Entities, Methods } from "../../constants"

const getConnections = {
  entities: [Entities.User, Entities.Connections],
  alias: "connections",
  initialParamsBuilder: () => {},
}

const getUserDashboard = {
  entities: [Entities.User, Entities.Dashboard],
  alias: "dashboard",
  initialParamsBuilder: () => {},
}

export const getAvailableOpenings = {
  entities: [Entities.User],
  action: "available_openings",
  alias: "availableOpenings",
}

export const hideOpening = {
  entities: [Entities.Tutor, Entities.Dashboard],
  action: "hide_opening",
  method: Methods.Post,
  skipStorage: true,
  next: [getAvailableOpenings],
}

export const applyTutor = {
  entities: [Entities.Tutor, Entities.Dashboard],
  action: "apply_for_opportunity",
  method: Methods.Post,
  skipStorage: true,
  next: [getAvailableOpenings],
}

const connectionsConfig = [getConnections]
const userDashboardConfig = [getUserDashboard]
const tutorDashboardConfig = [getAvailableOpenings, hideOpening]

export const ConnectionsProvider = ({ children }) => {
  return (
    <DomainProvider
      config={connectionsConfig}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      {children}
    </DomainProvider>
  )
}

export const UserDashboardProvider = ({ children }) => {
  return (
    <DomainProvider
      config={userDashboardConfig}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      {children}
    </DomainProvider>
  )
}

export const TutorDashboardProvider = ({ children }) => {
  return (
    <DomainProvider
      config={tutorDashboardConfig}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <DomainProvider connectToParentStore config={[applyTutor]} useHandlersBuilder={useBaseHandlersBuilder}>
        {children}
      </DomainProvider>
    </DomainProvider>
  )
}
