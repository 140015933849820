import React from "react"
import WorkingHoursCalendar from "./WorkingHoursCalendar"

const CalendarPreview = ({ dataSources: { user, isParent } }) => (
  <WorkingHoursCalendar
    value={user.profile.working_hours}
    isParent={isParent}
    editMode={false}
    FullCalendarProps={{ height: "auto" }}
  />
)

export default CalendarPreview
