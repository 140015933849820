export const routerOptions = Object.freeze({ basename: "/openings" })

export const OpeningStatuses = Object.freeze({
  Draft: "draft",
  Active: "active",
  Closed: "closed",
})

export const OpeningTutoringTypes = Object.freeze({
  Groups: "many_sessions",
  Individual: "one_session",
})

export const TutorPositionTypes = Object.freeze({
  Main: "main",
  Substitute: "substitute",
})
