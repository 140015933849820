export const INITIAL_PAGINATION = { pageSize: 20, page: 0, paginate: true }

export const FILTER_API_PARAM_MAP = {
  name: "name",
  grades: "grades",
  schools: "schools",
  parent: "parent",
  groups: "groups",
  assignments: "assignments",
  statuses: "status",
}
