import { omit } from "lodash"
import { SELECT, DESELECT, LOADING, COPY_TO_ALL_STUDENTS } from "./constants"

export default (state, action) => {
  switch (action.type) {
    case SELECT:
      return {
        ...state,
        selectedSubskills: {
          ...state.selectedSubskills,
          [action.payload.id]: action.payload,
        },
      }
    case DESELECT:
      return {
        ...state,
        selectedSubskills: omit(state.selectedSubskills, [action.payload.id]),
      }
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case COPY_TO_ALL_STUDENTS:
      return {
        ...state,
        copyToAllStudents: action.payload,
      }
    default:
      return state
  }
}
