import React, { useCallback, useContext, useMemo, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { DataContext, QueryContext, TableSelectionContext } from "../../../../contexts"
import { StatelessStudentList } from "./students_list"
import { addScheduleToOpening } from "../../configurations/domain"
import { GroupName, StudentsGrades, StudentsNames, StudentsSpecialNeeds } from "./columns"
import { TableSelectionProvider } from "../../../table"
import { Entities } from "../../../../constants"
import { EmptyGroupsOpeningDataProvider } from "../../providers/students_page"

const columns = [GroupName, StudentsNames, StudentsGrades, StudentsSpecialNeeds]

const GroupsModalContent = ({ onStudentsAdd }) => {
  const { schoolSchedules, opening, groups } = useContext(DataContext)
  const { getQueryState } = useContext(QueryContext)
  const { request: addSchedules } = getQueryState(addScheduleToOpening)
  const [searchString, setSearchString] = useState("")
  const { selected = [] } = useContext(TableSelectionContext)

  const onSave = useCallback(
    schedules => {
      addSchedules({
        entitiesIds: {
          [Entities.Openings]: opening.id,
        },
        data: {
          educatables: schedules.map(({ id }) => id),
        },
        onSuccess: onStudentsAdd,
      })
    },
    [addSchedules, opening, onStudentsAdd]
  )

  const filterExisting = useCallback(
    educatables => {
      const existingIds = groups.map(({ educatable }) => educatable.id)
      return educatables.filter(({ id }) => !existingIds.includes(id))
    },
    [groups]
  )

  const filteredSchedules = useMemo(() => {
    if (!schoolSchedules || schoolSchedules.length === 0) {
      return []
    }

    // Filter by search string
    let filtered =
      searchString === ""
        ? schoolSchedules
        : schoolSchedules.filter(({ name }) => name && name.toLowerCase().includes(searchString.toLowerCase()))

    // Filter already added to opening
    filtered = filterExisting(filtered)

    return filtered?.map(educatable => ({ educatable, id: educatable.id }))
  }, [filterExisting, searchString, schoolSchedules])

  if (!schoolSchedules || schoolSchedules.length === 0)
    return (
      <div className="d-flex align-items-center justify-content-center flex-grow-1 min-h-400-px">
        <h1 className="text-gray text-center mb-0 font-weight-normal">No schedules for the school</h1>
      </div>
    )

  return (
    <>
      <Row>
        <Col xs={24} md={12} lg={8}>
          <Form.Control
            type="text"
            placeholder="Filter groups by name"
            className="v-2 mb-4"
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
          />
        </Col>
      </Row>

      <StatelessStudentList data={filteredSchedules} columns={columns} />

      <div className="btrust-dialog__actions -sticky justify-content-end">
        <button className="btn btn-primary" onClick={() => onSave(selected)} disabled={selected.length === 0}>
          Add selected groups
        </button>
      </div>
    </>
  )
}

export const AvailableStudentsList = ({ onStudentsAdd }) => {
  return (
    <EmptyGroupsOpeningDataProvider>
      <TableSelectionProvider selectable checkSelection={(list, item) => list.map(({ id }) => id).includes(item.id)}>
        <GroupsModalContent onStudentsAdd={onStudentsAdd} />
      </TableSelectionProvider>
    </EmptyGroupsOpeningDataProvider>
  )
}
