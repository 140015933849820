import BRTThemeProvider from "@/providers/theme"
import { styled } from "@mui/system"
import { DataGrid } from "@mui/x-data-grid"
import React, { forwardRef } from "react"
import TableSkeletonLoader from "../skeleton_loaders/TableSkeletonLoader"
import GenericNoResults from "./GenericNoResults"
import GenericNoRows from "./GenericNoRows"

const BRTStyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "none",
  "--DataGrid-overlayHeight": "300px",
  "& .MuiTablePagination-displayedRows": {
    padding: 0,
    margin: 0,
  },
  "& .MuiTablePagination-selectLabel": {
    padding: 0,
    margin: 0,
  },
  '& .MuiDataGrid-booleanCell[data-value="true"]': {
    color: theme.palette.success.main,
  },
}))

const BraintrustDataGrid = forwardRef(
  ({ paginationModel, rowSelectionModel, handleRowSelectionModelChange, ...props }, ref) => {
    const { pageSize } = paginationModel

    return (
      <BRTThemeProvider>
        <BRTStyledDataGrid
          ref={ref}
          paginationModel={paginationModel}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleRowSelectionModelChange}
          className="school-admins-students-table bg-white"
          getRowId={row => row.id}
          pageSizeOptions={[20, 50, 100]}
          {...props}
          slots={{
            noRowsOverlay: GenericNoRows,
            noResultsOverlay: GenericNoResults,
            loadingOverlay: TableSkeletonLoader,
            ...props.slots,
          }}
          slotProps={{
            loadingOverlay: {
              rowCount: pageSize,
              columnCount: props.columns.length,
              ...props.slotProps.loadingOverlay,
            },
            ...props.slotProps,
          }}
        />
      </BRTThemeProvider>
    )
  }
)

BraintrustDataGrid.displayName = "BraintrustDataGrid"

export default BraintrustDataGrid
