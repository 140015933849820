import React, { useCallback, Fragment, useState, useContext } from "react"
import { Form as FForm, Field } from "react-final-form"
import { Col, Row, Form } from "react-bootstrap"
import FormRow from "../../components/blocks/FormRow"
import TimePicker from "../../library/timePicker"
import DurationField from "../../components/blocks/DurationField"
import { PropsInjector } from "../PropsInjector"
import useViewport from "../../hooks/useViewport"
import { minutesToHours } from "../../helpers/dates"
import { DataContext, QueryContext } from "../../contexts"
import { formatDateTimeKeepZone } from "../../helpers/dates"
import { createGroupSession, updateGroupSession, deleteGroupSession, cancelGroupSession } from "./configuration"
import { Entities } from "../../constants"
import ShiftSubmit from "../blocks/ShiftSubmit"

import { ReactComponent as Agreement } from "../../assets/images/agreement.svg"
import { ReactComponent as NoPerson } from "../../assets/images/no-person.svg"

const colProps = { sm: 17, md: 18, className: "mb-0 form-input-align flex-column" }
const labelProps = { sm: 7, md: 6, className: "v-2 form-label-align px-0" }

const FieldNames = Object.freeze({
  Time: "started_at",
  Duration: "duration",
  Location: "meeting_format",
  LocationComment: "meeting_info",
})

const formatTypes = [
  { value: "pencil_spaces", label: "Pencil Spaces" },
  { value: "online", label: "Online" },
  { value: "offline", label: "Offline" },
]

const defaultValues = {
  [FieldNames.Time]: "",
  [FieldNames.Duration]: "",
  [FieldNames.Location]: "",
  [FieldNames.LocationComment]: "",
}

const defaultValuesShort = {
  [FieldNames.Duration]: "",
}

const validate =
  ({ maxBillableMin, disableLocation }) =>
  formState => {
    const acc = {}
    const time = formState[FieldNames.Time]
    const duration = formState[FieldNames.Duration]
    const location = formState[FieldNames.Location]
    const comment = formState[FieldNames.LocationComment]
    if (!time) acc[FieldNames.Time] = "Required field"
    if (typeof duration !== "number" && !duration) acc[FieldNames.Duration] = "Required field"
    else if (duration <= 0) acc[FieldNames.Duration] = "Duration should be greater than 0"
    else if (maxBillableMin > 0 && duration > maxBillableMin) {
      const [billableHours, billableMinutes] = minutesToHours(maxBillableMin)
      acc[FieldNames.Duration] = `Sessions can be no longer than ${billableHours > 0 ? `${billableHours}h ` : ""}
    ${billableMinutes > 0 ? `${billableMinutes}min` : ""}`
    }
    if (!disableLocation) {
      if (!location) acc[FieldNames.Location] = "Required field"
      if (!comment) {
        if (location !== "pencil_spaces") acc[FieldNames.LocationComment] = "Required field"
      }
    }
    return acc
  }

const SessionDetailsForm = props => {
  const {
    initialValues,
    onSubmit,
    loading,
    disableLocation,
    maxBillableMin,
    pencilSpaceLink,
    date,
    touched,
    children,
  } = props

  const { isMobileViewport } = useViewport()
  const [billableHours, billableMinutes] = minutesToHours(maxBillableMin)

  const submit = data => {
    if (data[FieldNames.Location] === "pencil_spaces") {
      data[FieldNames.LocationComment] = initialValues[FieldNames.LocationComment]
    }
    onSubmit(data)
  }

  return (
    <FForm
      initialValues={{ ...defaultValues, ...initialValues }}
      validate={validate({ maxBillableMin, disableLocation })}
      onSubmit={submit}
    >
      {({ values, errors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field type="time" name={FieldNames.Time}>
            {({ input, meta }) => (
              <FormRow label="Time" ColProps={colProps} LabelProps={labelProps} className="mb-4">
                <TimePicker
                  {...input}
                  invalid={(touched || meta.touched) && meta.error}
                  date={date}
                  className="v-2 w-118-px"
                  placeholder="Set time"
                >
                  <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                </TimePicker>
              </FormRow>
            )}
          </Field>

          <Field type="text" name={FieldNames.Duration}>
            {({ input, meta }) => (
              <FormRow label="Duration" ColProps={colProps} LabelProps={labelProps} className="mb-2">
                <DurationField
                  {...input}
                  placeholder="1:30"
                  className="v-2 w-118-px"
                  invalid={(touched || meta.touched) && meta.error}
                />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                {(billableHours > 0 || billableMinutes > 0) && !meta.error?.startsWith("Sessions can be no longer") && (
                  <span className="form-control_caption">
                    Sessions can be no longer than {billableHours > 0 ? `${billableHours}h ` : ""}
                    {billableMinutes > 0 ? `${billableMinutes}min` : ""}
                  </span>
                )}
              </FormRow>
            )}
          </Field>

          <FormRow label="Location" ColProps={{ ...colProps, className: "d-flex mb-0" }} LabelProps={labelProps}>
            <Col>
              <Row
                xs={24}
                className={`mb-2 form-input-align mb-0 ${isMobileViewport ? "flex-column" : "align-items-center"}`}
              >
                <Field type="radio" name={FieldNames.Location}>
                  {({ input }) =>
                    formatTypes
                      .filter(({ value }) => {
                        if (value === "pencil_spaces" && !pencilSpaceLink) return false
                        return true
                      })
                      .map(({ value, label }) => (
                        <Form.Check
                          name={FieldNames.Location}
                          type="radio"
                          className={`v-2 ${isMobileViewport ? "mb-2" : "mr-3"}`}
                          id={`${FieldNames.Location}-${value}`}
                          key={value}
                          label={label}
                          custom
                          {...input}
                          value={value}
                          checked={values[FieldNames.Location] === value}
                          disabled={disableLocation}
                        />
                      ))
                  }
                </Field>
              </Row>
              <Row xs={24} className="mt-2">
                {values[FieldNames.Location] !== "pencil_spaces" ? (
                  <Field type="text" name={FieldNames.LocationComment}>
                    {({ input, meta }) => (
                      <Form.Control
                        className="v-2"
                        placeholder={values[FieldNames.Location] === "offline" ? "Address" : "Link"}
                        isInvalid={(touched || meta.touched) && meta.error}
                        disabled={disableLocation}
                        {...input}
                      />
                    )}
                  </Field>
                ) : pencilSpaceLink ? (
                  <div className="form-control v-2 overflow-hidden text-no-wrap text-overflow-ellipsis">
                    <a href={pencilSpaceLink} rel="noreferrer" target="_blank">
                      {pencilSpaceLink}
                    </a>
                  </div>
                ) : null}
              </Row>
            </Col>
          </FormRow>
          <hr className="my-4" />
          <Col>
            <Row className={isMobileViewport ? "flex-column" : ""}>
              <PropsInjector props={{ submitDisabled: loading || Object.keys(errors).length > 0 }}>
                {children}
              </PropsInjector>
            </Row>
          </Col>
        </form>
      )}
    </FForm>
  )
}

const sessionDetailsShortFormValidate =
  ({ maxBillableMin }) =>
  formState => {
    const acc = {}
    const duration = formState[FieldNames.Duration]
    if (typeof duration !== "number" && !duration) acc[FieldNames.Duration] = "Required field"
    else if (duration <= 0) acc[FieldNames.Duration] = "Duration should be greater than 0"
    else if (maxBillableMin > 0 && duration > maxBillableMin) {
      const [billableHours, billableMinutes] = minutesToHours(maxBillableMin)
      acc[FieldNames.Duration] = `Sessions can be no longer than ${billableHours > 0 ? `${billableHours}h ` : ""}
    ${billableMinutes > 0 ? `${billableMinutes}min` : ""}`
    }

    return acc
  }

export const SessionDetailsShortForm = props => {
  const { day, maxBillableMin, onHide } = props
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(updateGroupSession)
  const { isMobileViewport } = useViewport()

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Duration]: day.duration,
  })

  const [hours, minutes] = minutesToHours(day[FieldNames.Duration])
  const [billableHours, billableMinutes] = minutesToHours(maxBillableMin)

  const onSubmit = async formState => {
    setInitialValues(formState)
    const data = {
      group_session: {
        [FieldNames.Time]: day.time,
        [FieldNames.Location]: day.meeting_format,
        [FieldNames.LocationComment]: day.meeting_info,
        ...formState,
      },
    }
    await request({
      entitiesIds: {
        [Entities.GroupSessions]: day.group_session_id,
      },
      data,
      onSuccess: onHide,
    })
  }

  return (
    <FForm
      initialValues={{ ...defaultValuesShort, ...initialValues }}
      validate={sessionDetailsShortFormValidate({ maxBillableMin })}
      onSubmit={onSubmit}
    >
      {({ errors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row as="span" className="mb-4">
            Recorded Pencil Spaces session duration:
            <span className="font-weight-semibold ml-2">
              {hours > 0 ? `${hours}h ` : ""}
              {minutes}min
            </span>
          </Row>
          <Field type="text" name={FieldNames.Duration}>
            {({ input, meta }) => (
              <FormRow label="Duration" ColProps={colProps} LabelProps={labelProps}>
                <DurationField {...input} placeholder="1:30" className="v-2 w-118-px" invalid={meta.error} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                {(billableHours || billableMinutes) && !meta.error?.startsWith("Sessions can be no longer") && (
                  <span className="form-control_caption">
                    Sessions can be no longer than {billableHours > 0 ? `${billableHours}h ` : ""}
                    {billableMinutes > 0 ? `${billableMinutes}min` : ""}
                  </span>
                )}
              </FormRow>
            )}
          </Field>
          <hr className="my-4" />
          <Col>
            <Row className={isMobileViewport ? "flex-column" : ""}>
              <button
                className="btn btn-primary mr-2"
                type="submit"
                disabled={loading || Object.keys(errors).length > 0}
              >
                Save
              </button>
              <button
                className={`btn btn-link btn-link-default ${isMobileViewport ? "my-4" : "mr-3"}`}
                type="button"
                onClick={onHide}
              >
                Dismiss
              </button>
            </Row>
          </Col>
        </form>
      )}
    </FForm>
  )
}

export const CreateSessionDetailsForm = ({ onHide, day, maxBillableMin }) => {
  const { groupSessionId, statusInfo } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(createGroupSession)

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Time]: "",
    [FieldNames.Duration]: "",
    [FieldNames.Location]: statusInfo.schedule.meeting_format || "online",
    [FieldNames.LocationComment]: statusInfo.schedule.meeting_info || "",
  })
  const { isMobileViewport } = useViewport()

  const onSubmit = formState => {
    setInitialValues(formState)
    request({
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
      data: {
        group_session: {
          ...formState,
          [FieldNames.Time]: formatDateTimeKeepZone(formState[FieldNames.Time]),
        },
      },
      onSuccess: onHide,
    })
  }

  const Footer = useCallback(
    ({ submitDisabled }) => {
      return (
        <>
          <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
            Create
          </button>
          <button
            className={`btn btn-link btn-link-default ${isMobileViewport ? "my-4" : "ml-3"}`}
            type="button"
            onClick={onHide}
          >
            Dismiss
          </button>
        </>
      )
    },
    [onHide, isMobileViewport]
  )
  return (
    <SessionDetailsForm
      date={day.date}
      loading={loading}
      pencilSpaceLink={statusInfo.schedule.pencil_space_link}
      initialValues={initialValues}
      maxBillableMin={maxBillableMin}
      onSubmit={onSubmit}
    >
      <Footer />
    </SessionDetailsForm>
  )
}

export const EditSessionDetailsForm = ({ onHide, day, maxBillableMin }) => {
  const { statusInfo } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(updateGroupSession)

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Time]: new Date(formatDateTimeKeepZone(day.time)),
    [FieldNames.Duration]: day.duration,
    [FieldNames.Location]: day.meeting_format,
    [FieldNames.LocationComment]: day.meeting_info,
  })
  const { isMobileViewport } = useViewport()

  const onSubmit = formState => {
    setInitialValues(formState)
    const data = {
      ...formState,
      [FieldNames.Time]: formatDateTimeKeepZone(formState[FieldNames.Time]),
    }
    request({
      entitiesIds: {
        [Entities.GroupSessions]: day.group_session_id,
      },
      data,
      onSuccess: onHide,
    })
  }

  const Footer = useCallback(
    ({ submitDisabled }) => {
      return (
        <>
          <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
            Save
          </button>
          <button
            className={`btn btn-link btn-link-default ${isMobileViewport ? "my-4" : "ml-3"}`}
            type="button"
            onClick={onHide}
          >
            Cancel
          </button>
        </>
      )
    },
    [onHide, isMobileViewport]
  )
  return (
    <SessionDetailsForm
      date={day.date}
      loading={loading}
      initialValues={initialValues}
      maxBillableMin={maxBillableMin}
      onSubmit={onSubmit}
      pencilSpaceLink={statusInfo.schedule.pencil_space_link}
      touched
    >
      <Footer />
    </SessionDetailsForm>
  )
}

export const UnplannedSessionDetailsForm = ({ onHide, day, maxBillableMin }) => {
  const { groupSessionId, statusInfo } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(createGroupSession)

  const [initialValues, setInitialValues] = useState({
    [FieldNames.Time]: new Date(formatDateTimeKeepZone(day.time)),
    [FieldNames.Duration]: day.ps_duration,
    [FieldNames.Location]: "pencil_spaces",
    [FieldNames.LocationComment]: statusInfo.schedule.meeting_info || "",
  })
  const onSubmit = async data => {
    setInitialValues(data)
    await request({
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
      data: {
        group_session: {
          ...data,
          [FieldNames.Time]: formatDateTimeKeepZone(data[FieldNames.Time]),
          [FieldNames.Location]: "pencil_spaces",
          [FieldNames.LocationComment]: statusInfo.schedule.meeting_info || "",
        },
      },
      onSuccess: onHide,
    })
  }

  const Footer = useCallback(({ submitDisabled }) => {
    return (
      <>
        <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
          Confirm
        </button>
        {/* <button
            className={`btn btn-outline-primary ${isMobileViewport ? "mt-4" : "ml-3"}`}
            type="button"
            onClick={onHide}
          >
            Ignore
          </button> */}
      </>
    )
  }, [])
  return (
    <>
      <Row className="mb-4">
        <span>Pencil Spaces tracked an unplanned session time.</span>
        <br />
        {/* <span>Please confirm or dismiss the tracked data.</span> */}
        <span>Please confirm the tracked data.</span>
      </Row>
      <SessionDetailsForm
        date={day.date}
        loading={loading}
        initialValues={initialValues}
        maxBillableMin={maxBillableMin}
        onSubmit={onSubmit}
        pencilSpaceLink={statusInfo.schedule.pencil_space_link}
        disableLocation
        touched
      >
        <Footer />
      </SessionDetailsForm>
    </>
  )
}

const CancelTypes = {
  Cancelled: "cancelled",
  CancelledNoShow: "cancelled_no_show",
  Delete: "delete",
}

const cancelAnswers = [
  { value: CancelTypes.Cancelled, text: "Session was canceled<br/>by agreement", icon: <Agreement /> },
  { value: CancelTypes.CancelledNoShow, text: "Student<br/>didn’t show up", icon: <NoPerson /> },
]

const CancelSessionFields = {
  Status: "status",
  CancelationReason: "cancellation_reason",
}

const cancelSessionDefaultValue = {
  [CancelSessionFields.Status]: "",
  [CancelSessionFields.CancelationReason]: "",
}

const cancelFormValidate = data => {
  const acc = {}
  if (data[CancelSessionFields.Status] === CancelTypes.Cancelled) {
    if (
      !data[CancelSessionFields.CancelationReason] ||
      data[CancelSessionFields.CancelationReason]?.trim()?.length === 0
    )
      acc[CancelSessionFields.CancelationReason] = "Required field"
  }
  return acc
}

export const CancelSessionForm = ({ day, maxCancelCount, currentCancelCount, onHide }) => {
  const { isMobileViewport } = useViewport()
  const { groupSessionId: original_session_id } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request: deleteRequest, loading: deleteLoading } = getHookState(deleteGroupSession)
  const { request: cancelRequest, loading: cancelLoading } = getHookState(cancelGroupSession)

  const [initialValues, setInitialValues] = useState(cancelSessionDefaultValue)

  const leftCancelCount = maxCancelCount - currentCancelCount > 0 ? maxCancelCount - currentCancelCount : 0

  const submit = async data => {
    if (data[CancelSessionFields.Status] === CancelTypes.CancelledNoShow) {
      data[CancelSessionFields.CancelationReason] = null
    }

    setInitialValues(data)
    await cancelRequest({
      entitiesIds: {
        [Entities.GroupSessions]: day.group_session_id,
      },
      data: { group_session: data },
      onSuccess: onHide,
    })
  }

  const onDelete = async () => {
    await deleteRequest({
      entitiesIds: {
        [Entities.GroupSessions]: day.group_session_id,
      },
      params: { original_session_id },
      onSuccess: onHide,
    })
  }

  return (
    <FForm initialValues={initialValues} validate={cancelFormValidate} onSubmit={submit}>
      {({ values, errors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Col>
            <Row xs={24} className="mb-3">
              <Field type="radio" name={CancelSessionFields.Status}>
                {({ input }) =>
                  cancelAnswers.map(({ value, text, icon }) => (
                    <Fragment key={value}>
                      <input
                        id={`${CancelSessionFields.Status}-${value}`}
                        {...input}
                        value={value}
                        checked={values[CancelSessionFields.Status] === value}
                        hidden
                      />
                      <label className="cancel-session-radio-label" htmlFor={`${CancelSessionFields.Status}-${value}`}>
                        <div className="cancel-session-radio-label-icon mb-1">{icon}</div>
                        <span className="cancel-session-radio-label-text" dangerouslySetInnerHTML={{ __html: text }} />
                      </label>
                    </Fragment>
                  ))
                }
              </Field>
            </Row>
            <Row className="mb-3">
              <Col className="p-0 d-flex flex-row-reverse">
                <Field type="radio" name={CancelSessionFields.Status}>
                  {({ input }) => (
                    <>
                      <input
                        id={`${CancelSessionFields.Status}-${CancelTypes.Delete}`}
                        {...input}
                        value={CancelTypes.Delete}
                        checked={values[CancelSessionFields.Status] === CancelTypes.Delete}
                        hidden
                      />
                      <label className="mb-0" htmlFor={`${CancelSessionFields.Status}-${CancelTypes.Delete}`}>
                        <span
                          className={`btn btn-link ${
                            values[CancelSessionFields.Status] === CancelTypes.Delete ? "disabled" : ""
                          }`}
                        >
                          Delete session
                        </span>
                      </label>
                    </>
                  )}
                </Field>
              </Col>
            </Row>
            {values[CancelSessionFields.Status] === CancelTypes.CancelledNoShow && (
              <Row xs={24} className="mb-4">
                <div className="cancel-session-description-wrapper p-4">
                  <div
                    className={`d-flex mb-3 justify-content-between ${
                      isMobileViewport ? "align-items-start" : "align-items-center"
                    }`}
                  >
                    <span className="cancel-session-description-title">
                      {leftCancelCount === 0 && (
                        <span>
                          Unfortunately, you have no any paid no-show session. Canceled session will not be paid
                        </span>
                      )}
                      {leftCancelCount > 0 && (
                        <span>
                          {leftCancelCount === 1 ? "Only 1" : leftCancelCount} paid{" "}
                          <span className="text-no-wrap">no-show</span> session{leftCancelCount !== 1 && "s"} left
                        </span>
                      )}
                    </span>
                    <div className={`cancel-session-description-counters ${leftCancelCount === 0 ? "-problem" : ""}`}>
                      {leftCancelCount} of {maxCancelCount}
                    </div>
                  </div>
                  <div className="cancel-session-description-text mb-3">
                    This seems to be a problem with scheduling. Please contact parents / guardians to adjust the
                    schedule or let us know if the session series should be canceled.
                  </div>
                  {/* <div className="cancel-session-description-text">Representative contact: <a className="font-weight-semibold" href="tel:+18085550111">+1 808 555 0111 (Alison Grippo)</a></div> */}
                </div>
              </Row>
            )}
            {values[CancelSessionFields.Status] === CancelTypes.Cancelled && (
              <Row xs={24} className="mb-4">
                <div className="cancel-session-description-wrapper px-4 py-3">
                  <div className="cancel-session-description-text -sub mb-3">
                    Please be advised that the session will not be paid.
                    <br />
                    <span className="font-weight-semibold">Specify the reason of canceling below:</span>
                  </div>
                  <Field type="text" name={CancelSessionFields.CancelationReason}>
                    {({ input, meta }) => (
                      <Form.Control
                        as="textarea"
                        className="v-2 mb-3"
                        placeholder="Please provide a reason"
                        required
                        isInvalid={meta.touched && meta.error}
                        {...input}
                      />
                    )}
                  </Field>
                  {/* <div className="cancel-session-description-text -tip">Would you like to cancel the series of sessions? <a className="font-weight-semibold" href="#">Delete the series of sessions</a></div> */}
                </div>
              </Row>
            )}
          </Col>
          {values[CancelSessionFields.Status] === CancelTypes.Delete && (
            <Row xs={24} className="mb-4">
              <div className="cancel-session-description-wrapper p-4">
                <div
                  className={`d-flex mb-3 justify-content-between ${
                    isMobileViewport ? "align-items-start" : "align-items-center"
                  }`}
                >
                  <span className="cancel-session-description-title">
                    Delete a session only if it was added by mistake
                  </span>
                </div>
                <div className="cancel-session-description-text">
                  Do not delete the session if the student didn’t show up or it was canceled by agreement
                </div>
              </div>
            </Row>
          )}
          {values[CancelSessionFields.Status] && (
            <Col>
              <Row
                className={`${isMobileViewport ? "flex-column justify-content-stretch" : "justify-content-between"}`}
              >
                {values[CancelSessionFields.Status] === CancelTypes.Cancelled && (
                  <button
                    className="btn mr-2 cancel-session-submit"
                    type="submit"
                    disabled={Object.keys(errors).length > 0}
                  >
                    Confirm canceling
                  </button>
                )}
                {values[CancelSessionFields.Status] === CancelTypes.CancelledNoShow && (
                  <Col xs={24} lg={14} className="px-0">
                    <ShiftSubmit disabled={cancelLoading} onSubmit={() => submit(values)} />
                  </Col>
                )}
                {values[CancelSessionFields.Status] === CancelTypes.Delete && (
                  <Col xs={24} lg={14} className="px-0">
                    <ShiftSubmit disabled={deleteLoading} onSubmit={onDelete} className="-danger" />
                  </Col>
                )}
                <button
                  className={`btn btn-link btn-link-default ${isMobileViewport ? "mt-4" : ""}`}
                  type="button"
                  disabled={cancelLoading || deleteLoading}
                  onClick={onHide}
                >
                  Dismiss
                </button>
              </Row>
            </Col>
          )}
        </form>
      )}
    </FForm>
  )
}
