/* eslint-disable */

document.addEventListener("turbolinks:load", () => {
  var form = document.getElementById("component-stripe")

  if (!form) return

  const { stripepk: stripeKey } = form.dataset

  if (!stripeKey) {
    console.error("stripe key is not set")
    return
  }
  // Create a Stripe client.
  var stripe = Stripe(stripeKey)

  // Create an instance of Elements.
  var elements = stripe.elements()

  // Custom styling can be passed to options when creating an Element.
  // (Note that this demo uses a wider set of styles than the guide below.)
  var style = {
    base: {
      fontFamily: '"Poppins", "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  }

  function inputChange(elementID, event) {
    var displayError = document.getElementById(elementID)
    if (event.error) {
      displayError.textContent = event.error.message
    } else {
      displayError.textContent = ""
    }
  }

  function validateCardholderName(target, elementID) {
    var displayError = document.getElementById(elementID)
    if (!target.value) {
      displayError.textContent = "You must enter a name."
      target.classList.add("StripeElement--invalid")
      return false
    } else if (!target.value.match(/^\s*[\w-]+\s+[\w-]+\s*/)) {
      displayError.textContent = "You must enter first and last name divided by space."
      target.classList.add("StripeElement--invalid")
      return false
    }
    target.classList.remove("StripeElement--invalid")
    return true
  }

  function inputNameChange(elementID, event) {
    validateCardholderName(event.target, elementID)
  }

  var cardNumber = elements.create("cardNumber", { style: style })
  cardNumber.mount("#card-number")
  cardNumber.addEventListener("change", inputChange.bind(this, "card-number-errors"))

  var cardHolder = document.getElementById("cardholder-name")
  cardHolder.addEventListener("change", inputNameChange.bind(this, "cardholder-name-errors"))

  var cardExpiry = elements.create("cardExpiry", { style: style })
  cardExpiry.mount("#card-expiry")
  cardExpiry.addEventListener("change", inputChange.bind(this, "card-expiry-errors"))

  var cardCvc = elements.create("cardCvc", { style: style })
  cardCvc.mount("#card-cvc")
  cardCvc.addEventListener("change", inputChange.bind(this, "card-cvc-errors"))

  // Handle form submission
  form.addEventListener("submit", function (event) {
    event.preventDefault()
    if (!validateCardholderName(cardHolder, "cardholder-name-errors")) return

    stripe.createToken(cardNumber, { name: cardHolder.value }).then(function (result) {
      if (result.error) {
        // Inform the user if there was an error.
        var errorElement = document.getElementById("card-errors")
        errorElement.textContent = result.error.message
      } else {
        // Send the token to your server.
        stripeTokenHandler(result.token)
      }
    })
  })

  function stripeTokenHandler(token) {
    var hiddenInput = document.createElement("input")
    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "card_token")
    hiddenInput.setAttribute("value", token.id)
    form.appendChild(hiddenInput)

    // Submit the form
    form.submit()
  }
})
