import React, { useState, useEffect } from "react"
import cn from "classnames"
import { Dropdown, Form, Button } from "react-bootstrap"
import { Field, useFormState, useForm } from "react-final-form"
import { isUndefined } from "lodash/fp"

import FormRow from "../blocks/FormRow"
import { formatZip, valueOrNull } from "../../helpers/formatters"
import { showTouchedFieldError } from "../../helpers/forms"
import Condition from "../Condition"
import FormSelect from "../blocks/FormSelect"

const LocationDropdown = ({
  className,
  completed,
  getButtonText,
  options,
  disabled,
  onSubmit,
  drop,
  submitButtonText,
}) => {
  const [showPopper, setShowPopper] = useState(false)

  const { values } = useFormState()
  const form = useForm()

  useEffect(() => {
    if (showPopper && isUndefined(values.online) && form.mutators.setFieldData) {
      form.mutators.setFieldData("online", true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopper])

  const onToggle = show => {
    setShowPopper(show)
    if (onSubmit) onSubmit(values)
  }

  const handleSubmit = () => {
    setShowPopper(false)
    onToggle(false)
  }

  return (
    <Dropdown show={showPopper} drop={drop || "up"} onToggle={onToggle}>
      <Dropdown.Toggle
        variant="outline-dropdown"
        disabled={disabled}
        className={cn(className, completed && "selected")}
      >
        {getButtonText(values)}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-4 min-w-md-400-px max-vw-100 overflow-auto">
        <Field name="online" initialValue={true} type="radio" value={true} parse={value => value === "true"}>
          {({ input }) => (
            <Form.Check {...input} id="online" className="text-nowrap mb-3" label="Online lessons" custom />
          )}
        </Field>
        <Field name="online" type="radio" value={false} parse={value => value === "true"}>
          {({ input }) => <Form.Check {...input} id="offline" className="text-nowrap" label="Offline lessons" custom />}
        </Field>
        <Condition when="online" is={false}>
          <p className="text-nowrap mt-4">Address where sessions will take place</p>

          <Field name="address_1" parse={valueOrNull}>
            {({ input, meta }) => (
              <FormRow label="Address 1" ColProps={{ lg: 18 }}>
                <Form.Control {...input} maxLength="255" isInvalid={showTouchedFieldError(meta)} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="address_2" parse={valueOrNull}>
            {({ input, meta }) => (
              <FormRow label="Address 2" ColProps={{ lg: 18 }}>
                <Form.Control {...input} maxLength="255" isInvalid={showTouchedFieldError(meta)} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="city" parse={valueOrNull}>
            {({ input, meta }) => (
              <FormRow label="City" ColProps={{ lg: 18 }}>
                <Form.Control {...input} maxLength="255" isInvalid={showTouchedFieldError(meta)} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="state" parse={valueOrNull}>
            {({ input, meta }) => (
              <FormRow label="State" ColProps={{ lg: 18 }}>
                <FormSelect
                  {...input}
                  options={options.states}
                  placeholder="Pick state"
                  isInvalid={showTouchedFieldError(meta)}
                />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="zip" parse={formatZip}>
            {({ input, meta }) => (
              <FormRow label="Zip" ColProps={{ lg: 10 }}>
                <Form.Control {...input} maxLength="255" isInvalid={showTouchedFieldError(meta)} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>
        </Condition>
        <Button
          variant="outline-primary"
          size="sm"
          className="mt-4"
          type="button"
          disabled={!completed}
          onClick={handleSubmit}
        >
          {submitButtonText}
        </Button>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LocationDropdown
