export const tutorStatuses = [
  ["Showing your learning levels", "divisions"],
  ["Showing all", "all"],
  ["Hidden by me", "hidden"],
]

export const [[, initialStatus]] = tutorStatuses

export const OpeningStatus = Object.freeze({
  Applied: "applied",
  Recommended: "recommended",
  Approved: "approved",
  Rejected: "rejected",
  None: "none",
})
