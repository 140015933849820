import React from "react"

import LogoImage from "../../../assets/images/logo.svg"

import { ReactComponent as FacebookIcon } from "../../../assets/images/icons/fb.svg"
import { ReactComponent as InstagramIcon } from "../../../assets/images/icons/ig.svg"
import { ReactComponent as LinkedinIcon } from "../../../assets/images/icons/linkedin.svg"
import { ReactComponent as YoutubeIcon } from "../../../assets/images/icons/youtube.svg"
import { ReactComponent as TwitterIcon } from "../../../assets/images/icons/twitter.svg"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-body">
          <div className="footer-company">
            <a href="https://braintrusttutors.com" className="footer-logo">
              <img src={LogoImage} alt="" />
            </a>
            <p>We match highly qualified, certified teachers and specialists with the students who need them most.</p>
          </div>

          <div className="footer-menu">
            <div className="footer-column footer-contacts">
              <h6>Get in Touch!</h6>
              <ul>
                <li>
                  <a href="mailto:hello@braintrusttutors.com">hello@braintrusttutors.com</a>
                </li>
                <li>
                  <a href="mailto:partners@braintrusttutors.com">partners@braintrusttutors.com</a>
                </li>
                <li className=" footer-socials">
                  <a
                    className="footer-social-icon"
                    href="https://www.instagram.com/braintrusttutors/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon width={40} height={40} />
                  </a>
                  <a
                    className="footer-social-icon"
                    href="https://www.facebook.com/braintrust.edu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookIcon width={40} height={40} />
                  </a>

                  <a
                    className="footer-social-icon"
                    href="https://www.linkedin.com/company/braintrusttutors"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedinIcon width={40} height={40} />
                  </a>
                  <a
                    className="footer-social-icon"
                    href="https://www.youtube.com/channel/UCDp1FBW2mA6ThAsshiKiQnA?view_as=subscriber"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YoutubeIcon width={40} height={40} />
                  </a>
                  <a
                    className="footer-social-icon"
                    href="https://twitter.com/braintrusttutr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterIcon width={40} height={40} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-links">
              <div className="footer-column footer-links-column">
                <ul>
                  <li>
                    <a
                      href="https://braintrusttutors.com/wp-content/uploads/2022/02/Terms-of-Use.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Use
                    </a>
                  </li>
                  <li>
                    <a href="https://braintrusttutors.com/payment_policies" target="_blank" rel="noreferrer">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://braintrusttutors.com/privacy-policy" target="_blank" rel="noreferrer">
                      Student Payment Policies
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-column footer-links-column">
                <ul>
                  <li>
                    <a href="https://braintrusttutors.com/tutor-payment-policy" target="_blank" rel="noreferrer">
                      Tutor Payment Policies
                    </a>
                  </li>
                  <li>
                    <a href="https://braintrusttutors.com/background-check-policy" target="_blank" rel="noreferrer">
                      Background Check Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright">© Braintrust {new Date().getFullYear()}, All Rights Reserved</div>
      </div>
    </footer>
  )
}

export default Footer
