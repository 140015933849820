import React, { useMemo } from "react"
import { Form } from "react-bootstrap"
import { Field, Form as FForm } from "react-final-form"
import FormRow from "../../../blocks/FormRow"
import { TutorField } from "../fields/tutor"
import { FieldNames, positionTypes } from "./constants"
import { DateRangeFields } from "../../../fields"
import useViewport from "../../../../hooks/useViewport"
import { className } from "../../../../helpers/className"
import { setFieldData } from "../../../../helpers/forms"

export const ConnectionForm = ({
  initialValues,
  multipleValues = {},
  showTutorField,
  showPositionField,
  validate,
  loading,
  rowsClassName = "",
  buttonsClassName = "",
  onSubmit,
  onCancel,
  children,
}) => {
  const { isPhoneViewport, isTabletViewport } = useViewport()
  const isMobile = useMemo(() => isPhoneViewport || isTabletViewport, [isPhoneViewport, isTabletViewport])

  return (
    <FForm initialValues={initialValues} onSubmit={onSubmit} validate={validate} mutators={{ setFieldData }}>
      {({ pristine, handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div className={rowsClassName}>
            {showTutorField ? (
              <FormRow label="Tutor" ColProps={{ lg: 13 }} LabelProps={{ className: "v-2", lg: 6 }} className="m-0">
                <TutorField name={FieldNames.Tutor} />
              </FormRow>
            ) : null}

            {showPositionField && (
              <FormRow
                label="Tutoring type"
                ColProps={{ lg: 13 }}
                LabelProps={{ className: "v-2", lg: 6 }}
                className="m-0"
              >
                <Field type="radio" name={FieldNames.Position}>
                  {({ input, meta }) => (
                    <>
                      <div className="d-flex align-items-center gap-1">
                        {positionTypes.map(({ value, label }) => (
                          <Form.Check
                            name={FieldNames.Position}
                            type="radio"
                            className="v-2"
                            id={`${FieldNames.Position}-${value}`}
                            key={value}
                            label={label}
                            custom
                            {...input}
                            value={value}
                            checked={values[FieldNames.Position] === value}
                          />
                        ))}
                      </div>
                      {meta.touched && meta.error ? (
                        <Form.Control.Feedback type="invalid" className="d-block mt-1">
                          {meta.error}
                        </Form.Control.Feedback>
                      ) : null}
                    </>
                  )}
                </Field>
              </FormRow>
            )}

            <FormRow label="Date range" ColProps={{ lg: 13 }} LabelProps={{ className: "v-2", lg: 6 }} className="m-0">
              <DateRangeFields
                showLabels={false}
                startPlaceholder={multipleValues[FieldNames.StartDate] ? "Multiple values" : "Date"}
                endPlaceholder={multipleValues[FieldNames.StartDate] ? "Multiple values" : "Date"}
              />
            </FormRow>

            <FormRow
              label="Paid no shows"
              ColProps={{ lg: 6 }}
              LabelProps={{ className: "v-2", lg: 6 }}
              className="m-0"
            >
              <Field
                type="number"
                name={FieldNames.MaxPaidNoShows}
                format={value =>
                  value !== null && value !== undefined
                    ? value
                        .toString()
                        .trim()
                        .replace(/[^0-9]/g, "")
                    : ""
                }
              >
                {({ input, meta }) => (
                  <>
                    <Form.Control
                      {...input}
                      className="v-2"
                      placeholder={multipleValues[input.name] ? "Multiple values" : 10}
                      isInvalid={meta.touched && meta.error}
                    />

                    {meta.touched && meta.error ? (
                      <Form.Control.Feedback type="invalid" className="mt-1">
                        {meta.error}
                      </Form.Control.Feedback>
                    ) : null}
                  </>
                )}
              </Field>
            </FormRow>

            <FormRow label="Rate" ColProps={{ lg: 6 }} LabelProps={{ className: "v-2", lg: 6 }} className="m-0">
              <Field
                type="number"
                name={FieldNames.Rate}
                format={value =>
                  value
                    ?.toString()
                    .trim()
                    .replace(",", ".")
                    .replace(/[^0-9.]/g, "")
                }
              >
                {({ input, meta }) => (
                  <>
                    <Form.Control
                      {...input}
                      className="v-2"
                      placeholder={multipleValues[input.name] ? "Multiple values" : 10}
                      isInvalid={meta.touched && meta.error}
                    />

                    {meta.touched && meta.error ? (
                      <Form.Control.Feedback type="invalid" className="mt-1">
                        {meta.error}
                      </Form.Control.Feedback>
                    ) : null}
                  </>
                )}
              </Field>
            </FormRow>
          </div>

          <div className={buttonsClassName}>
            <button
              className={className("btn btn-primary text-no-wrap", isMobile && "w-100")}
              type="submit"
              disabled={pristine || loading}
            >
              Save
            </button>

            {onCancel && (
              <button
                className={className("btn btn-link text-black text-no-wrap", isMobile && "w-100")}
                type="button"
                onClick={onCancel}
              >
                Cancel
              </button>
            )}

            {loading && <div className="spinner-border text-primary" />}

            {children}
          </div>
        </form>
      )}
    </FForm>
  )
}
