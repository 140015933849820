import { useEffect } from "react"
import { parse } from "query-string"

const useFocusedInterview = () => {
  const { id: focusedInterviewId } = parse(window.location.search)

  useEffect(() => {
    const card = document.getElementById(focusedInterviewId)
    if (card) {
      card.scrollIntoView()
    }
  })

  return +focusedInterviewId
}

export default useFocusedInterview
