import React, { useMemo } from "react"
import { FormCheck, Modal } from "react-bootstrap"
import { Form, Field } from "react-final-form"
import { Row, Col, Button } from "react-bootstrap"
import { formatDateTimeKeepZone } from "../../../helpers/dates"
import InterviewSlot from "./InterviewSlot"
import { getPrivateFullName } from "../../../helpers/presentational"

const InterviewPickTimeModal = ({ show, interview, onSubmit, onClose }) => {
  const { tutor } = interview
  const tutorName = getPrivateFullName(tutor)

  const initialValues = useMemo(() => {
    return {
      ...interview,
      start_at_slots: interview.start_at_slots.map(v => formatDateTimeKeepZone(v)),
      started_at: formatDateTimeKeepZone(interview.started_at) || interview.start_at_slots[0],
    }
  }, [interview])

  const handleSubmit = values => onSubmit({ ...values, started_at: formatDateTimeKeepZone(values.started_at) })

  return (
    <Modal show={show} size="lg" onHide={onClose}>
      <Modal.Header className="px-4 pt-4 pb-2" closeButton>
        <Modal.Title>Video interview details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          {({ invalid, submitting, handleSubmit }) => (
            <Row>
              <Col lg={18}>
                <h4>Date and time</h4>

                <p>
                  {interview.start_at_slots.length > 0
                    ? `${tutorName} suggested the time below for your interview.`
                    : `${tutorName} suggested the times below for your interview. Pick the time that works best for you to confirm the meeting.`}
                  <br />
                  You’ll be able to see the contact information for the interview on your Braintrust calendar!
                </p>

                {interview.start_at_slots.map(slot => (
                  <div key={slot}>
                    <Field name="started_at" type="radio" value={slot}>
                      {({ input }) => (
                        <FormCheck
                          {...input}
                          id={slot}
                          label={<InterviewSlot start={slot} tag="div" timePosition="start" />}
                          custom
                        />
                      )}
                    </Field>
                  </div>
                ))}

                <div className="mt-5">
                  <Button
                    variant="outline-primary"
                    className="anl-btn"
                    disabled={invalid || submitting}
                    onClick={handleSubmit}
                  >
                    Confirm
                  </Button>
                  <Button variant="link" className="link-primary ml-5" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default InterviewPickTimeModal
