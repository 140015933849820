import React, { Fragment, useState, useContext, useMemo } from "react"
import { Form as FForm, Field } from "react-final-form"
import { Col, Row, Form } from "react-bootstrap"
import useViewport from "../../../hooks/useViewport"
import { QueryContext } from "../../../contexts"
import { cancelSession, bulkDeleteSessionInReport } from "../configurations/domain"
import { Entities } from "../../../constants"
import ShiftSubmit from "../../blocks/ShiftSubmit"

import { ReactComponent as Agreement } from "../../../assets/images/agreement.svg"
import { ReactComponent as NoPerson } from "../../../assets/images/no-person.svg"
import { className } from "../../../helpers/className"
import { SessionActions } from "../constants"
import { checkActionExist } from "../helpers"

const CancelTypes = {
  Cancelled: "cancelled",
  CancelledNoShow: "cancelled_no_show",
  Delete: "delete",
}

const cancelAnswers = [
  { value: CancelTypes.Cancelled, text: "Session was canceled<br/>by agreement", icon: <Agreement /> },
  { value: CancelTypes.CancelledNoShow, text: "Student<br/>didn’t show up", icon: <NoPerson /> },
]

const CancelSessionFields = {
  Status: "status",
  CancelationReason: "cancellation_reason",
}

const cancelSessionDefaultValue = {
  [CancelSessionFields.Status]: "",
  [CancelSessionFields.CancelationReason]: "",
}

const cancelFormValidate = data => {
  const acc = {}
  if (data[CancelSessionFields.Status] === CancelTypes.Cancelled) {
    if (
      !data[CancelSessionFields.CancelationReason] ||
      data[CancelSessionFields.CancelationReason]?.trim()?.length === 0
    )
      acc[CancelSessionFields.CancelationReason] = "Required field"
  }
  return acc
}

export const CancelSessionForm = ({
  scheduleId,
  session,
  haveConnection,
  maxCancelCount,
  currentCancelCount,
  onHide,
}) => {
  const { isMobileViewport } = useViewport()
  const { getHookState } = useContext(QueryContext)
  const { request: deleteRequest, loading: deleteLoading } = getHookState(bulkDeleteSessionInReport)
  const { request: cancelRequest, loading: cancelLoading } = getHookState(cancelSession)

  const [initialValues] = useState(cancelSessionDefaultValue)

  const leftCancelCount = useMemo(
    () => (maxCancelCount - currentCancelCount > 0 ? maxCancelCount - currentCancelCount : 0),
    [currentCancelCount, maxCancelCount]
  )

  const submit = async data => {
    await cancelRequest({
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Sessions]: session.id,
      },
      data,
      onSuccess: onHide,
    })
  }

  const onDelete = async () => {
    await deleteRequest({
      data: { ids: [session.id] },
      onSuccess: onHide,
    })
  }

  const canDelete = useMemo(() => checkActionExist(session.actions, SessionActions.Destroy), [session])

  return (
    <FForm initialValues={initialValues} validate={cancelFormValidate} onSubmit={submit}>
      {({ values, errors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Col>
            <Row xs={24} className="mb-3">
              <Field type="radio" name={CancelSessionFields.Status}>
                {({ input }) =>
                  cancelAnswers.map(({ value, text, icon }) => (
                    <Fragment key={value}>
                      <input
                        id={`${CancelSessionFields.Status}-${value}`}
                        {...input}
                        value={value}
                        checked={values[CancelSessionFields.Status] === value}
                        hidden
                      />
                      <label className="cancel-session-radio-label" htmlFor={`${CancelSessionFields.Status}-${value}`}>
                        <div className="cancel-session-radio-label-icon mb-1">{icon}</div>
                        <span className="cancel-session-radio-label-text" dangerouslySetInnerHTML={{ __html: text }} />
                      </label>
                    </Fragment>
                  ))
                }
              </Field>
            </Row>
            {canDelete && (
              <Row className="mb-3">
                <Col className="p-0 d-flex flex-row-reverse">
                  <Field type="radio" name={CancelSessionFields.Status}>
                    {({ input }) => (
                      <>
                        <input
                          id={`${CancelSessionFields.Status}-${CancelTypes.Delete}`}
                          {...input}
                          value={CancelTypes.Delete}
                          checked={values[CancelSessionFields.Status] === CancelTypes.Delete}
                          hidden
                        />
                        <label className="mb-0" htmlFor={`${CancelSessionFields.Status}-${CancelTypes.Delete}`}>
                          <span
                            className={`btn btn-link ${
                              values[CancelSessionFields.Status] === CancelTypes.Delete ? "disabled" : ""
                            }`}
                          >
                            Delete session
                          </span>
                        </label>
                      </>
                    )}
                  </Field>
                </Col>
              </Row>
            )}
            {values[CancelSessionFields.Status] === CancelTypes.CancelledNoShow && (
              <Row xs={24} className="mb-4">
                <div className="cancel-session-description-wrapper p-4">
                  <div
                    className={`d-flex mb-3 justify-content-between ${
                      isMobileViewport ? "align-items-start" : "align-items-center"
                    }`}
                  >
                    <span className="cancel-session-description-title">
                      {leftCancelCount === 0 && (
                        <span>
                          Unfortunately, you have no any paid no-show session. Canceled session will not be paid
                        </span>
                      )}
                      {leftCancelCount > 0 && (
                        <span>
                          {leftCancelCount === 1 ? "Only 1" : leftCancelCount} paid{" "}
                          <span className="text-no-wrap">no-show</span> session{leftCancelCount !== 1 && "s"} left
                        </span>
                      )}
                    </span>
                    {haveConnection && (
                      <div className={`cancel-session-description-counters ${leftCancelCount === 0 ? "-problem" : ""}`}>
                        {leftCancelCount} of {maxCancelCount}
                      </div>
                    )}
                  </div>
                  <div className="cancel-session-description-text mb-3">
                    This seems to be a problem with scheduling. Please contact parents / guardians to adjust the
                    schedule or let us know if the session series should be canceled.
                  </div>
                  {/* <div className="cancel-session-description-text">Representative contact: <a className="font-weight-semibold" href="tel:+18085550111">+1 808 555 0111 (Alison Grippo)</a></div> */}
                </div>
              </Row>
            )}
            {values[CancelSessionFields.Status] === CancelTypes.Cancelled && (
              <Row xs={24} className="mb-4">
                <div className="cancel-session-description-wrapper px-4 py-3">
                  <div className="cancel-session-description-text -sub mb-3">
                    Please be advised that the session will not be paid.
                    <br />
                    <span className="font-weight-semibold">Specify the reason of canceling below:</span>
                  </div>
                  <Field type="text" name={CancelSessionFields.CancelationReason}>
                    {({ input, meta }) => (
                      <Form.Control
                        as="textarea"
                        className="v-2 mb-3"
                        placeholder="Please provide a reason"
                        required
                        isInvalid={meta.touched && meta.error}
                        {...input}
                      />
                    )}
                  </Field>
                  {/* <div className="cancel-session-description-text -tip">Would you like to cancel the series of sessions? <a className="font-weight-semibold" href="#">Delete the series of sessions</a></div> */}
                </div>
              </Row>
            )}
          </Col>
          {values[CancelSessionFields.Status] === CancelTypes.Delete && (
            <Row xs={24} className="mb-4">
              <div className="cancel-session-description-wrapper p-4">
                <div
                  className={`d-flex mb-3 justify-content-between ${
                    isMobileViewport ? "align-items-start" : "align-items-center"
                  }`}
                >
                  <span className="cancel-session-description-title">
                    Delete a session only if it was added by mistake
                  </span>
                </div>
                <div className="cancel-session-description-text">
                  Do not delete the session if the student didn’t show up or it was canceled by agreement
                </div>
              </div>
            </Row>
          )}
          {values[CancelSessionFields.Status] && (
            <Col>
              <Row
                className={`${
                  isMobileViewport
                    ? "flex-column gap-2 justify-content-stretch"
                    : "justify-content-between align-items-center"
                }`}
              >
                <Col xs={24} lg={16} className="d-flex px-0 gap-2  align-items-center">
                  {values[CancelSessionFields.Status] === CancelTypes.Cancelled && (
                    <button
                      className="btn mr-2 cancel-session-submit"
                      type="submit"
                      disabled={Object.keys(errors).length > 0}
                    >
                      Confirm canceling
                    </button>
                  )}
                  {(!isMobileViewport || !(cancelLoading || deleteLoading)) && (
                    <>
                      {values[CancelSessionFields.Status] === CancelTypes.CancelledNoShow && (
                        <ShiftSubmit disabled={cancelLoading} onSubmit={() => submit(values)} />
                      )}
                      {values[CancelSessionFields.Status] === CancelTypes.Delete && (
                        <ShiftSubmit disabled={deleteLoading} onSubmit={onDelete} className="-danger" />
                      )}
                    </>
                  )}
                  {(!isMobileViewport || cancelLoading || deleteLoading) && (
                    <div
                      className={className(
                        "spinner-border text-primary",
                        cancelLoading || deleteLoading ? "visible" : "invisible"
                      )}
                    />
                  )}
                </Col>
                <Col xs="auto">
                  <button
                    className="btn btn-link btn-link-default fullwidth"
                    type="button"
                    disabled={cancelLoading || deleteLoading}
                    onClick={onHide}
                  >
                    Dismiss
                  </button>
                </Col>
              </Row>
            </Col>
          )}
        </form>
      )}
    </FForm>
  )
}
