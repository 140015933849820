import React, { Fragment } from "react"

export const EditPassword = () => {
  return (
    <Fragment>
      <h2 className="sign_title">Change Your Password</h2>
      <div className="password required user_password">
        <input
          className="form-control password required form-control-lg sign_input"
          name="user[password]"
          required
          placeholder="New Password"
          type="password"
        />
      </div>
      <div className="mt-2 mb-2 ml-2">
        <small className="m-0 pl-2 pt-2">6 characters minimum</small>
      </div>

      <div className="form-group password required user_password">
        <input
          className="form-control password required form-control-lg sign_input"
          name="user[password_confirmation]"
          placeholder="Confirm your new password"
          type="password"
        />
      </div>
      <div className="form-actions">
        <input
          type="submit"
          name="commit"
          value="Change my password"
          className="sign_submit"
          data-disable-with="Change my password"
        />
      </div>
    </Fragment>
  )
}
