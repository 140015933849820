import arrayMutators from "final-form-arrays"
import React from "react"
import { Form } from "react-final-form"
import { putAndRedirect } from "../../../common/apiClient"
import ProfileValidationProvider from "../providers/ProfileValidation"
import FormFields from "./FormFields"

const EmploymentAndTutoring = ({ dataSources }) => {
  const onSubmit = profile => {
    putAndRedirect(dataSources.urls.save, dataSources.urls.next, { profile })
  }

  const validation = values => {
    const result = {}

    if (values?.employment_histories?.length === 0) result.employmentHistoriesLength = "Required"

    return result
  }

  return (
    <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} validate={validation}>
      {({ handleSubmit, submitting, errors }) => (
        <form onSubmit={handleSubmit} className="container experience-form">
          <ProfileValidationProvider errors={errors}>
            <FormFields dataSources={dataSources} />
          </ProfileValidationProvider>
          <div className="col-lg-6 mb-4"></div>
          <button type="submit" className="btn btn-primary" disabled={submitting || Object.keys(errors).length > 0}>
            Next
          </button>
        </form>
      )}
    </Form>
  )
}
export default EmploymentAndTutoring
