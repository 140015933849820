import useViewport from "../../../../../../hooks/useViewport"
import React, { useContext } from "react"
import { TutorCardApplicationContext } from "../../../../contexts"
import { QuickActions } from "./quick_actions"
import { TutorInfo } from "../../tutor_info"

export const TutorCardContent = () => {
  const { isMobileViewport } = useViewport()
  const application = useContext(TutorCardApplicationContext)

  return (
    <>
      <TutorInfo application={application} />

      {!isMobileViewport && <QuickActions />}
      {!isMobileViewport && (
        <div>
          <hr className="horizontal soft-color mr-5" />
        </div>
      )}
    </>
  )
}
