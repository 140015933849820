import React from "react"
import {
  compose,
  map,
  groupBy,
  mapValues,
  invertBy,
  identity,
  toPairs,
  sortBy,
  join,
  find,
  reduce,
  get,
  toUpper,
  isEqual,
  isEmpty,
  filter,
  upperFirst,
  differenceWith,
  uniq,
} from "lodash/fp"
import moment from "moment"

import { DAYPERIODS, SHORT_WEEKDAYS, WEEKDAYS } from "../common/constants"
import { scheduleStringToObject } from "./serializers"
import { isValidLocation } from "./validators"
import { isExecutiveFunctionProgram, programsHierarchyToList } from "./programsHierarchy"
import { formatDateKeepZone } from "./dates"

export const getLabelByValue = options => value => compose(get("label"), find(["value", value]))(options)

export const getFullName = ({ first_name, last_name }) => `${first_name || ""} ${last_name || ""}`

export const getFirstLetter = value => (value || "").charAt(0)

export const getPrivateFullName = ({ first_name, last_name }) => `${first_name || ""} ${getFirstLetter(last_name)}`

export const getInitials = ({ first_name, last_name }) =>
  toUpper(getFirstLetter(first_name) + getFirstLetter(last_name))

export const getDayPeriodShortLabel = value => compose(get("shortLabel"), find(["value", value]))(DAYPERIODS)

export const getPeriodsSummary = (periods = []) => {
  const difference = differenceWith(({ value }, { period }) => value === period)(DAYPERIODS)(periods)
  if (isEmpty(difference)) {
    return "all day"
  }

  const sortedPeriods = sortBy(({ period }) => DAYPERIODS.findIndex(({ value }) => period === value))(periods)
  return compose(
    join(","),
    map(({ period }) => getDayPeriodShortLabel(period))
  )(sortedPeriods)
}

const formatDaysList = compose(
  join(","),
  map(([l]) => l.toUpperCase()),
  sortBy(item => SHORT_WEEKDAYS.findIndex(({ value }) => value === item))
)

export const getScheduleSummary = (value = [], maxSymbols = 50, simpleRepresentation = "Schedule preference set") => {
  const summary = compose(
    join("; "),
    map(([period, days]) => `${formatDaysList(days)}—${period}`),
    sortBy(([period]) => isEqual(period, "all day")),
    toPairs,
    invertBy(identity),
    mapValues(getPeriodsSummary),
    groupBy("day"),
    map(scheduleStringToObject)
  )(value)

  return summary.length > maxSymbols ? simpleRepresentation : summary
}

export const getProgramsSummary = (id, programs) => {
  const program = find(["id", id])(programs)
  if (!program) {
    return ""
  }
  return program.domain.name.toLowerCase() === program.name.toLowerCase()
    ? program.name
    : `${program.domain.name}, ${program.name}`
}

export const getProgramTitle = (id, programs) =>
  isExecutiveFunctionProgram(id, programs) ? "Executive Function" : compose(get("name"), find(["id", id]))(programs)

export const getStudentInfo = (student, options) => {
  const fullName = getFullName(student)
  const grade = getLabelByValue(options.grades)(student.grade)
  const specialTreatments = map(getLabelByValue(options.specialTreatments))(student.special_treatments)
  return [fullName, grade, ...specialTreatments].join(", ")
}

export const getSpecialTreatmentsSummary = (
  { special_treatments: specialTreatments, executive_function: executiveFunction },
  options,
  maxSymbols = 40
) => {
  const result = []
  if (!isEmpty(specialTreatments)) {
    const values = specialTreatments.map(getLabelByValue(options.specialTreatments))
    result.push(...values)
  }
  if (executiveFunction) {
    result.push("Executive function skills support")
  }
  const summary = result.join(", ")
  const simpleRepresentation = result.length > 1 ? `${result.slice(0, 1).join(", ")} +${result.length - 1}` : summary
  return summary.length > maxSymbols ? simpleRepresentation : summary
}

export const getLocationSummary = ({ online, offline, zip }) => {
  const offlineText = isValidLocation({ offline, zip }) ? `Near ${zip}` : ""
  const onlineText = online ? "Online" : ""
  return compose(join(", "), filter(Boolean))([offlineText, onlineText])
}

export const getOfflineLocationText = ({ address_1, address_2, city, state, zip }, options) => {
  const stateValue = state ? getLabelByValue(options.states)(state) : ""
  return compose(join(", "), filter(Boolean))([address_1, address_2, city, stateValue, zip])
}

export const getStruggleWithSummary = (ids, options) => {
  const answers = reduce((result, ids) => [...result, ...ids.answers], [])(options.struggleWithQuestions)
  const [firstId, ...restIds] = ids
  return compose(
    value => (restIds.length ? `${value} +${restIds.length}` : value),
    upperFirst,
    get("text"),
    find(["id", firstId])
  )(answers)
}

export const getStruggleWithFullSummary = (ids, options) => {
  return ids.map(id => (getStruggleWithAnswer(id, options) || "").replace("?", ""))
}

export const getStruggleWithAnswer = (value, options) => {
  const answers = reduce((result, value) => [...result, ...value.answers], [])(options.struggleWithQuestions)
  return compose(get("text"), find(["id", value]))(answers)
}

export const getPriceSummary = value => `Up to $${value} per hour`

export const getTutorSpecializationsText = (tutorPrograms, { hierarchy }) => {
  const result = []
  const programsList = programsHierarchyToList(hierarchy)

  tutorPrograms.forEach(({ id }) => {
    const programData = find(["id", id])(programsList)
    if (programData) {
      result.push(`${programData.division.name} ${programData.domain.name}`)
    }
  })
  const resultText = compose(join(", "), uniq)(result)
  return resultText
}

export const getSessionInfo = (day, time, duration) => {
  const weekday = getLabelByValue(WEEKDAYS)(day)
  const momentStart = moment(time, "HH:mm")
  const momentEnd = moment(time, "HH:mm").add(duration, "m")
  if (!momentStart.isValid() || !momentEnd.isValid()) {
    return ""
  }
  return `${weekday} ${momentStart.format("h:mma")}-${momentEnd.format("h:mma")}`
}

export const getPeriodInfo = (start, end) =>
  `from ${formatDateKeepZone(start, "MM/DD/YYYY")} till ${formatDateKeepZone(end, "MM/DD/YYYY")}`

export const preserveNewlines = text => {
  if (!text) {
    return ""
  }
  return text.split("\n").map((line, i, arr) => {
    const resultLine = <span key={i}>{line}</span>
    return i === arr.length - 1 ? resultLine : [resultLine, <br key={i + "br"} />]
  })
}
