import { ReactComponent as Alarm } from "@/assets/images/alarm.svg"
import { ReactComponent as Finished } from "@/assets/images/approved-icon.svg"
import { ReactComponent as NoPerson } from "@/assets/images/no-person.svg"
import { ReactComponent as Submitted } from "@/assets/images/report_state_done.svg"
import { ReactComponent as Cancelled } from "@/assets/images/session-cancelled.svg"
import moment from "moment"
import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const statusIconMapping = {
  overdue: Alarm,
  cancelled: Cancelled,
  no_show: NoPerson,
  finished: Finished,
  report_submitted: Submitted,
}

const reportStatusMap = {
  overdue: "Overdue",
  cancelled: "Canceled",
  no_show: "No show",
  finished: "Finished",
  report_submitted: "Pending",
  in_progress: "Pending",
}

const EventContent = ({ event }) => {
  const { title, start } = event
  const { is_overdue, status, user_time_zone_abbr } = event.extendedProps
  let comment = reportStatusMap[status] || null
  let Icon = statusIconMapping[status] || null
  if (is_overdue) {
    Icon = statusIconMapping.overdue
    comment = reportStatusMap.overdue
  }
  return (
    <OverlayTrigger overlay={<Tooltip>{comment ? `${title} | ${comment}` : title}</Tooltip>}>
      <div className="fc-content">
        <div className="fc-time">
          <div className="d-inline-flex align-items-end">
            {moment(start).format("hh:mma")}
            {` ${user_time_zone_abbr}`}
            {Icon && (
              <div className="icon-16-px event-icon ml-1">
                <Icon />
              </div>
            )}
          </div>
        </div>
        <div className="fc-title flex-fill">{title}</div>
        {Icon && (
          <div className="icon-16-px event-icon -short">
            <Icon />
          </div>
        )}
      </div>
    </OverlayTrigger>
  )
}
export default EventContent
