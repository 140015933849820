import React, { useContext } from "react"
import { Col, Row } from "react-bootstrap"
import moment from "moment"
import { checkUrl } from "./helpers"
import { DataContext } from "../../contexts"
import { getStatusClass, getStatusText, getStatusIcon } from "./helpers"
import { formatDateKeepZone, minutesToHours } from "../../helpers/dates"

const ReportStatus = () => {
  const { statusInfo } = useContext(DataContext)
  if (statusInfo === void 0) return null
  const { status } = statusInfo
  const statusClass = getStatusClass(status)
  const statusText = getStatusText(status)
  const Icon = getStatusIcon(status)
  return (
    <div className={`session-status ${statusClass}`}>
      {Icon && (
        <div className="session-status-icon">
          <Icon />
        </div>
      )}
      <span className="session-status-text font-weight-semibold">{statusText}</span>
    </div>
  )
}

const buildTime = mins => {
  const [hours, minutes] = minutesToHours(mins)
  return [hours > 0 ? `${hours}h` : "", minutes > 0 ? `${minutes}min` : ""].filter(Boolean).join(" ")
}

const Series = ({ series }) => {
  return series.map((s, idx) => {
    const dayList = Object.keys(s.timetable)
    const count = dayList.length
    const durations = dayList.map(d => {
      const day = s.timetable[d]
      return parseInt(day.duration, 10)
    })
    const duration = [Math.min(...durations), Math.max(...durations)]
      .filter((el, idx, arr) => arr.indexOf(el) === idx)
      .map(buildTime)
      .join(" - ")

    const days = dayList.map(day => moment().day(day).format("ddd")).join(", ")
    return (
      <div className="d-flex flex-column mb-2" key={idx}>
        <div className="font-weight-semibold">
          {duration}, {count}x per week — {days}
        </div>
        <div className="font-weight-medium">
          {formatDateKeepZone(s.started_at, "MM/DD/YYYY")}
          {s.end_at ? " – " : ""}
          {s.end_at && formatDateKeepZone(s.end_at, "MM/DD/YYYY")}
        </div>
      </div>
    )
  })
}

const GroupInformation = () => {
  const { role, groupInfo, statusInfo } = useContext(DataContext)

  if (groupInfo === void 0 || statusInfo === void 0) return null

  const { schedule } = statusInfo

  let url = null
  if (schedule.meeting_format !== "offline") url = checkUrl(schedule.meeting_info)

  return (
    <div className="session-information d-flex flex-column fullwidth">
      <div className="session-information-title justify-content-between mb-3 d-flex">
        <span>{groupInfo.program.name}</span>
        {groupInfo.tutor_rate && <span>${parseFloat(groupInfo.tutor_rate).toFixed(2)}/hr</span>}
      </div>
      <Series series={groupInfo.series} />
      <div>
        <span className="mr-1">Location:</span>
        <span className="font-weight-semibold">
          <span className="mr-1 text-capitalize d-inline-block">
            {schedule.meeting_format}
            {schedule.meeting_info ? "," : ""}
          </span>
          {schedule.meeting_info ? (
            schedule.meeting_format === "offline" ? (
              <span>{schedule.meeting_info}</span>
            ) : url ? (
              <a target="_blank" rel="noreferrer" className="session-information-link" href={url}>
                {schedule.meeting_format === "pencil_spaces" ? "Pencilapp link" : "Link"}
              </a>
            ) : (
              <span>{schedule.meeting_info} (Invalid link url)</span>
            )
          ) : null}
        </span>
      </div>
      {groupInfo.tutor && (
        <div className="mt-2">
          <span className="mr-1">Tutor:</span>
          <span className="font-weight-semibold">
            {groupInfo.tutor_profile_url ? (
              <a
                target="_blank"
                rel="noreferrer"
                className="session-information-link"
                href={groupInfo.tutor_profile_url}
              >
                {groupInfo.tutor.name}
              </a>
            ) : (
              <span>{groupInfo.tutor.name}</span>
            )}
          </span>
        </div>
      )}
      {statusInfo.schedule?.start_at && (
        <div className="mt-2">
          <span className="mr-1">Assignment lifetime:</span>
          <span className="font-weight-semibold">
            {formatDateKeepZone(statusInfo.schedule.start_at, "MM/DD/YYYY")}
            {statusInfo.schedule.end_at ? " - " : ""}
            {statusInfo.schedule.end_at && formatDateKeepZone(statusInfo.schedule.end_at, "MM/DD/YYYY")}
          </span>
        </div>
      )}
      {role !== "parent" && (
        <>
          <hr className="divider fullwidth" />
          <div className="session-information-title">{groupInfo.group.name}</div>
          <div className="session-information-subtitle mb-2">{groupInfo.group?.school?.name}</div>
          <div className="d-flex flex-column">
            {groupInfo.group.students.map((student, idx) => (
              <div key={idx} className="mb-1 font-weight-medium">
                {student}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

const GroupInfo = () => {
  return (
    <Col>
      <Row className="mb-4">
        <ReportStatus />
      </Row>
      <Row>
        <GroupInformation />
      </Row>
    </Col>
  )
}

export default GroupInfo
