import React, { useState, useMemo } from "react"
import cn from "classnames"
import { Dropdown, Button } from "react-bootstrap"
import { useFormState, Field, useField } from "react-final-form"

import StruggleWithControl from "./StruggleWithControl"
import { getMatchedQuestions } from "../../helpers/programsHierarchy"

const StruggleWithDropdown = ({
  className,
  options,
  disabled,
  completed,
  getButtonText,
  dropdownMaxWidth = 400,
  onSubmit,
  ...props
}) => {
  const [showPopper, setShowPopper] = useState(false)

  const {
    values: { grade, program_ids, executive_function: executiveFunction },
  } = useFormState()
  const { meta } = useField("struggle_with")

  const matchedQuestions = useMemo(
    () => getMatchedQuestions(grade, program_ids, executiveFunction, options),
    [grade, program_ids, executiveFunction, options]
  )

  const onToggle = show => {
    if (show) {
      setShowPopper(true)
      return
    }
    setShowPopper(false)
    if (!meta.pristine) {
      onSubmit && onSubmit()
    }
  }

  return (
    <Field name="struggle_with">
      {({ input }) => (
        <Dropdown show={showPopper} onToggle={onToggle} className="anl-struggles">
          <Dropdown.Toggle
            variant="outline-dropdown"
            className={cn(className, completed && "selected")}
            disabled={disabled || !matchedQuestions.length}
          >
            {getButtonText(input.value, options)}
          </Dropdown.Toggle>
          <Dropdown.Menu className={`p-4 min-w-md-${dropdownMaxWidth}-px max-vw-100 overflow-auto`}>
            <StruggleWithControl {...input} {...props} questions={matchedQuestions} />

            <Button variant="outline-primary" size="sm" className="mt-4" type="button" onClick={() => onToggle(false)}>
              Ok
            </Button>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Field>
  )
}

export default StruggleWithDropdown
