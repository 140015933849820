import React from "react"
import { Button } from "react-bootstrap"

const RESOURCES_LINKS = [
  {
    title: "Print Students Login Cards",
    link: "/school_admin/students",
  },
  {
    title: "Create School Admin",
    link: "school_admins/new",
  },
]

export const ResourcesDashboard = () => {
  return (
        <div className="mt-2 d-grid gap-1 w-100" style={{display: "grid"}}>
          {RESOURCES_LINKS.map(resource => (
            <Button key={resource.link} href={resource.link} variant="outline-primary">
              {resource.title}
            </Button>
          ))}
        </div>
  )
}
