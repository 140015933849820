import React from "react"
import { Modal, Button, Row, Col, FormControl } from "react-bootstrap"
import { Form, Field } from "react-final-form"

import Rating from "../blocks/Rating"
import { required } from "../../helpers/validators"
import { getFullName } from "../../helpers/presentational"
import { valueOrNull } from "../../helpers/formatters"

const ReviewModal = ({ show, review, tutor, onSubmit, onDelete, handleClose }) => {
  return (
    <Modal show={show} size="xl" onHide={handleClose}>
      <Modal.Header className="borderless px-4 pt-4 pb-2" closeButton>
        <Modal.Title>
          {review ? "Edit" : "Leave"} a review for {getFullName(tutor)}
        </Modal.Title>
      </Modal.Header>
      <Form initialValues={review} onSubmit={onSubmit}>
        {({ invalid, submitting, handleSubmit }) => (
          <Modal.Body className="p-0">
            <p className="px-4">
              Your feedback helps improve the Braintrust experience, so thank you for taking a time to leave a review.
              Your ratings and comments will be published to the tutor’s profile, and you can edit or remove your review
              at any time. Please note that any particularly negative feedback will be reviewed by administrators before
              being posted to a tutor’s profile.
            </p>
            <hr className="m-0" />
            <div className="p-4">
              <Row className="mb-4">
                <Col lg={6} className="order-1 order-lg-0">
                  <h6>Overall impression</h6>
                  <p>How was your overall experience with this tutor?</p>
                </Col>
                <Col lg={12} className="order-1 order-lg-0">
                  <Field name="rating" validate={required}>
                    {({ input }) => <Rating starSize={36} {...input} />}
                  </Field>
                </Col>
                <Col lg={6} className="text-right order-0 order-lg-1">
                  <small>Visible in tutors profile</small>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <h6>Your review</h6>
                  <p>What would be helpful for other parents to know about this tutor?</p>
                </Col>
                <Col lg={12}>
                  <Field name="comment" parse={valueOrNull}>
                    {({ input }) => <FormControl as="textarea" rows={5} {...input} />}
                  </Field>
                </Col>
              </Row>
            </div>
            <hr className="m-0" />
            <div className="p-4 bg-extra-light">
              <Row className="mb-4">
                <Col lg={6} className="order-1 order-lg-0">
                  <h6>Engagement</h6>
                  <p>How engaging are sessions?</p>
                </Col>
                <Col lg={12} className="order-1 order-lg-0">
                  <Field name="engagement">{({ input }) => <Rating starSize={36} {...input} />}</Field>
                </Col>
                <Col lg={6} className="text-right order-0 order-lg-1">
                  <small>Tutor will not see ratings you gave</small>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg={6}>
                  <h6>Effectiveness</h6>
                  <p>Is your child making progress?</p>
                </Col>
                <Col lg={12}>
                  <Field name="effectiveness">{({ input }) => <Rating starSize={36} {...input} />}</Field>
                </Col>
              </Row>
              {/*<Row className="mb-4">*/}
              {/*  <Col lg={6}>*/}
              {/*    <h6>Creativity</h6>*/}
              {/*    <p>Does this tutor creatively engage your child?</p>*/}
              {/*  </Col>*/}
              {/*  <Col lg={12}>*/}
              {/*    <Field name="creativity">{({ input }) => <Rating starSize={36} {...input} />}</Field>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <Row>
                <Col lg={6}>
                  <h6>Communication</h6>
                  <p>How responsive is this tutor?</p>
                </Col>
                <Col lg={12}>
                  <Field name="communication">{({ input }) => <Rating starSize={36} {...input} />}</Field>
                </Col>
              </Row>
            </div>
            <hr className="m-0" />
            <div className="d-flex justify-content-between flex-column flex-md-row m-4">
              <div className="d-flex flex-column flex-md-row">
                <Button variant="outline-primary" disabled={invalid || submitting} onClick={handleSubmit}>
                  Publish
                </Button>
                <Button variant="link link-primary" className="ml-0 mt-3 ml-md-4 mt-md-0" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
              {review && (
                <Button variant="link link-error" className="mt-3 mt-md-0" onClick={onDelete}>
                  Delete review
                </Button>
              )}
            </div>
          </Modal.Body>
        )}
      </Form>
    </Modal>
  )
}

export default ReviewModal
