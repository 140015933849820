import React from "react"
import { steps, stepValid } from "./logic"

import { OpeningTabs } from "../../common"

const EditOpeningTabs = ({ activeStep, setActiveStep, opening }) => {
  const checkIsDisabled = key => !stepValid(key, opening)

  return (
    <OpeningTabs
      steps={steps}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      checkIsDisabled={checkIsDisabled}
    />
  )
}
export default EditOpeningTabs
