import React, { useState } from "react"
import DurationField from "./DurationField"

const SlimDurationField = ({ name, initialValue, error, className }) => {
  const [value, setValue] = useState(initialValue)

  const onChange = ({ target: { value } }) => setValue(value)

  return (
    <div className="form-group row select required session_duration form-group-valid">
      <label className="col-form-label col-sm-7 col-md-5 col-lg-4 select required" htmlFor="sesion_duration">
        Duration <abbr title="required">*</abbr>
      </label>
      <div className="col-form-input col-sm-17 col-md-7 col-lg-5 col-xl-4">
        <DurationField
          id="sesion_duration"
          name={name}
          invalid={!!error}
          value={value}
          onChange={onChange}
          className={className}
        />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
      <div className="col-form-hint col-sm-24 col-md-13 col-lg-11 col-xl-13" />
    </div>
  )
}

export default SlimDurationField
