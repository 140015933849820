import React from "react"

const FormFieldErrorFeedback = ({ message }) => {
  if (message) {
    return <div className="invalid-feedback">{message}</div>
  }
  return null
}

export default FormFieldErrorFeedback
