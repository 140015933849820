import React, { useContext, useEffect, useMemo } from "react"
import { StatelessStudentList } from "../students/students_list"
import {
  GroupName,
  StudentsSpecialNeeds,
  StudentsNames,
  StudentsGrades,
  ScheduleDateAndNote,
  MatchTutorWithGroup,
} from "../students/columns"
import { StudentsDialogDataProvider } from "../../providers/tutors_page"
import { Entities } from "../../../../constants"
import { DataContext, QueryContext } from "../../../../contexts"
import { getOpeningStudents, matchApplication } from "../../configurations/domain"
// import { StudentModalListFilters } from "./students_modal_list_filters"
import { TutorInfo } from "./tutor_info"

const columns = [
  GroupName,
  MatchTutorWithGroup,
  ScheduleDateAndNote,
  StudentsNames,
  StudentsGrades,
  StudentsSpecialNeeds,
]

const StudentsList = ({ application, onSave }) => {
  const { openingId, groups, modalStudentsListFilters } = useContext(DataContext)
  const { getQueryState } = useContext(QueryContext)
  const { request: getStudents, loading } = getQueryState(getOpeningStudents)
  const { request: match } = getQueryState(matchApplication)

  const handlersMap = useMemo(() => {
    const map = new Map()
    map.set(MatchTutorWithGroup, {
      onMatchTutor: (event, data) => {
        match({
          entitiesIds: {
            [Entities.Openings]: openingId,
          },
          data: {
            tutor_id: application.tutor.id,
            schedule_ids: [data.id],
          },
          onSuccess: onSave,
          next: [getOpeningStudents],
        })
      },
    })
    map.set(ScheduleDateAndNote, {
      // todo - add context block about schedule
      // eslint-disable-next-line no-console
      // onRangeClick: (event, data) => console.log(event, data, application),
      buildHref: data => `/schedules/${data.id}`,
    })
    return map
  }, [match, openingId, application.tutor.id, onSave])

  useEffect(() => {
    if (modalStudentsListFilters)
      getStudents({
        entitiesIds: {
          [Entities.Openings]: openingId,
        },
        params: modalStudentsListFilters,
      })
  }, [modalStudentsListFilters, getStudents, openingId])

  const data = groups.filter(group => !group.connections || group.connections.length === 0)
  return <StatelessStudentList data={data} loading={loading} columns={columns} handlersMap={handlersMap} />
}

export const StudentsModalContent = ({ application, onSave }) => {
  return (
    <StudentsDialogDataProvider>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-1">
          <TutorInfo application={application} short />
        </div>
        <div>{/* <StudentModalListFilters /> */}</div>
        <div className="mx-n4 px-4 overflow-x-auto">
          <StudentsList application={application} onSave={onSave} />
        </div>
      </div>
    </StudentsDialogDataProvider>
  )
}
