import React, { useState } from "react"
import { Table } from "react-bootstrap"
import BasePagination from "../blocks/BasePagination"
import moment from "moment"
import { startCase } from "lodash"

const MEETING_FORMATS = {
  OFFLINE: "offline",
}

const formatSessionsData = (sessions = []) => {
  return sessions.map(session => ({
    ...session,
    date: (session && session.date && moment.utc(session.date).format("MM/DD/YYYY")) || "",
    time: (session && session.time && moment(session.time).format("hh:mma ")) || "",
  }))
}

const SessionsGrid = ({ ...props }) => {
  const { sessions, initialState } = props

  const { sessions_total_count, sessions_per_page, sessions_page } = initialState

  const [input, setInput] = useState(sessions_page)

  return (
    <div className="container panel-wrapper">
      <h2 className="users-navbar_title mb-3">Sessions</h2>
      <div className="panel">
        <div className="panel_body">
          {!sessions.length ? (
            <tbody>
              <tr>
                <td colSpan={3} align="center">
                  <span>No Sessions Scheduled</span>
                </td>
              </tr>
            </tbody>
          ) : (
            <Table className="grid-table table datagrid admins_students_grid" size="sm">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Location</th>
                  <th>Format</th>
                  <th>Program</th>
                  <th>Tutor Name</th>
                  <th>Tutor Email</th>
                  <th>Session Count</th>
                </tr>
              </thead>
              <tbody>
                {formatSessionsData(sessions).map(s => (
                  <tr key={`${s.date}-${s.time}`}>
                    <td>{s.date}</td>
                    <td>{s.time}</td>
                    {s.meeting_format !== MEETING_FORMATS.OFFLINE ? (
                      <td>
                        <a href={s.meeting_info} target="_blank" rel="noreferrer">
                          Link
                        </a>
                      </td>
                    ) : (
                      <td>{s.meeting_info}</td>
                    )}
                    <td>{startCase(s.meeting_format)}</td>
                    <td>{s.program_name}</td>
                    <td>{s.tutor_name}</td>
                    <td>{s.tutor_email}</td>
                    <td>{s.session_count}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
        {!!sessions.length && (
          <div className="panel_footer">
            <nav className="pagy-bootstrap-nav pagination">
              <ul className="pagination">
                <BasePagination
                  currentPage={input}
                  limit={Number(sessions_per_page)}
                  count={sessions_total_count}
                  onPageChanged={value => {
                    setInput(value)
                  }}
                  siblingCount={3}
                />
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  )
}

export default SessionsGrid
