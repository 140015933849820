import React from "react"
import { Form, Field } from "react-final-form"
import { Row, Col, Button, Form as BForm } from "react-bootstrap"
import { FileSelectButton } from "./FileSelectButton"
import Template from "../../../../../assets/docs/student_upload_template.csv"

const allowedTypes = ["text/csv"]

const initialValues = { file: null, school_id: "" }

const validate = ({ file, school_id }) => {
  return {
    ...(!file ? { file: false } : {}),
    ...(!school_id ? { school_id: false } : {}),
  }
}

const SchoolFieldSet = ({ schools }) => {
  return (
    <Field name="school_id" initialValue="">
      {({ input }) => (
        <BForm.Control as="select" {...input} className="mb-4">
          <option value="" disabled>
            Select school
          </option>
          {schools.map(school => (
            <option key={school.id} value={school.id}>
              {school.name}
            </option>
          ))}
        </BForm.Control>
      )}
    </Field>
  )
}

export const CSVUploadForm = ({ schools, isLoading, onSubmit }) => {
  return (
    <Form initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
      {({ invalid, submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row className="mb-2">
            {/* TODO - add rules here */}
            {/* <Col>
              <p>Mollit sunt fugiat minim duis ad qui irure amet deserunt magna do laborum duis proident.</p>
              <ul className="mb-2">
                <li className="mb-2">Mollit sunt fugiat minim duis ad qui irure amet deserunt</li>
                <li className="mb-2">Mollit sunt fugiat minim duis ad qui irure amet deserunt</li>
                <li className="mb-2">Mollit sunt fugiat minim duis ad qui irure amet deserunt</li>
              </ul>
            </Col> */}
          </Row>
          <Row>
            <Col>
              <SchoolFieldSet schools={schools} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field name="file" type="file">
                {({ input }) => (
                  <FileSelectButton
                    name={input.name}
                    title="Select file"
                    allowedTypes={allowedTypes}
                    onChange={input.onChange}
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Row className="mt-2 justify-content-center">
            <a href={Template} rel="noreferrer" target="_blank" className="text-small">
              CSV example
            </a>
          </Row>
          <Row className="btrust-dialog__actions gap-1 align-items-center">
            <Col>
              <Button
                className="btn btn-outline-primary fullwidth"
                type="submit"
                disabled={isLoading || invalid || submitting}
              >
                Upload
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  )
}
