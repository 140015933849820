import React, { useMemo } from "react"
import { formatDate } from "../../../../helpers/dates"
import { useDialogState } from "../../../../hooks/useDialogState"
import { ScheduleActions } from "../../constants"
import { checkActionExist } from "../../helpers"
import { EditDialog } from "../../schedule/edit_dialog"
import { DashboardRow } from "./dashboard_row"
import { Location } from "./location"

export const Settings = ({ schedule, role }) => {
  const { openDialog, dialogState, closeDialog } = useDialogState()
  const canChange = useMemo(() => checkActionExist(schedule.available_actions, ScheduleActions.Update), [schedule])

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h6 className="font-weight-semibold mb-0 flex-grow-1">Schedule settings</h6>

        {canChange ? (
          <button onClick={() => openDialog(schedule)} className="btn btn-link">
            Edit
          </button>
        ) : null}
      </div>

      <DashboardRow title="Start date">{formatDate(schedule.start_on, "MMMM DD, YYYY")}</DashboardRow>

      <DashboardRow title="End date">
        {schedule.end_on ? formatDate(schedule.end_on, "MMMM DD, YYYY") : "—"}
      </DashboardRow>

      <DashboardRow title="Subject">{schedule.program.name}</DashboardRow>

      <DashboardRow title="Location">
        <Location schedule={schedule} role={role} />
      </DashboardRow>

      <DashboardRow title="Sessions">
        {schedule.scheduled_sessions_count} of {schedule.max_sessions}
      </DashboardRow>

      <DashboardRow title="Note">{schedule.note}</DashboardRow>

      <EditDialog schedule={dialogState.data} show={dialogState.open} onHide={() => closeDialog()} />
    </>
  )
}
