import React from "react"
import Button from "react-bootstrap/Button"
import arrayMutators from "final-form-arrays"
import { Form } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import Hierarchy from "./Hierarchy"
import { postAndRedirect } from "../../../common/apiClient"
import {
  RejectionConfirmationDialog,
  useRejectionDialog,
} from "../rejection_confirmation_dialog/RejectionConfirmationDialog"

const getIds = (values, programsToShow) =>
  values.map(id => {
    const program = programsToShow.find(p => p.id === id)
    return program.ptt_id
  })

const buttonDisabled = form => {
  return !form.getFieldState("program_ids") || form.getFieldState("program_ids").length === 0
}

const ProgramsToReview = ({ hierarchy, programsToShow, urls }) => {
  const { show, dialogProps } = useRejectionDialog()

  if (programsToShow.length === 0) {
    return null
  }

  return (
    <div className="col-lg-18 pending-area">
      <h5>Programs</h5>

      <Form initialValues={{ program_ids: [] }} onSubmit={() => {}} mutators={{ ...arrayMutators }}>
        {({ form }) => (
          <>
            <FieldArray name="program_ids">
              {({ fields }) => (
                <Hierarchy hierarchy={hierarchy} elements={programsToShow} form={form} fields={fields} />
              )}
            </FieldArray>
            <hr className="thin" />
            <Button
              disabled={buttonDisabled(form)}
              variant="outline-primary"
              onClick={() => {
                postAndRedirect(urls.approve, urls.redirect, {
                  entity: {
                    type: "ProgramToTutor",
                    ids: getIds(form.getState().values.program_ids, programsToShow),
                  },
                })
              }}
              data-test="check-program-group-approve"
            >
              Approve selected
            </Button>
            <Button
              disabled={buttonDisabled(form)}
              variant="secondary"
              className="reject"
              onClick={() => {
                show(getIds(form.getState().values.program_ids, programsToShow), urls, "ProgramToTutor", "program")
              }}
            >
              Reject selected
            </Button>
          </>
        )}
      </Form>

      <RejectionConfirmationDialog {...dialogProps} />
    </div>
  )
}

export default ProgramsToReview
