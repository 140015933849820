import React from "react"
import { Badge, Card, Col, Container, Image, Row } from "react-bootstrap"
import pluralize from "pluralize"

import NewMessageImage from "../../assets/images/new_chat_bubble.svg"

export const getChatStatus = (chat, showBothParties) =>
  showBothParties === true
    ? chat.chat_average_response_time_in_words
    : chat.has_replied_ever === false
    ? "Pending"
    : chat.waiting_for_reply === true
    ? "Sent"
    : "Replied"

const Chat = ({ chat, isSelected, onSelect, showBothParties }) => {
  const onSelectChat = () => {
    onSelect(chat.id)
  }

  return (
    <Container className="pb-2 px-lg-2 px-0 my-2 panel-wrapper">
      <Card className={isSelected ? "bg-white" : "bg-white chat-unselected"} onClick={onSelectChat}>
        <Row>
          <Col xs={{ span: 17, offset: 2 }} className="py-3">
            <div className="recipient-name">
              <span>
                {showBothParties === true ? chat.recipient_name + " & " + chat.sender_name : chat.recipient_name}
              </span>
              {chat.has_new_message && (
                <span>
                  <Image src={NewMessageImage} className="new-chat-message-indicator" />
                </span>
              )}
            </div>
            {chat.last_message_sent && (
              <div className="text-gray-chat">Last message sent {chat.last_message_sent} ago</div>
            )}
          </Col>
          <Col xs={{ span: 5 }} className="text-right p-3">
            <Badge pill className={"text-white p-0 message-count-badge" + (isSelected ? " bg-blue-chat-selected" : "")}>
              {chat.count_messages}
            </Badge>
          </Col>
        </Row>
        <Row className="pb-3">
          <Col xs={{ span: 8, offset: 2 }} className="text-gray-chat">
            {pluralize("Interview", chat.count_interviews, true)}
          </Col>
          <Col xs={{ span: 7 }} className="text-gray-chat">
            {pluralize("Session", chat.count_sessions, true)}
          </Col>
          <Col xs={{ span: 7 }} className="text-right text-gray-chat pr-3">
            {getChatStatus(chat, showBothParties)}
          </Col>
        </Row>
      </Card>
    </Container>
  )
}

export default Chat
