import React from "react"
import { Form, Field } from "react-final-form"
import { putAndRedirect } from "../../../common/apiClient"
import WorkingHoursCalendar from "./WorkingHoursCalendar"
import { notEmptyArray } from "../../../helpers/validators"
import RowWarning from "../../blocks/RowWarning"
import ProfileValidationProvider from "../providers/ProfileValidation"

const Calendar = ({ dataSources: { urls, user } }) => {
  const onSubmit = profile => {
    putAndRedirect(urls.save, urls.next, { profile })
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, invalid, errors }) => (
        <form onSubmit={handleSubmit} className="container">
          <ProfileValidationProvider errors={errors}>
            <RowWarning className="panel_body-warning" name="working_hours">
              <div className="d-flex justify-content-between mb-4">
                <div className="flex-grow-1">
                  <h4>Calendar</h4>
                  <span className="text-dark d-none d-md-block">
                    When would you like to work?
                    <br />
                    Click a time slot, or click and drag to create windows of availability.
                    <br />
                    Peak times for tutoring are typically 4-6pm. The more windows of availability you have during these
                    times, the more likely you are to receive a student match!
                  </span>
                  <span className="text-muted d-block d-md-none">Tap to create and remove regions</span>
                </div>
                <div className="d-flex justify-content-end align-items-start ml-4">
                  <button
                    type="submit"
                    className="btn btn-primary text-nowrap"
                    disabled={submitting || invalid || Object.keys(errors).length > 0}
                  >
                    Next
                  </button>
                </div>
              </div>
            </RowWarning>
          </ProfileValidationProvider>
          <div className="overflow-hidden">
            <div className="overflow-auto">
              <Field
                name="working_hours"
                validate={notEmptyArray}
                initialValue={user.profile.working_hours}
                render={({ input }) => <WorkingHoursCalendar {...input} FullCalendarProps={{ height: 650 }} />}
              />
            </div>
          </div>
        </form>
      )}
    </Form>
  )
}

export default Calendar
