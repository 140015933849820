import React, { useMemo } from "react"
import { formatDate } from "../../../../helpers/dates"

export const Dates = ({ opening }) => {
  const dateString = useMemo(() => {
    const startDate = formatDate(opening.start_on, "MM/DD/YYYY")
    if (!opening.end_on) {
      return `Starting ${startDate}`
    }

    const endDate = formatDate(opening.end_on, "MM/DD/YYYY")
    return `${startDate} - ${endDate}`
  }, [opening])

  return <div className="font-weight-semibold font-size-medium">{dateString}</div>
}
