import React from "react"
import { Button } from "react-bootstrap"
import cn from "classnames"
import useViewport from "../../../hooks/useViewport"

import { ReactComponent as CloseIcon } from "../../../../assets/images/icons/close.svg"

const WizardCard = ({ subtitle, title, children, buttonText, onButtonClick, onClose }) => {
  const { isMobileViewport } = useViewport()

  return (
    <div className="wizard-card">
      {onClose && (
        <div className="wizard-card_close" onClick={onClose}>
          <CloseIcon />
        </div>
      )}
      {subtitle && <div className="wizard-card_subtitle">{subtitle}</div>}
      <div className="wizard-card_title">{title}</div>
      <div className="wizard-card_body">
        <div className="wizard-card_content">{children}</div>
      </div>
      {buttonText && (
        <div className="wizard-card_footer">
          <Button
            className={cn(isMobileViewport ? "btn-link btn-link-primary" : "btn-outline-primary")}
            variant=""
            onClick={onButtonClick}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default WizardCard
