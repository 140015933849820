import React from "react"
import { Col, Row } from "react-bootstrap"

export const DashboardRow = ({ title, children, titleFontWeight = "normal", titleSpace = 8 }) => {
  return (
    <Row className="mb-2">
      <Col lg={titleSpace}>
        <div className={`font-size-medium font-weight-${titleFontWeight} text-gray`}>{title}</div>
      </Col>

      <Col lg={24 - titleSpace}>
        <div className="font-size-medium font-weight-semibold extra-dark-gray">{children}</div>
      </Col>
    </Row>
  )
}
