import React from "react"
import EmploymentHistory from "./EmploymentHistory"
import TutoredFrom from "./TutoredFrom"

const FormFields = ({ dataSources }) => {
  return (
    <div>
      <EmploymentHistory dataSources={dataSources} />
      <TutoredFrom dataSources={dataSources} />
    </div>
  )
}

export default FormFields
