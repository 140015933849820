import React, { useState } from "react"
import { Button } from "react-bootstrap"
import apiClient, { showAllErrorsInAlert } from "../../common/apiClient"
import MarkPaidModal from "./MarkPaidModal"

// TODO ALEX: remove file
const MarkPaid = ({ markPaidUrl, session }) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const requestMarkPaid = async () => {
    try {
      setIsLoading(true)
      await apiClient.post(markPaidUrl)
      setShowModal(true)
    } catch (e) {
      showAllErrorsInAlert(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button variant="link" className="btn-sm btn-link-primary" disabled={isLoading} onClick={requestMarkPaid}>
        Mark Paid
      </Button>
      <MarkPaidModal show={showModal} session={session} />
    </>
  )
}

export default MarkPaid
