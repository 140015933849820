import moment from "moment"
import React, { useEffect, useMemo } from "react"
import { Col, Row } from "react-bootstrap"
import { Form as FForm, useField, useForm, useFormState } from "react-final-form"
import { ReactComponent as AlertIcon } from "../../../assets/images/report-overdue.svg"
import FormRow from "../../../components/blocks/FormRow"
import { className } from "../../../helpers/className"
import { formatDateKeepZone, formatTime, minutesToHours } from "../../../helpers/dates"
import { clearFieldData, setFieldData } from "../../../helpers/forms"
import useViewport from "../../../hooks/useViewport"
import { DateRangeFields } from "../../fields"
import { ModernWeeklyScheduleControl } from "../../schedule_session/WeeklyScheduleControl"
import { isAssistantAddAction } from "../schedule/schedule_assistant"

const FormFieldNames = {
  From: "from",
  To: "to",
  WeekDays: "timetable",
}

const RangeNames = {
  start: FormFieldNames.From,
  end: FormFieldNames.To,
}

const createDeleteSessionInitialValue = {
  [FormFieldNames.From]: "",
  [FormFieldNames.To]: "",
  [FormFieldNames.WeekDays]: {},
}

export const fieldViewProps = {
  ColProps: { xs: 24, md: 19, className: "mb-0 mt-n1 px-0 form-input-align flex-column" },
  LabelProps: { xs: 24, lg: 5, className: "v-2 col-form-label px-0" },
  RowProps: { className: "px-2" },
  className: "px-4",
}

const getSessionsCountInRange = (sessions = [], weekDays, from, to) =>
  sessions.filter(session => {
    const date = formatDateKeepZone(session.started_at)
    return (
      moment(date).isBetween(from, to, "date", "[]") &&
      weekDays.indexOf(moment(date).format("dddd").toLowerCase()) !== -1
    )
  }).length

const createDeleteSessionsFormValidate =
  ({ action, schedules }) =>
  data => {
    const acc = {}
    const from = data[FormFieldNames.From]
    const to = data[FormFieldNames.To]
    const weekDays = data[FormFieldNames.WeekDays]
    const isSingle = schedules.length === 1
    const [{ max_duration }] = schedules

    if (!from) acc[FormFieldNames.From] = "Start date is required"
    if (!to) acc[FormFieldNames.To] = "End date is required"

    if (from && to && moment(from).isAfter(to)) {
      acc[FormFieldNames.From] = "Start date should be greater than end date"
      acc[FormFieldNames.To] = "Start date should be less than end date"
    }
    if (!weekDays || Object.keys(weekDays).length === 0) acc[FormFieldNames.WeekDays] = "Schedule is required to fill"

    if (isAssistantAddAction(action) && weekDays) {
      for (const weekDay of Object.keys(weekDays)) {
        const { time, duration } = weekDays[weekDay] || {}
        let message
        if (!time) message = `Time of ${weekDay} isn't filled`
        else if (!duration) message = `Duration of ${weekDay} isn't filled`
        else if (isSingle && max_duration && duration > max_duration) {
          const [hours, minutes] = minutesToHours(max_duration || 0)
          message = `Sessions can be no longer than ${hours > 0 && ` ${hours}h`}${minutes > 0 && ` ${minutes}min`}`
        }
        if (message) {
          acc[FormFieldNames.WeekDays] = message
          break
        }
      }
    }

    return acc
  }

const CreateDeleteSessionsAlert = ({ isAdd, sessions }) => {
  const fromField = useField(FormFieldNames.From)
  const toField = useField(FormFieldNames.To)
  const weekDaysField = useField(FormFieldNames.WeekDays)

  const [from, to, weekDays] = useMemo(
    () => [fromField.input?.value, toField.input?.value, weekDaysField.input?.value],
    [fromField.input.value, toField.input.value, weekDaysField.input.value]
  )

  const sessionsCount = useMemo(() => {
    if (!from || !to || !weekDays) return 0
    return getSessionsCountInRange(sessions, Object.keys(weekDays), from, to)
  }, [from, sessions, to, weekDays])

  if (!from || !to || sessionsCount === 0) return null

  return (
    <div className="schedule_assistant_alert">
      <div className="icon-16-px icon-orange-color">
        <AlertIcon />
      </div>
      <span className="ml-2">
        {sessionsCount} Session{sessionsCount > 1 && "s"} scheduled in this timeframe will be{" "}
        {isAdd ? "overwritten" : "deleted"}
      </span>
    </div>
  )
}

const CreateDeleteSessionsFormFields = ({
  isAdd,
  maxDuration,
  maxSessions,
  scheduledSessions,
  loading,
  reset,
  minDate,
  maxDate,
  completeReset,
  children,
  sessions,
}) => {
  const { isMobileViewport } = useViewport()

  const dateLabelProps = useMemo(
    () => ({
      ...fieldViewProps.LabelProps,
      className: className(fieldViewProps.LabelProps.className, !isMobileViewport && "mt-2 pt-4"),
    }),
    [isMobileViewport]
  )

  const form = useForm()
  const { errors } = useFormState()

  useEffect(() => {
    if (reset) {
      form.reset()
      completeReset()
    }
  }, [completeReset, form, reset])

  const fromField = useField(FormFieldNames.From)
  const toField = useField(FormFieldNames.To)
  const weekDaysField = useField(FormFieldNames.WeekDays)

  const sessionsCount = useMemo(() => {
    const [from, to, weekDays] = [fromField.input?.value, toField.input?.value, weekDaysField.input?.value]
    if (!from || !to || !weekDays) return 0
    return getSessionsCountInRange(sessions, Object.keys(weekDays), from, to)
  }, [fromField.input?.value, toField.input?.value, weekDaysField.input?.value, sessions])

  const isSubmitDisabled = isAdd
    ? Object.keys(errors).length > 0 || !maxDuration || !maxSessions || (scheduledSessions >= maxSessions && sessionsCount === 0)
    : Object.keys(errors).length > 0 || sessionsCount === 0

  return (
    <>
      <FormRow label="Date range" {...fieldViewProps} LabelProps={dateLabelProps}>
        <Row>
          <Col xs={24} lg={16}>
            <DateRangeFields disabled={loading} names={RangeNames} minDate={minDate} maxDate={maxDate} />
          </Col>
        </Row>
      </FormRow>
      <hr className="my-0 soft-color" />
      <div className="mt-4">
        <ModernWeeklyScheduleControl
          showTimeDuration={isAdd}
          maxDuration={maxDuration}
          disabled={loading}
          formRowProps={fieldViewProps}
          name={FormFieldNames.WeekDays}
        />
      </div>
      <hr className="my-0 soft-color" />
      <Row className="px-4 py-3 align-items-center justify-content-between">
        <Col xs="auto">
          <Row className="align-items-center">
            <Col xs="auto">
              <button
                disabled={isSubmitDisabled}
                className="btn btn-primary"
                type="submit"
                data-test="submit-calendar-assistant"
              >
                {isAdd ? "Add sessions" : "Delete sessions"}
              </button>
              {(!maxDuration || !maxSessions) && (
                <span className="form-control_caption" style={{ marginLeft: "10px" }}>
                  Max Sessions and Duration need to be set
                </span>
              )}
              {scheduledSessions >= maxSessions && isAdd && (
                <span className="form-control_caption" style={{ marginLeft: "10px" }}>
                  You have reached the maximum number of sessions
                </span>
              )}
            </Col>
            {loading && (
              <Col xs="auto" className="ml-4">
                <div className="spinner-border text-primary" />
              </Col>
            )}
          </Row>
        </Col>
        <Col xs="auto">{children}</Col>
      </Row>
    </>
  )
}

export const CreateDeleteSessionsForm = ({
  action,
  sessions,
  schedules,
  loading,
  reset,
  minDate,
  maxDate,
  completeReset,
  onSubmit,
}) => {
  const submit = data => {
    const weekDays = Object.keys(data[FormFieldNames.WeekDays])
    const count = getSessionsCountInRange(sessions, weekDays, data[FormFieldNames.From], data[FormFieldNames.To])
    for (const day of Object.values(data.timetable)) day.time = formatTime(day.time, "HH:mm")
    onSubmit(data, count)
  }
  const [maxDuration, maxSessions, scheduledSessions] = useMemo(() => {
    return schedules.reduce(
      ([maxDur, maxSess, schedSess], schedule) => {
        const newMaxDur =
          (schedule.max_duration && schedule.max_duration < maxDur) || maxDur === 0 ? schedule.max_duration : maxDur
        const newMaxSess =
          (schedule.max_sessions && schedule.max_sessions < maxSess) || maxSess === 0 ? schedule.max_sessions : maxSess
        const newSchedSess =
          (schedule.scheduled_sessions_count && schedule.scheduled_sessions_count < schedSess) || schedSess === 0
            ? schedule.scheduled_sessions_count
            : schedSess

        return [newMaxDur, newMaxSess, newSchedSess]
      },
      [0, 0, 0]
    )
  }, [schedules])

  const isAdd = useMemo(() => isAssistantAddAction(action), [action])

  return (
    <FForm
      initialValues={createDeleteSessionInitialValue}
      validate={createDeleteSessionsFormValidate({ action, schedules })}
      mutators={{ clearFieldData, setFieldData }}
      onSubmit={submit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CreateDeleteSessionsFormFields
            isAdd={isAdd}
            maxDuration={maxDuration}
            maxSessions={maxSessions}
            scheduledSessions={scheduledSessions}
            loading={loading}
            reset={reset}
            minDate={minDate}
            maxDate={maxDate}
            completeReset={completeReset}
            sessions={sessions}
          >
            {sessions && <CreateDeleteSessionsAlert isAdd={isAdd} sessions={sessions} />}
          </CreateDeleteSessionsFormFields>
        </form>
      )}
    </FForm>
  )
}
