import { isEmpty } from "lodash"
import moment from "moment"
import * as EmailValidator from "email-validator"

export const chain =
  (...fns) =>
  value => {
    let result = undefined
    fns.forEach(validator => (result = result || validator(value)))
    return result
  }

export const required = value => (value ? undefined : "Required")

export const selectRequired = value => (!!value && value !== "empty" ? undefined : "Required")

export const notEmptyArray = value => (value && value.length ? undefined : "Required")

export const float = value => (value && !isNaN(parseFloat(value)) ? undefined : "Invalid")

export const mustBeGreaterOrEqual = number => value =>
  value !== "" && !isNaN(parseFloat(value)) && value >= number ? undefined : `Must be greater or equal than ${number}`

export const validateEmail = value => (EmailValidator.validate(value) ? undefined : "Invalid email address")

export const validateZip = value =>
  !value || (value && /^\d{5}([-]|\s*)?(\d{4})?$/.test(value)) ? undefined : "Invalid"

export const validateSchedule = value => {
  if (!value || isEmpty(value)) {
    return "Required"
  }
  if (Object.keys(value).some(day => !value[day].duration || !value[day].time)) {
    return "Invalid"
  }
}

export const afterDate = beforeDate => value => value && moment(value).isAfter(beforeDate) ? undefined : "Invalid"

export const isValidLocation = ({ offline, zip }) => offline && zip && !validateZip(zip)

export const isValidExtendedLocation = ({ online, address_1, city, state, zip }) => {
  if (online || (address_1 && city && state && !validateZip(zip))) {
    return true
  }
  return false
}

export const isValidOffline = ({ address_1, city, state, zip }) =>
  address_1 && city && state && zip && !validateZip(zip)

export const isMeetingInfoValid = ({ meeting_info }) => Boolean(meeting_info)

export const isNumeric = value => {
  return !isNaN(parseFloat(value)) && isFinite(value)
}

export const propertyGetter = source => name =>
  (Array.isArray(name) ? name : [name]).reduce((value, path) => {
    if (typeof value === "boolean") return value
    return value[path] || false
  }, source)

export const validateLength =
  ({ expected, type = "eq" }) =>
  val => {
    const value = `${val}`.length
    let result = true // means no error
    switch (type) {
      case "eq":
        result = value === expected
        break
      case "l":
        result = value < expected
        break
      case "leq":
        result = value <= expected
        break
      case "g":
        result = value > expected
        break
      case "geq":
        result = value >= expected
        break
      default:
        result = false
    }
    return !result
  }
