import React, { useMemo } from "react"
import { Form as FForm, Field } from "react-final-form"
import FormRow from "../../../blocks/FormRow"
import { DateRangeFields, LocationFields, SchoolField, SubjectField } from "../../../fields"
import { Col, Row, Form } from "react-bootstrap"
import Dropdown from "../../../blocks/Dropdown"
import { LearningLevel } from "../../../../constants"
import useViewport from "../../../../hooks/useViewport"
import DurationField from "../../../blocks/DurationField"
import { getInitialValues, validateOpening } from "./helpers"
import { OpeningFieldsNames, TutoringTypes } from "./constants"
import { OpeningTutoringTypes } from "../../constants"

const OpeningFormRow = ({ children, required, label, extendedPadding }) => {
  const { isMobileViewport } = useViewport()
  return (
    <FormRow
      required={required}
      label={label}
      className="mb-4"
      RowProps={{ className: "px-2" }}
      LabelProps={{
        xs: 24,
        lg: 8,
        className: "v-2 col-form-label px-0" + (extendedPadding && !isMobileViewport ? " mt-2 pt-4" : ""),
      }}
      ColProps={{ xs: 24, lg: 16, className: "mb-0 mt-n1 px-0 form-input-align flex-column" }}
    >
      {children}
    </FormRow>
  )
}

export const OpeningForm = ({ opening, onSubmit, loading }) => {
  const initialValues = useMemo(() => getInitialValues(opening), [opening])

  return (
    <FForm onSubmit={onSubmit} validate={validateOpening} initialValues={initialValues}>
      {({ handleSubmit, pristine }) => (
        <Row as="form" onSubmit={handleSubmit}>
          <Col xs={24} lg={20} xl={14}>
            <h5 className="font-weight-semibold mb-4">Opening settings</h5>
            <OpeningFormRow required label="School">
              <SchoolField name={OpeningFieldsNames.School} />
            </OpeningFormRow>

            <OpeningFormRow required label="Learning level">
              <Field type="text" name={OpeningFieldsNames.LearningLevel}>
                {({ input, meta }) => (
                  <Dropdown
                    placeholder="Select level"
                    selected={input.value}
                    error={meta.touched ? meta.error : null}
                    buildLabel={option => option.name}
                    buildValue={JSON.stringify}
                    options={LearningLevel}
                    onChange={input.onChange}
                    dataTest="learning-level-dropdown"
                  />
                )}
              </Field>
            </OpeningFormRow>

            <OpeningFormRow required label="Subject">
              <SubjectField name={OpeningFieldsNames.Subject} />
            </OpeningFormRow>

            <OpeningFormRow required label="Tutoring type">
              <Row className="align-items-center py-2 mt-1">
                <Col xs={24} lg="auto">
                  <Field
                    name={OpeningFieldsNames.TutoringType}
                    type="radio"
                    value={OpeningTutoringTypes.Groups}
                    defaultValue
                  >
                    {({ input }) => (
                      <Form.Check {...input} id="groups" className="text-nowrap mr-2 v-2" label="Groups" custom />
                    )}
                  </Field>
                </Col>

                <Col xs={24} lg="auto">
                  <Field name={OpeningFieldsNames.TutoringType} type="radio" value={OpeningTutoringTypes.Individual}>
                    {({ input }) => (
                      <Form.Check
                        {...input}
                        id="individual"
                        className="text-nowrap mr-2 v-2"
                        label="Individual sessions"
                        custom
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </OpeningFormRow>

            <OpeningFormRow label="Note for tutors">
              <Field name={OpeningFieldsNames.NoteForTutors} type="text">
                {({ input }) => <Form.Control as="textarea" {...input} className="v-2" rows="3" />}
              </Field>
            </OpeningFormRow>

            <OpeningFormRow label="Internal note">
              <Field name={OpeningFieldsNames.InternalNote} type="text">
                {({ input }) => <Form.Control as="textarea" {...input} className="v-2" rows="3" />}
              </Field>
            </OpeningFormRow>

            {/*<OpeningFormRow label="Tutor binding statement">
              <Field name={OpeningFieldsNames.TutorBindingStatement} type="text">
                {({ input }) => <Form.Control as="textarea" {...input} className="v-2" rows="3" />}
              </Field>
            </OpeningFormRow>*/}

            <OpeningFormRow required label="School rate">
              <Row>
                <Col xs={12} lg={7}>
                  <Field name={OpeningFieldsNames.SchoolRate} type="number">
                    {({ input, meta }) => (
                      <>
                        <Form.Control
                          {...input}
                          className="v-2"
                          placeholder="10"
                          isInvalid={meta.touched && meta.error}
                        />

                        {meta.touched && meta.error ? (
                          <Form.Control.Feedback type="invalid" className="mt-1">
                            {meta.error}
                          </Form.Control.Feedback>
                        ) : null}
                      </>
                    )}
                  </Field>
                </Col>
                <Col xs={12} lg={5} className="pt-3">
                  / hour
                </Col>
              </Row>
            </OpeningFormRow>

            <h5 className="font-weight-semibold mb-4 mt-5">Default Connection Settings</h5>
            <OpeningFormRow required label="Tutoring position">
              <Row>
                <Col xs={24} lg={12}>
                  <Field type="text" name={OpeningFieldsNames.PositionLevel}>
                    {({ input, meta }) => (
                      <Dropdown
                        placeholder="Select position"
                        selected={input.value}
                        error={meta.touched ? meta.error : null}
                        buildLabel={option => option.name}
                        buildValue={JSON.stringify}
                        options={Object.values(TutoringTypes)}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </OpeningFormRow>

            <OpeningFormRow label="Tutoring dates" extendedPadding>
              <DateRangeFields
                startRequired
                names={{ start: OpeningFieldsNames.StartOn, end: OpeningFieldsNames.EndOn }}
              />
            </OpeningFormRow>

            <OpeningFormRow required label="Tutor rate">
              <Row>
                <Col xs={12} lg={7}>
                  <Field name={OpeningFieldsNames.TutorRate} type="number">
                    {({ input, meta }) => (
                      <>
                        <Form.Control
                          {...input}
                          className="v-2"
                          placeholder="10"
                          isInvalid={meta.touched && meta.error}
                        />

                        {meta.touched && meta.error ? (
                          <Form.Control.Feedback type="invalid" className="mt-1">
                            {meta.error}
                          </Form.Control.Feedback>
                        ) : null}
                      </>
                    )}
                  </Field>
                </Col>
                <Col xs={12} lg={5} className="pt-3">
                  / hour
                </Col>
              </Row>
            </OpeningFormRow>

            <OpeningFormRow required label="Paid no shows">
              <Row>
                <Col xs={12} lg={7}>
                  <Field name={OpeningFieldsNames.PaidNoShows} type="number">
                    {({ input, meta }) => (
                      <>
                        <Form.Control
                          {...input}
                          className="v-2"
                          placeholder="10"
                          isInvalid={meta.touched && meta.error}
                        />

                        {meta.touched && meta.error ? (
                          <Form.Control.Feedback type="invalid" className="mt-1">
                            {meta.error}
                          </Form.Control.Feedback>
                        ) : null}
                      </>
                    )}
                  </Field>
                </Col>
                <Col xs={12} lg={5} className="pt-3">
                  per tutor
                </Col>
              </Row>
            </OpeningFormRow>

            <h5 className="font-weight-semibold mb-4 mt-5">Default Schedule settings</h5>
            <OpeningFormRow required label="Default location">
              <LocationFields />
            </OpeningFormRow>

            <OpeningFormRow label="Report cadence">
              {/*<Row className="align-items-center py-2 mt-1">*/}
              {/*  <Col xs={24} lg="auto">*/}
              {/*    <Field name={OpeningFieldsNames.ReportCadence} type="radio" value="weekly" defaultValue>*/}
              {/*      {({ input }) => (*/}
              {/*        <Form.Check {...input} id="weekly" className="text-nowrap mr-2 v-2" label="Weekly" custom />*/}
              {/*      )}*/}
              {/*    </Field>*/}
              {/*  </Col>*/}

              {/*  <Col xs={24} lg="auto">*/}
              {/*    <Field name={OpeningFieldsNames.ReportCadence} type="radio" value="per_session">*/}
              {/*      {({ input }) => (*/}
              {/*        <Form.Check*/}
              {/*          {...input}*/}
              {/*          id="per_session"*/}
              {/*          className="text-nowrap mr-2 v-2"*/}
              {/*          label="Per session"*/}
              {/*          custom*/}
              {/*        />*/}
              {/*      )}*/}
              {/*    </Field>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              <span className="py-2 my-1 font-weight-semibold">Weekly</span>
            </OpeningFormRow>

            <OpeningFormRow label="Upcoming session notification">
              <Row className="align-items-center py-2 mt-1">
                <Col xs={24} lg="auto">
                  <Field name={OpeningFieldsNames.SessionNotification} type="radio" value="no" defaultValue>
                    {({ input }) => (
                      <Form.Check
                        {...input}
                        id="do_not_notify"
                        className="text-nowrap mr-2 v-2"
                        label="Do not notify parents"
                        custom
                      />
                    )}
                  </Field>
                </Col>

                <Col xs={24} lg="auto">
                  <Field name={OpeningFieldsNames.SessionNotification} type="radio" value="yes">
                    {({ input }) => (
                      <Form.Check
                        {...input}
                        id="notify"
                        className="text-nowrap mr-2 v-2"
                        label="Notify parents about upcoming"
                        custom
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </OpeningFormRow>

            <OpeningFormRow label="Max session duration">
              <Row>
                <Col xs={12} lg={7}>
                  <Field name={OpeningFieldsNames.Duration} type="number">
                    {({ input, meta }) => (
                      <>
                        <DurationField
                          {...input}
                          className="v-2"
                          placeholder="Duration"
                          invalid={meta.touched && meta.error}
                          error={meta.error}
                        />
                      </>
                    )}
                  </Field>
                </Col>
              </Row>
            </OpeningFormRow>

            <OpeningFormRow label="Maximum sessions">
              <Row>
                <Col xs={12} lg={7}>
                  <Field name={OpeningFieldsNames.MaxSessions} type="number">
                    {({ input, meta }) => (
                      <>
                        <Form.Control
                          {...input}
                          className="v-2"
                          placeholder="10"
                          isInvalid={meta.touched && meta.error}
                        />

                        {meta.touched && meta.error ? (
                          <Form.Control.Feedback type="invalid" className="mt-1">
                            {meta.error}
                          </Form.Control.Feedback>
                        ) : null}
                      </>
                    )}
                  </Field>
                </Col>
              </Row>
            </OpeningFormRow>

            <hr />

            <Row>
              <Col className="flex-grow-0 mr-3">
                <button className="btn btn-primary" type="submit" disabled={pristine || loading}>
                  {opening ? "Save" : "Create"}
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </FForm>
  )
}
