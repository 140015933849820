import React from "react"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { putAndRedirect } from "../../../common/apiClient"
import FormFields from "./FormFields"
import { Container, Button } from "react-bootstrap"

const InviteParent = ({ invitation, urls, grades }) => {
  const onSubmit = values => {
    putAndRedirect(urls.save, urls.index, { invite: values })
  }

  const disableAll = invitation.status !== "pending"

  return (
    <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} initialValues={invitation}>
      {({ handleSubmit, submitting }) => (
        <Container className="panel-wrapper">
          <FormFields grades={grades} disableAll={disableAll} />

          <div className="d-flex justify-content-end align-items-center">
            <a href={urls.index} className="btn btn-link btn-link-primary mr-5">
              Cancel
            </a>

            <Button variant="outline-primary" onClick={handleSubmit} disabled={submitting || disableAll}>
              Continue
            </Button>
          </div>
        </Container>
      )}
    </Form>
  )
}
export default InviteParent
