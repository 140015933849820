import { ReactComponent as SignOutIcon } from "@/assets/images/sign-out.svg"
import { STUDENT_URLS } from "@/constants"
import axios from "axios"
import Alerter from "common/alerter"
import React from "react"
import { Dropdown } from "react-bootstrap"
import LogoImage from "../../assets/images/logo.svg"

/* TODO:
  Currently this component is only serving the students area.
  In the future we should make it reusable and generic to unify application nav.
*/

const getInitials = name => {
  const [last, first] = name.split(", ")
  return `${first[0]}${last[0]}`
}

const Navbar = ({ user }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const csrfToken = document.querySelector("[name=csrf-token]")?.content || ""

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleSignOut = async () => {
    try {
      await axios.delete(STUDENT_URLS.signOut, {
        timeout: 10000,
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": csrfToken,
        },
        credentials: "same-origin",
      })
    } catch (error) {
      if (error.response && error.response.data) {
        Alerter.error(error.response.data["error"])
      } else {
        Alerter.error("An error occurred while signing out")
      }
    } finally {
      window.location.href = STUDENT_URLS.signIn
    }
  }

  return (
    <nav className="container header-container students-navbar">
      <div className="navbar-left">
        <a className="navbar-logo" href={STUDENT_URLS.dashboard}>
          <img src={LogoImage} alt="Braintrust Logo" />
        </a>
        <div className="navbar-divider" />
        <div className="navbar-school-name">{user && user.school_name}</div>
      </div>
      <Dropdown show={isMenuOpen} onToggle={handleClick} size="sm" alignRight>
        <Dropdown.Toggle className="dropdown-toggle-no-icon btn-light p-0">
          <div className="user-info-toggler">
            <div className="user-name">{user.name}</div>
            <div className="user-info_initials">{getInitials(user.name)}</div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ offset: 0 }} className="user-menu">
          <Dropdown.Item
            as="button"
            className="user-menu-item px-3 py-2"
            id="signOutButton"
            data-turbo="false"
            onClick={handleSignOut}
          >
            <SignOutIcon width="20px" height="18px" />
            Sign Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </nav>
  )
}

export default Navbar
