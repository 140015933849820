import { compose, get, map, find, filter } from "lodash/fp"

import { EXECUTIVE_FUNCTION_DOMAIN } from "../common/constants"

/**
 * @typedef {{ domain: { id: string, name: string }, programs: { id: number, name: string } }} ProgramHierarchyDomain
 * @typedef {{ grade: { id: string, name: string }, division: { id: string, name: string }, domains: Array<ProgramHierarchyDomain> }} ProgramHierarchyItem
 * @typedef {{ id: number, name: string, domain: { id: string, name: string }, grade: { id: string, name: string }, division: { id: string, name: string } }} ProgramData
 * @typedef {{ domain: string, grade: Array<string>, division: string, main_question: string, answers: Array<{ id: number, name: string }> }} StruggleWithQuestion
 */

/**
 * @function getDomainsByGrade
 * @description get filtered domains from programs hierarchy by grade
 *
 * @param {number} gradeId - grade id for filtering domains
 * @param {Array<ProgramHierarchyItem>} hierarchy - programs hierarchy by grade
 * @return {Array<ProgramHierarchyDomain>}
 */
export const getDomainsByGrade = (gradeId, hierarchy) => compose(get("domains"), find(["grade.id", gradeId]))(hierarchy)

/**
 * @function programsHierarchyToList
 * @description get list of programs with domain, grade and division
 *
 * @param {Array<ProgramHierarchyItem>} hierarchy - programs hierarchy by grade
 * @return {Array<ProgramData>}
 */
export const programsHierarchyToList = hierarchy => {
  const programsList = []
  hierarchy.forEach(({ division, grade, domains }) => {
    domains.forEach(({ domain, programs }) => {
      programs.forEach(program => {
        programsList.push({
          ...program,
          domain,
          grade,
          division,
        })
      })
    })
  })
  return programsList
}

/**
 * @function getDomainByProgram
 *
 * @param {number} programId - program id to search
 * @param {Array<ProgramHierarchyDomain>} gradeDomains - filtered domains by grade id
 * @return {(ProgramHierarchyDomain|undefined)}
 */
export const getDomainByProgram = (programId, gradeDomains) =>
  find(({ programs }) => programs.find(({ id }) => id === programId))(gradeDomains)

/**
 * @function getDomainById
 *
 * @param {Array<ProgramHierarchyDomain>} gradeDomains - filtered domains by grade id
 * @param {number} id - domain id
 * @returns {({ id: string, name: string }|undefined)}
 */
export const getDomainById = (id, gradeDomains) => compose(get("domain"), find(["domain.id", id]))(gradeDomains)

/**
 * @function getDomainsByValues
 * @description get filtered domains from programs hierarchy by grade without executive function
 *
 * @param {Array<ProgramHierarchyDomain>} gradeDomains
 * @param {Array<number>} programIds - program ids
 * @param {boolean} executiveFunction
 * @return {Array<string>}
 */
const getDomainsByValues = (gradeDomains, programIds, executiveFunction) => {
  const result = []
  if (programIds && programIds.length > 0) {
    programIds.forEach(id => {
      const programDomain = getDomainByProgram(id, gradeDomains)
      const domainId = get("domain.id")(programDomain)
      if (!result.includes(domainId)) {
        result.push(domainId)
      }
    })
  }
  if (executiveFunction) {
    result.push(EXECUTIVE_FUNCTION_DOMAIN)
  }
  return result
}

/**
 * @function getMatchedQuestions
 * @description get struggle with questions
 *
 * @param {number} gradeId
 * @param {Array<number>} programIds - program ids
 * @param {boolean} executiveFunction
 * @param {{ struggleWithQuestions: Array<StruggleWithQuestion>, hierarchy: Array<ProgramHierarchyItem> }} options
 * @return {Array<{ domain: { id: string, name: string }, main_question: string, answers: Array<{ id: number, text: string }> }>}
 */
export const getMatchedQuestions = (gradeId, programIds, executiveFunction, options) => {
  const questions = options.struggleWithQuestions
  const gradeDomains = gradeId ? getDomainsByGrade(gradeId, options.hierarchy) : []
  const domains = getDomainsByValues(gradeDomains, programIds, executiveFunction, options)

  return compose(
    map(({ domain, ...data }) => ({ ...data, domain })),
    filter(({ domain, grade }) => grade.includes(gradeId) && domains.includes(domain))
  )(questions)
}

export const isExecutiveFunctionProgram = (programId, programs) => {
  return compose(get("domain.id"), find(["id", programId]))(programs) === EXECUTIVE_FUNCTION_DOMAIN
}

export const getDomainsOptions = (gradeId, hierarchy) =>
  compose(
    map(({ domain, programs }) => ({ ...domain, programs })),
    hierarchy => getDomainsByGrade(gradeId, hierarchy)
  )(hierarchy)
