import React from "react"
import { dateRangeMapper } from "../opening_information/text_mappers"
import { ReactComponent as Calendar } from "../../../../assets/images/calendar1.svg"

const NodeType = ({ href, onClick, dataTest, children }) => {
  if (typeof href === "string") {
    return (
      <a href={href} target="_black" className="font-weight-medium font-size-small text-capitalize" onClick={onClick}>
        {children}
      </a>
    )
  } else if (typeof onClick === "function") {
    return (
      <button
        className="btn btn-link font-weight-medium font-size-small text-capitalize border-0"
        onClick={onClick}
        data-test={dataTest}
      >
        {children}
      </button>
    )
  }
  return <span className="font-weight-medium font-size-small text-capitalize">{children}</span>
}

export const ScheduleInfo = ({ startOn, endOn, note, href, onClick, dataTest }) => {
  return (
    <div className="d-flex flex-column align-items-start">
      <label className="d-flex align-items-center gap-025 mb-0">
        <div className="icon-24-px text-primary">
          <Calendar />
        </div>
        <NodeType href={href} onClick={onClick} dataTest={dataTest}>
          {dateRangeMapper(startOn, endOn)}
        </NodeType>
      </label>
      {note && <span className="text-black-50 ml-4 pl-2">{note}</span>}
    </div>
  )
}
