import React, { useMemo } from "react"
import { Row, Col, Modal, Button, FormControl, FormCheck } from "react-bootstrap"
import { Form, Field } from "react-final-form"
import { formatDateTimeKeepZone } from "../../../helpers/dates"

import { required } from "../../../helpers/validators"
import InterviewSlot from "../schedule_interview/InterviewSlot"

const ConfirmModalContent = ({ interview, onShowAlternateTime, onSubmit, onClose }) => {
  const initialValues = useMemo(() => {
    return {
      ...interview,
      start_at_slots: interview.start_at_slots.map(v => formatDateTimeKeepZone(v)),
      started_at: formatDateTimeKeepZone(interview.started_at) || formatDateTimeKeepZone(interview.start_at_slots[0]),
    }
  }, [interview])

  const handleSubmit = values => {
    onSubmit({
      started_at: formatDateTimeKeepZone(values.started_at),
      call_details: values.call_details,
    })
  }

  return (
    <>
      <Modal.Header className="px-4 pt-3 pb-2" closeButton>
        <Modal.Title>Video interview details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          {({ invalid, submitting, handleSubmit }) => (
            <Row>
              <Col lg={18}>
                <h4>Date and time</h4>

                <p>
                  {interview.start_at_slots.length > 0
                    ? "The parent suggested the times below for an interview. Please pick the time that’s best for you!"
                    : "The parent suggested the time below for an interview."}
                </p>

                {interview.start_at_slots.map(slot => (
                  <div key={slot}>
                    <Field name="started_at" type="radio" value={slot}>
                      {({ input }) => (
                        <FormCheck
                          {...input}
                          id={slot}
                          label={<InterviewSlot start={slot} tag="div" timePosition="start" />}
                          custom
                        />
                      )}
                    </Field>
                  </div>
                ))}

                <p className="my-3">
                  If these aren’t a good fit,
                  <Button variant="link" className="btn-link-primary ml-1" onClick={onShowAlternateTime}>
                    suggest a different time to connect
                  </Button>
                  .
                </p>

                <h4 className="mt-5 mb-3">Add call details</h4>
                <p className="pb-2">
                  How should the parent contact you? Paste a link for a Zoom or Google Meet! This can be edited later.
                </p>

                <Field name="call_details" validate={required}>
                  {({ input }) => <FormControl {...input} as="textarea" rows={5} />}
                </Field>

                <div className="mt-5">
                  <Button
                    variant="outline-primary"
                    className="anl-btn"
                    disabled={invalid || submitting}
                    onClick={handleSubmit}
                  >
                    Confirm
                  </Button>
                  <Button variant="link" className="link-primary ml-5" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
    </>
  )
}

export default ConfirmModalContent
