import React, { useState } from "react"
import cn from "classnames"
import { Dropdown, Button } from "react-bootstrap"
import { Field, useForm, useField, useFormState } from "react-final-form"
import Slider from "rc-slider"

const MIN_PRICE = 40
const MAX_PRICE = 500
const DEFAULT_PRICE = 100

const MARKS = {
  [MIN_PRICE]: `${MIN_PRICE}$`,
  [MAX_PRICE]: `${MAX_PRICE}$`,
}

const SliderPriceDropdown = ({ completed, getButtonText, onSubmit }) => {
  const [showPopper, setShowPopper] = useState(false)

  const form = useForm()
  const { values } = useFormState()
  const { meta } = useField("max_price")

  const onToggle = show => {
    if (show) {
      setShowPopper(true)
      if (!values.max_price) {
        form.mutators.setFieldData("max_price", DEFAULT_PRICE)
      }
      return
    }

    setShowPopper(false)
    if (!meta.pristine) {
      onSubmit()
    }
  }

  return (
    <Field name="max_price" type="number">
      {({ input }) => (
        <Dropdown show={showPopper} onToggle={onToggle}>
          <Dropdown.Toggle
            variant="outline-dropdown"
            className={cn("dropdown-toggle-no-icon", "fullwidth", completed && "selected")}
          >
            {getButtonText(input.value)}
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-4 min-w-md-400-px max-vw-100 overflow-auto">
            <h6 className="mb-4">Session price</h6>
            <p className="text-nowrap mt-2">up to ${input.value} per hour</p>
            <Slider
              min={input.value ? MIN_PRICE : 0}
              max={MAX_PRICE}
              defaultValue={DEFAULT_PRICE}
              marks={MARKS}
              dots={false}
              dotStyle={{ display: "none" }}
              className="rc-slider-fit-marks"
              {...input}
            />

            <Button variant="outline-primary" size="sm" className="mt-4" type="button" onClick={() => onToggle(false)}>
              Ok
            </Button>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Field>
  )
}

export default SliderPriceDropdown
