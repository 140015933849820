import React from "react"
import { Field } from "react-final-form"
import FormRow from "../../../blocks/FormRow"
import { FormControl } from "react-bootstrap"
import { showFieldError } from "../../../../helpers/forms"
import { labelSize, colSize } from "../FormFields"
import FormSelect from "../../../blocks/FormSelect"
import { required } from "../../../../helpers/validators"

const Partnership = ({ isFormDisabled, partnerships }) => {
  return (
    <Field name="partnership_id" initialValue={partnerships && partnerships[0].value} validate={required}>
      {({ input, meta }) => (
        <FormRow label="Partnership Type" LabelProps={labelSize} ColProps={colSize} required>
          <FormSelect {...input} options={partnerships} disabled={isFormDisabled} isInvalid={showFieldError(meta)} />
          <FormControl.Feedback type="invalid">{meta.error || meta.submitError}</FormControl.Feedback>
        </FormRow>
      )}
    </Field>
  )
}

export default Partnership
