import React from "react"

const Switch = ({ name, label, checked, onChange, disabled, wrapperClassName, className }) => {
  return (
    <div className={`brain-trust-switch-wrapper py-2 ${wrapperClassName || ""}`}>
      <label htmlFor={name} className={`brain-trust-switch ${className || ""}`}>
        <input
          type="checkbox"
          name={name}
          onChange={onChange}
          id={name}
          checked={checked}
          hidden
          disabled={disabled}
          data-test={name}
        />
        <div className="brain-trust-switch-input mr-2" />
        <span className="brain-trust-switch-label" dangerouslySetInnerHTML={{ __html: label }} />
      </label>
    </div>
  )
}

export default Switch
