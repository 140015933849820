import React, { useMemo, useState } from "react"
import { Modal } from "react-bootstrap"
import { useFormState, useForm } from "react-final-form"
import { filter } from "lodash/fp"

import schema from "./schema"
import WizardCard from "../wizard/WizardCard"

const steps = filter("WizardComponent")(schema)

const WizardModal = ({ show, options, onClose }) => {
  const { values } = useFormState()
  const [stepIndex, setStepIndex] = useState(0)

  const form = useForm()

  const { WizardComponent, WizardComponentProps: wizardProps, completed, ...stepProps } = steps[stepIndex]

  const stepText = useMemo(() => `Question ${stepIndex + 1}/${steps.length}`, [stepIndex])
  const buttonText = useMemo(() => {
    if (wizardProps.nextButtonText && completed(values)) {
      return wizardProps.nextButtonText
    }
    if (wizardProps.skipButtonText && !completed(values)) {
      return wizardProps.skipButtonText
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardProps, values])

  const onStepSubmit = () => {
    if (stepIndex + 1 < steps.length) {
      setStepIndex(stepIndex + 1)
    } else {
      onFormSubmit()
    }
  }

  const onFormSubmit = () => {
    form.submit()
    form.mutators.setFieldData("page", 1)
    onClose()
    setStepIndex(0)
  }

  return (
    <Modal show={show} size="lg" className="wizard-modal" onHide={onFormSubmit}>
      <Modal.Body className="p-0">
        <WizardCard
          subtitle={stepText}
          title={wizardProps.title}
          buttonText={buttonText}
          onButtonClick={onStepSubmit}
          onClose={onFormSubmit}
        >
          <WizardComponent {...stepProps} options={options} onSubmit={onStepSubmit} />
        </WizardCard>
      </Modal.Body>
    </Modal>
  )
}

export default WizardModal
