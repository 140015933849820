import React, { useContext } from "react"
import { Col, Row } from "react-bootstrap"
import useViewport from "../../hooks/useViewport"
import GroupInfo from "./group_info"
import WeekCalendar from "./week_calendar"
import Report from "./report"
import SchoolSessionDataProvider from "./providers"
import { DataContext, InitialLoadingContext } from "../../contexts"
import { WeekDates } from "./week_dates"

const SchoolSessions = () => {
  const { role, groupInfo } = useContext(DataContext)
  const { initialLoading } = useContext(InitialLoadingContext)
  const { isMobileViewport } = useViewport()

  if (initialLoading) {
    return (
      <div
        className={`d-flex align-items-center justify-content-center ${
          isMobileViewport ? "min-h-200-px" : "min-h-600-px"
        }`}
      >
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  const title = role === "parent" ? groupInfo.group.students.join(", ") : groupInfo.group.name

  return (
    <div className={`school-session  ${isMobileViewport && "px-2"}`}>
      <Row className={`align-content-between ${isMobileViewport ? "mb-3 mx-n2" : "mx-n2"}`}>
        {groupInfo && (
          <Col
            xs={24}
            md
            className={`users-navbar_container flex-grow-1 align-items-baseline ${
              isMobileViewport ? "mt-2 px-4" : "mb-4"
            }`}
          >
            <h1 className="users-navbar_title -margin-initial mr-2">{title}</h1>
            <span className="users-navbar_subtitle">
              School session <WeekDates />
            </span>
          </Col>
        )}
        {/* <Col xs={24} md className="flex-grow-0">
        <button>Edit</button>
      </Col> */}
      </Row>
      <Row className="mx-n2">
        <Col xs={24} lg={5} className={`school-session_side -info px-0 ${isMobileViewport ? "mb-3" : "mr-5"}`}>
          <div className="card position-sticky px-4 py-3 mb-5" style={{ top: 20 }}>
            <GroupInfo />
          </div>
        </Col>
        <Col xs={24} lg={19} className="school-session_side -content px-0">
          <WeekCalendar className="mb-3" />
          <Report />
        </Col>
      </Row>
    </div>
  )
}

const SchoolSessionPage = ({ role, groupSessionId, directUploadUrl }) => {
  return (
    <SchoolSessionDataProvider role={role} groupSessionId={groupSessionId} directUploadUrl={directUploadUrl}>
      <SchoolSessions />
    </SchoolSessionDataProvider>
  )
}

export default SchoolSessionPage
