import React from "react"
import { Button } from "react-bootstrap"
import { useField } from "react-final-form"

import MultipleProgramsDropdown from "../MultipleProgramsDropdown"

const WizardProgramStep = ({ options, getButtonText, completed, onSubmit }) => {
  const {
    input: { value },
  } = useField("program_ids")

  return (
    <>
      <MultipleProgramsDropdown options={options} completed={completed} getButtonText={getButtonText} />
      <div className="mt-5">
        <Button type="button" variant="outline-primary" disabled={!value} onClick={onSubmit}>
          Ok
        </Button>
      </div>
    </>
  )
}

export default WizardProgramStep
