import { ReactComponent as GroupIcon } from "../../../../assets/images/group.svg"
import { ReactComponent as PersonIcon } from "../../../../assets/images/person.svg"
import React from "react"
import { OpeningTutoringTypes } from "../../../opening_module/constants"

const Title = ({ icon: Icon, title }) => (
  <>
    <div className="icon-24-px tutor-dashboard__opening-type-icon">
      <Icon />
    </div>
    {title}
  </>
)

export const CardTitle = ({ opening }) => {
  const { tutoring_type } = opening
  const isGroup = tutoring_type === OpeningTutoringTypes.Groups

  return (
    <h6 className="mb-0 font-weight-semibold d-flex align-items-center gap-025">
      <Title title={isGroup ? "Group tutoring" : "Individual tutoring"} icon={isGroup ? GroupIcon : PersonIcon} />
    </h6>
  )
}
