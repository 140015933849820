import React from "react"
import { Field } from "react-final-form"
import { required } from "../../../../helpers/validators"
import FormRow from "../../../blocks/FormRow"
import { showFieldError } from "../../../../helpers/forms"
import { labelSize, colSize } from "../FormFields"
import FormSelect from "../../../blocks/FormSelect"
import { FormControl } from "react-bootstrap"

const Company = ({ isFormDisabled, onSelectOrganization, organizations }) => {
  return (
    <Field name="organization_id" validate={required}>
      {({ input, meta }) => (
        <FormRow label="Company" LabelProps={labelSize} ColProps={colSize} required>
          <FormSelect
            {...input}
            placeholder="Choose company..."
            options={organizations}
            disabled={isFormDisabled}
            isInvalid={showFieldError(meta)}
            onChange={e => onSelectOrganization(e.target.value, input.onChange)}
          />
          <FormControl.Feedback type="invalid">{meta.error || meta.submitError}</FormControl.Feedback>
        </FormRow>
      )}
    </Field>
  )
}

export default Company
