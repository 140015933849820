import React, { useState, useMemo } from "react"
import arrayMutators from "final-form-arrays"
import { Form } from "react-final-form"
import { isEmpty } from "lodash/fp"
import FormFields from "./FormFields"
import { putAndRedirect } from "../../../common/apiClient"
import useViewport from "../../../hooks/useViewport"
import ProfileValidationProvider from "../providers/ProfileValidation"

const getAlertClass = (submitFailed, errors) => {
  return submitFailed && errors.program_ids && errors.program_ids.length > 0 ? "alert-error" : "alert-info"
}

const getInitialValues = user => ({
  program_ids: user.programs.map(({ id }) => id),
  disabilities: isEmpty(user.profile.disabilities) ? [] : user.profile.disabilities.map(({ name }) => name),
  documents: user.profile.certificates.map(({ id, description, training, document }) => ({
    id,
    description,
    training,
    filename: document && document.filename,
  })),
})

const ProgramsAndTrainings = ({ dataSources }) => {
  const { isMobileViewport } = useViewport()
  const { user, urls } = dataSources
  const initialValues = useMemo(() => getInitialValues(user), [user])

  const [loadingFiles, setLoadingFiles] = useState({})
  const isLoading = Object.keys(loadingFiles).length > 0

  const onSubmit = values => {
    putAndRedirect(urls.save, urls.next, values)
  }
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} mutators={{ ...arrayMutators }}>
      {({ form, handleSubmit, submitting, submitFailed, errors }) => (
        <div className="container position-relative">
          <div className="row d-flex flex-column align-items-stretch">
            <div className="d-flex justify-content-between align-items-center">
              <h4>What do you teach best?</h4>
            </div>
            <p>Please add all the programs you would like to teach below</p>
          </div>
          <div
            className={`d-flex flex-column align-items-stretch ${
              !isMobileViewport ? `position-absolute top right max-w-400-px` : ""
            }`}
          >
            <div className="alert alert-info mb-4">
              Choose the subjects you teach best and then upload certificates for any additional special training
              you&#39;ve received, such as to do academic interventions or support students with executive function
              skills.
            </div>
            <div className={`alert ${getAlertClass(submitFailed, errors)}`}>
              One program is definitely required, but not a certificate. To teach 1st grade math, for example, you just
              have to have a Master&#39;s in Elementary Education
            </div>
          </div>
          <ProfileValidationProvider errors={errors}>
            <FormFields
              form={form}
              dataSources={dataSources}
              admin={false}
              loadingFiles={loadingFiles}
              setLoadingFiles={setLoadingFiles}
            />
          </ProfileValidationProvider>

          <div className="row">
            <button
              type="button"
              className="btn btn-primary mt-4"
              onClick={handleSubmit}
              disabled={isLoading || submitting || Object.keys(errors).length > 0}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </Form>
  )
}

export default ProgramsAndTrainings
