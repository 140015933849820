import React, { useRef, useState, useEffect, useCallback, useMemo } from "react"
import { Form } from "react-bootstrap"
import { minutesToHours } from "../../helpers/dates"
import { SESSION_DURATIONS } from "../../common/constants"
import { buildId } from "../../helpers/forms"
import { noop } from "../../helpers/noop"
import { className } from "../../helpers/className"

const DurationField = ({
  id,
  name,
  onChange,
  value: initialValue = "",
  className: fieldClassName = "",
  wrapperClassName = "",
  placeholder = "0:45",
  invalid = false,
  disabled = false,
  options = SESSION_DURATIONS,
  onBlur = noop,
  onFocus = noop,
  error,
  dataTest,
}) => {
  const fieldId = useMemo(() => id || buildId(), [id])
  const wrapperRef = useRef(null)
  const [openList, setOpenList] = useState(false)
  const splitMilliseconds = useCallback(value => {
    if (value === "") return []
    return minutesToHours(value)
  }, [])
  const buildTempValue = useCallback(
    value => {
      const result = splitMilliseconds(value)
      if (result.length === 0) return ""
      const [hours, minutes] = result
      return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`
    },
    [splitMilliseconds]
  )

  const [tempValue, setTempValue] = useState(() => buildTempValue(initialValue))
  const inputRef = useRef()
  const readonlyInputRef = useRef()
  const [isFocused, setIsFocused] = useState(false)
  const focus = useCallback(
    event => {
      setIsFocused(true)
      setTimeout(() => {
        if (options.length > 0) setOpenList(true)
        inputRef.current?.focus()
      }, 4)
      onFocus({ ...event, target: { ...event.target, value: initialValue } })
    },
    [initialValue, onFocus, options.length]
  )

  const buildResult = useCallback(
    value => {
      let result = ""
      if (value === "") return result
      const matchHM = `${value}`.match(/((\d+)h)?[-,\s]*((\d+)m)?/)
      let [hours, minutes] = matchHM[0] ? [matchHM[2] || "0", matchHM[4] || "0"] : `${value}`.split(":")

      hours = Math.abs(parseInt(hours, 10))
      minutes = Math.abs(parseInt(minutes, 10))
      if ((Number.isNaN(hours) && Number.isNaN(minutes)) || hours < 0) {
        result = initialValue
      } else if ((hours === 0 && Number.isNaN(minutes)) || (hours === 0 && minutes === 0)) {
        result = hours
      } else if (hours && Number.isNaN(minutes)) {
        result = hours * (hours <= 8 ? 60 : 1)
      } else {
        if (hours > 23) hours = 23
        if (minutes > 59) minutes = 59
        const hour = hours * 60
        result = hour + minutes
      }
      return result
    },
    [initialValue]
  )

  const change = useCallback(
    event => {
      if (disabled) return
      const {
        target: { value },
      } = event
      const result = buildResult(value)
      setTempValue(value)
      if (result !== initialValue) onChange({ ...event, target: { ...event.target, value: result } })
    },
    [buildResult, disabled, initialValue, onChange]
  )

  const blur = useCallback(
    event => {
      setIsFocused(false)
      const result = buildResult(tempValue)
      setTempValue(buildTempValue(result))
      onBlur({ ...event, target: { ...event.target, value: result } })
    },
    [buildResult, buildTempValue, onBlur, tempValue]
  )

  const buildResultValue = useCallback(
    value => {
      const result = splitMilliseconds(value)
      if (result.length === 0) return ""
      const [hours, minutes] = result
      return `${hours}h ${minutes ? `${minutes}min` : ""}`
    },
    [splitMilliseconds]
  )

  const onEnter = useCallback(
    event => {
      if (event.charCode !== 13) return
      event.stopPropagation()
      event.preventDefault()
      if (isFocused) {
        setOpenList(false)
        blur({ target: inputRef?.current || null })
      } else focus({ target: readonlyInputRef?.current || null })
    },
    [blur, focus, isFocused]
  )

  const checkIsFocused = useCallback(target => wrapperRef.current?.contains(target), [])

  const handleCloseList = useCallback(
    event => {
      const canChange = openList && !checkIsFocused(event.target)
      if (canChange) setOpenList(false)
    },
    [checkIsFocused, openList]
  )

  const selectFromList = useCallback(
    value => {
      change({ target: { value, name } })
      setOpenList(false)
    },
    [change, name]
  )

  useEffect(() => {
    if (!isFocused && tempValue !== initialValue) setTempValue(initialValue)
  }, [initialValue, isFocused, tempValue])

  useEffect(() => {
    if (options.length > 0 && wrapperRef.current && openList) {
      document.addEventListener("mousedown", handleCloseList)
      return () => document.removeEventListener("mousedown", handleCloseList)
    }
  }, [options, openList, handleCloseList])

  return (
    <div
      ref={wrapperRef}
      className={className("duration-field d-block mb-0", wrapperClassName)}
      data-test="duration-input"
    >
      {openList && (
        <ul className={className("brain-trust-dropdown-menu", fieldClassName)}>
          {options.map((el, idx) => (
            <li className="brain-trust-dropdown-menu-item" key={el}>
              <button
                className="btn-clear fullwidth text-left"
                onClick={() => selectFromList(`${el}`)}
                tabIndex={idx}
                data-test={`duration-${el}`}
              >
                {buildTempValue(el)}
              </button>
            </li>
          ))}
        </ul>
      )}
      <Form.Control
        ref={readonlyInputRef}
        disabled={disabled}
        isInvalid={invalid && !openList}
        hidden={isFocused}
        className={className("position-relative", fieldClassName, openList && "active")}
        autoComplete="off"
        placeholder={placeholder}
        value={buildResultValue(initialValue)}
        onFocus={focus}
        onChange={noop}
        data-test={dataTest}
      />
      <Form.Control
        ref={inputRef}
        id={fieldId}
        disabled={disabled}
        className={className("position-relative", fieldClassName, openList && "active")}
        autoComplete="off"
        placeholder={placeholder}
        isInvalid={invalid && !openList}
        hidden={!isFocused}
        value={tempValue}
        onBlur={blur}
        onChange={change}
        onKeyPress={onEnter}
      />
      <Form.Control hidden readOnly name={name} disabled={disabled} value={initialValue} />

      {invalid && !openList && error ? (
        <Form.Control.Feedback type="invalid" className="mt-1">
          {error}
        </Form.Control.Feedback>
      ) : null}
    </div>
  )
}

export default DurationField
