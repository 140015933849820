import React, { memo } from "react"

/**
 * StudentInfo component displays a label and its corresponding value.
 *
 * @component
 * @example
 * const label = "Name"
 * const value = "John Doe"
 * return <StudentInfo label={label} value={value} />
 *
 * @param {Object} props - Component props
 * @param {string} props.label - The label to display
 * @param {string} props.value - The value to display
 * @returns {JSX.Element} The rendered component
 */
const StudentInfo = memo(({ label, value }) => (
  <div className="d-flex flex-column student-info">
    <div className="student-info-label">{label}</div>
    <div className="font-weight-bold student-info-value">{value}</div>
  </div>
))

StudentInfo.displayName = "StudentInfo"

export default StudentInfo
