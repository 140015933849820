import { useContext, useMemo } from "react"
import { DataContext, ApiContext } from "../contexts"

export const useApiPathWithRole = initial => {
  const { role } = useContext(DataContext)
  const { base } = useContext(ApiContext)
  return useMemo(() => `${base || initial}${role ? `/${role}` : ""}`, [base, initial, role])
}

export const useApiInitialPath = initial => {
  const { base } = useContext(ApiContext)
  return useMemo(() => initial || base, [base, initial])
}
