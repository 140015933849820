import React from "react"
import noop from "../../../../helpers/noop"
import { ScheduleInfo } from "./schedule_info"
import { EducatableTypes } from "../../../schedule_module/constants"
import { PickTutorButton } from "./tutor_info"
import { EducatableNames } from "./educatable_names"
import { EducatableGrades } from "./educatable_grades"
import { EducatableSpecialNeeds } from "./educatable_special_needs"
import { MatchButton } from "./match_button"

export const GroupName = {
  field: "educatable.name",
  headerName: "Group",
  type: "string",
  className: "font-weight-semibold opening-students__group-name-cell",
  outerLink: data => {
    const { educatable } = data
    return educatable.type === EducatableTypes.Group
      ? `/admin/opening_groups/${educatable.real_id}`
      : `/admin/students/${educatable.real_id}/program_progresses`
  },
}

export const Tutor = {
  field: "tutor",
  headerName: "Tutor",
  type: "custom",
  component: ({ row, cellProps, handlers }) => {
    const { connections } = row
    const { onPickTutor = noop } = handlers
    return (
      <td align={cellProps?.align} className="font-weight-medium font-size-small">
        <PickTutorButton connections={connections} onClick={() => onPickTutor(row)} />
      </td>
    )
  },
}

export const ScheduleDateAndNote = {
  field: "schedule",
  headerName: "Schedule",
  type: "custom",
  component: ({ row, cellProps, handlers }) => {
    const { start_on, end_on, note } = row
    const { onRangeClick, buildHref } = handlers
    return (
      <td align={cellProps?.align} className="font-weight-medium font-size-small opening-students__date-cell">
        <ScheduleInfo
          startOn={start_on}
          endOn={end_on}
          note={note}
          onClick={onRangeClick}
          href={buildHref ? buildHref(row) : void 0}
          dataTest={`schedule-info-${row.id}`}
        />
      </td>
    )
  },
}

export const StudentsNames = {
  field: "students",
  headerName: "Student",
  type: "custom",
  component: ({ row, cellProps }) => {
    const { educatable } = row
    return (
      <td align={cellProps?.align} className="font-weight-medium font-size-small opening-students__text-cell">
        <EducatableNames educatable={educatable} />
      </td>
    )
  },
}

export const StudentsGrades = {
  field: "students",
  headerName: "Grade",
  type: "custom",
  component: ({ row, cellProps }) => {
    const { educatable } = row
    return (
      <td align={cellProps?.align} className="font-weight-medium font-size-small opening-students__text-cell">
        <EducatableGrades educatable={educatable} />
      </td>
    )
  },
}

export const StudentsSpecialNeeds = {
  field: "students",
  headerName: "Special needs",
  type: "custom",
  cellProps: { align: "right" },
  component: ({ row, cellProps }) => {
    const { educatable } = row
    return (
      <td align={cellProps?.align} className="font-weight-medium font-size-small opening-students__text-cell">
        <EducatableSpecialNeeds educatable={educatable} />
      </td>
    )
  },
}

export const MatchTutorWithGroup = {
  field: "id",
  headerName: "Tutor",
  type: "custom",
  component: ({ row, cellProps, handlers }) => {
    const { onMatchTutor = noop } = handlers

    return (
      <td align={cellProps?.align} className="font-weight-medium font-size-small">
        <MatchButton onClick={event => onMatchTutor(event, row)} />
      </td>
    )
  },
}
