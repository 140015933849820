export const BETA_TOOLTIP_TEXT =
  "Our lesson recommendations will continue to improve as we refine our algorithm. Please use your expertise to decide if our recommendation is right for you! For guidance, email tutor@braintrusttutors.com."

export const PROGRAMS = {
  ADVANCED_READING_PROGRAM: "Braintrust Advanced Reading",
  FOUNDATIONAL_READING_PROGRAM: "Braintrust Foundational Reading",
}

export const columnsList = [
  { field: "name", headerName: "Student", flex: 1, headerClassName: "table-header", resizable: false },
  {
    field: "assessment_score_baba",
    headerName: `Diagnostic Assessment Score`,
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
  {
    field: "assessment_score_BSA",
    headerName: `Spelling Diagnostic Assessment Score`,
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
  {
    field: "assessment_score_BRA",
    headerName: "Reading Diagnostic Assessment Score",
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
  {
    field: "suggested_level",
    headerName: "Suggested Level",
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
  {
    field: "suggested_lessons",
    headerName: "Suggested Lessons",
    flex: 1,
    headerClassName: "table-header",
    resizable: false,
  },
]
