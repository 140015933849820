import React, { useContext } from "react"
import { OpeningContext } from "./context"
import { GradeSelect, ProgramSelect, StrugglesWithSelect, LearnDifference, LocationSelect } from "../../common"

const Step2Configuration = () => {
  const { opening, setOpening, options } = useContext(OpeningContext)
  return (
    <div className="p-4">
      <div className="mb-3">Pick items below to configure the session</div>

      <GradeSelect opening={opening} setOpening={setOpening} options={options} />
      <ProgramSelect opening={opening} setOpening={setOpening} options={options} />
      <StrugglesWithSelect opening={opening} setOpening={setOpening} options={options} />
      <LearnDifference opening={opening} setOpening={setOpening} options={options} />
      <LocationSelect opening={opening} setOpening={setOpening} options={options} />
    </div>
  )
}

export default Step2Configuration
