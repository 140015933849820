export const noop = () => {}

const locationTypeTitles = {
  pencil_spaces: "Online",
  online: "Online",
  offline: "In Person",
  undefined: "",
  null: "",
}

const openingLocationTypeTitles = {
  ...locationTypeTitles,
  pencil_spaces: "Pencil Spaces",
}

export const getLocationTypeTitle = type => locationTypeTitles[type] || locationTypeTitles[null]
export const getOpeningLocationTypeTitle = type => openingLocationTypeTitles[type] || openingLocationTypeTitles[null]
