import React from "react"
import arrayMutators from "final-form-arrays"
import { FieldArray } from "react-final-form-arrays"
import Button from "react-bootstrap/Button"
import { Form } from "react-final-form"
import Tutoring from "./Tutoring"
import { postAndRedirect } from "../../../common/apiClient"
import {
  RejectionConfirmationDialog,
  useRejectionDialog,
} from "../rejection_confirmation_dialog/RejectionConfirmationDialog"

const onApprove = (values, urls) => {
  postAndRedirect(urls.approve, urls.redirect, {
    entity: { type: "TutoringHistory", ids: values.tutoring_ids },
  })
}

const buttonDisabled = form => {
  return !form.getFieldState("tutoring_ids") || form.getFieldState("tutoring_ids").length === 0
}

const TutoringsToReview = ({ tutoringsToShow, urls, divisions }) => {
  const { show, dialogProps } = useRejectionDialog()

  if (tutoringsToShow.length === 0) {
    return null
  }

  return (
    <div className="col-lg-18 pending-area">
      <h5>Tutored students from</h5>

      <Form initialValues={{ tutoring_ids: [] }} onSubmit={() => {}} mutators={{ ...arrayMutators }}>
        {({ form }) => (
          <>
            <FieldArray name="tutoring_ids">
              {({ fields }) => (
                <>
                  {tutoringsToShow.map(tutoring => (
                    <Tutoring key={tutoring.id} tutoring={tutoring} fields={fields} divisions={divisions} />
                  ))}
                </>
              )}
            </FieldArray>
            <hr className="thin" />
            <Button
              disabled={buttonDisabled(form)}
              variant="outline-primary"
              onClick={() => onApprove(form.getState().values, urls)}
            >
              Approve selected
            </Button>
            <Button
              disabled={buttonDisabled(form)}
              variant="secondary"
              className="reject"
              onClick={() => {
                show(form.getState().values.tutoring_ids, urls, "TutoringHistory", "record")
              }}
            >
              Reject selected
            </Button>
          </>
        )}
      </Form>

      <RejectionConfirmationDialog {...dialogProps} />
    </div>
  )
}

export default TutoringsToReview
