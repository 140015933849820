import React from "react"
import { Field } from "react-final-form"
import { ListGroup } from "react-bootstrap"

const WizardGradeStep = ({ options: { grades }, onSubmit }) => {
  return (
    <Field name="grade">
      {({ input }) => (
        <ListGroup>
          {grades.map(grade => (
            <ListGroup.Item
              key={grade.value}
              action
              className="pl-1"
              active={input.value === grade.value}
              onClick={() => {
                input.onChange(grade.value)
                onSubmit()
              }}
            >
              {grade.label}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Field>
  )
}

export default WizardGradeStep
