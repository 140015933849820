import React from "react"
import noop from "../../helpers/noop"

export const StyledLink = ({ href, children, onClick, ...restProps }) => {
  return (
    <a href={href} onClick={onClick ? onClick : noop} {...restProps}>
      {children}
    </a>
  )
}
