import React from "react"
import { connect } from "react-redux"
import Skill from "./Skill"

const SkillsList = ({ list }) => (
  <>
    {list.map(skill => (
      <Skill key={skill.id} skill={skill} />
    ))}
  </>
)

export default connect(state => ({
  list: state.list,
}))(SkillsList)
