import { parse, stringify } from "query-string"
import { isEmpty, omit } from "lodash/fp"
import ServerSideRendering from "./serverSideRendering"

const { window } = ServerSideRendering.wrapWindow()

export const limit = 10

export const parseOptions = {
  arrayFormat: "comma",
  parseBooleans: true,
  parseNumbers: true,
}

export const stringifyOptions = {
  arrayFormat: "comma",
  skipNull: true,
  skipEmptyString: true,
}

export const parseQueryString = (queryString = window.location.search, paramsWithoutCast = []) => {
  const parsedParams = parse(queryString, parseOptions)
  const parsedParamsWithoutCast = parse(queryString, { arrayFormat: "comma" })
  paramsWithoutCast.forEach(name => {
    if (parsedParams[name] || parsedParamsWithoutCast[name]) {
      parsedParams[name] = parsedParamsWithoutCast[name]
    }
  })
  return parsedParams
}

export const getQueryStringOmitParams = (
  paramsToOmit = [],
  paramsWithoutCast = [],
  queryString = window.location.search
) => {
  const searchParams = parseQueryString(queryString, paramsWithoutCast)
  const filteredSearchParams = omit(paramsToOmit)(searchParams)
  return isEmpty(filteredSearchParams) ? "" : `?${stringify(filteredSearchParams, stringifyOptions)}`
}

export const addQueryStringParams = (params = {}, paramsWithoutCast = [], queryString = window.location.search) => {
  const searchParams = { ...parseQueryString(queryString, paramsWithoutCast), ...params }
  return isEmpty(searchParams) ? "" : `?${stringify(searchParams, stringifyOptions)}`
}
