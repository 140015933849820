import React from "react"
import cn from "classnames"
import { Row, Dropdown } from "react-bootstrap"

import { getLabelByValue } from "../../helpers/presentational"
import Chip from "../Chip"

const SpecialTreatmentPicker = ({ value, options, onChange }) => {
  const items = options.filter(item => !value.includes(item.value))
  const hasValue = !!(value && value.length)

  const onChooseItem = id => {
    onChange([...value, id])
  }

  const onRemoveItem = idToRemove => {
    const newValue = value.filter(id => id !== idToRemove)
    onChange(newValue)
  }

  return (
    <Row className="align-items-center" noGutters>
      {hasValue &&
        value.map(id => (
          <Chip key={id} onClose={() => onRemoveItem(id)}>
            {getLabelByValue(options)(id)}
          </Chip>
        ))}

      {(!!items.length || !value.length) && (
        <Dropdown>
          <Dropdown.Toggle variant="link" className={cn("link-primary mb-2", hasValue && "ml-3")}>
            Select
          </Dropdown.Toggle>

          <Dropdown.Menu className="-fullwidth">
            {items.map(({ value, label }) => (
              <Dropdown.Item key={value} onClick={() => onChooseItem(value)}>
                {label}
              </Dropdown.Item>
            ))}
            {!items.length && (
              <Dropdown.Item className="text-center" disabled>
                No options
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Row>
  )
}

export default SpecialTreatmentPicker
