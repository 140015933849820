import React from "react"
import { difference, intersection, union } from "lodash"

const toggleId = (fields, id) => {
  const index = fields.value.findIndex(el => el === id)
  if (index > -1) {
    fields.remove(index)
  } else {
    fields.push(id)
  }
}

const toggleIds = (form, fields, programIds) => {
  let newIds = difference(programIds, fields.value)

  if (newIds.length === 0) {
    form.change("program_ids", difference(fields.value, programIds))
  } else {
    form.change("program_ids", union(fields.value, programIds))
  }
}

const elText = (program, ids) => {
  if (ids && ids.findIndex(id => program.id === id) > -1) {
    return `[\u2713] ${program.name}`
  } else {
    return `[\u00A0\u00A0\u00A0] ${program.name}`
  }
}

const domainText = (domain, elementInDomainIds, selectedIds) => {
  const domainSelectedIds = intersection(elementInDomainIds, selectedIds)
  if (elementInDomainIds.length === domainSelectedIds.length && domainSelectedIds.length > 0) {
    return `[\u2713] ${domain.domain}`
  } else {
    return `[\u00A0\u00A0\u00A0] ${domain.domain}`
  }
}

const Hierarchy = ({ hierarchy, elements, form, fields }) => {
  const elementIds = elements.map(el => el.id)

  let divisions = []

  hierarchy.forEach(division => {
    let domains = []
    division.domains.forEach(domain => {
      const elementInDomainIds = intersection(domain.programs, elementIds)

      var programs = []

      if (elementInDomainIds.length > 0) {
        elementInDomainIds.forEach(id => {
          const index = elements.findIndex(el => el.id === id)
          programs.push(
            <div key={elements[index].id} className="form-check">
              <label className={`collection_check_boxes`} onClick={() => toggleId(fields, elements[index].id)}>
                {elText(elements[index], fields.value)}
              </label>
            </div>
          )
        })

        domains.push(
          <div className="row" key={domain.domain}>
            <div
              className="col-lg-6 cursor-pointer"
              onClick={() => {
                toggleIds(form, fields, elementInDomainIds)
              }}
              data-test={`check-program-group-${domain.domain.toLowerCase()}`}
            >
              {domainText(domain, elementInDomainIds, fields.value)}
            </div>
            <div className="col-lg-18">{programs}</div>
          </div>
        )
      }
    })

    if (domains.length > 0) {
      divisions.push(
        <div className="row mt-4" key={division.division}>
          <h5 className="col-lg-24">{division.division}</h5>
          <div className="col-lg-24">{domains}</div>
        </div>
      )
    }
  })

  return divisions.length ? <>{divisions}</> : null
}

export default Hierarchy
