import React from "react"
import { Row, Col, Modal, Button, FormControl } from "react-bootstrap"
import { Form, Field } from "react-final-form"

const SessionCallDetailsModal = ({ show, onSubmit, onClose }) => {
  return (
    <Modal show={show} size="lg" data-backdrop="static" onHide={onClose} centered>
      <Modal.Header className="px-4 pt-3 pb-2" closeButton>
        <Modal.Title>Add call details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form onSubmit={onSubmit}>
          {({ submitting, handleSubmit }) => (
            <Row>
              <Col lg={18}>
                <p className="pb-2">
                  How should the parent contact you? Paste a link for a Zoom or Google Meet! This can be edited later.
                </p>

                <Field name="call_details">{({ input }) => <FormControl {...input} as="textarea" rows={5} />}</Field>

                <div className="mt-5">
                  <Button variant="outline-primary" disabled={submitting} onClick={handleSubmit}>
                    Confirm
                  </Button>
                  <Button variant="link" className="link-primary ml-5" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default SessionCallDetailsModal
