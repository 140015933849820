import React, { useCallback, useContext } from "react"
import { ExistingScheduleDetector } from "./existing_schedule_detector"
import { ScheduleFormProvider } from "../../providers/schedule_form_providers"
import { multipleValidateScheduleForm, useEditInitialValues, validateScheduleForm } from "./utils"
import { ScheduleForm } from "./form"
import { DataContext } from "../../../../contexts"
import { editSchedule } from "../../configurations/domain"

const EditScheduleFormContent = ({ onSubmit, onCancel, schedules, loading }) => {
  const { existingSchedule } = useContext(DataContext)

  const { initialValues, multipleValues } = useEditInitialValues(schedules)

  const submit = useCallback(data => onSubmit(data, multipleValues), [multipleValues, onSubmit])

  return (
    <ScheduleForm
      initialValues={initialValues}
      multipleValues={multipleValues}
      validate={schedules.length > 1 ? multipleValidateScheduleForm : validateScheduleForm({ type: "edit" })}
      loading={loading}
      disabled={Boolean(existingSchedule)}
      type="edit"
      onSubmit={submit}
      onCancel={onCancel}
    >
      {schedules?.length === 1 && <ExistingScheduleDetector schedule={schedules[0]} />}
    </ScheduleForm>
  )
}

export const EditScheduleForm = props => {
  return (
    <ScheduleFormProvider configs={editSchedule}>
      <EditScheduleFormContent {...props} />
    </ScheduleFormProvider>
  )
}
