import React from "react"
import { Container, Button } from "react-bootstrap"
import TabsNav from "../../../blocks/UserTabs"
import SchoolAdminsTable from "./SchoolAdminsTable"

const SchoolAdminsPage = ({ currentUser, currentPath, schoolAdmins }) => {
  return (
    <Container className="school-admins-dashboard-content">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="users-navbar_title m-0">School Admins</h2>
        <Button href="/admin/school_admins/new" variant="outline-primary">
          New School Admin
        </Button>
      </div>
      <div className="container panel-wrapper">
        <div className="panel">
          <div className="panel_header">
            <TabsNav currentUser={currentUser} currentPath={currentPath} />
          </div>
          <div className="panel_body p-0">
            <SchoolAdminsTable schoolAdmins={schoolAdmins} />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default SchoolAdminsPage
