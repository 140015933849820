import React, { useContext } from "react"
import { ValidationContext } from "../../contexts"
import { className } from "../../helpers/className"

const RowWarning = ({ name, className: cn, children }) => {
  const { getValidationValueByName } = useContext(ValidationContext)
  const problem = getValidationValueByName(name)
  const rowClassName = className("row-warning", Boolean(problem) && "-problem", cn)
  return <div className={rowClassName}>{children}</div>
}

export default RowWarning
