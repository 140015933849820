import React from "react"
import renderRawHtml from "../../common/renderRawHtml"

const AdminsMessage = ({ message }) => {
  if (!message) return null
  return (
    <div className="panel dashboard_tutor-message font-size-big">
      <div className="panel_body p-4">
        <div {...renderRawHtml(message)} />
      </div>
    </div>
  )
}

export default AdminsMessage
