import { map } from "lodash/fp"
import { programsHierarchyToList } from "./programsHierarchy"

export const serializeTimeZones = timeZones =>
  timeZones.filter(([value]) => !!value).map(([label, value]) => ({ label, value }))

export const serializeStates = states => Object.entries(states).map(([value, label]) => ({ label, value }))

export const serializeGrades = map(([label, value]) => ({ label, value }))

export const serializeGradesFromHierarchy = map(({ grade: { id, name } }) => ({ label: name, value: id }))

export const serializePrograms = map(({ id, name }) => ({ label: name, value: id }))

export const serializeSpecialTreatments = map(([label, value]) => ({ value, label }))

export const scheduleStringToObject = str => {
  const [day, period] = str.split("-")
  return { day, period }
}

export const scheduleObjectToString = ({ day, period }) => `${day}-${period}`

export const serializeRequestOptions = dataSources => ({
  students: dataSources.parent.students,
  hierarchy: dataSources.hierarchy,
  states: dataSources.states ? serializeStates(dataSources.states) : [],
  programs: dataSources.hierarchy ? programsHierarchyToList(dataSources.hierarchy) : [],
  struggleWithQuestions: dataSources.struggleWithQuestions,
  grades: dataSources.hierarchy ? serializeGradesFromHierarchy(dataSources.hierarchy) : [],
  specialTreatments: dataSources.specialTreatments ? serializeSpecialTreatments(dataSources.specialTreatments) : [],
})
