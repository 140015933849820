import { className as cn } from "../../../helpers/className"
import { TutorCard } from "./tutor_card"
import React from "react"

export const TutorsList = ({ connections, onEdit, title, withAddButton, className }) => {
  return (
    <div className={cn("card", "p-4", className)}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="mb-0 font-weight-semibold">{title}</h4>
        {withAddButton && (
          <button className="btn btn-primary" onClick={() => onEdit()}>
            Add tutor
          </button>
        )}
      </div>

      <div className="d-flex flex-column gap-2">
        {connections.map(connection => (
          <TutorCard
            key={connection.id}
            connection={connection}
            onEditClick={onEdit ? () => onEdit(connection) : undefined}
          />
        ))}
      </div>
    </div>
  )
}
