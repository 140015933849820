import React from "react"
import { Field } from "react-final-form"
import { Form } from "react-bootstrap"

import FormSelect from "../../blocks/FormSelect"
import FormRow from "../../blocks/FormRow"
import { required } from "../../../helpers/validators"
import { serializeGrades } from "../../../helpers/serializers"
import { showFieldError } from "../../../helpers/forms"
import { valueOrNull } from "../../../helpers/formatters"

const FormFields = ({ grades }) => {
  const gradeOptions = serializeGrades(grades)

  return (
    <>
      <Field name="first_name" validate={required}>
        {({ input, meta }) => (
          <FormRow label="First name" required>
            <Form.Control {...input} maxLength="255" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="last_name" validate={required}>
        {({ input, meta }) => (
          <FormRow label="Last name" required>
            <Form.Control {...input} maxLength="255" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="grade" validate={required}>
        {({ input, meta }) => (
          <FormRow label="Grade" required>
            <FormSelect {...input} options={gradeOptions} placeholder="Pick grade" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="additional_emails" parse={valueOrNull}>
        {({ input, meta }) => (
          <FormRow label="Additional emails">
            <Form.Control {...input} maxLength="255" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
            <Form.Text>
              We will send sessions reports to the emails listed above. Please list additional email addresses,
              separated by commas
            </Form.Text>
          </FormRow>
        )}
      </Field>
    </>
  )
}

export default FormFields
