import React from "react"
import { Button } from "react-bootstrap"
import { Field } from "react-final-form"

import SchedulePicker from "../../blocks/SchedulePicker"

const WizardScheduleStep = ({ onSubmit, ...props }) => {
  return (
    <>
      <h6 className="mx-2 mb-3">Your schedule preference</h6>
      <div className="overflow-auto">
        <Field name="schedule" initialValue={[]}>
          {({ input }) => <SchedulePicker {...props} {...input} />}
        </Field>
      </div>

      <Button type="button" variant="outline-primary" onClick={onSubmit}>
        Continue
      </Button>
    </>
  )
}

export default WizardScheduleStep
