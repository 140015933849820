import React, { useState } from "react"
import cn from "classnames"
import { Dropdown, Button } from "react-bootstrap"
import { Field, useField } from "react-final-form"
import SchedulePicker from "../blocks/SchedulePicker"

const ScheduleDropdown = ({ className, getButtonText, completed, onSubmit }) => {
  const [showPopper, setShowPopper] = useState(false)

  const { meta } = useField("timetable")

  const onToggle = show => {
    if (show) {
      setShowPopper(true)
      return
    }
    setShowPopper(false)
    if (!meta.pristine) {
      onSubmit()
    }
  }

  return (
    <Field name="timetable">
      {({ input }) => (
        <Dropdown show={showPopper} onToggle={onToggle}>
          <Dropdown.Toggle variant="outline-dropdown" className={cn(className, completed && "selected")}>
            {getButtonText(input.value)}
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-4 min-w-md-600-px max-vw-100 overflow-auto">
            <h6 className="mx-2 mb-3">Your schedule preference</h6>
            <SchedulePicker {...input} />

            <Button variant="outline-primary" size="sm" className="mt-1" type="button" onClick={() => onToggle(false)}>
              Ok
            </Button>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Field>
  )
}

export default ScheduleDropdown
