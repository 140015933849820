import CloseIcon from "@mui/icons-material/Close"
import DownloadIcon from "@mui/icons-material/Download"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import { styled } from "@mui/system"
import React, { useCallback } from "react"

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "100%",
    maxWidth: "95vw",
    maxHeight: "95vh",
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[24],
  },
}))

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(2, 3),
  color: theme.palette.text.primary,
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "calc(100% - 130px)",
  overflow: "hidden",
  backgroundColor: theme.palette.background.default,
}))

const StyledIframe = styled("iframe")(({ theme }) => ({
  border: "none",
  width: "100%",
  height: "100%",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}))

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  justifyContent: "flex-end",
  backgroundColor: theme.palette.background.paper,
}))

const PDFPreviewDialog = ({
  open,
  onClose,
  pdfUrl,
  fileName,
  title = "PDF Preview",
  downloadButtonText = "Download PDF",
  closeButtonText = "Close",
}) => {
  const handleDownloadPDF = useCallback(() => {
    const link = document.createElement("a")
    link.href = pdfUrl
    link.download = fileName
    link.click()
    onClose()
  }, [pdfUrl, fileName, onClose])

  return (
    <StyledDialog
      fullScreen={true}
      open={open}
      onClose={onClose}
      aria-labelledby="pdf-preview-dialog-title"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
    >
      <StyledDialogTitle id="pdf-preview-dialog-title">
        <Typography variant="h6" component="span">
          {title}
        </Typography>
        <Box>
          <Tooltip title="Close">
            <IconButton aria-label="Close dialog" onClick={onClose} size="large" color="inherit">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </StyledDialogTitle>
      <StyledDialogContent>
        {pdfUrl && (
          <Fade in={true} timeout={1000}>
            <StyledIframe src={pdfUrl} title={`PDF Preview - ${title}`} />
          </Fade>
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <Button variant="outlined" onClick={onClose} startIcon={<CloseIcon />} color="primary">
          {closeButtonText}
        </Button>
        <Button
          variant="contained"
          onClick={handleDownloadPDF}
          disabled={!pdfUrl}
          startIcon={<DownloadIcon />}
          color="primary"
        >
          {downloadButtonText}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  )
}

export default PDFPreviewDialog
