import React from "react"
import { toggleId, elText } from "../../../helpers/profile_form"

const Certificate = ({ certificate, fields }) => {
  return (
    <>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">Document</label>
        <div className="col-lg-16">
          <div className="btn btn-light col-lg-24 filename">
            {certificate.document.filename}
            <a href={certificate.document.download_link} className="download-icon"></a>
          </div>
        </div>
        <div className="clearfix col-lg-2">
          <span
            className="cursor-pointer"
            onClick={() => {
              toggleId(fields, certificate.id)
            }}
          >
            {elText(certificate, fields.value)}
          </span>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">Certificate type</label>
        <div className="col-lg-16 form-control-plaintext">{certificate.training_text}</div>
      </div>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">Description</label>
        <div className="col-lg-16">
          <textarea className="form-control" value={certificate.description} disabled />
        </div>
      </div>
    </>
  )
}

export default Certificate
