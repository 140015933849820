import moment from "moment"
import { Entities, Methods } from "../../../constants"
import { getSchoolsAutocomplete, getSubjectAutocomplete } from "../../../domain"

const scheduleInitialParamsBuilder = getState => {
  const scheduleId = getState("scheduleId")
  return { entitiesIds: { [Entities.Schedules]: scheduleId } }
}

const scheduleDaysInitialParamsBuilder = getState => {
  const config = scheduleInitialParamsBuilder(getState)
  const scheduleDate = getState("scheduleDate")
  config.params = {
    from: moment(scheduleDate).startOf("month").startOf("week").format("YYYY-MM-DD"),
    to: moment(scheduleDate).endOf("month").endOf("week").format("YYYY-MM-DD"),
  }
  return config
}

const reportsDaysInitialParamsBuilder = getState => {
  const date = getState("report_date")
  const reportDate = moment(date).isValid() ? moment(date) : moment(new Date()).startOf("week")
  const config = scheduleInitialParamsBuilder(getState)

  config.params = {
    from: reportDate.startOf("week").format("YYYY-MM-DD"),
    to: reportDate.endOf("week").format("YYYY-MM-DD"),
  }

  return config
}

export const getSchedule = {
  entities: [Entities.Schedules],
  alias: "schedule",
  initialParamsBuilder: scheduleInitialParamsBuilder,
}

export const getMonthScheduleDays = {
  entities: [Entities.Schedules],
  action: "days",
  alias: "scheduleDays",
  initialParamsBuilder: scheduleDaysInitialParamsBuilder,
}

export const getMonthScheduleSessions = {
  entities: [Entities.Schedules],
  action: "sessions",
  alias: "scheduleSessions",
  initialParamsBuilder: scheduleInitialParamsBuilder,
}

export const getWeekScheduleDays = {
  entities: [Entities.Schedules],
  action: "days",
  alias: "scheduleDays",
  initialParamsBuilder: reportsDaysInitialParamsBuilder,
}

export const getWeekScheduleSessions = {
  entities: [Entities.Schedules],
  action: "sessions",
  alias: "scheduleSessions",
  initialParamsBuilder: reportsDaysInitialParamsBuilder,
}

export const postSchedule = {
  entities: [Entities.Schedules],
  method: Methods.Post,
  skipStorage: true,
}

export const updateSchedule = {
  entities: [Entities.Schedules],
  method: Methods.Patch,
  skipStorage: true,
}

export const createConnection = {
  entities: [Entities.Schedules, Entities.Connections],
  method: Methods.Post,
  skipStorage: true,
  next: [getSchedule],
}

export const updateConnection = {
  entities: [Entities.Connections],
  method: Methods.Patch,
  skipStorage: true,
}

export const deleteConnection = {
  entities: [Entities.Schedules, Entities.Connections],
  method: Methods.Delete,
  skipStorage: true,
  next: [getSchedule],
}

export const getReport = {
  entities: [Entities.Schedules, Entities.Reports],
  alias: "report",
}

export const getUserPrograms = {
  entities: [Entities.Schedules, Entities.Reports],
  action: "available_programs",
  alias: "availablePrograms",
  onStoreUpdate: data => [...data, { value: "other", title: "Custom" }],
}

// export const tutorsAutocomplete = {
//   entities: [Roles.Admin, Entities.Autocomplete, Entities.Tutors],
//   method: Methods.Get,
//   alias: "tutors",
//   onStoreUpdate: data => data.items,
// }
export const tutorsAutocomplete = {
  entities: [Entities.Connections],
  action: "tutors_autocomplete",
  alias: "tutors",
  onStoreUpdate: data => data.items,
}

const createSession = {
  entities: [Entities.Schedules],
  action: "sessions",
  method: Methods.Post,
  skipStorage: true,
}

export const createSessionFromSchedule = {
  ...createSession,
  next: [getWeekScheduleDays, getWeekScheduleSessions, getSchedule],
}

export const createSessionFromReport = {
  ...createSession,
  next: [getWeekScheduleDays, getWeekScheduleSessions, getSchedule, getReport],
}

const bulkUpdateSession = {
  method: Methods.Patch,
  entities: [Entities.GroupSessions],
  skipStorage: true,
}

export const bulkUpdateSessionInSchedule = {
  ...bulkUpdateSession,
  next: [getWeekScheduleDays, getWeekScheduleSessions, getSchedule],
}

export const bulkUpdateSessionInReport = {
  ...bulkUpdateSession,
  next: [getWeekScheduleDays, getWeekScheduleSessions, getSchedule, getReport],
}

const bulkDeleteSession = {
  method: Methods.Delete,
  entities: [Entities.GroupSessions],
  skipStorage: true,
}

export const bulkDeleteSessionInSchedule = {
  ...bulkDeleteSession,
  next: [getWeekScheduleDays, getWeekScheduleSessions, getSchedule],
}

export const bulkDeleteSessionInReport = {
  ...bulkDeleteSession,
  next: [getWeekScheduleDays, getWeekScheduleSessions, getSchedule, getReport],
}

export const cancelSession = {
  method: Methods.Patch,
  entities: [Entities.Schedules, Entities.Sessions],
  action: "cancel",
  skipStorage: true,
  next: [getSchedule, getWeekScheduleDays, getWeekScheduleSessions, getReport],
}

export const finishSession = {
  method: Methods.Patch,
  entities: [Entities.Schedules, Entities.Sessions],
  action: "finish",
  skipStorage: true,
  next: [getWeekScheduleDays, getWeekScheduleSessions, getSchedule, getReport],
}

export const updateReportAgenda = {
  method: Methods.Patch,
  entities: [Entities.Schedules, Entities.Reports],
  next: [getReport, getSchedule],
  skipStorage: true,
  alias: "agenda",
}

export const updateReportNextSteps = {
  method: Methods.Patch,
  entities: [Entities.Schedules, Entities.Reports],
  next: [getReport, getSchedule],
  skipStorage: true,
  alias: "next_steps",
}

export const updateStudentReport = {
  method: Methods.Patch,
  entities: [Entities.Schedules, Entities.Reports, Entities.StudentReports],
  skipStorage: true,
  next: [getSchedule, getReport],
  alias: "attendance",
}

export const updateStudentReportStudentStrengths = {
  method: Methods.Patch,
  entities: [Entities.Schedules, Entities.Reports, Entities.StudentReports],
  skipStorage: true,
  next: [getSchedule, getReport],
  alias: "strengths",
}

export const updateCustomProgram = {
  method: Methods.Patch,
  entities: [Entities.Schedules, Entities.Reports],
  next: [getReport, getSchedule],
  skipStorage: true,
  alias: "custom_program",
}

export const getStudentProgramProgress = {
  method: Methods.Get,
  entities: [Entities.Schedules, Entities.Reports, Entities.StudentReports, Entities.ProgramProgresses],
  skipStorage: true,
}

export const getNewStudentProgramProgress = {
  method: Methods.Get,
  entities: [Entities.Schedules, Entities.Reports, Entities.StudentReports, Entities.ProgramProgresses],
  action: "new",
  skipStorage: true,
}

export const createStudentProgramProgress = {
  method: Methods.Post,
  entities: [Entities.Schedules, Entities.Reports, Entities.StudentReports, Entities.ProgramProgresses],
  skipStorage: true,
  next: [getSchedule, getReport],
}

export const updateStudentProgramProgress = {
  method: Methods.Patch,
  entities: [Entities.Schedules, Entities.Reports, Entities.StudentReports, Entities.ProgramProgresses],
  skipStorage: true,
  next: [getSchedule, getReport],
}

export const deleteStudentProgramProgress = {
  method: Methods.Delete,
  entities: [Entities.Schedules, Entities.Reports, Entities.StudentReports, Entities.ProgramProgresses],
  skipStorage: true,
  next: [getSchedule, getReport],
}

export const sendReport = {
  method: Methods.Post,
  entities: [Entities.Schedules, Entities.Reports],
  action: "submit",
  skipStorage: true,
  next: [getSchedule, getReport, getWeekScheduleDays, getWeekScheduleSessions],
}

export const restoreReport = {
  method: Methods.Post,
  entities: [Entities.Schedules, Entities.Reports],
  action: "draft",
  skipStorage: true,
  next: [getSchedule, getReport, getWeekScheduleDays, getWeekScheduleSessions],
}

export const pendReport = {
  method: Methods.Patch,
  entities: [Entities.Schedules, Entities.Sessions],
  action: "pend",
  skipStorage: true,
  next: [getSchedule, getReport, getWeekScheduleDays, getWeekScheduleSessions],
}

export const updateProgramProgressForAllStudents = {
  method: Methods.Post,
  entities: [Entities.Schedules, Entities.Reports, Entities.StudentReports, Entities.ProgramProgresses],
  skipStorage: true,
  next: [getSchedule, getReport],
}

export const createSessionsWithAssistant = {
  method: Methods.Post,
  entities: [Entities.Schedules],
  action: "assistant",
}

export const deleteSessionsWithAssistant = {
  method: Methods.Delete,
  entities: [Entities.Schedules],
  action: "assistant",
}

export const primary = [
  getSchedule,
  updateSchedule,
  createConnection,
  {
    ...updateConnection,
    next: [getSchedule],
  },
  deleteConnection,
  tutorsAutocomplete,
  getReport,
]

export const newSchedule = [postSchedule]

export const schedule = [
  getMonthScheduleDays,
  getMonthScheduleSessions,
  createSessionFromSchedule,
  createSessionsWithAssistant,
  deleteSessionsWithAssistant,
  bulkUpdateSessionInSchedule,
  bulkDeleteSessionInSchedule,
]

export const report = [
  updateReportAgenda,
  updateReportNextSteps,
  updateStudentReport,
  getNewStudentProgramProgress,
  getStudentProgramProgress,
  createStudentProgramProgress,
  updateStudentProgramProgress,
  deleteStudentProgramProgress,
  updateStudentReportStudentStrengths,
  updateProgramProgressForAllStudents,
  sendReport,
  restoreReport,
  createSessionFromReport,
  updateCustomProgram,
  getWeekScheduleDays,
  getWeekScheduleSessions,
  bulkUpdateSessionInReport,
  bulkDeleteSessionInReport,
  cancelSession,
  finishSession,
  pendReport,
  getUserPrograms,
]

export const changeSessionDocuments = {
  entities: [Entities.Schedules, Entities.Reports],
  action: "documents",
}

export const autocompleteEducatables = {
  entities: [Entities.Autocomplete, Entities.Educatables],
  method: Methods.Get,
  alias: "educatables",
  onStoreUpdate: data => data.items,
}

export const checkSchedulesExist = {
  entities: [Entities.Schedules],
  method: Methods.Get,
  alias: "existingSchedule",
  onStoreUpdate: ([item]) => item,
}

export const students = [
  getSchoolsAutocomplete(true),
  getSubjectAutocomplete(),
  autocompleteEducatables,
  checkSchedulesExist,
]

export const createSchedule = [
  getSchoolsAutocomplete(),
  getSubjectAutocomplete(true),
  autocompleteEducatables,
  checkSchedulesExist,
]

export const editSchedule = [getSubjectAutocomplete(true), autocompleteEducatables, checkSchedulesExist]
