import React from "react"
import { useFormState, useForm } from "react-final-form"
import { find, isEmpty, isUndefined } from "lodash/fp"

import StudentsDropdown from "./StudentsDropdown"
import MultipleProgramsDropdown from "../find_tutor/MultipleProgramsDropdown"
import StruggleWithDropdown from "../find_tutor/StruggleWithDropdown"
import Chip from "../Chip"
import SpecialTreatmentDropdown from "../find_tutor/SpecialTreatmentDropdown"
import LocationDropdown from "./LocationDropdown"
import {
  getStudentInfo,
  getProgramsSummary,
  getStruggleWithAnswer,
  getLabelByValue,
  getOfflineLocationText,
} from "../../helpers/presentational"
import { isValidOffline } from "../../helpers/validators"

const StudentValue = ({ values, options, form, disabled }) => {
  const student = find(["id", values.student_id])(options.students)

  const onRemoveStudent = () => {
    form.mutators.clearFieldsData(["student_id", "grade", "special_treatments", "program_ids", "struggle_with"])
  }

  return (
    <Chip disabled={disabled} onClose={onRemoveStudent}>
      {getStudentInfo(student, options)}
    </Chip>
  )
}

const ProgramValue = ({ values, options, form, disabled }) => {
  const onRemoveProgram = id => {
    form.mutators.clearFieldsData(["struggle_with"])
    form.mutators.setFieldData(
      "program_ids",
      values.program_ids.filter(value => value !== id)
    )
  }

  return (
    <>
      {values.program_ids.map(id => (
        <Chip disabled={disabled} key={id} onClose={() => onRemoveProgram(id)}>
          {getProgramsSummary(id, options.programs)}
        </Chip>
      ))}
    </>
  )
}

const StruggleWithValue = ({ values, options, form, disabled }) => {
  const onRemoveStruggleWith = id => {
    const newValue = values.struggle_with.filter(value => value !== id)
    form.mutators.setFieldData("struggle_with", newValue)
  }

  return (
    <>
      {values.struggle_with.map(id => (
        <Chip disabled={disabled} key={id} onClose={() => onRemoveStruggleWith(id)}>
          Struggles with {getStruggleWithAnswer(id, options)}
        </Chip>
      ))}
    </>
  )
}

const LearnDifferenceValue = ({ values, options, form, disabled }) => {
  const onRemoveExecutiveFunction = () => {
    form.mutators.clearFieldData("executive_function")
  }

  const onRemoveSpecialTreatment = id => {
    const newValue = values.special_treatments.filter(value => value !== id)
    form.mutators.setFieldData("special_treatments", newValue)
  }

  return (
    <>
      {values.executive_function && (
        <Chip disabled={disabled} onClose={onRemoveExecutiveFunction}>
          Executive function
        </Chip>
      )}
      {!isEmpty(values.special_treatments) &&
        values.special_treatments.map(id => (
          <Chip disabled={disabled} key={id} onClose={() => onRemoveSpecialTreatment(id)}>
            {getLabelByValue(options.specialTreatments)(id)}
          </Chip>
        ))}
    </>
  )
}

const LocationValue = ({ values, options, form, disabled }) => {
  const onRemoveLocation = () => {
    form.mutators.clearFieldData("online")
  }

  if (values.online) {
    return (
      <Chip disabled={disabled} onClose={onRemoveLocation}>
        Online
      </Chip>
    )
  }
  if (!isUndefined(values.online) && isValidOffline(values)) {
    return (
      <Chip disabled={disabled} onClose={onRemoveLocation}>
        {getOfflineLocationText(values, options)}
      </Chip>
    )
  }
  return null
}

const fields = [
  {
    id: "student",
    title: "Student",
    required: true,
    disabled: () => false,
    completed: values => !!values.student_id,
    ControlComponent: StudentsDropdown,
    ValueComponent: StudentValue,
  },
  {
    id: "program_ids",
    title: "Subject",
    required: true,
    disabled: values => !values.grade,
    completed: values => !!values.program_ids && values.program_ids.length > 0,
    ControlComponent: MultipleProgramsDropdown,
    ControlProps: { maxProgramsCount: 2 },
    ValueComponent: ProgramValue,
  },
  {
    id: "struggle_with",
    title: "Struggles with",
    required: false,
    disabled: values => !values.grade,
    completed: values => !isEmpty(values.struggle_with),
    ControlComponent: StruggleWithDropdown,
    ControlProps: { dropdownMaxWidth: 800 },
    ValueComponent: StruggleWithValue,
  },
  {
    id: "learn_difference",
    title: "Disability?",
    required: false,
    disabled: () => false,
    completed: values => !isEmpty(values.special_treatments) || values.executive_function,
    ControlComponent: SpecialTreatmentDropdown,
    ValueComponent: LearnDifferenceValue,
  },
  {
    id: "location",
    title: "Location",
    required: true,
    disabled: () => false,
    completed: values => values.online || (!isUndefined(values.online) && isValidOffline(values)),
    ControlComponent: LocationDropdown,
    ControlProps: { submitButtonText: "Continue" },
    ValueComponent: LocationValue,
  },
]

const ButtonTitle = ({ title, required }) => (
  <>
    {title}
    {required && <sup className="asterisk">*</sup>}
  </>
)

const SessionConfiguration = ({ options, disabled: disabledForm }) => {
  const { values } = useFormState()
  const form = useForm()

  return (
    <div className="session-configuration">
      {fields.map(
        ({ id, title, required, disabled, completed, ControlComponent, ControlProps = {}, ValueComponent }) => (
          <div key={id} className="session-configuration_row">
            <div className="session-configuration_control">
              <ControlComponent
                className="dropdown-toggle-no-icon fullwidth"
                {...ControlProps}
                options={options}
                getButtonText={() => <ButtonTitle title={title} required={required} />}
                completed={completed(values)}
                disabled={disabledForm || disabled(values)}
              />
            </div>
            <div className="session-configuration_values">
              {completed(values) && (
                <ValueComponent values={values} options={options} form={form} disabled={disabledForm} />
              )}
            </div>
          </div>
        )
      )}
    </div>
  )
}

export default SessionConfiguration
