import React from "react"
import moment from "moment"
import { Row, Col, Button, Card } from "react-bootstrap"
import cn from "classnames"

import { getPrivateFullName, getFullName, preserveNewlines } from "../../../helpers/presentational"
import InterviewSlot from "../schedule_interview/InterviewSlot"
import { formatDateKeepZone } from "../../../helpers/dates"
import ExpandCollapse from "../../blocks/ExpandCollapse"
import MessageButton from "../../chat/MessageButton"
import Linkify from "linkifyjs/react"

const TutorInterviewCard = ({
  interview,
  focused,
  readonly,
  saveMessageUrl,
  enableMessageButtonUrl,
  onEdit,
  onComplete,
  onCancel,
}) => {
  const parentName = getPrivateFullName(interview.parent)

  return (
    <Card className={cn("card-outline mb-4", focused && "focused")} id={interview.id}>
      <Card.Header className="py-3 px-4">
        <div className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
          <div>
            <InterviewSlot start={interview.started_at} tag="h5" />
            <h6 className="font-weight-normal mt-2 mb-0">Interview with {parentName}</h6>
          </div>
          {interview.created_at && (
            <div className="text-muted mt-2 mt-md-0">
              Requested {formatDateKeepZone(interview.created_at, "MM/DD/YYYY")}
            </div>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        {interview.call_details && (
          <Row>
            <Col className="form-label" lg={6}>
              Call details:
            </Col>
            <Col lg={10}>
              <ExpandCollapse>
                {preserveNewlines(interview.call_details).map((item, i) => (
                  <Linkify key={i}>{item}</Linkify>
                ))}
              </ExpandCollapse>
            </Col>
            {!readonly && (
              <Col lg={6}>
                <Button variant="link" className="btn-link-primary mb-3 mb-md-0" onClick={onEdit}>
                  Edit details
                </Button>
              </Col>
            )}
          </Row>
        )}

        {interview.additional_notes && (
          <Row className={interview.call_details ? "mt-3" : ""}>
            <Col className="form-label" lg={6}>
              Interview notes:
            </Col>
            <Col lg={10}>{interview.additional_notes}</Col>
          </Row>
        )}

        {!readonly && (
          <div className="d-flex flex-column flex-md-row mt-5">
            <MessageButton
              buttonText="Message parent"
              className="btn-sm mr-md-3 mb-3 mb-md-0"
              dataSources={{
                urls: { save_message: saveMessageUrl, enable_message_button: enableMessageButtonUrl },
                from: { id: interview.tutor.id, name: getFullName(interview.tutor), email: interview.tutor.email },
                recipient: {
                  id: interview.parent.id,
                  name: getPrivateFullName(interview.parent),
                  averageResponseTime: "unknown",
                },
                subject: "",
                changeSubject: true,
              }}
            />

            {moment.parseZone(interview.started_at) < moment().add(30, "m") && (
              <Button
                variant="outline-primary"
                className="btn-sm mr-md-4 mb-3 mb-md-0 text-nowrap"
                onClick={onComplete}
              >
                Complete interview
              </Button>
            )}
            <Button variant="link" className="link-error" onClick={onCancel}>
              Cancel interview
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default TutorInterviewCard
