import React from "react"
import { Button } from "react-bootstrap"

import LocationDropdown from "../LocationDropdown"

const WizardLocationStep = ({ disabled, completed, getButtonText, onSubmit }) => {
  return (
    <>
      <LocationDropdown
        className="mb-4"
        completed={completed}
        getButtonText={getButtonText}
        submitButtonText="Continue"
      />
      <Button type="button" variant="outline-primary" disabled={disabled} onClick={onSubmit}>
        Continue
      </Button>
    </>
  )
}

export default WizardLocationStep
