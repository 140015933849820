import React from "react"

import { ReactComponent as BgCheckIcon } from "../../../assets/images/bg-check.svg"

const BackgroundCheckLabel = () => {
  return (
    <div className="background-check-label">
      <BgCheckIcon className="background-check-label_icon" />
      <div className="background-check-label_text">Background check</div>
    </div>
  )
}

export default BackgroundCheckLabel
