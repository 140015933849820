import React from "react"
import { Field } from "react-final-form"
import { mustBeGreaterOrEqual } from "../../../../helpers/validators"
import { positiveFloatNumber } from "../../../../helpers/formatters"
import FormRow from "../../../blocks/FormRow"
import { FormControl, Row } from "react-bootstrap"
import { showFieldError } from "../../../../helpers/forms"
import { labelSize, colSize } from "../FormFields"

const TutorPay = ({ isFormDisabled, selectedPartnership, defaultTutorPay }) => {
  return (
    <React.Fragment>
      {selectedPartnership && (
        <Row>
          <hr className="col-sm-12" />
        </Row>
      )}

      <FormRow label="Tutor pay" LabelProps={labelSize} ColProps={colSize} required>
        <Field name="tutor_pay" validate={mustBeGreaterOrEqual(0)} parse={positiveFloatNumber}>
          {({ input, meta }) => (
            <React.Fragment>
              <FormControl {...input} isInvalid={showFieldError(meta)} disabled={isFormDisabled} />
              <FormControl.Feedback type="invalid">{meta.error || meta.submitError}</FormControl.Feedback>
            </React.Fragment>
          )}
        </Field>

        {selectedPartnership && (
          <React.Fragment>
            <div className="mt-3">
              {defaultTutorPay > 0 && (
                <label>
                  Default tutor rate: <span className="color -violet">${defaultTutorPay}</span>
                </label>
              )}
            </div>

            <Field name="tutor_payout_kind" type="radio" value="auto" initialValue="idle">
              {({ input, meta }) => (
                <div className="form-check mt-2">
                  <FormControl
                    {...input}
                    id="tutor_payout_kind-auto"
                    className="form-check-input radio_buttons"
                    isInvalid={showFieldError(meta)}
                    disabled={isFormDisabled}
                  />
                  <label className="collection_radio_buttons" htmlFor="tutor_payout_kind-auto">
                    Pay tutor automatically from Braintrust account
                    <br />
                    If not enough funds, payout will need to be made manually
                  </label>
                </div>
              )}
            </Field>

            <Field name="tutor_payout_kind" type="radio" value="idle">
              {({ input, meta }) => (
                <div className="form-check">
                  <FormControl
                    {...input}
                    id="tutor_payout_kind-idle"
                    className="form-check-input radio_buttons"
                    isInvalid={showFieldError(meta)}
                    disabled={isFormDisabled}
                  />
                  <label className="collection_radio_buttons" htmlFor="tutor_payout_kind-idle">
                    No automatic payout
                  </label>
                </div>
              )}
            </Field>
          </React.Fragment>
        )}
      </FormRow>
    </React.Fragment>
  )
}

export default TutorPay
