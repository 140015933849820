import { OpeningFieldsNames, TutoringTypes } from "./constants"
import { LocationFieldNames } from "../../../fields/location"
import { LearningLevel, MeetingFormats } from "../../../../constants"
import { OpeningTutoringTypes } from "../../constants"
import { defaultLocationValidation } from "../../../fields/location"

export const validateOpening = values => {
  const errors = {}

  if (!values[OpeningFieldsNames.School]) {
    errors[OpeningFieldsNames.School] = "This field is required"
  }

  if (!values[OpeningFieldsNames.LearningLevel]) {
    errors[OpeningFieldsNames.LearningLevel] = "This field is required"
  }

  if (!values[OpeningFieldsNames.Subject]) {
    errors[OpeningFieldsNames.Subject] = "This field is required"
  }

  if (!values[OpeningFieldsNames.TutorRate]) {
    errors[OpeningFieldsNames.TutorRate] = "This field is required"
  }

  if (!values[OpeningFieldsNames.SchoolRate]) {
    errors[OpeningFieldsNames.SchoolRate] = "This field is required"
  }

  if (!values[OpeningFieldsNames.StartOn]) {
    errors[OpeningFieldsNames.StartOn] = "This field is required"
  }

  if (
    values[OpeningFieldsNames.PaidNoShows] === null ||
    values[OpeningFieldsNames.PaidNoShows] === undefined ||
    values[OpeningFieldsNames.PaidNoShows] === ""
  ) {
    errors[OpeningFieldsNames.PaidNoShows] = "This field is required"
  }

  return {
    ...errors,
    ...defaultLocationValidation(values),
  }
}

export const getInitialValues = opening => ({
  [OpeningFieldsNames.School]: opening?.school,
  [OpeningFieldsNames.LearningLevel]: opening?.learning_level
    ? LearningLevel.find(({ key }) => opening.learning_level === key)
    : undefined,
  [OpeningFieldsNames.Subject]: opening?.program,
  [OpeningFieldsNames.TutoringType]: opening?.tutoring_type ?? OpeningTutoringTypes.Groups,
  [OpeningFieldsNames.NoteForTutors]: opening?.note ?? "",
  [OpeningFieldsNames.InternalNote]: opening?.internal_note ?? "",
  [OpeningFieldsNames.TutorBindingStatement]: opening?.tutor_statement ?? "",
  [OpeningFieldsNames.PositionLevel]: opening?.position_level
    ? TutoringTypes[opening?.position_level]
    : TutoringTypes.main,
  [OpeningFieldsNames.StartOn]: opening?.start_on ?? null,
  [OpeningFieldsNames.EndOn]: opening?.end_on ?? null,
  [OpeningFieldsNames.TutorRate]: opening?.tutor_rate ?? "",
  [OpeningFieldsNames.SchoolRate]: opening?.school_rate ?? "",
  [OpeningFieldsNames.PaidNoShows]: opening?.max_number_of_noshows ?? "",
  [OpeningFieldsNames.ReportCadence]: opening?.report_cadence ?? "weekly",
  [OpeningFieldsNames.SessionNotification]: opening?.notify_parents_on_upcoming_sessions ? "yes" : "no",
  [OpeningFieldsNames.Duration]: opening?.max_session_duration ?? "",
  [OpeningFieldsNames.MaxSessions]: opening?.max_number_of_sessions ?? "",
  [LocationFieldNames.MeetingFormat]: opening?.location ?? MeetingFormats.PencilSpaces,
  [LocationFieldNames.Link]: opening?.meeting_info ?? "",
  [LocationFieldNames.Zip]: opening?.zip ?? "",
  [LocationFieldNames.Address1]: opening?.address_1 ?? "",
  [LocationFieldNames.Address2]: opening?.address_2 ?? "",
  [LocationFieldNames.City]: opening?.city ?? "",
  [LocationFieldNames.State]: opening?.state ?? "",
})

export const mapFormDataToBackend = data => ({
  school_id: parseInt(data[OpeningFieldsNames.School].value ?? data[OpeningFieldsNames.School].id, 10),
  learning_level: data[OpeningFieldsNames.LearningLevel].key,
  default_program_id: parseInt(data[OpeningFieldsNames.Subject].value ?? data[OpeningFieldsNames.Subject].id, 10),
  tutoring_type: data[OpeningFieldsNames.TutoringType],
  note: data[OpeningFieldsNames.NoteForTutors] ?? "",
  internal_note: data[OpeningFieldsNames.InternalNote] ?? "",
  tutor_statement: data[OpeningFieldsNames.TutorBindingStatement] ?? "",
  position_level: data[OpeningFieldsNames.PositionLevel].key,
  start_on: data[OpeningFieldsNames.StartOn],
  end_on: data[OpeningFieldsNames.EndOn],
  tutor_rate: data[OpeningFieldsNames.TutorRate],
  school_rate: data[OpeningFieldsNames.SchoolRate],
  max_number_of_noshows: data[OpeningFieldsNames.PaidNoShows],
  location: data[LocationFieldNames.MeetingFormat],
  notify_parents_on_upcoming_sessions: data[OpeningFieldsNames.SessionNotification] === "yes",
  max_session_duration: data[OpeningFieldsNames.Duration],
  max_number_of_sessions: data[OpeningFieldsNames.MaxSessions],
  zip: data[LocationFieldNames.Zip] ?? "",
  address_1: data[LocationFieldNames.Address1] ?? "",
  address_2: data[LocationFieldNames.Address2] ?? "",
  city: data[LocationFieldNames.City] ?? "",
  state: data[LocationFieldNames.State] ?? "",
  meeting_info: data[LocationFieldNames.Link] ?? "",
})
