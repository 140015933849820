import BRTThemeProvider from "@/providers/theme"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { Box, Button, Container, Typography, useMediaQuery, useTheme } from "@mui/material"
import { styled } from "@mui/system"
import React from "react"
import { Link } from "react-router-dom"

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  textAlign: "center",
  padding: theme.spacing(3),
}))

const StyledIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  fontSize: "6rem",
  color: theme.palette.error.main,
  marginBottom: theme.spacing(2),
}))

const NotFound = ({ useLink = false }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const ButtonComponent = useLink ? Link : "a"
  const buttonProps = useLink ? { to: "/" } : { href: "/" }
  return (
    <BRTThemeProvider>
      <StyledContainer maxWidth="sm">
        <StyledIcon aria-hidden="true" />
        <Typography variant={isMobile ? "h4" : "h3"} component="h1" gutterBottom>
          404 - Page Not Found
        </Typography>
        <Typography variant="body1" paragraph>
          Oops! The page you were looking for doesn&apos;t exist.
        </Typography>
        <Typography variant="body2" paragraph color="text.secondary">
          You may have mistyped the address or the page may have moved.
        </Typography>
        <Box mt={4}>
          <Button component={ButtonComponent} {...buttonProps} variant="contained" color="primary" size="large">
            Go to Homepage
          </Button>
        </Box>
      </StyledContainer>
    </BRTThemeProvider>
  )
}

export default NotFound
