import { ConfigurationContext, DomainTransactionContext } from "@/contexts"
import React, { useContext, useMemo } from "react"
import { Request } from "./Request"

export const InitialRequests = ({ children }) => {
  const { initial } = useContext(ConfigurationContext)
  const { buildTransaction } = useContext(DomainTransactionContext)
  const transaction = useMemo(
    () =>
      buildTransaction({
        autoCommitOn: initial.length,
      }),
    [buildTransaction, initial]
  )
  return (
    <>
      {initial.map((config, idx) => (
        <Request key={idx} config={config} transaction={transaction} />
      ))}
      {children}
    </>
  )
}
