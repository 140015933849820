import { INTERVIEW_CALENDAR_MAX_TIME, INTERVIEW_CALENDAR_MIN_TIME } from "@/common/constants"
import { formatDateKeepZone } from "@/helpers/dates"
import FullCalendar from "@fullcalendar/react"
import ReactDOM from "react-dom"

import BRTThemeProvider from "@/providers/theme"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import { map } from "lodash"
import MobileDetect from "mobile-detect"
import moment from "moment"
import React, { useCallback, useMemo, useRef } from "react"
import EventContent from "./EventContent"

const DAY_FORMAT = "HH:mm:ss"
const MULTIPLIER_VALUES = [1000, 60, 60]
const HOUR = MULTIPLIER_VALUES.reduce((acc, el) => acc * el, 1)
const md = new MobileDetect(window.navigator.userAgent)

const transform = (action, roundTo, list) => {
  const ms = action(
    ...list.map(time =>
      time
        .split(":")
        .reverse()
        .reduce(
          (acc, value, idx) => acc + parseInt(value) * MULTIPLIER_VALUES.slice(0, idx + 1).reduce((a, b) => a * b, 1),
          0
        )
    )
  )
  return moment().startOf("day").add(ms, "milliseconds")[roundTo]("hour").format(DAY_FORMAT)
}
const transformToDate = date => new Date(formatDateKeepZone(date, "YYYY-MM-DDTHH:mm:ss"))

const SessionCalendar = ({ calendarData }) => {
  const calendarRef = useRef(null)
  const defaultDay = useMemo(() => new Date(formatDateKeepZone(calendarData.weekStart)), [calendarData.weekStart])
  const getDate = useCallback(date => moment(formatDateKeepZone(date)).get("date"), [])

  const buildEndDate = useCallback(
    event => {
      const { start, end } = event
      return getDate(start) !== getDate(end)
        ? moment(start).endOf("day").format(DAY_FORMAT)
        : formatDateKeepZone(end, DAY_FORMAT)
    },
    [getDate]
  )

  const start = useMemo(
    () =>
      transform(Math.min, "startOf", [
        INTERVIEW_CALENDAR_MIN_TIME,
        ...calendarData.events.map(event => formatDateKeepZone(event.start, DAY_FORMAT)),
      ]),
    [calendarData.events]
  )

  const end = useMemo(
    () => transform(Math.max, "endOf", [INTERVIEW_CALENDAR_MAX_TIME, ...calendarData.events.map(buildEndDate)]),
    [buildEndDate, calendarData.events]
  )

  const columnHeaderHtml = useCallback(
    date => `
        <div class="fc-header-text">
          <span class="fc-header-day-number">${formatDateKeepZone(date, "DD")}</span>
          <span class="fc-header-day-title">${formatDateKeepZone(date, "dddd")}</span>
        </div>
      `,
    []
  )

  const onSelectEvent = useCallback(({ event }) => {
    if (event.extendedProps.link) {
      window.open(event.extendedProps.link, "_blank")
    }
  }, [])

  const eventRender = ({ event, el }) => {
    ReactDOM.render(<EventContent event={event} />, el)
  }

  const events = map(calendarData.events, event => {
    const classNames = [`-${event.view}`]

    const start = transformToDate(event.start)
    const end = transformToDate(event.end)

    if (new Date(end).getTime() - new Date(start).getTime() < HOUR) classNames.push("fc-short")

    return {
      id: event.id,
      title: event.title,
      classNames,
      start,
      end,
      overlap: false,
      extendedProps: {
        status: event.status,
        link: event.link,
        is_overdue: event.is_overdue,
        user_time_zone_abbr: event.user_time_zone_abbr,
      },
    }
  })

  return (
    <BRTThemeProvider>
      <FullCalendar
        allDaySlot={false}
        aspectRatio={md.mobile() ? 0.5 : 1.35}
        columnHeader
        columnHeaderHtml={columnHeaderHtml}
        defaultDate={defaultDay}
        defaultView="timeGridWeek"
        eventClick={onSelectEvent}
        eventRender={eventRender}
        timeGridEventMinHeight={15}
        events={events}
        header={false}
        height="auto"
        maxTime={end}
        minTime={start}
        navLinks={true}
        nowIndicator={true}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        ref={calendarRef}
        slotDuration="0:30:00"
        themeSystem="bootstrap"
      />
    </BRTThemeProvider>
  )
}
export default SessionCalendar
