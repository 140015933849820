import { TutorAvatar } from "../tutor_avatar"
import { QuickActions } from "../tutors_list/tutor_card/quick_actions"
import { DEGREE_ABBREVIATION } from "../../../../../common/constants"
import { AdditionalInfo } from "./additional_info"
import React from "react"
import useViewport from "../../../../../hooks/useViewport"

export const TutorInfo = ({ application, short = false }) => {
  const { isMobileViewport } = useViewport()
  const { email, phone } = application.tutor

  return (
    <>
      {!isMobileViewport && <TutorAvatar url={application.tutor.avatar_url} />}
      <div className="opening-tutor-card_bio">
        <div className="d-flex justify-content-between gap-05">
          <div className="d-flex gap-05">
            {isMobileViewport && <TutorAvatar url={application.tutor.avatar_url} />}
            <div className="opening-tutor-card_title">
              <h6 className="opening-tutor-card_name mb-0 text-no-wrap">
                {application.tutor.first_name} {application.tutor.last_name}
              </h6>

              {!short && application.is_program_matches && (
                <div className="d-flex align-items-center flex-wrap gap-05">
                  <div className="opening-tutor-card_label -positive text-no-wrap">matching subject</div>
                </div>
              )}
            </div>
          </div>
          {!short && isMobileViewport && <QuickActions />}
        </div>

        <p className="opening-tutor-card_degree">
          {application.tutor.profile.degrees.map(degree => (
            <span key={degree.degree + degree.college}>
              {DEGREE_ABBREVIATION[degree.degree]}, {degree.college}
            </span>
          ))}
        </p>

        {phone && <p>Phone: {phone}</p>}
        {email && <p>Email: {email}</p>}
        {!short && <p>{application.tutor.profile.bio}</p>}

        {!short && (
          <a
            href={application.tutor.profile_url}
            aria-label={`View User Profile for ${application.tutor.first_name} ${application.tutor.last_name}`}
          >
            View Profile
          </a>
        )}

        {!short && <AdditionalInfo tutor={application.tutor} />}
      </div>
    </>
  )
}
