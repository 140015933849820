import React, { useMemo } from "react"
import useViewport from "../../../../hooks/useViewport"
import { className as cn } from "../../../../helpers/className"

export const TextRow = ({ label, value, className, labelClassName = "text-black-50", valueClassName }) => {
  const { isMobileViewport } = useViewport()
  const classNames = useMemo(
    () => ({
      wrapper: cn("d-flex align-items-start", className, isMobileViewport ? "flex-column" : "gap-1"),
      label: cn(labelClassName, !isMobileViewport && "w-25"),
      value: cn(valueClassName, !isMobileViewport && "w-75"),
    }),
    [className, isMobileViewport, labelClassName, valueClassName]
  )
  return (
    <div className={classNames.wrapper}>
      <div className={classNames.label}>{label}</div>
      <div className={classNames.value}>{value ?? "—"}</div>
    </div>
  )
}

export default TextRow
