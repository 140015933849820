import { capitalizeFirstLetter } from "@/helpers/formatters"
import { Typography } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { Text } from "./styles"
import { getScheduleUrl } from "./utils"

const Headline = ({ sessionStartsIn, programName, students, scheduleId, sessionScheduledAt }) => {
  return (
    <Text variant="body2" component="div" gutterBottom>
      <strong>{capitalizeFirstLetter(sessionStartsIn)}: </strong> {programName}
      {students.length > 0 && (
        <>
          &nbsp;with&nbsp;
          <Typography
            component="a"
            variant="body2"
            href={getScheduleUrl(scheduleId, sessionScheduledAt)}
            aria-label="Students list"
          >
            {students.map(({ first_name, last_name }, index) => (
              <span key={`${first_name}-${last_name}-${index}`}>
                {`${first_name} ${last_name}`}
                {index < students.length - 1 ? ", " : "."}
              </span>
            ))}
          </Typography>
        </>
      )}
    </Text>
  )
}

Headline.propTypes = {
  sessionStartsIn: PropTypes.string.isRequired,
  programName: PropTypes.string.isRequired,
  students: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    })
  ).isRequired,
  scheduleId: PropTypes.number,
  sessionScheduledAt: PropTypes.string,
}

export default React.memo(Headline)
