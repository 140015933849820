import React from "react"
import cn from "classnames"

const Chip = ({ children, disabled, onClose }) => {
  return (
    <span className="badge badge-pill badge-light mr-2 mb-2">
      <span className={cn(!disabled && "mr-4")}>{children}</span>
      {!disabled && (
        <button type="button" className="close" onClick={onClose}>
          ✕
        </button>
      )}
    </span>
  )
}

export default Chip
