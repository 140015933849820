import { useForm, useFormState } from "react-final-form"
import React, { useCallback } from "react"

export const FormAutoSubmit = ({ submitAbilityRef, handleSubmit, className, children }) => {
  const form = useForm()
  const onChange = useCallback(() => {
    if (submitAbilityRef?.current) form.submit()
  }, [submitAbilityRef, form])
  useFormState({ onChange, subscription: { values: true } })
  return (
    <form onSubmit={handleSubmit} className={className}>
      {children}
    </form>
  )
}
