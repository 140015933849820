import { Entities, Roles, Methods } from "../../constants"

const groupSessionsInitialParamsBuilder = getState => {
  const groupSessionId = getState("groupSessionId")
  return { entitiesIds: { [Entities.GroupSessions]: groupSessionId } }
}

export const getStatus = {
  entities: [Entities.GroupSessions],
  action: "status",
  alias: "statusInfo",
  initialParamsBuilder: groupSessionsInitialParamsBuilder,
}

export const getDashboard = {
  entities: [Entities.GroupSessions],
  action: "dashboard",
  alias: "groupInfo",
  initialParamsBuilder: groupSessionsInitialParamsBuilder,
}

export const getWeek = {
  entities: [Entities.GroupSessions],
  action: "week",
  alias: "weekInfo",
  initialParamsBuilder: groupSessionsInitialParamsBuilder,
}

export const getReport = {
  entities: [Entities.GroupSessions],
  action: "report",
  alias: "reportInfo",
  initialParamsBuilder: groupSessionsInitialParamsBuilder,
}

export const getGroupProgress = {
  entities: [Entities.GroupSessions, Entities.GroupSessionStudents],
  alias: "groupProgressInfo",
  initialParamsBuilder: groupSessionsInitialParamsBuilder,
}

export const getTutorHimselfPrograms = {
  entities: [Entities.Tutors],
  action: "my_programs",
  alias: "tutorProgramsInfo",
  permissions: { allow: [Roles.Tutor] },
  onStoreUpdate: data => data.items,
}

export const getTutorPrograms = {
  entities: [Entities.Tutors],
  action: "programs",
  alias: "tutorProgramsInfo",
  permissions: { deny: [Roles.Parent] },
  onStoreUpdate: data => data.items,
}

export const createGroupSession = {
  entities: [Entities.GroupSessions],
  action: "create",
  method: Methods.Post,
  permissions: { deny: [Roles.Parent] },
  skipStorage: true,
  next: [getWeek, getStatus, getGroupProgress],
}

export const updateGroupSession = {
  method: Methods.Put,
  entities: [Entities.GroupSessions],
  permissions: { deny: [Roles.Parent] },
  skipStorage: true,
  next: [getWeek, getStatus, getGroupProgress],
}

export const deleteGroupSession = {
  method: Methods.Delete,
  entities: [Entities.GroupSessions],
  permissions: { deny: [Roles.Parent] },
  skipStorage: true,
  next: [getWeek, getStatus, getGroupProgress],
}

export const cancelGroupSession = {
  method: Methods.Patch,
  entities: [Entities.GroupSessions],
  action: "cancel",
  permissions: { deny: [Roles.Parent] },
  skipStorage: true,
  next: [getWeek, getStatus, getGroupProgress],
}

export const finishGroupSession = {
  method: Methods.Patch,
  entities: [Entities.GroupSessions],
  action: "finish",
  permissions: { deny: [Roles.Parent] },
  skipStorage: true,
  next: [getWeek, getStatus, getGroupProgress],
}

export const updateReportAgenda = {
  method: Methods.Put,
  entities: [Entities.GroupSessions],
  action: "report",
  skipStorage: true,
  next: [getReport, getStatus],
  alias: "agenda",
}

export const updateReportNextSteps = {
  method: Methods.Put,
  entities: [Entities.GroupSessions],
  action: "report",
  skipStorage: true,
  next: [getReport, getStatus],
  alias: "next_steps",
}

export const markAbsentStudent = {
  method: Methods.Post,
  entities: [Entities.GroupSessionStudents],
  action: "mark_absent",
  skipStorage: true,
  next: [getGroupProgress, getStatus],
}

export const updateGroupSessionStudentAttendance = {
  method: Methods.Put,
  entities: [Entities.GroupSessionStudents],
  skipStorage: true,
  next: [getGroupProgress, getStatus],
  alias: "attendance",
}

export const updateGroupSessionStudentStrengths = {
  method: Methods.Put,
  entities: [Entities.GroupSessionStudents],
  skipStorage: true,
  next: [getGroupProgress, getStatus],
  alias: "strengths",
}

export const updateCustomProgram = {
  method: Methods.Put,
  entities: [Entities.GroupSessions],
  action: "program",
  skipStorage: true,
  next: [getGroupProgress, getStatus],
}

export const getStudentProgramProgress = {
  method: Methods.Get,
  entities: [Entities.GroupSessionStudents, Entities.ProgramProgresses],
  skipStorage: true,
}

export const getNewStudentProgramProgress = {
  method: Methods.Get,
  entities: [Entities.GroupSessionStudents, Entities.ProgramProgresses],
  action: "new",
  skipStorage: true,
}

export const createStudentProgramProgress = {
  method: Methods.Post,
  entities: [Entities.GroupSessionStudents, Entities.ProgramProgresses],
  skipStorage: true,
  next: [getStatus, getGroupProgress],
}

export const updateStudentProgramProgress = {
  method: Methods.Put,
  entities: [Entities.GroupSessionStudents, Entities.ProgramProgresses],
  skipStorage: true,
  next: [getStatus, getGroupProgress],
}

export const deleteStudentProgramProgress = {
  method: Methods.Delete,
  entities: [Entities.GroupSessionStudents, Entities.ProgramProgresses],
  skipStorage: true,
  next: [getStatus, getGroupProgress],
}

export const sendReport = {
  method: Methods.Post,
  entities: [Entities.GroupSessions],
  action: "report_ready",
  skipStorage: true,
  next: [getStatus, getGroupProgress, getWeek],
}

export const restoreReport = {
  method: Methods.Patch,
  entities: [Entities.GroupSessions],
  action: "restore",
  skipStorage: true,
  next: [getStatus, getGroupProgress, getWeek],
}

export const pendReport = {
  method: Methods.Patch,
  entities: [Entities.GroupSessions],
  permissions: { allow: [Roles.Admin] },
  action: "pend",
  skipStorage: true,
  next: [getStatus, getGroupProgress, getWeek],
}

export const withRoles = [
  getStatus,
  getDashboard,
  getWeek,
  getReport,
  getGroupProgress,
  getTutorHimselfPrograms,
  getTutorPrograms,
  createGroupSession,
  updateGroupSession,
  deleteGroupSession,
  cancelGroupSession,
  finishGroupSession,
  updateReportAgenda,
  updateReportNextSteps,
  markAbsentStudent,
  updateGroupSessionStudentAttendance,
  updateGroupSessionStudentStrengths,
  updateCustomProgram,
  getNewStudentProgramProgress,
  createStudentProgramProgress,
  updateStudentProgramProgress,
  deleteStudentProgramProgress,
  sendReport,
  restoreReport,
  pendReport,
]

export const updateProgramProgressForAllStudents = {
  method: Methods.Post,
  entities: [Entities.GroupSessions, Entities.ProgramProgresses],
  action: "all",
  skipStorage: true,
  next: [getStatus, getGroupProgress],
}

export const unified = [updateProgramProgressForAllStudents, getStudentProgramProgress]

// used only for build correct upload url
export const changeSessionDocuments = {
  entities: [Entities.GroupSessions],
  action: "report/documents",
}

export default {
  withRoles,
  unified,
}
