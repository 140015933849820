import React from "react"

const UsersNavbar = ({ title, children }) => (
  <nav className="users-navbar">
    <div className="users-navbar_container container">
      <h1 className="users-navbar_title mb-3 mb-md-0">{title}</h1>
      {children}
    </div>
  </nav>
)

export default UsersNavbar
