import React from "react"
import { Button, Col, Container, Modal, Row } from "react-bootstrap"
import { Form, Field } from "react-final-form"
import { required } from "../../helpers/validators"

const MessageFormModal = ({ show, onCancel, onSubmit, isSubmitting, from, recipient, subject, changeSubject }) => {
  const onFormSubmit = values => {
    onSubmit(values)
  }

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={{
        from: from.name,
        email: from.email,
        from_id: from.id,
        recipient_id: recipient.id,
        subject: subject,
      }}
    >
      {({ handleSubmit, pristine, invalid }) => (
        <Modal show={show} onHide={onCancel} size="lg" centered dialogClassName="message-form-modal">
          <Modal.Header className="p-0 pt-lg-3 px-lg-3" closeButton={true}>
            <Container className="mt-2-mobile">
              <Row>
                <Col xs={{ span: 24 }} lg={{ span: 12, order: "last" }}>
                  <Row className="pb-2 ">
                    <Col xs={{ span: 3 }} className="mt-2 text-right text-gray-chat hidden-mobile">
                      To:
                    </Col>
                    <Col xs={{ span: 18 }} className="hidden-mobile">
                      <div className="text-primary font-weight-bold recipient-name">
                        {recipient.name}
                        <Field name="recipient_id" component="input" className="hidden" />
                      </div>
                      <div className={"hidden"}>[Recipient Review Rating]</div>
                      <div className="text-gray-chat">Usually responds in {recipient.averageResponseTime}</div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12, order: "first" }}>
                  <Row className="pb-2">
                    <Col xs={{ span: 5 }} className="mt-2 text-right text-gray-chat">
                      <label>From:</label>
                    </Col>
                    <Col>
                      <Field
                        name="from"
                        component="input"
                        type="text"
                        className="form-control text-gray-chat chat-input"
                        readOnly={true}
                      />
                      <Field name="from_id" component="input" className="hidden" />
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col xs={{ span: 5 }} className="mt-2 text-right text-gray-chat">
                      <label>Email:</label>
                    </Col>
                    <Col>
                      <Field
                        name="email"
                        component="input"
                        type="text"
                        className="form-control text-gray-chat chat-input"
                        readOnly={true}
                      />
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col xs={{ span: 5 }} className="mt-2 text-right text-gray-chat">
                      <label>Subject:</label>
                    </Col>
                    <Col>
                      <Field
                        name="subject"
                        component="input"
                        type="text"
                        className="form-control text-gray-chat chat-input"
                        readOnly={!changeSubject}
                        validate={required}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body className="px-0 py-2 px-lg-3">
            <Container>
              <Field name="body" component="textarea" className="form-control chat-textarea" validate={required} />
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row>
                <Col className="text-center text-lg-right">
                  <Button
                    variant="outline-primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting || pristine || invalid}
                  >
                    Send
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>
      )}
    </Form>
  )
}

export default MessageFormModal
