import React, { useState } from "react"
import { Button } from "react-bootstrap"

import ReviewModal from "./ReviewModal"
import ReviewSuccessModal from "./ReviewSuccessModal"
import apiClient, { showAllErrorsInAlert, replaceId } from "../../common/apiClient"
import Alerter from "../../common/alerter"

const ReviewButton = ({ buttonText, className, review, show_form, tutor, urls }) => {
  const [currentReview, setCurrentReview] = useState(review)
  const [showReviewModal, setShowReviewModal] = useState(show_form)
  const [showReviewSuccessModal, setShowReviewSuccessModal] = useState(false)

  const saveReview = async values => {
    try {
      const { data } = await apiClient.post(urls.create, values)
      setCurrentReview(data)
      setShowReviewModal(false)
      setShowReviewSuccessModal(true)
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const editReview = async values => {
    try {
      const { data } = await apiClient.put(replaceId(urls.update, currentReview.id), values)
      setCurrentReview(data)
      setShowReviewModal(false)
      Alerter.success("Your review was successfully changed")
      window.location.replace(tutor.profile_link)
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const deleteReview = async id => {
    try {
      await apiClient.delete(replaceId(urls.delete, id))
      setShowReviewModal(false)
      Alerter.success("Your review was successfully deleted")
      window.location.replace(tutor.profile_link)
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const onCloseReviewSuccessModal = () => {
    setShowReviewSuccessModal(false)
    window.location.replace(tutor.profile_link)
  }

  return (
    <>
      <Button variant="outline-primary" className={className} onClick={() => setShowReviewModal(true)}>
        {buttonText || review ? "Edit" : "Leave a review"}
      </Button>
      <ReviewModal
        show={showReviewModal}
        tutor={tutor}
        review={review}
        onSubmit={values => (review ? editReview(values) : saveReview(values))}
        onDelete={() => deleteReview(review.id)}
        handleClose={() => setShowReviewModal(false)}
      />
      <ReviewSuccessModal show={showReviewSuccessModal} handleClose={onCloseReviewSuccessModal} />
    </>
  )
}

export default ReviewButton
