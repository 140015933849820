import React, { useMemo } from "react"
import { Field, useField, useForm } from "react-final-form"
import { compose, map } from "lodash/fp"

import ProgramPicker from "../blocks/ProgramPicker"
import { getDomainsByGrade, isExecutiveFunctionProgram } from "../../helpers/programsHierarchy"

const getDomainsOptions = (gradeId, hierarchy) =>
  compose(
    map(({ domain, programs }) => ({ ...domain, programs })),
    hierarchy => getDomainsByGrade(gradeId, hierarchy)
  )(hierarchy)

const MultipleProgramsDropdown = ({
  maxProgramsCount = 1,
  className,
  getButtonText,
  completed,
  disabled,
  options,
  onSubmit,
}) => {
  const form = useForm()
  const {
    input: { value: gradeId },
  } = useField("grade")

  const domains = useMemo(() => getDomainsOptions(gradeId, options.hierarchy), [options, gradeId])

  const onChange = (input, value) => {
    if (input.value.includes(value)) {
      return
    }

    input.onChange([...input.value, value].slice(-maxProgramsCount))

    if (isExecutiveFunctionProgram(value, options.programs)) {
      form.mutators.setFieldData("executive_function", false)
    }

    onSubmit && onSubmit()
  }

  return (
    <Field name="program_ids">
      {({ input }) => (
        <ProgramPicker
          program={input.value}
          className={className}
          wrapperClassName="anl-subject"
          domains={domains}
          selected={completed}
          disabled={disabled}
          buttonText={getButtonText(input.value, options)}
          onChange={value => onChange(input, value)}
        />
      )}
    </Field>
  )
}

export default MultipleProgramsDropdown
