import React, { useCallback, useEffect, useRef } from "react"
import { Form as FForm, Field, useForm, useFormState } from "react-final-form"
import { Form } from "react-bootstrap"

const FieldNames = {
  ShowMatched: "show_matched",
}

const initialValues = {
  [FieldNames.ShowMatched]: false,
}

const FormAutoSubmit = ({ submitAbilityRef, handleSubmit, className, children }) => {
  const form = useForm()
  const onChange = useCallback(() => {
    if (submitAbilityRef?.current) form.submit()
  }, [submitAbilityRef, form])
  useFormState({ onChange, subscription: { values: true } })
  return (
    <form onSubmit={handleSubmit} className={className}>
      {children}
    </form>
  )
}

export const TutorMatchingForm = ({ onSubmit }) => {
  const submitAbilityRef = useRef(false)
  useEffect(() => {
    submitAbilityRef.current = true
  }, [])
  return (
    <FForm initialValues={initialValues} onSubmit={onSubmit} subscription={{ values: true }}>
      {({ handleSubmit }) => (
        <FormAutoSubmit
          submitAbilityRef={submitAbilityRef}
          onSubmit={handleSubmit}
          className="d-flex align-items-center gap-2"
        >
          <Field name={FieldNames.ShowMatched} type="checkbox">
            {({ input }) => (
              <Form.Check id={FieldNames.ShowMatched} label="Show matched groups" className="v-2" custom {...input} />
            )}
          </Field>
        </FormAutoSubmit>
      )}
    </FForm>
  )
}
