import { OpeningStatus } from "../constants"
import { ReactComponent as AppliedIcon } from "../../../assets/images/tutor-applied-icon.svg"
import { ReactComponent as ApprovedIcon } from "../../../assets/images/approved-icon.svg"
import { ReactComponent as RejectedIcon } from "../../../assets/images/tutor-rejected-icon.svg"
import React from "react"

const TutorStatus = ({ status, text, children }) => (
  <div className={`opening-tutor-status ${status ? `-${status}` : ""}`.trim()}>
    <div className="icon-20-px">{children}</div>
    <span className="ml-2 font-weight-semibold">{text}</span>
  </div>
)

export const Status = ({ opening, openOpening }) => {
  const { status } = opening
  switch (status) {
    case OpeningStatus.Applied:
    case OpeningStatus.Recommended:
      return (
        <TutorStatus text="Applied for opportunity" status={status}>
          <AppliedIcon />
        </TutorStatus>
      )
    case OpeningStatus.Approved:
      return (
        <TutorStatus text="Approved" status={status}>
          <ApprovedIcon />
        </TutorStatus>
      )
    case OpeningStatus.Rejected:
      return (
        <TutorStatus text="Rejected" status={status}>
          <RejectedIcon />
        </TutorStatus>
      )
    case OpeningStatus.None:
    default:
      return (
        <button
          className="btn btn-outline-primary"
          onClick={() => openOpening(opening)}
          data-test={`apply-opening-${opening.id}`}
        >
          Apply for opportunity
        </button>
      )
  }
}
