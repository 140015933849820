import React, { useContext, useState, useEffect, useCallback, useMemo, Fragment, useRef, useReducer } from "react"
import { Form, Modal, Row, Col, Tab, Nav, Popover, OverlayTrigger } from "react-bootstrap"
import { cloneDeep } from "lodash"
import moment from "moment"
import { DataContext, ValidationContext, QueryContext, ApiContext, DataContextActions } from "../../contexts"
import FormRow from "../../components/blocks/FormRow"
import RowWarning from "../blocks/RowWarning"
import { selectProgramSubskills, getTabIcon, getTabIconClassName, daysInStatus } from "./helpers"
import { PropsInjector } from "../PropsInjector"
import Switch from "../blocks/Switch"
import useViewport from "../../hooks/useViewport"
import EditableSkillList from "./editable_skill_list"
import { UploadFileField } from "../UploadFileField"
import { ValidationNames, cancelledStatuses } from "./constants"
import { Entities, Roles } from "../../constants"
import {
  getTutorHimselfPrograms,
  getTutorPrograms,
  updateReportAgenda,
  updateReportNextSteps,
  markAbsentStudent,
  updateGroupSessionStudentAttendance,
  updateGroupSessionStudentStrengths,
  updateCustomProgram,
  getStudentProgramProgress,
  getNewStudentProgramProgress,
  deleteStudentProgramProgress,
  createStudentProgramProgress,
  updateStudentProgramProgress,
  getReport,
  sendReport,
  restoreReport,
  updateProgramProgressForAllStudents,
  changeSessionDocuments,
} from "./configuration"
import PartialEntityTextUpdate from "../../components/blocks/PartialEntityTextUpdate"
import { ProgramProgressBars } from "../blocks/ProgramProgressBars"
import { urlBuilder } from "../../hooks/useQueryList"
import { ReactComponent as EmptyBox } from "../../assets/images/empty_box.svg"
import { ReactComponent as Pencil } from "../../assets/images/pencil.svg"
import { ReactComponent as ReportOverdue } from "../../assets/images/report-overdue.svg"
import { ReactComponent as Delete } from "../../assets/images/delete.svg"
import { ReactComponent as Copy } from "../../assets/images/copy.svg"

const baseTabs = ["Progress", "Documents"]

const RevertProgressButton = () => {
  const { groupSessionId } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(restoreReport)

  const sendReportRestore = async () =>
    await request({
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
    })

  return (
    <div className={`d-flex ${loading ? "align-items-center" : "align-items-baseline"}`}>
      <span className="school-session-report-reverse-text">Report submitted and will be sent on Sunday.</span>
      {loading ? (
        <div className="spinner-border text-primary ml-2" />
      ) : (
        <button className="btn btn-link ml-2" onClick={sendReportRestore}>
          Edit report
        </button>
      )}
    </div>
  )
}

const SendReportButton = ({ popoverPlacement = "bottom", buttonClassName = "" }) => {
  const { isMobileViewport } = useViewport()
  const { groupSessionId, statusInfo } = useContext(DataContext)
  const { getValidationValueByName, getErrorList } = useContext(ValidationContext)
  const { can_report_ready } = statusInfo
  const isInvalid = getValidationValueByName(ValidationNames.CommonInvalid)
  const errorList = useMemo(() => getErrorList(), [getErrorList])
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(sendReport)

  const invalid = isInvalid || !can_report_ready
  const correctSessionStatus = statusInfo.status === "in_progress" || statusInfo.status === "action_needed"

  const sendReportInfo = useCallback(
    async () =>
      await request({
        entitiesIds: {
          [Entities.GroupSessions]: groupSessionId,
        },
      }),
    [groupSessionId, request]
  )

  const ErrorListPopover = useMemo(
    () => (
      <Popover className="school-session-report">
        {errorList.length > 0 && (
          <Popover.Content>
            <div className="school-session-report_error-tip mb-2">You need to fill in the following data:</div>
            <ul className="school-session-report_error-list">
              {errorList.map((error, idx) => (
                <li key={idx} className="school-session-report_error-list-item">
                  <span className="school-session-report_error-text">{error}</span>
                </li>
              ))}
            </ul>
          </Popover.Content>
        )}
      </Popover>
    ),
    [errorList]
  )

  const RequestButton = useMemo(
    () => (
      <button
        onClick={invalid ? () => {} : sendReportInfo}
        tabIndex={invalid ? -1 : 1}
        className={`btn btn-outline-primary session-report-submit ${buttonClassName} ${
          invalid ? "cursor-default disabled" : ""
        }`}
      >
        <div className="d-flex align-items-center justify-content-center">
          <span className={!isMobileViewport && invalid && correctSessionStatus ? "ml-n2" : ""}>Send Report</span>
          {invalid && correctSessionStatus && (
            <div className="school-session-report-error-icon ml-2">
              <ReportOverdue />
            </div>
          )}
        </div>
      </button>
    ),
    [buttonClassName, correctSessionStatus, invalid, isMobileViewport, sendReportInfo]
  )

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  if (errorList.length === 0) return RequestButton
  else
    return (
      <OverlayTrigger
        trigger={isMobileViewport ? ["click"] : ["hover", "focus"]}
        placement={popoverPlacement}
        overlay={ErrorListPopover}
        popperConfig={{ enabled: invalid }}
      >
        {RequestButton}
      </OverlayTrigger>
    )
}

const Header = ({ hideSubmit }) => {
  const { statusInfo } = useContext(DataContext)
  const { isPhoneViewport } = useViewport()

  return (
    <div className={`d-flex ${isPhoneViewport ? "flex-column" : "align-items-center"}`}>
      <div className={`flex-grow-1 ${isPhoneViewport ? "mb-3" : "mr-3"}`}>
        <h4 className="session-report-header">
          <span>Weekly&nbsp;Report</span>
        </h4>
      </div>
      {!hideSubmit && statusInfo.status !== "report_submitted" && <SendReportButton />}
      {statusInfo.can_restore && statusInfo.status === "report_submitted" && <RevertProgressButton />}
    </div>
  )
}

const Footer = () => {
  const { getValidationValueByName } = useContext(ValidationContext)
  const { isPhoneViewport } = useViewport()

  return (
    <div className={`d-flex ${isPhoneViewport ? "flex-column" : "align-items-center"}`}>
      <SendReportButton popoverPlacement="top" />
      {getValidationValueByName(ValidationNames.CommonInvalid) && (
        <span className={`session-report_submit-tip ${isPhoneViewport ? "mt-4 text-center" : "ml-4"}`}>
          All fields required
        </span>
      )}
    </div>
  )
}

const formRowProps = {
  LabelProps: { sm: 24, lg: 7, className: "v-2" },
  ColProps: { sm: 24, lg: 17, className: "mb-0" },
  RowProps: {},
  className: "mb-0",
}

const SessionTab = ({ name, studentId, status }) => {
  const { getValidationValueByName } = useContext(ValidationContext)
  const Icon = getTabIcon(status)
  const iconClass = getTabIconClassName(status)
  const iconClassName = [
    "session-report_tab-icon",
    "ml-1",
    iconClass,
    getValidationValueByName([ValidationNames.GroupProgressInfoStudentValid, studentId]) ? "-warning" : false,
  ]
    .filter(Boolean)
    .join(" ")

  return (
    <Nav.Item className="brain-trust-tab">
      <Nav.Link eventKey={studentId} as="button" role="tab" className="brain-trust-tab-content">
        <span>{name}</span>
        <div className={iconClassName}>
          <Icon />
        </div>
      </Nav.Link>
    </Nav.Item>
  )
}

const SkillListWrapper = ({ studentId, showCopyToRestStudents, programProgressId, editableProgramId, onCancel }) => {
  const { groupProgressInfo, groupSessionId } = useContext(DataContext)
  const [fullProgramProgress, setFullProgramProgress] = useState(null)
  const selectedSubskills = useMemo(() => {
    const student = groupProgressInfo.find(el => el.id === studentId)
    const progress = student.program_progresses.find(el => el.id === programProgressId)
    return selectProgramSubskills(progress?.skills || [])
  }, [groupProgressInfo, programProgressId, studentId])

  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(
    programProgressId === "new" ? getNewStudentProgramProgress : getStudentProgramProgress
  )

  const studentProgramProgressRequest = useCallback(
    async () =>
      await request({
        params: programProgressId === "new" ? { program_id: editableProgramId } : null,
        entitiesIds: {
          [Entities.GroupSessionStudents]: studentId,
          [Entities.ProgramProgresses]: programProgressId === "new" ? null : programProgressId,
        },
        onSuccess: setFullProgramProgress,
      }),
    [editableProgramId, programProgressId, request, studentId]
  )

  const { request: programProgressRequest } = getHookState(
    programProgressId === "new" ? createStudentProgramProgress : updateStudentProgramProgress
  )

  const { request: progressForAllStudents } = getHookState(updateProgramProgressForAllStudents)

  const onEdit = async ({ copyToAllStudents, ...program_progress }) => {
    if (copyToAllStudents) {
      await progressForAllStudents({
        data: { program_progress },
        entitiesIds: {
          [Entities.GroupSessions]: groupSessionId,
        },
      })
    } else
      await programProgressRequest({
        data: { program_progress },
        entitiesIds: {
          [Entities.GroupSessionStudents]: studentId,
          [Entities.ProgramProgresses]: programProgressId === "new" ? void 0 : programProgressId,
        },
      })
  }

  useEffect(() => {
    if (loading) return
    if (programProgressId !== null && fullProgramProgress === null) studentProgramProgressRequest()
  }, [studentProgramProgressRequest, programProgressId, loading, fullProgramProgress])

  if (fullProgramProgress === null || loading) {
    return (
      <div className="min-h-400-px d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  return (
    <EditableSkillList
      programId={editableProgramId}
      progressId={studentId}
      selectedSubskills={selectedSubskills}
      programProgress={fullProgramProgress.skills}
      title={fullProgramProgress.program?.name}
      onCancel={onCancel}
      onEdit={onEdit}
      showCopyToRestStudents={showCopyToRestStudents}
    />
  )
}

const SkillsModal = ({ show, showCopyToRestStudents, studentId, programProgressId, editableProgramId, onHide }) => {
  const { isMobileViewport } = useViewport()

  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop="static" keyboard={false} dialogClassName="skill-list">
      <Modal.Header className="-empty" closeButton />
      <Modal.Body className={`py-4 ${isMobileViewport ? "py-5" : "px-5"}`}>
        <Col className="px-3 py-2">
          <SkillListWrapper
            editableProgramId={editableProgramId}
            studentId={studentId}
            programProgressId={programProgressId}
            onCancel={onHide}
            showCopyToRestStudents={showCopyToRestStudents}
          />
        </Col>
      </Modal.Body>
    </Modal>
  )
}

const SkillStatuses = {
  Mastered: "mastered",
  WorkingOn: "working_on",
  StudiedEarlier: "studied_earlier",
}

const skillStatusesOrder = [SkillStatuses.Mastered, SkillStatuses.WorkingOn, SkillStatuses.StudiedEarlier]

const SkillStatusTitles = {
  [SkillStatuses.Mastered]: "Skills mastered",
  [SkillStatuses.WorkingOn]: "Working on",
  [SkillStatuses.StudiedEarlier]: "Studied earlier",
}

const SkillStatusStyles = {
  [SkillStatuses.Mastered]: "-mastered",
  [SkillStatuses.WorkingOn]: "-working-on",
  [SkillStatuses.StudiedEarlier]: "-studied-earlier",
}

const ProgramProgress = ({ visibleSkillStatus, skills, className }) => {
  return (
    <FormRow
      label={SkillStatusTitles[visibleSkillStatus]}
      {...formRowProps}
      LabelProps={{ ...formRowProps.LabelProps, className: "school-report_skill-status" }}
      className={className}
    >
      <div className="school-report_content-w-410 d-flex flex-column align-items-stretch my-2">
        {skills.map(({ id, name, subskills }) => {
          return (
            <Fragment key={id}>
              <span className="school-report_subskill-name">{name}</span>
              {subskills.map(({ id, name, mastered_status: status }) => (
                <div key={id} className={`skill v-2 color ${SkillStatusStyles[status] || ""}`} title={name}>
                  {name}
                </div>
              ))}
            </Fragment>
          )
        })}
      </div>
    </FormRow>
  )
}

const ProgramTitle = ({ name, programId, programProgressId, readonly, onEdit, loading, onDelete }) => {
  const deleteProgress = () => {
    onDelete({ programProgressId })
  }

  return (
    <div className="d-flex align-items-start justify-content-between mb-3">
      <div className="d-flex">
        <h4 className="school-report_program-title">{name}</h4>
        {!readonly && (
          <>
            {!loading ? (
              <button onClick={deleteProgress} className="school-report_program-action -delete ml-2">
                <Delete />
              </button>
            ) : (
              <div className="school-report_program-action-spinner spinner-border text-primary ml-2" />
            )}
          </>
        )}
      </div>
      {!readonly && (
        <button
          disabled={loading}
          onClick={() => onEdit({ programId, programProgressId })}
          className="school-report_program-action -add ml-4"
        >
          <Pencil />
        </button>
      )}
    </div>
  )
}

const ProgramProgressList = ({
  studentId,
  showCopyToRestStudents,
  defaultProgramId,
  studentName,
  readonly,
  studentPrograms,
  onEdit,
}) => {
  const removingProgressIdsRef = useRef(new Set())
  const filterProgressByStatus = useCallback(([visibleSkillStatus, program]) => {
    let list = cloneDeep(program.skills)
    for (const skill of list) {
      skill.subskills = skill.subskills.filter(({ mastered_status }) => mastered_status === visibleSkillStatus)
    }
    list = list.filter(skill => skill.subskills.length)
    return {
      status: visibleSkillStatus,
      list,
    }
  }, [])
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(deleteStudentProgramProgress)

  const deleteProgress = async ({ programProgressId }) => {
    removingProgressIdsRef.current.add(programProgressId)
    await request({
      entitiesIds: {
        [Entities.GroupSessionStudents]: studentId,
        [Entities.ProgramProgresses]: programProgressId,
      },
      onComplete: () => {
        removingProgressIdsRef.current.delete(programProgressId)
      },
    })
  }

  return (
    <>
      {studentPrograms
        .sort(progress => (progress.program.id === defaultProgramId ? -1 : 1))
        .map(progress => {
          return (
            <Fragment key={progress.id}>
              <ProgramTitle
                name={progress.program.name}
                programId={progress.program.id}
                programProgressId={progress.id}
                readonly={readonly}
                onEdit={onEdit}
                onDelete={deleteProgress}
                loading={loading && removingProgressIdsRef.current.has(progress.id)}
              />
              {skillStatusesOrder
                .map(status => [status, progress])
                .map(filterProgressByStatus)
                .filter(({ list }) => list.length)
                .map(({ status, list }, idx, arr) => (
                  <ProgramProgress
                    key={status}
                    visibleSkillStatus={status}
                    skills={list}
                    className={idx === arr.length - 1 ? "mb-4" : ""}
                  />
                ))}
              {showCopyToRestStudents && !readonly && <CopySkillsToAllStudents programProgress={progress} />}
              <OverallProgress studentName={studentName} studentId={studentId} programProgressId={progress.id} />
            </Fragment>
          )
        })}
    </>
  )
}

const ProgramSelector = ({ defaultProgram, availablePrograms, studentPrograms, hasCustomProgram, onAdd }) => {
  const programs = useMemo(() => {
    let list = cloneDeep(availablePrograms)
    if (studentPrograms.length)
      list = list.filter(
        program => !studentPrograms.find(progressProgram => `${progressProgram.program.id}` === program.value)
      )
    if (hasCustomProgram) list = list.filter(program => program.title.toLowerCase() !== "other")
    const defaultProgramIdx = list.findIndex(el => el.value === `${defaultProgram.id}`)
    if (defaultProgramIdx !== -1) list[defaultProgramIdx].title = `${list[defaultProgramIdx].title} (default)`
    return list
  }, [availablePrograms, defaultProgram.id, studentPrograms, hasCustomProgram])

  const getDefaultValue = useCallback(() => {
    const exist = programs.some(el => el.value === `${defaultProgram.id}`)
    return exist ? `${defaultProgram.id}` : programs.length > 0 ? programs[0].value : void 0
  }, [defaultProgram.id, programs])

  const [selectedProgramId, setSelectedProgramId] = useState(getDefaultValue)
  const updateSelectedProgramId = ({ target: { value } }) => setSelectedProgramId(value)

  const onAddSkills = () => {
    const selectedProgram = availablePrograms.find(program => program.value === selectedProgramId)
    onAdd(selectedProgram)
  }

  useEffect(() => {
    if (selectedProgramId !== void 0 && !programs.some(el => el.value === selectedProgramId))
      setSelectedProgramId(getDefaultValue())
  }, [getDefaultValue, programs, selectedProgramId])

  return (
    <div className="school-report_content-w-410 d-flex align-items-stretch mt-n1">
      <Form.Control as="select" className="v-2" value={selectedProgramId} onChange={updateSelectedProgramId}>
        {programs.map(({ title, value }) => (
          <option key={value} value={value}>
            {title}
          </option>
        ))}
      </Form.Control>
      <button className="partial-entity-text-add-btn flex-shrink-0 ml-2" onClick={onAddSkills}>
        Add skills
      </button>
    </div>
  )
}

const CopySkillsToAllStudentsModal = ({ show, onConfirm, onCancel }) => {
  return (
    <Modal
      size="sm"
      show={show}
      onHide={onCancel}
      backdrop="static"
      keyboard={false}
      dialogClassName="copy-to-all-students-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Apply to all students?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col>You’ll overwrite the skills progression of all students</Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <button className="btn btn-primary" onClick={onConfirm}>
              Confirm
            </button>
          </Col>
          <Col className="d-flex">
            <button className="btn btn-link" onClick={onCancel}>
              Cancel
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

const CopySkillsToAllStudents = ({ programProgress }) => {
  const { getHookState } = useContext(QueryContext)
  const { groupSessionId } = useContext(DataContext)

  const [{ loading, open }, dispatch] = useReducer((state, newState) => ({ ...state, ...newState }), {
    open: false,
    loading: false,
  })

  const { request } = getHookState(updateProgramProgressForAllStudents)

  const confirm = () => {
    dispatch({ loading: true, open: false })
    const {
      kind,
      program: { id: program_id },
      skills,
    } = programProgress
    const new_sub_skills = skills.reduce((list, { subskills }) => {
      const subskillsList = subskills.map(({ id: subskill_id, status }) => ({ subskill_id, status }))
      list.push(...subskillsList)
      return list
    }, [])
    const program_progress = { program_id, kind, new_sub_skills }
    request({
      data: { program_progress },
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
      onSuccess: () => dispatch({ loading: false }),
    })
  }

  return (
    <FormRow label=" " {...formRowProps}>
      <hr className="mt-0 mb-4 soft-color" />
      <div className="d-flex align-items-center">
        <button
          onClick={() => dispatch({ open: true })}
          disabled={loading}
          className="btn btn-link d-flex align-items-center mr-4"
        >
          <span className="mr-2">
            <Copy className="icon-16" />
          </span>
          <span>Copy these skills to all students</span>
        </button>
        <div className={`spinner-border text-primary ${!loading ? "invisible" : ""}`} />
      </div>
      <hr className="mt-4 mb-4 soft-color" />
      <CopySkillsToAllStudentsModal show={open} onCancel={() => dispatch({ open: false })} onConfirm={confirm} />
    </FormRow>
  )
}

const UpdateCustomProgramText = ({ onClear, children }) => {
  const { groupSessionId, statusInfo } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(updateCustomProgram)

  const onEdit = async group_session => {
    await request({
      data: {
        group_session,
      },
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
    })
    onClear()
  }

  const props = {
    loading,
    onEdit,
    readonly: statusInfo.readonly,
  }

  return <PropsInjector props={props}>{children}</PropsInjector>
}

const OtherProgramTitle = ({ readonly, onEdit, loading }) => {
  const onClick = () => onEdit({ custom_program: "" })

  return (
    <div className="d-flex">
      <h4 className="school-report_program-title mb-2">Custom program</h4>
      {!readonly && (
        <button onClick={onClick} disabled={loading} className="school-report_program-action -delete ml-2">
          <Delete />
        </button>
      )}
    </div>
  )
}

const OverallProgressModalContent = ({ studentId, programProgressId }) => {
  const { getHookState } = useContext(QueryContext)
  const { loading, request, failed } = getHookState(getStudentProgramProgress)
  const [progress, setProgress] = useState(null)

  useEffect(() => {
    if (progress || loading || failed) return
    request({
      entitiesIds: {
        [Entities.GroupSessionStudents]: studentId,
        [Entities.ProgramProgresses]: programProgressId,
      },
      onSuccess: data => setProgress(data),
    })
  })

  if (loading || failed) {
    return (
      <Col>
        <div className="min-h-200-px">
          <div className="spinner-wrapper">
            <div className="spinner-border text-primary" />
          </div>
        </div>
      </Col>
    )
  }

  return <Col>{progress && <ProgramProgressBars progress={progress} />}</Col>
}

const OverallProgressModal = ({ show, onHide, studentName, studentId, programProgressId }) => {
  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop="static" keyboard={false} dialogClassName="skill-list">
      <Modal.Header className="px-4 py-3" closeButton>
        <Modal.Title>{studentName} — Overall progress</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-4">
        <OverallProgressModalContent studentId={studentId} programProgressId={programProgressId} />
      </Modal.Body>
    </Modal>
  )
}

const OverallProgress = ({ studentName, studentId, programProgressId }) => {
  const [open, setOpen] = useState(false)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)
  return (
    <FormRow label=" " {...formRowProps}>
      <button onClick={openModal} className="btn btn-link d-flex align-items-center">
        <span>Overall progress</span>
      </button>
      <hr className="mt-4 mb-4 soft-color" />
      <OverallProgressModal
        show={open}
        onHide={closeModal}
        studentName={studentName}
        studentId={studentId}
        programProgressId={programProgressId}
      />
    </FormRow>
  )
}

const StudentProgress = ({
  showCopyToRestStudents,
  readonly,
  studentId,
  defaultProgramId,
  studentName,
  programProgresses,
  customProgram,
}) => {
  const inputRef = useRef(null)
  const [editableProgram, setEditableProgram] = useState(null)
  const hideModal = () => setEditableProgram(null)
  const [customProgramEditing, setCustomProgramEditing] = useState(false)
  const { groupInfo, tutorProgramsInfo } = useContext(DataContext)

  const onProgramAdd = program => {
    if (program.title.toLowerCase() === "other") {
      setCustomProgramEditing(true)
      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus()
      }, 4)
    } else setEditableProgram({ programId: parseInt(program.value, 10), programProgressId: "new" })
  }

  const onProgramEdit = ({ programId, programProgressId }) => {
    setEditableProgram({ programId, programProgressId })
  }

  return (
    <>
      {!readonly && <div className="school-report_subject-tip mb-4">Choose subject AND add skills below</div>}
      {!tutorProgramsInfo ||
        (!readonly && (
          <RowWarning
            className="px-4 mx-n4 mb-4"
            name={[ValidationNames.GroupProgressInfo, studentId, ValidationNames.StudentProgramProgresses]}
          >
            <FormRow label="Subject" {...formRowProps}>
              <ProgramSelector
                defaultProgram={groupInfo.program}
                availablePrograms={tutorProgramsInfo}
                studentPrograms={programProgresses}
                hasCustomProgram={Boolean(customProgram)}
                onAdd={onProgramAdd}
              />
            </FormRow>
          </RowWarning>
        ))}
      {!tutorProgramsInfo ||
        (!readonly && (
          <FormRow
            label=" "
            {...{ ...formRowProps, LabelProps: { ...formRowProps.LabelProps, className: "p-0 v-2" } }}
            className="mt-0 mb-4"
          >
            <hr className="my-0 soft-color" />
          </FormRow>
        ))}
      {(customProgram || customProgramEditing) && (
        <div className="mb-4">
          <UpdateCustomProgramText onClear={() => setCustomProgramEditing(false)}>
            <OtherProgramTitle />
            <PartialEntityTextUpdate
              label="Add custom program information"
              className="mb-3"
              readonlyPlaceholder="—"
              name="custom_program"
              onCancel={() => setCustomProgramEditing(false)}
              initialEditState={customProgramEditing}
              ref={inputRef}
              value={customProgram}
            />
          </UpdateCustomProgramText>
        </div>
      )}
      <ProgramProgressList
        studentId={studentId}
        defaultProgramId={defaultProgramId}
        studentName={studentName}
        readonly={readonly}
        studentPrograms={programProgresses}
        onEdit={onProgramEdit}
        showCopyToRestStudents={showCopyToRestStudents}
      />
      {!readonly && (
        <SkillsModal
          show={editableProgram !== null}
          showCopyToRestStudents={showCopyToRestStudents}
          studentId={studentId}
          editableProgramId={editableProgram?.programId || null}
          programProgressId={editableProgram?.programProgressId || null}
          onHide={hideModal}
        />
      )}
    </>
  )
}

const UpdateReport = ({ children, config }) => {
  const { groupSessionId, statusInfo } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { loading, request } = getHookState(config)

  const onEdit = async report =>
    await request({
      data: { report },
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
    })

  const props = {
    loading,
    onEdit,
    readonly: statusInfo.readonly,
  }

  return <PropsInjector props={props}>{children}</PropsInjector>
}

const UpdateStrengthsAndChallenges = ({ studentId, children }) => {
  const { statusInfo } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)

  const { loading, request } = getHookState(updateGroupSessionStudentStrengths)

  const onEdit = async group_session_student =>
    await request({
      data: {
        group_session_student,
      },
      entitiesIds: {
        [Entities.GroupSessionStudents]: studentId,
      },
    })

  const props = {
    loading,
    onEdit,
    readonly: statusInfo.readonly,
  }

  return <PropsInjector props={props}>{children}</PropsInjector>
}

const WeekAttendanceSwitch = ({ value, loading, onChange, disabled }) => {
  return (
    <div className="d-flex align-items-center">
      <Switch
        name="attended"
        label="Attended the week"
        wrapperClassName="flex-grow-1"
        checked={value}
        disabled={loading || disabled}
        onChange={onChange}
      />
    </div>
  )
}

const AttendanceTypeSwitch = ({ loading, weeklyAttendance, onClick }) => {
  const change = () => {
    onClick(!weeklyAttendance)
  }

  let text = "Switch to attendance by week"
  if (weeklyAttendance) text = "Switch to attendance by day"

  return (
    <button className="btn btn-link" onClick={change} disabled={loading}>
      {text}
    </button>
  )
}

const Attendance = props => {
  const { statusInfo } = useContext(DataContext)
  const disabledByStatus = statusInfo.status === "planned"

  const { studentId, readonly, weeklyAttendance, weeklyLateCancellation, lateCancellationSchedule, psAbsentInfo } =
    props
  const { isMobileViewport } = useViewport()

  const { getHookState } = useContext(QueryContext)

  const { loading: updateStudentLoading, request: updateStudentRequest } = getHookState(
    updateGroupSessionStudentAttendance
  )

  const updateStudent = async (group_session_student, id) =>
    await updateStudentRequest({
      data: {
        group_session_student,
      },
      entitiesIds: {
        [Entities.GroupSessionStudents]: id,
      },
    })

  const { request: markAbsentRequest, loading: markAbsentLoading } = getHookState(markAbsentStudent)

  const markAbsent = async () =>
    await markAbsentRequest({
      entitiesIds: {
        [Entities.GroupSessionStudents]: studentId,
      },
    })

  const updateWeeklyAttendance = newValue => {
    const data = { weekly_attendance: newValue }
    updateStudent(data, studentId)
  }

  const updateWeeklyLateCancelation = newValue => {
    const data = { weekly_late_cancellation: newValue }
    updateStudent(data, studentId)
  }

  const updateLateCancelationSchedule = (newValue, updatedStudentId) => {
    const data = { late_cancellation: newValue }
    updateStudent(data, updatedStudentId)
  }

  const buildDate = useCallback(date => moment(date).format("ddd D"), [])

  const studentAbsences = lateCancellationSchedule.filter(obj => {
    return obj.late_cancellation
  })

  return (
    <>
      {readonly &&
        (weeklyAttendance ? (
          <FormRow label="Attendance" {...formRowProps} className="my-0 px-4 mx-n4">
            <div className="my-2 font-weight-medium">
              {weeklyLateCancellation ? "Student missed all sessions" : "Student attended for the week"}
            </div>
          </FormRow>
        ) : studentAbsences.length > 0 ? (
          <FormRow label="Attendance" {...formRowProps} className="my-0 px-4 mx-n4">
            {studentAbsences.length > 0 ? (
              <div className="my-2 font-weight-medium">
                Student missed day{studentAbsences.length > 1 ? "s" : ""}:{" "}
                {studentAbsences.map(({ date }) => buildDate(date)).join(", ")}
              </div>
            ) : (
              <div className="my-2 font-weight-medium">Student attended for a week</div>
            )}
          </FormRow>
        ) : null)}
      {!readonly && (
        <>
          <FormRow label="Attendance" {...formRowProps} className="my-0 px-4 mx-n4">
            <Row className="align-items-center">
              <Col className="col-auto">
                <WeekAttendanceSwitch
                  disabled={!weeklyAttendance || updateStudentLoading || disabledByStatus || markAbsentLoading}
                  value={!weeklyLateCancellation}
                  onChange={({ target: { checked } }) => updateWeeklyLateCancelation(!checked)}
                  loading={updateStudentLoading || markAbsentLoading}
                />
              </Col>
              <Col className="col-auto">
                <Row className="align-items-center">
                  {!weeklyLateCancellation && (
                    <>
                      <Col className="col-auto py-1 px-0">
                        <hr className="horizontal thin" />
                      </Col>
                      <Col className="d-flex">
                        <AttendanceTypeSwitch
                          weeklyAttendance={weeklyAttendance}
                          onClick={updateWeeklyAttendance}
                          loading={updateStudentLoading || markAbsentLoading}
                        />
                      </Col>
                    </>
                  )}
                  {(updateStudentLoading || markAbsentLoading) && (
                    <Col>
                      <div className="spinner-border text-primary ml-2" />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            {!weeklyAttendance && (
              <Row className={`mt-4 mb-2 ${isMobileViewport ? "flex-column" : ""}`}>
                <Col className={`col-auto ${isMobileViewport ? "mr-2" : ""}`}>
                  <Row>
                    {lateCancellationSchedule.map(day => (
                      <Col xs="auto" key={day.group_session_student_id}>
                        <Form.Check
                          custom
                          id={`group_session_student_id_${day.group_session_student_id}`}
                          onChange={({ target: { checked } }) =>
                            updateLateCancelationSchedule(!checked, day.group_session_student_id)
                          }
                          checked={!day.late_cancellation}
                          className="v-2 text-nowrap text-uppercase"
                          label={buildDate(day.date)}
                          disabled={updateStudentLoading || disabledByStatus || markAbsentLoading}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )}
            {psAbsentInfo.length > 0 && (
              <Row className="mt-4">
                <Col className="col-auto">
                  <Row className="school-session-report-attendance-warning align-items-baseline">
                    <Col>
                      <span className="font-weight-semibold mr-4">
                        Pencil Spaces missed classes:{" "}
                        {psAbsentInfo
                          .map(({ date }) => date)
                          .map(buildDate)
                          .join(", ")}
                      </span>
                    </Col>
                    <Col className="col-auto">
                      <button className="btn btn-link fullwidth" onClick={markAbsent}>
                        Apply
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </FormRow>
        </>
      )}
    </>
  )
}

const StudentReportTabContent = ({
  readonly,
  studentId,
  defaultProgramId,
  studentName,
  weeklyAttendance,
  weeklyLateCancellation,
  lateCancellationSchedule,
  programProgresses,
  customProgram,
  strengthsChallenges,
  psAbsentInfo,
}) => {
  const { groupProgressInfo } = useContext(DataContext)

  return (
    <>
      {groupProgressInfo.length > 1 && (
        <Attendance
          studentId={studentId}
          readonly={readonly}
          weeklyAttendance={weeklyAttendance}
          weeklyLateCancellation={weeklyLateCancellation}
          lateCancellationSchedule={lateCancellationSchedule}
          psAbsentInfo={psAbsentInfo}
        />
      )}
      {(weeklyAttendance && !weeklyLateCancellation) ||
      (!weeklyAttendance && lateCancellationSchedule.some(({ late_cancellation }) => !late_cancellation)) ? (
        <>
          {groupProgressInfo.length > 1 && <hr className="mt-3 mb-4 mx-n4 soft-color" />}
          <StudentProgress
            readonly={readonly}
            studentName={studentName}
            studentId={studentId}
            defaultProgramId={defaultProgramId}
            programProgresses={programProgresses}
            customProgram={customProgram}
            showCopyToRestStudents={groupProgressInfo.length > 1}
          />
          <RowWarning
            className="px-4 mx-n4"
            name={[ValidationNames.GroupProgressInfo, studentId, ValidationNames.StudentStrengthsChallenges]}
          >
            <FormRow label="Strengths/Challenges" {...formRowProps}>
              <UpdateStrengthsAndChallenges studentId={studentId}>
                <PartialEntityTextUpdate
                  label="Add strengths and challenges"
                  name="strengths_challenges"
                  readonlyPlaceholder="—"
                  value={strengthsChallenges}
                />
              </UpdateStrengthsAndChallenges>
            </FormRow>
          </RowWarning>
        </>
      ) : null}
    </>
  )
}

const DocumentUploadTab = ({ reportInfo, readonly }) => {
  const { current: apiBase } = useContext(ApiContext)
  const { groupSessionId, directUploadUrl } = useContext(DataContext)
  const { updateState } = useContext(DataContextActions)

  const url = useMemo(
    () =>
      urlBuilder({
        ...changeSessionDocuments,
        apiBase,
        entitiesIds: {
          [Entities.GroupSessions]: groupSessionId,
        },
      }),
    [apiBase, groupSessionId]
  )

  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(getReport)

  const onChange = async () =>
    await request({
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
    })

  return (
    <UploadFileField
      submitUrl={url}
      removeUrl={url}
      directUploadUrl={directUploadUrl}
      files={reportInfo.documents}
      setFiles={getNewFiles => {
        const documents = getNewFiles(reportInfo.documents)
        updateState(getReport, { ...reportInfo, documents })
      }}
      uploadEnabled={!readonly || loading}
      onChange={onChange}
    />
  )
}

const StudentReportContent = ({ student, readonly }) => {
  return (
    <StudentReportTabContent
      readonly={readonly}
      studentId={student.id}
      defaultProgramId={student.default_program_id}
      studentName={student.name}
      status={student.status}
      weeklyAttendance={student.weekly_attendance}
      weeklyLateCancellation={student.weekly_late_cancellation}
      lateCancellationSchedule={student.late_cancellation_schedule}
      programProgresses={student.program_progresses}
      customProgram={student.custom_program}
      strengthsChallenges={student.strengths_challenges}
      psAbsentInfo={student.absent_ps}
    />
  )
}

const ReportContent = () => {
  const { role, reportInfo, groupInfo, statusInfo, weekInfo, groupProgressInfo, tutorProgramsInfo } =
    useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request, failed, loading } = getHookState(role === Roles.Tutor ? getTutorHimselfPrograms : getTutorPrograms)

  useEffect(() => {
    if (groupInfo === void 0 || loading || failed) return
    if (tutorProgramsInfo === void 0 && (role === Roles.Admin || role === Roles.Tutor)) {
      request({
        entitiesIds: { [Entities.Tutors]: role === Roles.Tutor ? null : groupInfo.tutor.id },
        params: { add_custom: true },
      })
    }
  }, [failed, groupInfo, loading, request, role, tutorProgramsInfo])

  const [reportTabTitle, documentsTabTitle] = baseTabs
  const dynamicDocumentsTabTitle = useMemo(
    () => `${documentsTabTitle}${reportInfo.documents.length > 0 ? ` (${reportInfo.documents.length})` : ""}`,
    [documentsTabTitle, reportInfo.documents.length]
  )

  if (loading)
    return (
      <div className="min-h-180-px d-flex flex-column align-items-center justify-content-center">
        <div className="spinner-border text-primary" />
      </div>
    )

  return (
    <>
      <h4 className="session-report_title mb-4">Agenda</h4>
      <RowWarning className="px-4 mx-n4 mb-4" name={ValidationNames.Agenda}>
        <FormRow label="Weekly agenda" {...formRowProps}>
          <UpdateReport config={updateReportAgenda}>
            <PartialEntityTextUpdate
              label="Add agenda"
              name="agenda"
              readonlyPlaceholder="—"
              value={reportInfo.agenda}
            />
          </UpdateReport>
        </FormRow>
      </RowWarning>
      <RowWarning className="px-4 mx-n4" name={ValidationNames.NextSteps}>
        <FormRow label="Next week steps" {...formRowProps}>
          <UpdateReport config={updateReportNextSteps}>
            <PartialEntityTextUpdate
              label="Add next steps"
              name="next_steps"
              readonlyPlaceholder="—"
              value={reportInfo.next_steps}
            />
          </UpdateReport>
        </FormRow>
      </RowWarning>
      <hr className="mx-n4 bold soft-color" />
      <Tab.Container defaultActiveKey={reportTabTitle}>
        <Nav className="d-flex align-items-center mb-4">
          <Nav.Item>
            <Nav.Link eventKey={reportTabTitle} as="button" role="tab" className="brain-trust-flat-tab">
              {reportTabTitle}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="ml-5">
            <Nav.Link eventKey={documentsTabTitle} as="button" role="tab" className="brain-trust-flat-tab">
              {dynamicDocumentsTabTitle}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane key={reportTabTitle} eventKey={reportTabTitle} unmountOnExit>
            {daysInStatus(cancelledStatuses, weekInfo.days) ? (
              <p>No student progress because all sessions are canceled</p>
            ) : (
              <>
                <Tab.Container defaultActiveKey={groupProgressInfo.length ? groupProgressInfo[0].id : null}>
                  <RowWarning name={ValidationNames.GroupProgressInfoInvalid} className="mx-n4 px-4 mb-4">
                    <div className="brain-trust-tabs-wrapper mx-n4 mb-4">
                      <Nav variant="tabs" className="brain-trust-tabs px-4" as="nav">
                        {groupProgressInfo.map(student => (
                          <SessionTab
                            key={student.id}
                            studentId={student.id}
                            name={student.name}
                            status={student.status}
                          />
                        ))}
                      </Nav>
                    </div>
                  </RowWarning>
                  <Tab.Content>
                    {groupProgressInfo.map(student => (
                      <Tab.Pane key={student.id} eventKey={student.id} unmountOnExit>
                        <StudentReportContent student={student} readonly={statusInfo.readonly} />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
                {!statusInfo.readonly && (
                  <>
                    <hr className="mx-n4 bold soft-color" />
                    <Footer />
                  </>
                )}
              </>
            )}
          </Tab.Pane>
          <Tab.Pane key={documentsTabTitle} eventKey={documentsTabTitle} unmountOnExit>
            <DocumentUploadTab reportInfo={reportInfo} readonly={statusInfo.readonly} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  )
}

const NoReportData = () => {
  return (
    <div className="session-report_no-data mt-3">
      <span className="session-report_no-data-icon">
        <EmptyBox />
      </span>
      <span className="session-report_no-data-title mt-3">Report has not been sent yet</span>
      <span className="session-report_no-data-subtitle">Check the page on Sunday</span>
    </div>
  )
}

const Report = () => {
  const { statusInfo } = useContext(DataContext)
  const { canShowReport } = useContext(ValidationContext)

  if (statusInfo === void 0) return null
  const showReport = canShowReport()
  return (
    <div className="card p-4 mb-5">
      <Header hideSubmit={statusInfo.readonly} />
      <hr className="my-4 soft-color" />
      {showReport ? <ReportContent /> : <NoReportData />}
    </div>
  )
}

export default Report
