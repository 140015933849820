import React, { useContext, useMemo, useCallback } from "react"
import { ValidationContext } from "../../../contexts"

const ProfileValidationProvider = ({ errors, children }) => {
  const { propertyGetter } = useContext(ValidationContext)

  const personalInfoValidationGetter = useMemo(() => propertyGetter(errors), [errors, propertyGetter])

  const getValidationValueByName = useCallback(personalInfoValidationGetter, [personalInfoValidationGetter])

  const value = {
    propertyGetter,
    getValidationValueByName,
  }

  return <ValidationContext.Provider value={value}>{children}</ValidationContext.Provider>
}

export default ProfileValidationProvider
