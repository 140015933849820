import React from "react"
import Chip from "../../Chip"
import { intersection } from "lodash"

const ProgramsTree = ({ admin, selectedIds, hierarchy, programsHash, onDelete }) => {
  let divisions = []

  hierarchy.forEach((division, index) => {
    let domains = []
    division.domains.forEach((domain, idx) => {
      const test = intersection(domain.programs, selectedIds)

      var programs = []

      if (test.length > 0) {
        test.forEach(id => {
          const index = selectedIds.findIndex(el => el === id)
          programs.push(
            <Chip key={id} onClose={() => onDelete(index)}>
              {programsHash[id].name}
            </Chip>
          )
        })

        domains.push(
          <div className="row" key={domain.domain}>
            <div className="col-lg-6">{domain.domain}</div>
            <div className={(index !== 0 && idx > 1) || admin ? "col-lg-18" : "col-lg-9"}>{programs}</div>
          </div>
        )
      }
    })

    if (domains.length > 0) {
      divisions.push(
        <div className="row mt-4" key={division.division}>
          <h5 className="col-lg-24">{division.division}</h5>
          <div className="col-lg-24">{domains}</div>
        </div>
      )
    }
  })

  return divisions.length ? <div className="mt-4">{divisions}</div> : null
}

export default ProgramsTree
