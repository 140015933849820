import { Entities } from "@/constants"
import { DataContext, QueryContext } from "@/contexts"
import React, { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { OpeningPageWrapper } from "../../components"
import { getOpening, updateOpening } from "../../configurations/domain"
import { OpeningForm } from "../../forms/opening_form"
import { mapFormDataToBackend } from "../../forms/opening_form/helpers"
import { OpeningProvider } from "../../providers/opening_page"

const EditOpeningPageContent = () => {
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(updateOpening)
  const { opening } = useContext(DataContext)
  const navigate = useNavigate()

  const onSubmit = data => {
    request({
      entitiesIds: {
        [Entities.Openings]: opening.id,
      },
      data: { opening: mapFormDataToBackend(data) },
      onSuccess: () => navigate(`/${opening.id}`),
    })
  }

  return (
    <OpeningPageWrapper title="Edit opening">
      <OpeningForm opening={opening} onSubmit={onSubmit} loading={loading} />
    </OpeningPageWrapper>
  )
}

export const EditOpeningPage = () => {
  const { openingId } = useParams()

  return (
    <OpeningProvider initial={{ openingId }} config={[getOpening, updateOpening]}>
      <EditOpeningPageContent />
    </OpeningProvider>
  )
}
