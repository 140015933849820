import React, { useState, useEffect, useRef } from "react"
import { ProgressBar } from "react-bootstrap"

const FREQUENCY = 100

const IndeterminateProgressBar = ({ className, duration = 1000 }) => {
  const [progress, setProgress] = useState(0)
  const [intervalId, setIntervalId] = useState(0)

  const [time, setTime] = useState(0)
  const savedCallback = useRef()

  const callback = () => {
    const timePassed = time + 500 / duration
    setTime(timePassed)

    const currentProgress = (1 - Math.exp(-1 * timePassed)) * 100
    setProgress(currentProgress)

    if (currentProgress > 99) {
      setProgress(100)
      clearInterval(intervalId)
    }
  }

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    const tick = () => savedCallback.current()

    const id = setInterval(tick, FREQUENCY)
    setIntervalId(id)

    return () => clearInterval(id)
  }, [])

  return <ProgressBar animated className={className} now={progress} style={{ height: "4px" }} />
}

export default IndeterminateProgressBar
