import React, { useMemo, useState } from "react"
import { useFormState } from "react-final-form"
import { filter } from "lodash/fp"
import schema from "../questionnaire/schema"
import WizardCard from "./WizardCard"

import WizardLayout from "./WizardLayout"
import WizardEmailCard from "./WizardEmailCard"

const steps = filter("WizardComponent")(schema)

const QuestionnaireWizard = ({ options, onSubmit }) => {
  const { values } = useFormState()
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const { WizardComponent, WizardComponentProps: stepProps, completed } = steps[currentStepIndex] || {}

  const stepText = useMemo(() => `Question ${currentStepIndex + 1}/${steps.length}`, [currentStepIndex])
  const buttonText = useMemo(() => {
    if (stepProps && stepProps.nextButtonText && completed(values)) {
      return stepProps.nextButtonText
    }
    if (stepProps && stepProps.skipButtonText && !completed(values)) {
      return stepProps.skipButtonText
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepProps, values])

  const onStepChange = () => {
    if (currentStepIndex + 1 <= steps.length) {
      setCurrentStepIndex(currentStepIndex + 1)
    } else {
      if (window && window.mixpanel) {
        window.mixpanel.track("wizard_complete")
      }
      onSubmit()
    }
  }

  return (
    <WizardLayout>
      {currentStepIndex + 1 <= steps.length ? (
        <WizardCard subtitle={stepText} title={stepProps.title} buttonText={buttonText} onButtonClick={onStepChange}>
          <WizardComponent {...stepProps} options={options} onSubmit={onStepChange} />
        </WizardCard>
      ) : (
        <WizardEmailCard userEmail={window?.localStorage.getItem("registration_email") || ""} onSubmit={onStepChange} />
      )}
    </WizardLayout>
  )
}

export default QuestionnaireWizard
