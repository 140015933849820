import React from "react"
import { Button } from "react-bootstrap"
import pluralize from "pluralize"
import moment from "moment"

import Rating from "../blocks/Rating"
import { getFullName, getPrivateFullName } from "../../helpers/presentational"

const ReviewListItem = ({ user, allowFullName, review, className, isAdmin, onEdit, onDelete }) => {
  return (
    <div className={className}>
      {user?.id === review.parent.id && <div className="text-success mb-2">Your review</div>}
      <div className="d-flex align-items-center mb-2">
        <div className="font-size-big font-weight-medium">
          {allowFullName // || user?.id === review.parent.id
            ? getFullName(review.parent)
            : getPrivateFullName(review.parent)}
        </div>
        {review.created_at && <div className="text-muted ml-3">{moment(review.created_at).format("MMMM, D")}</div>}
      </div>
      <div className="d-flex align-items-md-center flex-column flex-md-row">
        <Rating value={review.rating} readonly />
        {review.sessions_completed && (
          <div className="text-muted ml-0 ml-md-3">
            {pluralize("session", review.sessions_completed, true)} completed
          </div>
        )}
      </div>
      {review.comment && <div className="my-3">{review.comment}</div>}
      {!review.comment && <div className="my-3 text-muted">No additional text</div>}
      {user?.id === review.parent.id && (
        <Button variant="outline-primary" size="sm" onClick={onEdit}>
          Edit
        </Button>
      )}
      {isAdmin && (
        <Button variant="outline-error" size="sm" onClick={onDelete}>
          Delete review
        </Button>
      )}
    </div>
  )
}

export default ReviewListItem
