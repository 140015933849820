import React, { useContext } from "react"
import { OpeningContext } from "./context"
import { Overview } from "../../common"

const Step4Overview = () => {
  const { opening, setOpening } = useContext(OpeningContext)

  return <Overview opening={opening} setOpening={setOpening} />
}

export default Step4Overview
