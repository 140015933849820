import axios from "axios"
import { useCallback, useState } from "react"

const useFetchSchoolAdmins = () => {
  const [schoolAdmins, setSchoolAdmins] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchSchoolAdmins = useCallback(async (filter, page, pageSize) => {
    setLoading(true)
    setError(null)
    try {
      const { data } = await axios.get("/api/v2/school_admins", {
        params: {
          ...filter,
          page: page + 1,
          per_page: pageSize,
        },
      })
      setSchoolAdmins(data.school_admins)
      setRowCount(data.total_count)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [])

  return { schoolAdmins, rowCount, fetchSchoolAdmins, error, loading }
}

export default useFetchSchoolAdmins
