import { Methods } from "../constants"

export const keyBuilder = ({ entities, action, method, alias }) =>
  [method, ...entities, action, alias].filter(Boolean).join("_").toUpperCase()

export const normalizeConfiguration = ({ entities = [], action = null, method = Methods.Get, alias = "" }) => ({
  entities,
  action,
  method,
  alias,
})
