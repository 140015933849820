import React from "react"
import { Modal, Button, FormControl } from "react-bootstrap"
import { Form, Field } from "react-final-form"
import { required } from "../../../helpers/validators"

const InterviewDeclineModal = ({ show, onSubmit, onClose }) => {
  return (
    <Modal show={show} size="lg" data-backdrop="static" onHide={onClose} centered>
      <Modal.Header className="px-4 pt-3 pb-2" closeButton>
        <Modal.Title>Decline interview</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form onSubmit={onSubmit}>
          {({ submitting, invalid, handleSubmit }) => (
            <>
              <p className="pb-2">
                Please let the parent know why you need to decline this interview. If it’s an issue with the time the
                parent requested, you can suggest another time by clicking “Check Details” on the prior screen!
              </p>

              <Field name="decline_reason" validate={required}>
                {({ input }) => <FormControl {...input} as="textarea" rows={5} />}
              </Field>

              <div className="mt-5">
                <Button variant="outline-primary " disabled={invalid || submitting} onClick={handleSubmit}>
                  Decline interview
                </Button>
                <Button variant="link" className="link-primary ml-5" onClick={onClose}>
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default InterviewDeclineModal
