import React from "react"
import Chip from "../../../Chip"
import { getLabelByValue } from "../../../../helpers/presentational"
import SingleChoiceDropdown from "../../../blocks/SingleChoiceDropdown"

export const GradeSelect = ({ opening, setOpening, options, disabled = false, completed = false }) => {
  const onChangeGrade = grade => {
    setOpening({
      grade,
      defaultProgramId: null,
      defaultProgramName: "",
    })
  }

  const clearGrade = () => {
    setOpening({
      grade: null,
      defaultProgramId: null,
      defaultProgramName: "",
    })
  }

  return (
    <div className="session-configuration_row">
      <div className="session-configuration_control">
        <SingleChoiceDropdown
          options={options.grades}
          buttonText="Grade"
          disabled={disabled}
          selected={completed || !!opening.grade}
          className="dropdown-toggle-no-icon fullwidth"
          wrapperClassName="anl-grade"
          onChange={onChangeGrade}
        />
      </div>
      <div className="session-configuration_values">
        {opening.grade && (
          <Chip disabled={disabled} onClose={clearGrade}>
            {getLabelByValue(options.grades)(opening.grade)}
          </Chip>
        )}
      </div>
    </div>
  )
}
