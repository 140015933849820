import React from "react"
import { Field, useFormState } from "react-final-form"
import { Button, Form } from "react-bootstrap"

import FormRow from "../../blocks/FormRow"
import SchoolSelect from "../../profile/employment_and_tutoring/SchoolSelect"

const WizardAdditionalStep = ({ options: { schoolUrl }, onSubmit }) => {
  const { values } = useFormState()

  return (
    <>
      <p className="mb-3">Prefer tutors with experience tutoring student from</p>
      <Field name="school">
        {({ input }) => (
          <FormRow label="School name" ColProps={{ lg: 18 }}>
            <SchoolSelect
              {...input}
              source={schoolUrl}
              initValue={input.value}
              renderInputComponent={props => <Form.Control {...props} />}
            />
          </FormRow>
        )}
      </Field>

      <p className="mb-3 mt-4">What school does your child attend?</p>
      <Field name="current_school">
        {({ input }) => (
          <FormRow label="Current school" ColProps={{ lg: 18 }}>
            <SchoolSelect
              {...input}
              source={schoolUrl}
              initValue={input.value}
              renderInputComponent={props => <Form.Control {...props} />}
            />
            <Form.Text>We do not show tutors who work at the same school student is attending</Form.Text>
          </FormRow>
        )}
      </Field>

      <div className="mt-4">
        <Button type="button" variant="outline-primary" onClick={onSubmit}>
          {values.school || values.current_school ? "Continue" : "No preferences"}
        </Button>
      </div>
    </>
  )
}

export default WizardAdditionalStep
