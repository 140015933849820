import { Col, Row } from "react-bootstrap"
import { ReactComponent as MailIcon } from "../../../../../assets/images/mail.svg"
import { ReactComponent as LocationIcon } from "../../../../../assets/images/location.svg"
import React from "react"

export const AdditionalInfo = ({ tutor }) => (
  <Row>
    <Col className="opening-tutor-card_additional-info-column">
      <a className="cursor-pointer opening-tutor-card_additional-info-element" href={`mailto:${tutor.email}`}>
        <MailIcon className="mt-1" /> Send message
      </a>
    </Col>

    <Col className="opening-tutor-card_additional-info-column">
      {tutor.profile.locations.length > 0 ? (
        <div className="opening-tutor-card_additional-info-element">
          <LocationIcon className="mt-1" />
          <div>
            {tutor.profile.locations.map(location => (
              <div key={location.zip}>
                {location.zip} + {location.pick_range} miles
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </Col>
  </Row>
)
