import React, { useContext, useState } from "react"
import { Field } from "react-final-form"
import Dropdown from "../blocks/Dropdown"
import { DataContext, QueryContext } from "../../contexts"
import { getSubjectAutocomplete } from "../../domain"

export const SubjectField = ({ name, multipleValues }) => {
  const { getHookState } = useContext(QueryContext)
  const { loading } = getHookState(getSubjectAutocomplete())
  const { subjects } = useContext(DataContext)
  const [searchString, setSearchString] = useState("")

  const options = searchString
    ? subjects.filter(({ title }) => title.toLowerCase().includes(searchString.toLowerCase()))
    : subjects

  return (
    <Field type="text" name={name || "subject"}>
      {({ input, meta }) => (
        <Dropdown
          onChange={input.onChange}
          selected={input.value}
          placeholder={multipleValues ? "Multiple values" : "Select subject"}
          error={meta.touched ? meta.error : null}
          // TODO change title to name
          buildLabel={option => (option ? option.title ?? option.name : void 0)}
          buildValue={JSON.stringify}
          onSearch={e => setSearchString(e.target.value)}
          onSearchReset={() => setSearchString("")}
          options={options}
          loading={loading}
          dataTest="subject-dropdown"
        />
      )}
    </Field>
  )
}
