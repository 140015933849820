import { useReducer, useCallback, useContext, useMemo, useEffect } from "react"
import { DataContext, DataContextActions } from "../contexts"

const reducer = name => (acc, data) => {
  const result = { ...acc, ...data }

  Object.keys(result[name]).forEach(key => {
    if (result[key] === void 0) delete result[key]
  })

  return name ? { [name]: result } : result
}

export const useMemoryState = (name, initial = {}) => {
  const [state, dispatch] = useReducer(reducer(name), name ? { [name]: initial } : initial)

  const remove = useCallback(
    keys =>
      keys.forEach(key => {
        if (state[key] !== void 0) dispatch({ [key]: void 0 })
      }),
    [state]
  )

  const add = useCallback(
    (data, keys = []) => {
      if (keys.length > 0) remove(keys)
      dispatch({ ...data })
    },
    [remove]
  )

  const reset = useCallback(() => remove(Object.keys(state)), [remove, state])

  return { state, add, remove, reset }
}

export const useDataMemoryState = (name, initial = {}) => {
  const store = useContext(DataContext)
  const { resetFields, setPartialState } = useContext(DataContextActions)

  const state = useMemo(() => (store[name] ? store : { [name]: {} }), [name, store])

  const reset = useCallback(() => resetFields(Object.keys(state)), [resetFields, state])

  const add = useCallback(
    data => {
      setPartialState(name ? { [name]: { ...state[name], ...data } } : data)
    },
    [name, setPartialState, state]
  )

  useEffect(() => {
    const initialKeys = Object.keys(initial)
    const empty = Object.keys(store)
      .filter(key => initialKeys.includes(key))
      .every(key => store[key] === void 0)

    if (empty && initialKeys.length > 0) return setPartialState(name ? { [name]: initial } : initial, true)
  }, [initial, name, setPartialState, store])

  return useMemo(
    () => ({
      state,
      reset,
      add,
      remove: resetFields,
    }),
    [add, reset, resetFields, state]
  )
}
