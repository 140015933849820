import React from "react"
import moment from "moment"
import { Row } from "react-bootstrap"

const WeekDays = ({ week }) => (
  <Row className="flex-nowrap mx-0 justify-content-between">
    {week.map(({ date }, idx) => (
      <div className="session-calendar_week-day" key={idx}>
        {moment(date).format("ddd")}
      </div>
    ))}
  </Row>
)

export default WeekDays
