import React, { useCallback, useContext, useRef, useState } from "react"
import { Field, useForm } from "react-final-form"
import { Form } from "react-bootstrap"
import Dropdown from "../blocks/Dropdown"
import { QueryContext } from "../../contexts"
import { getSchoolsAutocomplete } from "../../domain"

const DEBOUNCE_TIMEOUT = 300

export const SchoolField = ({ name, disabled, showTimezone = true, resettable }) => {
  const timerRef = useRef(null)
  const { mutators } = useForm()
  const [options, setOptions] = useState()
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState({
    ...getSchoolsAutocomplete(),
    skipStorage: true,
  })

  const changeAndReset = useCallback(
    (event, change) => {
      change(event)
      mutators?.resetEducatable?.()
      setOptions(void 0)
    },
    [mutators]
  )

  const onSearch = useCallback(
    ({ target: { value } }) => {
      if (value.length <= 2) return
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        request({
          params: { q: value },
          onSuccess: data => setOptions(data.items),
        })
      }, DEBOUNCE_TIMEOUT)
    },
    [request]
  )

  return (
    <Field type="text" name={name || "school"}>
      {({ input, meta }) => (
        <>
          <Dropdown
            name={input.name}
            placeholder="Select school"
            onChange={e => changeAndReset(e, input.onChange)}
            selected={input.value}
            error={meta.touched ? meta.error : null}
            loading={loading}
            // TODO change title to name
            buildLabel={option => (option ? option.title ?? option.name : void 0)}
            buildValue={JSON.stringify}
            onSearch={onSearch}
            options={options}
            disabled={disabled}
            resettable={resettable}
            dataTest="school-dropdown"
          />

          {showTimezone && input.value && input.value.timezone ? (
            <Form.Text className="mt-2 text-gray-chat field-subtitle">
              School timezone: {input.value.timezone.replace("/", " / ").replace("_", " ")}
            </Form.Text>
          ) : null}
        </>
      )}
    </Field>
  )
}
