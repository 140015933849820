import React, { useCallback, useMemo } from "react"
import { TutorCardConfigsProvider, TutorsDialogDataProvider } from "../../providers/students_page"
import { TutorsContent } from "../tutors"
import { TutorPageActionsProvider } from "../../providers/tutors_page"
import { ScheduleInfo } from "./schedule_info"
import { EducatableTypes } from "../../../schedule_module/constants"

const EducatableLink = ({ educatable }) => {
  const href = useMemo(
    () =>
      educatable.type === EducatableTypes.Group
        ? `/admin/opening_groups/${educatable.real_id}`
        : `/admin/tutors/${educatable.real_id}/profile`,
    [educatable]
  )

  return (
    <a href={href} target="_blank" rel="noreferrer">
      {educatable.name}
    </a>
  )
}

const GroupShortInfo = ({ group }) => {
  const buildStudentsRow = useCallback((student, idx) => {
    const studentRow = [
      student.name,
      student.grade,
      ...(Array.isArray(student.special_treatments) ? student.special_treatments : ["No special needs"]),
    ].filter(Boolean)

    return <span key={idx}>{studentRow.join(", ")}</span>
  }, [])

  const educatableMapping = useCallback(
    educatable => {
      if (educatable.type === EducatableTypes.Group) {
        return educatable.students
          .map(({ first_name, last_name, grade, special_treatments }) => ({
            name: `${last_name} ${first_name}`,
            grade,
            special_treatments,
          }))
          .map(buildStudentsRow)
      } else {
        return buildStudentsRow(
          {
            grade: educatable.grade,
            special_treatments: educatable.special_treatments,
          },
          0
        )
      }
    },
    [buildStudentsRow]
  )

  return (
    <div className="p-4 d-flex gap-2 align-items-start align-self-start opening-students__group-info">
      <div className="d-flex flex-shrink-0 align-items-center gap-05">
        <span>{EducatableTypes.Group ? "Group" : "Student"}:</span>
        <EducatableLink educatable={group.educatable} />
      </div>
      <div className="d-flex flex-column">
        {group.educatable ? educatableMapping(group.educatable) : "No students yet"}
      </div>
      <div className="flex-shrink-0">
        <ScheduleInfo startOn={group.start_on} endOn={group.end_on} note={group.note} href={`/schedules/${group.id}`} />
      </div>
    </div>
  )
}

export const TutorsModalContent = ({ groups, onMatch }) => {
  return (
    <TutorsDialogDataProvider>
      <TutorPageActionsProvider>
        <TutorCardConfigsProvider groups={groups} onMatch={onMatch}>
          <div className="d-flex flex-column gap-2">
            {groups.map((group, idx) => (
              <GroupShortInfo key={idx} group={group} />
            ))}
            <TutorsContent />
          </div>
        </TutorCardConfigsProvider>
      </TutorPageActionsProvider>
    </TutorsDialogDataProvider>
  )
}
