import React, { useMemo, useState } from "react"
import cn from "classnames"
import { remove } from "lodash"
import { Button, Dropdown } from "react-bootstrap"
import Chip from "../../../Chip"
import StruggleWithControl from "../../../find_tutor/StruggleWithControl"
import { getStruggleWithSummary } from "../../../../helpers/presentational"
import { getMatchedQuestions } from "../../../../helpers/programsHierarchy"

export const StrugglesWithSelect = ({ opening, setOpening, options, disabled = false, completed = false }) => {
  const [showPopper, setShowPopper] = useState(false)
  const onToggle = show => {
    setShowPopper(show)
  }

  const matchedQuestions = useMemo(() => {
    return getMatchedQuestions(opening.grade, [opening.defaultProgramId], null, options)
  }, [options, opening.grade, opening.defaultProgramId])

  const onChangeStruggleWith = v => {
    const changedOpening = { ...opening }
    changedOpening.struggleWith = v
    setOpening(changedOpening)
  }

  const clearStruggleWith = swId => {
    const changedOpening = { ...opening }
    remove(changedOpening.struggleWith, id => id === swId)
    setOpening(changedOpening)
  }

  const showValues = !!opening.grade && !!opening.defaultProgramId && opening.struggleWith.length > 0

  return (
    <div className="session-configuration_row">
      <div className="session-configuration_control">
        <Dropdown show={showPopper} onToggle={onToggle} className="anl-struggles">
          <Dropdown.Toggle
            variant="outline-dropdown"
            className={cn(
              "dropdown-toggle-no-icon fullwidth",
              (completed || opening.struggleWith.length > 0) && "selected"
            )}
            disabled={!matchedQuestions.length || disabled}
          >
            Struggles With
          </Dropdown.Toggle>
          <Dropdown.Menu className={`p-4 min-w-md-400-px max-vw-100 overflow-auto`}>
            <StruggleWithControl
              value={opening.struggleWith}
              optionType="button"
              questions={matchedQuestions}
              onChange={onChangeStruggleWith}
            />

            <Button variant="outline-primary" size="sm" className="mt-4" type="button" onClick={() => onToggle(false)}>
              Ok
            </Button>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="session-configuration_values">
        {showValues &&
          opening.struggleWith.map(swId => (
            <Chip
              key={swId}
              disabled={disabled}
              onClose={() => {
                clearStruggleWith(swId)
              }}
            >
              {getStruggleWithSummary([swId], options)}
            </Chip>
          ))}
      </div>
    </div>
  )
}
