import React, { useContext, useCallback, useMemo } from "react"
import { DataContext, ValidationContext } from "../../../contexts"

import {
  ValidationNames,
  finalStatuses,
  cancelledStatuses,
  reportValidationStatuses,
  SessionStatuses,
  ReportStatuses,
} from "../constants"
import { daysInStatus } from "../helpers"
import { Roles } from "../../../constants"

const ReportValidationProvider = ({ children }) => {
  const { role, report, scheduleSessions } = useContext(DataContext)
  const { propertyGetter } = useContext(ValidationContext)

  const getStudentNames = useCallback(
    (validationResult, validationName) =>
      Object.keys(validationResult[ValidationNames.GroupProgressInfo])
        .map(id => [
          report.student_reports.find(report => report.student.id === parseInt(id, 10)),
          validationResult[ValidationNames.GroupProgressInfo][id],
        ])
        .map(
          ([report, studentValidation]) =>
            studentValidation[validationName] && `${report.student.first_name} ${report.student.last_name}`
        )
        .filter(Boolean),
    [report]
  )

  const validationResult = useMemo(() => {
    const result = {
      [ValidationNames.GroupProgressInfoInvalid]: false,
      [ValidationNames.CommonInvalid]: false,
      [ValidationNames.AllSessionFinished]: false,
      [ValidationNames.Agenda]: false,
      [ValidationNames.NextSteps]: false,
      [ValidationNames.StrengthsChallenges]: false,
      [ValidationNames.ProgramProgresses]: false,
      [ValidationNames.EmptySessions]: false,
      [ValidationNames.AttendanceWarnings]: [],
      [ValidationNames.GroupProgressInfo]: {},
    }

    if ([report, scheduleSessions].includes(void 0)) return result
    if (report.readonly) return result
    if (report.status === ReportStatuses.NoSessions || scheduleSessions.length === 0)
      result[ValidationNames.EmptySessions] = "No sessions in the report"
    if (report.status === ReportStatuses.Planned) result[ValidationNames.FutureReport] = "Can't send future report"

    if (!reportValidationStatuses.includes(report.status)) return result

    if (!daysInStatus(finalStatuses, scheduleSessions))
      result[ValidationNames.AllSessionFinished] = "Some sessions are not finished"
    if (!report.agenda) result[ValidationNames.Agenda] = "Agenda is empty"
    if (!report.next_steps) result[ValidationNames.NextSteps] = "Next steps is empty"
    const groupProgressInfoStudentInvalid = {}

    if (!daysInStatus(cancelledStatuses, scheduleSessions)) {
      result[ValidationNames.GroupProgressInfo] = report.student_reports.reduce((acc, student_report) => {
        const { sessions, strengths_challenges, program_progresses, student } = student_report

        const result = {}

        const isAttended = sessions.some(({ attendance }) => attendance)

        if (isAttended) {
          if (!strengths_challenges) result[ValidationNames.StudentStrengthsChallenges] = true
          if (!report.custom_program && program_progresses.length === 0)
            result[ValidationNames.StudentProgramProgresses] = true
        }

        if (Object.keys(result).length > 0) acc[student.id] = result
        return acc
      }, {})
      const emptyStrengthsChallenges = getStudentNames(result, ValidationNames.StudentStrengthsChallenges)

      if (emptyStrengthsChallenges.length)
        result[ValidationNames.StrengthsChallenges] = `Strengths/Challenges of ${emptyStrengthsChallenges.join(
          ", "
        )} is empty`

      const emptyProgramProgress = getStudentNames(result, ValidationNames.StudentProgramProgresses)

      if (emptyProgramProgress.length)
        result[ValidationNames.ProgramProgresses] = `Program progress of ${emptyProgramProgress.join(", ")} is empty`

      Object.keys(result[ValidationNames.GroupProgressInfo]).reduce((acc, id) => {
        acc[id] = Object.keys(result[ValidationNames.GroupProgressInfo][id]).length > 0
        return acc
      }, groupProgressInfoStudentInvalid)

      result[ValidationNames.GroupProgressInfoInvalid] = Object.keys(groupProgressInfoStudentInvalid).reduce(
        (acc, id) => acc || groupProgressInfoStudentInvalid[id],
        false
      )
    }

    result[ValidationNames.CommonInvalid] =
      Boolean(result[ValidationNames.FutureReport]) ||
      Boolean(result[ValidationNames.EmptySessions]) ||
      Boolean(result[ValidationNames.Agenda]) ||
      Boolean(result[ValidationNames.NextSteps]) ||
      Boolean(result[ValidationNames.AllSessionFinished]) ||
      result[ValidationNames.GroupProgressInfoInvalid]

    scheduleSessions
      .filter(({ status }) => status === SessionStatuses.Finished)
      .forEach(session => {
        const isGroupPresent = report.student_reports.some(student_report => {
          const studentReportSession = student_report.sessions.find(
            ({ started_on }) => session.started_on === started_on
          )
          return studentReportSession ? studentReportSession.attendance : student_report.default_attendance
        })
        if (!isGroupPresent) result[ValidationNames.AttendanceWarnings].push(session.started_on)
      })

    return result
  }, [getStudentNames, report, scheduleSessions])

  const canShowReport = useCallback(() => {
    return (
      role === Roles.Tutor ||
      role === Roles.Admin ||
      report.status === "report_sent" ||
      report.status === "report_overdue"
    )
  }, [role, report])

  const getErrorList = useCallback(() => {
    const fields = [
      ValidationNames.FutureReport,
      ValidationNames.EmptySessions,
      ValidationNames.AllSessionFinished,
      ValidationNames.Agenda,
      ValidationNames.NextSteps,
      ValidationNames.StrengthsChallenges,
      ValidationNames.ProgramProgresses,
    ]

    return fields.map(key => validationResult[key]).filter(Boolean)
  }, [validationResult])

  const schoolSessionValidationGetter = useMemo(
    () => propertyGetter(validationResult),
    [propertyGetter, validationResult]
  )

  const getValidationValueByName = useCallback(schoolSessionValidationGetter, [schoolSessionValidationGetter])

  return (
    <ValidationContext.Provider value={{ propertyGetter, getValidationValueByName, getErrorList, canShowReport }}>
      {children}
    </ValidationContext.Provider>
  )
}

export default ReportValidationProvider
