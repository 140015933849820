import React, { useState } from "react"
import { Dropdown, Form, Button } from "react-bootstrap"
import { Field, useField } from "react-final-form"
import cn from "classnames"

import FormRow from "../blocks/FormRow"
import SchoolSelect from "../profile/employment_and_tutoring/SchoolSelect"

const AdditionalDropdown = ({ getButtonText, completed, options: { schoolUrl }, onSubmit }) => {
  const [showPopper, setShowPopper] = useState(false)

  const { meta: schoolMeta } = useField("school")
  const { meta: currentSchoolMeta } = useField("current_school")

  const onToggle = show => {
    if (show) {
      setShowPopper(true)
      return
    }
    setShowPopper(false)
    if (!schoolMeta.pristine || !currentSchoolMeta.pristine) {
      onSubmit()
    }
  }

  return (
    <Dropdown show={showPopper} onToggle={onToggle}>
      <Dropdown.Toggle
        variant="outline-dropdown"
        className={cn("dropdown-toggle-no-icon", "fullwidth", completed && "selected")}
      >
        {getButtonText()}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-4 max-w-md-600-px vw-100">
        <h6 className="mb-4">Prefer tutors with experience tutoring student from</h6>
        <Field name="school">
          {({ input }) => (
            <FormRow label="School name" ColProps={{ lg: 18 }}>
              <SchoolSelect
                {...input}
                source={schoolUrl}
                initValue={input.value}
                renderInputComponent={props => <Form.Control {...props} />}
              />
            </FormRow>
          )}
        </Field>

        <h6 className="mb-3 mt-4">What school does your child attend?</h6>
        <Field name="current_school">
          {({ input }) => (
            <FormRow label="Current school" ColProps={{ lg: 18 }}>
              <SchoolSelect
                {...input}
                source={schoolUrl}
                initValue={input.value}
                renderInputComponent={props => <Form.Control {...props} />}
              />
              <Form.Text>We do not show tutors who work at the same school student is attending</Form.Text>
            </FormRow>
          )}
        </Field>

        <Button variant="outline-primary" size="sm" className="mt-4" type="button" onClick={() => onToggle(false)}>
          Ok
        </Button>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default AdditionalDropdown
