import useViewport from "../../../hooks/useViewport"
import { className } from "../../../helpers/className"
import React from "react"
import { Card, Col, Row } from "react-bootstrap"

export const OpeningPageWrapper = ({ children, title, titleAffix }) => {
  const { isMobileViewport } = useViewport()

  return (
    <div className="container p-0 d-flex flex-column flex-grow-1">
      <Row>
        <Col className="p-0">
          <div className="container users-navbar_container -white justify-content-between">
            <h2 className="users-navbar_title m-0">{title}</h2>
            {titleAffix}
          </div>
          <div className="container mt-4 px-0">
            <Row className="mx-n2 px-2 mb-4">
              <Col as="main" xs={24}>
                <Card className={className(isMobileViewport ? "px-3 py-4" : "px-4 py-5")}>{children}</Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}
