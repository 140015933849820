import React from "react"
import { FieldArray } from "react-final-form-arrays"
import Tutoring from "./Tutoring"

import { ReactComponent as Plus } from "../../../assets/images/plus.svg"

const addHandler = fields => {
  fields.push({ degree: "", college: "" })
}

const TutoredFrom = ({ dataSources: { user, divisions, urls } }) => {
  return (
    <FieldArray name="tutoring_histories" initialValue={user.profile.tutoring_histories}>
      {({ fields }) => (
        <>
          <div className="row mb-4">
            <h4 className="col-sm-18">Tutored students from</h4>
            <p className="col-sm-18">
              Do you have experience working with any particular schools? If so, we can consider that in the matches we
              make for you.
            </p>
          </div>
          <div>
            {fields.map((name, index) => (
              <Tutoring
                key={index}
                name={name}
                index={index}
                fields={fields}
                schoolUrl={urls.schools}
                divisions={divisions}
              />
            ))}
          </div>
          <hr className="divider" />
          <div className="mt-2 mb-5">
            <button
              type="button"
              className="btn btn-outline-primary d-flex align-items-center"
              onClick={() => addHandler(fields)}
            >
              <div className="icon-16-px">
                <Plus />
              </div>
              <span className="ml-3">Add a school</span>
            </button>
          </div>
        </>
      )}
    </FieldArray>
  )
}

export default TutoredFrom
