import React from "react"
import { Field } from "react-final-form"

import SingleChoiceDropdown from "../blocks/SingleChoiceDropdown"

const GradeDropdown = ({ options, className, completed, getButtonText, onSubmit }) => {
  return (
    <Field name="grade">
      {({ input }) => (
        <SingleChoiceDropdown
          {...input}
          options={options.grades}
          buttonText={getButtonText(input.value, options)}
          selected={completed}
          className={className}
          wrapperClassName="anl-grade"
          onChange={value => {
            input.onChange(value)
            onSubmit()
          }}
        />
      )}
    </Field>
  )
}

export default GradeDropdown
