/* eslint-disable no-console */
import { flatten, flow, map, partialRight, filter, keyBy } from "lodash"
import { noEventDayStatus } from "./constants"

import { ReactComponent as InProgress } from "../../assets/images/in-progress.svg"
import { ReactComponent as ReportSubmitted } from "../../assets/images/report-submitted.svg"
import { ReactComponent as ReportSent } from "../../assets/images/report-sent.svg"
import { ReactComponent as ActionNeeded } from "../../assets/images/action-needed.svg"
import { ReactComponent as ReportOverdue } from "../../assets/images/report-overdue.svg"
import { ReactComponent as ReportStateDone } from "../../assets/images/report_state_done.svg"
import { ReactComponent as ReportStateNoReport } from "../../assets/images/report_state_no_report.svg"
import { ReactComponent as ReportStatePending } from "../../assets/images/report_state_pending.svg"

import { EMPTY } from "../../session_report/subskill_statuses"

export const getStatusClass = status => {
  if (status === "planned") return "-planned"
  else if (status === "in_progress") return "-progress"
  else if (status === "report_submitted") return "-submitted"
  else if (status === "action_needed") return "-action"
  else if (status === "report_sent") return "-sent"
  else if (status === "report_overdue") return "-overdue"
  else return ""
}

export const getStatusText = status => {
  if (status === "planned") return "Planned"
  else if (status === "in_progress") return "In progress"
  else if (status === "report_submitted") return "Report submitted"
  else if (status === "action_needed") return "Action needed"
  else if (status === "report_sent") return "Report sent"
  else if (status === "report_overdue") return "Report overdue"
  else return ""
}

export const getStatusIcon = status => {
  if (status === "planned") return null
  else if (status === "in_progress") return InProgress
  else if (status === "report_submitted") return ReportSubmitted
  else if (status === "action_needed") return ActionNeeded
  else if (status === "report_sent") return ReportSent
  else if (status === "report_overdue") return ReportOverdue
  else return null
}

export const getTabIcon = status => {
  if (status === "valid") return ReportStateDone
  else if (status === "invalid") return ReportStatePending
  else if (status === "late") return ReportStateNoReport
  else return null
}

export const getTabIconClassName = status => {
  if (status === "valid") return "-valid"
  else if (status === "invalid") return "-invalid"
  else if (status === "late") return "-late"
  else return ""
}

export const selectProgramSubskills = flow(
  partialRight(map, skill => skill.subskills),
  flatten,
  partialRight(filter, subskill => subskill.mastered_status && !(subskill.mastered_status === EMPTY)),
  partialRight(keyBy, value => value.id)
)

export const isValidUrl = urlString => {
  const pattern = [
    "^(https?:\\/\\/)?", // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|", // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))", // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*", // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?", // validate query string
    "(\\#[-a-z\\d_]*)?$", // validate fragment locator
  ].join("")
  var urlPattern = new RegExp(pattern, "i")
  return Boolean(urlPattern.test(urlString))
}

export const checkUrl = link => {
  const linkUrl = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`

  try {
    if (!isValidUrl(linkUrl)) throw new Error(link)
    const url = new URL(linkUrl)
    return url.href
  } catch (e) {
    return null
  }
}

export const daysInStatus = (statuses, days) =>
  days.reduce((acc, day) => acc && [...statuses, noEventDayStatus].indexOf(day.status) !== -1, true)
