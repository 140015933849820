import React from "react"
import { Col } from "react-bootstrap"
import FormRow from "../../../blocks/FormRow"

const rowProps = { className: "align-items-center" }
const colProps = { className: "mb-0" }

export const AdditionalInformation = ({ opening, options = {} }) => {
  const { hideRate, hidePaidNoShows } = options
  return (
    <Col xl={17} className="pb-2">
      <FormRow RowProps={rowProps} ColProps={colProps} label="School rate">
        {opening.school_rate || "—"}
      </FormRow>
      {opening.max_sessions && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Max amount of sessions">
          {opening.max_sessions}
        </FormRow>
      )}
      {!hidePaidNoShows && opening?.max_paid_noshows >= 0 && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Max paid no-shows">
          {opening.max_paid_noshows}
        </FormRow>
      )}
      {opening?.max_duration && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Max duration, minutes">
          {opening.max_duration}
        </FormRow>
      )}
      <FormRow RowProps={rowProps} ColProps={colProps} label="Assignment lifetime">
        {opening.ttg_start_at || "—"}
        {opening.ttg_end_at ? ` — ${opening.ttg_end_at}` : ""}
      </FormRow>
      {opening.additionalInformation && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Additional information">
          {opening.additionalInformation}
        </FormRow>
      )}
      {opening.additionalEmails && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Reports are sent to">
          {opening.additionalEmails}
        </FormRow>
      )}
      {opening.sessionsNotify && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Notifications information">
          Parents are notified about the upcoming sessions
        </FormRow>
      )}
      {!hideRate && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Tutor rate">
          {opening.tutor_rate}$/{opening.is_per_hour_rate ? "hour" : "session"}
        </FormRow>
      )}
      {opening.schedule_flexible && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Schedule preference">
          {opening.schedule_preference}
        </FormRow>
      )}
    </Col>
  )
}
