import { showErrors } from "@/common/alerter"
import apiClient from "@/common/apiClient"
import { useCallback, useState } from "react"

export const useTutorScheduleRequest = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const request = useCallback(async date => {
    const params = `date=${date}`
    try {
      setLoading(true)
      const { data: result } = await apiClient.get(`/api/tutor/calendar/timetable?${params}`)
      setData(result.events || [])
    } catch (e) {
      showErrors(e)
    } finally {
      setLoading(false)
    }
  }, [])

  return { loading, data, request }
}
