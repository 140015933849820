import FilterListIcon from "@mui/icons-material/FilterList"
import GroupIcon from "@mui/icons-material/Group"
import SearchIcon from "@mui/icons-material/Search"
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { memo, useCallback, useMemo } from "react"

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },

    "&:hover .MuiSvgIcon-root.MuiSvgIcon-colorAction": {
      color: theme.palette.primary.main,
    },

    "& .Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },

    "& .Mui-focused.MuiSvgIcon-root.MuiSvgIcon-colorAction": {
      color: theme.palette.primary.main,
    },
  },
}))

const FilterBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(3),
  boxShadow: theme.shadows[1],
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: theme.shadows[2],
  },
}))

const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  "& .MuiChip-label": {
    fontWeight: 500,
    textTransform: "capitalize",
  },
}))

const FILTER_DISPLAY_NAMES = {
  name: "Name",
  email: "Email",
  groups: "Groups",
}

const FilterInputs = ({ filterOptions, filterModel, handleFilterChange, handleRemoveFilter, handleResetFilters }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const renderTextField = useCallback(
    (label, field, icon) => {
      const filterItem = filterModel.items.find(item => item.field === field)
      return (
        <StyledTextField
          fullWidth
          label={label}
          name={field}
          type="search"
          value={filterItem ? filterItem.value : ""}
          onChange={e => handleFilterChange(field, e.target.value)}
          InputProps={{
            startAdornment: icon,
          }}
          placeholder={label}
          sx={{
            "& .MuiSvgIcon-root.MuiSvgIcon-colorAction": {
              color: theme => (filterItem ? theme.palette.primary.main : theme.palette.text.disabled),
            },
          }}
        />
      )
    },
    [filterModel, handleFilterChange]
  )

  const renderAutocomplete = useCallback(
    (label, field, options, icon) => {
      const filterItem = filterModel.items.find(item => item.field === field)
      let value = null

      const isGroupFilter = field === "groups"

      if (isGroupFilter) {
        value = filterItem ? options.filter(option => filterItem.value.includes(option.id)) : []
      } else {
        value = filterItem ? options.find(option => option.id === filterItem.value) : null
      }

      return (
        <Autocomplete
          multiple={isGroupFilter}
          options={options}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <StyledTextField
              {...params}
              label={label}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {icon}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              placeholder={`Select ${label.toLowerCase()}`}
            />
          )}
          onChange={(event, newValue) => {
            if (field === "groups") {
              handleFilterChange(
                field,
                newValue.map(v => v.id)
              )
            } else {
              handleFilterChange(field, newValue ? newValue.id : null)
            }
          }}
          value={value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{
            "& .MuiSvgIcon-root.MuiSvgIcon-colorAction": {
              color: theme =>
                (isGroupFilter && value.length > 0) || (!isGroupFilter && value)
                  ? theme.palette.primary.main
                  : theme.palette.text.disabled,
            },
          }}
        />
      )
    },
    [filterModel, handleFilterChange]
  )

  return (
    <FilterBox>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" component="h2">
          <FilterListIcon sx={{ mr: 1, verticalAlign: "middle" }} />
          Student Filters
        </Typography>
        <Tooltip title="Reset all filters">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleResetFilters}
            size={isMobile ? "small" : "medium"}
            startIcon={<FilterListIcon />}
          >
            Reset
          </Button>
        </Tooltip>
      </Box>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={4}>
          {renderTextField("Filter by Name", "name", <SearchIcon color="action" />)}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {renderTextField("Filter by Email", "email", <SearchIcon color="action" />)}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {renderAutocomplete("Filter by Group", "groups", filterOptions?.groups || [], <GroupIcon color="action" />)}
        </Grid>
      </Grid>
      <FilterChips filterModel={filterModel} filters={filterOptions} handleRemoveFilter={handleRemoveFilter} />
    </FilterBox>
  )
}

export default memo(FilterInputs)

const FilterChips = ({ filterModel, filters, handleRemoveFilter }) => {
  const renderFilterValue = useMemo(
    () => (field, value) => {
      switch (field) {
        case "groups":
        case "grades":
          return Array.isArray(value)
            ? value.map(id => filters[field]?.find(option => option.id === id)?.name || "Unknown").join(", ")
            : "Unknown"
        default:
          return filters[field] ? filters[field].find(option => option.id === value)?.name || value : value
      }
    },
    [filters]
  )

  if (filterModel.items.length === 0) return null

  return (
    <Box mt={2}>
      <Typography variant="subtitle2" gutterBottom>
        Active Filters:
      </Typography>
      {filterModel.items.map(({ field, value }) => {
        if (value)
          return (
            <FilterChip
              key={field}
              label={`${FILTER_DISPLAY_NAMES[field]}: ${renderFilterValue(field, value)}`}
              onDelete={() => handleRemoveFilter(field)}
              color="primary"
              variant="outlined"
            />
          )
      })}
    </Box>
  )
}
