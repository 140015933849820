import React, { useContext, useMemo } from "react"
import { kebabCase } from "lodash"
import { TutorCardApplicationContext, TutorCardConfigsContext } from "../../../../contexts"
import { className as cn } from "../../../../../../helpers/className"
import { DataContext } from "../../../../../../contexts"

const TutorAction = ({ icon, text, loading, ...rest }) => {
  const classes = cn(
    "btn",
    "btn-outline-primary",
    "opening-tutor-card_action-btn",
    "d-flex",
    "flex-grow-1",
    "flex-shrink-0",
    "align-items-center",
    "justify-content-center",
    "gap-025"
  )

  return (
    <button className={classes} {...rest} data-test={`${kebabCase(text)}`}>
      {loading ? (
        <div className="spinner-border text-primary" />
      ) : (
        <>
          {icon && <span className="icon-24-px">{icon}</span>}
          <span>{text}</span>
        </>
      )}
    </button>
  )
}

export const TutorActionList = () => {
  const application = useContext(TutorCardApplicationContext)
  const configs = useContext(TutorCardConfigsContext)
  const { opening } = useContext(DataContext)
  const disable = useMemo(() => opening.schedules_count === opening.schedules_matched_count, [opening])

  if (!configs?.length) return null

  return (
    <div className="opening-tutor-card_actions">
      {configs.map(({ onClick, disabled, ...rest }, idx) => (
        <TutorAction
          key={`${rest.text}${idx}`}
          {...rest}
          onClick={event => onClick(event, application)}
          disabled={disabled || disable}
          title={disable ? "No groups in opening yet" : undefined}
        />
      ))}
    </div>
  )
}
