import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import Editor, { editorStateToHtml } from "../../Editor"
import { useGerTutorDashboardMessageRequest, usePutTutorDashboardMessageRequest } from "./hooks"
import AdminsMessage from "../../tutor_dashboard/AdminsMessage"

const TutorMessageEditor = ({ initialEditorState }) => {
  const [editorState, setEditorState] = useState(initialEditorState)
  const { loading, request } = usePutTutorDashboardMessageRequest()

  const updateEditorState = state => {
    const content = editorStateToHtml(state)
    setEditorState(content)
  }

  const saveEditorState = () => {
    request(editorState)
  }
  return (
    <Row>
      <Col xs={24} md={16} className="container panel-wrapper">
        <div className="panel">
          <div className="panel_body">
            <Col className="col dashboard_text-editor">
              <Editor initialContentState={initialEditorState} onChange={updateEditorState} />
              <button className="btn btn-outline-primary" disabled={loading} onClick={saveEditorState}>
                Save
              </button>
            </Col>
          </div>
        </div>
      </Col>
      <Col sx={24} lg={7} className="container panel-wrapper">
        {editorState ? <AdminsMessage message={editorState} /> : <div>No message yet</div>}
      </Col>
    </Row>
  )
}

const TutorMessageLoader = () => {
  const { loading, data } = useGerTutorDashboardMessageRequest()
  if (loading) {
    return (
      <Col className="min-h-100-px">
        <div className="spinner-wrapper">
          <div className="spinner-border text-primary" />
        </div>
      </Col>
    )
  }
  return <TutorMessageEditor initialEditorState={data} />
}

export default TutorMessageLoader
