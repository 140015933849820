import React, { useState, useMemo } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { Row, Col, Form, Card } from "react-bootstrap"
import { useForm } from "react-final-form"

import CheckoutForm from "../blocks/CheckoutForm"

const PaymentCard = ({
  parentHasCard,
  cardFormRef,
  cardSubmitButtonRef,
  initialCardholderName,
  ccDetails,
  stripeKey,
  onRequestStart,
  onRequestEnd,
  showDefaultText = true,
}) => {
  const form = useForm()
  const stripePromise = useMemo(() => loadStripe(stripeKey), [stripeKey])
  const [shouldCreateCardToken, setShouldCreateCardToken] = useState(!parentHasCard)

  const onChangePaymentCard = useNewCard => {
    if (useNewCard) {
      setShouldCreateCardToken(true)
      form.mutators.clearFieldData("cc_token")
    } else {
      setShouldCreateCardToken(false)
    }
  }

  const onTokenCreate = token => {
    form.mutators.setFieldData("cc_token", token)
    form.submit()
  }

  return (
    <Row>
      <Col lg={12}>
        {!parentHasCard && showDefaultText && (
          <>
            <h4 className="mb-3 mt-5">Please add credit card info</h4>
            <p>
              To schedule a session please add your payment information, we will authorize payment on your card 1 hour
              prior to the first session. Afterwards you&apos;re billed once you&apos;ve received your session report
            </p>
          </>
        )}
        {parentHasCard && (
          <>
            {showDefaultText && <h4 className="mb-3 mt-5">Payment method</h4>}
            <div className="d-flex mb-3">
              <Form.Check
                id="current"
                value="false"
                checked={!shouldCreateCardToken}
                type="radio"
                className="mr-5"
                onChange={e => onChangePaymentCard(e.target.value === "true")}
                label="Current"
                custom
              />
              <Form.Check
                id="new"
                value="true"
                checked={shouldCreateCardToken}
                type="radio"
                label="Add new"
                onChange={e => onChangePaymentCard(e.target.value === "true")}
                custom
              />
            </div>
            {showDefaultText && (
              <p>
                We will authorize payment on your card 1 hour prior to the first session. Afterwards, you&apos;ll be
                billed after you receive a session report from your tutor.
              </p>
            )}
            {!shouldCreateCardToken && (
              <>
                <Card className="card-outline mb-2" body>
                  {ccDetails}
                </Card>
                {showDefaultText && (
                  <small>You can also update your payment details anytime by going to your profile page.</small>
                )}
              </>
            )}
          </>
        )}
        {shouldCreateCardToken && (
          <Elements stripe={stripePromise}>
            <CheckoutForm
              cardFormRef={cardFormRef}
              cardSubmitButtonRef={cardSubmitButtonRef}
              initialCardholderName={initialCardholderName}
              onTokenCreate={onTokenCreate}
              onRequestStart={onRequestStart}
              onRequestEnd={onRequestEnd}
            />
          </Elements>
        )}
      </Col>
    </Row>
  )
}

export default PaymentCard
