import React, { useMemo, useRef } from "react"
import { className as cn } from "../../../../helpers/className"
import { OpeningStatus } from "../../constants"
import { CardTitle } from "./CardTitle"
import { ProgramName } from "./ProgramName"
import { Rate } from "./Rate"
import { Location } from "./Location"
import { AdditionalInformation } from "./AdditionalInformation"
import { Dates } from "./Dates"
import { Status } from "../Status"
import { ReviewScheduleButton } from "../ReviewScheduleButton"
import { HideOpeningButton } from "../HideOpeningButton"
import { Row } from "react-bootstrap"

export const OpeningCard = ({
  opening,
  sliceAdditionalInformation,
  cardClassName = "",
  short = false,
  onApplyClick,
}) => {
  const cardRef = useRef(null)

  const statusClasses = useMemo(
    () =>
      cn(
        opening.status === OpeningStatus.Approved && "-approved",
        opening.status === OpeningStatus.Applied && "-applied"
      ),
    [opening]
  )

  const cardClasses = useMemo(
    () => cn("card", "card-outline", "tutor-dashboard__opening-card", statusClasses, cardClassName),
    [statusClasses, cardClassName]
  )

  return (
    <div className={cardClasses} ref={cardRef}>
      <div className="card-body tutor-dashboard__opening-card-body">
        <div className="d-flex">
          <div className="flex-grow-1">
            <CardTitle opening={opening} />
            <ProgramName opening={opening} />
          </div>

          <Rate opening={opening} />
        </div>

        <div className="d-flex gap-05">
          <Dates opening={opening} />

          {opening.max_number_of_sessions ? (
            <div className="font-size-medium">({opening.max_number_of_sessions} sessions)</div>
          ) : null}
        </div>

        <Location opening={opening} />

        <AdditionalInformation opening={opening} sliceAdditionalInformation={sliceAdditionalInformation} />

        {!short && (
          <>
            <hr className={cn("my-3", "tutor-dashboard__opening-card-divider", statusClasses)} />
            <Row className="justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <Status opening={opening} openOpening={onApplyClick} />
                {opening.status === OpeningStatus.Approved ? (
                  <ReviewScheduleButton opening={opening} anchorRef={cardRef} />
                ) : null}
              </div>
              {!opening.hidden && <HideOpeningButton openingId={opening.id} />}
            </Row>
          </>
        )}
      </div>
    </div>
  )
}
