import React from "react"
import Report from "./report"
import WeekSchedule from "../schedule/week_schedule"
import InitialLoadingSpinner from "../../blocks/InitialLoadingSpinner"

const ReportPage = () => {
  return (
    <InitialLoadingSpinner>
      <div className="d-flex flex-column">
        <WeekSchedule className="mb-4" />
        <Report />
      </div>
    </InitialLoadingSpinner>
  )
}

export default ReportPage
