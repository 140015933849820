import React from "react"
import cn from "classnames"
import { Row, Col } from "react-bootstrap"
import { map } from "lodash/fp"
import MultipleChoice from "../blocks/MultipleChoice"

const StruggleWithPicker = ({ value, optionType, questions, onChange }) => {
  return (
    <>
      {questions.map(({ domain, main_question, answers }, i) => (
        <Row key={i} className={cn("flex-column", i !== questions.length - 1 && "mb-3")}>
          <Col className="col-md-auto mb-2">
            <span className="font-weight-medium">{domain.replace(/_/g, " ")}:</span> {main_question}
          </Col>
          <Col>
            <MultipleChoice
              value={value}
              optionType={optionType}
              onChange={onChange}
              options={map(({ id, text }) => ({ label: text, value: id }))(answers)}
            />
          </Col>
        </Row>
      ))}
    </>
  )
}

export default StruggleWithPicker
