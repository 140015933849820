import React, { useContext } from "react"
// import {
//   Link as RouterLink,
//   LinkProps as RouterLinkProp,
// } from 'react-router-dom';
// import { LinkProps } from '@mui/material';
import { StyledLink } from "./styledLink"
import { QueryParamsContextActions } from "../../contexts"

export const InnerLink = ({ target, children, ...rest }) => {
  const { resetParams } = useContext(QueryParamsContextActions)
  return (
    <StyledLink target={target} onClick={resetParams} {...rest}>
      {children}
    </StyledLink>
  )
}
