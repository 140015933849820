import React from "react"
import { Field } from "react-final-form"
import { required } from "../../../../helpers/validators"
import FormRow from "../../../blocks/FormRow"
import { showFieldError } from "../../../../helpers/forms"
import { labelSize, colSize } from "../FormFields"
import AsyncAutocompleteField from "../../../AsyncAutocompleteField"

const Tutor = ({ isFormDisabled, urls, tutor, onSelectTutor }) => {
  return (
    <Field name="tutor_id" validate={required}>
      {({ input, meta }) => (
        <FormRow label="Tutor" LabelProps={labelSize} ColProps={colSize} required>
          <AsyncAutocompleteField
            source={urls.tutorSource}
            fieldName="tutor_id"
            validationErrors={showFieldError(meta) ? meta.error || meta.submitError : ""}
            selected={tutor}
            disabled={isFormDisabled}
            onSelect={data => onSelectTutor(data, input.onChange)}
          />
        </FormRow>
      )}
    </Field>
  )
}

export default Tutor
