import React, { useCallback, useMemo } from "react"
import { className } from "../../helpers/className"

const SkillProgressBar = ({ skill }) => {
  const getCountWithStatus = useCallback(
    (...statuses) => {
      const count = skill.subskills.filter(subskill => statuses.indexOf(subskill.status) !== -1).length
      return count ? (count / skill.subskills.length) * 100 : count
    },
    [skill.subskills]
  )

  const masteredEarlierPercent = useMemo(
    () => getCountWithStatus("mastered_earlier", "studied_earlier"),
    [getCountWithStatus]
  )
  const masteredPercent = useMemo(() => getCountWithStatus("mastered"), [getCountWithStatus])
  const workingOnCount = useMemo(() => getCountWithStatus("working_on"), [getCountWithStatus])

  return (
    <li className="progress-skills_item">
      <div className="progress-skills_label">{skill.name}</div>
      <div className="progress-skills_line">
        <div
          className="color -studied-earlier"
          title="Mastered earlier"
          style={{ width: `${masteredEarlierPercent}%` }}
        />
        <div className="color -mastered" title="Mastered this year" style={{ width: `${masteredPercent}%` }} />
        <div className="color -working-on" title="Working" style={{ width: `${workingOnCount}%` }} />
      </div>
    </li>
  )
}

export const ProgramProgressBars = ({ progress }) => {
  const progresses = useMemo(() => (Array.isArray(progress) ? progress : [progress]), [progress])

  return (
    <section className="progress-page">
      <div className="progress-page_content">
        {progresses.length === 0 && <p className="mb-0">No progress yet</p>}
        {progresses.length > 0 &&
          progresses.map((progress, idx) => (
            <div key={idx} className={className(idx !== progresses.length - 1 && "mb-5")}>
              <div className="progress-title">
                <h1 className="progress-title_title">
                  <span>{progress.program.name}</span>
                  {progress.details_url && (
                    <a
                      href={progress.details_url}
                      target="_blank"
                      rel="noreferrer"
                      className="progress-title_hint"
                      title="Click to see whole picture"
                    >
                      i
                    </a>
                  )}
                </h1>
                <div className="progress-title_count">
                  {progress.achieved_subskills_total} of {progress.subskills_total}
                </div>
              </div>
              <ul className="progress-skills">
                {progress.skills.map(skill => (
                  <SkillProgressBar key={skill.id} skill={skill} />
                ))}
              </ul>
            </div>
          ))}
      </div>
      <div className="progress-page_aside">
        <ul className="progress-legend">
          <li className="progress-legend_li">
            <div className="progress-legend_icon color -studied-earlier -shadow" />
            Mastered earlier
          </li>
          <li className="progress-legend_li">
            <div className="progress-legend_icon color -mastered" />
            Mastered this year
          </li>
          <li className="progress-legend_li">
            <div className="progress-legend_icon color -working-on" />
            Working
          </li>
        </ul>
      </div>
    </section>
  )
}

export default ProgramProgressBars
