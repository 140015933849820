import React, { Fragment } from "react"
import { Field } from "react-final-form"
import { Form, Col, Row } from "react-bootstrap"
import { positiveFloatNumber } from "../../../helpers/formatters"
import { chain, required, float } from "../../../helpers/validators"
import { requiredClass } from "../../../helpers/profile_form"
import FormRow from "../../../components/blocks/FormRow"
import useViewport from "../../../hooks/useViewport"
import RowWarning from "../../blocks/RowWarning"
import Switch from "../../blocks/Switch"

const formRowProps = {
  LabelProps: { sm: 24, lg: 8, className: "v-2" },
  ColProps: { sm: 24, lg: 16, className: "mb-0 mt-n1" },
  RowProps: {},
}

const schoolPartnershipTutoringText = () => {
  return (
    <>
      You’ll be able to apply for these positions through our opportunities jobs board on your Dashboard.
      <br />
      The likelihood of high dosage bookings can be significantly higher for school partnerships than for private
      tutoring.
      <br />
      Pay rates, location (in-person or online), and other key details for each opportunity will be clearly displayed.
    </>
  )
}

const FormFields = ({ dataSources: { user }, values }) => {
  const { isMobileViewport } = useViewport()

  return (
    <Fragment>
      <div className="row mb-3">
        <div className="col-lg-17">
          <h4>School Partnership Tutoring</h4>
          {isMobileViewport && <div className="alert alert-info">{schoolPartnershipTutoringText()}</div>}
          <RowWarning className="panel_body-warning" name="sessionTypeMissed">
            <Field name="tutor_school" initialValue={user.profile.tutor_school} type="checkbox">
              {({ input }) => (
                <Switch
                  {...input}
                  label="Are you interested in tutoring students through our school partnership programs?"
                />
              )}
            </Field>
          </RowWarning>
        </div>
        {!isMobileViewport && (
          <>
            <div className="col-lg-1" />
            <div className="col-lg-6 mb-4">
              <div className="alert alert-info my-3">{schoolPartnershipTutoringText()}</div>
            </div>
          </>
        )}
      </div>

      <div className="row">
        <div className="col-lg-17">
          <h4>Private Tutoring</h4>
          {isMobileViewport && (
            <div className="alert alert-info">
              Parents will reach out about your services directly through our marketplace. For these matches, you can
              set your hourly rate and you’ll net 90% of the fee.
            </div>
          )}
          <RowWarning className="panel_body-warning" name="sessionTypeMissed">
            <Field name="tutor_private" initialValue={user.profile.tutor_private} type="checkbox">
              {({ input }) => <Switch {...input} label="Are you interested in working directly with families?" />}
            </Field>
          </RowWarning>

          {values.tutor_private && (
            <Fragment>
              <hr />
              <Field
                name="hourly_rate"
                initialValue={user.hourly_rate}
                validate={chain(required, float)}
                parse={positiveFloatNumber}
                format={value => parseFloat(value || "0", 2)}
                formatOnBlur
              >
                {({ input, meta }) => (
                  <RowWarning className="panel_body-warning" name={input.name}>
                    <FormRow label="Hourly rate" {...formRowProps} required>
                      <Row>
                        <Col lg={8}>
                          <div className="input-group">
                            <Form.Control {...input} className={`v-2 ${requiredClass(meta)}`} />
                            <div className="input-group-append">
                              <div className="input-group-text v-2">$</div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </FormRow>
                  </RowWarning>
                )}
              </Field>

              <Field
                name="cancellation"
                initialValue={user.profile.session_max_cancellation_period}
                validate={required}
                parse={parseInt}
              >
                {({ input, meta }) => (
                  <RowWarning className="panel_body-warning" name={input.name}>
                    <FormRow label="Cancellation policy" {...formRowProps} required>
                      <Row>
                        <Col lg={8}>
                          <Form.Control as="select" {...input} className={`v-2 ${requiredClass(meta)}`}>
                            <option key="empty" value="0">
                              Pick a policy
                            </option>
                            <option key="24" value="24">
                              24 hours notice
                            </option>
                            <option key="12" value="12">
                              12 hours notice
                            </option>
                            <option key="3" value="3">
                              3 hours notice
                            </option>
                          </Form.Control>
                        </Col>
                      </Row>
                    </FormRow>
                  </RowWarning>
                )}
              </Field>

              {!!values.cancellation && (
                <FormRow label=" " {...formRowProps}>
                  <span>
                    Your profile will show that you won&#39;t charge for sessions if cancelled{" "}
                    <b>{values.cancellation} hours or more</b> before scheduled time, though enforcement of this policy
                    will be at your discretion.
                  </span>
                </FormRow>
              )}
            </Fragment>
          )}
        </div>

        {!isMobileViewport && (
          <>
            <div className="col-lg-1" />
            <div className="col-lg-6 mb-4">
              <div className="alert alert-info my-3">
                Parents will reach out about your services directly through our marketplace. For these matches, you can
                set your hourly rate and you’ll net 90% of the fee.
              </div>
            </div>
          </>
        )}
      </div>
    </Fragment>
  )
}

export default FormFields
