import { TutorPositionTypes } from "../../constants"
import { LocationFieldNames } from "../../../fields/location"

export const OpeningFieldsNames = {
  School: "school",
  LearningLevel: "learning_level",
  Subject: "subject",
  TutoringType: "tutoring_type",
  NoteForTutors: "note_for_tutors",
  InternalNote: "internal_note",
  TutorBindingStatement: "tutor_binding_statement",
  PositionLevel: "position_level",
  StartOn: "start_on",
  EndOn: "end_on",
  TutorRate: "tutor_rate",
  SchoolRate: "school_rate",
  PaidNoShows: "paid_no_shows",
  ReportCadence: "report_cadence",
  SessionNotification: "session_notification",
  Duration: "duration",
  MaxSessions: "max_sessions",
  ...LocationFieldNames,
}

export const TutoringTypes = {
  main: { key: TutorPositionTypes.Main, name: "Main tutor" },
  substitute: { key: TutorPositionTypes.Substitute, name: "Substitute" },
}
