class ServerSideRendering {
  static wrapWindow() {
    if (typeof window === "undefined") {
      return {
        document: {
          querySelector: function () {
            return {}
          },
          content: "",
        },
        window: { location: { search: "" }, href: "" },
      }
    } else {
      return { document: document, window: window }
    }
  }
}

export default ServerSideRendering
