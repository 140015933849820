import React, { useContext } from "react"
import { Row, Col } from "react-bootstrap"
import AppliedTutorList from "./components/AppliedTutorList"
import { OpeningContext } from "./context"
import { noop } from "../../common"

import {
  Timetable,
  School,
  GradeSelect,
  ProgramSelect,
  StrugglesWithSelect,
  LearnDifference,
  LocationSelect,
  AdditionalInformation,
} from "../../common"

const OpeningCard = () => {
  const { opening, tutorList, options, permissions } = useContext(OpeningContext)

  return (
    <>
      <Row className="mb-4">
        <School
          opening={opening}
          tutorList={tutorList}
          showEdit={permissions.can_approve_tutor_applications || permissions.can_manage_openings}
        />
      </Row>
      <Row className="mb-4">
        <Col>
          <span className="h3">Session details and schedule</span>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <GradeSelect opening={opening} options={options} setOpening={noop} disabled completed />
          <ProgramSelect opening={opening} options={options} setOpening={noop} disabled completed />
          <StrugglesWithSelect opening={opening} options={options} setOpening={noop} disabled completed />
          <LearnDifference opening={opening} options={options} setOpening={noop} disabled completed />
          <LocationSelect opening={opening} options={options} setOpening={noop} disabled completed />
        </Col>
      </Row>
      <Row className="mb-4">
        <AdditionalInformation opening={opening} />
      </Row>
      {!opening.schedule_flexible && (
        <Row className="mb-4">
          <Col>
            <Timetable opening={opening} />
          </Col>
        </Row>
      )}
      <Row className="mb-4">
        <AppliedTutorList />
      </Row>
    </>
  )
}

export default OpeningCard
