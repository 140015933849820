import * as React from "react"
import { DirectUploadProvider } from "react-activestorage-provider"

class ActiveStorageUpload extends React.PureComponent {
  state = {
    loading: false,
  }

  render() {
    return <DirectUploadProvider {...this.props} render={this.doRender} />
  }

  doRender = options => {
    const { handleUpload, ...leftOptions } = options
    return this.props.render({
      ...leftOptions,
      handleUpload: this.wrappedHandleUpload(handleUpload),
      ready: !this.state.loading,
    })
  }

  wrappedHandleUpload = handleUpload => async files => {
    try {
      if (this.props.onLoading) {
        this.props.onLoading(true)
      }
      this.setState({ loading: true })
      await handleUpload(files)
    } catch (e) {
      if (this.props.onError) {
        this.props.onError(e)
      }
    } finally {
      this.setState({ loading: false })
      if (this.props.onLoading) {
        this.props.onLoading(false)
      }
    }
  }
}

export default ActiveStorageUpload
