import React, { forwardRef, useMemo } from "react"
import { Col, Form, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { useField } from "react-final-form"
import { ReactComponent as Calendar } from "../../assets/images/calendar1.svg"
import { className } from "../../helpers/className"
import { formatDateTimeKeepZone, parseDate } from "../../helpers/dates"
import { buildId } from "../../helpers/forms"

const defaultNames = Object.freeze({
  start: "start_on",
  end: "end_on",
})

const fieldConfig = { type: "time", defaultValue: new Date() }

const DateInput = forwardRef(function DateInput(props, ref) {
  const fieldId = useMemo(buildId, [])
  return (
    <label htmlFor={fieldId} className="position-relative d-block mb-0">
      <Form.Control {...props} ref={ref} id={fieldId} className="v-2 -with-icon-24" />
      <div
        className={className(
          "icon-24-px input-side-icon-24",
          props.disabled ? "icon-gray-color cursor-default" : "icon-black-color cursor-text"
        )}
      >
        <Calendar />
      </div>
    </label>
  )
})

export const DateRangeFields = ({
  names,
  className: rootClassName,
  startPlaceholder,
  endPlaceholder,
  disabled,
  minDate,
  maxDate,
  startRequired,
  endRequired,
  showLabels = true,
}) => {
  const { start, end } = { ...defaultNames, ...names }

  const startField = useField(start, fieldConfig)
  const endField = useField(end, fieldConfig)

  const wrapperClassName = useMemo(() => className("d-flex", "flex-nowrap", rootClassName), [rootClassName])

  return (
    <>
      <div className={wrapperClassName}>
        <div className="flex-grow-1 d-flex flex-column">
          {showLabels ? <span className="text-gray-chat field-subtitle">Start date {startRequired && "*"}</span> : null}
          <DatePicker
            name={start}
            disabled={disabled}
            placeholderText={`${startPlaceholder || "Date"}${startRequired ? "*" : ""}`}
            autoComplete="off"
            minDate={minDate}
            maxDate={parseDate(endField.input.value) || maxDate}
            selected={parseDate(formatDateTimeKeepZone(startField.input.value, "YYYY-MM-DD"))}
            onChange={date => startField.input.onChange(formatDateTimeKeepZone(date, "YYYY-MM-DD"))}
            dateFormat="MM/dd/yyyy"
            customInput={<DateInput isInvalid={startField.meta.touched && startField.meta.error} />}
            required={startRequired}
          />
        </div>
        <div className="mx-2 mb-2 pb-1 align-self-end">—</div>
        <div className="flex-grow-1 d-flex flex-column">
          {showLabels ? <span className="text-gray-chat field-subtitle">End date {endRequired && "*"}</span> : null}
          <DatePicker
            name={end}
            disabled={disabled}
            minDate={parseDate(startField.input.value) || minDate}
            maxDate={maxDate}
            autoComplete="off"
            placeholderText={`${endPlaceholder || "Date"}${endRequired ? "*" : ""}`}
            selected={parseDate(formatDateTimeKeepZone(endField.input.value, "YYYY-MM-DD"))}
            onChange={date => endField.input.onChange(formatDateTimeKeepZone(date, "YYYY-MM-DD"))}
            dateFormat="MM/dd/yyyy"
            customInput={<DateInput isInvalid={endField.meta.touched && endField.meta.error} />}
            required={endRequired}
          />
        </div>
      </div>
      {((startField.meta.touched && startField.meta.error) || (endField.meta.touched && endField.meta.error)) && (
        <Row>
          <Col>
            <Form.Control.Feedback type="invalid" className="d-block px-2">
              {startField.meta.error || endField.meta.error}
            </Form.Control.Feedback>
          </Col>
        </Row>
      )}
    </>
  )
}
