import React, { useContext, useMemo } from "react"
import { OpeningContext } from "./context"
import { Students } from "../../common"

const Step1Students = ({ groups, schools }) => {
  const { opening, setOpening, tutorApplications } = useContext(OpeningContext)

  const canChangeType = useMemo(() => tutorApplications.every(({ group }) => group === null), [tutorApplications])

  return (
    <Students
      opening={opening}
      setOpening={setOpening}
      canChangeType={canChangeType}
      groups={groups}
      schools={schools}
      tutorsList={tutorApplications}
    />
  )
}

export default Step1Students
