import React, { useMemo } from "react"
import { EducatableTypes } from "../../../schedule_module/constants"

export const EducatableNames = ({ educatable }) => {
  const list = useMemo(() => {
    if (educatable.type === EducatableTypes.Group)
      return educatable.students.map(({ first_name, last_name }) => `${last_name} ${first_name}`)
    else if (educatable.type === EducatableTypes.Student) return [educatable.name]
    else return []
  }, [educatable])
  if (list.length === 0) return <span className="text-black-50">None</span>
  return (
    <div className="d-flex flex-column gap-025">
      {list.map((name, idx) => (
        <span key={name + idx}>{name}</span>
      ))}
    </div>
  )
}
