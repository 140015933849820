import React from "react"
import { Nav } from "react-bootstrap"
import { noop } from "../utils"

export const OpeningTabs = ({ steps, activeStep, setActiveStep, checkIsDisabled }) => {
  const onSelect = key => {
    if (key !== activeStep && !checkIsDisabled(key)) {
      setActiveStep(key)
    }
  }

  return (
    <React.Fragment>
      <Nav activeKey={activeStep} className="py-2" onSelect={noop}>
        {steps.map(({ title, key }) => (
          <Nav.Item key={key}>
            <Nav.Link
              eventKey={key}
              className="px-4 py-2"
              disabled={key !== activeStep && checkIsDisabled(key)}
              onSelect={onSelect}
            >
              {title}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </React.Fragment>
  )
}
