import React, { useCallback, useContext, useMemo } from "react"
import { DomainProvider } from "../../../providers"
import { useApiInitialPath } from "../../../hooks/useApiPath"
import Dialog from "../../blocks/Dialog"
import useBaseHandlersBuilder from "../../../hooks/useBaseHandlersBuilder"
import {
  tutors,
  studentsDialog,
  updateOpeningApplication,
  matchApplication,
  getSchoolSchedules,
  getOpening,
  getOpeningApplications,
} from "../configurations/domain"
import { TutorCardConfigsContext, TutorCardActionsContext } from "../contexts"
import { ReactComponent as Lightning } from "../../../assets/images/lightning.svg"
import { StudentsModalContent } from "../pages/tutors/students_modal_content"
import InitialLoadingSpinner from "../../blocks/InitialLoadingSpinner"
import { DataContext, QueryContext } from "../../../contexts"
import { Entities } from "../../../constants"
import { useDialogState } from "../../../hooks/useDialogState"

export const TutorPageActionsProvider = ({ children }) => {
  const { openingId } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request: updateApplication } = getHookState(updateOpeningApplication)

  const update = useCallback(
    (applicationId, data) =>
      updateApplication({
        entitiesIds: {
          [Entities.Openings]: openingId,
          [Entities.Applications]: applicationId,
        },
        data: {
          opening_application: data,
        },
      }),
    [updateApplication, openingId]
  )

  const actions = useMemo(
    () => ({
      saveNote: (application, internal_note) => update(application.id, { internal_note }),
      toggleStar: application => update(application.id, { starred: !application.starred }),
      toggleVisibility: application => update(application.id, { show: !application.show }),
    }),
    [update]
  )

  return <TutorCardActionsContext.Provider value={actions}>{children}</TutorCardActionsContext.Provider>
}

export const StudentsDialogDataProvider = ({ children }) => {
  return (
    <DomainProvider
      config={studentsDialog}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <InitialLoadingSpinner>{children}</InitialLoadingSpinner>
    </DomainProvider>
  )
}

export const TutorCardConfigsProvider = ({ children }) => {
  const { closeDialog, openDialog, dialogState } = useDialogState()
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(matchApplication)
  const { request: reloadOpening } = getHookState(getOpening)
  const { request: reloadApplications } = getHookState(getOpeningApplications)
  const { openingId, tutorListFilters } = useContext(DataContext)
  const { request: update } = getHookState(getSchoolSchedules)

  const updateTutorsList = useCallback(async () => {
    await update({
      entitiesIds: {
        [Entities.Openings]: openingId,
      },
      params: tutorListFilters,
    })
  }, [update, openingId, tutorListFilters])

  const refreshOpening = useCallback(
    () =>
      reloadOpening({
        entitiesIds: {
          [Entities.Openings]: openingId,
        },
      }),
    [reloadOpening, openingId]
  )

  const refreshApplications = useCallback(
    () =>
      reloadApplications({
        entitiesIds: {
          [Entities.Openings]: openingId,
        },
      }),
    [reloadApplications, openingId]
  )

  const configs = useMemo(
    () => [
      {
        text: "Quick match",
        icon: <Lightning />,
        onClick: (_, application) => {
          request({
            entitiesIds: {
              [Entities.Openings]: openingId,
            },
            data: {
              tutor_id: application.tutor.id,
            },
            onSuccess: () => {
              refreshOpening()
              refreshApplications()
            },
          })
        },
        disabled: loading,
      },
      {
        text: "Match with...",
        onClick: (_, application) => openDialog(application),
      },
    ],
    [loading, openDialog, openingId, request, refreshOpening, refreshApplications]
  )

  return (
    <TutorCardConfigsContext.Provider value={configs}>
      {children}
      <Dialog size="xl" title="Match tutor with a group" open={dialogState.open} onClose={closeDialog} centered={false}>
        <StudentsModalContent
          application={dialogState.data}
          onSave={() => {
            updateTutorsList()
            refreshOpening()
            refreshApplications()
            closeDialog()
          }}
        />
      </Dialog>
    </TutorCardConfigsContext.Provider>
  )
}

const TutorsPageProviders = ({ initial, children }) => {
  return (
    <DomainProvider
      connectToParentStore
      initialStorageState={initial}
      config={tutors}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <TutorPageActionsProvider>{children}</TutorPageActionsProvider>
    </DomainProvider>
  )
}

export default TutorsPageProviders
