import React from "react"
import { Form, Col, Row } from "react-bootstrap"
import { Field } from "react-final-form"
import { selectRequired, required } from "../../../helpers/validators"
import { deleteId } from "../../../helpers/profile_form"
import { requiredClass } from "../../../helpers/profile_form"
import RowWarning from "../../blocks/RowWarning"
import FormRow from "../../../components/blocks/FormRow"

import { ReactComponent as Delete } from "../../../assets/images/delete.svg"

const formRowProps = {
  LabelProps: { sm: 24, lg: 8, className: "v-2" },
  ColProps: { sm: 24, lg: 16, className: "mb-0" },
  RowProps: {},
}

const Degree = ({ name, fields, index, degrees }) => {
  return (
    <Row>
      <Col xs={24} lg={18}>
        <RowWarning className="panel_body-warning" name={["degrees", index, "degree"]}>
          <FormRow label="Degree" {...formRowProps} required>
            <Field name={`${name}.degree`} validate={selectRequired}>
              {({ input, meta }) => (
                <Row className="align-items-center">
                  <Col xs={22}>
                    <Form.Control as="select" {...input} className={`v-2 ${requiredClass(meta)}`}>
                      <option value="empty">Pick your degree</option>
                      {degrees.map(([label, value]) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col xs={2} className="align-items-center justify-content-center d-flex">
                    <button
                      type="button"
                      className="icon-button -hover-red"
                      onClick={() => {
                        deleteId(fields, index)
                      }}
                    >
                      <div className="icon-20-px">
                        <Delete />
                      </div>
                    </button>
                  </Col>
                </Row>
              )}
            </Field>
          </FormRow>
        </RowWarning>

        <RowWarning className="panel_body-warning" name={["degrees", index, "description"]}>
          <FormRow label="Description" {...formRowProps} required>
            <Field name={`${name}.description`} validate={required}>
              {({ input, meta }) => (
                <Row>
                  <Col xs={24} lg={22}>
                    <Form.Control
                      as="textarea"
                      {...input}
                      className={`v-2 ${requiredClass(meta)}`}
                      placeholder="Type in degree description"
                      rows="3"
                    />
                  </Col>
                </Row>
              )}
            </Field>
          </FormRow>
        </RowWarning>

        <RowWarning className="panel_body-warning" name={["degrees", index, "college"]}>
          <FormRow label="College" {...formRowProps} required>
            <Field name={`${name}.college`} validate={required}>
              {({ input, meta }) => (
                <Row>
                  <Col xs={24} lg={22}>
                    <Form.Control
                      {...input}
                      className={`v-2 ${requiredClass(meta)}`}
                      placeholder="Type in college name"
                    />
                  </Col>
                </Row>
              )}
            </Field>
          </FormRow>
        </RowWarning>

        {index !== fields.length - 1 && <hr />}
      </Col>
    </Row>
  )
}

export default Degree
