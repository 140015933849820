import Noty from "noty"

class Alerter {
  static info(text, opts = {}) {
    return new Noty({
      type: "info",
      progressBar: false,
      closeWith: ["click", "button"],
      timeout: 1000,
      text,
      ...opts,
    }).show()
  }

  static success(text, opts = {}) {
    return new Noty({
      type: "success",
      progressBar: false,
      closeWith: ["click", "button"],
      timeout: 1000,
      text,
      ...opts,
    }).show()
  }

  static error(text, opts = {}) {
    return new Noty({
      type: "error",
      progressBar: false,
      closeWith: ["click", "button"],
      timeout: 2500,
      text,
      ...opts,
    }).show()
  }
}

export const showErrors = (error, showKey = false) => {
  const { message } = error
  // eslint-disable-next-line no-console
  console.error(message)
  if (error?.response?.data) {
    const {
      response: { data },
    } = error

    if (data.status && data.msg) {
      const message = `${data.status.toUpperCase()}: ${data.msg}`
      Alerter.error(message)
      // eslint-disable-next-line no-console
      console.error(message)
      return
    }
    try {
      for (const key of Object.keys(data.errors)) {
        const value = data.errors[key]
        for (const error of value) {
          Alerter.error(showKey ? `${key}: ${error}` : error)
        }
      }
    } catch (e) {
      Alerter.error("Internal server error")
    }
  } else {
    // eslint-disable-next-line no-console
    console.error("Incorrect response schema")
    Alerter.error("Internal server error")
  }
}

export default Alerter
