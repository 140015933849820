import React from "react"
import { Row, Col, Button } from "react-bootstrap"
import CreateNew from "./create_new"

const Status = ({ students, status, onAccept, onDecline, loading, disabledIds, hasSameName }) => {
  if (status === "accepted") {
    return (
      <Row className="p-3">
        <Col lg={16}>
          <Button variant="outline-primary" block disabled={true}>
            Accepted
          </Button>
        </Col>
      </Row>
    )
  }

  if (status === "declined") {
    return (
      <Row className="p-3">
        <Col lg={16}>
          <Button variant="outline-primary" block disabled={true}>
            Declined
          </Button>
        </Col>
      </Row>
    )
  }

  return (
    <>
      {hasSameName ? null : <CreateNew onAccept={onAccept} loading={loading} />}

      {students.map(student => {
        const linked = disabledIds.findIndex(el => el === student.id) > -1
        return (
          <Row className="p-3" key={student.id}>
            <Col lg={16}>
              <Button
                variant="outline-primary"
                block
                className="text"
                onClick={() => onAccept(student.id)}
                disabled={loading || linked}
              >
                {linked ? "Already linked" : "Accept and link with existing student:"}
                <br />
                <b>
                  {student.name}, {student.grade_text}
                </b>
              </Button>
            </Col>
          </Row>
        )
      })}

      <Row className="p-3">
        <Col lg={16}>
          <Button
            variant="outline-danger"
            block
            className="text"
            onClick={() => {
              onDecline(null)
            }}
            disabled={loading}
          >
            Decline invitation
          </Button>
        </Col>
      </Row>
    </>
  )
}
export default Status
