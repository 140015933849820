import React, { useCallback } from "react"
import { OverlayTrigger, Popover } from "react-bootstrap"
import useViewport from "../../../../hooks/useViewport"

export const Progressbar = ({ planned, scheduled, completed }) => {
  const { isMobileViewport } = useViewport()

  const renderPopover = useCallback(
    props => (
      <Popover {...props}>
        <Popover.Content className="schedule-dashboard__popover-content">
          <div className="d-flex justify-content-between font-size-medium font-weight-semibold mb-2">
            <div>Planned</div>
            <div>{planned}</div>
          </div>
          <div className="d-flex justify-content-between font-size-medium font-weight-semibold mb-2">
            <div>Scheduled</div>
            <div>{scheduled}</div>
          </div>
          <div className="d-flex justify-content-between font-size-medium font-weight-semibold mb-2">
            <div>Completed</div>
            <div>{completed}</div>
          </div>
          <div className="d-flex justify-content-between font-size-medium font-weight-semibold mb-2">
            <div>Left</div>
            <div>{scheduled - completed}</div>
          </div>
        </Popover.Content>
      </Popover>
    ),
    [planned, scheduled, completed]
  )

  return (
    <div>
      <OverlayTrigger
        trigger={isMobileViewport ? ["click"] : ["hover", "focus"]}
        placement="bottom"
        overlay={renderPopover}
      >
        <div className="schedule-dashboard__progress-bar planned-bar">
          <div
            className="schedule-dashboard__progress-bar percentage-bar schedule-bar"
            style={{ width: `${Math.round((scheduled / planned) * 100)}%` }}
          ></div>

          <div
            className="schedule-dashboard__progress-bar percentage-bar completed-bar"
            style={{ width: `${Math.round((completed / planned) * 100)}%` }}
          ></div>
        </div>
      </OverlayTrigger>

      <div className="d-flex justify-content-between font-weight-medium font-size-medium pt-2">
        <div>{planned - completed} left</div>
        <div>
          {scheduled} / {planned}
        </div>
      </div>
    </div>
  )
}
