import React, { useState } from "react"
import { ConnectionsProvider } from "./providers"
import { TutorClientPickerDialog } from "./TutorClientPickerDialog"
import { className as cn } from "../../helpers/className"

const ScheduleSessionButton = ({ variant = "filled" }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <button
        className={cn("btn", variant === "outline" ? "btn-outline-primary" : "btn-primary")}
        onClick={() => setOpen(true)}
      >
        Schedule a session
      </button>

      {open ? (
        <ConnectionsProvider>
          <TutorClientPickerDialog open={open} onClose={() => setOpen(false)} />
        </ConnectionsProvider>
      ) : null}
    </>
  )
}

export default ScheduleSessionButton
