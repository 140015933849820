import React, { useContext, useState } from "react"
import { DataContext, QueryContext } from "../../../../contexts"
import PartialEntityTextUpdate from "../../../blocks/PartialEntityTextUpdate"
import { getOpening, updateOpening } from "../../configurations/domain"
import { Entities } from "../../../../constants"

const OpeningInformationNotes = () => {
  const { opening } = useContext(DataContext)
  const { note, internal_note } = opening
  const { getHookState } = useContext(QueryContext)
  const { request: update } = getHookState(updateOpening)
  const [updating, setUpdating] = useState(false)

  const onChange = noteType => async data => {
    setUpdating(noteType)

    await update({
      entitiesIds: { [Entities.Openings]: opening.id },
      data: { opening: data },
      next: [getOpening],
    })

    setUpdating(false)
  }

  return (
    <div className="d-flex flex-column gap-1">
      <PartialEntityTextUpdate
        label="Note for tutors"
        placeholder="Add note for tutors"
        name="note"
        value={note}
        loading={updating === "note"}
        onChange={onChange("note")}
        inputProps={{ rows: 1 }}
      />
      <PartialEntityTextUpdate
        label="Internal note"
        placeholder="Add internal note"
        name="internal_note"
        value={internal_note}
        loading={updating === "internal_note"}
        onChange={onChange("internal_note")}
        inputProps={{ rows: 1 }}
      />
    </div>
  )
}

export default OpeningInformationNotes
