import ClassroomContainer from "@/components/classroom"
import Navbar from "@/components/Navbar"
import BRTThemeProvider from "@/providers/theme"
import { Box } from "@mui/material"
import React from "react"
import useFetchClassroom from "../hooks/useFetchClassroom"
import useFetchStudent from "../hooks/useFetchStudent"

// StudentDashboardClassroomPage Component
const StudentDashboardClassroomPage = ({ student_id, sign_out_button, currentUser }) => {
  const scheduleId = window.location.pathname.split("/").pop()
  const { studentData } = useFetchStudent({ student_id })
  const { classroom, loading } = useFetchClassroom({ studentId: student_id, scheduleId })
  const isCurriculumProgram = classroom?.is_curriculum_program || false

  if (!studentData) return null

  return (
    <BRTThemeProvider>
      <Box component="header" sx={{ mb: 4 }}>
        <Navbar user={studentData} sign_out_button={sign_out_button} />
      </Box>
      <ClassroomContainer
        loading={loading}
        error={null}
        classroomLink={classroom?.classroom_link}
        data={classroom}
        currentUser={currentUser}
        isCurriculumProgram={isCurriculumProgram}
      />
    </BRTThemeProvider>
  )
}

export default StudentDashboardClassroomPage
