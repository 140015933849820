import React from "react"
import useViewport from "../../../../hooks/useViewport"
import { StudentsList } from "./students_list"
import StudentsPageProviders from "../../providers/students_page"
import InitialLoadingSpinner from "../../../blocks/InitialLoadingSpinner"
import { className } from "../../../../helpers/className"

export const StudentsPage = () => {
  const { isMobileViewport } = useViewport()
  return (
    <StudentsPageProviders>
      <InitialLoadingSpinner>
        <div className={className("card position-relative", isMobileViewport ? "p-3" : "p-4")}>
          <StudentsList />
        </div>
      </InitialLoadingSpinner>
    </StudentsPageProviders>
  )
}
