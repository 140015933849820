document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".js-flash").forEach(flash => {
    const setClose = () => flash.classList.add("-close")

    const closeButton = flash.querySelector(".js-flash-close")
    if (closeButton) {
      closeButton.addEventListener("click", setClose)
    }

    setTimeout(setClose, 20000)
  })
})
