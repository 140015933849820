import { ConnectionLevels } from "../../constants"

export const FieldNames = Object.freeze({
  StartDate: "start_on",
  EndDate: "end_on",
  MaxPaidNoShows: "max_paid_noshows",
  Rate: "rate",
  Tutor: "tutor",
  Position: "position_level",
})

export const positionTypes = [
  { label: "Main tutor", value: ConnectionLevels.Main },
  { label: "Substitute", value: ConnectionLevels.Substitute },
]

export const formValidate =
  ({ checkTutorField, checkPositionField, multipleValues = {} }) =>
  values => {
    const errors = {}

    if (checkTutorField && !multipleValues[FieldNames.Tutor] && !values[FieldNames.Tutor]) {
      errors[FieldNames.Tutor] = "This field is required"
    }

    if (!multipleValues[FieldNames.StartDate] && !values[FieldNames.StartDate]) {
      errors[FieldNames.StartDate] = "This field is required"
    }

    if (
      !multipleValues[FieldNames.MaxPaidNoShows] &&
      (values[FieldNames.MaxPaidNoShows] === null ||
        values[FieldNames.MaxPaidNoShows] === undefined ||
        values[FieldNames.MaxPaidNoShows] === "")
    ) {
      errors[FieldNames.MaxPaidNoShows] = "This field is required"
    }

    if (!multipleValues[FieldNames.Rate] && !values[FieldNames.Rate]) {
      errors[FieldNames.Rate] = "This field is required"
    }

    if (checkPositionField && !multipleValues[FieldNames.Position] && !values[FieldNames.Position]) {
      errors[FieldNames.Position] = "This field is required"
    }

    return errors
  }
