import React from "react"
import { Navigate } from "react-router-dom"
import OpeningOutlet from "../navigation/opening_outlet"
import {
  OpeningInformationPage,
  TutorsPage,
  StudentsPage,
  CreateOpeningPage,
  EditOpeningPage,
  OpeningsPage,
} from "../pages"

export const RouteNames = Object.freeze({
  OpeningInfo: "",
  Students: "students",
  Tutors: "tutors",
})

export const RoutesTitleNames = Object.freeze({
  [RouteNames.OpeningInfo]: "Settings",
  [RouteNames.Students]: "Students",
  [RouteNames.Tutors]: "Tutors",
})

export const RoutesPatterns = Object.freeze({
  [RouteNames.OpeningInfo]: "/:openingId",
  [RouteNames.Tutors]: `/:openingId/tutors`,
  [RouteNames.Students]: `/:openingId/students`,
  Edit: "/:openingId/edit",
  New: "/new",
  List: "/",
})

export const getNavigationConfig = initial => [
  {
    path: RoutesPatterns.List,
    element: <OpeningsPage initial={initial} />,
  },
  {
    path: RoutesPatterns.New,
    element: <CreateOpeningPage />,
  },
  {
    path: RoutesPatterns.Edit,
    element: <EditOpeningPage />,
  },
  {
    path: RoutesPatterns[RouteNames.OpeningInfo],
    element: <OpeningOutlet />,
    children: [
      {
        path: "",
        element: <OpeningInformationPage />,
      },
      {
        path: RoutesPatterns[RouteNames.Students],
        element: <StudentsPage />,
      },
      {
        path: RoutesPatterns[RouteNames.Tutors],
        element: <TutorsPage />,
      },
      {
        path: "*",
        element: <Navigate to=".." />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={RoutesPatterns.List} />,
  },
]

export const tabsNavigationConfig = Object.values(RouteNames).map(path => ({
  path,
  pattern: RoutesPatterns[path],
  title: RoutesTitleNames[path],
}))
