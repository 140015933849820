import React, { forwardRef, useMemo, useContext } from "react"
import moment from "moment"
import { buildId } from "../../../../helpers/forms"
import { className } from "../../../../helpers/className"
import { ScheduleContext } from "../../../../contexts"
import { formatDateKeepZone } from "../../../../helpers/dates"
import { DayActions } from "../../constants"
import { ReactComponent as Plus } from "../../../../assets/images/plus.svg"

const DayNode = forwardRef(function DayNode({ children, className: nodeClassName, onClick }, ref) {
  const nodeId = useMemo(buildId, [])
  return typeof onClick === "function" ? (
    <label htmlFor={nodeId} className={className(nodeClassName, "cursor-pointer")}>
      <button ref={ref} id={nodeId} type="button" className="d-none" onClick={onClick} />
      {children}
    </label>
  ) : (
    <div ref={ref} className={nodeClassName}>
      {children}
    </div>
  )
})

const Day = ({ date, className: htmlClass, children, onClick }) => {
  const { short = false, month } = useContext(ScheduleContext)
  const day = useMemo(() => moment(formatDateKeepZone(date)), [date])
  const isSameMonth = useMemo(() => (month ? day.isSame(moment(month), "month") : true), [day, month])

  const dayClassName = useMemo(
    () =>
      className(
        "session-calendar-day -empty",
        !isSameMonth && "-not-current-month",
        short ? "-month-view" : "-week-view",
        htmlClass
      ),
    [htmlClass, isSameMonth, short]
  )
  const dateClassName = useMemo(
    () => className("session-calendar-date", day.isSame(moment(), "day") && "-current"),
    [day]
  )

  return (
    <DayNode className={dayClassName} onClick={onClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div className={dateClassName}>{day.format("DD")}</div>
      </div>
      {children}
    </DayNode>
  )
}

export const DayTile = ({ date, actions, actionsHandlers = {}, className }) => {
  const canCreate = useMemo(
    () =>
      actions.some(action => action === DayActions.CreateSession) &&
      typeof actionsHandlers[DayActions.CreateSession] === "function",
    [actionsHandlers, actions]
  )

  if (canCreate)
    return (
      <Day date={date} className={className} onClick={actionsHandlers[DayActions.CreateSession]}>
        <div className="d-flex flex-column flex-grow-1 justify-content-between">
          <div className="session-calendar-day_content -action">
            <div className="session-calendar-day_content-button">
              <span className="session-calendar-day_content-button-icon">
                <Plus />
              </span>
            </div>
          </div>
        </div>
      </Day>
    )

  return <Day date={date} className={className} />
}
