import React from "react"
import { Form, Col, Row } from "react-bootstrap"
import { Field } from "react-final-form"
import DownloadFileButton from "./DownloadFileButton"
import UploadFileButton from "./UploadFileButton"
import { required } from "../../../helpers/validators"
import { requiredClass } from "../../../helpers/profile_form"
import FormRow from "../../../components/blocks/FormRow"
import RowWarning from "../../blocks/RowWarning"

import { ReactComponent as Delete } from "../../../assets/images/delete.svg"

const formRowProps = {
  LabelProps: { sm: 24, lg: 8, className: "v-2" },
  ColProps: { sm: 24, lg: 16, className: "mb-0 mt-n1" },
  RowProps: {},
}

const onDeleteClick = (fields, index) => {
  fields.remove(index)
}

const requiredFile = (record, value) => {
  return !!record.id || !!value ? undefined : "Required"
}

const Certificate = ({ name, index, fields, trainings, certificateInfo, admin, isLoading, setIsLoading }) => {
  const crt = fields.value[index]

  if (admin && certificateInfo[crt.id] && certificateInfo[crt.id].review_status !== "verified") {
    return null
  }

  return (
    <>
      <RowWarning className="panel_body-warning" name={["documents", index, "blob_id"]}>
        <Field
          name={`${name}.blob_id`}
          validate={v => {
            return requiredFile(fields.value[index], v)
          }}
        >
          {({ input, meta }) => (
            <FormRow label="Document" {...formRowProps} required>
              <Row className="flex-nowrap align-items-center">
                <Col xs={22}>
                  <input {...input} type="hidden" />

                  <DownloadFileButton
                    file={fields.value[index]}
                    info={certificateInfo[fields.value[index].id]}
                    visible={!!fields.value[index].id || !!fields.value[index].blob_id}
                  />

                  <UploadFileButton
                    fields={fields}
                    index={index}
                    input={input}
                    visible={!fields.value[index].id && !fields.value[index].blob_id}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />

                  {meta.error && meta.touched && <span className="ml-3 text-error text-right">{meta.error}</span>}
                </Col>
                <Col xs={2}>
                  <button
                    type="button"
                    className="icon-button -hover-red"
                    onClick={() => {
                      onDeleteClick(fields, index)
                    }}
                  >
                    <div className="icon-20-px">
                      <Delete />
                    </div>
                  </button>
                </Col>
              </Row>
            </FormRow>
          )}
        </Field>
      </RowWarning>

      <RowWarning className="panel_body-warning" name={["documents", index, "training"]}>
        <Field name={`${name}.training`} validate={required}>
          {({ input, meta }) => (
            <FormRow label="Certificate type" {...formRowProps} required>
              <Row>
                <Col lg={22}>
                  <Form.Control as="select" {...input} className={`v-2 ${requiredClass(meta)}`}>
                    <option key="empty" value="">
                      Pick type
                    </option>
                    {trainings.map(([label, value]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </FormRow>
          )}
        </Field>
      </RowWarning>

      <RowWarning className="panel_body-warning" name={["documents", index, "description"]}>
        <Field name={`${name}.description`} validate={required}>
          {({ input, meta }) => (
            <FormRow label="Description" {...formRowProps} required>
              <Row>
                <Col lg={22}>
                  <Form.Control
                    as="textarea"
                    {...input}
                    className={`v-2 ${requiredClass(meta)}`}
                    placeholder="Please describe the qualification the document gives you"
                  />
                </Col>
              </Row>
            </FormRow>
          )}
        </Field>
      </RowWarning>

      {index !== fields.length - 1 && <hr />}
    </>
  )
}

export default Certificate
