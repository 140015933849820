import React from "react"
import { Form } from "react-final-form"

import apiClient, { showBaseErrorsInAlert } from "../../../common/apiClient"
import FormFields from "./FormFields"
import { getSubmitErrors } from "../../../helpers/forms"
import ProfileValidationProvider from "../providers/ProfileValidation"

const ThirdParty = ({ dataSources }) => {
  const onSubmit = async profile => {
    try {
      await apiClient.put(dataSources.urls.save, { profile })
      window.location.replace(dataSources.urls.next)
    } catch (e) {
      showBaseErrorsInAlert(e)
      return { profile: getSubmitErrors(e) }
    }
  }

  // Placeholder for validations
  const validate = () => {
    const errors = {}
    return errors
  }

  const {
    user: { profile_status },
  } = dataSources

  return dataSources.showThirdPartySignup ? (
    <Form onSubmit={onSubmit} validate={validate}>
      {({ handleSubmit, submitting, values, errors }) => {
        return (
          <form onSubmit={handleSubmit} className="container">
            <ProfileValidationProvider errors={errors}>
              <FormFields dataSources={dataSources} values={values} />
            </ProfileValidationProvider>

            <button
              type={profile_status === "on_review" ? "button" : "submit"}
              className="btn btn-primary"
              disabled={Object.keys(errors).length > 0 || submitting || profile_status === "on_review"}
            >
              {profile_status === "on_review" ? "On review" : "Save"}
            </button>
          </form>
        )
      }}
    </Form>
  ) : (
    <p className="text-lg-center" style={{ fontSize: 1.5 + "rem" }}>
      Thank you for starting your application to tutor with Braintrust. Our summer engagements have been staffed
      already, but we will have a lot of new tutoring opportunities available this fall. We will be in touch later this
      summer about how you can complete your application and training in order to become a tutor with us for the 24/25
      school year. Until then, have a great summer!
    </p>
  )
}
export default ThirdParty
