import React from "react"
import { Form } from "react-final-form"
import { Container, Row, Col, Card, Button } from "react-bootstrap"

import FormFields from "./FormFields"
import UsersNavbar from "../../blocks/UsersNavbar"
import apiClient, { showBaseErrorsInAlert } from "../../../common/apiClient"
import { getSubmitErrors } from "../../../helpers/forms"

const PersonalInfo = ({ dataSources: { user, urls, timeZones, states } }) => {
  const onSubmit = async values => {
    try {
      await apiClient.put(urls.save, values)
      window.location.replace(urls.next)
    } catch (e) {
      showBaseErrorsInAlert(e)
      return getSubmitErrors(e)
    }
  }

  return (
    <Form initialValues={user} onSubmit={onSubmit}>
      {({ handleSubmit, submitting }) => (
        <>
          <UsersNavbar title="Complete your profile" />
          <Container className="panel-wrapper">
            <Card className="panel mb-4" body>
              <h4>Personal Information</h4>
              <Row className="mb-2">
                <Col as="p" lg={15}>
                  We do not show your personal information to other users on the platform, except the tutors, you are
                  scheduling a session with
                </Col>
              </Row>
              <FormFields timeZones={timeZones} states={states} />
            </Card>
          </Container>
          <Container className="d-flex pb-5 bg-light-gray flex-column flex-md-row justify-content-md-end">
            <Button variant="outline-primary" className="mb-5" disabled={submitting} onClick={handleSubmit}>
              Continue
            </Button>
          </Container>
        </>
      )}
    </Form>
  )
}

export default PersonalInfo
