import React from "react"
import { kebabCase } from "lodash"
import { toggleId, elText } from "../../../helpers/profile_form"

const Degree = ({ degree, fields }) => {
  return (
    <>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">Degree</label>
        <div className="col-lg-16">
          <div className="col-lg-24 form-control-plaintext">{degree.degree_text}</div>
        </div>
        <div className="clearfix col-lg-2">
          <span
            className="cursor-pointer"
            onClick={() => {
              toggleId(fields, degree.id)
            }}
            data-test={`degree-${kebabCase(degree.degree_text)}`}
          >
            {elText(degree, fields.value)}
          </span>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">Description</label>
        <div className="col-lg-16 form-control-plaintext">{degree.description}</div>
      </div>

      <div className="form-group row">
        <label className="col-lg-6 col-form-label">College</label>
        <div className="col-lg-16 form-control-plaintext">{degree.college}</div>
      </div>
    </>
  )
}

export default Degree
