import { useSearchParams } from "react-router-dom"
import { RouteNames } from "../configurations/routing"
import { useDefaultLinkBuilder } from "../../blocks/NavigationMenu"

export const useScheduleModuleLinkBuilder = defaultParams => {
  const [searchParams] = useSearchParams()
  const linkBuilder = useDefaultLinkBuilder(defaultParams)
  return config => {
    let link = linkBuilder(config)
    const search = searchParams.toString()
    if (config.path === RouteNames.Schedules && search) link += `?${search}`
    return link
  }
}
