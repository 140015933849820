import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import pluralize from "pluralize"
import { postAndRedirect } from "../../../common/apiClient"
import styled from "styled-components"

const StyledDialogButton = styled(Button)`
  min-width: 82px;
`

const initialState = {
  isShown: false,
  values: [],
  urls: [],
  entityType: "",
  pluralizationKey: "",
}

export const useRejectionDialog = () => {
  const [state, setState] = useState(initialState)

  const show = (values, urls, entityType, pluralizationKey) =>
    setState({
      isShown: true,
      values,
      urls,
      entityType,
      pluralizationKey,
    })

  const hide = () => setState(initialState)

  const onOk = () => {
    postAndRedirect(state.urls.reject, state.urls.redirect, {
      entity: { type: state.entityType, ids: state.values },
    })
  }

  const text = `You sure you want to reject ${pluralize(
    state.pluralizationKey,
    state.values.length,
    true
  )}? This cannot be undone`

  return {
    show,
    dialogProps: {
      isShown: state.isShown,
      text,
      onOk,
      onClose: hide,
      onCancel: hide,
    },
  }
}

export const RejectionConfirmationDialog = ({ isShown, onClose, onCancel, onOk, text }) => {
  return (
    <Modal show={isShown} onHide={onClose} size="sm">
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <StyledDialogButton variant="outline-secondary" size="sm" onClick={onCancel}>
          Cancel
        </StyledDialogButton>

        <StyledDialogButton size="sm" onClick={onOk}>
          Ok
        </StyledDialogButton>
      </Modal.Footer>
    </Modal>
  )
}
