import React, { useState } from "react"
// import apiClient from "../common/apiClient"

const warningText = externalCheckUrl => {
  return (
    <React.Fragment>
      <h5 className="text-center">⚠️ Verification required</h5>
      <p className="text-center">
        Thank you for submitting your profile for approval. In connection with our review, we also need to run a
        background check (at no cost to you) before we can validate your profile to be featured on Braintrust.
        <br />
        Please click{" "}
        <a target="_blank" rel="noreferrer" href={externalCheckUrl}>
          this link
        </a>{" "}
        to complete your background check with our partner, Justifacts!
        {/*{new URL(externalCheckUrl).host}!*/}
      </p>
      {/*<p className="text-center">Confirm that you completed verification and provided all correct information</p>*/}
    </React.Fragment>
  )
}

const setText = () => {
  return <h5 className="text-center">You’re all set, please wait for admin to review your profile</h5>
}

const VerificationCheckbox = ({ externalCheckUrl, initValue }) => {
  //completePath
  const [checked] = useState(initValue) // setChecked
  // const [loading, setLoading] = useState(false)

  // const onClick = () => {
  //   if (checked) {
  //     return
  //   }
  //
  //   setLoading(true)
  //
  //   apiClient
  //     .put(completePath)
  //     .then(() => {
  //       setChecked(true)
  //     })
  //     .catch(() => {})
  //     .finally(() => {
  //       setLoading(false)
  //     })
  // }

  return (
    <div className="panel">
      <div className={`panel_body ${checked ? "verification-complete" : "verification-warning"}`}>
        {checked ? setText() : warningText(externalCheckUrl)}

        {/*<p className="text-center">*/}
        {/*  <label>*/}
        {/*    <input type="checkbox" checked={checked} onChange={onClick} disabled={checked || loading} />*/}
        {/*    &nbsp;I’ve provided my information on the{" "}*/}
        {/*    <a target="_blank" rel="noreferrer" href={externalCheckUrl}>*/}
        {/*      app.justifacts.com*/}
        {/*    </a>*/}
        {/*  </label>*/}
        {/*</p>*/}
      </div>
    </div>
  )
}

export default VerificationCheckbox
