import React from "react"
import { MeetingFormats } from "../../../../constants"

const TitleValue = ({ title, value, className }) => {
  if (!value) return null

  return (
    <div className={`font-size-big ${className}`}>
      <span>{title}:&nbsp;</span>
      <span className="font-weight-semibold">{value}</span>
    </div>
  )
}

export const Location = ({ opening }) => {
  const classes =
    opening.struggle_with?.length ||
    opening.special_treatments?.length ||
    opening.information?.length ||
    opening.schedule_flexible
      ? "mb-2"
      : ""

  const location =
    opening.location === MeetingFormats.Offline ? `Offline${opening.zip ? `, ${opening.zip}` : ""}` : "Online"

  return <TitleValue title="Location" value={location} className={classes} />
}
