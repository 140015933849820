import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  Fragment,
} from "react"
import { kebabCase } from "lodash"
import { Form } from "react-bootstrap"
import { className as cn } from "../../helpers/className"
import { ReactComponent as Pencil } from "../../assets/images/pencil.svg"
import { buildId } from "../../helpers/forms"

export const NodeTypes = Object.freeze({
  Textarea: "textarea",
  Input: "input",
})

const getInputType = type => ([NodeTypes.Textarea, NodeTypes.Input].includes(type) ? type : NodeTypes.Textarea)

const PartialEntityTextUpdate = forwardRef(function PartialEntityTextUpdate(
  {
    label,
    placeholder,
    nodeType = NodeTypes.Textarea,
    inputProps,
    disabled,
    name,
    value: val,
    readonly,
    loading,
    readonlyPlaceholder = "",
    initialEditState = false,
    onChange,
    onCancel = () => {},
    className,
    initialButtonClassName,
    rows,
  },
  ref
) {
  const inputRef = useRef(null)
  const timerRef = useRef(null)
  const [editable, setEditable] = useState(initialEditState)
  const [value, setValue] = useState(val || "")
  const inputId = useMemo(buildId, [])
  const inputDataTest = `textarea-${kebabCase(placeholder)}`
  const inputPropsWithDataTest = { "data-test": inputDataTest, ...inputProps }
  const updateValue = ({ target: { value: newValue } }) => setValue(newValue)
  const updateEditable = value => () => {
    setEditable(value)
    if (value) {
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        if (inputRef.current) inputRef.current.focus()
      }, 4)
    }
  }

  const onSaveClick = async () => {
    await onChange(name ? { [name]: value.trim() } : value.trim())
    updateEditable(false)()
  }

  const onKeyDown = event => {
    switch (event.key) {
      case "Enter":
        if (event.ctrlKey || event.metaKey) {
          onSaveClick()
        }

        break
      case "Escape":
        updateEditable(false)()
        onCancel()
        break
      default:
        return
    }
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus()
    },
  }))

  useEffect(() => {
    if (!loading && !editable && val !== value) setValue(val || "")
  }, [val, value, editable, loading])

  const Grid = useCallback(
    ({ children }) => {
      return (
        <div className="d-flex flex-column w-100 mt-n1">
          {label && (
            <label className="mb-0 text-black-50" htmlFor={inputId}>
              {label}
            </label>
          )}
          <div className={cn("d-flex flex-no-wrap gap-1", className)}>
            <div className="flex-grow-1">{children?.content || null}</div>
            <div className="flex-shrink-0">{children?.action || null}</div>
          </div>
        </div>
      )
    },
    [className, label, inputId]
  )
  const readonlyValue = useMemo(
    () => (
      <div className={cn("partial-entity-text-readonly", readonly ? "py-2" : "")}>
        {val
          ? String(val)
              .split("\n")
              .map((text, idx, arr) => (
                <Fragment key={text + idx}>
                  {text}
                  {idx !== arr.length - 1 && <br />}
                </Fragment>
              ))
          : readonlyPlaceholder}
      </div>
    ),
    [readonlyPlaceholder, val, readonly]
  )

  if (readonly) return readonlyValue
  if (editable)
    return (
      <Grid>
        {{
          content: (
            <Form.Control
              className="v-2"
              id={inputId}
              as={getInputType(nodeType)}
              ref={inputRef}
              value={value}
              onKeyDown={onKeyDown}
              onChange={updateValue}
              placeholder={placeholder}
              disabled={loading || disabled}
              rows={rows}
              {...inputPropsWithDataTest}
            />
          ),
          action: loading ? (
            <div className="spinner-border text-primary" />
          ) : (
            <button
              onClick={onSaveClick}
              disabled={disabled}
              className="partial-entity-text-add-btn"
              data-test={`save-${kebabCase(placeholder)}`}
            >
              Save
            </button>
          ),
        }}
      </Grid>
    )
  return value.length === 0 ? (
    <button
      disabled={disabled}
      className={cn("partial-entity-text-add-btn w-100", initialButtonClassName)}
      onClick={updateEditable(true)}
      data-test={`open-${kebabCase(placeholder || label)}`}
    >
      {placeholder || label}
    </button>
  ) : (
    <Grid>
      {{
        content: readonlyValue,
        action: loading ? (
          <div className="spinner-border text-primary" />
        ) : (
          <button onClick={updateEditable(true)} disabled={disabled} className="icon-16-px btn-clear text-black">
            <Pencil />
          </button>
        ),
      }}
    </Grid>
  )
})

export default PartialEntityTextUpdate
