import React from "react"
import { DomainProvider } from "../../../providers"
import { useApiInitialPath } from "../../../hooks/useApiPath"
import useBaseHandlersBuilder from "../../../hooks/useBaseHandlersBuilder"
import { students } from "../configurations/domain"

export const StudentsDataProvider = ({ children }) => {
  return (
    <DomainProvider
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
      initialStorageState={{ schools: [], educatables: [], subjects: [] }}
      config={students}
    >
      {children}
    </DomainProvider>
  )
}
