import React, { useCallback, useContext, useState } from "react"
import { QueryContext } from "../../../contexts"
import { hideOpening } from "../providers"
import Alerter from "../../../common/alerter"

export const HideOpeningButton = ({ openingId }) => {
  const { getQueryState } = useContext(QueryContext)
  const { request } = getQueryState(hideOpening)
  const [loading, setLoading] = useState(false)

  const hide = useCallback(async () => {
    setLoading(true)
    await request({
      data: { opening_id: openingId },
      onSuccess: () => {
        Alerter.success("Opening successfully saved")
      },
    })
    setLoading(false)
  }, [openingId, request])

  return loading ? (
    <div className="d-flex align-content-center">
      <div className="spinner-border text-primary" />
    </div>
  ) : (
    <button className="btn p-0 min-w-50-px text-gray-chat" onClick={hide}>
      Hide
    </button>
  )
}
