import React from "react"
import { Field } from "react-final-form"
import Searchable from "./Searchable"
import { required } from "../../../helpers/validators"

import Input from "../../Input"

const FormFields = ({ dataSources: { user, organizations }, form }) => {
  const showSearchable = form.getFieldState("organization.id") && form.getFieldState("organization.id").value

  return (
    <React.Fragment>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">Company</label>
        <div className="col-lg-9">
          <Field
            name={`organization.id`}
            initialValue={user.organization?.id}
            parse={value => (value ? parseInt(value) : null)}
          >
            {({ input }) => (
              <select {...input} className="form-control">
                <option key="empty" value="">
                  None
                </option>
                {
                  // eslint-disable-next-line no-unused-vars
                  organizations.map(([_, { id, name }]) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))
                }
              </select>
            )}
          </Field>
        </div>

        <div className="offset-lg-6 col-lg-18">{showSearchable && <Searchable user={user} />}</div>
      </div>

      {showSearchable && (
        <>
          <Field
            name={`organization.default_session_rate`}
            initialValue={user.organization?.default_session_rate}
            validate={required}
          >
            {({ input, meta }) => (
              <Input input={input} meta={meta} label={"Default Session rate"} isRequired={true} type="number">
                <div className="input-group-append">
                  <div className="input-group-text">$</div>
                </div>
              </Input>
            )}
          </Field>

          <Field
            name={`organization.default_tutor_pay`}
            initialValue={user.organization?.default_tutor_pay}
            validate={required}
          >
            {({ input, meta }) => (
              <Input input={input} meta={meta} label={"Default Tutor Pay"} isRequired={true} type="number">
                <div className="input-group-append">
                  <div className="input-group-text">$</div>
                </div>
              </Input>
            )}
          </Field>
        </>
      )}
    </React.Fragment>
  )
}

export default FormFields
