import BRTThemeProvider from "@/providers/theme"
import React from "react"

import { ApiProvider } from "./ApiProvider"
import { ConfigurationProvider } from "./ConfigurationProvider"
import { DataProvider } from "./DataProvider"
import { DomainTransactionContextProvider } from "./DomainTransactionContextProvider"
import { InitialLoadingProvider } from "./InitialLoadingProvider"
import { QueryProvider } from "./QueryProvider"
import { RequestsParamsCacheProvider } from "./RequestsParamsCacheProvider"

import { InitialRequests } from "@/helpers/requests/InitialRequests"

export const DomainProvider = ({
  config,
  apiBase,
  children,
  dataProviderType,
  initialStorageState = {},
  connectToParentStore = false,
  useApiPathBuilder = () => "",
  useHandlersBuilder = () => ({}),
}) => {
  return (
    <BRTThemeProvider>
      <ConfigurationProvider config={config}>
        <DataProvider type={dataProviderType} initialState={initialStorageState} connectToParent={connectToParentStore}>
          <DomainTransactionContextProvider>
            <RequestsParamsCacheProvider connectToParent={connectToParentStore}>
              <ApiProvider apiBase={apiBase} useApiPathBuilder={useApiPathBuilder}>
                <QueryProvider useHandlersBuilder={useHandlersBuilder} connectToParent={connectToParentStore}>
                  <InitialLoadingProvider>
                    <InitialRequests>{children}</InitialRequests>
                  </InitialLoadingProvider>
                </QueryProvider>
              </ApiProvider>
            </RequestsParamsCacheProvider>
          </DomainTransactionContextProvider>
        </DataProvider>
      </ConfigurationProvider>
    </BRTThemeProvider>
  )
}
