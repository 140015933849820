import React, { useMemo } from "react"
import { Row, Col, Form } from "react-bootstrap"
import { Field } from "react-final-form"
import Condition from "../Condition"
import { valueOrNull, formatZip } from "../../helpers/formatters"
import useViewport from "../../hooks/useViewport"
import { MeetingFormats } from "../../constants"
import { validateZip } from "../../helpers/validators"

export const LocationFieldNames = Object.freeze({
  MeetingFormat: "meeting_format",
  Address1: "address_1",
  Address2: "address_2",
  City: "city",
  State: "state",
  Zip: "zip",
  Link: "meeting_info",
})

const defaultRequiredFields = Object.freeze({
  [LocationFieldNames.MeetingFormat]: false,
  [LocationFieldNames.Address1]: true,
  [LocationFieldNames.Address2]: false,
  [LocationFieldNames.City]: true,
  [LocationFieldNames.State]: true,
  [LocationFieldNames.Zip]: true,
  [LocationFieldNames.Link]: true,
})

const LocationLabels = Object.freeze({
  [LocationFieldNames.Address1]: "Address 1",
  [LocationFieldNames.Address2]: "Address 2",
  [LocationFieldNames.City]: "City",
  [LocationFieldNames.State]: "State",
  [LocationFieldNames.Zip]: "Zip",
  [LocationFieldNames.Link]: "Link to the meeting",
})

const PencilSpaces = Object.freeze({ value: MeetingFormats.PencilSpaces, label: "Pencil spaces", defaultValue: true })
const Online = Object.freeze({ value: MeetingFormats.Online, label: "Online" })
const Offline = Object.freeze({ value: MeetingFormats.Offline, label: "Offline" })
const LessonSpace = Object.freeze({ value: MeetingFormats.LessonSpace, label: "Lessonspace" })
const locationFormatCases = Object.freeze([PencilSpaces, Online, Offline, LessonSpace])

export const defaultLocationValidation = values => {
  const errors = {}
  if (!values[LocationFieldNames.MeetingFormat]) {
    errors[LocationFieldNames.MeetingFormat] = "Meeting format is required"
  } else {
    if (values[LocationFieldNames.MeetingFormat] === MeetingFormats.Online) {
      if (!values[LocationFieldNames.Link])
        errors[LocationFieldNames.Link] = `${LocationLabels[LocationFieldNames.Link]} is required`
    } else if (values[LocationFieldNames.MeetingFormat] === MeetingFormats.Offline) {
      if (!values[LocationFieldNames.Address1])
        errors[LocationFieldNames.Address1] = `${LocationLabels[LocationFieldNames.Address1]} is required`
      if (!values[LocationFieldNames.City])
        errors[LocationFieldNames.City] = `${LocationLabels[LocationFieldNames.City]} is required`
      if (!values[LocationFieldNames.State])
        errors[LocationFieldNames.State] = `${LocationLabels[LocationFieldNames.State]} is required`
      if (!values[LocationFieldNames.Zip])
        errors[LocationFieldNames.Zip] = `${LocationLabels[LocationFieldNames.Zip]} is required`
      else if (validateZip(values[LocationFieldNames.Zip])) errors[LocationFieldNames.Zip] = "Invalid zip format"
    }
  }
  return errors
}

export const LocationFields = ({ disabled, multipleValues = {}, requiredFields = defaultRequiredFields }) => {
  const { isMobileViewport } = useViewport()

  const placeholders = useMemo(
    () =>
      Object.values(LocationFieldNames).reduce((acc, key) => {
        acc[key] = LocationLabels[key] || ""
        if (multipleValues[key]) acc[key] += " - Multiple values"
        else if (requiredFields[key]) acc[key] += "*"
        return acc
      }, {}),
    [multipleValues, requiredFields]
  )

  return (
    <>
      <Row className="align-items-center py-2 mt-1 flex-nowrap">
        {locationFormatCases.map(({ label, value, defaultValue }, idx) => (
          <Col
            key={value}
            xs={24}
            lg="auto"
            className={isMobileViewport && idx !== locationFormatCases.length - 1 ? "mb-2" : ""}
          >
            <Field name={LocationFieldNames.MeetingFormat} defaultValue={!!defaultValue} type="radio" value={value}>
              {({ input }) => (
                <Form.Check
                  {...input}
                  id={value}
                  className="text-nowrap mr-2 v-2"
                  label={label}
                  custom
                  disabled={disabled}
                />
              )}
            </Field>
          </Col>
        ))}
      </Row>
      <Condition when={LocationFieldNames.MeetingFormat} is={Offline.value}>
        <Row className="my-3">
          <Col xs={24}>
            <Field name={LocationFieldNames.Address1} format={valueOrNull}>
              {({ input, meta }) => (
                <Row className="mx-0">
                  <Form.Control
                    {...input}
                    className="v-2"
                    placeholder={placeholders[LocationFieldNames.Address1]}
                    disabled={disabled}
                    isInvalid={meta.touched && meta.error}
                  />
                  {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
                </Row>
              )}
            </Field>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={24}>
            <Field name={LocationFieldNames.Address2} format={valueOrNull}>
              {({ input, meta }) => (
                <Row className="mx-0">
                  <Form.Control
                    {...input}
                    className="v-2"
                    placeholder={placeholders[LocationFieldNames.Address2]}
                    disabled={disabled}
                    isInvalid={meta.touched && meta.error}
                  />
                  {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
                </Row>
              )}
            </Field>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={24}>
            <Field name={LocationFieldNames.City} format={valueOrNull}>
              {({ input, meta }) => (
                <Row className="mx-0">
                  <Form.Control
                    {...input}
                    className="v-2"
                    placeholder={placeholders[LocationFieldNames.City]}
                    disabled={disabled}
                    isInvalid={meta.touched && meta.error}
                  />
                  {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
                </Row>
              )}
            </Field>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12}>
            <Field name={LocationFieldNames.State} format={valueOrNull}>
              {({ input, meta }) => (
                <Row className="mx-0">
                  <Form.Control
                    {...input}
                    className="v-2"
                    placeholder={placeholders[LocationFieldNames.State]}
                    disabled={disabled}
                    isInvalid={meta.touched && meta.error}
                  />
                  {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
                </Row>
              )}
            </Field>
          </Col>
          <Col xs={12}>
            <Field name={LocationFieldNames.Zip} format={formatZip}>
              {({ input, meta }) => (
                <Row className="mx-0">
                  <Form.Control
                    {...input}
                    className="v-2"
                    placeholder={placeholders[LocationFieldNames.Zip]}
                    isInvalid={meta.touched && meta.error}
                    disabled={disabled}
                  />
                  {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
                </Row>
              )}
            </Field>
          </Col>
        </Row>
      </Condition>
      <Condition when={LocationFieldNames.MeetingFormat} is={Online.value}>
        <Row className="my-3">
          <Col xs={24}>
            <Field name={LocationFieldNames.Link} format={valueOrNull}>
              {({ input, meta }) => (
                <Row className="mx-0">
                  <Form.Control
                    {...input}
                    placeholder={placeholders[LocationFieldNames.Link]}
                    className="v-2"
                    disabled={disabled}
                    isInvalid={meta.touched && meta.error}
                  />
                  {meta.touched && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}
                </Row>
              )}
            </Field>
          </Col>
        </Row>
      </Condition>
    </>
  )
}
