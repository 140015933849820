import { useEffect } from "react"
import { parse } from "query-string"

const useFocusedSession = () => {
  const { id: focusedSessionId } = parse(window.location.search)

  useEffect(() => {
    const card = document.getElementById(focusedSessionId)
    if (card) {
      card.scrollIntoView()
    }
  })

  return +focusedSessionId
}

export default useFocusedSession
