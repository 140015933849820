import React from "react"
import { Field } from "react-final-form"
import { required } from "../../../helpers/validators"
import FormRow from "../../blocks/FormRow"
import { Col, Row, Form } from "react-bootstrap"
import { showFieldError } from "../../../helpers/forms"
import { serializeGrades } from "../../../helpers/serializers"
import FormSelect from "../../blocks/FormSelect"
import { trim } from "../../../helpers/formatters"

const FormFields = ({ grades, disableAll }) => {
  const gradeOptions = serializeGrades(grades)

  return (
    <>
      <Row noGutters>
        <h4 className="mb-4">Parent details</h4>
      </Row>

      <Row>
        <Col sm={12}>
          <Field name="first_name">
            {({ input }) => (
              <FormRow label="First name" LabelProps={{ lg: 8 }} ColProps={{ lg: 14 }}>
                <Form.Control {...input} disabled={disableAll} />
              </FormRow>
            )}
          </Field>

          <Field name="last_name">
            {({ input }) => (
              <FormRow label="Last name" LabelProps={{ lg: 8 }} ColProps={{ lg: 14 }}>
                <Form.Control {...input} disabled={disableAll} />
              </FormRow>
            )}
          </Field>

          <Field name="email" validate={required} format={trim}>
            {({ input, meta }) => (
              <FormRow label="Email" required LabelProps={{ lg: 8 }} ColProps={{ lg: 14 }}>
                <Form.Control {...input} isInvalid={showFieldError(meta)} disabled={disableAll} />
                <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col sm={12}>
          <Field name="note">
            {({ input }) => (
              <FormRow label="Note" LabelProps={{ lg: 8 }} ColProps={{ lg: 14 }}>
                <Form.Control {...input} as="textarea" rows="5" disabled={disableAll} />
                <Form.Text>Your note will be shared with parent upon invitation</Form.Text>
              </FormRow>
            )}
          </Field>
        </Col>
      </Row>

      <Row noGutters>
        <h4 className="mb-4">Student details</h4>
      </Row>

      <Row>
        <Col sm={12}>
          <Field name="student_first_name" validate={required}>
            {({ input, meta }) => (
              <FormRow label="First name" required LabelProps={{ lg: 8 }} ColProps={{ lg: 14 }}>
                <Form.Control {...input} isInvalid={showFieldError(meta)} disabled={disableAll} />
                <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="student_last_name" validate={required}>
            {({ input, meta }) => (
              <FormRow label="Last name" required LabelProps={{ lg: 8 }} ColProps={{ lg: 14 }}>
                <Form.Control {...input} isInvalid={showFieldError(meta)} disabled={disableAll} />
                <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="student_grade" validate={required}>
            {({ input, meta }) => (
              <FormRow label="Grade" required LabelProps={{ lg: 8 }} ColProps={{ lg: 14 }}>
                <FormSelect
                  {...input}
                  options={gradeOptions}
                  placeholder="Pick grade"
                  isInvalid={showFieldError(meta)}
                  disabled={disableAll}
                />
                <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>
        </Col>
      </Row>
    </>
  )
}

export default FormFields
