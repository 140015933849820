import React, { useState } from "react"
import { Field } from "react-final-form"
import { Form, Col, Row } from "react-bootstrap"
import { PatternFormat } from "react-number-format"
import ActiveStorageUpload from "../../../common/ActiveStorageUpload"
import { chain, required, validateLength } from "../../../helpers/validators"
import { requiredClass } from "../../../helpers/profile_form"
import Alerter from "../../../common/alerter"
import RowWarning from "../../blocks/RowWarning"
import FormRow from "../../../components/blocks/FormRow"
import useViewport from "../../../hooks/useViewport"

const FieldNames = Object.freeze({
  FirstName: "first_name",
  LastName: "last_name",
  Bio: "bio",
  PhotoId: "photo_blob_id",
  PhoneNumber: "phone",
  TimeZone: "time_zone",
  ZipCode: "zip",
})

const formRowProps = {
  LabelProps: { sm: 24, lg: 6, className: "v-2" },
  ColProps: { sm: 24, lg: 18, className: "mb-0" },
  RowProps: {},
}

const PhotoSelector = ({ url, isLoadingPhoto, setIsLoadingPhoto, input, meta }) => {
  const [photoUrl, setPhotoUrl] = useState(url)

  const onError = () => {
    Alerter.error("Upload error")
    setIsLoadingPhoto(false)
  }

  const onFileChange = handleUpload => event => {
    const files = event.target.files

    if (files && files[0]) {
      setPhotoUrl(URL.createObjectURL(files[0]))
    }

    handleUpload(files)
    setIsLoadingPhoto(true)
  }

  const resetImage = () => {
    setPhotoUrl(null)
    input.onChange(null)
  }

  return (
    <div className="d-flex flex-grow-1">
      <div className="col-md-auto">
        <div className={`frame ${!isLoadingPhoto ? requiredClass(meta) : ""}`}>
          {!!photoUrl && <img src={photoUrl} />}
          {isLoadingPhoto && (
            <div className="frame-overlay">
              <div className="spinner-border spinner-border-lg text-primary" />
            </div>
          )}
        </div>
      </div>
      <div className="col-md-auto flex-grow-1 d-flex flex-column align-items-start">
        <input {...input} type="hidden" />
        <ActiveStorageUpload
          onSuccess={ids => {
            input.onChange(ids && ids[0])
            setIsLoadingPhoto(false)
          }}
          onError={onError}
          render={({ handleUpload, ready }) => (
            <label className={`btn btn-sm btn-link btn-link-primary ${!ready && "disabled"}`}>
              Upload new photo
              <input
                accept="image/*"
                type="file"
                hidden
                disabled={!ready}
                onChange={onFileChange(handleUpload)}
                data-test="photo_upload"
              />
            </label>
          )}
        />
        {!!photoUrl && !isLoadingPhoto && (
          <button type="button" className="btn btn-sm btn-link btn-link-error" onClick={resetImage}>
            Remove photo
          </button>
        )}
      </div>
    </div>
  )
}

const FormFields = ({ dataSources: { timeZones, user }, isLoadingPhoto, setIsLoadingPhoto }) => {
  const { isMobileViewport } = useViewport()

  return (
    <React.Fragment>
      <FormRow label="Email" {...formRowProps}>
        <Row>
          <Col lg={12}>
            <Form.Control value={user.email} className="form-control v-2 mt-n1" disabled />
          </Col>
        </Row>
      </FormRow>

      <RowWarning className="panel_body-warning" name={FieldNames.FirstName}>
        <Field name={FieldNames.FirstName} initialValue={user.first_name} validate={required}>
          {({ input, meta }) => (
            <FormRow label="First name" {...formRowProps} required>
              <Row>
                <Col lg={12}>
                  <Form.Control
                    {...input}
                    className={`form-control v-2 ${requiredClass(meta)}`}
                    placeholder="Enter first name"
                  />
                </Col>
              </Row>
            </FormRow>
          )}
        </Field>
      </RowWarning>

      <RowWarning className="panel_body-warning" name={FieldNames.LastName}>
        <Field name={FieldNames.LastName} initialValue={user.last_name} validate={required}>
          {({ input, meta }) => (
            <FormRow label="Last name" {...formRowProps} required>
              <Row>
                <Col lg={12}>
                  <Form.Control
                    {...input}
                    className={`form-control v-2 ${requiredClass(meta)}`}
                    placeholder="Enter last name"
                  />
                </Col>
              </Row>
            </FormRow>
          )}
        </Field>
      </RowWarning>

      <RowWarning className="panel_body-warning" name={FieldNames.PhoneNumber}>
        <Field
          name={FieldNames.PhoneNumber}
          initialValue={user.phone}
          validate={chain(required, validateLength({ expected: 11, type: "geq" }))}
        >
          {({ input, meta }) => (
            <FormRow label="Phone number" {...formRowProps} required>
              <Row>
                <Col lg={12}>
                  <PatternFormat
                    value={input.value?.substring(1) || ""}
                    format="(###)###-####"
                    allowEmptyFormatting
                    mask=" "
                    type="tel"
                    customInput={Form.Control}
                    valueIsNumericString
                    placeholder="Enter phone number"
                    onValueChange={({ value }) => input.onChange(value ? `1${value}` : "")}
                    className={`form-control v-2 ${requiredClass(meta)}`}
                    name="phone"
                  />
                </Col>
              </Row>
            </FormRow>
          )}
        </Field>
      </RowWarning>

      <RowWarning className="panel_body-warning" name={FieldNames.ZipCode}>
        <Field
          name={FieldNames.ZipCode}
          initialValue={user.zip}
          validate={chain(required, validateLength({ expected: 5, type: "geq" }))}
        >
          {({ input, meta }) => (
            <FormRow label="Zip Code" {...formRowProps} required>
              <Row className={isMobileViewport ? "flex-column-reverse" : ""}>
                <Col lg={12}>
                  <Form.Control
                    {...input}
                    className={`form-control v-2 ${requiredClass(meta)}`}
                    placeholder="Zip Code"
                  />
                </Col>
                <Col className="flex-grow" />
                <Col lg={10} xl={8}>
                  <div className="alert alert-info">
                    We collect your Zip Code so that we can notify you of In Person tutoring opportunities that are in
                    your area.
                  </div>
                </Col>
              </Row>
            </FormRow>
          )}
        </Field>
      </RowWarning>

      <RowWarning className="panel_body-warning" name={FieldNames.Bio}>
        <Field name={FieldNames.Bio} initialValue={user.profile.bio} validate={required}>
          {({ input, meta }) => (
            <FormRow label="Bio" {...formRowProps} required>
              <Row className={isMobileViewport ? "flex-column-reverse" : ""}>
                <Col lg={12}>
                  <Form.Control
                    {...input}
                    as="textarea"
                    className={`form-control v-2 ${requiredClass(meta)}`}
                    placeholder="Enter bio"
                    rows="5"
                  />
                </Col>
                <Col className="flex-grow" />
                <Col lg={10} xl={8}>
                  <div className="alert alert-info">
                    Introduce yourself. What is your background? What do you like about your job?
                  </div>
                </Col>
              </Row>
            </FormRow>
          )}
        </Field>
      </RowWarning>

      <RowWarning className="panel_body-warning" name={FieldNames.PhotoId}>
        <Field name={FieldNames.PhotoId}>
          {props => (
            <FormRow label="Photo" {...formRowProps} required>
              <Row className={isMobileViewport ? "flex-column-reverse" : ""}>
                <Col lg={12}>
                  <PhotoSelector
                    {...props}
                    url={user.profile.photo_url}
                    isLoadingPhoto={isLoadingPhoto}
                    setIsLoadingPhoto={setIsLoadingPhoto}
                  />
                </Col>
                <Col className="flex-grow" />
                <Col lg={10} xl={8}>
                  <div className="alert alert-info">
                    Don&#39;t have a headshot? No problem. Go to a bright room. Stand in front of a plain wall. Have a
                    friend take pic of your face from 4 feet away. Smile!{" "}
                  </div>
                </Col>
              </Row>
            </FormRow>
          )}
        </Field>
      </RowWarning>

      <RowWarning className="panel_body-warning" name={FieldNames.TimeZone}>
        <FormRow label="Time zone" {...formRowProps} required>
          <Row className={isMobileViewport ? "flex-column-reverse" : ""}>
            <Col lg={12}>
              <Field name={FieldNames.TimeZone} initialValue={user.time_zone} validate={required}>
                {({ input, meta }) => (
                  <Form.Control {...input} as="select" className={`form-control v-2 ${requiredClass(meta)}`}>
                    <option key="empty" />
                    {timeZones.map(tz => {
                      if (tz[1]) {
                        return (
                          <option key={tz[1]} value={tz[1]}>
                            {tz[0]}
                          </option>
                        )
                      } else {
                        return (
                          <option key="dash" value="" disabled="disabled">
                            -------------
                          </option>
                        )
                      }
                    })}
                  </Form.Control>
                )}
              </Field>
            </Col>
          </Row>
        </FormRow>
      </RowWarning>
    </React.Fragment>
  )
}

export default FormFields
