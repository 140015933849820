import React, { useCallback, useMemo } from "react"
import { kebabCase } from "lodash"
import { Link, useResolvedPath, useParams, matchPath, generatePath } from "react-router-dom"
import { Nav } from "react-bootstrap"

export const useDefaultLinkBuilder = defaultParams => {
  const routerParams = useParams()
  const params = useMemo(() => ({ ...defaultParams, ...routerParams }), [defaultParams, routerParams])
  return ({ pattern }) => generatePath(pattern, params)
}

export const NavigationMenu = ({ configs, defaultParams, useLinkBuilder = useDefaultLinkBuilder }) => {
  const { pathname } = useResolvedPath()
  const matchLinkPath = useCallback(pattern => matchPath(pattern, pathname), [pathname])
  const linkBuilder = useLinkBuilder(defaultParams)

  return (
    <div className="brain-trust-tabs-wrapper mx-n4">
      <Nav variant="tabs" className="brain-trust-tabs -links px-4" as="nav">
        {configs.map(config => (
          <Nav.Item key={config.pattern} className="brain-trust-tab -links">
            <Nav.Link
              as={Link}
              active={matchLinkPath(config.pattern)}
              to={linkBuilder(config)}
              role="link"
              className="brain-trust-tab-content -links"
              data-test={`openings-${kebabCase(config.title)}`}
            >
              {config.title}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  )
}

export default NavigationMenu
