import React from "react"
import { Row, Col, Button } from "react-bootstrap"

const CreateNew = ({ onAccept, loading }) => {
  return (
    <Row className="p-3">
      <Col lg={16}>
        <Button
          variant="outline-primary"
          block
          className="text"
          onClick={() => {
            onAccept(null)
          }}
          disabled={loading}
        >
          This is correct, add student to my profile and accept
        </Button>
      </Col>
    </Row>
  )
}

export default CreateNew
