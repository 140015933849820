import React, { useCallback, useContext } from "react"
import ContextMenuProvider, { ContextMenuNodeTypes, ContextMenuPositions } from "../../blocks/ContextMenu"
import { ContextMenuContext } from "../../../contexts"

const SchedulesDropdownButton = ({ schedules }) => {
  const { openContextMenu } = useContext(ContextMenuContext)

  const openMenu = useCallback(
    event => {
      openContextMenu(
        event,
        schedules.map(({ id, educatable }) => ({
          title: educatable.name,
          nodeType: ContextMenuNodeTypes.NativeLink,
          href: `/schedules/${id}`,
          target: "_blank",
          rel: "noreferrer noopener",
          className: "text-no-wrap",
        }))
      )
    },
    [schedules, openContextMenu]
  )

  return (
    <button className="btn btn-link-primary p-0 tutor-dashboard__review-schedule-button" onClick={openMenu}>
      Review schedule
    </button>
  )
}

const SchedulesDropdown = ({ schedules, anchorRef }) => (
  <ContextMenuProvider
    anchorRef={anchorRef}
    positionX={ContextMenuPositions.Start}
    positionY={ContextMenuPositions.End}
  >
    <SchedulesDropdownButton schedules={schedules} />
  </ContextMenuProvider>
)

export const ReviewScheduleButton = ({ opening, anchorRef }) => {
  if (opening.matched_schedules.length === 1) {
    return (
      <a
        href={`/schedules/${opening.matched_schedules[0].id}`}
        target="_blank"
        rel="noreferrer noopener"
        className="text-uppercase font-weight-bold font-size-extra-small"
      >
        Review schedule
      </a>
    )
  }

  return <SchedulesDropdown schedules={opening.matched_schedules} anchorRef={anchorRef} />
}
