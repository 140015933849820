import React, { useState, useMemo } from "react"
import { Button } from "react-bootstrap"
import { useField, useForm } from "react-final-form"
import apiClient, { showBaseErrorsInAlert } from "../../../common/apiClient"
import { getSubmitErrors } from "../../../helpers/forms"
import { getFullName } from "../../../helpers/presentational"
import { isBraintrustOrganization } from "./index"
import { find } from "lodash"
import TutorPay from "./fields/TutorPay"
import Tutor from "./fields/Tutor"
import Company from "./fields/Company"
import TutorPayKind from "./fields/TutorPayKind"
import SessionRate from "./fields/SessionRate"
import Partnership from "./fields/Partnership"

const labelSize = { sm: 7, md: 5, lg: 4 }
const colSize = { sm: 17, md: 14, lg: 10, xl: 8 }

const FormFields = ({ student, organizations, partnerships, urls, submitting, hasCC }) => {
  const [tutor, setTutor] = useState(null)
  const [defaultTutorPay, setDefaultTutorPay] = useState(0)
  const [isFormDisabled, setIsFormDisabled] = useState(false)

  const form = useForm()
  const {
    input: { value: organizationId },
  } = useField("organization_id")

  const onSelectTutor = (data, onChange) => {
    setTutor(data)
    onChange(data?.value)

    fetchTutorRate(data?.value, organizationId)
  }

  const onSelectOrganization = (value, onChange) => {
    onChange(value)
    fetchTutorRate(tutor?.value, value)
  }

  const fetchTutorRate = async (tutorId, organizationId) => {
    if (!tutorId) {
      form.mutators.setFieldData("tutor_pay", 0)
      form.mutators.setFieldData("session_rate", 0)
      return
    }
    setIsFormDisabled(true)
    try {
      const query =
        organizationId && !isBraintrustOrganization(organizationId) ? `?organization_id=${organizationId}` : ""
      const { data } = await apiClient.get(`/api/admin/tutors/${tutorId}/rates${query}`)
      const { default_session_rate, default_tutor_pay } = data
      form.mutators.setFieldData("tutor_pay", default_tutor_pay || 0)
      form.mutators.setFieldData("session_rate", default_session_rate || 0)
      setDefaultTutorPay(default_tutor_pay)
    } catch (e) {
      showBaseErrorsInAlert(e)
      return getSubmitErrors(e)
    } finally {
      setIsFormDisabled(false)
    }
  }

  const isPartnershipOrganization = id => {
    const test = find(organizations, el => el.label === "Partnership")
    return id === test.value
  }

  const selectedPartnership = isPartnershipOrganization(organizationId)

  const partnershipOptions = useMemo(() => [{ label: " — select —", value: null }, ...partnerships], [partnerships])

  return (
    <>
      <p>{getFullName(student)}</p>
      <Tutor urls={urls} isFormDisabled={isFormDisabled} tutor={tutor} onSelectTutor={onSelectTutor} />

      <Company
        isFormDisabled={isFormDisabled}
        onSelectOrganization={onSelectOrganization}
        organizations={organizations}
      />

      {selectedPartnership && <Partnership isFormDisabled={isFormDisabled} partnerships={partnershipOptions} />}

      {organizationId && (
        <>
          {!isBraintrustOrganization(organizationId) && (
            <TutorPay
              isFormDisabled={isFormDisabled}
              selectedPartnership={selectedPartnership}
              defaultTutorPay={defaultTutorPay}
            />
          )}

          {isBraintrustOrganization(organizationId) && <TutorPayKind isFormDisabled={isFormDisabled} />}
          <SessionRate
            isFormDisabled={isFormDisabled}
            label={isBraintrustOrganization(organizationId) ? "Tutor hourly rate" : "Session rate"}
            selectedPartnership={selectedPartnership}
            hasCC={hasCC}
          />
        </>
      )}

      <Button variant="outline-primary" type="submit" disabled={submitting || isFormDisabled}>
        Create
      </Button>
    </>
  )
}

export default FormFields
export { labelSize, colSize }
