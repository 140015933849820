import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import PropTypes from "prop-types"
import React from "react"

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "center",
  color: theme.palette.text.primary,
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  letterSpacing: "-0.04rem",
  fontFamily: "Poppins, sans-serif",
  borderRadius: "12px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)",
  },
}))

const DashboardCard = ({ title, children }) => (
  <Item>
    {title && <h4 className="text-gray-700 mb-2">{title}</h4>}
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        flexDirection: "column",
      }}
    >
      {children}
    </Box>
  </Item>
)

DashboardCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default React.memo(DashboardCard)
