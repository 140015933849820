import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import debounce from "lodash.debounce"
import React, { useEffect, useMemo, useState } from "react"
import { usePagination } from "../../../../hooks/dataGrid"
import useFetchSchoolAdmins from "../../../../hooks/useFetchSchoolAdmins"
import BRTThemeProvider from "../../../../providers/theme"
import { FilterInputs } from "./Filters"
import NoResultsSection from "./NoResults"
import CreateIcon from "@mui/icons-material/Create"

const INITIAL_FILTER = { name: "", email: "", school_name: "" }
const INITIAL_PAGE = 0
const INITIAL_PAGE_SIZE = 10
const DEBOUNCE_DELAY = 500

const commonStyles = {
  border: "none",
  borderRadius: 0,
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
}

const dataGridStyles = {
  ...commonStyles,
  "& .MuiGrid-root": {
    padding: "20px",
    gap: "20px",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #e0e0e0",
    borderRadius: "8px 8px 0 0",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    padding: "0 15px",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "600",
    color: "#3c00f5",
  },
  "& .MuiTablePagination-selectLabel": {
    fontWeight: 500,
    color: "#bbbbbf",
    margin: 0,
  },
  "& .MuiSelect-select-MuiInputBase-input, & div#mui-2": {
    padding: "5px 20px 5px 5px",
    borderRadius: "15px",
    backgroundColor: "#f5f5f5",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#f5f5f5",
  },
  "& .MuiTablePagination-displayedRows": {
    margin: 0,
  },
}

const AccordionStyled = styled(Accordion)(() => ({
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  fontFamily: "'Poppins', sans-serif",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f8f9fa",
}))

const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  height: "48px",
  minHeight: "48px",
}))

const SchoolAdminsTable = () => {
  const { page, pageSize, handlePaginationChange } = usePagination(INITIAL_PAGE, INITIAL_PAGE_SIZE)
  const { schoolAdmins, rowCount, fetchSchoolAdmins, loading, error } = useFetchSchoolAdmins()
  const [filters, setFilters] = useState(INITIAL_FILTER)

  const columns = useMemo(
    () => [
      { field: "name", headerName: "Name", flex: 1, headerClassName: "table-header", resizable: false },
      { field: "email", headerName: "Email", flex: 1, headerClassName: "table-header", resizable: false },
      { field: "school_name", headerName: "School", flex: 1, headerClassName: "table-header", resizable: false },
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: params => [
          <GridActionsCellItem
            key="edit"
            icon={<CreateIcon />}
            label="edit"
            className="textPrimary"
            onClick={() => window.location.replace(`/admin/school_admins/${params.row.id}/edit`)}
            color="primary"
          />,
        ],
      },
    ],
    []
  )

  useEffect(() => {
    const fetch = debounce((filter, page, pageSize) => {
      fetchSchoolAdmins(filter, page, pageSize)
    }, DEBOUNCE_DELAY)
    fetch(filters, page, pageSize)
  }, [filters, page, pageSize, fetchSchoolAdmins])

  const handleFilterChange = e => {
    const { name, value } = e.target
    setFilters(prev => ({ ...prev, [name]: value }))
  }

  const handleResetFilters = () => {
    setFilters(INITIAL_FILTER)
  }

  return (
    <BRTThemeProvider>
      <Box sx={{ height: "100%", width: "100%" }}>
        <AccordionStyled defaultExpanded={false}>
          <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />} aria-controls="filter-content" id="filter-header">
            <Typography variant="subtitle1" fontWeight="700" color="text.secondary">
              Filters
            </Typography>
          </AccordionSummaryStyled>
          <AccordionDetails>
            <FilterInputs
              filter={filters}
              handleFilterChange={handleFilterChange}
              handleResetFilters={handleResetFilters}
            />
          </AccordionDetails>
        </AccordionStyled>
        {error && (
          <Alert severity="error" sx={{ width: "100%" }}>
            Failed to load school admins. Please try again later.
          </Alert>
        )}
        <DataGrid
          rows={schoolAdmins}
          columns={columns}
          rowCount={rowCount}
          pagination
          paginationMode="server"
          paginationModel={{ pageSize, page }}
          onPaginationModelChange={handlePaginationChange}
          pageSizeOptions={[5, 10, 25]}
          filterMode="server"
          onFilterModelChange={handleFilterChange}
          autoHeight
          disableSelectionOnClick
          loading={loading}
          slots={{ noRowsOverlay: NoResultsSection }}
          sx={dataGridStyles}
          aria-label="School Admins Table"
        />
      </Box>
    </BRTThemeProvider>
  )
}

export default SchoolAdminsTable
