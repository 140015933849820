import React, { useState } from "react"
import Opening from "./Opening"
import { serializeGradesFromHierarchy, serializeStates } from "../../../../helpers/serializers"
import { programsHierarchyToList } from "../../../../helpers/programsHierarchy"
import { formatDateKeepZone } from "../../../../helpers/dates"
import { serializeSpecialTreatments } from "../../../../helpers/serializers"
import { useSaveOpeningRequest } from "./hooks"
import { OpeningContext } from "./context"

const Index = ({
  schools,
  hierarchy,
  struggleWithQuestions,
  states,
  timeZone,
  specialTreatments,
  initialData = {},
  redirectUrl,
  canEdit,
  canReopen,
}) => {
  const [opening, setOpening] = useState(() => ({
    id: initialData.id,
    group: initialData.group,
    student: initialData.student ? { title: initialData.student.name, value: initialData.student.id } : null,
    groupIds: initialData.opening_groups_ids || [],
    school: initialData.school,
    grade: initialData.grade,
    defaultProgramId: initialData.default_program_id,
    struggleWith: initialData.struggle_with || [],
    meeting_format: initialData.meeting_format,
    meeting_info: initialData.meeting_info,
    recurrence: initialData.recurrence,
    address_1: initialData.address_1,
    address_2: initialData.address_2,
    city: initialData.city,
    state: initialData.state,
    zip: initialData.zip,
    schedule: initialData.schedule_flexible ? {} : initialData.schedule || {},
    started_at: initialData.started_at ? new Date(initialData.started_at) : undefined,
    ended_at: initialData.ended_at ? new Date(initialData.ended_at) : undefined,
    additionalInformation: initialData.information,
    duration: initialData.duration,
    executive_function: initialData.executive_function,
    special_treatments: initialData.special_treatments,
    additionalEmails: initialData.additional_emails,
    sessionsNotify: initialData.sessions_notify,
    tutor_rate: parseFloat(initialData.tutor_rate),
    school_rate: parseFloat(initialData.school_rate),
    schedule_flexible: initialData.schedule_flexible,
    schedule_preference: initialData.schedule_preference,
    custom_program: initialData.custom_program,
    defaultProgramName: initialData.custom_program ? "Other" : "",
    is_per_hour_rate: initialData.is_per_hour_rate,
    max_sessions: initialData.max_sessions || "",
    max_paid_noshows: initialData.max_paid_noshows || 0,
    max_duration: initialData.max_duration || "",
    ttg_start_at: formatDateKeepZone(initialData.ttg_start_at, "YYYY-MM-DD"),
    ttg_end_at: initialData.ttg_end_at ? formatDateKeepZone(initialData.ttg_end_at, "YYYY-MM-DD") : undefined,
  }))

  const [options] = useState(() => ({
    hierarchy,
    timeZone,
    struggleWithQuestions,
    states: serializeStates(states),
    grades: serializeGradesFromHierarchy(hierarchy),
    programs: programsHierarchyToList(hierarchy),
    specialTreatments: serializeSpecialTreatments(specialTreatments),
  }))

  const { loading, request } = useSaveOpeningRequest({
    id: initialData.id,
    onSuccess: () => {
      window.location.assign(redirectUrl)
    },
  })

  const updateOpening = data => {
    setOpening({ ...opening, ...data })
  }

  const submit = () => {
    const requestData = {
      group: opening.group, //Bool
      meeting_format: opening.meeting_format,
      meeting_info: opening.meeting_info,
      recurrence: opening.recurrence, //Bool
      ...(opening.recurrence
        ? {
            schedule: opening.schedule, //Json
          }
        : null),
      duration: opening.duration ? parseInt(opening.duration, 10) : void 0, //Int
      grade: opening.grade,
      ...(opening.group ? { opening_groups_ids: opening.groupIds } : {}), //Int[]
      student_id: opening.student ? parseInt(opening.student.value) : void 0, //Int
      struggle_with: opening.struggleWith, //Int[]
      default_program_id: opening.defaultProgramId, //Int
      started_at: opening.started_at,
      ended_at: opening.recurrence || opening.schedule_flexible ? opening.ended_at : null,
      special_treatments: opening.special_treatments,
      executive_function: opening.executive_function,
      information: opening.additionalInformation,
      additional_emails: opening.additionalEmails,
      sessions_notify: opening.sessionsNotify,
      tutor_rate: opening.tutor_rate,
      school_rate: opening.school_rate,
      address_1: opening.address_1,
      address_2: opening.address_2,
      city: opening.city,
      state: opening.state,
      zip: opening.zip,
      schedule_flexible: opening.schedule_flexible,
      schedule_preference: opening.schedule_flexible ? opening.schedule_preference : null,
      custom_program: opening.custom_program,
      is_per_hour_rate: opening.is_per_hour_rate,
      max_sessions: opening.max_sessions || null,
      max_paid_noshows: opening.max_paid_noshows || 0,
      max_duration: opening.max_duration || null,
      ttg_start_at: formatDateKeepZone(opening.ttg_start_at, "YYYY-MM-DD"),
      ttg_end_at: opening.ttg_end_at ? formatDateKeepZone(opening.ttg_end_at, "YYYY-MM-DD") : opening.ttg_end_at,
    }
    request({ opening: requestData })
  }

  const openingContextValue = {
    opening,
    options,
    canEdit,
    canReopen,
    openingGroups: initialData.opening_groups,
    setOpening: updateOpening,
    tutorApplications: initialData.tutor_applications || [],
  }

  return (
    <OpeningContext.Provider value={openingContextValue}>
      <Opening schools={schools} onSubmit={submit} submitting={loading} />
    </OpeningContext.Provider>
  )
}

export default Index
