import React, { useContext } from "react"
import styled from "styled-components"
import { ReactComponent as AlarmIcon } from "../../../assets/images/alarm.svg"
import { DataContext } from "../../../contexts"

const InfoBlock = styled.div`
  background-color: rgba(255, 138, 73, 0.2);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  fill: rgb(240, 35, 14);
`

const Text = styled.div`
  color: #333;
  font-size: 16px;
  font-weight: bold;
`

export const UserDashboardContent = () => {
  const { dashboard } = useContext(DataContext)
  if (!dashboard || dashboard.overdue.count === 0) return null

  return (
    <InfoBlock>
      <IconWrapper>
        <AlarmIcon />
      </IconWrapper>
      <Text>
        You have <a href={dashboard.overdue.path}>{dashboard.overdue.count} overdue sessions</a>
      </Text>
    </InfoBlock>
  )
}
