import React from "react"
import ReactRating from "react-rating"

import { ReactComponent as StarIcon } from "../../assets/images/star.svg"

const Rating = ({ value, starSize = 18, readonly, showAllStars = true, onChange }) => {
  if (readonly) {
    return (
      <div className="rating">
        {!!value && (
          <>
            {showAllStars ? (
              <ReactRating
                readonly
                initialRating={value}
                className="rating_stars"
                emptySymbol={<StarIcon width={starSize} height={starSize} className="rating_star" />}
                fullSymbol={<StarIcon width={starSize} height={starSize} className="rating_star filled" />}
              />
            ) : (
              <StarIcon width={starSize} height={starSize} className="rating_star filled" />
            )}
            <span className="rating_value">{Math.round(value * 10) / 10}</span>
          </>
        )}
        {!value && <div className="rating_placeholder">No reviews yet</div>}
      </div>
    )
  }

  return (
    <div className="rating with-labels">
      <ReactRating
        initialRating={value}
        className="rating_stars"
        emptySymbol={<StarIcon width={starSize} height={starSize} className="rating_star" />}
        fullSymbol={<StarIcon width={starSize} height={starSize} className="rating_star filled" />}
        onChange={onChange}
      />
      <div className="rating_labels">
        <div>Poor</div>
        <div>Excellent</div>
      </div>
    </div>
  )
}

export default Rating
