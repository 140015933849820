import React, { useState } from "react"
import apiClient, { showErrorMessage } from "../../../common/apiClient"
import Status from "./status"
import { Row, Col } from "react-bootstrap"
import TutorCard from "../../find_tutor/tutor_card/TutorCard"

const InviteParent = ({ invitation, tutor, urls, hierarchy }) => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(invitation.status)

  const onAccept = studentId => {
    setLoading(true)
    apiClient
      .put(urls.accept, { id: invitation.id, student_id: studentId })
      .then(() => {
        setStatus("accepted")
      })
      .catch(showErrorMessage)
      .finally(() => {
        setLoading(false)
      })
  }

  const onDecline = () => {
    setLoading(true)
    apiClient
      .put(urls.decline, { id: invitation.id })
      .then(() => {
        setStatus("declined")
      })
      .catch(showErrorMessage)
      .finally(() => {
        setLoading(false)
      })
  }

  const hasSameName =
    invitation.parent.students.findIndex(
      el =>
        invitation.student_first_name &&
        invitation.student_last_name &&
        el.first_name &&
        el.last_name &&
        invitation.student_first_name.toLowerCase() === el.first_name.toLowerCase() &&
        invitation.student_last_name.toLowerCase() === el.last_name.toLowerCase()
    ) > -1

  return (
    <>
      <Row>
        <Col lg={16}>
          <p>Tutor wants to add you as a parent of their student</p>
        </Col>
      </Row>

      <TutorCard className="col-lg-16" tutor={tutor} options={{ hierarchy: hierarchy }} />

      <Row>
        <Col lg={8}>
          <p>Student information</p>
          <p className="font-weight-bold">{invitation.student_info}</p>
        </Col>
        <Col lg={8}>
          <p>Tutor notes</p>
          <p className="font-weight-bold">{invitation.note}</p>
        </Col>
      </Row>

      <Status
        status={status}
        students={invitation.parent.students}
        onAccept={onAccept}
        onDecline={onDecline}
        loading={loading}
        disabledIds={invitation.linked_students_ids}
        hasSameName={hasSameName}
      />
    </>
  )
}
export default InviteParent
