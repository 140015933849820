import React, { useState } from "react"
import { clearFieldData, setFieldData } from "../../helpers/forms"
import SessionSchedule from "../schedule_session/SessionSchedule"
import { Form } from "react-final-form"

const InlineGroupSessionDetails = ({ init, timeZone, labelClass, disableRecurrence = false }) => {
  const [gs, setGs] = useState(init)

  const onScheduleChange = values => {
    setGs(values)
  }

  return (
    <React.Fragment>
      <input type="hidden" name="group_session[recurrence]" value={Boolean(gs.recurrence)} />
      <input type="hidden" name="group_session[started_at]" value={gs.started_at || ""} />
      <input type="hidden" name="group_session[ended_at]" value={gs.ended_at || ""} />
      <input type="hidden" name="group_session[duration]" value={gs.duration || ""} />
      <input type="hidden" name="group_session[timetable]" value={JSON.stringify(gs.timetable || {})} />

      <Form initialValues={init} mutators={{ setFieldData, clearFieldData }} onSubmit={onScheduleChange}>
        {({ form }) => {
          return (
            <React.Fragment>
              <SessionSchedule
                timeZone={timeZone}
                onChange={() => form.submit()}
                labelClass={labelClass}
                disableRecurrence={disableRecurrence}
              />
            </React.Fragment>
          )
        }}
      </Form>
    </React.Fragment>
  )
}

export default InlineGroupSessionDetails
