import { LearningLevel } from "../../../../constants"
import React, { useMemo } from "react"
import { Col, Row } from "react-bootstrap"

export const ProgramName = ({ opening }) => {
  const learning_level = useMemo(() => LearningLevel.find(({ key }) => key === opening.learning_level), [opening])

  return (
    <Row as="h6" className="mb-0 font-weight-semibold">
      <Col>
        {learning_level.name}, {opening.program.name}
      </Col>
    </Row>
  )
}
