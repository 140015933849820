import React from "react"
import { Pagination } from "react-bootstrap"
import { addQueryStringParams, getQueryStringOmitParams } from "../../common/searchOptions"
import ServerSideRendering from "../../common/serverSideRendering"

const FIRST_PAGE = "FIRST"
const PREV_PAGE = "PREV"
const NEXT_PAGE = "NEXT"
const LAST_PAGE = "LAST"

const { window } = ServerSideRendering.wrapWindow()

const range = (start, end) => {
  const result = []
  for (let i = start; i <= end; i++) {
    result.push(i)
  }
  return result
}

const BasePagination = ({ currentUrl, count = 0, limit = 10, currentPage = 1, siblingCount = 3, onPageChanged }) => {
  const totalPages = Math.ceil(count / limit)

  const href = window.location.href || currentUrl
  const urlWithoutQuery = href.split("?")[0]
  const queryString = getQueryStringOmitParams(["page"], ["zip"], href.split("?")[1])

  const createPageHref = page => `${urlWithoutQuery}${addQueryStringParams({ page }, ["zip"], queryString)}`

  const goToPage = page => {
    onPageChanged(Math.max(1, Math.min(page, totalPages)))
  }

  const prevPage = () => {
    goToPage(currentPage - 1)
  }

  const nextPage = () => {
    goToPage(currentPage + 1)
  }

  const getPageNumbers = () => {
    const totalNumbers = siblingCount * 2 + 1
    const totalBlocks = totalNumbers + 4

    if (totalPages > totalBlocks) {
      let pages = []

      const leftBound = currentPage - siblingCount
      const rightBound = currentPage + siblingCount

      const startPage = leftBound > 1 ? leftBound : 1
      const endPage = rightBound < totalPages ? rightBound : totalPages

      pages = range(startPage, endPage)

      const pagesCount = pages.length
      const singleSpillOffset = totalNumbers - pagesCount - 1

      const leftSpill = startPage > 1
      const rightSpill = endPage < totalPages

      if (leftSpill && !rightSpill) {
        const extraPages = range(startPage - singleSpillOffset, startPage - 1)
        return [FIRST_PAGE, PREV_PAGE, ...extraPages, ...pages]
      } else if (!leftSpill && rightSpill) {
        const extraPages = range(endPage + 1, endPage + singleSpillOffset)
        return [...pages, ...extraPages, NEXT_PAGE, LAST_PAGE]
      } else if (leftSpill && rightSpill) {
        return [FIRST_PAGE, PREV_PAGE, ...pages, NEXT_PAGE, LAST_PAGE]
      }
    }

    return range(1, totalPages)
  }

  const pageNumbers = getPageNumbers()

  if (pageNumbers.length < 2) {
    return null
  }

  return (
    <Pagination className="pagy-bootstrap-nav">
      {pageNumbers.map((page, i) => {
        if (page === FIRST_PAGE) {
          return (
            <Pagination.First
              key="first"
              href={createPageHref(1)}
              className="prev text-nowrap"
              onClick={() => goToPage(1)}
            >
              &lt;&lt; First
            </Pagination.First>
          )
        }
        if (page === PREV_PAGE) {
          return (
            <Pagination.Prev
              key="prev"
              href={createPageHref(currentPage - 1)}
              className="prev text-nowrap"
              onClick={prevPage}
            >
              &lt; PREV
            </Pagination.Prev>
          )
        }
        if (page === NEXT_PAGE) {
          return (
            <Pagination.Next
              key="next"
              href={createPageHref(currentPage + 1)}
              className="next text-nowrap"
              onClick={nextPage}
            >
              NEXT &gt;
            </Pagination.Next>
          )
        }
        if (page === LAST_PAGE) {
          return (
            <Pagination.Last
              key="last"
              href={createPageHref(totalPages)}
              className="next text-nowrap"
              onClick={() => goToPage(totalPages)}
            >
              LAST &gt;&gt;
            </Pagination.Last>
          )
        }
        return (
          <Pagination.Item
            href={createPageHref(page)}
            key={i}
            active={currentPage === page}
            onClick={() => goToPage(page)}
          >
            {page}
          </Pagination.Item>
        )
      })}
    </Pagination>
  )
}

export default BasePagination
