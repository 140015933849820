import { DataContext, DataContextActions, DomainTransactionContext } from "@/contexts"
import React, { useCallback, useContext, useRef, useState } from "react"

export const DomainTransactionContextProvider = ({ children }) => {
  const [prevState, setPrevState] = useState({})
  const store = useContext(DataContext)
  const { setPartialState } = useContext(DataContextActions)
  const parentContext = useContext(DomainTransactionContext)
  const stateRef = useRef(new Map())

  const start = useCallback(
    id => {
      setPrevState(store)
      let counter = 1
      let list = []
      if (stateRef.current.has(id)) {
        const [savedCounter, savedList] = stateRef.current.get(id)
        counter = savedCounter + 1
        list = savedList
      }
      stateRef.current.set(id, [counter, list])
    },
    [store]
  )

  const add = useCallback(
    async (id, fn, commitConfig) => {
      // eslint-disable-next-line no-console
      if (!stateRef.current.has(id)) return void console.warn("Transaction not found")

      const [counter, list] = stateRef.current.get(id)
      list.push(fn)
      stateRef.current.set(id, [counter, list])

      await commit(id, commitConfig)
    },
    [commit]
  )

  const rollback = useCallback(
    id => {
      setPartialState(prevState)
      setPrevState({})
      stateRef.current.set(id, [0, []])
    },
    [prevState, setPartialState]
  )

  const destroy = useCallback(id => {
    stateRef.current.delete(id)
  }, [])

  const commit = useCallback(
    async (id, config = {}) => {
      const { manualCommitControl = false, destroyAfterCommit = true, force = false } = config
      // eslint-disable-next-line no-console
      if (!stateRef.current.has(id)) return void console.warn("Transaction not found")

      const [counter, list] = stateRef.current.get(id)
      if (force || counter === list.length) {
        // eslint-disable-next-line no-console
        if (!list.every(fn => typeof fn === "function")) return void console.warn("Transaction should be a function")
        try {
          if (!manualCommitControl) await Promise.all(list.map(async fn => await fn()))
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("ROLLBACK: Some of transaction commits couldn't be finished.", e.message)
          rollback()
        } finally {
          if (destroyAfterCommit) destroy(id)
        }
      }
    },
    [destroy, rollback]
  )

  const buildTransaction = useCallback(
    (config = {}) => {
      const { name = Symbol(), manualCommitControl: commitControl, ...rest } = config
      return Object.freeze({
        start: () => start(name),
        add: fn => add(name, fn, rest),
        rollback: () => rollback(name),
        destroy: () => destroy(name),
        commit: (config = {}) => {
          const { manualCommitControl = !commitControl, ...restConfig } = config
          commit(name, {
            ...rest,
            ...restConfig,
            manualCommitControl,
          })
        },
      })
    },
    [add, commit, destroy, rollback, start]
  )

  const value = Object.keys(parentContext).length ? parentContext : { buildTransaction }

  return <DomainTransactionContext.Provider value={value}>{children}</DomainTransactionContext.Provider>
}
