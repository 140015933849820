import React from "react"
import { Field } from "react-final-form"

import SingleChoiceDropdown from "../../blocks/SingleChoiceDropdown"
import { getLabelByValue } from "../../../helpers/presentational"

const WizardGradeStep = ({ options: { grades }, placeholder = "Pick a grade", onSubmit }) => {
  return (
    <Field name="grade">
      {({ input }) => (
        <SingleChoiceDropdown
          {...input}
          options={grades}
          buttonText={getLabelByValue(grades)(input.value) || placeholder}
          selected={!!input.value}
          onChange={value => {
            input.onChange(value)
            onSubmit()
          }}
        />
      )}
    </Field>
  )
}

export default WizardGradeStep
