import React, { useContext } from "react"
import { useSearchParams } from "react-router-dom"
import { ExistingScheduleDetector } from "./existing_schedule_detector"
import { ScheduleFormProvider } from "../../providers/schedule_form_providers"
import { useCreateInitialValues, validateScheduleForm } from "./utils"
import { ScheduleForm } from "./form"
import { DataContext } from "../../../../contexts"
import { createSchedule } from "../../configurations/domain"

const CreateScheduleFormContent = ({ onSubmit, onCancel, loading }) => {
  const [searchParams] = useSearchParams()
  const { existingSchedule } = useContext(DataContext)

  const { initialValues, loading: initialValuesLoading } = useCreateInitialValues(
    [],
    searchParams.get("school_id"),
    searchParams.get("educatable_id")
  )

  return (
    <>
      {(loading || initialValuesLoading) && (
        <div className="spinner-wrapper loading">
          <div className="spinner-border text-primary" />
        </div>
      )}
      <ScheduleForm
        multipleValues={{}}
        initialValues={initialValues}
        validate={validateScheduleForm({ type: "create" })}
        loading={loading || initialValuesLoading}
        disabled={Boolean(existingSchedule)}
        type="create"
        onSubmit={onSubmit}
        onCancel={onCancel}
      >
        <ExistingScheduleDetector />
      </ScheduleForm>
    </>
  )
}

export const CreateScheduleForm = props => {
  return (
    <ScheduleFormProvider configs={createSchedule}>
      <CreateScheduleFormContent {...props} />
    </ScheduleFormProvider>
  )
}
