import React from "react"

export const columns = [
  {
    field: "name",
    headerName: "Student name",
    type: "custom",
    component: ({ row }) => {
      const name = row.name ?? `${row.first_name} ${row.last_name}`
      let content
      if (row.profile_url) {
        content = (
          <a href={row.profile_url} target="_blank" rel="noreferrer">
            {name}
          </a>
        )
      } else {
        content = name
      }

      return <td className="font-weight-medium">{content}</td>
    },
  },
  {
    field: "grade",
    headerName: "Grade",
    type: "string",
    className: "font-size-small font-weight-medium",
  },
  {
    field: "special_treatments",
    headerName: "Learning difference",
    type: "custom",
    component: ({ row }) => (
      <td>
        {row.special_treatments ? (
          <span className="font-size-small font-weight-medium">{row.special_treatments}</span>
        ) : (
          <span className="font-size-small text-gray-chat">—</span>
        )}
      </td>
    ),
  },
  {
    field: "program_progress_url",
    headerName: "",
    type: "custom",
    component: ({ row }) => {
      if (row.program_progress_url) {
        return (
          <td>
            <a href={row.program_progress_url} target="_blank" rel="noreferrer">
              Overall progress
            </a>
          </td>
        )
      }

      return (
        <td>
          <span>No progress yet</span>
        </td>
      )
    },
  },
]
