import React from "react"
import { Link } from "react-router-dom"
import NavbarLinks from "../navbar_links"
import CouponDropdown from "./CouponDropdown"
import UserInfo from "./UserInfo"

const Header = ({ currentUser }) => {
  if (!currentUser) return null

  return (
    <header className="header">
      <div className="container header_container">
        <Link to="/" className="header_logo anl-btn-menu" data-turbolinks="false">
          <img src="logo.svg" alt="Logo" />
        </Link>

        <div className="header_nav">
          <nav className="main-nav">
            <NavbarLinks currentUser={currentUser} />
          </nav>
        </div>

        <div className="header_right-col">
          {currentUser.parent && currentUser.coupon && (
            <div className="header_discount">
              <CouponDropdown active={currentUser.coupon?.active_coupon} info={currentUser.coupon?.coupon_info} />
            </div>
          )}
          <div className="header_user-info">
            <div className="dropdown">
              <button
                className="btn-clear js-class-toggle"
                type="button"
                data-target="#user-dropdown"
                data-class="show"
                data-test="profile-navigation"
                aria-label="Toggle user dropdown"
              >
                <UserInfo />
              </button>
              <div
                className="dropdown-menu dropdown-menu-right js-onclick-outside"
                id="user-dropdown"
                data-class-remove="show"
              >
                <Link to={`/profile/${currentUser.id}`} className="dropdown-item anl-btn-menu">
                  Profile
                </Link>
                {currentUser.parent && (
                  <Link to="/parent_invitations" className="dropdown-item anl-btn-menu">
                    Invitations
                    {currentUser.invited_by_tutors.with_status("pending").exists && <span className="red-dot"></span>}
                  </Link>
                )}
                <Link to="/sign_out" data-method="delete" className="dropdown-item anl-btn-menu" data-test="sign-out">
                  Sign Out
                </Link>
              </div>
            </div>
          </div>

          <div className="mobile-nav" id="mobile-nav">
            <button
              className="mobile-nav_opener js-class-toggle"
              type="button"
              data-target="#mobile-nav"
              data-class="-show"
              aria-label="Open mobile navigation"
            ></button>
            <button
              className="mobile-nav_closer js-class-toggle"
              type="button"
              data-target="#mobile-nav"
              data-class="-show"
              aria-label="Close mobile navigation"
            ></button>

            <div className="mobile-nav_menu-wrapper">
              <Link to="/" className="mobile-nav_logo anl-btn-menu" data-test="profile-navigation">
                <img src="logo.svg" alt="Logo" />
              </Link>
              <nav className="mobile-nav_links">
                <NavbarLinks currentUser={currentUser} />
              </nav>

              <div className="mobile-nav_profile">
                <Link to={`/profile/${currentUser.id}`} className="anl-btn-menu">
                  <UserInfo />
                </Link>
                <Link to="/sign_out" data-method="delete" className="mobile-nav_sign-out anl-btn-menu">
                  Sign Out
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
