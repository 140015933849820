import React from "react"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import { includes } from "lodash"
import classNames from "classnames"
import { Col, Row } from "react-bootstrap"
import { deleteId, requiredClass } from "../../../helpers/profile_form"
import { notEmptyArray, required } from "../../../helpers/validators"
import SchoolSelect from "./SchoolSelect"
import FormRow from "../../../components/blocks/FormRow"
import RowWarning from "../../blocks/RowWarning"

import { ReactComponent as Delete } from "../../../assets/images/delete.svg"

const formRowProps = {
  LabelProps: { sm: 24, lg: 6, className: "v-2" },
  ColProps: { sm: 24, lg: 18, className: "mb-0" },
  RowProps: {},
}

const CheckboxGroup = ({ fields, options, meta }) => {
  const toggle = (event, option) => {
    if (event.target.checked) {
      fields.push(option)
    } else {
      fields.remove(fields.value.indexOf(option))
    }
  }

  const isChecked = (option, fields) => {
    return includes(fields.value, option[1])
  }

  return (
    <div className="py-2">
      {options.map((option, index) => (
        <div key={`${fields.name}-${option[1]}`} className={index !== options.length - 1 ? "mb-2" : ""}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className={classNames({ "custom-control-input": true, "is-invalid": !!meta.error && meta.touched })}
              id={`tutored-from-${fields.name}-${option[0]}`}
              onChange={event => toggle(event, option[1])}
              checked={isChecked(option, fields)}
            />
            <label className="custom-control-label" htmlFor={`tutored-from-${fields.name}-${option[0]}`}>
              {option[0]}
            </label>
          </div>
        </div>
      ))}
    </div>
  )
}

const Tutoring = ({ name, index, fields, schoolUrl, divisions }) => {
  return (
    <>
      <RowWarning className="panel_body-warning" name={["tutoring_histories", index, "school"]}>
        <FormRow label="School" {...formRowProps} required className="mb-4">
          <Row>
            <Col lg={18}>
              <Row className="align-items-center">
                <Col xs={22}>
                  <Field name={`${name}.school`} validate={required}>
                    {({ input, meta }) => (
                      <SchoolSelect
                        className={`form-control v-2 ${requiredClass(meta)}`}
                        placeholder="Start typing school name"
                        source={schoolUrl}
                        initValue={input.value}
                        onChange={el => {
                          input.onChange(el)
                        }}
                      />
                    )}
                  </Field>
                </Col>
                <Col xs={2}>
                  <button
                    type="button"
                    className="icon-button -hover-red"
                    onClick={() => {
                      deleteId(fields, index)
                    }}
                  >
                    <div className="icon-20-px">
                      <Delete />
                    </div>
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </FormRow>
      </RowWarning>

      <RowWarning className="panel_body-warning" name={["tutoring_histories", index, "divisions"]}>
        <FormRow label="Division" {...formRowProps} required className="mb-4">
          <FieldArray name={`${name}.divisions`} validate={notEmptyArray}>
            {({ fields, meta }) => {
              return <CheckboxGroup fields={fields} options={divisions} meta={meta} />
            }}
          </FieldArray>
        </FormRow>
      </RowWarning>

      {index !== fields.length - 1 && <hr />}
    </>
  )
}

export default Tutoring
