import React from "react"
import { Modal, Button } from "react-bootstrap"

const ReviewSuccessModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="borderless px-4 pt-4 pb-2" closeButton />
      <Modal.Body className="px-5 pb-5">
        <div className="d-flex flex-column align-items-center">
          <h1 className="text-primary display-4 font-weight-bold mb-5">Great!</h1>
          <h5 className="font-weight-bold rule-height text-center pb-5">Thank you for your feedback!</h5>
          <Button variant="outline-primary" className="mt-2" onClick={handleClose}>
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ReviewSuccessModal
