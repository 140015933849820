import PersonIcon from "@mui/icons-material/Person"
import { CardContent, CardHeader, ListItemText, Typography } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import ProgramDetails from "../ProgramDetails"
import {
  StyledAvatar,
  StyledButton,
  StyledCard,
  StyledList,
  StyledListItem,
  StyledListItemAvatar,
} from "../StyledComponents"

const StudentList = ({ students }) => (
  <StyledList>
    {students.map(({ student_name }) => (
      <StyledListItem key={student_name} divider>
        <StyledListItemAvatar>
          <StyledAvatar sx={{ width: 24, height: 24 }}>
            <PersonIcon />
          </StyledAvatar>
        </StyledListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="body1" color="text.primary" sx={{ fontWeight: 600 }}>
              {student_name}
            </Typography>
          }
        />
      </StyledListItem>
    ))}
  </StyledList>
)

StudentList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      student_name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export const StudentAdministeredTutorView = ({ data }) => (
  <StyledCard>
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      {data.assignments?.student_administered?.length > 0 ? (
        <>
          <Typography variant="h6" color="text.primary" sx={{ mb: 2, fontWeight: 500 }}>
            Complete assessment for:
          </Typography>
          <StudentList students={data.assignments.student_administered} />
        </>
      ) : (
        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 500 }}>
          No assessments available at this time.
        </Typography>
      )}
    </CardContent>
  </StyledCard>
)

StudentAdministeredTutorView.propTypes = {
  data: PropTypes.shape({
    program_name: PropTypes.string.isRequired,
    assignment_name: PropTypes.string,
    assignments: PropTypes.shape({
      student_administered: PropTypes.arrayOf(
        PropTypes.shape({
          student_name: PropTypes.string.isRequired,
        })
      ),
    }),
  }).isRequired,
}

export const StudentAdministeredStudentView = ({ data }) => (
  <StyledCard>
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      {data.assignment_url ? (
        <StyledButton
          onClick={() => window.open(data.assignment_url, "_blank", "noopener,noreferrer")}
          variant="contained"
          aria-label="Take Assessment"
          disableElevation
        >
          Take Assessment
        </StyledButton>
      ) : (
        <Typography variant="body1" color="text.secondary" sx={{ fontWeight: "600" }}>
          No assessment available at this time.
        </Typography>
      )}
    </CardContent>
  </StyledCard>
)

StudentAdministeredStudentView.propTypes = {
  data: PropTypes.shape({
    program_name: PropTypes.string.isRequired,
    assignment_name: PropTypes.string,
    assignment_url: PropTypes.string,
  }).isRequired,
}
