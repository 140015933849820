import React from "react"
import { TutorCardApplicationContext } from "../../../contexts"
import { TutorCard } from "./tutor_card"

export const ApplicationsList = ({ applications }) =>
  applications.map(application => (
    <React.Fragment key={application.id}>
      <hr className="my-0 soft-color" />
      <TutorCardApplicationContext.Provider value={application}>
        <TutorCard />
      </TutorCardApplicationContext.Provider>
    </React.Fragment>
  ))
