import React, { useState } from "react"
import Alerter from "../common/alerter"

const CopyText = ({ label, textToCopy, className }) => {
  const [visible, setVisible] = useState(true)

  const onClick = e => {
    e.preventDefault()

    let textField = document.createElement("textarea")
    textField.innerText = textToCopy
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()

    Alerter.success("Link copied to clipboard")
    setVisible(false)
    setTimeout(() => {
      setVisible(true)
    }, 2000)
  }

  return (
    <span className={className}>
      <a className="cursor-pointer" onClick={onClick}>
        {label}&nbsp;
      </a>
      <a className={`copy-icon cursor-pointer ${visible ? "" : "fade"}`} onClick={onClick}></a>
    </span>
  )
}

export default CopyText
