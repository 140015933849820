import React from "react"
import { Col } from "react-bootstrap"
import FormRow from "../../../blocks/FormRow"

const rowProps = { className: "align-items-center" }
const colProps = { className: "mb-0" }

export const EditableAdditionalInformation = ({ opening, updateEmails }) => {
  return (
    <Col xl={17} className="pb-2">
      <FormRow RowProps={rowProps} ColProps={colProps} label="School rate">
        {opening.school_rate}
      </FormRow>
      {opening.max_sessions && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Max amount of sessions">
          {opening.max_sessions}
        </FormRow>
      )}
      {opening?.max_duration && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Max duration, minutes">
          {opening.max_duration}
        </FormRow>
      )}
      <FormRow RowProps={rowProps} ColProps={colProps} label="Assignment lifetime">
        {opening.ttg_start_at}
        {opening.ttg_end_at ? ` — ${opening.ttg_end_at}` : ""}
      </FormRow>
      {opening.additionalInformation && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Additional information">
          {opening.additionalInformation}
        </FormRow>
      )}
      <FormRow RowProps={rowProps} ColProps={colProps} label="Reports are sent to">
        <input
          type="text"
          className="form-control fullwidth"
          placeholder="Enter emails separated with a comma"
          value={opening.additionalEmails}
          onChange={({ target: { value } }) => updateEmails(value)}
        />
      </FormRow>
      {opening.sessionsNotify && (
        <FormRow RowProps={rowProps} ColProps={colProps} label="Reports are sent to">
          Parents are notified about the upcoming sessions
        </FormRow>
      )}
    </Col>
  )
}
