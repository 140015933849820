import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown"
import {
  Alert,
  Box,
  Card,
  Container,
  Drawer,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { keyframes, styled } from "@mui/material/styles"
import Grid2 from "@mui/material/Unstable_Grid2"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { NoSession } from "../students/dashboard/hero_section/NoSession"
import ResourcesComponent from "./Resources"

// Keyframe for loading animation
const pulsate = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.8;
  }
`

// Styled Component for the iframe
const IframeContainer = styled("iframe")(({ theme }) => ({
  height: "100vh",
  width: "100%",
  border: "none",
  borderRadius: theme.shape.borderRadius,
}))

// Styled Card for loading state
const StyledLoadingCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "80vh",
  backgroundColor: "#e6e4f1",
}))

// ClassroomIframe Component
const ClassroomIframe = React.memo(({ classroomLink }) => (
  <IframeContainer
    src={classroomLink}
    title="LessonSpace Session"
    allow="camera; microphone; display-capture; autoplay"
    allowFullScreen
    aria-label="LessonSpace Classroom Session"
  />
))
ClassroomIframe.displayName = "ClassroomIframe"

// LessonSpaceContent Component
const LessonSpaceContent = React.memo(({ loading, classroomLink }) => {
  if (loading) {
    return (
      <StyledLoadingCard aria-busy="true" aria-live="polite">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              mb: 1,
              textAlign: "center",
              animation: `${pulsate} 2s infinite`,
              transition: "transform 0.2s ease-in-out, opacity 0.2s ease-in-out",
            }}
            aria-label="Loading LessonSpace Classroom"
          >
            Loading Classroom
          </Typography>
          <LinearProgress sx={{ width: "100%" }} />
        </Box>
      </StyledLoadingCard>
    )
  }

  if (!classroomLink) {
    return (
      <StyledLoadingCard>
        <NoSession message="There is no LessonSpace session found" />
      </StyledLoadingCard>
    )
  }

  return <ClassroomIframe classroomLink={classroomLink} />
})

LessonSpaceContent.displayName = "LessonSpaceContent"

// ClassroomContainer Component
const ClassroomContainer = React.memo(({ loading, error, classroomLink, data, currentUser, isCurriculumProgram }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const isLargerThanTablet = useMediaQuery("(min-width:769px)")

  const toggleDrawer = open => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return
    }
    setDrawerOpen(open)
  }

  return (
    <Container
      sx={{
        flexGrow: 1,
        padding: "0 20px",
        margin: "unset",
      }}
      maxWidth="100%"
      disableGutters
    >
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} role="alert">
          {error}
        </Alert>
      )}

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          keepMounted: true,
          hideBackdrop: true,
        }}
        PaperProps={{
          sx: {
            mt: "120px",
            ml: "5px",
            height: "100vh",
            borderRadius: "10px",
          },
        }}
      >
        <div style={{ width: 400, p: 2 }}>
          <ResourcesComponent loading={loading} data={data} userRole={currentUser.role} />
          <Tooltip title="Click to hide">
            <IconButton
              onClick={toggleDrawer(false)}
              sx={{
                color: "#3c00f5",
                position: "absolute",
                top: "20px",
                right: 0,
              }}
            >
              <ExpandCircleDownIcon sx={{ transform: "rotate(90deg)", fontSize: "30px" }} />
            </IconButton>
          </Tooltip>
        </div>
      </Drawer>

      <Grid2 container spacing={3} justifyContent={!classroomLink ? "center" : undefined}>
        <Grid2
          item
          xs={12}
          md={isCurriculumProgram ? 12 : 12}
          sx={{
            minWidth: isLargerThanTablet ? "800px" : "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          {!drawerOpen && (
            <ExpandButton
              toggleDrawer={toggleDrawer}
              tooltipText="Click to display lesson resources"
              transform="rotate(-90deg)"
            />
          )}
          <LessonSpaceContent loading={loading} classroomLink={classroomLink} />
        </Grid2>
      </Grid2>
    </Container>
  )
})

export const ExpandButton = ({ toggleDrawer, tooltipText, placement = "right", transform = "rotate(-90deg)" }) => (
  <Tooltip title={tooltipText} placement={placement}>
    <IconButton
      onClick={toggleDrawer(true)}
      sx={{
        color: "#3c00f5",
      }}
    >
      <ExpandCircleDownIcon sx={{ transform, fontSize: "30px" }} />
    </IconButton>
  </Tooltip>
)

ClassroomContainer.displayName = "ClassroomContainer"

ClassroomContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  classroomLink: PropTypes.string,
  data: PropTypes.object,
  userRole: PropTypes.oneOf(["admin", "tutor", "student"]).isRequired,
  isCurriculumProgram: PropTypes.bool,
}

ClassroomContainer.defaultProps = {
  error: null,
  classroomLink: null,
  data: null,
  isCurriculumProgram: false,
}

export default React.memo(ClassroomContainer)
