import React, { useCallback, useContext } from "react"
import { EditScheduleForm } from "../forms"
import { QueryContext } from "../../../contexts"
import { getSchedule, updateSchedule } from "../configurations/domain"
import { Entities } from "../../../constants"
import Dialog from "../../blocks/Dialog"

export const EditDialog = ({ schedule, show, onHide }) => {
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(updateSchedule)

  const submit = useCallback(
    values => {
      request({
        entitiesIds: {
          [Entities.Schedules]: schedule.id,
        },
        data: {
          // school_id and educatable_id are not editable
          schedule: Object.fromEntries(
            Object.entries(values).filter(([key]) => key !== "school_id" && key !== "educatable_id")
          ),
        },
        onSuccess: onHide,
        next: [getSchedule],
      })
    },
    [schedule, request, onHide]
  )

  return (
    <Dialog
      title={schedule ? `Schedule settings ${schedule.educatable.name}, ${schedule.school.name}` : null}
      open={show}
      onClose={onHide}
      size="lg"
    >
      <div className="mt-4">
        <EditScheduleForm onSubmit={submit} onCancel={onHide} schedules={[schedule]} loading={loading} />
      </div>
    </Dialog>
  )
}
