import React from "react"
import { Button, Card, Row, Col } from "react-bootstrap"
import cn from "classnames"
import Linkify from "linkifyjs/react"

import {
  // getFullName,
  getPrivateFullName,
  getInitials,
  getTutorSpecializationsText,
  preserveNewlines,
} from "../../../helpers/presentational"
import InterviewSlot from "../schedule_interview/InterviewSlot"
import Avatar from "../../blocks/Avatar"
import ExpandCollapse from "../../blocks/ExpandCollapse"

const ParentInterviewCard = ({ interview, focused, hierarchy, readonly, onCancel }) => {
  const { tutor } = interview
  const tutorName = getPrivateFullName(tutor)
  const tutorInitials = getInitials(tutor)

  const specializationsText = getTutorSpecializationsText(tutor.programs, { hierarchy })

  return (
    <Card className={cn("card-outline mb-4", focused && "focused")} id={interview.id} body>
      <InterviewSlot start={interview.started_at} />

      <Row className="d-flex flex-column flex-md-row my-4">
        <Col className="w-180-px" lg="auto">
          <Avatar initials={tutorInitials} photoUrl={tutor.profile.photo_url} />
        </Col>
        <Col className="flex-grow-1 mt-3 mt-md-0">
          <h5 className="tutor-card_name">{tutorName}</h5>
          <div className="tutor-card_specializations mb-3">{specializationsText}</div>
          <a href={tutor.profile_link} target="_blank" rel="noreferrer">
            Full profile
          </a>
        </Col>
      </Row>

      {interview.call_details && (
        <Row className="mt-4">
          <Col className="form-label w-180-px" lg="auto">
            Call details
          </Col>
          <Col lg={12}>
            <ExpandCollapse>
              {preserveNewlines(interview.call_details).map((item, i) => (
                <Linkify key={i}>{item}</Linkify>
              ))}
            </ExpandCollapse>
          </Col>
        </Row>
      )}

      {interview.additional_notes && (
        <Row className="mt-4">
          <Col className="form-label w-180-px" lg="auto">
            Interview notes
          </Col>
          <Col lg={12} className="font-weight-medium">
            {interview.additional_notes}
          </Col>
        </Row>
      )}

      {!readonly && (
        <div className="d-flex flex-column flex-md-row mt-5">
          <Button
            variant="outline-primary"
            className="btn-sm mr-md-4"
            href={`${interview.tutor.profile_link}?open_session_request=true`}
          >
            Schedule a session
          </Button>
          <Button variant="link" className="link-error mt-3 mt-md-0" onClick={onCancel}>
            Cancel interview
          </Button>
        </div>
      )}
    </Card>
  )
}

export default ParentInterviewCard
