import { useContext, useEffect, useState } from "react"
import moment from "moment"
import { Entities } from "../../../../constants"
import { useNavigate } from "react-router-dom"
import { DataContext, DataContextActions, QueryContext } from "../../../../contexts"
import { getReport } from "../../configurations/domain"

export const useReportRenderReady = validReportDate => {
  const { scheduleId } = useContext(DataContext)
  const { updateState } = useContext(DataContextActions)
  const [readyForRender, setReadyForRender] = useState(false)
  const navigate = useNavigate()
  const { getHookState } = useContext(QueryContext)
  const { request } = getHookState(getReport)

  useEffect(() => {
    if (readyForRender) return

    if (!validReportDate) {
      navigate(`./${moment().format("YYYY-MM-DD")}`)
      return
    }

    request({
      entitiesIds: {
        [Entities.Schedules]: scheduleId,
        [Entities.Reports]: validReportDate,
      },
      onSuccess: () => {
        setReadyForRender(true)
      },
      onFailure: () => {
        updateState(getReport, { empty: true })
        setReadyForRender(true)
      },
    })
  }, [navigate, validReportDate, request, scheduleId, readyForRender, updateState])

  return readyForRender
}
