import Alerter from "../common/alerter"

const UpdateStatuses = Object.freeze({ Success: "success", Errors: "errors" })
const defaultKeys = Object.freeze(["forbidden", "not_found", "internal_error"])

export const useBalkErrorBuilder =
  (params = {}) =>
  ({ onUpdate, buildErrorMessage, successMessage } = {}) =>
  response => {
    const successMsg = params.successMessage || successMessage || false
    const errorBuilder = params.buildErrorMessage || buildErrorMessage || (() => "")
    const errors = response.data.filter(({ status }) => status === UpdateStatuses.Errors)
    const noErrors = errors.length === 0

    if (noErrors) {
      if (successMsg) Alerter.success(successMessage)
    } else {
      errors.forEach(error => {
        const message = errorBuilder(error)
        if (message) {
          const msg = `${message}: ${error.errors
            .map(({ message, key }) => (defaultKeys.includes(key) ? message : `${key} - ${message}`))
            .join(", ")}`
          Alerter.error(msg)
          throw new Error(msg)
        }
      })
    }
    if (onUpdate) onUpdate(response, noErrors)
    if (params.onUpdate) params.onUpdate(response, noErrors)
  }

export const useBalkError = ({ onUpdate, buildErrorMessage, successMessage }) => {
  return useBalkErrorBuilder()({ onUpdate, buildErrorMessage, successMessage })
}
