import React, { useMemo, useState } from "react"
import { Field, useField, useForm } from "react-final-form"
import { compose, map } from "lodash/fp"

import { getDomainsByGrade, isExecutiveFunctionProgram } from "../../../helpers/programsHierarchy"
import { ListGroup } from "react-bootstrap"

const getDomainsOptions = (gradeId, hierarchy) =>
  compose(
    map(({ domain, programs }) => ({ ...domain, programs })),
    hierarchy => getDomainsByGrade(gradeId, hierarchy)
  )(hierarchy)

const DOMAINS_LIST = "domains"
const PROGRAMS_LIST = "programs"

const WizardProgramStep = ({ options, onSubmit }) => {
  const form = useForm()
  const {
    input: { value: gradeId },
  } = useField("grade")

  const domains = useMemo(() => getDomainsOptions(gradeId, options.hierarchy), [options, gradeId])
  const [listType, setListType] = useState(DOMAINS_LIST)

  const [programs, setPrograms] = useState([])

  const onDomainChange = (input, { programs }) => {
    if (programs.length === 1) {
      onChange(input, programs[0].id)
    } else {
      setPrograms(programs)
      setListType(PROGRAMS_LIST)
    }
  }

  const onChange = (input, value) => {
    input.onChange([value])

    if (isExecutiveFunctionProgram(value, options.programs)) {
      form.mutators.setFieldData("executive_function", false)
    }

    onSubmit && onSubmit()
  }

  return (
    <Field name="program_ids">
      {({ input }) =>
        listType === DOMAINS_LIST ? (
          <ListGroup>
            {domains.map(domain => (
              <ListGroup.Item
                key={domain.id}
                action
                active={input.value === domain.id}
                className="d-flex pl-1 align-items-center"
                onClick={() => onDomainChange(input, domain)}
              >
                {domain.name}
                {domain.programs.length > 1 && <div className="dropdown-chevron-right" />}
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <ListGroup>
            <ListGroup.Item
              action
              className="d-flex pl-1 align-items-center text-muted"
              onClick={() => setListType(DOMAINS_LIST)}
            >
              <div className="dropdown-chevron-left mr-2" />
              Back
            </ListGroup.Item>
            {programs.map(program => (
              <ListGroup.Item
                key={program.id}
                action
                active={input.value === program.id}
                className="d-flex pl-1 align-items-center"
                onClick={() => onChange(input, program.id)}
              >
                {program.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )
      }
    </Field>
  )
}

export default WizardProgramStep
