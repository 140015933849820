import React, { useMemo } from "react"
import { className } from "../helpers/className"

export const ButtonIcon = ({ icon: Icon, size = "sm", ...props }) => {
  const sizeClassName = useMemo(() => {
    switch (size) {
      case "xs":
        return "icon-12-px"
      case "sm":
        return "icon-16-px"
      case "md":
        return "icon-20-px"
      case "lg":
        return "icon-24-px"
      case "xl":
        return "icon-28-px"
      default:
        return "icon-16-px"
    }
  }, [size])
  return (
    <button {...props} className={className("icon-button", props.className)} data-test="close-modal">
      <span className={sizeClassName}>
        <Icon />
      </span>
    </button>
  )
}
