import React from "react"
import { StatelessTable } from "../../table"
import { useColumns } from "./useColumns"

export const ConnectionsTable = ({ connections }) => {
  const columns = useColumns()

  return (
    <StatelessTable
      columns={columns}
      data={connections}
      count={connections.length}
      hideFooter
      headerCellClasses="connections-table__header-cell"
    />
  )
}
