export const elText = (el, ids) => {
  return ids && ids.includes(el.id) ? `[\u2713]` : `[\u00A0\u00A0\u00A0]`
}

export const toggleId = (fields, id) => {
  const index = fields.value.indexOf(id)
  if (index > -1) {
    fields.remove(index)
  } else {
    fields.push(id)
  }
}

export const deleteId = (fields, index) => {
  fields.remove(index)
}

export const requiredClass = meta => {
  return meta.error && meta.touched ? "red-border" : ""
}
