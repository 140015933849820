import React from "react"
import { Form } from "react-bootstrap"

const FormSelect = ({ options, placeholder, allowEmpty = true, ...props }) => (
  <Form.Control as="select" {...props}>
    {placeholder && (
      <option key="empty" value="" disabled={!allowEmpty} hidden={!allowEmpty}>
        {placeholder}
      </option>
    )}
    {options.map(({ label, value }) => (
      <option key={value} value={value}>
        {label}
      </option>
    ))}
  </Form.Control>
)

export default FormSelect
