import React from "react"
import { Col, Row, Form } from "react-bootstrap"
import { Field } from "react-final-form"

export const SendReportFieldNames = Object.freeze({
  SendReportsTo: "additional_emails",
  NotifyAboutUpcoming: "notify_parent",
})

const defaultNames = {
  sendTo: SendReportFieldNames.SendReportsTo,
  sendToParents: SendReportFieldNames.NotifyAboutUpcoming,
}

export const SendReportFields = ({ names, disabled, multipleValues = {} }) => {
  const { sendTo, sendToParents } = { ...defaultNames, ...names }
  return (
    <Row>
      <Col xs={24}>
        <Field type="text" name={sendTo}>
          {({ input, meta }) => (
            <>
              <Form.Control
                {...input}
                className="v-2"
                placeholder={`Emails, splitted by comma${multipleValues[sendTo] ? " - Multiple values" : ""}`}
                disabled={disabled}
              />
              <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
            </>
          )}
        </Field>
      </Col>
      <Col>
        <Field type="checkbox" name={sendToParents}>
          {({ input, meta }) => {
            return (
              <>
                <Form.Check
                  {...input}
                  id={input.name}
                  type="checkbox"
                  value={input.checked}
                  className="text-nowrap mt-3 v-2"
                  label={`Notify parents about upcoming sessions${
                    multipleValues[sendToParents] ? " (Multiple values)" : ""
                  }`}
                  custom
                  disabled={disabled}
                />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </>
            )
          }}
        </Field>
      </Col>
    </Row>
  )
}
