import React, { useCallback, useMemo, useState } from "react"
import useViewport from "../../../../hooks/useViewport"
import { Card, Col, Form, Row } from "react-bootstrap"
import { className } from "../../../../helpers/className"
import { RECEIVE_SESSIONS_OPTIONS, YES_NO_OPTIONS } from "../../../../common/constants"
import { validateEmail } from "../../../../helpers/validators"
import { InputAdornment, ListSubheader, MenuItem, Select, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import Alerter from "../../../../common/alerter"

const CreateSchoolAdminPageWrapper = ({ children, title, titleAffix }) => {
  const { isMobileViewport } = useViewport()
  return (
    <div className="container p-0 d-flex flex-column flex-grow-1">
      <Row>
        <Col className="p-0">
          <div className="container users-navbar_container -white justify-content-between">
            <h2 className="users-navbar_title m-0">{title}</h2>
            {titleAffix}
          </div>
          <div className="container mt-4 px-0">
            <Row className="mx-n2 px-2 mb-4">
              <Col as="main" xs={24}>
                <Card className={className(isMobileViewport ? "px-3 py-4" : "px-4 py-5")}>{children}</Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const SelectWithSearchInput = ({ school, handleOnChangeSelect, schoolsList }) => {
  const [searchText, setSearchText] = useState("")

  const schoolListOptions = useMemo(
    () => schoolsList.filter(([value]) => value.toLowerCase().indexOf(searchText.toLowerCase()) > -1),
    [schoolsList, searchText]
  )

  return (
    <Select
      id="school"
      value={school}
      className="mb-0 mt-n1 form-input-align form-control flex-column form-group col-lg-16 col-24 form-control"
      label=""
      style={{
        paddingRight: 0,
        paddingLeft: "2em",
        lineHeight: "0.4375em",
        borderRadius: "40px",
      }}
      defaultValue={school}
      onClose={() => setSearchText("")}
      onChange={handleOnChangeSelect}
    >
      <ListSubheader>
        <TextField
          size="small"
          autoFocus
          placeholder="Type to search for school..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={e => setSearchText(e.target.value)}
          onKeyDown={e => {
            if (e.key !== "Escape") {
              e.stopPropagation()
            }
          }}
        />
      </ListSubheader>
      {schoolListOptions.map(([value, id]) => (
        <MenuItem key={id} value={id}>
          {value}
        </MenuItem>
      ))}
    </Select>
  )
}

const CreateOrUpdateForm = ({ title, onSubmit, initialValues, schoolsList }) => {
  const schoolAdminInit = initialValues?.schoolAdmin
  const receiveSessionsInit = initialValues?.receiveSessions

  const [firstName, setFirstName] = useState(schoolAdminInit?.first_name)
  const [lastName, setLastName] = useState(schoolAdminInit?.last_name)
  const [school, setSchool] = useState(schoolAdminInit?.school_id)
  const [email, setEmail] = useState(schoolAdminInit?.email)
  const [receiveSessions, setReceiveSessions] = useState(
    receiveSessionsInit ? RECEIVE_SESSIONS_OPTIONS.YES : RECEIVE_SESSIONS_OPTIONS.NO
  )
  const [emailValid, setValidateEmail] = useState(undefined)

  const handleOnChange = e => {
    e.preventDefault()
    const emailValue = e.target.value
    setEmail(emailValue)
    const isValid = validateEmail(emailValue)
    setValidateEmail(isValid)
  }

  const handleOnSubmit = useCallback(
    async e => {
      e.preventDefault()
      try {
        await onSubmit(e, schoolAdminInit?.id, email, firstName, lastName, receiveSessions, school)
      } catch (error) {
        Alerter.error(error.response.data.errors)
      }
    },
    [onSubmit, schoolAdminInit?.id, email, firstName, lastName, receiveSessions, school]
  )

  const handleOnChangeSelect = e => {
    e.preventDefault()
    const schoolValue = e.target.value
    setSchool(schoolValue)
  }

  const disableSubmit = (email, firstName, lastName, school, emailValid, schoolList) => {
    if (schoolList) {
      return !email || !firstName || !lastName || !school || emailValid
    }

    return !email || !firstName || !lastName || emailValid
  }

  return (
    <CreateSchoolAdminPageWrapper title={title}>
      <Row>
        <Col xs={24} lg={20} xl={14}>
          <div className="mb-4 form-group">
            <div className="px-2 form-row">
              <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">Email</span>
              <Form.Control
                type="email"
                style={{
                  width: "100%",
                  padding: "12px 20px",
                  margin: "8px 0",
                  boxSizing: "border-box",
                }}
                className="mb-0 mt-n1 form-input-align flex-column form-group col-lg-16 col-24 control-label"
                value={email}
                isValid={emailValid}
                required
                onChange={e => handleOnChange(e)}
              />
              <Form.Control.Feedback type="invalid">Please choose a first name.</Form.Control.Feedback>
            </div>
          </div>

          <div className="mb-4 form-group">
            <div className="px-2 form-row">
              <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">First Name</span>
              <Form.Control
                type="text"
                required
                style={{
                  width: "100%",
                  padding: "12px 20px",
                  margin: "8px 0",
                  boxSizing: "border-box",
                }}
                className="mb-0 mt-n1 form-input-align flex-column form-group col-lg-16 col-24"
                value={firstName}
                onChange={e => {
                  setFirstName(e.target.value)
                }}
              />
            </div>
          </div>

          <div className="mb-4 form-group">
            <div className="px-2 form-row">
              <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">Last Name</span>
              <Form.Control
                type="text"
                required
                style={{
                  width: "100%",
                  padding: "12px 20px",
                  margin: "8px 0",
                  boxSizing: "border-box",
                }}
                className="mb-0 mt-n1 form-input-align flex-column form-group col-lg-16 col-24"
                value={lastName}
                onChange={e => {
                  setLastName(e.target.value)
                }}
              />
            </div>
          </div>
          {schoolsList?.length && (
            <div className="mb-4 form-group">
              <div className="px-2 form-row">
                <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">School</span>
                <SelectWithSearchInput
                  school={school}
                  schoolsList={schoolsList}
                  handleOnChangeSelect={handleOnChangeSelect}
                />
              </div>
            </div>
          )}

          <div className="px-2 form-row">
            <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">
              Receive Student Session Reports
            </span>

            {YES_NO_OPTIONS.map(el => (
              <Form.Check
                name={el}
                type="radio"
                className="mr-3"
                id={el}
                onChange={() => {
                  setReceiveSessions(el)
                }}
                checked={el === receiveSessions}
                key={el}
                value={el}
                label={el}
                custom
              />
            ))}
          </div>
          <div className="mt-4 form-group">
            <button
              className="btn btn-primary"
              type="submit"
              onClick={async e =>
                await handleOnSubmit(e, schoolAdminInit?.id, email, firstName, lastName, receiveSessions, school)
              }
              disabled={disableSubmit(email, firstName, lastName, school, emailValid, schoolsList)}
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </CreateSchoolAdminPageWrapper>
  )
}

export default CreateOrUpdateForm