import React, { useRef } from "react"
import { Col, Row, Form, Button } from "react-bootstrap"
import { Form as FForm, Field } from "react-final-form"
import FormRow from "../../blocks/FormRow"
import AsyncAutocompleteField from "../../AsyncAutocompleteField"
import { useCreateGroupRequest } from "./hooks"

const Fields = Object.freeze({
  SCHOOL_ID: "school_id",
  NAME: "name",
  OPENING_STUDENTS: "opening_students",
})

const defaultValues = {
  [Fields.SCHOOL_ID]: "",
  [Fields.NAME]: "",
  [Fields.OPENING_STUDENTS]: [],
}

const labelProps = { sm: 7, md: 5, lg: 4 }
const colProps = { sm: 17, md: 14, lg: 10, xl: 8 }

const validateForm = data => {
  const errors = {}
  const schoolId = data[Fields.SCHOOL_ID]
  const name = data[Fields.NAME]
  if (!schoolId) errors[Fields.SCHOOL_ID] = "Required field"
  if (!name) errors[Fields.NAME] = "Required field"
  return errors
}

const CreateGroupForm = ({ schools, autoCompleteSource, redirectUrl }) => {
  const { loading, request } = useCreateGroupRequest({
    onSuccess: () => {
      setTimeout(() => window.location.assign(redirectUrl), 1000)
    },
  })

  const initialValues = useRef({ ...defaultValues })

  const submit = data => {
    const name = data[Fields.NAME]
    const school_id = parseInt(data[Fields.SCHOOL_ID])
    const student_ids = (data[Fields.OPENING_STUDENTS] || []).map(({ value }) => parseInt(value, 10))
    request({ opening_group: { name, school_id, student_ids } })
  }

  return (
    <FForm initialValues={initialValues.current} onSubmit={submit} validate={validateForm}>
      {({ handleSubmit, invalid, values }) => {
        return (
          <form className="simple_form new_opening_group" onSubmit={handleSubmit}>
            <Field name={Fields.SCHOOL_ID}>
              {({ input, meta }) => (
                <FormRow
                  label="School"
                  required
                  LabelProps={{ htmlFor: input.name, ...labelProps }}
                  ColProps={{ ...colProps }}
                >
                  <Form.Control {...input} as="select" className={meta.touched && meta.invalid ? "error" : ""}>
                    <option value="" disabled>
                      Select school
                    </option>
                    {schools.map(({ id, title }) => (
                      <option key={id} value={id}>
                        {title}
                      </option>
                    ))}
                  </Form.Control>
                </FormRow>
              )}
            </Field>
            <Field name={Fields.NAME}>
              {({ input, meta }) => (
                <FormRow
                  label="Name"
                  required
                  LabelProps={{ htmlFor: input.name, ...labelProps }}
                  ColProps={{ ...colProps }}
                >
                  <Form.Control
                    {...input}
                    placeholder="Group name"
                    className={meta.touched && meta.invalid ? "error" : ""}
                  />
                </FormRow>
              )}
            </Field>
            <Field name={Fields.OPENING_STUDENTS}>
              {({ input }) => (
                <FormRow
                  label="Students"
                  LabelProps={{ htmlFor: input.name, ...labelProps }}
                  ColProps={{ ...colProps }}
                >
                  <AsyncAutocompleteField
                    isMulti={true}
                    disabled={!values[Fields.SCHOOL_ID]}
                    source={autoCompleteSource}
                    placeholder="Select student to add"
                    fieldName={input.name}
                    selected={input.value}
                    onSelect={input.onChange}
                    externalParams={{
                      school_id: values[Fields.SCHOOL_ID],
                    }}
                  />
                </FormRow>
              )}
            </Field>
            <Row className="form-actions">
              <Col>
                {loading ? (
                  <Button className="btn btn-outline-primary pt-2 pb-2" type="submit" disabled>
                    <div className="spinner-border text-primary" />
                  </Button>
                ) : (
                  <Button className="btn btn-outline-primary" type="submit" disabled={invalid}>
                    Create
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        )
      }}
    </FForm>
  )
}

export default CreateGroupForm
