import React, { useState } from "react"
import { Button } from "react-bootstrap"
import Alerter from "../../../../common/alerter"

export const FileSelectButton = ({ title, name, allowedTypes, onChange }) => {
  const [fileName, setFileName] = useState(null)
  const onFileChange = async event => {
    const { files } = event.target
    if (!files || files?.length === 0) return
    const [file] = Array.from(files)
    const isTypeAllowed = allowedTypes.some(type => file.type === type)
    if (isTypeAllowed) {
      setFileName(file.name)
      if (onChange) onChange({ name, target: { value: file } })
    } else Alerter.error(`Incorrect file type: expected - ${allowedTypes.join(", ")}, selected - ${file.type}`)
  }
  return (
    <>
      <Button as="label" className="btn btn-outline-primary cursor-pointer fullwidth mb-2">
        {title}
        <input hidden required name={name} type="file" accept={allowedTypes.join(",")} onChange={onFileChange} />
      </Button>
      {fileName && (
        <div className="mx-2">
          <span className="font-weight-semibold">Selected file:</span>
          <br />
          <span>{fileName}</span>
        </div>
      )}
    </>
  )
}
