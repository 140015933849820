import React from "react"

import TutorCard from "../find_tutor/tutor_card/TutorCard"
import SessionConfiguration from "./SessionConfiguration"

const ConfigurationStep = ({ editMode, hourRate, parent, tutor, options }) => {
  return (
    <div>
      {editMode && (
        <div className="mb-5">
          <h4 className="mb-3">Tutor</h4>
          <TutorCard tutor={{ ...tutor, hourly_rate: hourRate }} options={options} isLink={false} />
        </div>
      )}

      <h4 className="mb-3">Session details</h4>
      <p className="pb-2">Please make your selections below!</p>

      <SessionConfiguration parent={parent} tutor={tutor} options={options} disabled={!editMode} />
    </div>
  )
}

export default ConfigurationStep
