import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { Fragment, memo } from "react"
import FilterInputs from "./Filters"

const AccordionStyled = styled(Accordion)(() => ({
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  fontFamily: "'Poppins', sans-serif",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f8f9fa",
}))

const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  height: 48,
  minHeight: 48,
}))

const StudentsDataGridToolbar = ({
  handleSelectAll,
  handleUnselectAll,
  rowSelectionModel,
  allRowsSelected,
  ...props
}) => {
  return (
    <Fragment>
      <div
        className="d-flex gap-1 flex-wrap pt-2 pb-2 pl-3 pr-3 w-100 justify-content-between align-items-center"
        style={{ marginLeft: "auto" }}
      >
        <Typography variant="subtitle1" fontWeight="700" color="text.secondary">
          Selection
        </Typography>
        <div className="d-flex gap-1">
          <Button size="sm" variant="outline-primary" onClick={handleSelectAll} disabled={allRowsSelected}>
            Select All Active
          </Button>
          <Button size="sm" variant="outline-primary" onClick={handleUnselectAll} disabled={!rowSelectionModel.length}>
            Deselect All
          </Button>
        </div>
      </div>

      <AccordionStyled defaultExpanded={false}>
        <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />} aria-controls="filter-content" id="filter-header">
          <Typography variant="subtitle1" fontWeight="700" color="text.secondary">
            Filters
          </Typography>
        </AccordionSummaryStyled>
        <AccordionDetails>
          <FilterInputs {...props} />
        </AccordionDetails>
      </AccordionStyled>
    </Fragment>
  )
}

export default memo(StudentsDataGridToolbar)
