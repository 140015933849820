import React from "react"
import { Field, useFormState } from "react-final-form"
import { Row, Col, Form } from "react-bootstrap"

import HourRateInfo from "./HourRateInfo"
import ScheduleSummary from "./ScheduleSummary"
import CouponInfo from "./CouponInfo"

const OverviewStep = ({ hourRate, editMode, isParent, parent }) => {
  const { values } = useFormState()

  return (
    <div>
      <Row>
        <Col lg={12}>
          <h4 className="mb-3">Additional information</h4>
          <p className="pb-2">
            Anything in particular you’re concerned about? Do you have specific goals in mind? Make sure to let the
            tutor know so you can make the most of the interview!
          </p>

          <Field name="additional_info">
            {({ input }) => <Form.Control {...input} disabled={!editMode} as="textarea" rows={5} />}
          </Field>
        </Col>
      </Row>

      <h6 className="mb-3 mt-5">
        <ScheduleSummary values={values} />
      </h6>

      <Row>
        <Col lg={6} md={12}>
          <HourRateInfo hourRate={hourRate} editMode={editMode} />
        </Col>
        <Col lg={6} md={12} className="ml-4">
          {isParent && parent.active_coupon && parent.coupon_info && (
            <CouponInfo text={parent.coupon_info.additional_info} />
          )}
        </Col>
      </Row>
    </div>
  )
}

export default OverviewStep
