import "bootstrap-datepicker"
import moment from "moment"

// DataGrid gem creates a form through its #filter method
// in Admins::TimesheetsGrid and Admins::TutorTimesheetsGrid,
// to select a started_at date and tutor. The backend is
// setup to take a date range formatted as `MM/DD/YYYY - MM/DD/YYYY`
// in the `started_at` input field. The form looks something like:

// <form class="simple_form table-filter_form input-group input-daterange" id="timesheet-datepicker">
//   <div class="form-group form-group-valid">
//     <label class="form-control-label" for="grid_started_at">Session Date:</label>
//     <input class="form-control timesheet-picker" type="text" value="10/16/2023 - 10/22/2023" name="grid[started_at]" id="grid_started_at">
//   </div>
//   {... tutor selection code}
//   <div class="datagrid-actions">
//     <input type="submit" value="Search" />
//   </div>
// </form>

// However, we want to select our dates using Bootstrap Range Datepicker:
// https://bootstrap-datepicker.readthedocs.io/en/latest/markup.html#date-range

// The use of the range datepicker requires two inputs to the UI, one for start date and one for end date.
// With the state of the backend, to add these fields would require us to build out more DataGrid filers.
// Instead, to achieve successful form submission, we are going to expose a total of three inputs:
// One for `started_at`, one for `ended_at`, and a hidden input that will combine the values of both inputs
// into a date range string, which on form submission, will be send to DataGrid to update our report
// filters, and display updated report data.

// To achieve this, we will be duplicating the `started_at` input as our base for building up the
// `ended_at` and hidden inputs.

// Slice the range string from DataGrid into two date strings, seperating
// on the joining ` - ` and return the start date
// rangeString = [String], MM/DD/YYYY
const getStartDateFromGridRange = rangeString => {
  return rangeString.split(" - ")[0]
}

// Slice the range string from DataGrid into two date strings, seperating
// on the joining ` - ` and return the end date
// rangeString = [String], MM/DD/YYYY
const getEndDateFromGridRange = rangeString => {
  return rangeString.split(" - ")[1]
}

// Takes the browser event and converts to UTC, then a formatted string
// event = [Browser Event]
const getFormattedDateString = event => {
  return moment(event.date).format("MM/DD/YYYY")
}

document.addEventListener("turbolinks:load", () => {
  const startDateInput = $("#timesheet-datepicker #grid_started_at")[0]

  if (!startDateInput) {
    return
  }

  // Our incoming range string from DataGrid
  const defaultDateRange = startDateInput.value

  // Fetch our default start and end dates from the incoming date range string
  const startDate = getStartDateFromGridRange(defaultDateRange)
  const endDate = getEndDateFromGridRange(defaultDateRange)

  // Grab the hidden input and end date input if they exist
  let hiddenInput = $("#timesheet-datepicker #hidden_date_range")[0]
  let endDateInput = $("#timesheet-datepicker #grid_ended_at")[0]

  // When pressing the back button or refreshing, we don't want to add extra inputs if they already exist on the page
  if (!hiddenInput) {
    // If the hidden input doesn't already exist, clone it from the existing start date input
    // and ensure the input is hidden, remove any classes, and change our id
    hiddenInput = $(startDateInput).clone().attr("type", "hidden").removeAttr("class").attr("id", "hidden_date_range")
    // Append the hidden input after our start date input
    $(startDateInput).after(hiddenInput)
    // Since our start date input doesn't need to capture the date range anymore,
    // But needs just the start date portion, we're going to set that as the value
    // so we can display it in the UI on page load, and remove the name attribute
    // so we don't have two inputs trying to submit to the DataGrid form
    $(startDateInput).val(startDate).removeAttr("name")
  }

  // when pressing the back button or refreshing, we don't want to add extra inputs if they already exist on the page
  if (!endDateInput) {
    // If the hidden input doesn't already exist, clone it from the existing start date input
    // and ensure it keeps the same styling, but removes the name, and captures the value of the end date
    // so we can display it in the UI on page load
    endDateInput = $(startDateInput).clone().removeAttr("name").attr("id", "grid_ended_at").val(endDate)
    // Append the end date input after the start date input
    $(startDateInput).after(endDateInput)
  }

  const datePickerOptions = {
    autoclose: true,
    maxViewMode: 0,
    weekStart: 1,
    orientation: "bottom auto",
    todayBtn: "linked",
  }

  // Initiate datepicker for start date, handle date selection
  $(startDateInput)
    .datepicker(datePickerOptions)
    .on("changeDate", e => {
      // We keep track of in progress date selectio updates while we call
      // `setDates` and update the value of our hidden input. Skip running
      // updates if are already running an update
      if ($(this).data("updating") === true) {
        return
      }

      const startDateString = getFormattedDateString(e)
      const endDateString = $("#timesheet-datepicker #grid_ended_at")[0].value

      handleUpdates(this, startDateString, endDateString)
    })

  // Initiate datepicker for end date, handle date selection
  $(endDateInput)
    .datepicker(datePickerOptions)
    .on("changeDate", e => {
      if ($(this).data("updating") === true) {
        return
      }

      const startDateString = $("#timesheet-datepicker #grid_started_at")[0].value
      const endDateString = getFormattedDateString(e)

      handleUpdates(this, startDateString, endDateString)
    })

  // Marks the changed input as "updating" and combines the incoming date with the existing date
  // to form a new date range. Set the hidden input's value to the new date range.
  // input = [Jquery Element], startDateString = [String], endDateString = [String]
  const handleUpdates = (input, startDateString, endDateString) => {
    $(input).data("updating", true)

    // Combine the new start date with the existing end date into a new date range string
    const dateRange = `${startDateString} - ${endDateString}`

    // Use our new date range string to update the hidden input
    hiddenInput.val(dateRange)

    $(input).data("updating", false)
  }
})
