import React, { useEffect, useCallback, useRef, useState, useMemo } from "react"
import { Form } from "react-bootstrap"
import { buildId } from "../../helpers/forms"
import { className } from "../../helpers/className"
import { ReactComponent as Chevron } from "../../assets/images/chevron1.svg"
import { ReactComponent as Close } from "../../assets/images/close.svg"

const noop = () => {}
const searchDelay = 500
const focusChangeDelay = 4

export const DropdownItems = ({ selected, options, getLabel, getValue, onSelect, dataTest }) => {
  const checkActive = useCallback(option => getValue(selected) === getValue(option), [getValue, selected])

  const optionList = () => {
    return options.map((option, idx) => {
      const label = getLabel(option, checkActive(option))

      return (
        <li className={className("brain-trust-dropdown-menu-item", checkActive(option) && "active")} key={idx}>
          <button
            type="button"
            className="btn-clear fullwidth text-left"
            onClick={event => onSelect(event, option)}
            data-test={`${dataTest}-${label}`}
          >
            {label}
          </button>
        </li>
      )
    })
  }

  return options.length === 0 ? <li className="py-2 px-3">No options</li> : optionList()
}

const Dropdown = ({
  name,
  selected,
  options,
  disabled,
  error,
  buildValue,
  buildLabel,
  buildOption,
  loading,
  placeholder,
  searchPlaceholder = "Search",
  className: dropdownClassName,
  Items = DropdownItems,
  onSearch,
  onSearchReset,
  onFocus,
  onChange,
  onBlur,
  resettable,
  dataTest,
}) => {
  const fieldId = useMemo(buildId, [])
  const dropdownRef = useRef(null)
  const focusTimerRef = useRef(null)
  const primaryButtonRef = useRef(null)
  const searchRef = useRef(null)
  const listRef = useRef(null)
  const timerId = useRef(null)
  const [openList, setOpenList] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const handleCloseList = useCallback(
    event => {
      const canChange = dropdownRef && dropdownRef.current && openList && !dropdownRef.current.contains(event.target)
      if (canChange) setOpenList(false)
    },
    [openList]
  )

  const selectFromList = useCallback(
    (event, option) => {
      if (disabled) return
      if (option !== selected) onChange({ ...event, target: { ...event.target, value: option } })
      primaryButtonRef.current?.focus()
      setOpenList(false)
      setSearchValue("")
      if (onSearchReset) onSearchReset()
    },
    [disabled, onChange, onSearchReset, selected]
  )

  const focus = useCallback(
    event => {
      setOpenList(!openList)
      if (onFocus) onFocus({ ...event, target: { ...event.target, value: selected } })
      clearTimeout(focusTimerRef.current)
      focusTimerRef.current = setTimeout(() => listRef.current?.focus(), focusChangeDelay)
    },
    [onFocus, openList, selected]
  )

  const blur = useCallback(
    event => {
      if (!dropdownRef.current?.contains(event.target)) setOpenList(false)
      if (onBlur) onBlur({ ...event, target: { ...event.target, value: selected } })
    },
    [onBlur, selected]
  )

  const search = useCallback(
    event => {
      setSearchValue(event.target.value)
      clearTimeout(timerId.current)
      timerId.current = setTimeout(onSearch, searchDelay, { ...event })
    },
    [onSearch]
  )

  useEffect(() => {
    if (dropdownRef.current && openList) {
      document.addEventListener("mousedown", handleCloseList)
      return () => document.removeEventListener("mousedown", handleCloseList)
    }
  }, [options, openList, handleCloseList])

  useEffect(() => {
    if (openList) {
      searchRef.current?.focus()
    }
  }, [openList])

  const getLabel = useCallback(option => buildLabel(option), [buildLabel])
  const label = useMemo(() => getLabel(selected), [getLabel, selected])
  const value = useMemo(() => buildValue(selected), [buildValue, selected])
  const getOption = useMemo(() => buildOption ?? buildLabel, [buildOption, buildLabel])

  return (
    <div ref={dropdownRef} className="position-relative">
      <label htmlFor={fieldId} className="fullwidth mb-0">
        <Form.Control id={fieldId} isInvalid={!!error} name={name} type="text" value={value} onChange={noop} hidden />
        <button
          ref={primaryButtonRef}
          type="button"
          className="form-control v-2 fullwidth"
          onClick={focus}
          onBlur={blur}
          disabled={disabled}
          data-test={`${dataTest}-button`}
        >
          <div className="d-flex flex-nowrap w-100 align-items-center justify-content-between font-size-lighter">
            <span className={className("text-overflow-ellipsis text-no-wrap overflow-hidden", !label && "text-gray")}>
              {label || placeholder}
            </span>

            {resettable && selected ? (
              <div
                className="icon-16-px icon-gray-color m-1"
                onClick={e => {
                  e.stopPropagation()
                  selectFromList(e, undefined)
                }}
              >
                <Close />
              </div>
            ) : (
              <div className="icon-24-px icon-gray-color">
                <Chevron />
              </div>
            )}
          </div>
        </button>
        <Form.Control.Feedback type="invalid" className="mt-1">
          {error}
        </Form.Control.Feedback>
      </label>
      {openList && (
        <ul ref={listRef} className={className("brain-trust-dropdown-menu show v-2", dropdownClassName)}>
          {onSearch && (
            <li className="brain-trust-dropdown-menu-item px-3 mb-2">
              <Form.Control
                ref={searchRef}
                value={searchValue}
                placeholder={searchPlaceholder}
                onChange={search}
                className="v-2 small mb-2"
                data-test={`${dataTest}-input`}
              />
              {options ? <hr className="my-2 mx-n3" /> : null}
            </li>
          )}
          {loading ? (
            <li className="d-flex align-items-center justify-content-center py-3">
              <div className="spinner-border text-primary" />
            </li>
          ) : options ? (
            <Items
              selected={selected}
              options={options}
              onSelect={selectFromList}
              getLabel={getOption}
              getValue={buildValue}
              dataTest={dataTest}
            />
          ) : null}
        </ul>
      )}
    </div>
  )
}

export default Dropdown
