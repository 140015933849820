import LoadingSpinner from "@/common/loading"
import { UpcomingSession } from "@/components/UpcomingSession"
import { formatDate } from "@/helpers/dates"
import { Box, Stack, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import Grid from "@mui/material/Unstable_Grid2"
import React, { useEffect, useMemo, useState } from "react"
import AdminMessage from "./AdminsMessage"
import { useGetAdminMessageRequest, useGetUpcomingSession, useTutorScheduleRequest } from "./hooks"
import { NextSession } from "./NextSession"
import { OpportunitiesList } from "./OpportunitiesList"
import { TutorDashboardProvider } from "./providers"
import { UserDashboard } from "./UserDashboard"
import WeekSchedule from "./WeekSchedule"

const TitleTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  letterSpacing: "-0.04em",
  marginBottom: theme.spacing(3),
}))

const TutorDashboardContent = ({ currentUser, calendarPath }) => {
  const { first_name: userFirstName, id: tutorId, feature_flags: featureFlags } = currentUser
  const [selectedDate, setSelectedDate] = useState(() => formatDate(new Date()))

  const handleDateChange = date => {
    setSelectedDate(formatDate(date))
  }

  const { loading: isUpcomingSessionLoading, data: upcomingSessionData } = useGetUpcomingSession({ tutorId })
  const { loading: isAdminMessageLoading, data: adminMessageData } = useGetAdminMessageRequest()
  const {
    loading: isTutorScheduleLoading,
    data: tutorScheduleEvents,
    request: fetchTutorSchedule,
  } = useTutorScheduleRequest()

  useEffect(() => {
    fetchTutorSchedule(selectedDate)
  }, [fetchTutorSchedule, selectedDate])

  const isGlobalLoading = useMemo(
    () => isUpcomingSessionLoading || isAdminMessageLoading || isTutorScheduleLoading,
    [isUpcomingSessionLoading, isAdminMessageLoading, isTutorScheduleLoading]
  )

  const shouldDisplayCurriculumSession = useMemo(
    () =>
      featureFlags["curriculum_enabled"] &&
      upcomingSessionData?.next_session?.is_curriculum_program &&
      upcomingSessionData?.next_session?.assignment_action_items,
    [featureFlags, upcomingSessionData]
  )

  const hasUpcomingSession = useMemo(
    () => upcomingSessionData?.next_session && Object.keys(upcomingSessionData.next_session).length > 0,
    [upcomingSessionData]
  )

  if (isGlobalLoading) {
    return <LoadingSpinner />
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid xs={12} sx={{ margin: "8px 8px 0" }}>
          <TitleTypography variant="h3" color="secondary" sx={{ m: 0 }}>
            Welcome back, {userFirstName}!
          </TitleTypography>
        </Grid>
        {hasUpcomingSession && (
          <Grid xs={12}>
            {shouldDisplayCurriculumSession ? (
              <UpcomingSession upcomingSession={upcomingSessionData.next_session} currentUser={currentUser} />
            ) : (
              <NextSession tutorId={tutorId} />
            )}
          </Grid>
        )}
        <Grid xs={12}>
          <WeekSchedule
            title="Week Schedule"
            loading={isTutorScheduleLoading}
            week={selectedDate}
            setWeek={handleDateChange}
            events={tutorScheduleEvents}
            calendarPath={calendarPath}
          />
        </Grid>
        <Grid xs={12} container spacing={3}>
          <Grid xs={12} md={8}>
            <OpportunitiesList />
          </Grid>
          <Grid xs={12} md={4}>
            <Stack spacing={3}>
              <UserDashboard />
              <AdminMessage message={adminMessageData} />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

// Main Dashboard Wrapper with Context Provider
const TutorDashboard = props => (
  <TutorDashboardProvider>
    <TutorDashboardContent {...props} />
  </TutorDashboardProvider>
)

export default TutorDashboard
