import { Box, Button, Typography } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { styled } from "@mui/system"
import React from "react"

const BEFORE_SESSION_START_MESSAGE = "Link will activate 5 minutes before the session."

const SessionActionContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  justifyContent: "flex-start",
})

const JoinSessionButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  maxWidth: "150px",
}))

const Text = styled(Typography)({
  letterSpacing: 0,
  maxWidth: "150px",
  marginTop: "0.5rem",
  textWrap: "balance",
  textAlign: "center",
})

export const SessionAction = ({ canStartNextSession, onJoinClassClick }) => (
  <SessionActionContainer>
    <Tooltip title={!canStartNextSession ? BEFORE_SESSION_START_MESSAGE : ""}>
      <span>
        <JoinSessionButton
          size="small"
          variant="contained"
          onClick={onJoinClassClick}
          disabled={!canStartNextSession}
          aria-label="Join Session"
          disableElevation
        >
          Join Session
        </JoinSessionButton>
      </span>
    </Tooltip>
    {!canStartNextSession && (
      <Text variant="caption" color="textSecondary">
        {BEFORE_SESSION_START_MESSAGE}
      </Text>
    )}
  </SessionActionContainer>
)
