import React, { useContext, useMemo } from "react"
import { TutorCardActionsContext, TutorCardApplicationContext } from "../../../../contexts"
import { className } from "../../../../../../helpers/className"
import { ReactComponent as EyeIcon } from "../../../../../../assets/images/eye-big.svg"
import { ReactComponent as EyeStruckIcon } from "../../../../../../assets/images/eye-big-struck.svg"
import { ReactComponent as StarFilled } from "../../../../../../assets/images/star-filled.svg"
import { ReactComponent as StarOutline } from "../../../../../../assets/images/star-outline.svg"

export const QuickActions = () => {
  const application = useContext(TutorCardApplicationContext)
  const { toggleStar, toggleVisibility } = useContext(TutorCardActionsContext)

  const classNames = useMemo(
    () => ({
      starred: className("btn-clear icon-24-px", application.starred ? "text-primary" : "text-gray-chat"),
      hidden: className("btn-clear icon-24-px", application.hidden ? "text-primary" : "text-gray-chat"),
    }),
    [application]
  )

  return (
    <div className="opening-tutor-card_quick-actions">
      <button className={classNames.hidden} onClick={() => toggleVisibility(application)}>
        {application.show ? <EyeIcon /> : <EyeStruckIcon />}
      </button>

      {application.show && (
        <button className={classNames.starred} onClick={() => toggleStar(application)}>
          {application.starred ? <StarFilled /> : <StarOutline />}
        </button>
      )}
    </div>
  )
}
