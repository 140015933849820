import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"

const ReviewDeleteConfirmationModal = ({ show, handleClose, onConfirm }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async () => {
    setIsSubmitting(true)
    await onConfirm()
    setIsSubmitting(false)
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="borderless px-4 pt-4 pb-2" closeButton />
      <Modal.Body className="px-5 pb-5">
        <div className="d-flex flex-column align-items-center">
          <h5 className="font-weight-bold rule-height text-center pb-4">
            Are you sure you want to delete this review?
          </h5>
          <Button variant="outline-primary" disabled={isSubmitting} className="mt-2" onClick={onSubmit}>
            Ok
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ReviewDeleteConfirmationModal
