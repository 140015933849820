import React, { useContext, useReducer, useRef } from "react"
import { Row, Col } from "react-bootstrap"
import { ConfirmationModalContext } from "../../contexts"
import { noop } from "../../helpers/noop"
import { Dialog } from "./Dialog"

const initialState = Object.freeze({
  size: void 0,
  open: false,
  title: null,
  content: null,
  buttonText: "Confirm",
  buttonClassName: "btn btn-primary",
  onConfirm: noop,
})

const ModalActions = Object.freeze({
  Open: "open",
  Close: "close",
  Reset: "reset",
})

const reducer = (state, { action, data }) => {
  switch (action) {
    case ModalActions.Open:
      return data
    case ModalActions.Close:
      return { ...state, open: false }
    case ModalActions.Reset:
      return initialState
    default:
      return state
  }
}

export const ConfirmationModal = ({ size, open, title, buttonText, buttonClassName, onConfirm, children }) => {
  const { closeConfirmationModal } = useContext(ConfirmationModalContext)
  return (
    <Dialog size={size} open={open} title={title} onClose={closeConfirmationModal}>
      {{
        body: (
          <Row>
            <Col xs={24}>{children}</Col>
          </Row>
        ),
        footer: (
          <Row className="align-items-center fullwidth mt-4 mb-0 mx-0 gap-1">
            <Col xs={24} md="auto" className="px-0">
              <button className={`${buttonClassName} fullwidth`} onClick={onConfirm}>
                {buttonText}
              </button>
            </Col>
            <Col xs={24} md="auto" className="px-0">
              <button className="btn btn-link fullwidth" onClick={closeConfirmationModal}>
                Close
              </button>
            </Col>
          </Row>
        ),
      }}
    </Dialog>
  )
}

const ConfirmationModalProvider = ({ size = "sm", children }) => {
  const timerRef = useRef(null)
  const [{ open, title, buttonText, buttonClassName, content, onConfirm, size: stateSize }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const openConfirmationModal = params => {
    const {
      title,
      content,
      onConfirm,
      buttonText = initialState.buttonText,
      buttonClassName = initialState.buttonClassName,
    } = params

    dispatch({
      action: ModalActions.Open,
      data: {
        open: true,
        title,
        content,
        buttonText,
        buttonClassName,
        onConfirm: (...params) => {
          closeConfirmationModal()
          onConfirm(...params)
        },
      },
    })
  }

  const closeConfirmationModal = () => {
    dispatch({ action: ModalActions.Close })
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => dispatch({ action: ModalActions.Reset }), 150)
  }

  const value = {
    isConfirmationMenuOpen: open,
    openConfirmationModal,
    closeConfirmationModal,
  }
  return (
    <ConfirmationModalContext.Provider value={value}>
      {children}
      <ConfirmationModal
        size={stateSize || size}
        open={open}
        title={title}
        buttonText={buttonText}
        buttonClassName={buttonClassName}
        onConfirm={onConfirm}
      >
        {content}
      </ConfirmationModal>
    </ConfirmationModalContext.Provider>
  )
}

export default ConfirmationModalProvider
