import React, { useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Form, Field } from "react-final-form"
import { Form as BForm } from "react-bootstrap"
import SelectWithChips from "../SelectWithChips"
import { setFieldTouched, setFieldData } from "../../helpers/forms"
import { apiClient } from "../../common/apiClient"
import Alerter from "../../common/alerter"
import FormRow from "../blocks/FormRow"
import { showTouchedFieldError } from "../../helpers/forms"

const FieldNames = Object.freeze({
  DURATION: "absent_form_duration",
  STUDENTS: "absent_form_students",
})

const useFinishSession = ({ onSuccess, finishUrl }) => {
  const [loading, setLoading] = useState(false)
  const request = async request_data => {
    try {
      setLoading(true)
      await apiClient.post(finishUrl, request_data)
      if (onSuccess) onSuccess()
    } catch ({ response: { data }, message }) {
      // eslint-disable-next-line no-console
      console.error(message)
      for (const key of Object.keys(data.errors)) {
        const value = data.errors[key]
        for (const error of value) {
          Alerter.error(`${key}: ${error}`)
        }
      }
    } finally {
      setLoading(false)
    }
  }

  return { loading, request }
}

const colProps = { sm: 17, md: 14, lg: 19, xl: 8 }
const labelProps = { sm: 7, md: 5, lg: 4 }

const FinishSession = ({ duration, students, finishUrl }) => {
  const { loading, request } = useFinishSession({
    onSuccess: () => {
      window.location.reload()
    },
    finishUrl: finishUrl,
  })
  const onSubmit = data => {
    const payload = {
      attendance_details: {
        duration: parseInt(data[FieldNames.DURATION], 10),
        absent_student_ids: data[FieldNames.STUDENTS],
      },
    }
    request(payload)
  }
  const validate = formState => {
    const acc = {}
    const dur = formState[FieldNames.DURATION]
    if (dur === "" || dur === void 0) acc[FieldNames.DURATION] = "Please fill required field"
    else if (dur > duration) acc[FieldNames.DURATION] = `Duration should be less or equal ${duration}`
    else if (dur < 1) acc[FieldNames.DURATION] = "Duration should be greater or equal 1"
    return acc
  }
  return (
    <div className="panel compact mb-1 mt-4">
      <div className="panel_body">
        <Form
          initialValues={{
            [FieldNames.DURATION]: duration || "",
            [FieldNames.STUDENTS]: [],
          }}
          mutators={{ setFieldTouched, setFieldData }}
          onSubmit={onSubmit}
          validate={validate}
        >
          {({ handleSubmit, invalid }) => (
            <BForm onSubmit={handleSubmit}>
              <Row className="justify-content-between mb-4">
                <Col>
                  <h1 className="users-navbar_title">Attendance details</h1>
                </Col>
                <Col className="flex-grow-0">
                  <button
                    disabled={loading || invalid}
                    type="submit"
                    className={`btn btn-outline-primary ${loading ? "py-2" : ""}`}
                  >
                    {loading ? <div className="spinner-border text-primary" /> : "Finish session"}
                  </button>
                </Col>
              </Row>
              <Field type="number" name={FieldNames.DURATION}>
                {({ input, meta }) => (
                  <FormRow
                    label="Session duration"
                    required
                    ColProps={colProps}
                    LabelProps={labelProps}
                    RowProps={{ className: "mb-3" }}
                  >
                    <BForm.Control {...input} type="number" isInvalid={showTouchedFieldError(meta)} />
                    <BForm.Control.Feedback type="invalid">{meta.error}</BForm.Control.Feedback>
                  </FormRow>
                )}
              </Field>
              <SelectWithChips
                disabled={loading}
                name={FieldNames.STUDENTS}
                label="Absent students"
                placeholder="Select student to add"
                list={students}
                ColProps={colProps}
                LabelProps={labelProps}
              />
            </BForm>
          )}
        </Form>
      </div>
    </div>
  )
}

export default FinishSession
