import React, { useRef } from "react"
import { Col, Row, Form, Button } from "react-bootstrap"
import { Form as FForm, Field } from "react-final-form"
import FormRow from "../../blocks/FormRow"
import AsyncAutocompleteField from "../../AsyncAutocompleteField"
import { useUpdateGroupRequest } from "./hooks"

const Fields = Object.freeze({
  NAME: "name",
  OPENING_STUDENTS: "opening_students",
})

const defaultValues = {
  [Fields.NAME]: "",
  [Fields.OPENING_STUDENTS]: [],
}

const labelProps = { sm: 7, md: 5, lg: 4 }
const colProps = { sm: 17, md: 14, lg: 10, xl: 8 }

const validateForm = data => {
  const errors = {}
  const name = data[Fields.NAME]
  if (!name) errors[Fields.NAME] = "Required field"
  return errors
}

const UpdateGroupForm = ({ opening_group_id, school, initial, autoCompleteSource, redirectUrl }) => {
  const { loading, request } = useUpdateGroupRequest({
    id: opening_group_id,
    onSuccess: () => {
      setTimeout(() => window.location.assign(redirectUrl), 1000)
    },
  })

  const initialValues = useRef({ ...defaultValues, ...initial })

  const submit = data => {
    const name = data[Fields.NAME]
    const student_ids = (data[Fields.OPENING_STUDENTS] || []).map(({ value }) => parseInt(value, 10))

    request({ opening_group: { name, student_ids } })
  }

  return (
    <FForm initialValues={initialValues.current} onSubmit={submit} validate={validateForm}>
      {({ handleSubmit, invalid }) => {
        return (
          <form className="simple_form new_opening_group" onSubmit={handleSubmit}>
            <FormRow
              label="School"
              RowProps={{ className: "align-items-center" }}
              LabelProps={{ ...labelProps }}
              ColProps={{ ...colProps, className: "mb-0" }}
            >
              {school.name}
            </FormRow>
            <Field name={Fields.NAME}>
              {({ input, meta }) => (
                <FormRow
                  label="Name"
                  required
                  LabelProps={{ htmlFor: input.name, ...labelProps }}
                  ColProps={{ ...colProps }}
                >
                  <Form.Control
                    {...input}
                    placeholder="Group name"
                    className={meta.touched && meta.invalid ? "error" : ""}
                  />
                </FormRow>
              )}
            </Field>
            <Field name={Fields.OPENING_STUDENTS}>
              {({ input }) => (
                <FormRow
                  label="Students"
                  LabelProps={{ htmlFor: input.name, ...labelProps }}
                  ColProps={{ ...colProps }}
                >
                  <AsyncAutocompleteField
                    isMulti={true}
                    source={autoCompleteSource}
                    placeholder="Select student to add"
                    fieldName={input.name}
                    selected={input.value}
                    onSelect={input.onChange}
                    externalParams={{ school_id: school.id }}
                  />
                </FormRow>
              )}
            </Field>
            <Row className="form-actions">
              <Col>
                {loading ? (
                  <Button className="btn btn-outline-primary pt-2 pb-2" type="submit" disabled>
                    <div className="spinner-border text-primary" />
                  </Button>
                ) : (
                  <Button className="btn btn-outline-primary" type="submit" disabled={invalid}>
                    Update
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        )
      }}
    </FForm>
  )
}

export default UpdateGroupForm
