import React from "react"
import { Row, Col } from "react-bootstrap"
import UploadCSV from "../../../admin/users/students/CSVUpload"

export const ListActions = ({ openingId, onAddGroupClick, onCreateGroups }) => {
  return (
    <Row className="d-flex flex-shrink-0 gap-1 align-items-center">
      <Col xs={24} md="auto">
        <UploadCSV uploadButtonVariant="link" openingId={openingId} />
      </Col>
      <Col xs={24} md="auto">
        <button className="btn btn-primary flex-shrink-0" onClick={onCreateGroups} data-test="create-groups">
          Create groups
        </button>
      </Col>
      <Col xs={24} md="auto">
        <button className="btn btn-primary flex-shrink-0" onClick={onAddGroupClick}>
          Add group
        </button>
      </Col>
    </Row>
  )
}
