import React, { useContext, useEffect, useRef } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { SchoolField } from "../../../fields"
import { Field, Form as FForm } from "react-final-form"
import { DataContextActions, TableStateContext } from "../../../../contexts"
import { FieldNames } from "./constants"
import { FormAutoSubmit } from "../../components"

const initialValues = {
  [FieldNames.ShowClosed]: false,
}

export const Filters = () => {
  const { onPageChange } = useContext(TableStateContext)
  const submitAbilityRef = useRef(false)

  useEffect(() => {
    submitAbilityRef.current = true
  }, [])

  const { updateState } = useContext(DataContextActions)

  const submit = data => {
    onPageChange(1)
    updateState("openingsListFilters", data)
  }

  return (
    <FForm onSubmit={submit} initialValues={initialValues} subscription={{ values: true }}>
      {({ handleSubmit }) => (
        <FormAutoSubmit handleSubmit={handleSubmit} submitAbilityRef={submitAbilityRef}>
          <Row className="align-items-center gap-05 mb-4">
            <Col xs={24} md={12} lg={8} xl={6}>
              <SchoolField name={FieldNames.School} showTimezone={false} resettable />
            </Col>
            <Col>
              <Field name={FieldNames.ShowClosed} type="checkbox">
                {({ input }) => (
                  <Form.Check id={FieldNames.ShowClosed} label="Show closed" className="v-2" custom {...input} />
                )}
              </Field>
            </Col>
          </Row>
        </FormAutoSubmit>
      )}
    </FForm>
  )
}
