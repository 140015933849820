import React, { useMemo } from "react"
import { Row, Col } from "react-bootstrap"
import moment from "moment"
import { SHORT_WEEKDAYS } from "../../../common/constants"

const dayMapping = SHORT_WEEKDAYS.reduce((acc, { label, value: val }) => {
  const key = label.toLowerCase()
  const value = `${val.at(0).toUpperCase()}${val.substring(1)}`
  acc[key] = value
  return acc
}, Object.create(null))

const dayOrder = Object.keys(dayMapping)

export const buildTimetableDate = ({ started_at, ended_at, recurrence, timetable_flexible }) => {
  const result = [moment(started_at).format("MM/DD/YYYY")]
  if (recurrence || timetable_flexible) result.push(moment(ended_at).format("MM/DD/YYYY"))
  return result.join(" - ")
}

export const buildRecurrenceSchedule = ({ timetable }) =>
  dayOrder
    .filter(key => !!timetable[key])
    .map(key => {
      const { time, duration: d } = timetable[key]
      const duration = parseInt(d, 10)
      const [h, m] = time.split(":").map(el => parseInt(el, 10))
      const start = moment().hours(h).minutes(m)
      const end = start.clone().add(duration, "minutes")
      const weekDay = dayMapping[key] || ""
      return [weekDay, `${start.format("hh:mma")} - ${end.format("hh:mma")}`]
    })

export const buildUnitSchedule = ({ started_at, duration }) => [
  moment(started_at).format("ddd"),
  `${moment(started_at).format("hh:mma")}, ${duration} min, One-time`,
]

const TimetableView = ({ weekDay, time }) => (
  <p className="font-size-big mb-0">
    <span className="font-weight-semibold" style={{ width: 48, display: "inline-block" }}>
      {weekDay}
    </span>
    {time}
  </p>
)

const ResultView = ({ title, timetable }) => (
  <Row className="mb-2">
    <Col>
      <p className="font-weight-semibold font-size-big mb-0">{title}</p>
      {timetable}
    </Col>
  </Row>
)

export const Timetable = ({
  opening,
  TimetableTemplate = TimetableView,
  ResultTemplate = ResultView,
  buildDate = buildTimetableDate,
  buildSchedule = buildRecurrenceSchedule,
  buildUnitDate = buildUnitSchedule,
}) => {
  const { timetable, started_at, ended_at, recurrence, duration, timetable_flexible } = opening

  const title = useMemo(
    () => buildDate({ started_at, ended_at, recurrence, timetable_flexible }),
    [buildDate, ended_at, recurrence, started_at, timetable_flexible]
  )

  const week = useMemo(() => buildSchedule({ timetable }), [buildSchedule, timetable])

  const unit = useMemo(() => buildUnitDate({ started_at, duration }), [buildUnitDate, started_at, duration])

  const timeTable = useMemo(() => {
    if (timetable_flexible) return []
    return (recurrence ? week : [unit]).map(([weekDay, time], idx) => (
      <TimetableTemplate key={idx} weekDay={weekDay} time={time} />
    ))
  }, [week, unit, recurrence, timetable_flexible])

  return <ResultTemplate title={title} timetable={timeTable} />
}
