import React from "react"

import LabeledProgress from "../../blocks/LabeledProgress"

const AdditionalRating = ({ reviewsCount, engagement, effectiveness, creativity, communication }) => {
  if (reviewsCount < 5) {
    return <div>Additional ratings will be calculated once 5 or more reviews will be available</div>
  }

  return (
    <div>
      {!!engagement && <LabeledProgress label="Engagement" value={engagement} className="mb-1" />}
      {!!effectiveness && <LabeledProgress label="Effectiveness" value={effectiveness} className="mb-1" />}
      {!!creativity && <LabeledProgress label="Creativity" value={creativity} className="mb-1" />}
      {!!communication && <LabeledProgress label="Communication" value={communication} className="mb-1" />}
    </div>
  )
}

export default AdditionalRating
