import React from "react"
import cn from "classnames"
import { Form, Button } from "react-bootstrap"
import { includes, without } from "lodash/fp"

const ButtonOption = ({ children, selected, onClick }) => (
  <Button
    size="sm"
    variant="warning-light"
    className={cn("mb-2 mr-2 text-nowrap", selected && "selected")}
    onClick={onClick}
  >
    {children}
  </Button>
)

const CheckboxOption = ({ id, children, selected, onClick }) => (
  <Form.Check
    custom
    id={id}
    label={children}
    className="mb-2 mr-2 text-nowrap"
    type="checkbox"
    checked={selected}
    onChange={onClick}
  />
)

const Wrapper = ({ noWrap, children, ...props }) => (noWrap ? <>{children}</> : <div {...props}>{children}</div>)

const MultipleChoice = ({ optionType = "button", value = [], options, className, noWrap, onChange }) => {
  const Option = optionType === "button" ? ButtonOption : CheckboxOption
  const flexDirection = optionType === "button" ? "flex-row flex-wrap" : "flex-column"

  const onChooseOptions = optionId => {
    const newValue = includes(optionId)(value) ? without([optionId])(value) : [...value, optionId]
    onChange(newValue)
  }

  return (
    <Wrapper noWrap={noWrap} className={cn("d-flex", flexDirection, className)}>
      {options.map(option => (
        <Option
          id={option.value}
          key={option.value}
          selected={includes(option.value)(value)}
          onClick={() => onChooseOptions(option.value)}
        >
          {option.label}
        </Option>
      ))}
    </Wrapper>
  )
}

export default MultipleChoice
