import React, { useEffect, useState } from "react"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { Typography, TextField } from "@mui/material"
import SelectWithSearchInput from "../../../common/LookupWithSearchInput"
import { Button } from "react-bootstrap"
import moment from "moment"
import { ORGANIZATION_LIST, ALL_TUTORS_LIST } from "../../../common/constants"
import Alerter from "../../../common/alerter"
import apiClient from "../../../common/apiClient"

const getQueryParameters = () => {
  const params = new URLSearchParams(window.location.search)
  const startedDateFromQueryParams = params.get("grid[started_at]")
  const startAtDate = startedDateFromQueryParams?.split(" - ")?.[0] || ""
  const endAtDate = startedDateFromQueryParams?.split(" - ")?.[1] || ""
  const tutorId = params.get("grid[tutor]")
  const organizationId = params.get("grid[organization_id]")
  const tutorTitle = params.get("tutor_title")

  return {
    startedDateFromQueryParams,
    startAtDate,
    endAtDate,
    tutorId,
    tutorTitle,
    organizationId,
  }
}

export const TimeSheetToolbarPage = () => {
  const { tutorId, organizationId, tutorTitle, startAtDate, endAtDate } = getQueryParameters()
  const initialStartDate = startAtDate ? moment(startAtDate, "MM/DD/YYYY") : moment().add(-2, "days")
  const initialEndDate = endDate ? moment(endAtDate, "MM/DD/YYYY") : moment()
  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(initialEndDate)
  const [tutorList, setTutorList] = useState(ALL_TUTORS_LIST)
  const [selectedTutorId, setSelectedTutorId] = useState(tutorId || "all")
  const [selectedTutorTitle, setSelectedTutorTitle] = useState(tutorTitle || "All Tutors")
  const [searchTextTutor, setSearchTextTutor] = useState(tutorTitle || "all")
  const [selectedOrganization, setSelectedOrganization] = useState(organizationId || "all")

  useEffect(() => {
    const getTutors = async () => {
      try {
        const response = await apiClient.get(
          `/api/admin/autocomplete/tutors?q=${searchTextTutor || selectedTutorTitle}`
        )
        const data = response.data.items.length ? response.data.items : []
        setTutorList(data)
      } catch (error) {
        Alerter.error("Error while fetching tutors")
      }
    }
    if (searchTextTutor || selectedTutorTitle) {
      getTutors()
    }
  }, [selectedTutorTitle, searchTextTutor])

  const onSubmit = e => {
    e.preventDefault()
    const parsedStartDate = startDate.format("MM/DD/YYYY")
    const parsedEndDate = endDate.format("MM/DD/YYYY")

    const queryString = `grid[started_at]=${parsedStartDate} - ${parsedEndDate}&grid[tutor]=${selectedTutorId}&grid[organization_id]=${selectedOrganization}&commit=Search&tutor_title=${selectedTutorTitle}`

    const actionUrl = `/admin/timesheets?${queryString}`
    window.location.href = actionUrl
  }

  return (
    <form
      onSubmit={onSubmit}
      className="simple_form table-filter_form input-group input-daterange flex-wrap"
      style={{
        minHeight: "120px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="form-group string optional grid_started_at form-group-valid gap-1">
          <Typography className="min-width-label">Session Date:</Typography>
          <DatePicker
            className="form-control timesheet-picker"
            value={startDate}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "40px",
              },
              "& .MuiOutlinedInput-input": {
                maxHeight: "10px",
              },
            }}
            onChange={date => setStartDate(date)}
            slotProps={{
              actionBar: {
                actions: ["today"],
              },
            }}
            renderInput={params => <TextField name="startDate" {...params} className="form-control timesheet-picker" />}
          />
          <DatePicker
            className="form-control timesheet-picker"
            value={endDate}
            onChange={date => setEndDate(date)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "40px",
              },
              "& .MuiOutlinedInput-input": {
                maxHeight: "10px",
              },
            }}
            renderInput={params => <TextField name="endDate" {...params} className="form-control timesheet-picker" />}
          />
        </div>

        <div className="form-group string optional grid_started_at form-group-valid gap-1">
          <Typography className="min-width-label">Tutor:</Typography>
          <SelectWithSearchInput
            id="tutor"
            value={selectedTutorId}
            handleOnChangeSelect={e => {
              const selectedTitle = event?.target?.getAttribute("title")
              setSelectedTutorTitle(selectedTitle)
              setSelectedTutorId(e?.target?.value)
            }}
            defaultValue={"all"}
            optionsList={tutorList}
            placeholder="Type to search for a tutor..."
            searchText={searchTextTutor}
            setSearchText={setSearchTextTutor}
            className="form-control timesheet-picker"
            style={{
              minWidth: "200px",
            }}
          />
        </div>

        <div className="form-group string optional grid_started_at form-group-valid gap-1">
          <Typography className="min-width-label">Organization:</Typography>
          <SelectWithSearchInput
            id="organization"
            value={selectedOrganization}
            handleOnChangeSelect={e => setSelectedOrganization(e.target.value)}
            showSearchBar={false}
            searchText={""}
            optionsList={ORGANIZATION_LIST}
            placeholder="Type to search for an organization..."
            className="form-control timesheet-picker"
            style={{
              minWidth: "200px",
            }}
          />
        </div>

        <Button
          variant="outline-primary"
          className="datagrid-actions"
          style={{
            maxWidth: "200px",
          }}
          type="submit"
        >
          Search
        </Button>
      </LocalizationProvider>
    </form>
  )
}
