import React, { useCallback, useContext, useMemo } from "react"
import { DomainProvider } from "../../../providers"
import { useApiInitialPath } from "../../../hooks/useApiPath"
import useBaseHandlersBuilder from "../../../hooks/useBaseHandlersBuilder"
import {
  students,
  tutorsDialog,
  getUpdateScheduleDialog,
  updateOpeningGroupsDialog,
  matchApplication,
} from "../configurations/domain"
import { TutorCardConfigsContext } from "../contexts"
import InitialLoadingSpinner from "../../blocks/InitialLoadingSpinner"
import { TableSelectionProvider } from "../../table"
import ContextMenuProvider, { ContextMenuPositions } from "../../blocks/ContextMenu"
import { Entities } from "../../../constants"
import { DataContext, QueryContext } from "../../../contexts"
import ConfirmationModalProvider from "../../blocks/ConfirmationModal"
import Alerter from "../../../common/alerter"

export const TutorCardConfigsProvider = ({ children, groups, onMatch }) => {
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(matchApplication)
  const { openingId } = useContext(DataContext)
  const onSuccess = useCallback(() => {
    Alerter.success("Tutors successfully matched")
    if (onMatch) onMatch()
  }, [onMatch])
  const configs = useMemo(
    () => [
      {
        loading,
        text: "Match with group",
        onClick: (_, application) => {
          request({
            entitiesIds: {
              [Entities.Openings]: openingId,
            },
            data: {
              tutor_id: application.tutor.id,
              schedule_ids: groups.map(({ id }) => id),
            },
            onSuccess,
          })
        },
      },
    ],
    [groups, onSuccess, loading, openingId, request]
  )

  return <TutorCardConfigsContext.Provider value={configs}>{children}</TutorCardConfigsContext.Provider>
}

export const TutorsDialogDataProvider = ({ children }) => {
  return (
    <DomainProvider
      connectToParentStore
      config={tutorsDialog}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <InitialLoadingSpinner>{children}</InitialLoadingSpinner>
    </DomainProvider>
  )
}

export const UpdateScheduleDataProvider = ({ schedules, children }) => {
  return (
    <DomainProvider
      initialStorageState={schedules?.length === 1 ? { scheduleId: schedules[0].id } : {}}
      config={getUpdateScheduleDialog(schedules.length === 1)}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      {children}
    </DomainProvider>
  )
}

export const EmptyGroupsOpeningDataProvider = ({ children }) => {
  return (
    <DomainProvider
      config={updateOpeningGroupsDialog}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <InitialLoadingSpinner>{children}</InitialLoadingSpinner>
    </DomainProvider>
  )
}

const StudentsPageProviders = ({ initial, children }) => {
  return (
    <DomainProvider
      connectToParentStore
      initialStorageState={initial}
      config={students}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <TableSelectionProvider selectable checkSelection={(list, item) => list.some(({ id }) => item.id === id)}>
        <ContextMenuProvider positionX={ContextMenuPositions.Start} positionY={ContextMenuPositions.End}>
          <ConfirmationModalProvider>{children}</ConfirmationModalProvider>
        </ContextMenuProvider>
      </TableSelectionProvider>
    </DomainProvider>
  )
}

export default StudentsPageProviders
