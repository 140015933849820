import React, { useState } from "react"
import { Dropdown, Button, Badge } from "react-bootstrap"
import { useForm, useFormState, Field, useField } from "react-final-form"
import cn from "classnames"
import pluralize from "pluralize"

import { getLabelByValue, getFullName } from "../../helpers/presentational"

const StudentsDropdown = ({
  getButtonText,
  completed,
  disabled,
  options: { students, grades, specialTreatments },
  className,
  onSubmit,
}) => {
  const [showPopper, setShowPopper] = useState(false)

  const form = useForm()
  const { values } = useFormState()
  const { input } = useField("student_id")

  const onChooseStudent = student => {
    const shouldReset = student.id === input.value

    if (shouldReset) {
      form.mutators.clearFieldsData(["student_id", "grade", "program_ids", "struggle_with", "special_treatments"])
      onToggle(false)
      return
    }
    form.mutators.setFieldData("grade", student.grade)
    form.mutators.setFieldData("special_treatments", student.special_treatments)
    input.onChange(student.id)
    onToggle(false)
  }

  const onToggle = show => {
    if (show) {
      setShowPopper(true)
    } else {
      setShowPopper(false)
      onSubmit && onSubmit()
    }
  }

  return (
    <Field name="student_id">
      {({ input }) => (
        <Dropdown show={showPopper} onToggle={onToggle}>
          <Dropdown.Toggle
            variant="outline-dropdown"
            disabled={disabled}
            className={cn(className, "dropdown-toggle-no-icon", completed && "selected")}
          >
            {getButtonText()}
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-4 min-w-md-600-px max-vw-100 overflow-auto">
            <p className="mb-4">You have {pluralize("student", students.length, true)} configured in your profile</p>
            <table>
              <tbody>
                {students.map(student => (
                  <tr key={student.id}>
                    <td style={{ verticalAlign: "top" }}>
                      <Button
                        type="button"
                        variant="warning-light"
                        size="sm"
                        className={cn("mb-2 mr-2 text-nowrap", input.value === student.id && "selected")}
                        onClick={() => onChooseStudent(student)}
                      >
                        {getFullName(student)}
                      </Button>
                    </td>
                    <td className="d-flex align-items-start flex-grow-1 flex-wrap">
                      <Badge
                        className={cn(
                          "badge-pill badge-light mr-2 mb-2",
                          input.value === student.id && values.grade === student.grade && "active"
                        )}
                      >
                        {getLabelByValue(grades)(student.grade)}
                      </Badge>
                      {student.special_treatments.map(id => (
                        <Badge
                          key={id}
                          className={cn(
                            "badge-pill badge-light mr-2 mb-2",
                            input.value === student.id && values.special_treatments?.includes(id) && "active"
                          )}
                        >
                          {getLabelByValue(specialTreatments)(id)}
                        </Badge>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Field>
  )
}

export default StudentsDropdown
