import BRTThemeProvider from "@/providers/theme"
import React from "react"
import Navbar from "../../../Navbar"
import StudentAssignmentsPage from "../../../student_assignments"
import useFetchStudent from "./../hooks/useFetchStudent"

export const StudentDashboardStudentAssignmentsPage = ({ currentUser, sign_out_button, programName, studentName }) => {
  const student_id = currentUser?.id
  const { studentData } = useFetchStudent({
    student_id,
  })

  if (!studentData) return null

  return (
    <BRTThemeProvider>
      <header className="header">
        <Navbar user={studentData} sign_out_button={sign_out_button} />
      </header>
      <StudentAssignmentsPage currentUser={currentUser} programName={programName} studentName={studentName} />
    </BRTThemeProvider>
  )
}

export default StudentDashboardStudentAssignmentsPage
