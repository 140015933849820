import React, { useContext } from "react"
import { OpeningContext } from "./context"
import { Students } from "../../common"

const Step1Students = ({ groups, schools }) => {
  const { opening, setOpening } = useContext(OpeningContext)

  return <Students opening={opening} setOpening={setOpening} groups={groups} schools={schools} />
}

export default Step1Students
