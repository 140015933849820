import Alerter from "@/common/alerter"
import apiClient from "@/common/apiClient"
import { useCallback, useEffect, useState } from "react"
import { FILTER_API_PARAM_MAP, INITIAL_PAGINATION } from "../constants"

export const fetchStudents = async (studentsAPIEndpoint, pageSize, pageNumber, paginate, filters = {}) => {
  const response = await apiClient.get(studentsAPIEndpoint, {
    params: { per_page: pageSize, page: pageNumber + 1, paginate, ...filters },
  })
  const {
    data: { students, google_sso_enabled, per_page, total_count, page },
  } = response
  return {
    students,
    googleSSOEnabled: Boolean(google_sso_enabled),
    totalCount: Number(total_count),
    perPage: Number(per_page),
    page: Number(page) - 1,
  }
}

const transformFiltersForAPI = filterModel => {
  if (!filterModel) return {}

  const { items } = filterModel

  return items.reduce((acc, filter) => {
    const { field, operator, value } = filter
    const apiParam = FILTER_API_PARAM_MAP[field] || field

    if (!apiParam) return acc

    if (field === "groups" && Array.isArray(value)) {
      acc[apiParam] = value
      return acc
    }

    let transformedValue = value

    switch (operator) {
      case "contains":
        transformedValue = `%${value}%`
        break
      case "startsWith":
        transformedValue = `${value}%`
        break
      case "endsWith":
        transformedValue = `%${value}`
        break
      case "isEmpty":
        transformedValue = ""
        break
      case "isNotEmpty":
        transformedValue = "!empty"
        break
      case "in":
        if (Array.isArray(value)) {
          acc[apiParam] = value
          return acc
        }
        break
    }

    if (acc[apiParam]) {
      acc[apiParam] = Array.isArray(acc[apiParam])
        ? [...acc[apiParam], transformedValue]
        : [acc[apiParam], transformedValue]
    } else {
      acc[apiParam] = transformedValue
    }

    return acc
  }, {})
}

const useFetchStudents = studentsAPIEndpoint => {
  const [data, setData] = useState({ students: [], totalCount: 0 })
  const [status, setStatus] = useState({ isLoading: false, error: null })

  const fetchData = useCallback(
    async (pagination = INITIAL_PAGINATION, filters) => {
      setStatus({ isLoading: true, error: null })
      try {
        const result = await fetchStudents(
          studentsAPIEndpoint,
          pagination?.pageSize,
          pagination?.page,
          pagination?.paginate,
          transformFiltersForAPI(filters)
        )

        setData(result)
      } catch (error) {
        setStatus({ isLoading: false, error })
        Alerter.error(`Failed to fetch Students. Error: ${error.message}`)
      } finally {
        setStatus(prevStatus => ({ ...prevStatus, isLoading: false }))
      }
    },
    [studentsAPIEndpoint]
  )

  const fetchAllStudents = useCallback(
    async (pagination, filters) => {
      setStatus({ isLoading: true, error: null })
      try {
        const result = await fetchStudents(
          studentsAPIEndpoint,
          pagination.pageSize,
          pagination.page,
          false,
          transformFiltersForAPI(filters)
        )
        return result.students.map(student => student.id)
      } catch (error) {
        setStatus({ isLoading: false, error })
        Alerter.error(`Failed to fetch all Students. Error: ${error.message}`)
        return []
      } finally {
        setStatus(prevStatus => ({ ...prevStatus, isLoading: false }))
      }
    },
    [studentsAPIEndpoint]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return { data, status, fetchData, fetchAllStudents }
}

export default useFetchStudents
