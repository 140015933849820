import React, { useContext, useMemo, useRef } from "react"
import { TextRow } from "./text_row"
import { StatusDropdown } from "./status_dropdown"
import { startFromMapper } from "./text_mappers"
import { DataContext } from "../../../../contexts"

const OpeningInformationStatistic = () => {
  const anchorRef = useRef(null)
  const { opening } = useContext(DataContext)

  const percentage = useMemo(() => {
    if (opening.schedules_matched_count === null || opening.schedules_count === 0) return ""
    return 100 - Math.round((opening.schedules_matched_count / opening.schedules_count) * 100)
  }, [opening])

  return (
    <div ref={anchorRef} className="d-flex flex-column gap-1">
      <TextRow
        labelClassName="font-size-medium font-weight-medium text-black"
        label="Status"
        value={<StatusDropdown status={opening.status} anchorRef={anchorRef} />}
      />
      <TextRow
        labelClassName="font-size-medium font-weight-medium text-black"
        valueClassName="font-size-medium font-weight-semibold"
        label="Start"
        value={startFromMapper(opening.start_on)}
      />
      <TextRow
        labelClassName="font-size-medium font-weight-medium text-black"
        valueClassName="font-size-medium font-weight-semibold"
        label="Tutors"
        value={
          <>
            {opening.applications_count} added
            {/*<br />*/}
            {/*{opening.applications_matched_count} matched*/}
          </>
        }
      />
      <TextRow
        labelClassName="font-size-medium font-weight-medium text-black"
        valueClassName="font-size-medium font-weight-semibold"
        label="Groups"
        value={
          <>
            {opening.schedules_count} applied
            {opening.schedules_matched_count !== null && opening.schedules_count > 0 && (
              <>
                <br />
                {opening.schedules_matched_count} matched ({percentage}% left)
              </>
            )}
          </>
        }
      />
    </div>
  )
}

export default OpeningInformationStatistic
