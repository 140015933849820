import React, { useContext } from "react"
import { Dialog } from "../blocks/Dialog"
import { DataContext, InitialLoadingContext } from "../../contexts"
import { ConnectionsTable } from "./ConnectionsTable"

export const TutorClientPickerDialog = ({ open, onClose }) => {
  const { connections } = useContext(DataContext)
  const { initialLoading } = useContext(InitialLoadingContext)

  return (
    <Dialog open={open} onClose={onClose} size="xl">
      {initialLoading ? (
        <div className="d-flex align-content-center justify-content-center">
          <div className="spinner-border text-primary" />
        </div>
      ) : (
        <div className="mt-2">
          <ConnectionsTable connections={connections ?? []} />
        </div>
      )}
    </Dialog>
  )
}
