import React, { Fragment } from "react"
import { Field } from "react-final-form"
import Switch from "../../blocks/Switch"
import RowWarning from "../../blocks/RowWarning"
import { required } from "../../../helpers/validators"

import { ReactComponent as JustifactsIcon } from "../../../assets/images/justifacts.svg"

const FormFields = ({ dataSources: { user, urls }, values }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-16">
          <RowWarning className="panel_body-warning" name="link_com_complete">
            <div className="row justify-content-between align-items-center">
              <div className="col col-lg-auto  d-flex flex-column">
                <JustifactsIcon />
                <span className="mt-2 profile-third-party-description">
                  Please visit Justifacts to complete your background check
                </span>
              </div>
              <div className="col-24 my-3 d-lg-none" />
              <div className="col col-lg-auto col-24 min-w-200-px">
                <a target="_blank" href={urls.justifacts} rel="next noreferrer">
                  <button
                    type="button"
                    className="btn btn-outline-primary fullwidth"
                    disabled={values.link_com_complete}
                  >
                    Go to Justifacts
                  </button>
                </a>
              </div>
            </div>
          </RowWarning>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-16">
          <hr />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-24">
          <div className="row mb-2">
            <div className="col-lg-24">
              <RowWarning className="panel_body-warning" name="link_com_complete">
                <Field
                  name="link_com_complete"
                  initialValue={user.profile.link_com_complete}
                  type="checkbox"
                  validate={required}
                >
                  {({ input }) => (
                    <Switch
                      wrapperClassName="profile-third-party-switch"
                      {...input}
                      label="I confirm that I’ve submitted data to Justifacts"
                    />
                  )}
                </Field>
              </RowWarning>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-24">
              <RowWarning className="panel_body-warning" name="confirmed_terms">
                <Field
                  name="confirmed_terms"
                  initialValue={user.profile.confirmed_terms}
                  type="checkbox"
                  validate={required}
                >
                  {({ input }) => (
                    <Switch
                      {...input}
                      wrapperClassName="profile-third-party-switch"
                      label="I am at least 18 years of age, I live and am authorized to work in the United States, and I have a valid Social Security number"
                    />
                  )}
                </Field>
              </RowWarning>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default FormFields
