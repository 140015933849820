import React from "react"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { putAndRedirect } from "../../../common/apiClient"
import FormFields from "../degrees/FormFields"
import PendingChanges from "./PendingChanges"

const DegreesAdmin = ({ dataSources }) => {
  const onSubmit = values => {
    putAndRedirect(dataSources.urls.save, dataSources.urls.next, values)
  }

  const draftDegrees = user => user.profile.degrees.filter(el => el.review_status === "draft")

  const degreesToShow = draftDegrees(dataSources.user)

  return (
    <div className="container">
      <div className="row">
        <PendingChanges dataSources={dataSources} degreesToShow={degreesToShow} />
      </div>

      <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }}>
        {({ handleSubmit, submitting }) => (
          <>
            <div className="row">
              <div className="col-lg-18">
                {degreesToShow.length > 0 ? <h4>Approved</h4> : null}
                <FormFields dataSources={dataSources} admin={true} />
              </div>
            </div>
            <button type="button" className="btn btn-outline-primary mt-4" onClick={handleSubmit} disabled={submitting}>
              Save and next
            </button>
          </>
        )}
      </Form>
    </div>
  )
}
export default DegreesAdmin
