import React, { useState } from "react"
import cn from "classnames"
import { Dropdown } from "react-bootstrap"
import { useForm, useFormState } from "react-final-form"
import { useMemo } from "react"

const priceOptions = [
  {
    key: 50,
    label: "$50 or less / hour",
    minPrice: null,
    maxPrice: 50,
  },
  {
    key: 100,
    label: "Up to $100 / hour",
    minPrice: null,
    maxPrice: 100,
  },
  {
    key: 150,
    label: "Up to $150 / hour",
    minPrice: null,
    maxPrice: 150,
  },
  {
    key: "any",
    label: "Show all rates",
    minPrice: null,
    maxPrice: null,
  },
]

const SimplePriceDropdown = ({ completed, onSubmit }) => {
  const [showPopper, setShowPopper] = useState(false)

  const form = useForm()
  const { values } = useFormState()

  const buttonText = useMemo(() => {
    if (values.min_price) {
      return `$${values.min_price} / hour or more`
    }
    if (values.max_price) {
      return `Up to $${values.max_price} / hour`
    }
    return "Price"
  }, [values])

  const onChange = option => {
    form.mutators.setFieldData("min_price", option.minPrice)
    form.mutators.setFieldData("max_price", option.maxPrice)

    setShowPopper(false)
    onSubmit()
  }

  return (
    <Dropdown show={showPopper} onToggle={setShowPopper}>
      <Dropdown.Toggle
        variant="outline-dropdown"
        className={cn("dropdown-toggle", "fullwidth", completed && "selected")}
      >
        {buttonText}
      </Dropdown.Toggle>
      <Dropdown.Menu className="-fullwidth">
        {priceOptions.map(option => (
          <Dropdown.Item key={option.key} onClick={() => onChange(option)}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SimplePriceDropdown
