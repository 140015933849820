import React from "react"

const TabsNav = ({ currentUser, currentPath }) => {
  const { permissions: userPolicies } = currentUser
  const navActiveClass = path => (currentPath === path ? "-active" : "")

  const renderLink = (href, label, dataTest) => (
    <li>
      <a
        href={href}
        className={`tabs-nav_item ${navActiveClass(href)}`}
        data-turbolinks="false"
        aria-current={currentPath === href ? "page" : undefined}
        data-test={dataTest}
      >
        {label}
      </a>
    </li>
  )

  return (
    <nav className="tabs-nav" aria-label="Main Navigation">
      <ul>
        {userPolicies.show_tutor_header && (
          <>
            {renderLink("/tutor/parents", "Parents")}
            {renderLink("/tutor/students", "Students")}
            {renderLink("/tutor/school_students", "School Students")}
            {userPolicies.can_invite_parent && renderLink("/tutor/invite_parents", "Invitations")}
          </>
        )}
        {userPolicies.show_admin_header && (
          <>
            {renderLink("/admin/parents", "Parents")}
            {renderLink("/admin/students", "Students", "students")}
            {renderLink("/admin/tutors", "Tutors", "tutors")}
            {renderLink("/admin/admins", "Admins")}
            {renderLink("/admin/school_admins", "School Admins")}
            {userPolicies.can_manage_openings && renderLink("/admin/partnerships", "Partnerships")}
            {renderLink("/admin/search_users", "Search")}
          </>
        )}
      </ul>
    </nav>
  )
}

export default TabsNav
