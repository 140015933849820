import React from "react"
import { Badge, Col, Container, Row } from "react-bootstrap"

import useViewport from "../../hooks/useViewport"

const Message = ({ message }) => {
  const { isMobileViewport } = useViewport()

  return (
    <Container className="p-2">
      <Row>
        {message.sender_role === "parent" && (
          <Col xs={{ span: 4 }} sm={{ span: 3 }} lg={{ span: 2 }} className="text-center">
            <Badge pill className="bg-primary text-white p-0 parent-badge">
              {message.sender_initials}
            </Badge>
          </Col>
        )}
        <Col className={message.sender_role === "tutor" ? "text-right" : ""}>
          <div className="message-subject text-gray-chat">Subject: {message.subject}</div>
          <div className="message-created-at text-gray-chat">{message.created_at}</div>
        </Col>
        {message.sender_role === "tutor" && (
          <Col xs={{ span: 4 }} sm={{ span: 3 }} lg={{ span: 2 }} className="text-center">
            <Badge pill className="bg-yellow text-white p-0 tutor-badge">
              {message.sender_initials}
            </Badge>
          </Col>
        )}
      </Row>
      <Row>
        {message.sender_role === "parent" && !isMobileViewport && (
          <Col xs={{ span: 4 }} sm={{ span: 3 }} lg={{ span: 2 }} className="text-center">
            <div className="text-gray-chat text-small">Parent</div>
          </Col>
        )}
        <Col className={message.sender_role === "tutor" ? "text-right" : ""}>
          <div className="messageBody pb-3">{message.body}</div>
        </Col>
        {message.sender_role === "tutor" && !isMobileViewport && (
          <Col xs={{ span: 4 }} sm={{ span: 3 }} lg={{ span: 2 }} className="text-center">
            <div className="text-gray-chat text-small">Tutor</div>
          </Col>
        )}
      </Row>
      <hr className="my-1" />
    </Container>
  )
}

export default Message
