import React from "react"
import { Row, Col, Modal, Button, FormControl } from "react-bootstrap"
import { Form, Field } from "react-final-form"
import { required } from "../../../helpers/validators"

const SessionDeclineModal = ({ show, onSubmit, onClose }) => {
  return (
    <Modal show={show} size="lg" data-backdrop="static" onHide={onClose} centered>
      <Modal.Header className="px-4 pt-3 pb-2" closeButton>
        <Modal.Title>Decline reason</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form onSubmit={onSubmit}>
          {({ submitting, invalid, handleSubmit }) => (
            <Row>
              <Col lg={18}>
                <p className="pb-2">Please add a comment for the parent. Why are you declining this request?</p>

                <Field name="reason" validate={required}>
                  {({ input }) => <FormControl {...input} as="textarea" rows={5} />}
                </Field>

                <div className="mt-5">
                  <Button variant="outline-primary" disabled={invalid || submitting} onClick={handleSubmit}>
                    Decline request
                  </Button>
                  <Button variant="link" className="link-primary ml-5" onClick={onClose}>
                    Back
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default SessionDeclineModal
