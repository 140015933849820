import React from "react"

const UsersNavbar = ({ showDescription = true }) => (
  <nav className="users-navbar mb-0">
    <div className="users-navbar_container container flex-column align-items-start">
      <h1 className="users-navbar_title">Let us help you find the perfect tutoring match!</h1>
      {showDescription && (
        <p className="font-weight-normal mt-2 mb-3">
          Choose from the filters below so we can learn more about your child as a learner.
        </p>
      )}
    </div>
  </nav>
)

export default UsersNavbar
