import React, { useState } from "react"
import { Dropdown, Button } from "react-bootstrap"
import { useForm, useFormState, Field } from "react-final-form"
import cn from "classnames"
import pluralize from "pluralize"
import { filter, isEmpty, uniq } from "lodash/fp"

import { getLabelByValue, getFullName } from "../../helpers/presentational"
import MultipleChoice from "../blocks/MultipleChoice"

const OptionButton = ({ selected, children, onClick }) => (
  <Button
    type="button"
    size="sm"
    variant="warning-light"
    className={cn("mb-2 mr-2 text-nowrap", selected && "selected")}
    onClick={onClick}
  >
    {children}
  </Button>
)

const StudentButton = ({ children, onClick }) => (
  <Button type="button" size="sm" className="btn-outline-primary mb-2 mr-2 text-nowrap" onClick={onClick}>
    {children}
  </Button>
)

const StudentsDropdown = ({ getButtonText, options: { students, grades, specialTreatments }, className, onSubmit }) => {
  const [showPopper, setShowPopper] = useState(false)
  const [initialValues, setInitialValues] = useState({})

  const form = useForm()
  const { values } = useFormState()

  const isGradeModified = initialValues.grade !== values.grade
  const isTreatmentModified = initialValues.special_treatments !== values.special_treatments

  const getStudentSpecialTreatments = ids => filter(({ value }) => ids.includes(value))(specialTreatments)

  const onChooseStudent = student => {
    const isEveryTreatmentSelected =
      !isEmpty(values.special_treatments) &&
      student.special_treatments.every(id => values.special_treatments.includes(id))
    const shouldReset = student.grade === values.grade && isEveryTreatmentSelected

    if (shouldReset) {
      form.mutators.clearFieldsData(["grade", "program_ids", "struggle_with", "special_treatments"])
      return
    }
    if (student.grade) {
      form.mutators.setFieldData("grade", student.grade)
    }
    if (!isEmpty(student.special_treatments)) {
      const studentSpecialTreatments = student.special_treatments || []
      const choosedSpecialTreatments = values.special_treatments || []
      form.mutators.setFieldData("special_treatments", uniq([...choosedSpecialTreatments, ...studentSpecialTreatments]))
    }
  }

  const onChooseGrade = grade => {
    if (values.grade === grade) {
      form.mutators.clearFieldData("grade")
    } else {
      form.mutators.setFieldData("grade", grade)
    }
  }

  const onToggle = show => {
    if (show) {
      setInitialValues(values)
      setShowPopper(true)
      return
    }

    setShowPopper(false)
    if (isGradeModified || isTreatmentModified) {
      onSubmit && onSubmit()
    }
  }

  return (
    <Dropdown show={showPopper} onToggle={onToggle}>
      <Dropdown.Toggle variant="outline-dropdown" className={cn(className, "dropdown-toggle-no-icon")}>
        {getButtonText()}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-4 min-w-md-600-px max-vw-100 overflow-auto">
        <p className="mb-4">You have {pluralize("student", students.length, true)} configured in your profile</p>
        <table>
          <tbody>
            {students.map(student => (
              <tr key={student.id}>
                <td style={{ verticalAlign: "top" }}>
                  <StudentButton onClick={() => onChooseStudent(student)}>{getFullName(student)}</StudentButton>
                </td>
                <td className="d-flex align-items-start flex-grow-1 flex-wrap">
                  <OptionButton selected={values.grade === student.grade} onClick={() => onChooseGrade(student.grade)}>
                    {getLabelByValue(grades)(student.grade)}
                  </OptionButton>
                  <Field name="special_treatments">
                    {({ input }) => (
                      <MultipleChoice
                        {...input}
                        options={getStudentSpecialTreatments(student.special_treatments)}
                        noWrap
                      />
                    )}
                  </Field>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default StudentsDropdown
