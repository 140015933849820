import React from "react"
import cn from "classnames"

const CertificateInfo = ({ IconComponent, text, className }) => {
  return (
    <div className={cn("certificate-info", className)}>
      <div className="certificate-info_icon">
        <IconComponent />
      </div>
      <div className="certificate-info_text">{text}</div>
    </div>
  )
}

export default CertificateInfo
