import React, { useState } from "react"
import { OpeningContext } from "./context"
import OpeningBody from "./OpeningBody"
import { serializeGradesFromHierarchy, serializeStates } from "../../../../helpers/serializers"
import { programsHierarchyToList } from "../../../../helpers/programsHierarchy"
import { serializeSpecialTreatments } from "../../../../helpers/serializers"
import { formatDateKeepZone } from "../../../../helpers/dates"

const Index = ({
  hierarchy,
  struggleWithQuestions,
  states,
  timeZone,
  permissions,
  urls,
  opening,
  specialTreatments,
}) => {
  const [state] = useState(() => ({
    group: opening.group,
    student: opening.student,
    grade: opening.grade,
    groupIds: opening.opening_groups_ids,
    openingGroups: opening.opening_groups,
    school: opening.school,
    defaultProgramId: opening.default_program_id,
    struggleWith: opening.struggle_with,
    meeting_format: opening.meeting_format,
    meeting_info: opening.meeting_info,
    address_1: opening.address_1,
    address_2: opening.address_2,
    city: opening.city,
    state: opening.state,
    zip: opening.zip,
    recurrence: opening.recurrence,
    schedule: opening.schedule,
    started_at: opening.started_at,
    ended_at: opening.ended_at,
    additionalInformation: opening.information,
    duration: opening.duration,
    executive_function: opening.executive_function,
    special_treatments: opening.special_treatments,
    subject: opening.subject,
    additionalEmails: opening.additional_emails,
    sessionsNotify: opening.sessions_notify,
    tutor_rate: opening.tutor_rate,
    school_rate: opening.school_rate,
    weekly_reports: opening.weekly_reports,
    schedule_flexible: opening.schedule_flexible,
    schedule_preference: opening.schedule_preference,
    status: opening.status,
    custom_program: opening.custom_program,
    is_per_hour_rate: open.is_per_hour_rate,
    max_sessions: opening.max_sessions,
    max_paid_noshows: opening.max_paid_noshows || 0,
    max_duration: opening.max_duration,
    ttg_start_at: formatDateKeepZone(opening.ttg_start_at, "MM/DD/YYYY"),
    ttg_end_at: opening.ttg_end_at ? formatDateKeepZone(opening.ttg_end_at, "MM/DD/YYYY") : undefined,
  }))
  // eslint-disable-next-line no-console
  const [tutorList, setTutorList] = useState(() => [...opening.tutor_applications])

  const [options] = useState(() => ({
    hierarchy,
    timeZone,
    struggleWithQuestions,
    states: serializeStates(states),
    grades: serializeGradesFromHierarchy(hierarchy),
    programs: programsHierarchyToList(hierarchy),
    specialTreatments: serializeSpecialTreatments(specialTreatments),
  }))

  const openingContextValue = {
    opening: state,
    options,
    tutorList,
    setTutorList,
    permissions,
    urls,
    openingId: opening.id,
  }

  return (
    <OpeningContext.Provider value={openingContextValue}>
      <OpeningBody />
    </OpeningContext.Provider>
  )
}

export default Index
