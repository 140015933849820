import React from "react"
import { Button, Card, Row, Col, Badge } from "react-bootstrap"
import cn from "classnames"

import { getPrivateFullName, getInitials, getTutorSpecializationsText } from "../../../helpers/presentational"
import InterviewSlot from "../schedule_interview/InterviewSlot"
import Avatar from "../../blocks/Avatar"
import { REQUESTED_INTERVIEW, TIME_SUGGESTED_INTERVIEW } from "../../../common/constants"

const ParentRequestedInterviewCard = ({ interview, focused, hierarchy, onEdit, onDelete }) => {
  const { tutor } = interview
  const tutorName = getPrivateFullName(tutor)
  const tutorInitials = getInitials(tutor)

  const specializationsText = getTutorSpecializationsText(tutor.programs, { hierarchy })

  return (
    <Card className={cn("card-outline mb-4", focused && "focused")} id={interview.id} body>
      {interview.status === REQUESTED_INTERVIEW && (
        <div className="mb-4">
          {interview.start_at_slots.length === 1 ? (
            <InterviewSlot start={interview.start_at_slots[0]} />
          ) : (
            <h6>You suggested several time slots</h6>
          )}
        </div>
      )}

      <Row className="d-flex flex-column flex-md-row mb-4">
        <Col className="w-180-px" lg="auto">
          <Avatar initials={tutorInitials} photoUrl={tutor.profile.photo_url} />
        </Col>
        <Col className="flex-grow-1 mt-3 mt-md-0">
          <h5 className="tutor-card_name">{tutorName}</h5>
          <div className="tutor-card_specializations mb-3">{specializationsText}</div>
          <a href={tutor.profile_link} target="_blank" rel="noreferrer">
            Full profile
          </a>
        </Col>
      </Row>

      {interview.additional_notes && (
        <Row className="mt-4">
          <Col className="form-label w-180-px" lg="auto">
            Interview notes
          </Col>
          <Col lg={12} className="font-weight-medium">
            {interview.additional_notes}
          </Col>
        </Row>
      )}

      <div className="d-flex justify-content-between align-self-stretch align-items-md-center flex-column flex-md-row mt-4">
        {interview.status === REQUESTED_INTERVIEW && (
          <Badge variant="info" className="default-badge text-wrap text-left mb-3 mb-md-0">
            Waiting for tutor to accept
          </Badge>
        )}
        {interview.status === TIME_SUGGESTED_INTERVIEW && (
          <Badge variant="warning" className="default-badge text-wrap text-left mb-3 mb-md-0">
            {tutorName} suggested new times for an interview
          </Badge>
        )}
        <div className="d-flex flex-column-reverse flex-md-row">
          <Button variant="link" className="link-error mt-3 mt-md-0" onClick={onDelete}>
            Delete request
          </Button>
          <Button variant="outline-primary" className="btn-sm ml-0 ml-md-4" onClick={onEdit}>
            {interview.status === REQUESTED_INTERVIEW ? "Edit request" : "Pick a Time"}
          </Button>
        </div>
      </div>
    </Card>
  )
}

export default ParentRequestedInterviewCard
