import React, { useState, useEffect, useRef } from "react"
import { kebabCase } from "lodash"
import cn from "classnames"
import { connect } from "react-redux"
import { SELECT, DESELECT } from "../store/constants"
import {
  MASTERED_EARLIER,
  MASTERED,
  WORKING_ON,
  STUDIED_EARLIER,
  EMPTY,
  WORKING_ON_EARLIER,
} from "../subskill_statuses"

const Subskill = ({ subskill, readOnlyMode = false, dispatch, selected }) => {
  const [status, setStatus] = useState(subskill.status)
  const [dropdown, toggleDropdown] = useState(false)
  const didMountRef = useRef(false)
  const popupRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdown && !popupRef.current?.contains(event.target)) {
        toggleDropdown(false)
      }
    }

    if (dropdown) {
      document.addEventListener("mousedown", handleClickOutside)
      return () => document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [dropdown])

  const setEmpty = () => {
    !readOnlyMode && setStatus(EMPTY)
  }
  const setMastered = () => {
    !readOnlyMode && setStatus(MASTERED)
  }
  const setWorkingOn = () => {
    !readOnlyMode && setStatus(WORKING_ON)
  }
  const setWorkingOnEarlier = () => {
    !readOnlyMode && setStatus(WORKING_ON_EARLIER)
  }
  const setMasteredEarlier = () => {
    !readOnlyMode && setStatus(MASTERED_EARLIER)
  }
  const setStudiedEarlier = () => {
    !readOnlyMode && setStatus(STUDIED_EARLIER)
  }

  useEffect(() => {
    if (didMountRef.current) {
      if (selected && status === EMPTY) {
        dispatch({ type: DESELECT, payload: subskill })
      } else {
        dispatch({ type: SELECT, payload: { ...subskill, status } })
      }
    } else {
      didMountRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const workingOnPartial = () => {
    if (!(subskill.status === WORKING_ON)) {
      return null
    }
    switch (status) {
      case WORKING_ON:
        if (subskill.previous_status === STUDIED_EARLIER) {
          return <button onClick={setStudiedEarlier} />
        } else if (subskill.previous_status === MASTERED_EARLIER) {
          return <button onClick={setMasteredEarlier} />
        } else if (subskill.previous_status === MASTERED) {
          return <button onClick={setMasteredEarlier} />
        } else {
          return <button onClick={setEmpty} />
        }

      case EMPTY:
        return <button onClick={setMastered} />
      case MASTERED:
        return <button onClick={setWorkingOn} />
      case MASTERED_EARLIER:
        return <button onClick={setWorkingOn} />
      default:
        return null
    }
  }

  return (
    <div className="report_subskill-wrapper" title={subskill.name}>
      <div
        className={cn("report_subskill", "color", {
          // FROM PREVIOUS SESSION
          "-empty": status === EMPTY,
          "-working-on-earlier": status === WORKING_ON_EARLIER,
          "-mastered-earlier": status === MASTERED_EARLIER,

          // RUNTIME CHANGES
          "-mastered": status === MASTERED,
          "-working-on": status === WORKING_ON,
          "-studied-earlier": status === STUDIED_EARLIER,
        })}
        data-test={`subskill-${kebabCase(subskill.name)}`}
      >
        <div className="report_subskill-text">{subskill.name}</div>
        <div className="report_subskill-toggle">
          {subskill.status === EMPTY && (
            <>
              {status === EMPTY && <button onClick={setMastered} />}
              {status === MASTERED && <button onClick={setWorkingOn} />}
              {(status === WORKING_ON || status === STUDIED_EARLIER) && <button onClick={setEmpty} />}
            </>
          )}

          {subskill.status === WORKING_ON_EARLIER && (
            <>
              {status === WORKING_ON_EARLIER && <button onClick={setMastered} />}
              {status === MASTERED && <button onClick={setWorkingOn} />}
              {status === WORKING_ON && <button onClick={setWorkingOnEarlier} />}
            </>
          )}

          {subskill.status === MASTERED_EARLIER && (
            <>
              {status === MASTERED_EARLIER && <button onClick={setWorkingOn} />}
              {status === WORKING_ON && <button onClick={setMasteredEarlier} />}
              {status === EMPTY && <button onClick={setWorkingOn} />}
            </>
          )}
          {subskill.status === MASTERED && (
            <>
              {status === MASTERED && <button onClick={setWorkingOn} />}
              {status === WORKING_ON && <button onClick={setEmpty} />}
              {status === EMPTY && <button onClick={setMastered} />}
            </>
          )}
          {workingOnPartial()}
          {subskill.status === STUDIED_EARLIER && (
            <>
              {status === STUDIED_EARLIER && <button onClick={setEmpty} />}
              {status === EMPTY && <button onClick={setMastered} />}
              {status === WORKING_ON && <button onClick={setEmpty} />}
              {status === MASTERED && <button onClick={setWorkingOn} />}
            </>
          )}
        </div>

        {status === EMPTY && !readOnlyMode && (
          <div ref={popupRef} className="report_subskill-burger dropdown">
            <div className="report_subskill-burger-icon" onClick={() => toggleDropdown(oldState => !oldState)} />
            <div
              className={cn("dropdown-menu", {
                show: dropdown,
                "-align-right": dropdown,
              })}
            >
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  setStudiedEarlier()
                  toggleDropdown(false)
                }}
              >
                Studied earlier
              </button>
            </div>
          </div>
        )}

        <div className="report_subskill-checkbox" />
      </div>
    </div>
  )
}
export default connect((state, { subskill }) => ({
  selected: Boolean(state.selectedSubskills[subskill.id]),
}))(Subskill)
