import React, { useCallback, useContext } from "react"
import { Form } from "react-bootstrap"
import { Form as FForm, Field } from "react-final-form"
import { formatStrToFloatWithRound } from "../../../helpers/formatters"
import FormRow from "../../blocks/FormRow"
import useViewport from "../../../hooks/useViewport"
import { className } from "../../../helpers/className"
import { QueryContext } from "../../../contexts"
import { createEmptyGroup } from "../configurations/domain"
import { Entities } from "../../../constants"

const FieldNames = Object.freeze({
  Prefix: "prefix",
  Count: "count",
})

const initialValues = {
  [FieldNames.Prefix]: "",
  [FieldNames.Count]: "",
}

const validate = data => {
  const errors = {}

  if (!data[FieldNames.Prefix]) errors[FieldNames.Prefix] = "Field is required"
  if (!data[FieldNames.Count]) errors[FieldNames.Count] = "Field is required"
  else if (typeof data[FieldNames.Count] !== "number") errors[FieldNames.Count] = "Count should be a number"
  else if (data[FieldNames.Count] <= 0 || data[FieldNames.Count] > 100)
    errors[FieldNames.Count] = "Count should be in range from 1 to 100"

  return errors
}

export const CreateEmptyGroupsForm = ({ onSubmit, loading }) => {
  const { isMobileViewport } = useViewport()
  return (
    <FForm onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
      {({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div className="d-flex mt-2 flex-column gap-1">
            <FormRow
              label="Prefix"
              className="mb-0"
              RowProps={{ className: "px-2" }}
              LabelProps={{
                xs: 24,
                lg: 10,
                className: className("v-2 col-form-label px-0", isMobileViewport && "mt-2 pt-4"),
              }}
              ColProps={{ xs: 24, lg: 14, className: "mb-0 mt-n1 px-0 form-input-align flex-column" }}
            >
              <Field name={FieldNames.Prefix}>
                {({ input, meta }) => (
                  <>
                    <Form.Control
                      {...input}
                      type="text"
                      className="v-2"
                      placeholder="Group name prefix"
                      isInvalid={meta.touched && meta.error}
                    />

                    {meta.touched && meta.error ? (
                      <Form.Control.Feedback type="invalid" className="mt-1">
                        {meta.error}
                      </Form.Control.Feedback>
                    ) : null}
                  </>
                )}
              </Field>
            </FormRow>
            <FormRow
              label="Number of groups"
              className="mb-0"
              RowProps={{ className: "px-2" }}
              LabelProps={{
                xs: 24,
                lg: 10,
                className: className("v-2 col-form-label px-0", isMobileViewport && "mt-2 pt-4"),
              }}
              ColProps={{ xs: 24, lg: 14, className: "mb-0 mt-n1 px-0 form-input-align flex-column" }}
            >
              <Field
                name={FieldNames.Count}
                parse={value => (Number.isNaN(parseInt(value)) ? "" : formatStrToFloatWithRound(value, 0))}
              >
                {({ input, meta }) => (
                  <>
                    <Form.Control
                      {...input}
                      type="number"
                      className="v-2"
                      placeholder="Number of groups, up to 100"
                      min={1}
                      max={100}
                      isInvalid={meta.touched && meta.error}
                    />

                    {meta.touched && meta.error ? (
                      <Form.Control.Feedback type="invalid" className="mt-1">
                        {meta.error}
                      </Form.Control.Feedback>
                    ) : null}
                  </>
                )}
              </Field>
            </FormRow>
          </div>
          <div className="btrust-dialog__actions gap-1 align-items-center">
            <button className="btn btn-primary" type="submit" disabled={pristine || loading} data-test="submit-group">
              Create groups
            </button>
            {loading && <div className="spinner-border text-primary" />}
          </div>
        </form>
      )}
    </FForm>
  )
}

export const CreateEmptyGroups = ({ openingId, onCreate }) => {
  const { getQueryState } = useContext(QueryContext)
  const { request, loading } = getQueryState(createEmptyGroup)

  const createGroups = useCallback(
    data => {
      request({
        entitiesIds: {
          [Entities.Openings]: openingId,
        },
        data,
        onSuccess: onCreate,
      })
    },
    [onCreate, openingId, request]
  )

  return <CreateEmptyGroupsForm onSubmit={createGroups} loading={loading} />
}
