import React from "react"
import { Row, Col } from "react-bootstrap"

const FinishSession = ({ duration, absent_students, status }) => {
  return (
    <div className="panel compact mb-1 mt-4">
      <div className="panel_body">
        <Row className="justify-content-between mb-4">
          <Col>
            <h1 className="users-navbar_title">Attendance details</h1>
          </Col>
          <Col className="flex-grow-0">
            <b className="font-size-big">{status}</b>
          </Col>
        </Row>
        <Row className="form-group">
          <Col sm={7} mb={5} lg={4} className="col-form-label">
            Session duration
          </Col>
          <Col sm={17} md={14} lg={10} xl={8}>
            <p className="form-control-plaintext">{duration} minutes</p>
          </Col>
        </Row>
        <Row className="form-group">
          <Col sm={7} mb={5} lg={4} className="col-form-label">
            Absent students
          </Col>
          <Col sm={17} md={14} lg={10} xl={8}>
            <p className="form-control-plaintext">{absent_students.length === 0 ? "—" : absent_students.join(", ")}</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default FinishSession
