import { getSchoolsAutocomplete, getSubjectAutocomplete } from "@/domain"
import { useApiInitialPath } from "@/hooks/useApiPath"
import useBaseHandlersBuilder from "@/hooks/useBaseHandlersBuilder"
import { DomainProvider } from "@/providers"
import React, { useMemo } from "react"
import { opening } from "../configurations/domain"

export const OpeningProvider = ({ initial, config = [], children, connectToParentStore = false }) => {
  const openingConfig = useMemo(
    () => [...config, ...opening, getSubjectAutocomplete(true), getSchoolsAutocomplete(true)],
    [config]
  )

  return (
    <DomainProvider
      initialStorageState={initial}
      config={openingConfig}
      useApiPathBuilder={useApiInitialPath}
      useHandlersBuilder={useBaseHandlersBuilder}
      connectToParentStore={connectToParentStore}
    >
      {children}
    </DomainProvider>
  )
}

export default OpeningProvider
