import React from "react"

export const DigitCell = ({ value, first, Link, empty = "-", style, className, handlers }) => {
  const align = first ? "left" : "right"
  return (
    <td scope="row" align={align} style={style} className={className} {...handlers}>
      {value === null || value === void 0 ? empty : Link ? <Link>{value}</Link> : value}
    </td>
  )
}
