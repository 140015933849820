import React from "react"
import moment from "moment"
import { compact } from "lodash/fp"

import { getSessionInfo, getPeriodInfo } from "../../helpers/presentational"

const ScheduleSummary = ({ values }) => {
  if (values.recurrence) {
    if (values.timetable === null) {
      return <>Error: incorrect schedule</>
    }
    const sessions = Object.entries(values.timetable).map(([day, { time, duration }]) =>
      getSessionInfo(day, time, duration)
    )
    const validSessions = compact(sessions)
    return (
      <>
        {validSessions.length > 0 && (
          <div className="font-weight-normal mb-1">Weekly, {getPeriodInfo(values.started_at, values.ended_at)}</div>
        )}
        {validSessions.map((text, i) => (
          <div key={i} className="font-weight-medium mb-1">
            {text}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      {moment.parseZone(values.started_at).format("dddd MM/DD/YYYY h:mma")}
      {" -  "}
      {moment.parseZone(values.started_at).add(values.duration, "m").format("h:mma")}
    </>
  )
}

export default ScheduleSummary
