import React, { useRef } from "react"
import { Form } from "react-final-form"
import { setFieldData, clearFieldData } from "../../../../helpers/forms"
import { getLabelByValue } from "../../../../helpers/presentational"
import SpecialTreatmentDropdown from "../../../find_tutor/SpecialTreatmentDropdown"
import Chip from "../../../Chip"

const LearnDifferenceValue = ({ values, options, form, disabled }) => {
  const onRemoveExecutiveFunction = () => {
    form.mutators.clearFieldData("executive_function")
    form.submit()
  }

  const onRemoveSpecialTreatment = id => {
    const newValue = values.special_treatments.filter(value => value !== id)
    form.mutators.setFieldData("special_treatments", newValue)
    form.submit()
  }
  return (
    <>
      {values.executive_function && (
        <Chip disabled={disabled} onClose={onRemoveExecutiveFunction}>
          Executive function
        </Chip>
      )}
      {values.special_treatments.map(id => (
        <Chip disabled={disabled} key={id} onClose={() => onRemoveSpecialTreatment(id)}>
          {getLabelByValue(options.specialTreatments)(id)}
        </Chip>
      ))}
    </>
  )
}

export const LearnDifference = ({ opening, setOpening, options, disabled = false, completed = false }) => {
  const initialValues = useRef(opening)

  const submit = data => {
    const result = {
      executive_function: data.executive_function || false,
      special_treatments: data.special_treatments,
    }
    setOpening(result)
  }

  return (
    <div className="session-configuration_row">
      <Form initialValues={initialValues.current} mutators={{ setFieldData, clearFieldData }} onSubmit={submit}>
        {({ values, form }) => {
          return (
            <div className="session-configuration_row">
              <div className="session-configuration_control">
                <SpecialTreatmentDropdown
                  className="dropdown-toggle-no-icon fullwidth"
                  options={options}
                  getButtonText={() => "Learn. difference"}
                  completed={completed || Boolean(values.executive_function) || values.special_treatments.length > 0}
                  disabled={disabled}
                  onSubmit={() => form.submit()}
                />
              </div>
              <div className="session-configuration_values">
                <LearnDifferenceValue disabled={disabled} values={values} options={options} form={form} />
              </div>
            </div>
          )
        }}
      </Form>
    </div>
  )
}
