import React, { useState } from "react"
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { FormControl } from "react-bootstrap"

import FormRow from "./FormRow"

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: '"Poppins", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "14px",
      fontWeight: 400,
      color: "#000",
      "::placeholder": {
        fontFamily: '"Poppins", sans-serif',
        fontWeight: 400,
        color: "#b8b9bd",
      },
    },
    invalid: {
      color: "#f12212",
    },
  },
}

const CheckoutForm = ({
  cardFormRef,
  cardSubmitButtonRef,
  initialCardholderName,
  onTokenCreate,
  onRequestStart,
  onRequestEnd,
}) => {
  const elements = useElements()
  const stripe = useStripe()

  const [name, setName] = useState(initialCardholderName)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    onRequestStart()
    const cardElement = elements.getElement(CardNumberElement)
    const payload = await stripe.createToken(cardElement, { name })

    if (payload.error) {
      setErrorMessage(payload.error.message)
    } else {
      setErrorMessage(null)
      onTokenCreate(payload.token.id)
    }
    onRequestEnd()
  }

  return (
    <form ref={cardFormRef} onSubmit={handleSubmit}>
      <FormRow label="Card number" ColProps={{ lg: 12, className: "mb-0" }}>
        <div className="stripe-control">
          <CardNumberElement id="cardNumber" className="form-control" options={ELEMENT_OPTIONS} />
        </div>
      </FormRow>

      <FormRow label="Name on card" ColProps={{ lg: 12, className: "mb-0" }}>
        <FormControl id="name" required placeholder="John Doe" value={name} onChange={e => setName(e.target.value)} />
      </FormRow>

      <FormRow label="Expiry date" ColProps={{ lg: 5, className: "mb-0" }}>
        <div className="stripe-control">
          <CardExpiryElement id="expiry" className="form-control" options={ELEMENT_OPTIONS} />
        </div>
      </FormRow>

      <FormRow label="Security code" ColProps={{ lg: 5, className: "mb-0" }}>
        <div className="stripe-control">
          <CardCvcElement id="cvc" className="form-control" options={ELEMENT_OPTIONS} />
        </div>
      </FormRow>
      {errorMessage && <p className="text-error max-w-400-px">{errorMessage}</p>}
      <button ref={cardSubmitButtonRef} type="submit" disabled={!stripe} hidden />
    </form>
  )
}

export default CheckoutForm
