import React from "react"
import ActiveStorageUpload from "../../../common/ActiveStorageUpload"
import Alerter from "../../../common/alerter"

const UploadFileButton = ({ fields, index, input, visible, isLoading, setIsLoading }) => {
  const onError = () => {
    Alerter.error("Upload error")
  }

  const onFileChange = handleUpload => async event => {
    const files = event.target.files

    var temp = fields.value[index]
    temp.filename = files[0].name
    fields.update(index, temp)

    setIsLoading(true)
    await handleUpload(files)
    setIsLoading(false)
  }

  const getLabelClassName = ({ ready }) =>
    ["btn btn-outline-primary m-0 cursor-pointer", !ready && "disabled", !visible && "d-none"].filter(Boolean).join(" ")

  return (
    <ActiveStorageUpload
      onSuccess={ids => input.onChange(ids && ids[0])}
      onError={onError}
      render={({ handleUpload, ready }) => (
        <label className={getLabelClassName({ ready })}>
          <span className={isLoading ? "invisible" : "visible"}>Upload</span>
          {isLoading && (
            <div className="spinner-wrapper">
              <div className="spinner-border text-primary" />
            </div>
          )}
          <input accept="*" type="file" hidden disabled={!ready} onChange={onFileChange(handleUpload)} />
        </label>
      )}
    />
  )
}

export default UploadFileButton
