import TableSkeletonLoader from "@/common/skeleton_loaders/TableSkeletonLoader"
import { DataContext, QueryContext } from "@/contexts"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { useCallback, useContext, useMemo } from "react"
import { Card } from "react-bootstrap"
import NoResultsSection from "../../../admin/users/school_admins/NoResults"
import { getSchedule } from "../../configurations/domain"
import { BETA_TOOLTIP_TEXT, PROGRAMS, columnsList } from "./constants"

const LessonRecommendation = () => {
  const { schedule } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { loading: loadingSchedule } = getHookState({
    ...getSchedule,
    skipStorage: true,
  })

  const { program, lesson_recommendations = {} } = schedule ?? {}
  const { name: programName = "" } = program ?? {}
  const {
    group_suggested_lesson = "",
    group_suggested_level = "",
    student_recommendations = [],
  } = lesson_recommendations ?? {}

  const programNameLower = programName.toLowerCase()
  const isFoundationalReading = programNameLower === PROGRAMS.FOUNDATIONAL_READING_PROGRAM.toLowerCase()
  const isAdvancedReading = programNameLower === PROGRAMS.ADVANCED_READING_PROGRAM.toLowerCase()

  const columnsVisibility = useCallback(
    programName => {
      if (!programName) return columnsList

      if (isAdvancedReading) {
        return columnsList.filter(c => c.field !== "assessment_score_BSA" && c.field !== "assessment_score_BRA")
      }

      if (isFoundationalReading) {
        return columnsList.filter(c => c.field !== "assessment_score_baba")
      }

      return columnsList
    },
    [isAdvancedReading, isFoundationalReading]
  )

  const columns = useMemo(() => columnsVisibility(programName), [columnsVisibility, programName])
  const rows = useMemo(() => student_recommendations, [student_recommendations])
  const hasLessonRecommendations = rows.length > 0 && columns?.length > 0

  const renderTableHeader = useCallback(
    () => (
      <TableHead>
        <TableRow>
          {columns.map(({ field, headerName }) => (
            <TableCell
              key={field}
              className="text-gray-chat font-weight-medium font-size-small"
              sx={{
                "&.MuiTableCell-head": {
                  borderTop: "none",
                },
              }}
              align="left"
              component="th"
            >
              {headerName}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    ),
    [columns]
  )

  const renderTableBody = useCallback(
    () => (
      <TableBody>
        {rows.map(row => (
          <TableRow key={row.name || "-"}>
            <TableCell>{row.name || "-"}</TableCell>
            {!isFoundationalReading && <TableCell>{row.assessment_score_BABA || "-"}</TableCell>}
            {!isAdvancedReading && (
              <TableCell>{row.assessment_score_BSA ? `${row.assessment_score_BSA}%` : "-"}</TableCell>
            )}
            {!isAdvancedReading && <TableCell>{row.assessment_score_BRA || "-"}</TableCell>}
            <TableCell>{group_suggested_level}</TableCell>
            <TableCell>{row.suggested_lessons.join(", ") || "-"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    ),
    [rows, isFoundationalReading, isAdvancedReading, group_suggested_level]
  )

  const renderTableFooter = useCallback(
    () => (
      <TableFooter>
        <TableRow>
          <TableCell
            sx={{
              fontWeight: "500",
              fontSize: "13px",
              color: "#030303",
              borderBottom: "none",
            }}
          >
            Group Recommendation
          </TableCell>
          {!isFoundationalReading && <TableCell sx={{ borderBottom: "none" }} />}
          {!isAdvancedReading && <TableCell sx={{ borderBottom: "none" }} />}
          {!isAdvancedReading && <TableCell sx={{ borderBottom: "none" }} />}
          <TableCell
            sx={{
              fontWeight: "500",
              fontSize: "13px",
              color: "#030303",
              borderBottom: "none",
            }}
          >
            {group_suggested_level}
          </TableCell>
          <TableCell
            sx={{
              fontWeight: "500",
              fontSize: "13px",
              color: "#030303",
              borderBottom: "none",
            }}
          >
            {group_suggested_lesson}
          </TableCell>
        </TableRow>
      </TableFooter>
    ),
    [isFoundationalReading, isAdvancedReading, group_suggested_level, group_suggested_lesson]
  )

  return (
    <Card className="mt-4 p-4">
      <h6>
        Lesson Recommendation
        <Box
          display="inline-flex"
          alignItems="center"
          className="ml-3"
          sx={{
            backgroundColor: "#c7edf5",
            borderRadius: "6px",
            padding: "4px 8px",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "small",
              fontWeight: "600",
            }}
          >
            beta
          </Typography>
          <Tooltip
            title={BETA_TOOLTIP_TEXT}
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#9e9e9e",
                  color: "white",
                  fontSize: "11px",
                },
              },
            }}
          >
            <InfoOutlinedIcon
              sx={{
                marginLeft: "4px",
                fontSize: "18px",
                color: "black",
                "&:hover": {
                  color: "black !important",
                  cursor: "default",
                },
              }}
            />
          </Tooltip>
        </Box>
      </h6>
      {loadingSchedule ? (
        <TableSkeletonLoader />
      ) : (
        <TableContainer className="mt-2">
          {!hasLessonRecommendations ? (
            <NoResultsSection />
          ) : (
            <Paper
              style={{
                width: "100%",
                overflowX: "auto",
                boxShadow: "none",
              }}
            >
              <Table style={{ minWidth: 650 }} aria-label="simple table">
                {renderTableHeader()}
                {renderTableBody()}
                {renderTableFooter()}
              </Table>
            </Paper>
          )}
        </TableContainer>
      )}
    </Card>
  )
}

export default React.memo(LessonRecommendation)
