import React, { cloneElement } from "react"

const patchProps = props => (child, key) =>
  typeof child === "object"
    ? cloneElement(child, {
        ...(key !== void 0 ? { key } : void 0),
        ...props,
      })
    : child

export const PropsInjector = ({ children, props }) => {
  if (!props) return children

  return React.Children.map(children, patchProps(props))
}
