import moment from "moment"
import React, { useCallback, useContext, useMemo, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { ReactComponent as Arrow } from "../../../assets/images/arrow.svg"
import Alerter from "../../../common/alerter"
import { ConfirmationModalContext, QueryContext } from "../../../contexts"
import { buildId } from "../../../helpers/forms"
import { useBalkErrorBuilder } from "../../../hooks/useBalkError"
import useClassName from "../../../hooks/useClassName"
import useViewport from "../../../hooks/useViewport"
import { createSessionsWithAssistant, deleteSessionsWithAssistant } from "../configurations/domain"
import { CreateDeleteSessionsForm } from "../forms/create_delete_sessions"
// import { Entities } from "../../../constants"

export const AssistantActions = Object.freeze({
  Add: "create",
  Delete: "delete",
})

export const isAssistantAddAction = action => action === AssistantActions.Add

const AssistantActionsTitles = Object.freeze({
  [AssistantActions.Add]: "Add",
  [AssistantActions.Delete]: "Delete",
})

const VisibilityButton = ({ open, onClick }) => {
  const arrowClassName = useClassName("ml-2 icon-10-px", open ? "rotate-270-deg" : "rotate-90-deg")
  return (
    <button
      onClick={onClick}
      className="btn btn-link text-decoration-none d-flex align-items-center"
      data-test="open-calendar-assistant"
    >
      <span>Calendar Assistant</span>
      <div className={arrowClassName}>
        <Arrow />
      </div>
    </button>
  )
}

const AssistantActionSelector = ({ selected, disabled, onChange }) => {
  const actionIds = useMemo(
    () =>
      Object.values(AssistantActions).reduce((acc, key) => {
        acc[key] = buildId()
        return acc
      }, {}),
    []
  )
  return (
    <Row className="align-items-center">
      {Object.values(AssistantActions).map(action => (
        <Col xs={12} mb="auto" key={action}>
          <Form.Check
            id={actionIds[action]}
            value={action}
            name="assistant_type"
            type="radio"
            disabled={disabled}
            checked={action === selected}
            className="text-nowrap mr-2 v-2 active-gray-wrapper"
            label={`${AssistantActionsTitles[action]} sessions`}
            onChange={() => onChange(action)}
            custom
          />
        </Col>
      ))}
    </Row>
  )
}

export const ScheduleAssistant = ({ withConfirmation, schedules, sessions, onCreate, onDelete, children }) => {
  const { isMobileViewport } = useViewport()
  const { openConfirmationModal } = useContext(ConfirmationModalContext)
  const [reset, setReset] = useState(false)
  const [action, setAction] = useState(AssistantActions.Add)
  const headerClassName = useClassName(
    "align-items-center justify-content-between",
    isMobileViewport && "flex-column-reverse"
  )

  const { getQueryState } = useContext(QueryContext)
  const { loading: createLoading, request: createRequest } = getQueryState(createSessionsWithAssistant)
  const { loading: deleteLoading, request: deleteRequest } = getQueryState(deleteSessionsWithAssistant)

  const handleBalkCreateErrors = useBalkErrorBuilder()

  const sendCreateRequest = useCallback(
    (data, overwrittenCount) =>
      createRequest({
        data: {
          ...data,
          ids: schedules.map(({ id }) => id),
        },
        onSuccess: handleBalkCreateErrors({
          successMessage: `Session${overwrittenCount > 1 ? "s" : ""} successfully added`,
          buildErrorMessage: () => "Assistant",
          onUpdate: (_, noErrors) => {
            if (noErrors) {
              setReset(true)
              if (onCreate) onCreate()
              setTimeout(() => window.location.reload(), 2000)
            }
          },
        }),
      }),
    [createRequest, handleBalkCreateErrors, onCreate, schedules]
  )

  const sendDeleteRequest = useCallback(
    (data, deleteCount) => {
      deleteRequest({
        data: {
          ...data,
          ids: schedules.map(({ id }) => id),
        },
        onSuccess: () => {
          setReset(true)
          if (onDelete) onDelete()
          Alerter.success(`Session${deleteCount > 1 ? "s" : ""} successfully removed`)
          setTimeout(() => window.location.reload(), 2000)
        },
      })
    },
    [deleteRequest, schedules, onDelete]
  )

  const onCreateSubmit = useCallback(
    (data, overwrittenCount) => {
      if (overwrittenCount > 0 && withConfirmation)
        openConfirmationModal({
          onConfirm: () => sendCreateRequest(data, overwrittenCount),
          title: "Add sessions",
          content: `${overwrittenCount} session${overwrittenCount > 1 ? "s" : ""} will be overwritten. Continue?`,
          buttonClassName: "btn btn-danger",
        })
      else sendCreateRequest(data, overwrittenCount)
    },
    [openConfirmationModal, sendCreateRequest, withConfirmation]
  )

  const onDeleteSubmit = useCallback(
    (data, deleteCount) => {
      if (withConfirmation)
        openConfirmationModal({
          title: "Delete sessions",
          content: `${deleteCount} session${deleteCount > 1 ? "s" : ""} will be removed. Continue?`,
          buttonClassName: "btn btn-danger",
          onConfirm: () => sendDeleteRequest(data, deleteCount),
        })
      else sendDeleteRequest(data, deleteCount)
    },
    [openConfirmationModal, sendDeleteRequest, withConfirmation]
  )

  const completeReset = useCallback(() => setReset(false), [])

  const { minDate, maxDate } = useMemo(() => {
    if (schedules.length === 1) {
      const [{ start_on, end_on }] = schedules
      const nextWeek = moment().startOf("week").add(1, "week")
      return {
        minDate: start_on && nextWeek.isBefore(start_on) ? moment(start_on).toDate() : nextWeek.toDate(),
        maxDate: end_on ? moment(end_on).toDate() : void 0,
      }
    } else return {}
  }, [schedules])

  const isAdd = useMemo(() => isAssistantAddAction(action), [action])

  return (
    <>
      <div className="px-4 py-3">
        <Row className={headerClassName}>
          <Col xs="auto">
            <AssistantActionSelector
              selected={action}
              disabled={createLoading || deleteLoading}
              onChange={value => setAction(value)}
            />
          </Col>
          {children}
        </Row>
      </div>
      <hr className="mt-0 mb-3 soft-color" />
      <CreateDeleteSessionsForm
        action={action}
        sessions={sessions}
        schedules={schedules}
        reset={reset}
        completeReset={completeReset}
        loading={isAdd ? createLoading : deleteLoading}
        onSubmit={isAdd ? onCreateSubmit : onDeleteSubmit}
        minDate={minDate}
        maxDate={maxDate}
      />
    </>
  )
}

export const ToggleScheduleAssistant = ({ schedules, sessions, withConfirmation, onDelete, onCreate }) => {
  const { isMobileViewport } = useViewport()
  const [isOpen, setIsOpen] = useState(false)
  const shortHeaderClassName = useClassName(
    "card px-4 d-flex mb-4",
    isMobileViewport ? "align-items-center py-3" : "align-items-end py-2"
  )
  const visibilityButtonClassName = useClassName(isMobileViewport && "mb-3")

  const hideForm = useCallback(() => setIsOpen(false), [])

  if (isOpen)
    return (
      <div className="card d-flex mb-4">
        <ScheduleAssistant
          withConfirmation={withConfirmation}
          schedules={schedules}
          sessions={sessions}
          onCreate={onCreate}
          onDelete={onDelete}
        >
          <Col xs="auto" className={visibilityButtonClassName}>
            <VisibilityButton open={isOpen} onClick={hideForm} />
          </Col>
        </ScheduleAssistant>
      </div>
    )

  return (
    <div className={shortHeaderClassName}>
      <VisibilityButton open={isOpen} onClick={() => setIsOpen(true)} />
    </div>
  )
}

export default ToggleScheduleAssistant
