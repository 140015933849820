import React from "react"
import { Skeleton } from "@mui/material"
import PropTypes from "prop-types"

const ButtonSkeletonLoader = ({ variant, width, height, animation, className, style }) => {
  return (
    <Skeleton
      variant={variant}
      width={width}
      height={height}
      animation={animation}
      className={className}
      style={style}
    />
  )
}

ButtonSkeletonLoader.defaultProps = {
  variant: "rectangular",
  width: 210,
  height: 118,
  animation: "wave",
  className: "",
  style: {},
}

ButtonSkeletonLoader.propTypes = {
  variant: PropTypes.oneOf(["text", "rectangular", "circular", "rounded"]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  animation: PropTypes.oneOf(["pulse", "wave", false]),
  className: PropTypes.string,
  style: PropTypes.object,
}

export default ButtonSkeletonLoader
