import React, { useState } from "react"
import cn from "classnames"
import { Dropdown, Form, Button } from "react-bootstrap"
import { Field, useFormState, useField } from "react-final-form"

import FormRow from "../blocks/FormRow"
import { formatZip } from "../../helpers/formatters"
import { showTouchedFieldError } from "../../helpers/forms"
import { isValidLocation } from "../../helpers/validators"
import Condition from "../Condition"

const LocationDropdown = ({ className, completed, getButtonText, onSubmit }) => {
  const [showPopper, setShowPopper] = useState(false)

  const { values } = useFormState()
  const {
    meta: { pristine: isOnlinePristine },
  } = useField("online")
  const {
    meta: { pristine: isOfflinePristine },
  } = useField("offline")
  const {
    meta: { pristine: isZipPristine },
  } = useField("zip")

  const onToggle = show => {
    if (show) {
      setShowPopper(true)
      return
    } else if (!values.offline || isValidLocation(values)) {
      setShowPopper(false)
    }
    if (!isOnlinePristine || !isOfflinePristine || !isZipPristine) {
      onSubmit && onSubmit()
    }
  }

  const onZipKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      onToggle(false)
    }
  }

  return (
    <Dropdown show={showPopper} onToggle={onToggle} className="anl-location">
      <Dropdown.Toggle variant="outline-dropdown" className={cn(className, completed && "selected")}>
        {getButtonText(values)}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-4 -fullwidth">
        <Form>
          <Field name="online" type="checkbox">
            {({ input }) => (
              <Form.Check {...input} id="online" className="text-nowrap mb-3" label="Online lessons" custom />
            )}
          </Field>
          <Field name="offline" type="checkbox">
            {({ input }) => <Form.Check {...input} id="offline" className="text-nowrap" label="In-person" custom />}
          </Field>
          <Condition when="offline" is={true}>
            <p className="text-nowrap mt-4">ZIP code where sessions will take place</p>
            <Field name="zip" parse={formatZip}>
              {({ input, meta }) => (
                <FormRow label="Zip" ColProps={{ lg: 18 }} required>
                  <Form.Control {...input} isInvalid={showTouchedFieldError(meta)} onKeyPress={onZipKeyPress} />
                  <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                </FormRow>
              )}
            </Field>
          </Condition>

          <Button variant="outline-primary" size="sm" className="mt-4" type="button" onClick={() => onToggle(false)}>
            Ok
          </Button>
        </Form>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LocationDropdown
