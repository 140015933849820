import React, { useContext } from "react"
import { TutorCardActionsContext, TutorCardApplicationContext } from "../../../../contexts"
import PartialEntityTextUpdate from "../../../../../blocks/PartialEntityTextUpdate"

export const QuickNote = () => {
  const application = useContext(TutorCardApplicationContext)
  const { saveNote } = useContext(TutorCardActionsContext)

  return (
    <div className="d-flex flex-column">
      <div className="font-size-small text-gray-chat font-weight-medium mb-1">Quick note</div>

      <PartialEntityTextUpdate
        placeholder="Add quick note"
        value={application.internal_note}
        onChange={value => saveNote(application, value)}
        rows={1}
      />
    </div>
  )
}
