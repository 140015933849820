import React from "react"

const DownloadFileButton = ({ file, info, visible }) => {
  if (visible) {
    return (
      <span className="form-control v-2 disabled d-flex justify-content-between align-items-center overflow-hidden">
        <span className="text-overflow-ellipsis overflow-hidden text-no-wrap">{file.filename}</span>
        {!file.blob_id && info && info.url && <a href={info.url} className="download-icon flex-shrink-0 ml-2"></a>}
      </span>
    )
  } else {
    return null
  }
}

export default DownloadFileButton
