import React, { useMemo } from "react"
import { EducatableTypes } from "../../../schedule_module/constants"

export const EducatableGrades = ({ educatable }) => {
  const list = useMemo(() => {
    if (educatable.type === EducatableTypes.Group) return educatable.students.map(({ grade }) => grade)
    else if (educatable.type === EducatableTypes.Student) return [educatable.grade]
    else return []
  }, [educatable])
  if (list.length === 0) return null
  return (
    <div className="d-flex flex-column gap-025">
      {list.map((grade, idx) => (
        <span key={grade + idx}>{grade}</span>
      ))}
    </div>
  )
}
