import React from "react"
import DegreesToReview from "./DegreesToReview"

const PendingChanges = ({ dataSources: { urls }, degreesToShow }) => {
  if (degreesToShow.length === 0) {
    return null
  }

  return (
    <>
      <h4 className="col-lg-18">Pending approval</h4>
      <DegreesToReview degreesToShow={degreesToShow} urls={urls} />
    </>
  )
}

export default PendingChanges
