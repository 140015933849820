import PDFPreviewDialog from "@/common/PDFPreviewDialog"
import TabsNav from "@/components/blocks/UserTabs"
import usePDFGeneration from "@/hooks/usePDFGeneration"
import BRTThemeProvider from "@/providers/theme"
import { Box, Button, Card, CircularProgress, Stack, Typography } from "@mui/material"
import { useGridApiRef } from "@mui/x-data-grid"
import React, { useCallback, useMemo, useState } from "react"
import CSVUpload from "./CSVUpload"
import useDialogState from "./hooks/useDialogState"
import StudentCard from "./studentCard"
import StudentsTable from "./StudentsTable"

const StudentsPage = ({ currentPath, currentUser }) => {
  const apiRef = useGridApiRef()
  const [studentsToPrint, setStudentsToPrint] = useState([])
  const { previewDialogState, openPreviewDialog, closePreviewDialog } = useDialogState()
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  const pdfGenerationOptions = useMemo(
    () => ({
      itemsPerPage: 8,
      pageSize: "letter",
      orientation: "portrait",
      renderItem: StudentCard,
    }),
    []
  )

  const { isLoading, generatePDF } = usePDFGeneration(openPreviewDialog, pdfGenerationOptions)
  const handleRowSelectionModelChange = useCallback(newSelectionModel => {
    setRowSelectionModel(newSelectionModel)
  }, [])

  const handleGeneratePDF = useCallback(async () => {
    const selectedRows = Array.from(apiRef.current.getSelectedRows().values())
    const selectedStudents = [...selectedRows, ...studentsToPrint]

    await generatePDF(selectedStudents)
  }, [apiRef, generatePDF, studentsToPrint])

  const isPrintButtonDisabled = rowSelectionModel.length === 0
  return (
    <BRTThemeProvider>
      <Box className="container panel-wrapper" sx={{ pb: 4 }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Typography variant="h2" component="h1" letterSpacing={-0.8} sx={{ color: "#1f007a" }}>
            Students
          </Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap" justifyContent="flex-end">
            <CSVUpload />
            <Button href="/admin/sessions/new" variant="outlined" color="primary">
              Schedule Family Session
            </Button>
            <Button href="/admin/students/new" variant="outlined" color="primary">
              New Student
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleGeneratePDF}
              disabled={isPrintButtonDisabled}
              startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {isLoading ? "Generating..." : "Print Credentials"}
            </Button>
          </Stack>
        </Stack>
        <Card sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <TabsNav currentUser={currentUser} currentPath={currentPath} />
          <StudentsTable
            apiRef={apiRef}
            setStudentsToPrint={setStudentsToPrint}
            rowSelectionModel={rowSelectionModel}
            handleRowSelectionModelChange={handleRowSelectionModelChange}
          />
        </Card>
        <PDFPreviewDialog
          open={previewDialogState.open}
          onClose={closePreviewDialog}
          pdfUrl={previewDialogState.pdfUrl}
          fileName={`admin_student_credentials_${new Date().toISOString()}.pdf`}
          title="Student Credentials PDF"
        />
      </Box>
    </BRTThemeProvider>
  )
}

export default StudentsPage
