import React from "react"
import useViewport from "../../../hooks/useViewport"
import { className as cn } from "../../../helpers/className"

export const useColumns = () => {
  const { isPhoneViewport } = useViewport()
  const mainCellClasses = cn("font-weight-semibold", "font-size-medium", !isPhoneViewport && "connections-table__cell")
  const textCellClasses = cn("font-weight-medium", "font-size-small", !isPhoneViewport && "connections-table__cell")

  return [
    {
      field: "group_name",
      headerName: "Group",
      type: "string",
      className: mainCellClasses,
    },
    {
      field: "school.name",
      headerName: "School",
      type: "string",
      className: textCellClasses,
    },
    {
      field: "program_name",
      headerName: "Subject",
      type: "string",
      className: textCellClasses,
    },
    {
      field: "student_names",
      headerName: "Students",
      type: "custom",
      component: ({ row }) => (
        <td className={textCellClasses}>
          {row.student_names.map((name, index) => (
            <React.Fragment key={name}>
              {name}
              {index < 1 ? <br /> : null}
            </React.Fragment>
          ))}
        </td>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      type: "custom",
      component: ({ row }) => (
        <td className={cn("text-nowrap", !isPhoneViewport && "connections-table__cell")}>
          <a
            href={row.creation_url}
            className={cn(
              "btn",
              row.type === "tutor_to_student" && "btn-outline-primary",
              row.type === "tutor_to_student" && "py-2",
              row.type !== "tutor_to_student" && "btn-link"
            )}
          >
            {row.type === "tutor_to_student" ? "Create session" : "Schedule"}
          </a>
        </td>
      ),
    },
  ]
}
