import { DataContextActions, QueryContext } from "@/contexts"
import { useContext, useEffect, useMemo } from "react"

export const Request = ({ config, transaction }) => {
  const { getQueryState } = useContext(QueryContext)
  const { getState } = useContext(DataContextActions)
  const exist = useMemo(() => Boolean(getState(config)), [config, getState])
  const query = useMemo(() => getQueryState(config), [config, getQueryState])
  const initialRequestParams = useMemo(() => config.initialParamsBuilder(getState), [config, getState])

  useEffect(() => {
    if (!query || query.complete || query.failed || query.loading || !query.request) return
    if (config.skipIfExist && exist) return
    query.request({ ...initialRequestParams, transaction })
  }, [config, initialRequestParams, query, exist, transaction])

  return null
}
