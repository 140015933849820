import React, { useContext } from "react"
import moment from "moment"
import { DataContext } from "../../contexts"

export const WeekDates = () => {
  const { weekInfo } = useContext(DataContext)

  if (!weekInfo) return null

  const { days } = weekInfo

  const first = days[0]
  const last = days[days.length - 1]

  const result = [first.date, last.date].map(d => moment(d).format("MMM&nb\\sp;D")).join("&nbsp;-&nbsp;")

  return first && last ? <span dangerouslySetInnerHTML={{ __html: result }} /> : null
}
