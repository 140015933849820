import React, { useEffect } from "react"
import { Field, useForm, useField } from "react-final-form"
import { Button } from "react-bootstrap"
import Slider from "rc-slider"

const MIN_PRICE = 40
const MAX_PRICE = 500
const DEFAULT_PRICE = 100

const MARKS = {
  [MIN_PRICE]: `${MIN_PRICE}$`,
  [MAX_PRICE]: `${MAX_PRICE}$`,
}

const WizardPriceStep = ({ onSubmit }) => {
  const form = useForm()
  const {
    input: { value },
  } = useField("max_price")

  useEffect(() => {
    if (!value) {
      form.mutators.setFieldData("max_price", DEFAULT_PRICE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Field name="max_price" type="number">
      {({ input }) => (
        <>
          <p className="text-nowrap mt-2">up to ${input.value} per hour</p>
          <div className="max-w-400-px">
            <Slider
              min={MIN_PRICE}
              max={MAX_PRICE}
              marks={MARKS}
              dots={false}
              dotStyle={{ display: "none" }}
              className="rc-slider-fit-marks"
              {...input}
            />
          </div>
          <div className="mt-5">
            <Button type="button" variant="outline-primary" onClick={onSubmit}>
              Continue
            </Button>
          </div>
        </>
      )}
    </Field>
  )
}

export default WizardPriceStep
