import React, { useContext } from "react"
import { StudentsFiltersForm } from "../../forms/students_filters"
import { DataContextActions } from "../../../../contexts"

export const ListFilters = () => {
  const { updateState } = useContext(DataContextActions)
  const submit = data => {
    updateState("studentsListFilters", data)
  }
  return <StudentsFiltersForm onSubmit={submit} />
}
