import React, { useContext, useCallback, useMemo } from "react"
import { DataContext, ValidationContext } from "../../contexts"

import { ValidationNames, finalStatuses, cancelledStatuses, reportValidationStatuses } from "./constants"
import { DomainProvider } from "../../providers"
import { useApiPathWithRole, useApiInitialPath } from "../../hooks/useApiPath"
import useBaseHandlersBuilder from "../../hooks/useBaseHandlersBuilder"
import { daysInStatus } from "./helpers"

import { withRoles, unified } from "./configuration"

const ValidationProvider = ({ children }) => {
  const { role, statusInfo, reportInfo, groupProgressInfo, weekInfo } = useContext(DataContext)
  const { propertyGetter } = useContext(ValidationContext)

  const getStudentNames = useCallback(
    (validationResult, validationName) =>
      Object.keys(validationResult[ValidationNames.GroupProgressInfo])
        .map(id => [
          groupProgressInfo.find(el => el.id === parseInt(id, 10)),
          validationResult[ValidationNames.GroupProgressInfo][id],
        ])
        .map(([student, studentValidation]) => studentValidation[validationName] && student?.name)
        .filter(Boolean),
    [groupProgressInfo]
  )

  const validationResult = useMemo(() => {
    const result = {
      [ValidationNames.GroupProgressInfoInvalid]: false,
      [ValidationNames.CommonInvalid]: false,
      [ValidationNames.AllSessionFinished]: false,
      [ValidationNames.Agenda]: false,
      [ValidationNames.NextSteps]: false,
      [ValidationNames.StrengthsChallenges]: false,
      [ValidationNames.ProgramProgresses]: false,
      [ValidationNames.AttendanceWarnings]: [],
      [ValidationNames.GroupProgressInfo]: {},
    }

    if ([statusInfo, reportInfo, groupProgressInfo, weekInfo].indexOf(void 0) !== -1) return result

    if (!reportValidationStatuses.some(status => status === statusInfo.status)) return result

    const { days } = weekInfo

    if (!daysInStatus(finalStatuses, days))
      result[ValidationNames.AllSessionFinished] = "Some sessions are not finished"
    if (!reportInfo.agenda) result[ValidationNames.Agenda] = "Agenda is empty"
    if (!reportInfo.next_steps) result[ValidationNames.NextSteps] = "Next steps is empty"
    const groupProgressInfoStudentInvalid = {}

    if (!daysInStatus(cancelledStatuses, days)) {
      result[ValidationNames.GroupProgressInfo] = groupProgressInfo.reduce((acc, student) => {
        const {
          id,
          weekly_attendance,
          weekly_late_cancellation,
          late_cancellation_schedule,
          custom_program,
          strengths_challenges,
          program_progresses,
        } = student
        const result = {}
        if (
          (weekly_attendance && !weekly_late_cancellation) ||
          (!weekly_attendance && late_cancellation_schedule.some(({ late_cancellation }) => !late_cancellation))
        ) {
          if (!strengths_challenges) result[ValidationNames.StudentStrengthsChallenges] = true
          if (!custom_program && program_progresses.length === 0)
            result[ValidationNames.StudentProgramProgresses] = true
        }

        acc[id] = result
        return acc
      }, {})
      const emptyStrengthsChallenges = getStudentNames(result, ValidationNames.StudentStrengthsChallenges)

      if (emptyStrengthsChallenges.length)
        result[ValidationNames.StrengthsChallenges] = `Strengths/Challenges of ${emptyStrengthsChallenges.join(
          ", "
        )} is empty`

      const emptyProgramProgress = getStudentNames(result, ValidationNames.StudentProgramProgresses)

      if (emptyProgramProgress.length)
        result[ValidationNames.ProgramProgresses] = `Program progress of ${emptyProgramProgress.join(", ")} is empty`

      Object.keys(result[ValidationNames.GroupProgressInfo]).reduce((acc, id) => {
        acc[id] = Object.keys(result[ValidationNames.GroupProgressInfo][id]).length > 0
        return acc
      }, groupProgressInfoStudentInvalid)

      result[ValidationNames.GroupProgressInfoInvalid] = Object.keys(groupProgressInfoStudentInvalid).reduce(
        (acc, id) => acc || groupProgressInfoStudentInvalid[id],
        false
      )
    }

    result[ValidationNames.CommonInvalid] =
      Boolean(result[ValidationNames.Agenda]) ||
      Boolean(result[ValidationNames.NextSteps]) ||
      Boolean(result[ValidationNames.AllSessionFinished]) ||
      result[ValidationNames.GroupProgressInfoInvalid]

    days
      .filter(({ status }) => status === "finished")
      .forEach(day => {
        const isGroupAbsent = groupProgressInfo.every(student => {
          if (student.weekly_attendance) return student.weekly_late_cancellation
          else {
            const scheduleDay = student.late_cancellation_schedule.find(({ date }) => day.date === date)
            return scheduleDay ? scheduleDay.late_cancellation : false
          }
        })
        if (isGroupAbsent) result[ValidationNames.AttendanceWarnings].push(day.date)
      })

    return result
  }, [getStudentNames, groupProgressInfo, reportInfo, statusInfo, weekInfo])

  const canShowReport = useCallback(() => {
    return (
      role === "tutor" ||
      role === "admin" ||
      statusInfo.status === "report_sent" ||
      statusInfo.status === "report_overdue"
    )
  }, [role, statusInfo])

  const getErrorList = useCallback(() => {
    const fields = [
      ValidationNames.AllSessionFinished,
      ValidationNames.Agenda,
      ValidationNames.NextSteps,
      ValidationNames.StrengthsChallenges,
      ValidationNames.ProgramProgresses,
    ]

    return fields.map(key => validationResult[key]).filter(Boolean)
  }, [validationResult])

  const schoolSessionValidationGetter = useMemo(
    () => propertyGetter(validationResult),
    [propertyGetter, validationResult]
  )

  const getValidationValueByName = useCallback(schoolSessionValidationGetter, [schoolSessionValidationGetter])

  return (
    <ValidationContext.Provider value={{ propertyGetter, getValidationValueByName, getErrorList, canShowReport }}>
      {children}
    </ValidationContext.Provider>
  )
}

const SchoolSessionDataProvider = ({ role, groupSessionId, directUploadUrl, children }) => {
  if (!role) throw new Error("Role is not defined")

  const initial = {
    groupSessionId,
    role,
    directUploadUrl,
  }
  return (
    <DomainProvider
      initialStorageState={initial}
      config={withRoles}
      useApiPathBuilder={useApiPathWithRole}
      useHandlersBuilder={useBaseHandlersBuilder}
    >
      <DomainProvider connectToParentStore config={unified} useApiPathBuilder={useApiInitialPath}>
        <ValidationProvider>{children}</ValidationProvider>
      </DomainProvider>
    </DomainProvider>
  )
}

export default SchoolSessionDataProvider
