import React from "react"
import { toggleId, elText } from "../../../helpers/profile_form"

const Disability = ({ id, label, fields }) => {
  return (
    <div
      className="form-group cursor-pointer"
      onClick={() => toggleId(fields, id)}
      data-test={`learning-differences-${label.toLowerCase()}`}
    >
      <span>{elText({ id }, fields.value)}</span>
      <span className="ml-2">{label}</span>
    </div>
  )
}

export default Disability
