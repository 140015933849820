import "bootstrap-datepicker"
import "../components/bootstrap-timepicker"

document.addEventListener("turbolinks:load", () => {
  const dateInputs = $(".datepicker-custom")

  dateInputs.each((idx, item) => {
    let jItem = $(item)
    jItem.addClass("input-datepicker").attr("type", "text")

    jItem.datepicker({
      autoclose: true,
      clearBtn: true,
      orientation: "bottom auto",
      todayBtn: "linked",
    })
  })
})
