// designTokens.js
export const colors = {
  // Primary colors
  primary: {
    main: "#3c00f7",
    light: "#e6e6ff",
    dark: "#2b03ae",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#00b5e2",
    light: "#e6f9ff",
    dark: "#007bff",
    contrastText: "#ffffff",
  },
  // Semantic colors
  error: {
    main: "#ff2c23",
    light: "#ffe6e6",
    dark: "#721c24",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#ff6600",
    light: "#fff2e6",
    dark: "#dc3545",
    contrastText: "#ffffff",
  },
  info: {
    main: "#00b5e2",
    light: "#e6f9ff",
    dark: "#007bff",
    contrastText: "#ffffff",
  },
  success: {
    main: "#00c063",
    light: "#e6fff2",
    dark: "#006e37",
    contrastText: "#ffffff",
  },
  // Neutral colors
  neutral: {
    20: "#f2f3f5",
    40: "#e9ecef",
    60: "#dee2e6",
    80: "#ced4da",
    100: "#adb5bd",
    120: "#6c757d",
    140: "#495057",
    160: "#343a40",
    180: "#212529",
  },
  ink: {
    100: "#030303",
    90: "#1C1C1C",
    80: "#2E2E2E",
    70: "#3D3D3D",
    60: "#4F4F4F",
    50: "#5F5F5F",
    40: "#707070",
    30: "#7F7F7F",
    20: "#8F8F8F",
    10: "#9E9E9E",
  },
  btBlue: {
    100: "#2B03AE",
    90: "#3000C1",
    80: "#3C00F7",
    70: "#4F1FF8",
    60: "#6140F9",
    50: "#7361FA",
    40: "#8683FB",
    30: "#99A4FC",
    20: "#ADC6FD",
    10: "#C1E7FE",
  },
  sparkBlue: {
    100: "#00597C",
    90: "#006B93",
    80: "#007DAA",
    70: "#008FC1",
    60: "#00A1D8",
    50: "#00B5E2",
    40: "#33C1E7",
    30: "#66CEEC",
    20: "#99DBF1",
    10: "#CCE8F6",
  },
  lockerOrange: {
    100: "#993D00",
    90: "#B34700",
    80: "#CC5200",
    70: "#E65C00",
    60: "#FF6600",
    50: "#FF8533",
    40: "#FFA366",
    30: "#FFC299",
    20: "#FFE0CC",
    10: "#FFF0E6",
  },
  eraserPink: {
    100: "#96507D",
    90: "#A85B8C",
    80: "#BA669B",
    70: "#CC71AA",
    60: "#DE7CB9",
    50: "#F085D0",
    40: "#F29DD7",
    30: "#F5B4DF",
    20: "#F7CCE7",
    10: "#FAE3EF",
  },
  chalkboardGreen: {
    100: "#00733B",
    90: "#008445",
    80: "#00964F",
    70: "#00A759",
    60: "#00B863",
    50: "#00C063",
    40: "#33CA82",
    30: "#66D4A1",
    20: "#99DFC1",
    10: "#CCE9E0",
  },
  appleRed: {
    100: "#991A15",
    90: "#B31E19",
    80: "#CC221C",
    70: "#E6271F",
    60: "#FF2C23",
    50: "#FF564F",
    40: "#FF807B",
    30: "#FFAAA7",
    20: "#FFD4D3",
    10: "#FFEBEA",
  },
  // Other colors
  black: "#030303",
  white: "#ffffff",
  gray: "#b8b9bd",
  pink: {
    main: "#fa85d0",
    light: "#ffe6f9",
    dark: "#bd0032",
  },
}

export const brandColors = {
  btBlue80: "#3c00f7",
  sparkBlue50: "#00b5e2",
  lockerOrange40: "#ff6600",
  eraserPink40: "#fa85d0",
  chalkboardGreen50: "#00c063",
  appleRed60: "#ff2c23",
  ink100: "#030303",
}

export const layout = {
  borderRadius: {
    small: "0.625rem", // 10px
    medium: "1.375rem", // 22px
    large: "3.125rem", // 50px
  },
  containerWidth: {
    sm: "540px",
    md: "720px",
    lg: "960px",
    xl: "1140px",
  },
}

export const zIndex = {
  modal: 1000,
  overlay: 900,
  drawer: 800,
  appBar: 700,
  dropdown: 600,
  tooltip: 500,
}

export const transitions = {
  duration: {
    short: "150ms",
    medium: "300ms",
    long: "500ms",
  },
  easing: {
    easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
    easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
    easeIn: "cubic-bezier(0.4, 0, 1, 1)",
  },
}

export const typography = {
  fontFamily: {
    base: "'Poppins', sans-serif",
    alternative: "'Campton', sans-serif",
  },
  fontFamilyBase: "'Poppins', sans-serif",
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  sizes: {
    h1: "2.5rem", // 40px
    h2: "2rem", // 32px
    h3: "1.75rem", // 28px
    h4: "1.5rem", // 24px
    h5: "1.25rem", // 20px
    h6: "1.125rem", // 18px
    body1: "1rem", // 16px
    body2: "0.875rem", // 14px
    caption: "0.75rem", // 12px
    button: "1rem", // 16px
  },
  lineHeights: {
    button: "1rem", // 16px
    heading: 1.2,
    body: 1.5,
  },
  letterSpacings: {
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
  },
}

export const spacing = {
  unit: 8,
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 48,
}

export const dimensions = {
  borderRadius: {
    small: 4,
    medium: 8,
    large: 16,
    pill: 9999,
  },
  buttonMinWidth: 120,
  buttonPaddingY: "1rem",
  buttonPaddingX: "1.5rem",
  tooltip: {
    paddingY: spacing.xs,
    paddingX: spacing.sm,
    maxWidth: 240,
  },
  alert: {
    padding: spacing.md,
  },
}

export const shadows = {
  sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
  md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
}

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}
