import React from "react"
import { Spinner } from "react-bootstrap"

/**
 * LoadingSpinner is a functional component that renders a loading spinner
 * centered both vertically and horizontally within its parent container.
 *
 * This component uses Bootstrap's Spinner component to display a border animation.
 * It is useful for indicating loading states in your application.
 *
 * @returns {JSX.Element} A div containing a centered loading spinner.
 */
const LoadingSpinner = () => (
  <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
    <Spinner animation="border" />
  </div>
)

export default LoadingSpinner
