import { stringify } from "query-string"
import { parseQueryString, stringifyOptions } from "../../common/searchOptions"
import { pickBy } from "lodash/fp"
import ServerSideRendering from "../../common/serverSideRendering"

const ARRAY_FIELD_NAMES = ["program_ids", "timetable", "special_treatments", "struggle_with"]

var { document, window } = ServerSideRendering.wrapWindow(document, window)

const getDecodedSearchString = () => {
  try {
    const decodedSearchString = decodeURIComponent(window.location.search)
    return decodedSearchString
  } catch (_) {
    return window.location.search
  }
}

export const getSearchParamsFromUrl = (paramsWithoutCast = []) => {
  const searchParams = parseQueryString(getDecodedSearchString(), paramsWithoutCast)
  ARRAY_FIELD_NAMES.forEach(param => {
    if (searchParams[param] && !Array.isArray(searchParams[param])) {
      searchParams[param] = [searchParams[param]]
    }
  })
  return searchParams
}

export const getQueryStringFromParams = params => {
  const filteredParams = pickBy(Boolean)(params)
  return `?${stringify(filteredParams, stringifyOptions)}`
}

export const searchParamsToFormValues = params => {
  return {
    ...params,
    program_ids: params.program_ids && Array.isArray(params.program_ids) ? params.program_ids : [],
  }
}

export const formValuesToSearchParams = params => {
  return {
    ...params,
    zip: params.offline && params.zip ? params.zip : null,
  }
}
