import React from "react"
import { InnerLink, OuterLink } from "../link"
import { TextCell } from "./textCell"
import { DigitCell } from "./digitCell"
import { DateCell } from "./dateCell"
import { EmailCell } from "./emailCell"
import { PhoneCell } from "./phoneCell"

export const DynamicCell = ({ type, row, field, handlers, innerLink, outerLink, linkProps, ...rest }) => {
  const path = field.split(".")
  let value
  try {
    value = path.reduce((record, chank) => {
      if (record === null) return record
      return record[chank]
    }, row)
  } catch (e) {
    return <td>Invalid cell field path</td>
  }

  let Link = void 0
  if (innerLink) {
    // eslint-disable-next-line react/display-name
    Link = ({ children }) => (
      <InnerLink href={innerLink(row)} {...linkProps}>
        {children}
      </InnerLink>
    )
  } else if (outerLink) {
    // eslint-disable-next-line react/display-name
    Link = ({ children }) => (
      <OuterLink href={outerLink(row)} {...linkProps}>
        {children}
      </OuterLink>
    )
  }
  switch (type) {
    case "string":
      return <TextCell {...rest} Link={Link} value={value} handlers={handlers} />
    case "digit":
      return <DigitCell {...rest} Link={Link} value={value} handlers={handlers} />
    case "date":
      return <DateCell {...rest} Link={Link} value={value} handlers={handlers} />
    case "email":
      return <EmailCell {...rest} value={value} handlers={handlers} />
    case "phone":
      return <PhoneCell {...rest} value={value} handlers={handlers} />
    case "custom": {
      const { component: Component, ...restProps } = rest
      if (!Component) return <td>Invalid dynamic cell</td>
      return <Component {...restProps} row={row} handlers={handlers} />
    }
    default:
      return <td>Invalid cell type</td>
  }
}
