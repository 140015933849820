import React, { useCallback, useRef, useState, useEffect } from "react"

const ShiftSubmit = ({ className, disabled, onSubmit }) => {
  const wrapperRef = useRef(null)
  const buttonRef = useRef(null)
  const resetTimerRef = useRef(null)
  const submittedRef = useRef(false)

  const [drugged, setDrugged] = useState(false)
  const [startReset, setStartReset] = useState(false)
  const [wrapperRect, setWrapperRect] = useState({ width: 0, left: 0, right: 0 })
  const [buttonRect, setButtonRect] = useState({ width: 0 })
  const [buttonTranslateX, setButtonTranslateX] = useState(0)

  const onMouseDown = () => {
    if (!wrapperRef.current || !buttonRef.current) return
    setWrapperRect(wrapperRef.current.getBoundingClientRect())
    setButtonRect(buttonRef.current.getBoundingClientRect())
    setDrugged(true)
  }

  const resetButtonData = () => {
    setStartReset(false)
    clearTimeout(resetTimerRef.current)
  }

  const reset = useCallback(() => {
    if (startReset) return
    setDrugged(false)
    if (!submittedRef.current) {
      setStartReset(true)
      setButtonTranslateX(0)
      setButtonRect(buttonRef.current.getBoundingClientRect())
      clearTimeout(resetTimerRef.current)
      resetTimerRef.current = setTimeout(resetButtonData, wrapperRect.width)
    } else resetButtonData()
  }, [wrapperRect, startReset])

  useEffect(() => () => clearTimeout(resetTimerRef.current), [resetTimerRef])

  useEffect(() => {
    if (submittedRef.current && !drugged) {
      submittedRef.current = false
      onSubmit()
      resetTimerRef.current = setTimeout(reset, 500)
    }
  }, [submittedRef, drugged, onSubmit, reset])

  const restrictions = useCallback(
    (newValue, prevValue) => {
      const { right, left, width: wrapperWidth } = wrapperRect
      const { width } = buttonRect
      if (!right || !left || !wrapperWidth || !width) return prevValue
      if (newValue <= 0) return 0
      if (newValue + left + width >= right) {
        submittedRef.current = true
        return wrapperWidth - width
      }
      return newValue
    },
    [buttonRect, wrapperRect]
  )

  const onMouseMove = useCallback(
    event => {
      if (!drugged || submittedRef.current || startReset || disabled) return
      const { movementX } = event
      setButtonRect(buttonRef.current.getBoundingClientRect())
      setButtonTranslateX(prev => restrictions(buttonTranslateX + movementX, prev))
    },
    [buttonTranslateX, drugged, restrictions, submittedRef, startReset, disabled]
  )

  return (
    <div ref={wrapperRef} className="cancel-session-slider">
      <button
        ref={buttonRef}
        style={{
          transform: `translateX(${buttonTranslateX}px)`,
          transition: startReset ? `transform ${wrapperRect.width}ms ease-in-out` : null,
        }}
        type="button"
        disabled={disabled}
        onMouseDown={onMouseDown}
        onMouseUp={reset}
        onMouseLeave={reset}
        onMouseMove={onMouseMove}
        className={`btn cancel-session-submit -arrow ${className || ""}`}
      >
        Slide to confirm
      </button>
      <div
        style={{
          width: `${buttonRect.width / 2 + buttonTranslateX}px`,
          transition: startReset ? `width ${wrapperRect.width}ms ease-in-out` : null,
        }}
        className="cancel-session-button-pursuer"
      />
    </div>
  )
}

export default ShiftSubmit
