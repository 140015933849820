import React from "react"
import { Button } from "react-bootstrap"

import SpecialTreatmentControl from "../SpecialTreatmentControl"

const WizardSpecialTreatmentStep = ({ options, completed, onSubmit }) => {
  return (
    <>
      <SpecialTreatmentControl options={options} />
      <div className="mt-4">
        <Button type="button" variant="outline-primary" onClick={onSubmit}>
          {completed ? "Continue" : " No learning differences"}
        </Button>
      </div>
    </>
  )
}

export default WizardSpecialTreatmentStep
