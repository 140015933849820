import React, { useContext, useRef } from "react"
import { useFormState } from "react-final-form"
import { FormFieldNames } from "./constants"
import { DataContext, DataContextActions, QueryContext } from "../../../../contexts"
import { checkSchedulesExist } from "../../configurations/domain"
import { ReactComponent as WarningIcon } from "../../../../assets/images/report-overdue.svg"

export const ExistingScheduleDetector = ({ schedule }) => {
  const timerId = useRef(null)
  const educatableRef = useRef(null)
  const subjectRef = useRef(null)
  const { getHookState } = useContext(QueryContext)
  const { request } = getHookState(checkSchedulesExist)
  const { updateState } = useContext(DataContextActions)
  const { existingSchedule } = useContext(DataContext)

  const { values } = useFormState({
    subscription: { values: true },
    onChange: ({ values }) => {
      clearTimeout(timerId.current)
      timerId.current = setTimeout(
        data => {
          const educatable = data[FormFieldNames.Educatable]
          const subject = data[FormFieldNames.Subject]

          if (schedule && schedule.program.id === parseInt(subject.value || subject.id, 10)) {
            updateState({ config: { alias: "existingSchedule" }, data: void 0 })
            return
          }

          if (
            request &&
            educatable &&
            subject &&
            (educatable !== educatableRef.current || subject !== subjectRef.current)
          ) {
            educatableRef.current = educatable
            subjectRef.current = subject
            request({
              params: {
                educatable_id: parseInt(educatable.value, 10) || schedule.educatable.id,
                default_program_id: parseInt(subject.value || subject.id, 10),
              },
            })
          }
        },
        50,
        values
      )
    },
  })

  if (!values.subject || !existingSchedule) return null

  return (
    <div className="font-weight-medium mt-2 d-flex align-items-center">
      <WarningIcon className="icon-16-px icon-red-color mr-2" />
      <span className="text-nowrap">
        This group already has&nbsp;
        <a href={`/schedules/${existingSchedule.id}`}>{values.subject.title} schedule &gt;</a>
      </span>
    </div>
  )
}
