import PersonOff from "@mui/icons-material/PersonOff"
import { Box, Stack, Typography } from "@mui/material"
import { styled } from "@mui/system"
import React from "react"

const NoRowsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .MuiSvgIcon-root": {
    fontSize: 70,
    color: theme.palette.text.secondary,
  },
}))

const GenericNoRows = ({ message = "No Rows Found" }) => {
  return (
    <NoRowsContainer>
      <Stack alignItems="center">
        <PersonOff />
        <Typography variant="h6" color="text.secondary">
          {message}
        </Typography>
      </Stack>
    </NoRowsContainer>
  )
}

export default GenericNoRows
