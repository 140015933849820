import React, { useState, useEffect } from "react"
import { reject } from "lodash/fp"

import SingleChoiceDropdown from "../../blocks/SingleChoiceDropdown"
import {
  UPCOMING_INTERVIEW,
  COMPLETED_INTERVIEW,
  CANCELLED_INTERVIEW,
  ALL_PAST_INTERVIEW,
} from "../../../common/constants"
import { getLabelByValue } from "../../../helpers/presentational"
import apiClient, { replaceId, showAllErrorsInAlert } from "../../../common/apiClient"
import Alerter from "../../../common/alerter"
import IndeterminateProgressBar from "../../blocks/IndeterminateProgressBar"
import ParentInterviewCard from "./ParentInterviewCard"
import useFocusedInterview from "../useFocusedInterview"

const statusOptions = [
  { value: UPCOMING_INTERVIEW, label: "Upcoming" },
  { value: COMPLETED_INTERVIEW, label: "Completed" },
  { value: CANCELLED_INTERVIEW, label: "Canceled" },
  { value: ALL_PAST_INTERVIEW, label: "All Past" },
]

const getStatusFilterText = status => `Show: ${getLabelByValue(statusOptions)(status).toLowerCase()} interviews`

const Interviews = ({ dataSources: { hierarchy }, urls }) => {
  const [status, setStatus] = useState(UPCOMING_INTERVIEW)
  const [isLoading, setIsLoading] = useState(false)
  const [interviews, setInterviews] = useState([])

  const focusedInterviewId = useFocusedInterview()

  useEffect(() => {
    getInterviews(status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeStatus = value => {
    getInterviews(value)
    setStatus(value)
  }

  const getInterviews = async status => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(urls.get, { params: { filter: status } })
      setInterviews(data)
    } catch (e) {
      Alerter.error("An error occurred while loading data")
    } finally {
      setIsLoading(false)
    }
  }

  const onInterviewCancel = async id => {
    try {
      await apiClient.delete(replaceId(urls.cancel, id))
      setInterviews(prevInterviews => reject(["id", id])(prevInterviews))
      Alerter.success("Interview was successfully canceled")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  return (
    <>
      {isLoading && <IndeterminateProgressBar />}
      <div className="p-4 p-md-5">
        <SingleChoiceDropdown
          value={status}
          className="mb-4"
          buttonText={getStatusFilterText(status)}
          options={statusOptions}
          onChange={onChangeStatus}
        />
        {!isLoading && !interviews.length && <p className="text-center m-0">No interviews found</p>}
        {!isLoading &&
          interviews.length > 0 &&
          interviews.map(interview => (
            <ParentInterviewCard
              key={interview.id}
              focused={focusedInterviewId === interview.id}
              interview={interview}
              hierarchy={hierarchy}
              readonly={status !== UPCOMING_INTERVIEW}
              onCancel={() => onInterviewCancel(interview.id)}
            />
          ))}
      </div>
    </>
  )
}

export default Interviews
