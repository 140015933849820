import React from "react"
import cn from "classnames"

const Avatar = ({ initials, photoUrl, className }) => {
  return (
    <div className={cn("avatar", className)}>
      {photoUrl ? <img className="avatar_photo" src={photoUrl} /> : <div className="avatar_initials">{initials}</div>}
    </div>
  )
}

export default Avatar
