import { QueryContext } from "@/contexts"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { OpeningPageWrapper } from "../../components"
import { createOpening } from "../../configurations/domain"
import { OpeningForm } from "../../forms/opening_form"
import { mapFormDataToBackend } from "../../forms/opening_form/helpers"
import { OpeningProvider } from "../../providers/opening_page"

const CreateOpeningPageContent = () => {
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(createOpening)
  const navigate = useNavigate()

  const onSubmit = data => {
    request({
      data: { opening: mapFormDataToBackend(data) },
      onSuccess: ({ id }) => navigate(`/${id}`),
    })
  }

  return (
    <OpeningPageWrapper title="Create opening">
      <OpeningForm onSubmit={onSubmit} loading={loading} />
    </OpeningPageWrapper>
  )
}

export const CreateOpeningPage = () => {
  return (
    <OpeningProvider config={[createOpening]}>
      <CreateOpeningPageContent />
    </OpeningProvider>
  )
}
