import React, { useState, useEffect, useRef, useMemo } from "react"
import { renderToString } from "react-dom/server"
import { nanoid } from "nanoid"
import { sortBy, last } from "lodash"
import moment from "moment"

import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import { INTERVIEW_DURATION, INTERVIEW_CALENDAR_MAX_TIME, INTERVIEW_CALENDAR_MIN_TIME } from "../../../common/constants"

const HeaderCell = ({ date }) => (
  <div className="fc-header-text">
    <span className="fc-header-day-title">{moment(date).format("dddd MM/DD")}</span>
  </div>
)

const columnHeaderHtml = date => renderToString(<HeaderCell date={date} />)

const getBackgroundEvents = availability =>
  availability.map(({ date, since, till }) => ({
    start: moment(`${date} ${since}`).seconds(0).milliseconds(0).toDate(),
    end: moment(`${date} ${till === "00:00" ? "24:00" : till}`)
      .seconds(0)
      .milliseconds(0)
      .toDate(),
    rendering: "background",
  }))

const dataToEvent = start => ({ id: nanoid(), start })
const eventToData = ({ start }) => start

const InterviewCalendar = ({
  showBetweenMinMaxInterval,
  value = [],
  isLoading,
  availableIntervals,
  start = new Date(),
  end = new Date(),
  onChange,
}) => {
  const [events, setEvents] = useState(value.map(dataToEvent))

  const calendarRef = useRef()
  const allEvents = useMemo(() => [...events, ...getBackgroundEvents(availableIntervals)], [events, availableIntervals])

  const visibleEvents = useMemo(
    () =>
      allEvents.filter(event => moment(event.start).isSameOrAfter(start) && moment(event.start).isSameOrBefore(end)),
    [allEvents, start, end]
  )

  const minTime = useMemo(() => {
    if (showBetweenMinMaxInterval) {
      return INTERVIEW_CALENDAR_MIN_TIME
    }
    const eventsTimes = visibleEvents.map(({ start }) => moment(start).format("HH:mm"))
    if (eventsTimes.length) {
      return sortBy(eventsTimes)[0]
    }
    return "09:00"
  }, [visibleEvents, showBetweenMinMaxInterval])

  const maxTime = useMemo(() => {
    if (showBetweenMinMaxInterval) {
      return INTERVIEW_CALENDAR_MAX_TIME
    }
    const eventsTimes = visibleEvents.map(({ start, end }) =>
      end ? moment(end).format("HH:mm") : moment(start).add(INTERVIEW_DURATION, "m").format("HH:mm")
    )
    if (eventsTimes.length) {
      return last(sortBy(eventsTimes))
    }
    return "21:00"
  }, [visibleEvents, showBetweenMinMaxInterval])

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.render()
  }, [])

  useEffect(() => {
    setEvents(value.map(dataToEvent))
  }, [value])

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.gotoDate(start)
  }, [start])

  const eventOverlap = event => event.rendering === "background"

  const onChangeEvents = newEvents => {
    setEvents(newEvents)
    if (onChange) {
      onChange(newEvents.map(eventToData))
    }
  }

  const addEvent = ({ date: start }) => {
    const newEvents = [...events, { start, id: nanoid() }]
    onChangeEvents(newEvents)
  }

  const removeEvent = ({ event }) => {
    const newEvents = events.filter(({ id }) => id !== event.id)
    onChangeEvents(newEvents)
  }

  const onEventClick = ({ event }) => {
    removeEvent({ event })
  }

  return (
    <div className="fc-wrapper overflow-auto">
      <div className="fc-weekly fc-schedule-interview min-w-800-px">
        <FullCalendar
          ref={calendarRef}
          minTime={minTime}
          maxTime={maxTime}
          defaultView="timeGridWeek"
          defaultTimedEventDuration="00:30"
          forceEventDuration={true}
          startedAt={start}
          plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
          events={allEvents}
          header={false}
          allDaySlot={false}
          eventTimeFormat={{ hour: "2-digit", minute: "2-digit", hour12: true }}
          columnHeaderHtml={columnHeaderHtml}
          height="auto"
          eventOverlap={eventOverlap}
          selectOverlap={eventOverlap}
          slotEventOverlap={false}
          eventDurationEditable={false}
          selectable={false}
          editable={false}
          dateClick={addEvent}
          eventClick={onEventClick}
        />
        {isLoading && (
          <div className="fc-overlay">
            <div className="spinner-border spinner-border-lg text-primary" />
          </div>
        )}
      </div>
    </div>
  )
}

export default InterviewCalendar
