export const initializeToggle = () => {
  document.querySelectorAll(".js-class-toggle").forEach(item => {
    const target = item.dataset.target
    const className = item.dataset.class
    const event = item.dataset.event || "click"
    const action = item.dataset.action || "toggle"

    item.addEventListener(event, e => {
      document.querySelectorAll(target).forEach(oneTarget => {
        oneTarget.classList[action](className)
      })

      e.stopPropagation()
    })
  })
}

document.addEventListener("turbolinks:load", initializeToggle)
