export const SCHEDULE_TITLE = "Week schedule"

export const FORMATTED_TEXT_INTRO = "Your next session starts "

export const NO_SESSIONS_TEXT = "There are no future sessions scheduled. Check a bit later."

export const TUTOR_NOT_ASSIGNED = "Tutor not assigned"

export const GREETINGS_TEXT = "Welcome back,"

export const NO_SESSIONS_SCHEDULED = "No lessons scheduled for this week."

export const SESSION_CURRENTLY_IN_PROGRESS = "Session currently in progress."

export const STUDENTS_DASHBOARD_TEXTS = {
  SCHEDULE_TITLE,
  FORMATTED_TEXT_INTRO,
  NO_SESSIONS_TEXT,
  TUTOR_NOT_ASSIGNED,
  GREETINGS_TEXT,
  NO_SESSIONS_SCHEDULED,
  SESSION_CURRENTLY_IN_PROGRESS,
}
