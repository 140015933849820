import React from "react"
import TutoringsToReview from "./TutoringsToReview"
import EmploymentsToReview from "./EmploymentsToReview"

const PendingChanges = ({ dataSources: { urls }, employmentsToShow, tutoringsToShow, divisions }) => {
  if (employmentsToShow.length === 0 && tutoringsToShow.length === 0) {
    return null
  }

  return (
    <>
      <h4 className="col-lg-18">Pending approval</h4>
      <EmploymentsToReview employmentsToShow={employmentsToShow} urls={urls} />
      <TutoringsToReview tutoringsToShow={tutoringsToShow} urls={urls} divisions={divisions} />
    </>
  )
}

export default PendingChanges
