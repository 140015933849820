import { DataContext, InitialLoadingContext } from "@/contexts"
import { className } from "@/helpers/className"
import useViewport from "@/hooks/useViewport"
import React, { useContext, useMemo } from "react"
import { Col, Row } from "react-bootstrap"
import { Link, Navigate, Outlet, useParams } from "react-router-dom"
import NavigationMenu from "../../blocks/NavigationMenu"
import { RoutesPatterns, tabsNavigationConfig } from "../configurations/routing"
import { OpeningProvider } from "../providers/opening_page"

const OpeningContent = () => {
  const { initialLoading } = useContext(InitialLoadingContext)
  const { opening } = useContext(DataContext)
  const { isMobileViewport } = useViewport()
  const classNames = useMemo(
    () => ({
      spinner: className(
        "d-flex",
        "align-items-center",
        "justify-content-center",
        isMobileViewport ? "min-h-200-px" : "min-h-600-px"
      ),
    }),
    [isMobileViewport]
  )

  if (initialLoading) {
    return (
      <div className={classNames.spinner}>
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  const { program, school } = opening

  return (
    <div className="container p-0 d-flex flex-column flex-grow-1 mt-n3">
      <Row className="entity-outlet mt-n1">
        <Col className="p-0">
          <div className="container users-navbar_container -white min-h-initial">
            <Link className="font-weight-semibold" to={RoutesPatterns.List}>
              {"<"} All openings
            </Link>
          </div>
          <div className="container users-navbar_container -white">
            <h2 className="users-navbar_title m-0">
              {program?.name}, {school?.name}
            </h2>
          </div>
          <div className="container users-navbar_container -white align-items-start">
            <NavigationMenu configs={tabsNavigationConfig} />
          </div>
          <div className="container mt-4">
            <div className="entity-outlet_side-wrapper mx-n1 px-2">
              <main className="entity-outlet_side -content px-0 mb-4">
                <Outlet />
              </main>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const OpeningOutlet = ({ initial }) => {
  const { openingId } = useParams()

  if (Number.isNaN(parseInt(openingId, 10))) return <Navigate to="/" />

  return (
    <OpeningProvider initial={{ ...initial, openingId }}>
      <OpeningContent />
    </OpeningProvider>
  )
}

export default OpeningOutlet
