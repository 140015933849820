import React, { useEffect, useRef, useState } from "react"
import { Modal } from "react-bootstrap"
import { className as cn } from "../../helpers/className"
import { ReactComponent as Close } from "../../assets/images/close.svg"
import { ButtonIcon } from "../../library"

export const Dialog = ({
  title,
  subtitle,
  children,
  open,
  onClose,
  className,
  underlineHeader,
  showCloseButton = true,
  ...props
}) => {
  const wrapperRef = useRef(null)
  const [container, setContainer] = useState()

  useEffect(() => {
    setContainer(wrapperRef?.current.closest("[data-react-class]") ?? document.body)
  }, [])

  return (
    <div ref={wrapperRef} className="dialog-wrapper">
      <Modal
        dialogClassName={className}
        keyboard={false}
        backdrop="static"
        centered
        {...props}
        show={open}
        onHide={onClose}
        container={container}
      >
        {showCloseButton && (
          <ButtonIcon size="lg" icon={Close} onClick={onClose} className="btrust-dialog__close-button" />
        )}
        {(title || subtitle) && (
          <Modal.Header className={cn("btrust-dialog__header mb-0", !underlineHeader && "borderless")}>
            <Modal.Title>
              {subtitle ? <div className="btrust-dialog__subtitle">{subtitle}</div> : null}

              {title}
            </Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body className="btrust-dialog__body">{children.body ? children.body : children}</Modal.Body>
        {children.footer && <Modal.Footer className="btrust-dialog__footer">{children.footer}</Modal.Footer>}
      </Modal>
    </div>
  )
}

export default Dialog
