import ReportIcon from "@mui/icons-material/Report"
import { Box, Typography } from "@mui/material"
import React from "react"

const NoResultsSection = () => (
  <Box
    className="bg-light d-flex flex-column align-items-center justify-content-center p-4 h-100"
    role="alert"
    aria-live="polite"
  >
    <ReportIcon sx={{ fontSize: 70 }} className="text-muted mb-4" />
    <Typography variant="h5" className="text-center text-muted font-weight-normal">
      No Results
    </Typography>
  </Box>
)

export default NoResultsSection
