import { Grid, InputBase, InputLabel } from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"

export const commonStyles = {
  fontFamily: "'Poppins', sans-serif",
  borderRadius: "8px",
  transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
}

export const FilterInput = styled(InputBase)(({ theme }) => ({
  ...commonStyles,
  display: "block",
  width: "100%",
  padding: theme.spacing(1, 2),
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: 2.25,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderRadius: "25px",
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    borderColor: theme.palette.grey[400],
  },
  "&:focus": {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 0.2rem ${theme.palette.primary.light}`,
  },
}))

export const FilterLabel = styled(InputLabel)(({ theme }) => ({
  ...commonStyles,
  color: theme.palette.text.secondary,
  fontSize: "0.85714rem",
  fontWeight: 400,
  lineHeight: 2.25,
  padding: theme.spacing(0, 1),
}))

export const FilterInputs = ({ filter, handleFilterChange, handleResetFilters }) => (
  <Grid container spacing={2} sx={{ alignItems: "flex-end" }}>
    <Grid item xs={12} sm={3}>
      <FilterLabel htmlFor="filter-by-name">Filter by Name</FilterLabel>
      <FilterInput
        label="Filter by Name"
        name="name"
        type="search"
        value={filter.name}
        onChange={handleFilterChange}
        aria-label="Filter by Name"
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <FilterLabel htmlFor="filter-by-email">Filter by Email</FilterLabel>
      <FilterInput
        label="Filter by Email"
        name="email"
        type="search"
        value={filter.email}
        onChange={handleFilterChange}
        aria-label="Filter by Email"
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <FilterLabel htmlFor="filter-by-school">Filter by School</FilterLabel>
      <FilterInput
        label="Filter by School"
        name="school_name"
        type="search"
        value={filter.school_name}
        onChange={handleFilterChange}
        aria-label="Filter by School"
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <button className="btn btn-outline-primary" onClick={handleResetFilters} aria-label="Reset Filters">
        Reset Filters
      </button>
    </Grid>
  </Grid>
)
