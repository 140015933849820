import { CardContent, CardHeader, Typography } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import ProgramDetails from "./ProgramDetails"
import { StyledCard } from "./StyledComponents"

const NoResources = ({ data }) => (
  <StyledCard>
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      <Typography variant="body1" color="text.secondary">
        No resources available!
      </Typography>
    </CardContent>
  </StyledCard>
)

NoResources.propTypes = {
  data: PropTypes.shape({
    program_name: PropTypes.string.isRequired,
    assignment_name: PropTypes.string,
  }).isRequired,
}

export default NoResources
