import React, { useMemo } from "react"
import { Field, useFormState } from "react-final-form"
import { Button } from "react-bootstrap"

import StruggleWithControl from "../StruggleWithControl"
import { getMatchedQuestions } from "../../../helpers/programsHierarchy"

const WizardStruggleWithStep = ({ options, onSubmit }) => {
  const {
    values: { grade, program_ids, executive_function: executiveFunction },
  } = useFormState()

  const matchedQuestions = useMemo(
    () => getMatchedQuestions(grade, program_ids, executiveFunction, options),
    [grade, program_ids, executiveFunction, options]
  )

  return (
    <Field name="struggle_with">
      {({ input }) => (
        <>
          <StruggleWithControl {...input} questions={matchedQuestions} />
          <div className="mt-5">
            <Button type="button" variant="outline-primary" onClick={onSubmit}>
              Next
            </Button>
          </div>
        </>
      )}
    </Field>
  )
}

export default WizardStruggleWithStep
