import React, { useContext } from "react"
import { OpeningContext } from "./context"
import { Schedule } from "../../common"

const Step3Schedule = () => {
  const { opening, setOpening, options } = useContext(OpeningContext)

  return <Schedule opening={opening} setOpening={setOpening} options={options} enableScheduleType />
}

export default Step3Schedule
