import SchoolIcon from "@mui/icons-material/School"
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { styled } from "@mui/system"
import PropTypes from "prop-types"
import React from "react"

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const ProgramDetails = ({ programName, assignmentName }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Stack spacing={1}>
      <StyledTypography variant={isMobile ? "h6" : "h5"} color="primary" component="h1">
        {programName}
      </StyledTypography>
      {assignmentName && (
        <StyledTypography variant={isMobile ? "subtitle1" : "h6"} color="text.secondary" component="h2">
          <SchoolIcon /> {assignmentName}
        </StyledTypography>
      )}
    </Stack>
  )
}

ProgramDetails.propTypes = {
  programName: PropTypes.string.isRequired,
  assignmentName: PropTypes.string,
}

export default ProgramDetails
