import React from "react"
import { StyledLink } from "./styledLink"

export const OuterLink = ({ children, to, target = "_blank", ...rest }) => {
  return (
    <StyledLink href={to} target={target} {...rest}>
      {children}
    </StyledLink>
  )
}
