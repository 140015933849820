import React from "react"
import ProgramsToReview from "./ProgramsToReview"
import CertificatesToReview from "./CertificatesToReview"
import DisabilitiesToReview from "./DisabilitiesToReview"
import { serializeSpecialTreatments } from "../../../helpers/serializers"
import { isEmpty } from "lodash/fp"

const PendingChanges = ({
  dataSources: { hierarchy, specialTreatments, urls },
  programsToShow,
  certificatesToShow,
  disabilitiesToShow,
}) => {
  const serializedSpecialTreatments = serializeSpecialTreatments(specialTreatments)

  if (isEmpty(programsToShow) && isEmpty(certificatesToShow) && isEmpty(disabilitiesToShow)) {
    return null
  }

  return (
    <>
      <h4 className="col-lg-18">Pending approval</h4>
      <ProgramsToReview hierarchy={hierarchy} programsToShow={programsToShow} urls={urls} />
      <DisabilitiesToReview
        specialTreatments={serializedSpecialTreatments}
        disabilitiesToShow={disabilitiesToShow}
        urls={urls}
      />
      <CertificatesToReview certificatesToShow={certificatesToShow} urls={urls} />
    </>
  )
}

export default PendingChanges
