import React from "react"
import cn from "classnames"
import { Dropdown } from "react-bootstrap"

const SingleChoiceDropdown = ({
  value,
  options,
  buttonText,
  selected,
  className,
  onChange,
  wrapperClassName,
  disabled,
}) => {
  return (
    <Dropdown className={wrapperClassName || ""}>
      <Dropdown.Toggle variant="outline-dropdown" className={cn(selected && "selected", className)} disabled={disabled}>
        {buttonText}
      </Dropdown.Toggle>

      <Dropdown.Menu className="-fullwidth">
        {options.map(option => (
          <Dropdown.Item
            disabled={disabled}
            key={option.value}
            className={option.value === value && "active"}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SingleChoiceDropdown
