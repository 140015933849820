import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import { TIME_SUGGESTED_INTERVIEW } from "../../../common/constants"
import AlternateTimeModalContent from "./AlternateTimeModalContent"
import ConfirmModalContent from "./ConfirmModalContent"

const ConfirmRequestedInterviewModal = ({ show, interview, onConfirm, onEdit, onClose }) => {
  const [showAlternateTime, setShowAlternateTime] = useState(interview.status === TIME_SUGGESTED_INTERVIEW)

  return (
    <Modal show={show} size={showAlternateTime ? "xl" : "lg"} onHide={onClose} data-backdrop="static" centered>
      {showAlternateTime ? (
        <AlternateTimeModalContent interview={interview} onSubmit={onEdit} onClose={onClose} />
      ) : (
        <ConfirmModalContent
          interview={interview}
          onShowAlternateTime={() => setShowAlternateTime(true)}
          onSubmit={onConfirm}
          onClose={onClose}
        />
      )}
    </Modal>
  )
}

export default ConfirmRequestedInterviewModal
