import _ from "lodash"
import React, { useMemo } from "react"
import { FieldArray } from "react-final-form-arrays"
import { ReactComponent as Plus } from "../../../assets/images/plus.svg"
import RowWarning from "../../blocks/RowWarning"
import Employment from "./Employment"

const INITIAL_EMPLOYMENT = {
  from: "",
  from_text: "",
  id: null,
  is_current_employment: false,
  position: "",
  school: "",
  to: "",
  to_text: "",
}

const validateArray = values => {
  if (!values || !values.length) return

  const errorsArray = values.map(value => {
    const errors = {}

    if (!value.from) {
      errors.from = "Required"
    }

    if (!value.to && !value.is_current_employment) {
      errors.to = "Required"
    }

    if (!value.school) {
      errors.school = "Required"
    }

    if (!value.position) {
      errors.position = "Required"
    }

    return errors
  })

  return errorsArray.some(object => !_.isEmpty(object)) ? errorsArray : undefined
}

const EmploymentHistory = ({ dataSources }) => {
  const initialEmploymentHistories = useMemo(() => {
    return dataSources.user.profile.employment_histories || []
  }, [dataSources])

  return (
    <div>
      <div className="row mb-4">
        <h4 className="col-sm-18">Employment history</h4>
      </div>
      <FieldArray name="employment_histories" initialValue={initialEmploymentHistories} validate={validateArray}>
        {({ fields }) => (
          <>
            <div>
              {fields.map((name, index) => (
                <Employment
                  key={index}
                  name={name}
                  index={index}
                  fields={fields}
                  schoolUrl={dataSources.urls.schools}
                />
              ))}
            </div>
            <hr className="divider" />
            <div className="mt-2 mb-5">
              <RowWarning className="panel_body-warning" name="employmentHistoriesLength">
                <button
                  type="button"
                  className="btn btn-outline-primary d-flex align-items-center"
                  onClick={() => fields.push(INITIAL_EMPLOYMENT)}
                >
                  <div className="icon-16-px">
                    <Plus />
                  </div>
                  <span className="ml-3">Add position</span>
                </button>
              </RowWarning>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  )
}

export default EmploymentHistory
