import React, { useState, useEffect } from "react"
import { Dropdown } from "react-bootstrap"
import { get, find } from "lodash/fp"
import cn from "classnames"

import { getDomainByProgram } from "../../helpers/programsHierarchy"

const DOMAINS_DROPDOWN = "domains"
const PROGRAMS_DROPDOWN = "programs"

const ProgramPicker = ({ program, className, domains, buttonText, selected, disabled, onChange, wrapperClassName }) => {
  const [showPopper, setShowPopper] = useState(false)
  const [dropdownType, setDropdownType] = useState(DOMAINS_DROPDOWN)

  const [programs, setPrograms] = useState([])

  useEffect(() => {
    setDropdownType(DOMAINS_DROPDOWN)
    const domain = get("id")(getDomainByProgram(program, domains))
    if (domain) {
      setPrograms(find(["domain.id", domain])(domains))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopper])

  const onToggle = (show, _, { source }) => {
    if (show) {
      setShowPopper(true)
    } else if (source !== "select") {
      setShowPopper(false)
    }
  }

  const onDomainChange = ({ programs }) => {
    if (programs.length === 1) {
      onChange(programs[0].id, programs[0].name)
      setShowPopper(false)
    } else {
      setPrograms(programs)
      setDropdownType(PROGRAMS_DROPDOWN)
    }
  }

  const onProgramChange = (id, name) => {
    onChange(id, name)
    setShowPopper(false)
  }

  return (
    <Dropdown show={showPopper} onToggle={onToggle} className={wrapperClassName || ""}>
      <Dropdown.Toggle variant="outline-dropdown" disabled={disabled} className={cn(className, selected && "selected")}>
        {buttonText}
      </Dropdown.Toggle>
      <Dropdown.Menu className="-fullwidth">
        {dropdownType === DOMAINS_DROPDOWN && !domains.length && <Dropdown.Item disabled>No options</Dropdown.Item>}

        {dropdownType === PROGRAMS_DROPDOWN && !programs.length && <Dropdown.Item disabled>No options</Dropdown.Item>}

        {dropdownType === DOMAINS_DROPDOWN && domains.length > 0 && (
          <>
            {domains.map(domain => (
              <Dropdown.Item
                key={domain.id}
                className="d-flex justify-content-between align-items-center"
                onClick={() => onDomainChange(domain)}
              >
                {domain.name}
                {domain.programs.length > 1 && <div className="dropdown-chevron-right" />}
              </Dropdown.Item>
            ))}
          </>
        )}
        {dropdownType === PROGRAMS_DROPDOWN && programs.length > 0 && (
          <>
            <Dropdown.Item
              className="d-flex align-items-center text-muted"
              onClick={() => setDropdownType(DOMAINS_DROPDOWN)}
            >
              <div className="dropdown-chevron-left mr-2" />
              Back
            </Dropdown.Item>
            {programs.map(({ id, name }) => (
              <Dropdown.Item key={id} onClick={() => onProgramChange(id, name)}>
                {name}
              </Dropdown.Item>
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProgramPicker
