import React, { useState, useEffect, useRef } from "react"
import { renderToString } from "react-dom/server"
import { sortBy, last } from "lodash"
import moment from "moment"

import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import listPlugin from "@fullcalendar/list"
import { INTERVIEW_CALENDAR_MAX_TIME, INTERVIEW_CALENDAR_MIN_TIME } from "../../common/constants"

const PLUGINS = [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]

const HeaderCell = ({ date }) => (
  <div className="fc-header-text">
    <span className="fc-header-day-title">{moment(date).format("dddd MM/DD")}</span>
  </div>
)

const columnHeaderHtml = date => renderToString(<HeaderCell date={date} />)

const getBackgroundEvents = availability =>
  availability.map(({ date, since, till }) => ({
    start: moment(`${date} ${since}`).seconds(0).milliseconds(0).toDate(),
    end: moment(`${date} ${till === "00:00" ? "24:00" : till}`)
      .seconds(0)
      .milliseconds(0)
      .toDate(),
    rendering: "background",
  }))

const ScheduleCalendar = ({ isParent, sessions, availableIntervals, startedAt = new Date(), ...scheduleProps }) => {
  const [events, setEvents] = useState([...sessions, ...getBackgroundEvents(availableIntervals)])

  const calendarRef = useRef()

  const minTime = isParent
    ? INTERVIEW_CALENDAR_MIN_TIME
    : sortBy(events.map(({ start }) => moment(start).format("HH:mm")))[0] || "00:00"
  const maxTime = isParent
    ? INTERVIEW_CALENDAR_MAX_TIME
    : last(sortBy(events.map(({ start, end }) => moment(start).isSame(end, "day") && moment(end).format("HH:mm")))) ||
      "24:00"

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.render()
  }, [])

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.gotoDate(startedAt)
  }, [startedAt])

  useEffect(() => {
    setEvents([...sessions, ...getBackgroundEvents(availableIntervals)])
  }, [sessions, availableIntervals])

  return (
    <div className="fc-weekly fc-schedule-session fullwidth">
      <FullCalendar
        ref={calendarRef}
        minTime={minTime}
        maxTime={maxTime}
        defaultView="timeGridWeek"
        plugins={PLUGINS}
        events={events}
        header={false}
        allDaySlot={false}
        displayEventTime={false}
        eventTimeFormat={{ hour: "2-digit", minute: "2-digit", hour12: true }}
        columnHeaderHtml={columnHeaderHtml}
        height="auto"
        {...scheduleProps}
        startedAt={startedAt}
      />
    </div>
  )
}

export default ScheduleCalendar
