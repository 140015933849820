import { FILTER_API_PARAM_MAP } from "../constants"

/**
 * Transforms an array of student objects by combining first and last names into a single name property.
 *
 * @param {Array} students - The array of student objects to transform.
 * @param {Object} students[].id - The ID of the student.
 * @param {Object} students[].first_name - The first name of the student.
 * @param {Object} students[].last_name - The last name of the student.
 * @returns {Array} The transformed array of student objects with combined name property.
 */
export const transformStudentData = students =>
  students.map(student => ({
    ...student,
    id: student.id,
    name: `${student.first_name} ${student.last_name}`,
  }))

/**
 * Transforms a filter model into a format suitable for API requests.
 *
 * @param {Object} filterModel - The filter model to transform.
 * @param {Array} filterModel.items - An array of filter objects.
 * @param {string} filterModel.items[].field - The field to filter on.
 * @param {string} filterModel.items[].operator - The operator to use for filtering.
 * @param {any} filterModel.items[].value - The value to filter by.
 * @returns {Object} The transformed filter model suitable for API requests.
 */
export const transformFiltersForAPI = filterModel => {
  if (!filterModel) return {}

  const { items } = filterModel

  return items.reduce((acc, filter) => {
    const { field, operator, value } = filter
    const apiParam = FILTER_API_PARAM_MAP[field] || field

    if (!apiParam) return acc

    if (["schools", "grades", "groups"].includes(field) && Array.isArray(value)) {
      acc[apiParam] = value
      return acc
    }

    let transformedValue = value

    switch (operator) {
      case "contains":
        transformedValue = `%${value}%`
        break
      case "startsWith":
        transformedValue = `${value}%`
        break
      case "endsWith":
        transformedValue = `%${value}`
        break
      case "isEmpty":
        transformedValue = ""
        break
      case "isNotEmpty":
        transformedValue = "!empty"
        break
      case "in":
        if (Array.isArray(value)) {
          acc[apiParam] = value
          return acc
        }
        break
    }

    if (acc[apiParam]) {
      acc[apiParam] = Array.isArray(acc[apiParam])
        ? [...acc[apiParam], transformedValue]
        : [acc[apiParam], transformedValue]
    } else {
      acc[apiParam] = transformedValue
    }

    return acc
  }, {})
}

/**
 * Creates a styled HTML element with the given styles.
 * @param {Object} styles - An object containing CSS styles.
 * @returns {HTMLElement} - The created HTML element with the applied styles.
 */
export const createStyledElement = styles => {
  const element = document.createElement("div")
  Object.assign(element.style, styles)
  return element
}
