import React from "react"
import Header from "../questionnaire/Header"
import { Card, Container } from "react-bootstrap"

import { ReactComponent as CloseIcon } from "../../../../assets/images/icons/close.svg"

const WizardLayout = ({ children }) => {
  return (
    <div className="wizard-layout">
      <div className="wizard-layout_header">
        <Header
          hideNav
          headerActions={
            <a className="wizard-layout_close" href="#" onClick={() => window?.history.back()}>
              <CloseIcon />
            </a>
          }
        />
      </div>
      <Container className="wizard-layout_content">
        <h1 className="wizard-layout_title">Let us help you find the perfect tutoring match!</h1>
        <Card className="wizard-layout_card overflow-hidden">{children}</Card>
        <div className="wizard-layout_text">
          <div className="wizard-layout_hint-title">Prices starting at $30/hour.</div>
          <div className="wizard-layout_hint-text">
            All of our educators are certified teachers or learning specialists who have the training and expertise to
            support a wide range of ages, subject areas, and learning challenges!
          </div>
        </div>
      </Container>
    </div>
  )
}

export default WizardLayout
