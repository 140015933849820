import PersonOn from "@mui/icons-material/Person"
import PersonOffIcon from "@mui/icons-material/PersonOff"
import { Tooltip } from "@mui/material"
import { GridActionsCellItem } from "@mui/x-data-grid"
import React, { Fragment } from "react"
import { Spinner } from "react-bootstrap"
import Alerter from "../../../../../common/alerter"
import apiClient from "../../../../../common/apiClient"

const ActivateOrDeactivateUser = ({ currentUser, params, state, setState }) => {
  const schoolAdmins = state && state.schoolAdmins
  const currentRow = params && params.row
  const currentSchoolAdmin = schoolAdmins.find(sc => sc?.id === currentRow?.identifier)

  const updateStatusForSchoolAdmin = async ({ active }) => {
    const status = active ? "deactivated" : "activated"
    try {
      setState(prevState => ({
        ...prevState,
        updateStatusLoading: true,
      }))
      const updatedListOfSchoolAdmins = schoolAdmins.map(sc => {
        if (sc.id === currentSchoolAdmin.id) {
          return {
            ...sc,
            active,
          }
        }
        return sc
      })

      await apiClient.put(`/api/v2/school_admin_settings/${currentSchoolAdmin.school_admin_setting_id}/change_status`, {
        is_active: active,
      })

      setState(prevState => ({
        ...prevState,
        schoolAdmins: updatedListOfSchoolAdmins,
        updateStatusLoading: false,
      }))

      Alerter.success(`School admin "${currentSchoolAdmin.name}" ${status} successfully`)
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        updateStatusLoading: false,
      }))
      Alerter.error(error.response.data.errors)
    }
  }

  const tooltipTitle =
    currentUser?.id === currentRow?.identifier ? "You are not able to deactivate your own user account" : ""

  return (
    <Fragment>
      {state.updateStatusLoading && (
        <GridActionsCellItem
          icon={<Spinner animation="border" variant="info" size="small" />}
          label="loading"
          className="textPrimary"
          color="primary"
        />
      )}

      {currentRow?.active && !state.updateStatusLoading && (
        <Tooltip title={tooltipTitle}>
          <span>
            <GridActionsCellItem
              icon={<PersonOffIcon />}
              disabled={currentUser?.id === currentRow?.identifier}
              label="Deactivate"
              className="textPrimary"
              onClick={async () => await updateStatusForSchoolAdmin({ active: false })}
              color="primary"
            />
          </span>
        </Tooltip>
      )}

      {!currentRow?.active && !state.updateStatusLoading && (
        <Tooltip title={tooltipTitle}>
          <span>
            <GridActionsCellItem
              icon={<PersonOn />}
              disabled={currentUser?.id === currentRow?.identifier}
              label="Activate"
              className="textPrimary"
              onClick={async () => await updateStatusForSchoolAdmin({ active: true })}
              color="inherit"
            />
          </span>
        </Tooltip>
      )}
    </Fragment>
  )
}

export default ActivateOrDeactivateUser
