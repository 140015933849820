import React from "react"
import { FieldArray } from "react-final-form-arrays"
import Degree from "./Degree"
import RowWarning from "../../blocks/RowWarning"

import { ReactComponent as Plus } from "../../../assets/images/plus.svg"

const addHandler = fields => {
  fields.push({ degree: "", college: "" })
}

const FormFields = ({ dataSources: { user, degrees } }) => {
  return (
    <FieldArray name="degrees" initialValue={user.profile.degrees}>
      {({ fields }) => (
        <>
          <div className="row">
            <h4 className="col-sm-18">What did you study in school?</h4>
            <p className="col-sm-24 mb-4 text-dark">
              Please list the degrees you&#39;ve earned to highlight your amazing qualifications.
            </p>
          </div>
          <div>
            {fields.map((name, index) => (
              <Degree key={index} name={name} fields={fields} index={index} degrees={degrees} />
            ))}
          </div>
          <div className="mb-4">
            <RowWarning className="panel_body-warning" name="degreesLength">
              <button
                type="button"
                className="btn btn-outline-primary d-flex align-items-center"
                onClick={() => addHandler(fields)}
              >
                <div className="icon-16-px">
                  <Plus />
                </div>
                <span className="ml-3">Add degree</span>
              </button>
            </RowWarning>
          </div>
        </>
      )}
    </FieldArray>
  )
}

export default FormFields
