import React from "react"
import { Row, Col, Modal, Button, FormControl } from "react-bootstrap"
import { Form, Field } from "react-final-form"

import { required } from "../../../helpers/validators"

const EditDetailsModal = ({ show, initialValues, onSubmit, onClose }) => {
  return (
    <Modal show={show} size="lg" data-backdrop="static" onHide={onClose} centered>
      <Modal.Header className="px-4 pt-3 pb-2" closeButton>
        <Modal.Title>Video interview details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form initialValues={initialValues} onSubmit={onSubmit}>
          {({ invalid, submitting, handleSubmit }) => (
            <Row>
              <Col lg={18}>
                <h4 className="mb-3">Call details</h4>
                <p className="pb-2">
                  How should the parent contact you? Paste a link for a Zoom or Google Meet! This can be edited later.
                </p>

                <Field name="call_details" validate={required}>
                  {({ input }) => <FormControl {...input} as="textarea" rows={5} />}
                </Field>

                <div className="mt-5">
                  <Button variant="outline-primary" disabled={invalid || submitting} onClick={handleSubmit}>
                    Confirm
                  </Button>
                  <Button variant="link" className="link-primary ml-5" onClick={onClose}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default EditDetailsModal
