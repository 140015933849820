import React, { useCallback, useContext } from "react"
import { Form } from "react-bootstrap"
import { OpeningCard } from "./OpeningCard"
import { QueryContext } from "../../../contexts"
import { applyTutor } from "../providers"
import Alerter from "../../../common/alerter"
import Dialog from "../../blocks/Dialog"
import { Field, Form as FForm } from "react-final-form"

const initialValues = { note: "" }

const ApplyForOpportunityModal = ({ opening, onClose }) => {
  const { getQueryState } = useContext(QueryContext)
  const { request: apply, loading } = getQueryState(applyTutor)

  const submit = useCallback(
    ({ note }) =>
      apply({
        data: {
          note,
          opening_id: opening?.id,
        },
        onSuccess: () => {
          onClose()
          Alerter.success("Application submitted")
        },
      }),
    [opening, onClose, apply]
  )

  return (
    <Dialog
      title="Job opportunity application"
      animation={false}
      size="lg"
      scrollable={true}
      open={!!opening}
      onClose={onClose}
      className="programs-modal"
    >
      <FForm onSubmit={submit} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {opening?.schedule_flexible && (
              <p className="mb-4">
                Thank you for your interest! Your profile will be sent to admin for review. Once they approve, sessions
                with the students will be created. You will be notified by email.
              </p>
            )}

            <OpeningCard opening={opening} cardClassName="mb-4" short />

            <Field name="note" type="text">
              {({ input, meta }) => (
                <>
                  <Form.Control
                    as="textarea"
                    {...input}
                    className="v-2 tutor-dashboard__opening-card-note-input"
                    rows="4"
                    disabled={loading}
                    isInvalid={meta.touched && meta.error}
                    placeholder="Note for admin!"
                  />

                  {meta.touched && meta.error ? (
                    <Form.Control.Feedback type="invalid" className="mt-1">
                      {meta.error}
                    </Form.Control.Feedback>
                  ) : null}
                </>
              )}
            </Field>

            <div className="btrust-dialog__actions">
              <button className="btn btn-primary" disabled={loading} type="submit" data-test="apply-opening">
                Apply for opportunity
              </button>

              <button className="btn btn-outline-primary" disabled={loading} onClick={onClose}>
                Back
              </button>
            </div>
          </form>
        )}
      </FForm>
    </Dialog>
  )
}

export default ApplyForOpportunityModal
