import FavoriteIcon from "@mui/icons-material/Favorite"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Unstable_Grid2"
import React, { useState } from "react"
import DashboardCard from "./dashboardCard"
import { InstructionalDaysDashboard } from "./instructional_days"
import { InstructionalScheduleDashboard } from "./instructional_schedule"
import { ResourcesDashboard } from "./resources"
import TodaysSessionsDashboard from "./todays_sessions"

export const SchoolAdminDashboard = ({ schoolName }) => {
  const [sessionsDate, setSessionsDate] = useState(new Date())

  return (
    <Container maxWidth={false} className="p-0 d-flex flex-column flex-grow-1">
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Box mb={1} mt={2} ml={1} display="flex" alignItems="center">
            <h4 className="text-gray-chat font-weight-normal">
              We <FavoriteIcon color="error" /> <b>{schoolName}</b>
            </h4>
          </Box>
        </Grid>
        <Grid container xs={12} spacing={3} minHeight={300}>
          <Grid xs={12} md={4} display="flex">
            <DashboardCard title="Instructional Days">
              <InstructionalDaysDashboard />
            </DashboardCard>
          </Grid>
          <Grid xs={12} md={4} display="flex">
            <TodaysSessionsDashboard date={sessionsDate} />
          </Grid>
          <Grid xs={12} md={4} display="flex">
            <DashboardCard title="Resources">
              <ResourcesDashboard />
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <DashboardCard>
            <InstructionalScheduleDashboard setSessionsDate={setSessionsDate} />
          </DashboardCard>
        </Grid>
      </Grid>
    </Container>
  )
}
