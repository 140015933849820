import React, { useState, useEffect } from "react"
import { Card, Col, Row, Container, Dropdown, DropdownButton, FormControl, InputGroup } from "react-bootstrap"

const Filter = ({ onChange }) => {
  const [filterNameValue, setFilterNameValue] = useState("")
  const [filterRoleValue, setFilterRoleValue] = useState("All")
  const [filterSortValue, setFilterSortValue] = useState("desc")
  const [filterAutoSuggest, setFilterAutoSuggest] = useState(false)

  useEffect(() => {
    setFilterRoleValue("All")
    setFilterSortValue("desc")
  }, [])

  const onFilterRoleChange = filter => {
    setFilterRoleValue(filter)
    onChange(filterNameValue, filter, filterSortValue)
  }

  const onFilterSortChange = filter => {
    setFilterSortValue(filter)
    onChange(filterNameValue, filterRoleValue, filter)
  }

  const onFilterTextChange = filter => {
    setFilterNameValue(filter)
    if (filter.length > 2) {
      setFilterAutoSuggest(true)
      onChange(filter, filterRoleValue, filterSortValue)
    }
    if (filter.length < 3 && filterAutoSuggest) {
      setFilterAutoSuggest(false)
      onChange("", filterRoleValue, filterSortValue)
    }
  }

  return (
    <Container className="pb-2 px-lg-2 px-0 my-2 container">
      <Card>
        <Row>
          <Col className="px-0">
            <InputGroup className="filter">
              <InputGroup.Prepend>
                <InputGroup.Text id="filter_text" className="chat-filter-prepend" />
              </InputGroup.Prepend>
              <FormControl
                placeholder="name"
                aria-label="name"
                aria-describedby="filter_text"
                className="chat-filter-input shadow-none"
                value={filterNameValue}
                onChange={e => onFilterTextChange(e.target.value)}
              />
              <DropdownButton
                as={InputGroup.Append}
                variant="outline-light"
                title={filterRoleValue}
                id="input-group-dropdown-1"
                className="chat-filter-dropdown shadow-none"
              >
                <Dropdown.Item href="#" onSelect={() => onFilterRoleChange("All")}>
                  All
                </Dropdown.Item>
                <Dropdown.Item href="#" onSelect={() => onFilterRoleChange("Parents")}>
                  Parents
                </Dropdown.Item>
                <Dropdown.Item href="#" onSelect={() => onFilterRoleChange("Tutors")}>
                  Tutors
                </Dropdown.Item>
              </DropdownButton>
              <DropdownButton
                as={InputGroup.Append}
                variant="outline-light"
                title=""
                id="input-group-dropdown-2"
                className="chat-filter-append shadow-none"
              >
                <Dropdown.Item href="#" onSelect={() => onFilterSortChange("asc")}>
                  Response time asc
                </Dropdown.Item>
                <Dropdown.Item href="#" onSelect={() => onFilterSortChange("desc")}>
                  Response time desc
                </Dropdown.Item>
                <Dropdown.Item href="#" onSelect={() => onFilterSortChange("pending")}>
                  Pending
                </Dropdown.Item>
              </DropdownButton>
            </InputGroup>
          </Col>
        </Row>
      </Card>
    </Container>
  )
}

export default Filter
