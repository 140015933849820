import React from "react"

export const DateCell = ({ value, format, first, Link, empty = "-", style, className, handlers }) => {
  const align = first ? "left" : "right"
  const result = format(value)
  return (
    <td scope="row" align={align} style={style} className={className} {...handlers}>
      {value ? Link ? <Link>{result}</Link> : result : empty}
    </td>
  )
}
