import DeselectIcon from "@mui/icons-material/Deselect"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PrintIcon from "@mui/icons-material/Print"
import SelectAllIcon from "@mui/icons-material/SelectAll"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { memo } from "react"
import FilterInputs from "./Filters"

const ButtonGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up("sm")]: {
    marginLeft: "auto",
  },
  "& .MuiButton-root.MuiButton-contained": {
    color: theme.palette.white,
  },
}))

const AccordionStyled = styled(Accordion)(() => ({
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  fontFamily: "'Poppins', sans-serif",
  borderBottom: "1px solid #e0e0e0",
  backgroundColor: "#f8f9fa",
}))

const AccordionSummaryStyled = styled(AccordionSummary)(() => ({
  height: 48,
  minHeight: 48,
}))

const StudentsDataGridToolbar = ({
  isLoading,
  rowSelectionModel,
  handleSelectAll,
  handleUnselectAll,
  handleGeneratePDF,
  rows,
  ...props
}) => {
  // const apiRef = useGridApiContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <>
      <ButtonGroup>
        <Tooltip title="Select All Students">
          <Button
            size="small"
            variant="outlined"
            onClick={handleSelectAll}
            disabled={!rows.length}
            startIcon={!isMobile && <SelectAllIcon />}
            aria-label="Select All Students"
          >
            {isMobile ? <SelectAllIcon /> : "Select All"}
          </Button>
        </Tooltip>
        <Tooltip title="Deselect All Students">
          <Button
            size="small"
            variant="outlined"
            onClick={handleUnselectAll}
            disabled={!rowSelectionModel.length}
            startIcon={!isMobile && <DeselectIcon />}
            aria-label="Deselect All Students"
          >
            {isMobile ? <DeselectIcon /> : "Deselect All"}
          </Button>
        </Tooltip>
        <Tooltip title="Generate and Print Student Credentials">
          <Button
            size="small"
            variant="contained"
            onClick={handleGeneratePDF}
            disabled={!rows.length || !rowSelectionModel.length || isLoading}
            startIcon={!isMobile && <PrintIcon />}
            aria-label="Generate and Print Student Credentials"
            sx={{ color: "#fff" }}
          >
            {isMobile ? (
              isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <PrintIcon />
              )
            ) : isLoading ? (
              "Generating..."
            ) : (
              "Print Credentials"
            )}
          </Button>
        </Tooltip>
      </ButtonGroup>

      <AccordionStyled>
        <AccordionSummaryStyled expandIcon={<ExpandMoreIcon />} aria-controls="filter-content" id="filter-header">
          <Typography variant="subtitle1" fontWeight="700">
            Filters
          </Typography>
        </AccordionSummaryStyled>
        <AccordionDetails>
          <FilterInputs {...props} />
        </AccordionDetails>
      </AccordionStyled>
    </>
  )
}

export default memo(StudentsDataGridToolbar)
