import React, { forwardRef, useCallback, useMemo, useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import { ContextMenuContext } from "../../contexts"
import { className } from "../../helpers/className"

const initialPosition = Object.freeze({ left: 0, top: 0 })

export const ContextMenuPositions = Object.freeze({
  Start: "start",
  Center: "center",
  End: "end",
})

export const ContextMenuNodeTypes = Object.freeze({
  Button: "button",
  Link: "link",
  NativeLink: "native_link",
  Divider: "divider",
  Block: "div",
})

const clickableNodeTypes = [ContextMenuNodeTypes.Button, ContextMenuNodeTypes.Link, ContextMenuNodeTypes.NativeLink]

const ContextMenuItem = ({ children, className: itemClassName = "", nodeType, ...rest }) => {
  const itemClass = useMemo(() => className("brain-trust-context-menu-item", itemClassName), [itemClassName])

  switch (nodeType) {
    case ContextMenuNodeTypes.Button:
      return (
        <button {...rest} className={itemClass}>
          {children}
        </button>
      )
    case ContextMenuNodeTypes.Link:
      return (
        <Link {...rest} className={itemClass}>
          {children}
        </Link>
      )
    case ContextMenuNodeTypes.NativeLink:
      return (
        <a {...rest} className={itemClass}>
          {children}
        </a>
      )
    case ContextMenuNodeTypes.Divider:
      return <hr {...rest} className={itemClass} />
    case ContextMenuNodeTypes.Block:
    default:
      return (
        <div {...rest} className={itemClass}>
          {children}
        </div>
      )
  }
}

const ContextMenu = forwardRef(function ContextMenu(props, ref) {
  const { position, configs, isVisible, closeMenu, className: contextMenuClassName } = props
  const contextMenuClass = useMemo(
    () =>
      className(
        "brain-trust-context-menu",
        "position-absolute",
        isVisible ? "visible" : "invisible",
        contextMenuClassName
      ),
    [contextMenuClassName, isVisible]
  )
  const withAutoClose = useCallback(nodeType => clickableNodeTypes.includes(nodeType), [])
  return (
    <div ref={ref} className={contextMenuClass} style={position}>
      {configs.map(({ title, icon, iconClassName, nodeType, ...rest }, idx) => {
        const onClick = event => {
          closeMenu()
          if (rest.onClick) rest.onClick(event)
        }
        const canonClickPatch = withAutoClose(nodeType)
        return (
          <ContextMenuItem key={idx} nodeType={nodeType} {...rest} onClick={canonClickPatch ? onClick : rest.onClick}>
            <div className="d-flex align-items-center">
              {icon && <span className={className("icon-20-px mr-2", iconClassName)}>{icon}</span>}
              <span>{title}</span>
            </div>
          </ContextMenuItem>
        )
      })}
    </div>
  )
})

const ContextMenuProvider = ({
  children,
  positionX,
  positionY,
  contextMenuClassName,
  className: wrapperClassName,
  anchorRef,
}) => {
  const lastTargetRef = useRef(null)
  const innerAnchorRef = useRef(null)
  const wrapperRef = anchorRef || innerAnchorRef
  const contextMenuRef = useRef(null)
  const [menuConfigs, setMenuConfigs] = useState([])
  const [position, setPosition] = useState(initialPosition)
  const isContextMenuOpen = useMemo(() => menuConfigs.length > 0, [menuConfigs.length])
  const [isVisible, setIsVisible] = useState(false)

  const openContextMenu = useCallback(
    (event, configs = []) => {
      if (configs.length === 0 || !wrapperRef.current) return
      event.stopPropagation()
      closeContextMenu()
      setMenuConfigs(configs)
      lastTargetRef.current = event.target
      const wrapperRect = wrapperRef.current.getBoundingClientRect()
      let target = event.target
      let targetRect = target.getBoundingClientRect()
      while (targetRect.width === 0 && targetRect.height === 0) {
        target = target.parentElement
        targetRect = target.getBoundingClientRect()
      }
      let left
      let top
      switch (positionX) {
        case ContextMenuPositions.Start: {
          left = target.offsetLeft
          break
        }
        case ContextMenuPositions.Center: {
          left = target.offsetLeft + target.offsetWidth / 2
          break
        }
        case ContextMenuPositions.End: {
          left = target.offsetLeft + target.offsetWidth
          break
        }
        default: {
          left = event.clientX - wrapperRect.left
        }
      }
      switch (positionY) {
        case ContextMenuPositions.Start: {
          top = target.offsetTop
          break
        }
        case ContextMenuPositions.Center: {
          top = target.offsetTop + target.offsetHeight / 2
          break
        }
        case ContextMenuPositions.End: {
          top = target.offsetTop + target.offsetHeight
          break
        }
        default: {
          top = event.clientY - wrapperRect.top
        }
      }

      setPosition({ left, top })
    },
    [wrapperRef, closeContextMenu, positionX, positionY]
  )

  const closeContextMenu = useCallback(() => {
    setMenuConfigs([])
    setPosition(initialPosition)
    setIsVisible(false)
  }, [])

  const wrapperClass = useMemo(() => className("position-relative", wrapperClassName), [wrapperClassName])

  const providerValue = useMemo(
    () => ({
      isContextMenuOpen,
      openContextMenu,
      closeContextMenu,
    }),
    [closeContextMenu, isContextMenuOpen, openContextMenu]
  )

  const onClickOutside = useCallback(
    event => {
      if (isContextMenuOpen && !contextMenuRef.current?.contains(event.target)) closeContextMenu()
    },
    [isContextMenuOpen, closeContextMenu]
  )

  const onEscape = useCallback(
    event => {
      if (isContextMenuOpen && event.keyCode === 27) closeContextMenu()
    },
    [isContextMenuOpen, closeContextMenu]
  )

  useEffect(() => {
    // position correction
    if (isContextMenuOpen && contextMenuRef.current && wrapperRef.current) {
      let { left, top } = position
      if (left + contextMenuRef.current.offsetWidth > wrapperRef.current.offsetWidth) {
        left = wrapperRef.current.offsetWidth - contextMenuRef.current.offsetWidth
        if (left < 0) left = 0
      }
      if (top + contextMenuRef.current.offsetHeight > wrapperRef.current.offsetHeight) {
        top = wrapperRef.current.offsetHeight - contextMenuRef.current.offsetHeight
        if (top < 0) top = 0
      }
      if (left !== position.left || top !== position.top) setPosition({ left, top })
      setIsVisible(true)
    }
  }, [isContextMenuOpen, position, wrapperRef])

  useEffect(() => {
    document.addEventListener("click", onClickOutside)
    document.addEventListener("keydown", onEscape)
    return () => {
      document.removeEventListener("click", onClickOutside)
      document.removeEventListener("keydown", onEscape)
    }
  }, [onClickOutside, onEscape])

  return (
    <ContextMenuContext.Provider value={providerValue}>
      <div ref={innerAnchorRef} className={wrapperClass}>
        {children}
        {isContextMenuOpen && (
          <ContextMenu
            ref={contextMenuRef}
            position={position}
            isVisible={isVisible}
            configs={menuConfigs}
            className={contextMenuClassName}
            closeMenu={closeContextMenu}
          />
        )}
      </div>
    </ContextMenuContext.Provider>
  )
}

export default ContextMenuProvider
