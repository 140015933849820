import React, { useState, useEffect } from "react"
import cn from "classnames"
import { Dropdown, Form, Button } from "react-bootstrap"
import { Field, useFormState, useForm } from "react-final-form"

import FormRow from "../../../blocks/FormRow"
import { valueOrNull } from "../../../../helpers/formatters"
import { showTouchedFieldError } from "../../../../helpers/forms"
import Condition from "../../../Condition"
import { required } from "../../../../helpers/validators"
import { formatZip } from "../../../../helpers/formatters"
import FormSelect from "../../../blocks/FormSelect"

const colProps = { lg: 18, className: "mb-0" }

const LocationDropdown = ({
  className,
  completed,
  getButtonText,
  disabled,
  onSubmit,
  drop,
  submitButtonText,
  options,
}) => {
  const [showPopper, setShowPopper] = useState(false)

  const { values } = useFormState()
  const form = useForm()

  useEffect(() => {
    if (showPopper && !values.meeting_format && form.mutators.setFieldData) {
      form.mutators.setFieldData("meeting_format", true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopper])

  const onToggle = show => {
    setShowPopper(show)
    if (onSubmit && completed) onSubmit(values)
  }

  const handleSubmit = () => {
    setShowPopper(false)
    onToggle(false)
  }

  return (
    <Dropdown show={showPopper} drop={drop || "up"} onToggle={onToggle}>
      <Dropdown.Toggle
        variant="outline-dropdown"
        disabled={disabled}
        className={cn(className, completed && "selected")}
      >
        {getButtonText(values)}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-4 min-w-md-400-px max-vw-100 overflow-auto">
        <Field name="meeting_format" type="radio" value="pencil_spaces">
          {({ input }) => (
            <Form.Check {...input} id="pencil_spaces" className="text-nowrap mb-3" label="Pencil spaces" custom />
          )}
        </Field>
        <Field name="meeting_format" initialValue={true} type="radio" value="online">
          {({ input }) => (
            <Form.Check {...input} id="meeting_format" className="text-nowrap mb-3" label="Online lessons" custom />
          )}
        </Field>
        <Field name="meeting_format" type="radio" value="offline">
          {({ input }) => <Form.Check {...input} id="offline" className="text-nowrap" label="Offline lessons" custom />}
        </Field>
        <Condition when="meeting_format" is="offline">
          <p className="text-nowrap mt-4">Address where sessions will take place</p>

          <Field name="address_1" parse={valueOrNull}>
            {({ input, meta }) => (
              <FormRow label="Address 1" ColProps={colProps}>
                <Form.Control {...input} maxLength="255" isInvalid={showTouchedFieldError(meta)} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="address_2" parse={valueOrNull}>
            {({ input, meta }) => (
              <FormRow label="Address 2" ColProps={colProps}>
                <Form.Control {...input} maxLength="255" isInvalid={showTouchedFieldError(meta)} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="city" parse={valueOrNull}>
            {({ input, meta }) => (
              <FormRow label="City" ColProps={colProps}>
                <Form.Control {...input} maxLength="255" isInvalid={showTouchedFieldError(meta)} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="state" parse={valueOrNull}>
            {({ input, meta }) => (
              <FormRow label="State" ColProps={colProps}>
                <FormSelect
                  {...input}
                  options={options.states}
                  placeholder="Pick state"
                  isInvalid={showTouchedFieldError(meta)}
                />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>

          <Field name="zip" parse={formatZip}>
            {({ input, meta }) => (
              <FormRow label="Zip" ColProps={colProps}>
                <Form.Control {...input} maxLength="255" isInvalid={showTouchedFieldError(meta)} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>
        </Condition>
        <Condition when="meeting_format" is="online">
          <p className="text-nowrap mt-4">Add link of online meeting service</p>

          <Field name="meeting_info" parse={valueOrNull} validate={required}>
            {({ input, meta }) => (
              <FormRow label="Link" ColProps={colProps} className="mb-0">
                <Form.Control {...input} isInvalid={showTouchedFieldError(meta)} />
                <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
              </FormRow>
            )}
          </Field>
        </Condition>
        <Button
          variant="outline-primary"
          size="sm"
          className="mt-4"
          type="button"
          disabled={!completed}
          onClick={handleSubmit}
        >
          {submitButtonText}
        </Button>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LocationDropdown
