import React from "react"

export const NoteRow = ({ label, text, onEdit }) => {
  return (
    <div className="d-flex flex-column">
      <div className="text-black-50">{label}</div>
      <div className="d-inline">
        {text}
        <button className="btn btn-link ml-2" onClick={onEdit}>
          Edit
        </button>
      </div>
    </div>
  )
}

export default NoteRow
