import React from "react"

const UserInfo = ({ user }) => {
  if (!user) return null

  return (
    <div className="user-info" data-show-avatar={user.showAvatar}>
      <div className="user-name">
        <div className="user-name_title">{user.name}</div>
      </div>
      {user.profilePhotoAttached ? (
        <div className="user-info_photo">
          <img src={user.profilePhotoUrl} alt="User Profile" />
        </div>
      ) : (
        <div className="user-info_initials">{user.initials}</div>
      )}
      {user.invitedByTutorsPending && <span className="user-info_indicator"></span>}
    </div>
  )
}

export default UserInfo
