import React, { useState, useMemo } from "react"
import { Row } from "react-bootstrap"
import { Table, Card } from "react-bootstrap"
import cn from "classnames"

const TutorInfo = ({ tutorName }) => {
  return (
    <Row>
      <b className="opening_tutor_status -approved mr-2">Tutor: {tutorName}</b>
      <b className="opening_session">Session created</b>
    </Row>
  )
}

export const Group = ({ group, tutorList, onRemove, initialOpenState = false }) => {
  const [open, setOpen] = useState(initialOpenState)
  const tutor = useMemo(
    () => tutorList.find(({ group: g }) => g && group && g.id === group.id)?.tutor,
    [group, tutorList]
  )

  return (
    <Card className="mb-3 flat sm col-24">
      <Card.Header className={cn({ "no-underline": !open })}>
        <Row>
          <div
            className="cursor-pointer flex-grow-1"
            onClick={() => {
              setOpen(!open)
            }}
          >
            <span className={open ? "mr-2 arrow-down-icon" : "mr-2 arrow-right-icon"} />
            <b>{group.name}</b>
          </div>
          {tutor && <TutorInfo tutorName={tutor.name} />}
          {onRemove && (
            <div className="px-2 cursor-pointer" onClick={() => onRemove(group.id)}>
              ×
            </div>
          )}
        </Row>
      </Card.Header>
      {open && (
        <Card.Body>
          <Table className="grid-table" size="sm">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Grade</th>
                <th>Learning difference</th>
                <th>Parent email</th>
              </tr>
            </thead>
            <tbody>
              {group.students.map(ogs => (
                <tr key={ogs.id}>
                  <td>{ogs.first_name}</td>
                  <td>{ogs.last_name}</td>
                  <td>{ogs.grade}</td>
                  <td>{ogs.special_treatments}</td>
                  <td>{ogs.parent_email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      )}
    </Card>
  )
}
