import React, { useContext, useState } from "react"
import { Modal } from "react-bootstrap"
import OpeningTabs from "./OpeningTabs"
import Step1Students from "./Step1Students"
import { OpeningContext } from "./context"
import { findIndex } from "lodash"
import { steps, stepValid } from "./logic"
import Step2Configuration from "./Step2Configuration"
import Step3Schedule from "./Step3Schedule"
import Step4Overview from "./Step4Overview"

const OpeningModal = ({ show, onClose, schools, onSubmit, submitting, groups, loading }) => {
  const { opening } = useContext(OpeningContext)

  const [activeStep, setActiveStep] = useState(steps[0].key)

  const isNextDisabled = () => {
    return !stepValid(activeStep, opening)
  }

  const onNextStep = () => {
    const i = findIndex(steps, s => s.key === activeStep)
    if (i < steps.length - 1) setActiveStep(steps[i + 1].key)
    else if (i === steps.length - 1) onSubmit()
  }

  const close = () => {
    setActiveStep(steps[0].key)
    onClose()
  }

  return (
    <Modal show={show} size="xl" onHide={close} backdrop="static" keyboard={false}>
      <Modal.Header className="px-4 pt-4 pb-3" closeButton>
        <Modal.Title className="pb-1">New tutoring opening</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="spinner-wrapper">
            <div className="spinner-border text-primary" />
          </div>
        ) : (
          <>
            <OpeningTabs activeStep={activeStep} setActiveStep={setActiveStep} opening={opening} />
            {activeStep === "students" && <Step1Students schools={schools} groups={groups} />}
            {activeStep === "configuration" && <Step2Configuration />}
            {activeStep === "schedule" && <Step3Schedule />}
            {activeStep === "overview" && <Step4Overview />}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        {submitting ? (
          <button type="button" className="btn btn-outline-primary" disabled>
            <div className="spinner-wrapper">
              <div className="spinner-border text-primary" />
            </div>
          </button>
        ) : (
          <button type="button" className="btn btn-outline-primary" disabled={isNextDisabled()} onClick={onNextStep}>
            {activeStep === "overview" ? "Create" : "Next"}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default OpeningModal
