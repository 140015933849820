import React, { useMemo } from "react"
import { Modal } from "react-bootstrap"

import InterviewForm from "./InterviewForm"
import { formatDateTimeKeepZone } from "../../../helpers/dates"
import { serializeRequestOptions } from "../../../helpers/serializers"

const InterviewModal = ({ editMode = true, show, handleClose, dataSources, urls, tutorTimeZone, onSubmit }) => {
  const { parent, tutor, interview, isParent } = dataSources

  const options = useMemo(() => serializeRequestOptions(dataSources), [dataSources])

  const initialValues = useMemo(() => {
    if (!interview) {
      return {}
    }
    return {
      ...interview,
      start_at_slots: interview.start_at_slots.map(v => formatDateTimeKeepZone(v)),
      started_at: formatDateTimeKeepZone(interview.started_at),
    }
  }, [interview])

  const handleSubmit = async values => {
    if (onSubmit) {
      const serializedValues = {
        ...values,
        tutor_id: tutor.id,
        start_at_slots: values.start_at_slots.map(v => formatDateTimeKeepZone(v)),
        started_at: formatDateTimeKeepZone(values.started_at),
      }
      await onSubmit(serializedValues)
    }
  }

  return (
    <Modal show={show} size="xl" onHide={handleClose}>
      <Modal.Header className="px-4 pt-4 pb-2" closeButton>
        <Modal.Title>Request online interview </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <InterviewForm
          editMode={editMode}
          isParent={isParent}
          initialValues={initialValues}
          options={options}
          parent={parent}
          tutor={tutor}
          urls={urls}
          tutorTimeZone={tutorTimeZone}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  )
}

export default InterviewModal
