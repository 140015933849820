import { filter, flatten, flow, keyBy, map, partialRight } from "lodash"
import React from "react"
import { ReactComponent as ActionNeeded } from "../../assets/images/action-needed.svg"
import { ReactComponent as InProgress } from "../../assets/images/in-progress.svg"
import { ReactComponent as ReportOverdue } from "../../assets/images/report-overdue.svg"
import { ReactComponent as ReportSent } from "../../assets/images/report-sent.svg"
import { ReactComponent as ReportSubmitted } from "../../assets/images/report-submitted.svg"
import { ReactComponent as ReportStateDone } from "../../assets/images/report_state_done.svg"
import { ReactComponent as ReportStateNoReport } from "../../assets/images/report_state_no_report.svg"
import { ReactComponent as ReportStatePending } from "../../assets/images/report_state_pending.svg"
import { noEventDayStatus } from "./constants"

import { formatDate } from "../../helpers/dates"
import { EMPTY } from "../../session_report/subskill_statuses"

const statusMap = {
  planned: {
    classname: "-planned",
    header: "Planned",
    text: () => "Fill all fields in the report and finish all the sessions to publish the report",
    icon: null,
  },
  in_progress: {
    classname: "-progress",
    header: "In Progress",
    text: () => "Fill all fields in the report and finish all the sessions to publish the report",
    icon: InProgress,
  },
  report_submitted: {
    classname: "-submitted",
    header: "Report Submitted",
    text: report => (
      <>
        Submitted at <strong>{report?.submitted_at}</strong>
      </>
    ),
    icon: ReportSubmitted,
  },
  action_needed: {
    classname: "-action",
    header: "Action Needed",
    text: () => "Fill all fields in the report and finish all the sessions to publish the report",
    icon: ActionNeeded,
  },
  report_sent: {
    classname: "-sent",
    header: "Report Sent",
    text: report => (
      <>
        Sent at <strong>{report?.sent_at}</strong>
      </>
    ),
    icon: ReportSent,
  },
  report_overdue: {
    classname: "-overdue",
    header: "Report Overdue",
    text: () => "Fill all fields in the report and finish all the sessions to publish the report",
    icon: ReportOverdue,
  },
  report_abandoned: {
    classname: "-abandoned",
    header: "Report Abandoned",
    text: () => "",
    icon: ReportOverdue,
  },
  no_sessions: {
    classname: "-abandoned",
    header: "Skipped: No Sessions",
    text: () => "",
    icon: null,
  },
}

export const getStatusClass = status => statusMap[status]?.classname || ""
export const getStatusHeader = status => statusMap[status]?.header || ""
export const getStatusText = status => statusMap[status]?.text || ""
export const getStatusIcon = status => statusMap[status]?.icon || null
export const getStatus = status => statusMap[status] || {}

export const getTabIcon = (isAbsent, isValid) => {
  if (isAbsent) return ReportStateNoReport
  if (isValid) return ReportStateDone
  return ReportStatePending
}

export const getTabIconClassName = (isAbsent, isValid) => {
  if (isAbsent) return "-empty"
  if (isValid) return "-valid"
  return "-invalid"
}

export const selectProgramSubskills = flow(
  partialRight(map, skill => skill.subskills),
  flatten,
  partialRight(filter, subskill => subskill.mastered_status && !(subskill.mastered_status === EMPTY)),
  partialRight(keyBy, value => value.id)
)

export const isValidUrl = urlString => {
  const pattern = [
    "^(https?:\\/\\/)?", // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|", // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))", // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*", // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?", // validate query string
    "(\\#[-a-z\\d_]*)?$", // validate fragment locator
  ].join("")
  var urlPattern = new RegExp(pattern, "i")
  return Boolean(urlPattern.test(urlString))
}

export const checkUrl = str => {
  const link = str || ""
  const linkUrl = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`

  try {
    if (!isValidUrl(linkUrl)) throw new Error(link)
    const url = new URL(linkUrl)
    return url.href
  } catch (e) {
    return null
  }
}

export const daysInStatus = (statuses, days) => days.every(day => [...statuses, noEventDayStatus].includes(day.status))

export const getScheduleOfflineAddress = schedule => {
  const { address_1, address_2, city, state, zip } = schedule
  const addressParts = [address_1, address_2, city, state, zip].filter(Boolean)
  return addressParts.join(" ")
}

export const checkActionExist = (actions, name) => actions.includes(name)

export const formatConnectionDates = connection => {
  const { start_on, end_on } = connection
  const startDate = formatDate(start_on, "D MMM YYYY")
  const endDate = end_on ? formatDate(end_on, "D MMM YYYY") : ""
  const dateRange = end_on ? ` – ${endDate}` : ""
  return `${end_on ? "" : "starting "} ${startDate}${dateRange}`
}
