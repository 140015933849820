import { Button, CardContent, CardHeader, Stack, Typography } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import ProgramDetails from "../ProgramDetails"
import { StyledCard } from "../StyledComponents"

const openInNewTab = url => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer")
  if (newWindow) newWindow.opener = null
}

export const TutorAdministeredTutorView = ({ data }) => (
  <StyledCard>
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      {data.assignments?.tutor_administered?.length > 0 ? (
        <Stack spacing={1}>
          {data.assignments.tutor_administered.map(({ student_name, link }) => (
            <Button
              key={student_name}
              onClick={() => openInNewTab(link)}
              fullWidth
              size="small"
              variant="contained"
              aria-label={`Start ${student_name}'s Assessment`}
              disableElevation
            >
              {`Start ${student_name}'s Assessment`}
            </Button>
          ))}
        </Stack>
      ) : (
        <Typography variant="body1" color="text.secondary">
          No assessments available at this time.
        </Typography>
      )}
    </CardContent>
  </StyledCard>
)

TutorAdministeredTutorView.propTypes = {
  data: PropTypes.shape({
    program_name: PropTypes.string.isRequired,
    assignment_name: PropTypes.string,
    assignments: PropTypes.shape({
      tutor_administered: PropTypes.arrayOf(
        PropTypes.shape({
          student_name: PropTypes.string.isRequired,
          link: PropTypes.string.isRequired,
        })
      ),
    }),
  }).isRequired,
}

export const TutorAdministeredStudentView = ({ data }) => (
  <StyledCard>
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      <Typography variant="body1" color="text.secondary">
        Your tutor will administer this assessment during your session.
      </Typography>
    </CardContent>
  </StyledCard>
)

TutorAdministeredStudentView.propTypes = {
  data: PropTypes.shape({
    program_name: PropTypes.string.isRequired,
    assignment_name: PropTypes.string,
  }).isRequired,
}
