import { ConfigurationContext, DataContext, DataContextActions } from "@/contexts"
import { useKeyBuilder } from "@/hooks/useKeyBuilder"
import { reducer } from "@/store/dataReducer"
import React, { useCallback, useContext, useEffect, useReducer } from "react"

export const StatelessDataProvider = ({ initialState, children }) => {
  const parentContextActions = useContext(DataContextActions)

  const updateState = useCallback(
    (config, data) => {
      parentContextActions.updateState(config, data, {
        updateState: parentContextActions.updateState,
        getState: parentContextActions.getState,
      })
    },
    [parentContextActions]
  )

  const getState = useCallback(config => parentContextActions.getState(config), [parentContextActions])

  useEffect(() => {
    return initialState ? parentContextActions.setPartialState(initialState, true) : undefined
  }, [initialState, parentContextActions])

  return (
    <DataContextActions.Provider value={{ ...parentContextActions, updateState, getState }}>
      {children}
    </DataContextActions.Provider>
  )
}

export const StatefulDataProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const keyBuilder = useKeyBuilder()

  const resetFields = useCallback(
    fields => {
      fields.forEach(name => {
        if (state[name]) dispatch({ name, data: undefined })
      })
    },
    [state]
  )

  const setPartialState = useCallback(
    partialState => {
      Object.keys(partialState).forEach(name => {
        if (state[name] === undefined) dispatch({ name, data: partialState[name] })
      })

      return () => resetFields(Object.keys(partialState))
    },
    [resetFields, state]
  )

  const updateState = useCallback(
    (config, data) => {
      const { alias, skipStorage, onStoreUpdate = data => data } = config
      if (skipStorage) return
      const name = alias || keyBuilder(config)
      dispatch({ name, data: onStoreUpdate(data, { updateState, getState }) })
    },
    [getState, keyBuilder]
  )

  const getState = useCallback(
    config => {
      const key = (typeof config === "string" ? config : config.alias) || keyBuilder(config)
      return state[key]
    },
    [keyBuilder, state]
  )

  return (
    <DataContext.Provider value={state}>
      <DataContextActions.Provider value={{ setPartialState, updateState, resetFields, getState }}>
        {children}
      </DataContextActions.Provider>
    </DataContext.Provider>
  )
}

const LayerDataProvider = ({ initialState, connectToParent = false, children }) => {
  const parentData = useContext(DataContext)
  const [state, dispatch] = useReducer(reducer, {})
  const parentActions = useContext(DataContextActions)
  const { current: currentConfigs } = useContext(ConfigurationContext)
  const keyBuilder = useKeyBuilder()

  const resetFields = useCallback(
    fields => {
      if (fields.length === 0) return
      fields.filter(name => state[name]).forEach(name => dispatch({ name, data: void 0 }))
      if (connectToParent) {
        const rest = fields.filter(name => !state[name])
        parentActions.resetFields(rest)
      }
    },
    [connectToParent, parentActions, state]
  )

  const setPartialState = useCallback(
    (partialState, initial) => {
      if (connectToParent) return parentActions.setPartialState(partialState, initial)
      else {
        const names = []
        Object.keys(partialState).forEach(name => {
          if (initial && state[name] !== void 0) return
          dispatch({ name, data: partialState[name] })
          names.push(name)
        })
        return () => resetFields(names)
      }
    },
    [connectToParent, parentActions, resetFields, state]
  )

  const updateState = useCallback(
    (config, data) => {
      let alias
      let skipStorage = false
      let onStoreUpdate = data => data
      if (typeof config === "string") alias = config
      else {
        if (config.alias) alias = config.alias
        if (config.skipStorage) skipStorage = config.skipStorage
        if (config.onStoreUpdate) onStoreUpdate = config.onStoreUpdate
      }
      if (skipStorage) return
      if (connectToParent && !currentConfigs.includes(config)) {
        parentActions.updateState(config, data)
        return
      }
      const name = alias || keyBuilder(config)
      dispatch({ name, data: onStoreUpdate(data, { updateState, getState }) })
    },
    [connectToParent, currentConfigs, getState, keyBuilder, parentActions]
  )

  const getState = useCallback(
    config => {
      const key = (typeof config === "string" ? config : config.alias) || keyBuilder(config)
      return { ...parentData, ...state }[key]
    },
    [keyBuilder, parentData, state]
  )

  useEffect(() => {
    if (initialState) {
      return setPartialState(initialState, true)
    }
  }, [initialState, setPartialState])

  return (
    <DataContext.Provider value={{ ...parentData, ...state }}>
      <DataContextActions.Provider value={{ updateState, setPartialState, resetFields, getState }}>
        {children}
      </DataContextActions.Provider>
    </DataContext.Provider>
  )
}

export const DataProvider = ({ type, connectToParent, initialState, children }) => {
  switch (type) {
    case "singleSource":
      return connectToParent ? (
        <StatelessDataProvider initialState={initialState}>{children}</StatelessDataProvider>
      ) : (
        <StatefulDataProvider initialState={initialState}>{children}</StatefulDataProvider>
      )
    case "layerSource":
    default:
      return (
        <LayerDataProvider connectToParent={connectToParent} initialState={initialState}>
          {children}
        </LayerDataProvider>
      )
  }
}
