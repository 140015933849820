import React from "react"

import alerter from "../common/alerter"

const withAlerter = WrappedComponent => {
  const f = props => {
    return <WrappedComponent {...props} alerter={alerter} />
  }
  return f
}

export default withAlerter
