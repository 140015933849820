import React from "react"
import { Form, Col } from "react-bootstrap"

const FormRow = ({
  label,
  required = false,
  LabelProps = { lg: 6 },
  ColProps = { lg: 9 },
  RowProps = {},
  className = "",
  children,
}) => (
  <Form.Group className={className}>
    <Form.Row {...RowProps}>
      {children.label ||
        (label && (
          <Form.Label column {...LabelProps}>
            {children.label || label}
            {required && <sup>*</sup>}
          </Form.Label>
        ))}
      <Form.Group as={Col} {...ColProps}>
        {children.col || children}
      </Form.Group>
    </Form.Row>
  </Form.Group>
)

export default FormRow
