import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import React from "react"
import customTheme from "../library/theme"

function BRTThemeProvider({ children }) {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default BRTThemeProvider
