import React, { useMemo } from "react"
import { Button, Card, Badge } from "react-bootstrap"
import { compact } from "lodash/fp"

import {
  getOfflineLocationText,
  getProgramTitle,
  getFullName,
  getPrivateFullName,
  getStruggleWithFullSummary,
} from "../../../helpers/presentational"
import ScheduleSummary from "../../schedule_session/ScheduleSummary"
import { serializeRequestOptions } from "../../../helpers/serializers"
import { formatDateKeepZone } from "../../../helpers/dates"
import MessageButton from "../../chat/MessageButton"
import { DECLINED_REQUEST, PENDING_REQUEST } from "../../../common/constants"

const SessionRequestCard = ({
  sessionRequest,
  dataSources,
  isConfirmationPending,
  isDeclinePending,
  saveMessageUrl,
  enableMessageButtonUrl,
  onRequestShow,
  onRequestConfirm,
  onRequestDecline,
}) => {
  const parent = useMemo(() => ({ ...sessionRequest.parent, students: [sessionRequest.student] }), [sessionRequest])
  const options = useMemo(() => serializeRequestOptions({ ...dataSources, parent }), [dataSources, parent])

  const parentName = getFullName(sessionRequest.parent)
  const recurrenceText = sessionRequest.recurrence ? "a recurring" : "a single"

  const programs = compact([
    ...sessionRequest.program_ids.map(id => getProgramTitle(id, options.programs)),
    sessionRequest.executive_function && "Executive function",
  ])

  const struggleWithSummary = getStruggleWithFullSummary(sessionRequest.struggle_with, options).join(", ")

  return (
    <Card className="card-outline mb-4">
      <Card.Body>
        <div className="d-flex justify-content-between flex-column-reverse flex-md-row">
          <h5 className="mb-2">
            {parentName} wants to schedule {recurrenceText} session
          </h5>
          <Button variant="link" className="link-primary align-self-end align-self-md-start" onClick={onRequestShow}>
            See details
          </Button>
        </div>
        <ScheduleSummary values={sessionRequest} />
        <div className="mb-1">Programs: {programs.join(", ")}</div>
        {sessionRequest.struggle_with && struggleWithSummary && (
          <div className="mb-1">Struggle with: {struggleWithSummary}</div>
        )}
        <div className="mb-2">
          Location: {sessionRequest.online ? "Online" : getOfflineLocationText(sessionRequest, options)}
        </div>
        {sessionRequest.additional_info && <div className="mb-2">Notes: {sessionRequest.additional_info}</div>}
        {sessionRequest.conflict_at.map(label => (
          <Badge key={label} variant="error" className="default-badge text-wrap text-left mt-3 mr-2">
            {label}
          </Badge>
        ))}

        {!sessionRequest.cc_details && (
          <Badge variant="error" className="default-badge text-wrap text-left mt-3 mr-2">
            Confirmation is not available since parent have to set up their payment information
          </Badge>
        )}
        <div className="d-flex justify-content-between align-items-end mt-3 flex-column flex-md-row">
          <div>
            {sessionRequest.status === DECLINED_REQUEST && (
              <Badge variant="error" className="default-badge text-wrap text-left mr-2">
                Declined
              </Badge>
            )}
            <div className="d-flex flex-column flex-sm-row">
              {sessionRequest.status === PENDING_REQUEST && (
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="mb-2 mb-md-0 mr-0 mr-md-4 mt-3"
                  disabled={isConfirmationPending || !sessionRequest.cc_details}
                  onClick={onRequestConfirm}
                >
                  <span className={isConfirmationPending ? "invisible" : "visible"}>Confirm session</span>
                  {isConfirmationPending && (
                    <div className="spinner-wrapper">
                      <div className="spinner-border text-white" />
                    </div>
                  )}
                </Button>
              )}

              <MessageButton
                buttonText="Message parent"
                className="btn-sm mr-0 mr-md-4 mb-2 mb-md-0 mt-3"
                dataSources={{
                  urls: { save_message: saveMessageUrl, enable_message_button: enableMessageButtonUrl },
                  from: {
                    id: sessionRequest.tutor.id,
                    name: getFullName(sessionRequest.tutor),
                    email: sessionRequest.tutor.email,
                  },
                  recipient: {
                    id: parent.id,
                    name: getPrivateFullName(parent),
                    averageResponseTime: "unknown",
                  },
                  subject: "",
                  changeSubject: true,
                }}
              />

              {sessionRequest.status === PENDING_REQUEST && (
                <Button
                  variant="link"
                  size="sm"
                  className="link-primary mb-2 mb-md-0 mt-3"
                  disabled={isDeclinePending}
                  onClick={onRequestDecline}
                >
                  <span className={isDeclinePending ? "invisible" : "visible"}>Decline session</span>
                  {isDeclinePending && (
                    <div className="spinner-wrapper">
                      <div className="spinner-border text-white" />
                    </div>
                  )}
                </Button>
              )}
            </div>
          </div>
          <div className="text-muted ml-auto">
            Request sent {formatDateKeepZone(sessionRequest.request_sent_at, "MM/DD/YYYY")}, updated{" "}
            {formatDateKeepZone(sessionRequest.updated_at, "MM/DD/YYYY")}
          </div>
        </div>
      </Card.Body>
      {sessionRequest.decline_reason && (
        <Card.Footer>
          <span className="font-weight-medium mr-1">Your comment:</span> {sessionRequest.decline_reason}
        </Card.Footer>
      )}
    </Card>
  )
}

export default SessionRequestCard
