import { useCallback, useState } from "react"

/**
 * Custom hook to manage the state of a preview dialog.
 *
 * @returns {Object} An object containing the state of the preview dialog and functions to open and close the dialog.
 * @property {Object} previewDialogState - The current state of the preview dialog.
 * @property {boolean} previewDialogState.open - Indicates whether the dialog is open or closed.
 * @property {string|null} previewDialogState.pdfUrl - The URL of the PDF to be previewed, or null if no PDF is set.
 * @property {Function} openPreviewDialog - Function to open the preview dialog with a specified PDF URL.
 * @property {Function} closePreviewDialog - Function to close the preview dialog.
 */
const useDialogState = () => {
  const [previewDialogState, setPreviewDialogState] = useState({ open: false, pdfUrl: null })

  /**
   * Opens the preview dialog with the specified PDF URL.
   *
   * @param {string} pdfUrl - The URL of the PDF to be previewed.
   */
  const openPreviewDialog = useCallback(pdfUrl => {
    setPreviewDialogState({ open: true, pdfUrl })
  }, [])

  /**
   * Closes the preview dialog.
   */
  const closePreviewDialog = useCallback(() => {
    setPreviewDialogState({ open: false, pdfUrl: null })
  }, [])

  return { previewDialogState, openPreviewDialog, closePreviewDialog }
}

export default useDialogState
