import React, { useEffect, useRef } from "react"
import { Form as FForm, Field } from "react-final-form"
import { Form } from "react-bootstrap"
import { FormAutoSubmit } from "../components"

const FieldNames = {
  Starred: "starred",
  Hidden: "show_hidden",
}

const initialValues = {
  [FieldNames.Hidden]: false,
  //[FieldNames.Starred]: false,
}

export const TutorsFiltersForm = ({ onSubmit }) => {
  const submitAbilityRef = useRef(false)
  useEffect(() => {
    submitAbilityRef.current = true
  }, [])
  return (
    <FForm initialValues={initialValues} onSubmit={onSubmit} subscription={{ values: true }}>
      {({ handleSubmit }) => (
        <FormAutoSubmit
          submitAbilityRef={submitAbilityRef}
          onSubmit={handleSubmit}
          className="d-flex align-items-center gap-2"
        >
          {/*<Field name={FieldNames.Starred} type="checkbox">*/}
          {/*  {({ input }) => (*/}
          {/*    <Form.Check id={FieldNames.Starred} label="Starred tutors" className="v-2" custom {...input} />*/}
          {/*  )}*/}
          {/*</Field>*/}
          <Field name={FieldNames.Hidden} type="checkbox">
            {({ input }) => (
              <Form.Check id={FieldNames.Hidden} label="Hidden tutors" className="v-2" custom {...input} />
            )}
          </Field>
        </FormAutoSubmit>
      )}
    </FForm>
  )
}
