import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import PropTypes from "prop-types"
import React, { useMemo } from "react"

const TableSkeletonLoader = React.memo(({ rowCount = 20, columnCount = 6 }) => {
  const headerCells = useMemo(
    () =>
      Array.from({ length: columnCount }).map((_, index) => (
        <TableCell key={index}>
          <Skeleton variant="text" />
        </TableCell>
      )),
    [columnCount]
  )

  const bodyRows = useMemo(
    () =>
      Array.from({ length: rowCount }).map((_, rowIndex) => (
        <TableRow key={rowIndex}>
          {Array.from({ length: columnCount }).map((_, colIndex) => (
            <TableCell key={colIndex}>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      )),
    [rowCount, columnCount]
  )

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Loading table skeleton">
        <TableHead>
          <TableRow>{headerCells}</TableRow>
        </TableHead>
        <TableBody>{bodyRows}</TableBody>
      </Table>
    </TableContainer>
  )
})

TableSkeletonLoader.propTypes = {
  rowCount: PropTypes.number,
  columnCount: PropTypes.number,
}

TableSkeletonLoader.displayName = "TableSkeletonLoader"

export default TableSkeletonLoader
