import React, { useMemo, useContext } from "react"
import { formatMoney } from "../../../../helpers/formatters"
import { TutorEditDialog } from "../../tutors/tutor_edit_dialog"
import { checkActionExist, formatConnectionDates } from "../../helpers"
import { ConnectionLevels, ScheduleActions } from "../../constants"
import { Link } from "react-router-dom"
import { DataContext } from "../../../../contexts"
import { useDialogState } from "../../../../hooks/useDialogState"
import ConfirmationModalProvider from "../../../blocks/ConfirmationModal"
// import { ReactComponent as CrownIcon } from "../../../../assets/images/crown.svg"

export const Tutors = ({ schedule }) => {
  const { scheduleId } = useContext(DataContext)
  const { dialogState, openDialog, closeDialog } = useDialogState()
  const canChange = useMemo(() => checkActionExist(schedule.available_actions, ScheduleActions.Update), [schedule])

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h6 className="font-weight-semibold mb-0 flex-grow-1">Tutors</h6>

        {canChange && schedule.connections && schedule.connections.length > 0 ? (
          <Link to={`${scheduleId}/tutors`} className="btn btn-link">
            Edit
          </Link>
        ) : null}
      </div>

      {!schedule.connections || !schedule.connections.length ? (
        <button onClick={() => openDialog()} className="btn btn-link align-self-start add_tutor_button">
          + Assign tutor
        </button>
      ) : (
        <div className="d-flex flex-column gap-05">
          {schedule.connections.map(conn => (
            <div key={conn.id} className="d-flex">
              <div className="schedule-dashboard__tutor-avatar-box">
                <img src={conn.tutor.avatar_url} alt="Tutor avatar" />
              </div>
              <div>
                <div className="d-flex gap-05 align-items-center">
                  <div className="font-weight-medium schedule-dashboard__tutor-text">
                    {`${conn.tutor.first_name} ${conn.tutor.last_name}`}
                  </div>
                  {/* {conn.is_actual ? (
                    <div className="icon-16-px color -yellow">
                      <CrownIcon />
                    </div>
                  ) : null} */}
                </div>
                <div className="text-gray-chat font-size-small font-weight-medium schedule-dashboard__tutor-text">
                  {conn.position_level === ConnectionLevels.Main ? "Main" : "Substitute"}, {formatConnectionDates(conn)}
                </div>
              </div>

              {conn.rate && (
                <div className="schedule-dashboard__tutor-rate font-weight-semibold flex-grow-1 text-right">
                  {formatMoney(conn.rate)}/hr
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      <ConfirmationModalProvider>
        <TutorEditDialog open={dialogState.open} onClose={closeDialog} />
      </ConfirmationModalProvider>
    </>
  )
}
