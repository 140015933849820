import React from "react"
import pluralize from "pluralize"
import cn from "classnames"

import TutorCard from "./tutor_card/TutorCard"
import { getQueryStringOmitParams } from "../../common/searchOptions"
import TutorsListSortDropdown from "./TutorsListSortDropdown"

const RECOMMENDED_TUTORS_COUNT = 3

const TutorsList = ({ tutors = [], page, count, options, shouldSplitResults, sort, onSortChanged }) => {
  const queryString = getQueryStringOmitParams(["page", "limit"], ["zip"])
  const tutorsWithLinks = tutors.map(({ profile_link, ...tutor }) => ({
    ...tutor,
    profile_link: profile_link + queryString,
  }))

  const recommendedTutors = page === 1 ? tutorsWithLinks.slice(0, RECOMMENDED_TUTORS_COUNT) : []
  const matchedTutors = page === 1 ? tutorsWithLinks.slice(RECOMMENDED_TUTORS_COUNT) : tutorsWithLinks
  const totalMatchedTutors = count //  > RECOMMENDED_TUTORS_COUNT ? count - RECOMMENDED_TUTORS_COUNT : count

  if (!shouldSplitResults) {
    return (
      <>
        <h4 className="mb-4">Sample of tutor profiles</h4>
        {tutorsWithLinks.map(tutor => (
          <TutorCard key={tutor.id} className="mb-3" tutor={tutor} options={options} />
        ))}
      </>
    )
  }

  return (
    <>
      {count === 0 && <p className="text-center m-0">Nothing found matching your criteria</p>}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="mb-1">{pluralize("tutors", totalMatchedTutors, true)} found</h6>
        <TutorsListSortDropdown value={sort} onChange={onSortChanged} />
      </div>
      {recommendedTutors.length > 0 && (
        <>
          <h4>Recommended Tutors</h4>
          <p className="mb-4">
            Based on experience and reviews, these are our top three tutor recommendations for your child’s needs.
          </p>
          {recommendedTutors.map(tutor => (
            <TutorCard key={tutor.id} className="mb-3" tutor={tutor} options={options} />
          ))}
        </>
      )}
      {matchedTutors.length > 0 && (
        <>
          <h4 className={cn(recommendedTutors.length > 0 && "mt-5 mb-4")}> Matching tutors</h4>
          <p className="mb-4">These highly qualified tutors are also a great fit.</p>
          {matchedTutors.map(tutor => (
            <TutorCard key={tutor.id} className="mb-3" tutor={tutor} options={options} />
          ))}
        </>
      )}
    </>
  )
}

export default TutorsList
