import { SendReportFieldNames } from "../fields"
import { LocationFieldNames } from "../../../fields/location"

export const FormFieldNames = Object.freeze({
  School: "school",
  Educatable: "educatable",
  Subject: "subject",
  StartOn: "start_on",
  EndOn: "end_on",
  ...LocationFieldNames,
  ScheduleNote: "note",
  MaxSessions: "max_sessions",
  Duration: "max_duration",
  Rate: "school_rate",
  ...SendReportFieldNames,
})

export const fieldViewProps = {
  ColProps: { xs: 24, lg: 16, className: "mb-0 mt-n1 px-0 form-input-align flex-column" },
  RowProps: { className: "px-2" },
  className: "mb-4",
}
