import React, { useState } from "react"
import { Button } from "react-bootstrap"
import TruncateMarkup from "react-truncate-markup"
import cn from "classnames"

const ExpandCollapse = ({
  className,
  previewLinesCount = 5,
  expandText = "Show more",
  collapseText = "Show less",
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)

  return (
    <div className={cn("expand-collapse", className)}>
      <TruncateMarkup
        lines={!isExpanded ? previewLinesCount : Infinity}
        ellipsis={
          <Button variant="link" className="d-block mt-2" onClick={() => setIsExpanded(true)}>
            {expandText}
          </Button>
        }
        onTruncate={setIsTruncated}
      >
        <div>
          {Array.isArray(children) ? (
            children.map((child, i) => <TruncateMarkup.Atom key={i}>{child}</TruncateMarkup.Atom>)
          ) : (
            <TruncateMarkup.Atom>{children}</TruncateMarkup.Atom>
          )}
        </div>
      </TruncateMarkup>
      {!isTruncated && isExpanded && (
        <Button variant="link" className="d-block mt-2" onClick={() => setIsExpanded(false)}>
          {collapseText}
        </Button>
      )}
    </div>
  )
}

export default ExpandCollapse
