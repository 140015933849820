import React, { useMemo } from "react"
import { Row } from "react-bootstrap"
import Chip from "../../../Chip"
import ProgramPicker from "../../../blocks/ProgramPicker"
import { getDomainsOptions } from "../../../../helpers/programsHierarchy"
import { getProgramsSummary } from "../../../../helpers/presentational"

const OTHER_PROGRAM_NAME = "Custom"

export const ProgramSelect = ({ opening, setOpening, options, disabled = false, completed = false }) => {
  const domains = useMemo(() => {
    if (!opening.grade) {
      return []
    }
    return getDomainsOptions(opening.grade, options.hierarchy) || []
  }, [options.hierarchy, opening.grade])

  const onChangeProgram = (defaultProgramId, defaultProgramName = "") => {
    setOpening({ defaultProgramId, defaultProgramName, custom_program: "" })
  }

  const clearProgram = () => {
    setOpening({ defaultProgramId: null, defaultProgramName: "", custom_program: "" })
  }

  const setOtherProgramName = ({ target: { value } }) => {
    setOpening({ custom_program: value })
  }

  return (
    <div className="session-configuration_row">
      <div className="session-configuration_control">
        <ProgramPicker
          program={null}
          className="dropdown-toggle-no-icon fullwidth"
          wrapperClassName="anl-subject"
          domains={domains}
          selected={
            completed ||
            !!opening.defaultProgramId ||
            (opening.defaultProgramName === OTHER_PROGRAM_NAME && opening.custom_program.length > 0)
          }
          disabled={disabled || !opening.grade}
          buttonText="Subject"
          onChange={(value, name) => onChangeProgram(value, name)}
        />
      </div>
      <div className="session-configuration_values">
        <Row className="px-2 align-items-center">
          {!!opening.grade && !!opening.defaultProgramId && (
            <Chip disabled={disabled} onClose={clearProgram}>
              {getProgramsSummary(opening.defaultProgramId, options.programs)}
            </Chip>
          )}
          {opening.defaultProgramName === OTHER_PROGRAM_NAME &&
            (completed ? (
              <span className="mb-2">{opening.custom_program}</span>
            ) : (
              <input
                type="text"
                className="form-control max-w-400-px mb-2"
                placeholder="Enter subject name"
                onChange={setOtherProgramName}
                value={opening.custom_program || ""}
              />
            ))}
        </Row>
      </div>
    </div>
  )
}
