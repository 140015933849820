import React from "react"
import cn from "classnames"
import pluralize from "pluralize"
import { Card, Badge } from "react-bootstrap"
import { isEmpty } from "lodash/fp"

import Avatar from "../../blocks/Avatar"
import Rating from "../../blocks/Rating"
import { getPrivateFullName, getInitials } from "../../../helpers/presentational"
import { formatMoney } from "../../../helpers/formatters"
import BackgroundCheckLabel from "./BackgroundCheckLabel"
import DegreesInfo from "./DegreesInfo"
import LearningDifferenceCertificates from "./LearningDifferenceCertificates"

const Wrapper = ({ isLink, href, children }) =>
  isLink ? (
    <a href={href} className="tutor-card anl-btn -link" target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <div className="tutor-card">{children}</div>
  )

const TutorCard = ({ tutor, className, isLink = true }) => {
  const name = getPrivateFullName(tutor)
  const initials = getInitials(tutor)
  const { profile, hourly_rate, rating, labels } = tutor

  return (
    <Wrapper isLink={isLink} href={tutor.profile_link}>
      <Card className={cn("tutor-card_card", "card-outline", className)} body>
        <div className="tutor-card_content">
          <div className="tutor-card_avatar">
            <Avatar initials={initials} photoUrl={profile.photo_url} />
          </div>
          <div className="tutor-card_main-info">
            <div className="tutor-card_title">
              <h5 className="tutor-card_name">{name}</h5>
              <BackgroundCheckLabel />
            </div>

            <div className="tutor-card_degrees">
              <DegreesInfo degrees={profile.degrees} />
            </div>

            <div className="tutor-card_rate-info d-lg-none">
              <div className="tutor-card_rating">
                <Rating value={rating} showAllStars={false} readonly />
                {!!rating && <span className="tutor-card_rating-text"> rating</span>}
              </div>
              {tutor.reviews_on_me_count > 0 && (
                <div className="tutor-card_reviews">{pluralize("review", tutor.reviews_on_me_count, true)}</div>
              )}
              <div className="tutor-card_hour-rate">{formatMoney(hourly_rate)} / hour</div>
            </div>

            <div className="tutor-card_bio">{profile.bio}</div>
            {!isEmpty(labels) && (
              <div className="tutor-card_badges">
                {labels.map((label, i) => (
                  <Badge key={i} variant="success" className="tutor-card_badge default-badge">
                    {label[0]}
                  </Badge>
                ))}
              </div>
            )}
            <div className="tutor-card_certificates">
              <LearningDifferenceCertificates {...tutor.profile} />
            </div>
          </div>
          <div className="tutor-card_rate-card">
            <div className="tutor-card_hour-rate">{formatMoney(hourly_rate)} / hour</div>
            <div className="tutor-card_rating">
              <Rating value={rating} readonly />
            </div>
            {tutor.reviews_on_me_count > 0 && (
              <div className="tutor-card_reviews">{pluralize("review", tutor.reviews_on_me_count, true)}</div>
            )}
          </div>
        </div>
      </Card>
    </Wrapper>
  )
}

export default TutorCard
