import "bootstrap-datepicker"
import "../components/bootstrap-timepicker"
import moment from "moment"

document.addEventListener("turbolinks:load", () => {
  const dateInputs = $('[type="date"]')

  dateInputs.each((idx, item) => {
    $(item).addClass("input-datepicker").attr("type", "text")
    $(item).datepicker({
      autoclose: true,
      format: {
        toDisplay: date => {
          return moment.utc(date).format("MM/DD/YYYY")
        },
        toValue: date => {
          return new Date(date)
        },
      },
    })
    $(item).datepicker("update", item.value)
  })
  $('[type="time"]').each((idx, item) => {
    $(item).addClass("input-datepicker").attr("type", "text")
    $(item).timepicker({
      template: "dropdown",
    })
  })

  dateInputs.each((idx, item) => {
    const toVal = () => {
      return item.value ? hiddenInput.val(moment(new Date(item.value)).format("YYYY-MM-DD")) : ""
    }
    const hiddenInput = $(item).clone().attr("type", "hidden")

    $(item).after(hiddenInput).removeAttr("name").change(toVal)
    toVal()
  })
})
