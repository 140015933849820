import React from "react"

export const TextCell = ({ value, last, Link, empty = "-", style, className, handlers }) => {
  const align = last ? "right" : "left"
  return (
    <td scope="row" align={align} style={style} className={className} {...handlers}>
      {value ? Link ? <Link>{value}</Link> : value : empty}
    </td>
  )
}
