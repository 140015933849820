import React, { useState, useRef } from "react"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { Nav, Button } from "react-bootstrap"
import { isEmpty, findIndex, pickBy, compose, isUndefined } from "lodash/fp"
import ConfigurationStep from "./ConfigurationStep"
import ScheduleStep from "./ScheduleStep"
import OverviewStep from "./OverviewStep"
import { setFieldData, clearFieldData, clearFieldsData } from "../../helpers/forms"
import {
  chain,
  required,
  validateZip,
  isValidOffline,
  validateSchedule,
  afterDate,
  notEmptyArray,
} from "../../helpers/validators"
import PaymentCard from "./PaymentCard"
import { getFullName } from "../../helpers/presentational"

const hasErrors = validationResult => !compose(isEmpty, pickBy(Boolean))(validationResult)

const validateConfigurationStep = values => ({
  student_id: required(values.student_id),
  program_ids: required(values.program_ids) || notEmptyArray(values.program_ids),
  online: !isUndefined(values.online) && isValidOffline(values) ? undefined : required(values.online),
  address_1: !values.online ? required(values.address_1) : undefined,
  city: !values.online ? required(values.city) : undefined,
  state: !values.online ? required(values.state) : undefined,
  zip: !values.online ? required(values.zip) || validateZip(values.zip) : undefined,
})

const validateWeeklySchedule = values => ({
  started_at: required(values.started_at),
  timetable: validateSchedule(values.timetable),
  ended_at: chain(required, afterDate(values.started_at))(values.ended_at),
})

const validateNotRecurrenceSchedule = values => ({
  started_at: required(values.started_at),
  duration: required(values.duration),
})

const validateScheduleStep = values => ({
  ...validateConfigurationStep(values),
  recurrence: isUndefined(values.recurrence) ? "Required" : undefined,
  ...(values.recurrence === true ? validateWeeklySchedule(values) : {}),
  ...(values.recurrence === false ? validateNotRecurrenceSchedule(values) : {}),
})

const validateOverviewStep = values => ({
  ...validateScheduleStep(values),
})

const steps = [
  {
    key: "configuration",
    title: "Details",
    component: ConfigurationStep,
    validate: validateConfigurationStep,
  },
  {
    key: "schedule",
    title: "Schedule",
    component: ScheduleStep,
    validate: validateScheduleStep,
  },
  {
    key: "overview",
    title: "Overview",
    component: OverviewStep,
    validate: validateOverviewStep,
  },
]

const RequestWizard = ({
  initialValues = {},
  editMode,
  isParent,
  parent,
  tutor,
  options,
  urls,
  hourRate,
  timeZone,
  paymentData = {},
  onSubmit,
}) => {
  const [step, setStep] = useState(0)

  const cardFormRef = useRef()
  const cardSubmitButtonRef = useRef()
  const [isPendingCardTokenRequest, setIsPendingCardTokenRequest] = useState(false)

  const onSelectTab = key => {
    setStep(findIndex(["key", key])(steps))
  }

  const next = () => {
    setStep(prevStep => Math.min(prevStep + 1, steps.length - 1))
  }

  const validate = values => {
    const activeStep = steps[step]
    return activeStep.validate ? activeStep.validate(values) : {}
  }

  const handleSubmit = values => {
    if (cardFormRef.current && !values.cc_token) {
      if (cardFormRef.current.requestSubmit) {
        cardFormRef.current.requestSubmit()
      } else if (cardSubmitButtonRef.current) {
        cardSubmitButtonRef.current.click()
      }
    } else {
      return onSubmit(values)
    }
  }

  const activeStep = steps[step]
  const isLastStep = step === steps.length - 1
  const StepComponent = activeStep.component

  return (
    <Form
      initialValues={initialValues}
      mutators={{ ...arrayMutators, setFieldData, clearFieldData, clearFieldsData }}
      validate={validate}
      onSubmit={handleSubmit}
    >
      {({ values, submitting, handleSubmit }) => (
        <>
          <Nav activeKey={activeStep.key} className="py-2" onSelect={onSelectTab}>
            {steps.map(({ title, key }, i) => (
              <Nav.Item key={key}>
                <Nav.Link
                  eventKey={key}
                  className="px-4 py-2"
                  disabled={submitting || (i !== step && hasErrors(steps[i].validate(values)))}
                >
                  {title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <hr className="m-0" />
          <div className="p-4">
            <StepComponent
              editMode={editMode}
              isParent={isParent}
              urls={urls}
              parent={parent}
              tutor={tutor}
              options={options}
              hourRate={hourRate}
              timeZone={timeZone}
            />
            {isLastStep && editMode && (
              <PaymentCard
                {...paymentData}
                initialCardholderName={getFullName(parent).toUpperCase()}
                cardFormRef={cardFormRef}
                cardSubmitButtonRef={cardSubmitButtonRef}
                onRequestStart={() => setIsPendingCardTokenRequest(true)}
                onRequestEnd={() => setIsPendingCardTokenRequest(false)}
              />
            )}
          </div>
          {editMode && (
            <>
              <hr className="m-0" />
              <div className="p-4">
                {!isLastStep && (
                  <Button
                    variant="outline-primary"
                    className="anl-btn"
                    disabled={hasErrors(activeStep.validate(values))}
                    onClick={next}
                  >
                    Continue
                  </Button>
                )}
                {isLastStep && (
                  <Button
                    variant="outline-primary"
                    className="anl-btn-sessionreq"
                    disabled={submitting || isPendingCardTokenRequest}
                    onClick={handleSubmit}
                  >
                    {values.id ? "Edit session request" : "Send session request"}
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </Form>
  )
}

export default RequestWizard
