import React from "react"
import { Container } from "react-bootstrap"

import { ScheduleIcon } from "@/assets/images"
import { NO_SESSIONS_TEXT } from "../constants"

export const NoSession = ({ message }) => {
  return (
    <Container fluid className="no-lesson-content">
      <ScheduleIcon className="no-lesson-icon" />
      <div className="text-center">{message ? message : NO_SESSIONS_TEXT }</div>
    </Container>
  )
}
