import { RequestsParamsCacheContext } from "@/contexts"
import { reducer } from "@/store/dataReducer"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useMemo, useReducer } from "react"

export const RequestsParamsCacheProvider = ({ children, connectToParent = false }) => {
  const { setCache: setParentCache, getCache: getParentCache } = useContext(RequestsParamsCacheContext)
  const [state, dispatch] = useReducer(reducer, {})

  const setCache = useCallback(
    (name, data) => {
      if (connectToParent) {
        setParentCache(name, data)
      } else {
        dispatch({ name, data })
      }
    },
    [connectToParent, setParentCache]
  )

  const getCache = useCallback(
    name => {
      if (connectToParent) {
        return getParentCache(name)
      } else {
        return state[name]
      }
    },
    [connectToParent, getParentCache, state]
  )

  const contextValue = useMemo(() => ({ setCache, getCache }), [setCache, getCache])

  return <RequestsParamsCacheContext.Provider value={contextValue}>{children}</RequestsParamsCacheContext.Provider>
}

RequestsParamsCacheProvider.propTypes = {
  children: PropTypes.node.isRequired,
  connectToParent: PropTypes.bool,
}

RequestsParamsCacheProvider.defaultProps = {
  connectToParent: false,
}
