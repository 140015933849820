import React, { useState } from "react"
import PropTypes from "prop-types"
import { renderToString } from "react-dom/server"
import moment from "moment"
import { nanoid } from "nanoid"

import FullCalendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import { INTERVIEW_CALENDAR_MAX_TIME, INTERVIEW_CALENDAR_MIN_TIME } from "../../../common/constants"

const HeaderCell = ({ date }) => (
  <div className="fc-header-text">
    <span className="fc-header-day-title">{moment(date).format("dddd")}</span>
  </div>
)

const columnHeaderHtml = date => renderToString(<HeaderCell date={date} />)

const dataToEvent = ({ day, from, to }) => {
  const [startHours, startMinutes] = from.split(":")
  let [endHours, endMinutes] = to === "00:00" ? ["24", "00"] : to.split(":")
  if (endHours === "24" && endMinutes !== "00") {
    endHours = "00"
  }
  return {
    id: nanoid(),
    start: moment().day(day).hours(startHours).minutes(startMinutes).seconds(0).milliseconds(0).toDate(),
    end: moment().day(day).hours(endHours).minutes(endMinutes).seconds(0).milliseconds(0).toDate(),
  }
}

const eventToData = ({ start, end }) => {
  let [endHours, endMinutes] = moment(end).format("kk:mm").split(":")
  if (endHours === "24" && endMinutes !== "00") {
    endHours = "00"
  }
  return {
    day: moment(start).format("dddd").toLowerCase(),
    from: moment(start).format("HH:mm"),
    to: `${endHours}:${endMinutes}`,
  }
}

const WorkingHoursCalendar = ({
  value,
  editMode = true,
  isParent,
  FullCalendarProps = {},
  scrollToTime = "9:00",
  onChange,
}) => {
  const [events, setEvents] = useState(value.map(dataToEvent))
  const [earliestTime] = useState(value.map(({ from }) => from).sort()[0] || scrollToTime)

  const isInsideOneDay = ({ start, end }) => {
    return moment(end).clone().subtract(1, "s").isSame(start, "day")
  }

  const onChangeEvents = newEvents => {
    setEvents(newEvents)
    if (onChange) {
      onChange(newEvents.map(eventToData))
    }
  }

  const addEvent = ({ start, end }) => {
    const newEvents = [...events, { start, end, id: Date.now().toString() }]
    onChangeEvents(newEvents)
  }

  const removeEvent = ({ event }) => {
    const newEvents = events.filter(({ id }) => id !== event.id)
    onChangeEvents(newEvents)
  }

  const updateEvent = ({ event: { id, start, end } }) => {
    const newEvents = events.map(e => (id === e.id ? { id, start, end } : e))
    onChangeEvents(newEvents)
  }

  const onEventClick = ({ event }) => {
    if (editMode) {
      removeEvent({ event })
    }
  }

  return (
    <div className="fc-working-hours fc-weekly">
      <FullCalendar
        defaultView="timeGridWeek"
        plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
        events={events}
        longPressDelay={250}
        scrollTime={earliestTime}
        minTime={isParent ? INTERVIEW_CALENDAR_MIN_TIME : "00:00:00"}
        maxTime={isParent ? INTERVIEW_CALENDAR_MAX_TIME : "24:00:00"}
        header={false}
        allDaySlot={false}
        eventOverlap={false}
        selectOverlap={false}
        slotEventOverlap={false}
        selectable={editMode}
        editable={editMode}
        eventTimeFormat={{ hour: "2-digit", minute: "2-digit", hour12: true }}
        columnHeaderHtml={columnHeaderHtml}
        selectAllow={isInsideOneDay}
        eventAllow={isInsideOneDay}
        select={addEvent}
        eventClick={onEventClick}
        eventDrop={updateEvent}
        eventResize={updateEvent}
        {...FullCalendarProps}
      />
    </div>
  )
}

WorkingHoursCalendar.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.oneOf(["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]),
      from: PropTypes.string,
      to: PropTypes.string,
    }).isRequired
  ),
  editMode: PropTypes.bool,
  FullCalendarProps: PropTypes.object,
  onChange: PropTypes.func,
}

export default WorkingHoursCalendar
