import apiClient from "@/common/apiClient"
import FormSelect from "@/components/blocks/FormSelect"
import { getMonths } from "@/helpers/dates"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import MobileDetect from "mobile-detect"
import { nanoid } from "nanoid"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

const PLUGINS = [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]

const formatEvents = calendar => {
  if (!calendar) return []
  const today = new Date().toISOString().split("T")[0]
  return Object.entries(calendar)
    .flatMap(([key, prop]) => {
      if (!prop?.sessions.length) return []
      return prop.sessions.map(session => ({
        id: nanoid(),
        start: new Date(session.time),
        title: session.students,
        url: session.online && session.meeting_info ? session.meeting_info : undefined,
        display: "list-item",
        className: key < today ? "school-admin-schedule-event-past" : "school-admin-schedule-event",
      }))
    })
    .filter(Boolean)
}

const useScheduleData = (month, setEvents) => {
  const getScheduleData = useCallback(async () => {
    const response = await apiClient.get(`/api/v2/school_admins/calendar/${month.name}`)
    if (response && response.data.calendar) {
      const schedules = formatEvents(response.data.calendar)
      setEvents(schedules)
    }
  }, [month, setEvents])

  useEffect(() => {
    getScheduleData()
  }, [getScheduleData])
}

export const InstructionalScheduleDashboard = ({ setSessionsDate }) => {
  const currentMonth = useMemo(() => getMonths()[new Date().getMonth()], [])
  const [events, setEvents] = useState([])
  const [month, setMonth] = useState(currentMonth)
  const calendarRef = useRef(null)

  const handleDateChange = useCallback(monthValue => {
    const selectedMonth = getMonths().find(m => m.label === monthValue)
    setMonth(selectedMonth)
  }, [])

  const handleDateClick = useCallback(
    info => {
      setSessionsDate(info.date)
    },
    [setSessionsDate]
  )

  const md = useMemo(() => new MobileDetect(window.navigator.userAgent), [])
  const monthsList = useMemo(() => getMonths().map(month => ({ label: month.label, value: month.name })), [])

  useScheduleData(month, setEvents)

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.render()
  }, [])

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.gotoDate(month.date)
  }, [month])

  return (
    <div className="school-admin-schedule-container">
      <div className="w-100 d-flex flex-grow-1 justify-content-between align-items-center mb-3">
        <h4 className="text-gray-700 mb-0">Instructional Schedule</h4>
        <FormSelect
          className="float-right w-25"
          options={monthsList}
          defaultValue={currentMonth.label}
          onChange={event => handleDateChange(event.target.value)}
          aria-label="Select Month"
        />
      </div>

      <FullCalendar
        ref={calendarRef}
        defaultView="dayGridMonth"
        plugins={PLUGINS}
        events={events}
        header={false}
        allDaySlot={false}
        weekends={false}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
          meridiem: "short",
        }}
        height="auto"
        aspectRatio={md.mobile() ? 0.5 : 1.35}
        dateClick={handleDateClick}
      />
    </div>
  )
}
