import React, { useEffect, useRef, useState } from "react"

export const AdditionalInformation = ({ opening, sliceAdditionalInformation }) => {
  const { note } = opening
  const [open, setOpen] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const informationContainerRef = useRef(null)
  const parentContainerRef = useRef(null)

  const updateOpen = () => setOpen(isOpen => !isOpen)

  useEffect(() => {
    if (!showButton || !informationContainerRef?.current || !parentContainerRef?.current) return

    const parentWidth = parentContainerRef.current.offsetWidth
    const childWidth = informationContainerRef?.current.offsetWidth

    if (childWidth <= parentWidth) setShowButton(false)
  }, [showButton])

  if (note.length === 0) return null

  return (
    <div>
      <div ref={parentContainerRef}>
        <p
          className={`mb-0 font-size-big dashboard-card__information ${
            sliceAdditionalInformation && showButton && !open ? " -sliced" : ""
          }`}
        >
          {note}
        </p>
        <p ref={informationContainerRef} className="mb-0 font-size-big dashboard-card__information -sliced -hidden">
          {note}
        </p>
      </div>

      {sliceAdditionalInformation && showButton && (
        <button
          className={`btn btn-link-primary btn-link arrow-down -after ${open ? "-open" : ""}`}
          onClick={updateOpen}
        >
          Show more
        </button>
      )}
    </div>
  )
}
