import { useState, useEffect, useCallback } from "react"
import { apiClient } from "../../../common/apiClient"
import Alerter from "../../../common/alerter"

export const useGerTutorDashboardMessageRequest = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState("")
  const request = useCallback(async () => {
    try {
      const { data: result } = await apiClient.get("/api/admin/notation/tutor_dashboard")
      setData(result.body || "")
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      Alerter.error(e?.message || "Internal server error")
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    request()
  }, [request])

  return { loading, data }
}

export const usePutTutorDashboardMessageRequest = () => {
  const [loading, setLoading] = useState(false)

  const request = async body => {
    try {
      setLoading(true)
      await apiClient.post("/api/admin/notation/create_or_update", { body })
      Alerter.success("Message for all tutors successfully saved")
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      Alerter.error(e?.message || "Internal server error")
    } finally {
      setLoading(false)
    }
  }

  return { loading, request }
}
