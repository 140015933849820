import Button from "@atlaskit/button"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { defaultTheme } from "react-select"
import AsyncSelect from "react-select/async"

import apiClient from "../common/apiClient"
import withAlerter from "../common/withAlerter"
import FormFieldErrorFeedback from "./FormFieldErrorFeedback"

const { colors } = defaultTheme

const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
}

const Menu = props => {
  const shadow = "hsla(218, 50%, 10%, 0.1)"
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  )
}
const Blanket = props => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
)

const Dropdown = ({ className, children, disabled, isOpen, target, onClose, validationClass }) => (
  <div
    style={{ position: "relative", minWidth: "10em", paddingRight: "1em !important" }}
    className={`form-control select ${validationClass || ""} ${disabled ? "disabled" : ""} ${className}`}
  >
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)

const Svg = p => <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg style={{ verticalAlign: "top" }}>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
)

const AsyncAutocompleteField = ({
  alerter,
  className,
  source,
  dynamicSource,
  placeholder,
  fieldName,
  selected,
  validationErrors,
  disabled,
  onSelect,
  formIdToAutoSubmit,
  hideError,
  isMulti,
  clearAfterSelect = false,
  externalParams,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(selected)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const promiseOptions = useCallback(
    query => {
      if (!query) {
        return Promise.resolve([])
      }

      if (typeof dynamicSource === "function") {
        return dynamicSource(query)
      } else if (source) {
        return apiClient
          .get(source, { params: { ...{ q: query }, ...externalParams } })
          .then(({ data: { items } }) => items)
          .catch(() => alerter.error("Autocomplete network/server error"))
      } else {
        throw "Autocomplete source must be defined"
      }
    },
    [externalParams, alerter, source, dynamicSource]
  )

  const toggleOpen = () => setIsOpen(!isOpen)
  const onSelectChange = newValue => {
    if (Array.isArray(value) && newValue === null) newValue = []
    // else if (newValue === null) return
    toggleOpen()
    setValue(newValue)

    if (typeof onSelect === "function") {
      onSelect(newValue)
    }

    if (clearAfterSelect) {
      setValue(null)
    }

    if (formIdToAutoSubmit) {
      const form = document.forms[formIdToAutoSubmit]
      const input = document.querySelector(`[name=${fieldName}]`)
      if (form && input) {
        if (newValue) {
          input.value = newValue.value
          form.submit()
        }
      }
    }
  }

  return (
    <>
      <Dropdown
        className={className}
        isOpen={isOpen}
        onClose={toggleOpen}
        disabled={disabled}
        validationClass={validationErrors ? "is-invalid" : ""}
        target={
          <>
            <Button
              onClick={() => !disabled && toggleOpen()}
              disabled={disabled}
              isSelected={isOpen}
              style={{
                background: "none",
                width: "100%",
                padding: 0,
                marginLeft: "-0.375rem",
                marginRight: "1rem",
                paddingRight: "1rem",
              }}
            >
              {isMulti
                ? (value && value.length > 0 && value.map(({ title }) => title).join(", ")) || placeholder
                : (value && value.title) || placeholder}
            </Button>
            {fieldName && isMulti && (
              <>
                {value &&
                  value.map(({ value }, idx) => (
                    <input key={idx} type="checkbox" checked hidden readOnly name={fieldName} value={value} />
                  ))}
              </>
            )}
            {fieldName && !isMulti && <input type="hidden" name={fieldName} value={(value && value.value) || ""} />}
          </>
        }
      >
        <AsyncSelect
          isMulti={isMulti}
          autoFocus
          components={{ DropdownIndicator, IndicatorSeparator: null }}
          hideSelectedOptions={false}
          isClearable
          menuIsOpen
          onChange={onSelectChange}
          styles={selectStyles}
          tabSelectsValue={false}
          name={formIdToAutoSubmit ? null : fieldName}
          cacheOptions
          noOptionsMessage={e => (e.inputValue ? "No options" : null)}
          defaultValue={value}
          defaultOptions
          loadOptions={promiseOptions}
          getOptionLabel={option => option.title}
        />
      </Dropdown>
      {!hideError && <FormFieldErrorFeedback message={validationErrors} />}
    </>
  )
}

AsyncAutocompleteField.propTypes = {
  source: PropTypes.string,
  dynamicSource: PropTypes.func,
  placeholder: PropTypes.string,
  fieldName: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  externalParams: PropTypes.object,
  validationErrors: PropTypes.string,
}

export default withAlerter(AsyncAutocompleteField)
