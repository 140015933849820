import { useCallback, useEffect, useState } from "react"
import apiClient from "@/common/apiClient"

export const useFetchScheduleProgram = ({ scheduleId }) => {
  const [program, setProgram] = useState({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const fetchScheduleProgram = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      if (scheduleId) {
        const response = await apiClient.get(`/api/v2/schedules/${scheduleId}/program`)
        setProgram(response?.data?.program || null)
      }
    } catch (e) {
      setError(e.message)
    } finally {
      setLoading(false)
    }
  }, [scheduleId])

  useEffect(() => {
    fetchScheduleProgram()
  }, [fetchScheduleProgram])

  const programLevels = program?.program_levels || []
  const preAssessments = program?.pre_assessments || []
  const isCurriculumProgram = program?.is_curriculum_program || false;

  return { program, programLevels, preAssessments, isCurriculumProgram, loading, error }
}

export default useFetchScheduleProgram
