import React from "react"
import { PhoneLink } from "../link"

export const PhoneCell = ({ value, last, empty = "-", style, className, handlers }) => {
  const align = last ? "right" : "left"
  return (
    <td scope="row" align={align} style={style} className={className} {...handlers}>
      {value ? <PhoneLink value={value} /> : empty}
    </td>
  )
}
