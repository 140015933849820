import React from "react"
import { ReactComponent as AddPersonIcon } from "../../../../assets/images/person-add.svg"

export const MatchButton = ({ onClick }) => {
  return (
    <button
      className="btn btn-link font-weight-medium font-size-small text-capitalize d-flex align-items-center gap-1 border-0"
      onClick={onClick}
    >
      <div className="opening-students__icon-box d-flex align-items-center justify-content-center">
        <AddPersonIcon />
      </div>
      Match with group
    </button>
  )
}
