import React, { useState, useRef, useEffect } from "react"
import moment from "moment"
import { Col, Row, Form as BForm } from "react-bootstrap"
import { Form, Field, useFormState } from "react-final-form"
import { forEach } from "lodash"
import DatePicker from "react-datepicker"
import { setFieldData, clearFieldData } from "../../../../helpers/forms"
import { formatDateTime, parseDate, formatStartOfDayKeepZone } from "../../../../helpers/dates"
import { WEEKDAYS } from "../../../../common/constants"
import ScheduleCalendar from "../../../schedule_session/ScheduleCalendar"
import Condition from "../../../Condition"
import DurationField from "../../../blocks/DurationField"
import WeeklyScheduleControl from "../../../schedule_session/WeeklyScheduleControl"
import { valueOrNull } from "../../../../helpers/formatters"

const FieldNames = Object.freeze({
  Recurrence: "recurrence",
  StartedAt: "started_at",
  EndedAt: "ended_at",
  Duration: "duration",
  Schedule: "schedule",
  IsScheduleFlexible: "schedule_flexible",
  Preference: "schedule_preference",
})

const repetitionRadioList = [
  { label: "Does not repeat", value: false },
  { label: "Weekly", value: true },
]

const scheduleRadioList = [
  { label: "Set dates", value: false },
  { label: "Flexible", value: true },
]

export const getParentSessionMinTime = value =>
  moment(value || new Date())
    .hour(7)
    .minute(0)
    .toDate()

export const getParentSessionMaxTime = value =>
  moment(value || new Date())
    .hour(22)
    .minute(0)
    .toDate()

const openingToEvents = opening => {
  if (!opening.recurrence && opening.started_at && opening.duration) {
    return [
      {
        start: formatDateTime(opening.started_at),
        end: formatDateTime(moment(opening.started_at).add(opening.duration, "m")),
        title: "Session",
      },
    ]
  } else if (opening.recurrence && opening.schedule) {
    if (!opening.started_at) return []
    const events = []
    const fromDate = moment(opening.started_at).startOf("week").startOf("day")
    const startedAt = new Date(opening.started_at)
    const endedAt = opening.ended_at ? new Date(opening.ended_at) : null
    // const today = moment().hour(0).minute(0).millisecond(0)
    forEach(WEEKDAYS, (wd, i) => {
      const sd = opening.schedule[wd.value]
      if (sd && sd.time && sd.duration) {
        const [h, m] = sd.time.split(":")
        const start = fromDate.clone().add(i, "d").hours(h).minutes(m).toDate()
        const end = moment(start).clone().add(sd.duration, "m").toDate()
        let canAdd = true
        // if (start < today || end < today) canAdd = false
        // else if (startedAt && (start < startedAt || end < startedAt)) canAdd = false
        // else if (endedAt && (start > endedAt || end > endedAt)) canAdd = false
        if (!endedAt) canAdd = false
        if (endedAt && startedAt > endedAt) canAdd = false
        if (canAdd) events.push({ start, end, title: "Session" })
      }
    })
    return events
  }

  return []
}

const DateAndTime = ({ label, name, disabled, showHours, formatValue = value => value, children }) => (
  <BForm.Row>
    <BForm.Label column className="col-lg-6">
      {label}
    </BForm.Label>
    <BForm.Group as={Col} lg={5} className="d-flex flex-column">
      <Field name={name}>
        {({ input }) => (
          <DatePicker
            placeholderText="Date"
            wrapperClassName="fullwidth"
            selected={parseDate(formatValue(input.value))}
            disabled={disabled}
            onChange={date => input.onChange(formatValue(date))}
            // minDate={new Date()} // Temp changes
            dateFormat="MM/dd/yyyy"
            customInput={<BForm.Control />}
          />
        )}
      </Field>
    </BForm.Group>
    {showHours && (
      <BForm.Group as={Col} lg={4} className="-hide-disabled-times">
        <Field name={name}>
          {({ input }) => (
            <DatePicker
              placeholderText="Time"
              wrapperClassName="fullwidth"
              selected={parseDate(formatValue(input.value))}
              disabled={disabled}
              onChange={date => input.onChange(formatValue(date))}
              dateFormat="h:mm aa"
              customInput={<BForm.Control />}
              showTimeSelect
              showTimeSelectOnly
              minTime={getParentSessionMinTime(input.value)}
              maxTime={getParentSessionMaxTime(input.value)}
              timeIntervals={15}
              timeCaption="Time"
            />
          )}
        </Field>
      </BForm.Group>
    )}
    {children}
  </BForm.Row>
)

const Duration = ({ disabled }) => (
  <BForm.Row>
    <BForm.Label column className="col-lg-6">
      Duration
    </BForm.Label>
    <BForm.Group as={Col} lg={5}>
      <Field name={FieldNames.Duration} parse={valueOrNull}>
        {({ input }) => <DurationField {...input} disabled={disabled} placeholder="Pick duration" />}
      </Field>
    </BForm.Group>
  </BForm.Row>
)

const TimeZone = ({ value }) => <Col className="align-self-center mb-3">{value}</Col>

const RadioList = ({ name, label, list, disabled, initialValue, parser }) => (
  <BForm.Row>
    <BForm.Label column className="col-lg-6">
      {label}
    </BForm.Label>
    <BForm.Group
      as={Col}
      lg={18}
      className="d-flex align-items-start align-items-md-center mb-0 flex-column flex-md-row"
    >
      {list.map(({ label, value }, idx) => (
        <Field key={idx} name={name} initialValue={initialValue} type="radio" value={value} parse={parser}>
          {({ input }) => {
            const { name, ...exceptName } = input // eslint-disable-line no-unused-vars
            return (
              <BForm.Check
                {...exceptName}
                id={label.split(" ").join("").toLocaleLowerCase()}
                disabled={disabled}
                className="text-nowrap mr-5 col-md-7 col-lg-5"
                label={label}
                custom
              />
            )
          }}
        </Field>
      ))}
    </BForm.Group>
  </BForm.Row>
)

const SchedulePreference = ({ disabled }) => (
  <BForm.Row>
    <BForm.Label column className="col-lg-6">
      Schedule preference
    </BForm.Label>
    <BForm.Group
      as={Col}
      lg={18}
      className="d-flex align-items-start align-items-md-center mb-0 flex-column flex-md-row"
    >
      <Field name={FieldNames.Preference} parse={valueOrNull}>
        {({ input }) => <textarea {...input} disabled={disabled} className="form-control" rows={3} />}
      </Field>
    </BForm.Group>
  </BForm.Row>
)

const ScheduleFormFields = ({ timeZone, disabled, onChange, endDateRequired, enableScheduleType }) => {
  const changeHandler = useRef(() => {})
  useFormState({
    onChange: ({ values }) => {
      if (changeHandler.current) {
        return changeHandler.current(values || {})
      }
    },
  })

  useEffect(() => {
    changeHandler.current = onChange
    return () => (changeHandler.current = () => {})
  })

  return (
    <>
      {enableScheduleType && (
        <>
          <RadioList
            name={FieldNames.IsScheduleFlexible}
            label="Schedule"
            list={scheduleRadioList}
            initialValue={false}
            disabled={disabled}
            parser={value => value === "true"}
          />

          <hr className="mt-2 mb-3" />
        </>
      )}

      <Condition when={FieldNames.IsScheduleFlexible} is={false}>
        <RadioList
          name={FieldNames.Recurrence}
          label="Repetition"
          list={repetitionRadioList}
          initialValue={false}
          disabled={disabled}
          parser={value => value === "true"}
        />

        <hr className="mt-2 mb-3" />
      </Condition>

      <Condition when={FieldNames.IsScheduleFlexible} is={false}>
        <Condition when={FieldNames.Recurrence} is={true}>
          <WeeklyScheduleControl disabled={disabled} labelClass="col-lg-6" />
          <hr className="mt-0 mb-3" />
        </Condition>

        <Condition when={FieldNames.Recurrence} is={true}>
          <DateAndTime label="Start" name={FieldNames.StartedAt} disabled={disabled}>
            <TimeZone value={timeZone} />
          </DateAndTime>
        </Condition>

        <Condition when={FieldNames.Recurrence} is={false}>
          <DateAndTime label="Start" name={FieldNames.StartedAt} disabled={disabled} showHours>
            <TimeZone value={timeZone} />
          </DateAndTime>
        </Condition>

        <Condition when={FieldNames.Recurrence} is={true}>
          <DateAndTime label="End" name={FieldNames.EndedAt} disabled={disabled} formatValue={formatStartOfDayKeepZone}>
            {!endDateRequired && (
              <Col lg={8}>
                <small>It&apos;s good to plan until the end of the school year.</small>
                <br />
                <small>You can cancel later at any time.</small>
              </Col>
            )}
          </DateAndTime>
        </Condition>

        <Condition when={FieldNames.Recurrence} is={false}>
          <Duration disabled={disabled} />
        </Condition>
      </Condition>

      <Condition when={FieldNames.IsScheduleFlexible} is={true}>
        <DateAndTime
          label="Start"
          name={FieldNames.StartedAt}
          disabled={disabled}
          formatValue={formatStartOfDayKeepZone}
        >
          <TimeZone value={timeZone} />
        </DateAndTime>

        <DateAndTime
          label="End date"
          name={FieldNames.EndedAt}
          disabled={disabled}
          formatValue={formatStartOfDayKeepZone}
        />
        <Duration disabled={disabled} />
        <SchedulePreference disabled={disabled} />
      </Condition>
    </>
  )
}

const ScheduleForm = ({ initialValues, options, onSubmit, enableScheduleType }) => {
  return (
    <Form initialValues={initialValues} mutators={{ setFieldData, clearFieldData }} onSubmit={onSubmit}>
      {({ form }) => {
        return (
          <ScheduleFormFields
            timeZone={options.timeZone}
            onChange={() => form.submit()}
            endDateRequired={true}
            enableScheduleType={enableScheduleType}
          />
        )
      }}
    </Form>
  )
}

export const Schedule = ({ opening, setOpening, options, enableScheduleType }) => {
  const [displayedWeek, setDisplayedWeek] = useState(opening.started_at)
  const initValues = useRef({
    [FieldNames.Recurrence]: opening.recurrence,
    [FieldNames.StartedAt]: opening.started_at,
    [FieldNames.EndedAt]: opening.ended_at,
    [FieldNames.Duration]: opening.duration ? parseInt(opening.duration, 10) : null,
    [FieldNames.Schedule]: opening.schedule || {},
    [FieldNames.IsScheduleFlexible]: opening.schedule_flexible,
    [FieldNames.Preference]: opening.schedule_preference,
  })

  const showNextWeek = () => {
    const date = moment.parseZone(displayedWeek).add(1, "week").toDate()
    setDisplayedWeek(date)
  }
  const showPreviousWeek = () => {
    const date = moment.parseZone(displayedWeek).subtract(1, "week").toDate()
    setDisplayedWeek(date)
  }
  const showCurrentWeek = () => {
    setDisplayedWeek(opening.started_at)
  }

  const onScheduleChange = values => {
    setOpening({
      recurrence: values[FieldNames.Recurrence],
      started_at: values[FieldNames.StartedAt],
      ended_at: values[FieldNames.EndedAt],
      duration: values[FieldNames.Duration],
      schedule: values[FieldNames.Schedule] || {},
      schedule_flexible: values[FieldNames.IsScheduleFlexible],
      schedule_preference: values[FieldNames.Preference],
    })
  }

  const events = openingToEvents(opening, displayedWeek)

  return (
    <div className="p-4">
      <Row className="mb-3">
        <Col lg={24}>
          <ScheduleForm
            initialValues={initValues.current}
            options={options}
            onSubmit={onScheduleChange}
            enableScheduleType={enableScheduleType}
          />
        </Col>
      </Row>

      {!opening.schedule_flexible && (
        <>
          <Row className="mb-3">
            <h6 className="mt-3 flex-grow-1">Session schedule preview</h6>
            <Row>
              <div style={{ display: "flex" }}>
                <button className="calendar-left-arrow mr-2" onClick={showPreviousWeek} />
                <button className="calendar-right-arrow mr-2" onClick={showNextWeek} />
              </div>
              <button className="btn btn-warning-light btn-sm" onClick={showCurrentWeek}>
                Current
              </button>
            </Row>
          </Row>

          <ScheduleCalendar startedAt={displayedWeek} sessions={events} isParent={true} availableIntervals={[]} />
        </>
      )}
    </div>
  )
}
