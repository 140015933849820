import React from "react"
import { Button, Card } from "react-bootstrap"
import pluralize from "pluralize"
import cn from "classnames"

import { getPrivateFullName, getFullName } from "../../../helpers/presentational"
import InterviewSlot from "../schedule_interview/InterviewSlot"
import { formatDateKeepZone } from "../../../helpers/dates"
import MessageButton from "../../chat/MessageButton"
import { TIME_SUGGESTED_INTERVIEW, REQUESTED_INTERVIEW } from "../../../common/constants"

const TutorRequestedInterviewCard = ({
  interview,
  focused,
  saveMessageUrl,
  enableMessageButtonUrl,
  onConfirm,
  onDecline,
}) => {
  const parentName = getPrivateFullName(interview.parent)

  return (
    <Card className={cn("card-outline mb-4", focused && "focused")} id={interview.id} body>
      <div className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
        <h5 className="mb-2">{parentName} requested a video interview.</h5>
        {interview.created_at && (
          <div className="text-muted mb-2">Requested {formatDateKeepZone(interview.created_at, "MM/DD/YYYY")}</div>
        )}
      </div>

      {interview.status === TIME_SUGGESTED_INTERVIEW && (
        <div className="font-weight-medium text-warning mb-2">
          You suggested additional times to connect and the parent will confirm.
        </div>
      )}

      {interview.start_at_slots.length === 1 && interview.status === REQUESTED_INTERVIEW && (
        <InterviewSlot start={interview.start_at_slots[0]} />
      )}
      {interview.start_at_slots.length > 1 && interview.status === REQUESTED_INTERVIEW && (
        <h6 className="font-weight-normal">{pluralize("time", interview.start_at_slots.length, true)} suggested</h6>
      )}

      {interview.additional_notes && <div>Interview notes: {interview.additional_notes}</div>}

      <div className="d-flex flex-column flex-md-row mt-4">
        <Button variant="outline-primary" className="btn-sm mr-md-3 mb-3 mb-md-0" onClick={onConfirm}>
          {interview.status === TIME_SUGGESTED_INTERVIEW ? "Edit details" : "Check details"}
        </Button>

        <MessageButton
          buttonText="Message parent"
          className="btn-sm mr-md-4 mb-3 mb-md-0"
          dataSources={{
            urls: { save_message: saveMessageUrl, enable_message_button: enableMessageButtonUrl },
            from: { id: interview.tutor.id, name: getFullName(interview.tutor), email: interview.tutor.email },
            recipient: {
              id: interview.parent.id,
              name: getPrivateFullName(interview.parent),
              averageResponseTime: "unknown",
            },
            subject: "",
            changeSubject: true,
          }}
        />

        <Button variant="link" className="link-error anl-btn" onClick={onDecline}>
          Decline interview
        </Button>
      </div>
    </Card>
  )
}

export default TutorRequestedInterviewCard
