import React, { useContext } from "react"
import { TextRow } from "./text_row"
import { Link, generatePath } from "react-router-dom"
import { RoutesPatterns } from "../../configurations/routing"
import { DataContext } from "../../../../contexts"
import {
  dateRangeMapper,
  durationMapper,
  noShowsMapper,
  rateMapper,
  statusMapper,
  locationMapper,
  tutorPositionMapper,
  tutoringTypesMapper,
  learningLevelMapper,
} from "./text_mappers"

const OpeningInformationContent = () => {
  const { opening } = useContext(DataContext)

  return (
    <div className="card p-4 d-flex flex-column gap-1">
      <div className="d-flex align-items-baseline justify-content-between mb-2">
        <h6 className="font-weight-semibold font-size-medium mb-0">Opening settings</h6>
        <Link as="button" className="btn btn-link" to={generatePath(RoutesPatterns.Edit, { openingId: opening.id })}>
          Edit
        </Link>
      </div>
      <TextRow label="School" value={opening.school.name} />
      <TextRow label="Learning level" value={learningLevelMapper(opening.learning_level)} />
      <TextRow label="Subject" value={opening.program.name} />
      <TextRow label="Tutoring type" value={tutoringTypesMapper(opening.tutoring_type)} />
      <TextRow label="Note for tutors" value={opening.note} />
      <TextRow label="Internal note" value={opening.internal_note} />
      <TextRow label="School rate" value={rateMapper(opening.school_rate)} />
      {/*<TextRow label="Tutor binding statement" value={opening.tutor_statement} />*/}
      <h6 className="font-weight-semibold font-size-medium my-2">Default Connection Settings</h6>
      <TextRow label="Tutoring position" value={tutorPositionMapper(opening.position_level)} />
      <TextRow label="Tutoring dates" value={dateRangeMapper(opening.start_on, opening.end_on)} />
      <TextRow label="Tutor rate" value={rateMapper(opening.tutor_rate)} />
      <TextRow label="Paid no-shows" value={noShowsMapper(opening.max_number_of_noshows)} />
      <h6 className="font-weight-semibold font-size-medium my-2">Default Schedule settings</h6>
      <TextRow label="Location" value={locationMapper(opening.location)} />
      <TextRow label="Report cadence" value="Weekly" />
      <TextRow label="Max session duration" value={durationMapper(opening.max_session_duration)} />
      <TextRow label="Max sessions" value={opening.max_number_of_sessions ?? "—"} />
      <TextRow label="Status" value={statusMapper(opening.status)} />
    </div>
  )
}

export default OpeningInformationContent
