import React, { useContext, useMemo } from "react"
import { Table, Card, Row } from "react-bootstrap"
import { OpeningContext } from "./context"
import { remove } from "lodash"

const TutorInfo = ({ tutorName }) => {
  return (
    <Row>
      <b className="opening_tutor_status -approved mr-2">Tutor: {tutorName}</b>
      <b className="opening_session">Session created</b>
    </Row>
  )
}

const Group = ({ group }) => {
  const { opening, setOpening, tutorApplications } = useContext(OpeningContext)

  const onRemoveGroup = () => {
    const changedOpening = { ...opening }
    remove(changedOpening.groupIds, id => id === group.id)
    setOpening(changedOpening)
  }

  const tutor = useMemo(
    () => tutorApplications.find(({ group: g }) => g && group && g.id === group?.id)?.tutor,
    [group, tutorApplications]
  )

  return (
    <Card className="mb-3 flat sm col-24">
      <Card.Header>
        <Row>
          <b className="flex-grow-1">{group.name}</b>
          {tutor ? (
            <TutorInfo tutorName={tutor.name} />
          ) : (
            <button className="float-right btn btn-sm btn-link btn-link-primary" onClick={onRemoveGroup}>
              Remove
            </button>
          )}
        </Row>
      </Card.Header>
      <Card.Body>
        <Table className="grid-table" size="sm">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Grade</th>
              <th>Learning difference</th>
              <th>Parent email</th>
            </tr>
          </thead>
          <tbody>
            {group.students.map(ogs => (
              <tr key={ogs.id}>
                <td>{ogs.first_name}</td>
                <td>{ogs.last_name}</td>
                <td>{ogs.grade}</td>
                <td>{ogs.special_treatments}</td>
                <td>{ogs.parent_email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default Group
