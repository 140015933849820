import React, { useMemo, useCallback } from "react"
import { useForm, useField } from "react-final-form"
import Chip from "./Chip"
import FormRow from "./blocks/FormRow"

const SelectWithChips = ({
  name,
  label,
  disabled,
  onChange,
  loading,
  list,
  className,
  placeholder,
  LabelProps,
  ColProps,
  required,
}) => {
  const form = useForm()
  const field = useField(name)
  const fieldValue = useMemo(() => field.input.value, [field.input.value])

  const selected = useMemo(() => list.filter(([, id]) => fieldValue.indexOf(id) !== -1), [fieldValue, list])

  const elements = useMemo(() => list.filter(([, id]) => !selected.some(([, el]) => el === id)), [selected, list])

  const add = useCallback(
    event => {
      form.mutators.setFieldTouched(name, true)
      const {
        target: { value },
      } = event
      if (!value) return
      const selectedList = [...selected]
      const item = list.find(([, id]) => id === parseInt(value, 10))
      selectedList.push(item)
      const ids = selectedList.map(([, id]) => id)
      field.input.onChange({ ...event, target: { value: ids, ...event.target } })
      if (onChange) onChange(selectedList)
    },
    [field.input, form.mutators, list, name, onChange, selected]
  )

  const remove = useCallback(
    id => {
      const selectedList = selected.filter(([, el]) => el !== id)
      const ids = selectedList.map(([, id]) => id)
      form.mutators.setFieldData(name, ids)
      form.focus(name)
      if (onChange) onChange(selectedList)
    },
    [selected, form, name, onChange]
  )

  const cn = `form-control ${field.meta.touched && field.meta.error ? "error" : ""} ${
    className ? className : ""
  }`.trim()

  return (
    <>
      <FormRow LabelProps={{ ...LabelProps, htmlFor: name }} ColProps={ColProps} label={label} required={required}>
        {loading ? (
          <div className="spinner-wrapper">
            <div className="spinner-border text-primary" />
          </div>
        ) : (
          <select className={cn} disabled={disabled} onChange={add} value="" name={name} id={name}>
            <option value="">{placeholder}</option>
            {elements.map(([title, value]) => (
              <option key={value} value={value}>
                {title}
              </option>
            ))}
          </select>
        )}
        <div className="row mx-0 my-3">
          {selected.map(([label, id]) => (
            <Chip key={id} onClose={() => remove(id)}>
              {label}
            </Chip>
          ))}
        </div>
      </FormRow>
    </>
  )
}

export default SelectWithChips
