import React, { useContext, useMemo } from "react"
import { className } from "../../helpers/className"
import { InitialLoadingContext } from "../../contexts"
import useViewport from "../../hooks/useViewport"

const InitialLoadingSpinner = ({ children, className: cn }) => {
  const { initialLoading } = useContext(InitialLoadingContext)

  const { isMobileViewport } = useViewport()

  const spinnerClassName = useMemo(
    () =>
      initialLoading
        ? className(
            cn,
            "d-flex",
            "align-items-center",
            "justify-content-center",
            isMobileViewport ? "min-h-200-px" : "min-h-600-px"
          )
        : "",
    [initialLoading, cn, isMobileViewport]
  )

  if (initialLoading) {
    return (
      <div className={spinnerClassName}>
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  return children
}

export default InitialLoadingSpinner
