import React from "react"
import { DomainProvider } from "../../../providers"
import { useApiInitialPath } from "../../../hooks/useApiPath"
import useBaseHandlersBuilder from "../../../hooks/useBaseHandlersBuilder"

export const ScheduleFormProvider = ({ children, configs }) => {
  return (
    <DomainProvider useApiPathBuilder={useApiInitialPath} useHandlersBuilder={useBaseHandlersBuilder} config={configs}>
      {children}
    </DomainProvider>
  )
}
