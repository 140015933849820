import React, { useMemo } from "react"
import { Modal } from "react-bootstrap"
import { find } from "lodash/fp"

import RequestWizard from "./RequestWizard"
import { serializeRequestOptions } from "../../helpers/serializers"
import { formatDateTimeKeepZone, formatStartOfDayKeepZone, formatEndOfDayKeepZone } from "../../helpers/dates"

const getInitialStudent = (requestData, parent) => {
  const getStudent = id => find(["id", id])(parent.students)
  if (requestData.student) {
    return getStudent(requestData.student.id)
  }
  if (requestData.student_id) {
    return getStudent(requestData.student_id)
  }
  if (parent.students.length > 0 && !requestData.student && !requestData.student_id) {
    return parent.students[0]
  }
  return null
}

const RequestModal = ({
  editMode = true,
  show,
  handleClose,
  dataSources,
  urls,
  timeZone,
  hourRate,
  paymentData,
  onSubmit,
}) => {
  const { parent, tutor, isParent } = dataSources

  const options = useMemo(() => serializeRequestOptions(dataSources), [dataSources])

  const initialValues = useMemo(() => {
    const requestData = dataSources.request || {}
    const initialStudent = getInitialStudent(requestData, parent)
    const defaultData = {
      timetable: {},
      grade: initialStudent && initialStudent.grade,
      special_treatments: initialStudent && initialStudent.special_treatments,
      address_1: parent.address_1,
      address_2: parent.address_2,
      city: parent.city,
      state: parent.state,
      zip: parent.zip,
    }
    const student_id = initialStudent && initialStudent.id
    const started_at = requestData.started_at ? formatDateTimeKeepZone(requestData.started_at) : null
    const ended_at = requestData.ended_at ? formatEndOfDayKeepZone(requestData.ended_at) : null

    return {
      ...defaultData,
      ...requestData,
      student_id,
      started_at,
      ended_at,
    }
  }, [parent, dataSources])

  const handleSubmit = async values => {
    if (onSubmit) {
      const serializedValues = {
        ...values,
        tutor_id: tutor.id,
        parent_cost: hourRate,
        started_at: values.recurrence
          ? formatStartOfDayKeepZone(values.started_at)
          : formatDateTimeKeepZone(values.started_at),
      }
      await onSubmit(serializedValues)
    }
  }

  return (
    <Modal show={show} size="xl" backdrop="static" onHide={handleClose}>
      <Modal.Header className="borderless px-4 pt-4 pb-2" closeButton>
        <Modal.Title>Request a tutoring session</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <RequestWizard
          editMode={editMode}
          isParent={isParent}
          initialValues={initialValues}
          options={options}
          parent={parent}
          tutor={tutor}
          urls={urls}
          paymentData={paymentData}
          timeZone={timeZone}
          hourRate={hourRate}
          onSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  )
}

export default RequestModal
