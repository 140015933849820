import React from "react"
import { kebabCase } from "lodash"
import { toggleId, elText } from "../../../helpers/profile_form"

const employmentText = employment => {
  if (!employment.is_current_employment) {
    return null
  }

  return (
    <>
      <br />
      {`\u2713`} Is my current employment
    </>
  )
}

const periodText = employment => {
  if (employment.is_current_employment) {
    return `from ${employment.from_text}`
  }
  return `${employment.from_text} - ${employment.to_text}`
}

const Employment = ({ employment, fields }) => {
  return (
    <>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">Dates</label>
        <div className="col-lg-16 form-control-plaintext">
          {periodText(employment)}
          {employmentText(employment)}
        </div>
        <div className="clearfix col-lg-2">
          <span
            className="cursor-pointer"
            onClick={() => {
              toggleId(fields, employment.id)
            }}
            data-test={`employment-${kebabCase(employment.position)}`}
          >
            {elText(employment, fields.value)}
          </span>
        </div>

        <label className="col-lg-6 col-form-label">School</label>
        <div className="col-lg-18 form-control-plaintext">{employment.school}</div>

        <label className="col-lg-6 col-form-label">Position</label>
        <div className="col-lg-18 form-control-plaintext">{employment.position}</div>
      </div>
    </>
  )
}

export default Employment
