import React, { useState } from "react"
import cn from "classnames"
import { useField, useFormState } from "react-final-form"
import { Dropdown, Button } from "react-bootstrap"

import SpecialTreatmentControl from "./SpecialTreatmentControl"

const SpecialTreatmentDropdown = ({ className, options, getButtonText, completed, disabled, onSubmit, ...props }) => {
  const [showPopper, setShowPopper] = useState(false)
  const { values } = useFormState()
  const { meta: specialTreatmentsMeta } = useField("special_treatments")
  const { meta: executiveFunctionMeta } = useField("executive_function")

  const onToggle = show => {
    if (show) {
      setShowPopper(true)
      return
    }
    setShowPopper(false)
    if (!specialTreatmentsMeta.pristine || !executiveFunctionMeta.pristine) {
      onSubmit && onSubmit()
    }
  }

  return (
    <Dropdown show={showPopper} onToggle={onToggle} className="anl-learning">
      <Dropdown.Toggle
        variant="outline-dropdown"
        disabled={disabled}
        className={cn(className, completed && "selected")}
      >
        {getButtonText(values, options)}
      </Dropdown.Toggle>

      <Dropdown.Menu className="p-4 -fullwidth">
        <SpecialTreatmentControl options={options} {...props} />

        <Button variant="outline-primary" size="sm" className="mt-4" type="button" onClick={() => onToggle(false)}>
          Ok
        </Button>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SpecialTreatmentDropdown
