import { Entities, Methods } from "../../../constants"
import {
  createSessionsWithAssistant,
  deleteSessionsWithAssistant,
  getSchedule,
  updateConnection,
  updateSchedule,
} from "../../schedule_module/configurations/domain"

// /api/openings/:id/applications
const openingsInitialParamsBuilder = getState => {
  const openingId = getState("openingId")
  return { entitiesIds: { [Entities.Openings]: openingId } }
}

const schoolSchedulesParamsBuilder = getState => {
  const opening = getState("opening")
  return { params: { school_id: opening.school.id } }
}

export const getOpening = {
  entities: [Entities.Openings],
  alias: "opening",
  initialParamsBuilder: openingsInitialParamsBuilder,
}

export const getOpeningApplications = {
  entities: [Entities.Openings],
  action: "applications",
  alias: "applications",
}

export const updateOpeningApplication = {
  entities: [Entities.Openings, Entities.Applications],
  method: Methods.Patch,
  skipStorage: true,
  next: [getOpeningApplications],
}

export const getOpeningStudents = {
  entities: [Entities.Openings],
  action: "schedules",
  alias: "groups",
}

export const getSchoolSchedules = {
  entities: [Entities.Educatables],
  alias: "schoolSchedules",
}

export const addScheduleToOpening = {
  entities: [Entities.Openings],
  action: "link_educatables",
  method: Methods.Patch,
  skipStorage: true,
}

export const openingsList = {
  entities: [Entities.Openings],
  alias: "openings",
  onStoreUpdate: (response, { updateState, getState }) => {
    const { data, meta } = response
    const {
      pagination: { total_results },
    } = meta
    const count = getState("openingsCount")
    if (total_results !== count) updateState("openingsCount", total_results)
    return data
  },
}

export const createOpening = {
  entities: [Entities.Openings],
  method: Methods.Post,
  skipStorage: true,
}

export const updateOpening = {
  entities: [Entities.Openings],
  method: Methods.Patch,
  skipStorage: true,
}

export const matchApplication = {
  entities: [Entities.Openings],
  action: "match",
  method: Methods.Post,
  skipStorage: true,
}

export const removeScheduleToOpening = {
  entities: [Entities.Openings],
  action: "unlink_educatables",
  method: Methods.Patch,
  skipStorage: true,
  next: [getOpeningStudents],
}

export const createEmptyGroup = {
  method: Methods.Post,
  entities: [Entities.Openings],
  action: "link_empty_groups",
  skipStorage: true,
}

export const opening = [getOpening, updateOpening]

export const tutors = [
  {
    ...getOpeningApplications,
    initialParamsBuilder: openingsInitialParamsBuilder,
  },
  updateOpeningApplication,
  matchApplication,
]

export const students = [
  {
    ...getOpeningStudents,
    initialParamsBuilder: openingsInitialParamsBuilder,
  },
  getSchoolSchedules,
  addScheduleToOpening,
  createSessionsWithAssistant,
  deleteSessionsWithAssistant,
  removeScheduleToOpening,
  updateConnection,
  createEmptyGroup,
]

export const tutorsDialog = [
  {
    ...getOpeningApplications,
    initialParamsBuilder: openingsInitialParamsBuilder,
  },
  updateOpeningApplication,
  {
    ...matchApplication,
    next: [getOpeningApplications],
  },
]

export const getUpdateScheduleDialog = preload => [
  preload ? getSchedule : { ...getSchedule, initialParamsBuilder: void 0 },
  updateSchedule,
]

export const updateOpeningGroupsDialog = [
  {
    ...getSchoolSchedules,
    initialParamsBuilder: schoolSchedulesParamsBuilder,
  },
  addScheduleToOpening,
]

export const studentsDialog = [
  {
    ...getOpeningStudents,
    initialParamsBuilder: openingsInitialParamsBuilder,
  },
  matchApplication,
]
