import React from "react"
import cn from "classnames"

import { ReactComponent as DyslexiaIcon } from "../../../assets/images/dyslexia.svg"
import { ReactComponent as DysgraphiaIcon } from "../../../assets/images/dysgraphia.svg"
import { ReactComponent as LanguageBasedLearningDisabilityIcon } from "../../../assets/images/learning-based-disability.svg"
import { ReactComponent as DyscalculiaIcon } from "../../../assets/images/dyscalculia.svg"
import { ReactComponent as AdhdIcon } from "../../../assets/images/adhd.svg"
import { ReactComponent as TeachingIcon } from "../../../assets/images/teaching-certificate.svg"
import CertificateInfo from "./CertificateInfo"

const certificateView = {
  dyslexia: {
    IconComponent: DyslexiaIcon,
    text: "Dyslexia expert",
  },
  dysgraphia: {
    IconComponent: DysgraphiaIcon,
    text: "Dysgraphia expert",
  },
  language_based_learning_disability: {
    IconComponent: LanguageBasedLearningDisabilityIcon,
    text: "Language based disability expert",
  },
  dyscalculia: {
    IconComponent: DyscalculiaIcon,
    text: "Dyscalculia expert",
  },
  adhd: {
    IconComponent: AdhdIcon,
    text: "ADHD expert",
  },
  teaching: {
    IconComponent: TeachingIcon,
    text: "Teaching certificate",
  },
}

const LearningDifferenceCertificates = ({ disabilities, certificates, gridClassName, certificateClassName }) => {
  const teachingCertificate = certificates.find(
    certificate => certificate.review_status === "verified" && certificate.training === "teaching_certificate"
  )

  const disabilityCertificates = disabilities.filter(disability => disability.review_status === "verified")

  return (
    <div className={cn("learning-difference-certificates", gridClassName)}>
      {disabilityCertificates.map(disability => (
        <div key={disability.id} className="learning-difference-certificates_item">
          <CertificateInfo {...certificateView[disability.name]} className={certificateClassName} />
        </div>
      ))}
      {teachingCertificate && (
        <div className="learning-difference-certificates_item">
          <CertificateInfo {...certificateView.teaching} className={certificateClassName} />
        </div>
      )}
    </div>
  )
}

export default LearningDifferenceCertificates
