import { useCallback, useEffect, useState } from "react"
import Alerter from "../../../../common/alerter"
import apiClient from "../../../../common/apiClient"

const useFetchClassroom = ({ studentId, scheduleId }) => {
  const [classroom, setClassroomData] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchClassroom = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await apiClient.get(`/api/v2/students/${studentId}/classroom/${scheduleId}`)
      setClassroomData(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Alerter.error(`Failed to fetch classroom data \n${error}`)
    }
  }, [studentId, scheduleId])

  useEffect(() => {
    fetchClassroom()
  }, [fetchClassroom])

  return { fetchClassroom, classroom, loading }
}

export default useFetchClassroom