import { isEmpty } from "lodash/fp"

import { isValidLocation } from "../../../helpers/validators"

import StudentsDropdown from "../../schedule_session/StudentsDropdown"
import GradeDropdown from "../GradeDropdown"
import MultipleProgramsDropdown from "../MultipleProgramsDropdown"
import StruggleWithDropdown from "../StruggleWithDropdown"
import SpecialTreatmentDropdown from "../SpecialTreatmentDropdown"
import LocationDropdown from "../LocationDropdown"
import AdditionalDropdown from "../AdditionalDropdown"
import ScheduleDropdown from "../ScheduleDropdown"
import SliderPriceDropdown from "../SliderPriceDropdown"
import WizardGradeStep from "../wizard/WizardGradeStep"
import WizardProgramStep from "../wizard/WizardProgramStep"
import WizardSpecialTreatmentStep from "../wizard/WizardSpecialTreatmentStep"
import {
  getLabelByValue,
  getProgramsSummary,
  getStruggleWithSummary,
  getLocationSummary,
  getSpecialTreatmentsSummary,
  getScheduleSummary,
  getPriceSummary,
} from "../../../helpers/presentational"

export default [
  // Student
  {
    id: "student",
    name: "Student",
    fieldsToReset: [],
    FilterComponent: StudentsDropdown,
    FilterComponentProps: {},
    WizardComponent: null,
    disabled: () => false,
    completed: () => false,
  },
  // Grade
  {
    id: "grade",
    name: "Grade",
    getButtonText: (value, options) => getLabelByValue(options.grades)(value) || "Pick a grade",
    fieldsToReset: ["program_ids", "struggle_with"],
    FilterComponent: GradeDropdown,
    FilterComponentProps: {},
    WizardComponent: WizardGradeStep,
    WizardComponentProps: {
      title: "What grade is your child in?",
    },
    disabled: () => false,
    completed: ({ grade }) => !!grade,
  },
  // Subject
  {
    id: "program_ids",
    name: "Subject",
    getButtonText: (value, options) => getProgramsSummary(value, options.programs) || "Pick a subject",
    fieldsToReset: ["struggle_with"],
    FilterComponent: MultipleProgramsDropdown,
    FilterComponentProps: {},
    WizardComponent: WizardProgramStep,
    WizardComponentProps: {
      title: "What does your child need support with?",
    },
    disabled: ({ grade }) => !grade,
    completed: ({ program_ids }) => !!program_ids && program_ids.length > 0,
  },
  // Struggles with
  {
    id: "struggle_with",
    name: "Struggles with",
    getButtonText: (value, options) => getStruggleWithSummary(value, options) || "Specific struggles",
    fieldsToReset: [],
    FilterComponent: StruggleWithDropdown,
    FilterComponentProps: { optionType: "checkbox" },
    WizardComponent: null,
    disabled: ({ grade, program_ids }) => !grade || !program_ids || !program_ids.length,
    completed: ({ struggle_with }) => !isEmpty(struggle_with),
  },
  // Learning differences (former Special treatments)
  {
    id: "special_treatments",
    name: "Learning difference",
    getButtonText: (values, options) =>
      getSpecialTreatmentsSummary(values, options) || "Diagnosed learning difference?",
    fieldsToReset: [],
    FilterComponent: SpecialTreatmentDropdown,
    FilterComponentProps: { optionType: "checkbox" },
    WizardComponent: WizardSpecialTreatmentStep,
    WizardComponentProps: {
      title: "Does your child have a learning difference?",
      nextButtonText: "Continue",
      skipButtonText: "No learning difference",
    },
    disabled: () => false,
    completed: ({ special_treatments, executive_function }) => !isEmpty(special_treatments) || executive_function,
  },
  // Additional
  {
    id: "additional",
    name: "Additional",
    getButtonText: () => "",
    fieldsToReset: [],
    FilterComponent: AdditionalDropdown,
    FilterComponentProps: {},
    WizardComponent: null,
    disabled: () => false,
    completed: ({ school, current_school }) => !!school || !!current_school,
  },
  // Location
  {
    id: "location",
    name: "Location",
    getButtonText: values => getLocationSummary(values) || "Location / Online",
    fieldsToReset: [],
    FilterComponent: LocationDropdown,
    FilterComponentProps: {
      submitButtonText: "Continue",
    },
    WizardComponent: null,

    disabled: values => !values.online && !isValidLocation(values),
    completed: ({ online, offline, zip }) => online || isValidLocation({ offline, zip }),
  },
  // Schedule
  {
    id: "schedule",
    name: "Schedule",
    getButtonText: values => getScheduleSummary(values) || "Pick days and time",
    fieldsToReset: [],
    FilterComponent: ScheduleDropdown,
    FilterComponentProps: {},
    WizardComponent: null,

    disabled: () => false,
    completed: ({ timetable }) => !isEmpty(timetable),
  },
  // Price
  {
    id: "price",
    name: "Price",
    getButtonText: value => getPriceSummary(value),
    fieldsToReset: [],
    FilterComponent: SliderPriceDropdown,
    FilterComponentProps: {},
    WizardComponent: null,
    disabled: () => false,
    completed: ({ max_price }) => !!max_price,
  },
]
