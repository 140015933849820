import React, { useMemo } from "react"
import cn from "classnames"
import { Form } from "react-bootstrap"
import { connect } from "react-redux"
import { groupBy, size, find, keys, map, values, flow, flatten, partialRight, filter } from "lodash"
import { LOADING, COPY_TO_ALL_STUDENTS } from "../../../session_report/store/constants"
import { MASTERED, WORKING_ON, STUDIED_EARLIER, EMPTY } from "../../../session_report/subskill_statuses"
import { ReactComponent as ReportOverdue } from "../../../assets/images/report-overdue.svg"

const Confirmation = ({
  selectedSubskills,
  copyToAllStudents,
  showCopyToRestStudents,
  canUpdate,
  list,
  loading,
  dispatch,
  programId,
  onCancel,
  onEdit,
  readOnlyMode,
}) => {
  const submitSkills = async () => {
    dispatch({ type: LOADING, payload: true })
    const subSkills = flow(
      values,
      flatten,
      partialRight(map, subskill => {
        return {
          subskill_id: subskill["id"],
          status: subskill["status"],
        }
      })
    )(selectedSubskills)

    const request = {
      copyToAllStudents,
      program_id: programId,
      kind: "skills",
      new_sub_skills: subSkills,
    }
    await onEdit(request)
    dispatch({ type: LOADING, payload: false })
    onCancel()
  }

  const updateCopyToAllStudents = ({ target }) => {
    dispatch({ type: COPY_TO_ALL_STUDENTS, payload: target.checked })
  }

  const hasChanges = useMemo(
    () =>
      selectedSubskills[MASTERED]?.length > 0 ||
      selectedSubskills[WORKING_ON]?.length > 0 ||
      selectedSubskills[STUDIED_EARLIER]?.length > 0,
    [selectedSubskills]
  )

  const renderList = (skillsList, className) => {
    const group = groupBy(skillsList, "skill_id")

    return (
      <>
        {map(keys(group), id => (
          <div key={id}>
            <div className="report_confirmation_subtitle">{find(list, { id: parseInt(id) }).name}</div>
            {group[id].map(subskill => (
              <div key={subskill.id} className={cn("skill", "color", className)} title={subskill.name}>
                {subskill.name}
              </div>
            ))}
          </div>
        ))}
      </>
    )
  }

  return (
    <div className="panel mb-0">
      <div className="panel_body -no-vertical-indent overflow-hidden">
        <div className="row">
          {selectedSubskills[MASTERED] && (
            <div className="report-confirmation col-sm-24 px-0">
              <div className="report_confirmation_title">Skills mastered in the session</div>
              {renderList(selectedSubskills[MASTERED], "-mastered")}
            </div>
          )}

          {selectedSubskills[WORKING_ON] && (
            <div className="report-confirmation col-sm-24 px-0">
              <div className="report_confirmation_title">Working on</div>
              {renderList(selectedSubskills[WORKING_ON], "-working-on")}
            </div>
          )}

          {selectedSubskills[STUDIED_EARLIER] && (
            <div className="report-confirmation col-sm-24 px-0">
              <div className="report_confirmation_title">Studied Earlier</div>
              {renderList(selectedSubskills[STUDIED_EARLIER], "-studied-earlier")}
            </div>
          )}
          {showCopyToRestStudents && hasChanges && (
            <div className="report-confirmation col-xl-24 pt-4 pb-4 px-0">
              <Form.Check
                id="copy-to-all-students"
                className="text-nowrap mr-5"
                label="Copy these skills to all students"
                custom
                checked={copyToAllStudents}
                onChange={updateCopyToAllStudents}
              />
            </div>
          )}
          {hasChanges && copyToAllStudents && (
            <div className="d-flex p-3 mt-1 mb-4 school-session_copy-skills-tip fullwidth">
              <span className="icon-16-px mr-2">
                <ReportOverdue />
              </span>
              <span>
                This option will replace ALL progresses of other students in the report, please double check that this
                is what you are willing to do
              </span>
            </div>
          )}
        </div>
      </div>
      {!canUpdate && (
        <div className="report-confirmation -empty">
          ← Pick skills mastered in the session to add them to session report
        </div>
      )}
      {!readOnlyMode && (
        <div className="panel_footer">
          <div
            className={cn("report-confirmation_controls mx-n2", {
              "-empty": !canUpdate,
            })}
          >
            <button onClick={onCancel} className="report-confirmation_control -cancel">
              Cancel
            </button>
            {canUpdate && (
              <button className="report-confirmation_control -submit" disabled={loading} onClick={submitSkills}>
                Add to report
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(state => {
  return {
    list: state.list,
    copyToAllStudents: state.copyToAllStudents,
    canUpdate:
      flow(
        partialRight(filter, el => el.status !== EMPTY),
        partialRight(groupBy, "status"),
        partialRight(size)
      )(state.selectedSubskills) > 0,
    selectedSubskills: groupBy(state.selectedSubskills, "status"),
    loading: state.loading,
    submitTo: state.submitTo,
    returnTo: state.returnTo,
    programId: state.programId,
    progressId: state.progressId,
    readOnlyMode: state.readOnlyMode,
    onEdit: state.onEdit,
    onCancel: state.onCancel,
    showCopyToRestStudents: state.showCopyToRestStudents,
  }
})(Confirmation)
