import React, { useState } from "react"
import PropTypes from "prop-types"
import { FieldArray } from "react-final-form-arrays"
import { Form } from "react-bootstrap"
import { union } from "lodash"
import { Field } from "react-final-form"

import ProgramsTree from "./ProgramsTree"
import AddProgramsModal from "./AddProgramsModal"
import Certificate from "./Certificate"
import RowWarning from "../../blocks/RowWarning"
import { serializeSpecialTreatments } from "../../../helpers/serializers"
import { SPECIAL_TREATMENT_REQUIREMENTS } from "../../../common/constants"

import { ReactComponent as Plus } from "../../../assets/images/plus.svg"

const requiredIds = value => {
  return value && value.length > 0 ? undefined : ["Required"]
}

const toShow = (admin, selectedIds, reviewStatusHash) => {
  return admin ? selectedIds.filter(id => reviewStatusHash[id] === "verified") : selectedIds
}

const onSelectNewIds = (newIds, ignoredProgramIds, form, setReviewStatusHash) => {
  setReviewStatusHash(
    newIds.reduce((acc, id) => {
      acc[id] = "verified"
      return acc
    }, {})
  )

  form.change("program_ids", union(newIds, ignoredProgramIds))
}

const FormFields = ({ form, dataSources, loadingFiles, setLoadingFiles, admin }) => {
  const { programs, user, hierarchy, trainings } = dataSources
  const [showProgramsModal, setShowProgramsModal] = useState(false)

  const specialTreatments = serializeSpecialTreatments(dataSources.specialTreatments)

  const addDocumentHandler = fields => {
    fields.push({ blob_id: "", description: "" })
  }

  const [prReviewStatus, setPrReviewStatus] = useState(
    user.programs.reduce((acc, item) => {
      acc[item.id] = item.review_status
      return acc
    }, {})
  )

  const ignoredProgramIds = admin ? user.programs.filter(el => el.review_status !== "verified").map(el => el.id) : []

  const programsHash = programs.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {})

  const certificateInfo = user.profile.certificates.reduce((acc, item) => {
    acc[item.id] = { url: item.document && item.document.download_link, review_status: item.review_status }
    return acc
  }, {})

  const handleFileLoading = (value, index) => {
    setLoadingFiles(prevState => {
      const newState = { ...prevState }
      if (value) newState[index] = value
      else delete newState[index]
      return newState
    })
  }

  return (
    <>
      <div className="row mb-5">
        <div className="col-sm-24">
          <FieldArray name="program_ids" validate={requiredIds}>
            {({ fields }) => {
              return (
                <>
                  <AddProgramsModal
                    show={showProgramsModal}
                    selectedIds={toShow(admin, fields.value, prReviewStatus)}
                    ignoredProgramIds={ignoredProgramIds}
                    hierarchy={hierarchy}
                    programsHash={programsHash}
                    admin={admin}
                    onSelect={newIds => onSelectNewIds(newIds, ignoredProgramIds, form, setPrReviewStatus)}
                    onHide={() => setShowProgramsModal(false)}
                  />

                  <ProgramsTree
                    admin={admin}
                    selectedIds={toShow(admin, fields.value, prReviewStatus)}
                    hierarchy={hierarchy}
                    programsHash={programsHash}
                    onDelete={index => fields.remove(index)}
                  />
                </>
              )
            }}
          </FieldArray>
        </div>
        <div className="col-lg-24 mb-5">
          <RowWarning className="panel_body-warning" name="program_ids">
            <button
              type="button"
              className="btn btn-outline-primary d-flex align-items-center"
              onClick={() => setShowProgramsModal(true)}
            >
              <div className="icon-16-px">
                <Plus />
              </div>
              <span className="ml-3">Add programs</span>
            </button>
          </RowWarning>
        </div>
      </div>

      <div className="row mb-5">
        <div className="col-lg-18 d-flex justify-content-between align-items-center">
          <h4>Learning Differences</h4>
        </div>
        <p className="col-sm-24">
          Do you have training and experience supporting students with learning differences? If so, please let us know
          which sort of students you can effectively teach. If you have any certificates of training in specific
          instructional programs, all the better! Please upload those in the Certificates and Training section below.
        </p>

        <div className="col-sm-24 mb-5">
          {specialTreatments.map(({ label, value }) => (
            <Field name="disabilities" type="checkbox" key={value} value={value}>
              {({ input }) => (
                <Form.Check className="mb-2" id={value} custom>
                  <Form.Check.Input {...input} data-test={`disability-${value}`} />
                  <Form.Check.Label>
                    <span className="profile-progress-difference-label">{label}</span>
                    <br />
                    {SPECIAL_TREATMENT_REQUIREMENTS[value] && (
                      <small className="profile-progress-difference-description">
                        * {SPECIAL_TREATMENT_REQUIREMENTS[value]}
                      </small>
                    )}
                  </Form.Check.Label>
                </Form.Check>
              )}
            </Field>
          ))}
        </div>
      </div>

      <FieldArray name="documents">
        {({ fields }) => (
          <div className="row mt-4">
            <div className="col-lg-18 d-flex justify-content-between align-items-center">
              <h4>Training and certificates</h4>
            </div>
            <div className="col-lg-12" />

            <p className="col-sm-24 mb-4">
              Please upload your teaching certification and/or any professional development certificates you have to
              show off your amazing training.
            </p>

            <div className="col-lg-18">
              {fields.map((name, index) => (
                <Certificate
                  key={name}
                  name={name}
                  index={index}
                  fields={fields}
                  trainings={trainings}
                  certificateInfo={certificateInfo}
                  isLoading={loadingFiles[index]}
                  setIsLoading={value => handleFileLoading(value, index)}
                  admin={admin}
                />
              ))}
            </div>

            <div className="col-lg-24 mb-4">
              <button
                type="button"
                className="btn btn-outline-primary d-flex align-items-center"
                onClick={() => addDocumentHandler(fields)}
              >
                <div className="icon-16-px">
                  <Plus />
                </div>
                <span className="ml-3">Add additional Training and Certificates</span>
              </button>
            </div>
          </div>
        )}
      </FieldArray>
    </>
  )
}

FormFields.propTypes = {
  dataSources: PropTypes.shape({
    programs: PropTypes.array,
  }),
}

export default FormFields
