import { Box, Typography } from "@mui/material"
import { styled } from "@mui/system"

export const NextSessionContainer = styled(Box)(({ theme }) => ({
  borderTop: `5px solid ${theme.palette.secondary.main}`,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

export const SessionDetails = styled("div")({
  flex: 1,
})

export const Text = styled(Typography)({
  letterSpacing: 0,
})

export const SessionHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))
