import React from "react"
import { connect } from "react-redux"
import { bool } from "prop-types"

const Loading = ({ children, loading }) => {
  return (
    <>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center min-h-400-px">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  )
}

Loading.defaultProps = {
  loading: true,
}

Loading.propTypes = {
  // children: element.isRequired,
  loading: bool,
}

export default connect(state => ({
  loading: state.loading,
}))(Loading)
