import React from "react"
import { Skeleton, Stack } from "@mui/material"
import ButtonSkeletonLoader from "../../../../common/skeleton_loaders/ButtonSkeletonLoader"

const LookupFieldLoader = () => (
  <Stack spacing={1} className="mt-4">
    <Skeleton variant="text" width={50} />
    <ButtonSkeletonLoader width={350} height={50} style={{ borderRadius: "10px" }} />
  </Stack>
)

export default LookupFieldLoader
