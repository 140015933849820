import { ConfigurationContext, DataContextActions, InitialLoadingContext } from "@/contexts"
import React, { useCallback, useContext, useMemo } from "react"

const InitialLoadingProvider = React.memo(({ children }) => {
  const parent = useContext(InitialLoadingContext)
  const { initial: config } = useContext(ConfigurationContext)
  const { getState } = useContext(DataContextActions)

  const hasAnyMissingState = useCallback(
    config => config.reduce((acc, config) => getState(config) === void 0 || acc, false),
    [getState]
  )

  const initialLoading = useMemo(
    () => hasAnyMissingState(config) || parent.initialLoading || false,
    [config, parent.initialLoading, hasAnyMissingState]
  )

  const result = {
    initialLoading,
  }

  return <InitialLoadingContext.Provider value={result}>{children}</InitialLoadingContext.Provider>
})

InitialLoadingProvider.displayName = "InitialLoadingProvider"

export { InitialLoadingProvider }
