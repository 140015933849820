import React from "react"
import { Field } from "react-final-form"

const Searchable = ({ user }) => {
  return (
    <Field
      name={`profile.searchable`}
      initialValue={user.organization ? user.organization.searchable : true}
      type="checkbox"
    >
      {({ input }) => (
        <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id="searchable" {...input} />
          <label className="custom-control-label" htmlFor="searchable">
            Braintrust marketplace
          </label>
        </div>
      )}
    </Field>
  )
}

export default Searchable
