import React, { useCallback, useContext, useMemo } from "react"
import { OpeningStatuses } from "../../constants"
import { statusMapper } from "./text_mappers"
import ContextMenuProvider, { ContextMenuNodeTypes, ContextMenuPositions } from "../../../blocks/ContextMenu"
import { ContextMenuContext, DataContext, QueryContext } from "../../../../contexts"
import { PropsInjector } from "../../../PropsInjector"
import { getOpening, updateOpening } from "../../configurations/domain"
import { Entities } from "../../../../constants"

const StatusButton = ({ status: currentStatus, onChange }) => {
  const { openContextMenu } = useContext(ContextMenuContext)
  const statusTitle = useCallback(status => statusMapper(status), [])

  const click = useCallback(
    event => {
      const configs = Object.values(OpeningStatuses)
        .filter(key => key !== currentStatus)
        .map(status => ({
          nodeType: ContextMenuNodeTypes.Button,
          title: statusTitle(status),
          onClick: e => {
            onChange(e, status)
          },
        }))
      openContextMenu(event, configs)
    },
    [openContextMenu, currentStatus, statusTitle, onChange]
  )

  return (
    <button className="btn-purpure-small" onClick={click}>
      {statusTitle(currentStatus)} ▾
    </button>
  )
}

const ChangeOpeningStatus = ({ children }) => {
  const { getHookState } = useContext(QueryContext)
  const { request: update } = getHookState(updateOpening)
  const { request: refresh } = getHookState(getOpening)
  const { opening } = useContext(DataContext)

  const onChange = useCallback(
    async (_, status) => {
      await update({
        entitiesIds: { [Entities.Openings]: opening.id },
        data: { opening: { status: status } },
      })

      await refresh({
        entitiesIds: { [Entities.Openings]: opening.id },
      })
    },
    [opening, update, refresh]
  )

  const props = useMemo(() => ({ onChange }), [onChange])
  return <PropsInjector props={props}>{children}</PropsInjector>
}

export const StatusDropdown = ({ status: currentStatus, anchorRef }) => {
  return (
    <ContextMenuProvider
      anchorRef={anchorRef}
      positionX={ContextMenuPositions.Start}
      positionY={ContextMenuPositions.End}
    >
      <ChangeOpeningStatus>
        <StatusButton status={currentStatus} />
      </ChangeOpeningStatus>
    </ContextMenuProvider>
  )
}
