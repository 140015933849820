import React from "react"
import { OuterLink } from "./outerLink"

export const EmailLink = ({ value, ...rest }) => {
  return (
    <OuterLink {...rest} to={`mailto:${value}`} target="_blank">
      {value}
    </OuterLink>
  )
}
