import { Entities, Methods } from "../constants"

export const getSchoolsAutocomplete = (preload = false) => ({
  entities: [Entities.Autocomplete, Entities.Schools],
  method: Methods.Get,
  alias: "schools",
  onStoreUpdate: data => data.items,
  initialParamsBuilder: preload ? () => {} : undefined,
})

export const getSubjectAutocomplete = (preload = false) => ({
  entities: [Entities.Autocomplete, Entities.Programs],
  method: Methods.Get,
  alias: "subjects",
  onStoreUpdate: data => data.items,
  initialParamsBuilder: preload ? () => {} : undefined,
})
