import React from "react"
import { Field } from "react-final-form"
import { mustBeGreaterOrEqual } from "../../../../helpers/validators"
import { positiveFloatNumber } from "../../../../helpers/formatters"
import FormRow from "../../../blocks/FormRow"
import { FormControl, Row } from "react-bootstrap"
import { showFieldError } from "../../../../helpers/forms"
import { labelSize, colSize } from "../FormFields"

const SessionRate = ({ isFormDisabled, label, selectedPartnership, hasCC }) => {
  return (
    <React.Fragment>
      {selectedPartnership && (
        <Row>
          <hr className="col-sm-12" />
        </Row>
      )}

      <FormRow label={label} LabelProps={labelSize} ColProps={colSize} required>
        <Field name="session_rate" validate={mustBeGreaterOrEqual(0)} parse={positiveFloatNumber}>
          {({ input, meta }) => (
            <React.Fragment>
              <FormControl {...input} isInvalid={showFieldError(meta)} disabled={isFormDisabled} />
              <FormControl.Feedback type="invalid">{meta.error || meta.submitError}</FormControl.Feedback>
            </React.Fragment>
          )}
        </Field>

        {selectedPartnership && (
          <React.Fragment>
            <Field name="parent_charge_kind" type="radio" value="auto" initialValue="idle">
              {({ input, meta }) => (
                <div className="form-check mt-3">
                  <FormControl
                    {...input}
                    id="parent_charge_kind-auto"
                    className="form-check-input radio_buttons"
                    isInvalid={showFieldError(meta)}
                    disabled={isFormDisabled}
                  />
                  <label className="collection_radio_buttons" htmlFor="parent_charge_kind-auto">
                    Charge parent automatically using CC on file
                    <br />
                    {!hasCC && <span className="color -red">Parent has no CC</span>}
                  </label>
                </div>
              )}
            </Field>

            <Field name="parent_charge_kind" type="radio" value="idle">
              {({ input, meta }) => (
                <div className="form-check">
                  <FormControl
                    {...input}
                    id="parent_charge_kind-idle"
                    className="form-check-input radio_buttons"
                    isInvalid={showFieldError(meta)}
                    disabled={isFormDisabled}
                  />
                  <label className="collection_radio_buttons" htmlFor="parent_charge_kind-idle">
                    No automatic charge
                  </label>
                </div>
              )}
            </Field>
          </React.Fragment>
        )}
      </FormRow>
    </React.Fragment>
  )
}

export default SessionRate
