import React, { useContext, Fragment, useState, useCallback, useRef, useEffect, useMemo, forwardRef } from "react"
import { Col, Row, Modal } from "react-bootstrap"
import moment from "moment"

import { DataContext, QueryContext, ValidationContext } from "../../contexts"
import { minutesToHours, formatDateKeepZone } from "../../helpers/dates"
import {
  CreateSessionDetailsForm,
  EditSessionDetailsForm,
  UnplannedSessionDetailsForm,
  CancelSessionForm,
} from "./forms"
import { WeekDates } from "./week_dates"
import useViewport from "../../hooks/useViewport"
import { deleteGroupSession, finishGroupSession, cancelGroupSession, pendReport } from "./configuration"
import { Entities, Roles } from "../../constants"
import { ValidationNames, WeekDayStatuses, WeekSessionStatuses } from "./constants"
import { className } from "../../helpers/className"

import { ReactComponent as Check } from "../../assets/images/check1.svg"
import { ReactComponent as Cross } from "../../assets/images/cross.svg"
import { ReactComponent as Pencil } from "../../assets/images/pencil.svg"
import { ReactComponent as Plus } from "../../assets/images/plus.svg"
import { ReactComponent as Minus } from "../../assets/images/minus.svg"
import { ReactComponent as ReportOverdue } from "../../assets/images/report-overdue.svg"

const meetingTypes = {
  online: "Online",
  offline: "Offline",
  pencil_spaces: "Pencil Spaces",
}

const statusesToChange = [
  {
    weekStatus: WeekDayStatuses.Finished,
    status: WeekSessionStatuses.Finished,
    config: finishGroupSession,
    title: "Finished",
  },
  {
    weekStatus: WeekDayStatuses.Canceled,
    status: WeekSessionStatuses.Canceled,
    config: cancelGroupSession,
    title: "Canceled",
  },
  {
    weekStatus: WeekDayStatuses.NoShow,
    status: WeekSessionStatuses.CancelledNoShow,
    config: cancelGroupSession,
    title: "No Show",
  },
  {
    weekStatus: WeekDayStatuses.Pending,
    status: WeekSessionStatuses.Pending,
    config: pendReport,
    title: "Pending",
  },
]

const NodeTypePicker = forwardRef(function NodeTypePicker(props, ref) {
  const { role } = useContext(DataContext)
  // eslint-disable-next-line no-unused-vars
  const { onClick, ...rest } = props
  return role === Roles.Admin ? <button {...props} ref={ref} /> : <div {...rest} ref={ref} />
})

const DayStatus = ({ groupSessionId, status: dayStatus, children }) => {
  const wrapperRef = useRef(null)
  const pickerRef = useRef(null)
  const listRef = useRef(null)
  const [open, setOpen] = useState(false)
  const { getRequest } = useContext(QueryContext)
  const [current, ...rest] = useMemo(
    () => [...statusesToChange].sort(current => (current.weekStatus === dayStatus ? -1 : 1)),
    [dayStatus]
  )

  const openMenu = () => {
    setOpen(true)
    setOverflow("hidden")
  }

  const setOverflow = useCallback(value => document.body.parentElement.style.setProperty("overflow", value), [])

  const closeMenu = useCallback(() => {
    setOpen(false)
    pickerRef.current?.focus()
    setOverflow(null)
  }, [setOverflow])

  const onClickOutside = useCallback(
    event => {
      if (open && !wrapperRef.current?.contains(event.target)) closeMenu()
    },
    [open, closeMenu]
  )

  const onEscape = useCallback(
    event => {
      if (open && event.keyCode === 27) closeMenu()
    },
    [open, closeMenu]
  )

  const onSelect = async (config, status) => {
    const request = getRequest(config)
    let requestParams = null
    switch (config) {
      case pendReport:
      case finishGroupSession: {
        requestParams = {
          entitiesIds: {
            [Entities.GroupSessions]: groupSessionId,
          },
        }
        break
      }
      case cancelGroupSession: {
        requestParams = {
          entitiesIds: {
            [Entities.GroupSessions]: groupSessionId,
          },
          data: {
            group_session: {
              status,
              cancellation_reason: status === WeekDayStatuses.Canceled ? "Canceled by Admin" : null,
            },
          },
        }
        break
      }
      default:
        break
    }
    setOverflow(null)
    setOpen(false)
    if (requestParams !== null) await request(requestParams)
  }

  useEffect(() => {
    if (open && listRef.current) listRef.current?.querySelector("button")?.focus()
  }, [open])

  useEffect(() => {
    document.addEventListener("click", onClickOutside)
    document.addEventListener("keydown", onEscape)
    return () => {
      document.removeEventListener("click", onClickOutside)
      document.removeEventListener("keydown", onEscape)
    }
  }, [onClickOutside, onEscape])

  return (
    <div ref={wrapperRef} className="d-flex position-relative">
      <NodeTypePicker
        ref={pickerRef}
        onClick={openMenu}
        className={`session-calendar-day_status -${current.weekStatus.replace(" ", "-")}`}
      >
        <span className="icon-12-px mr-1">{children}</span>
        <span className="session-calendar-day_status-text">{current.weekStatus}</span>
      </NodeTypePicker>
      {open && (
        <ul ref={listRef} className="position-absolute card fullwidth bottom mb-0 list-group">
          {rest.map(({ title, config, status }, idx) => {
            return (
              <li key={idx} className="list-group-item p-0" onClick={() => onSelect(config, status)}>
                <button className={`btn-change-session-status -${status}`}>{title}</button>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const BaseDay = ({ date, time, showTime, borderType = "solid", borderColor = "gray", bgColor = "white", children }) => {
  const day = moment(formatDateKeepZone(date))
  const isToday = day.isSame(moment(), "day")
  const dayClassName = [
    "session-calendar-day",
    borderType && `-border-type-${borderType}`,
    borderColor && `-border-color-${borderColor}`,
    bgColor && `-bg-color-${bgColor}`,
  ]
    .filter(Boolean)
    .join(" ")
  return (
    <div className={dayClassName}>
      {isToday && <div className="session-calendar-day-current" />}
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex flex-column align-items-start session-calendar-date">
          <span className="mr-1">{day.format("ddd")}</span>
          <span>{day.format("DD")}</span>
        </div>
        <div />
        {showTime && <div className="session-calendar-day-time">{formatDateKeepZone(time, "hh:mm a")}</div>}
      </div>
      <div className="d-flex flex-column flex-grow-1 justify-content-between">{children}</div>
    </div>
  )
}

const BaseDayContent = ({ type, meetingType, duration, onEdit, children }) => {
  const className = ["session-calendar-day_content", onEdit && "-editable", type && `-${type}`]
    .filter(Boolean)
    .join(" ")
  const [hours, minutes] = minutesToHours(duration)
  const content = (
    <>
      <span className="session-calendar-day_content-duration">
        {hours}h {minutes}min
      </span>
      <span className="session-calendar-day_content-type">{meetingTypes[meetingType]}</span>
      {children}
    </>
  )
  return typeof onEdit === "function" ? (
    <div className="session-calendar-day_content-wrapper">
      <button className={className} onClick={onEdit}>
        {content}
      </button>
    </div>
  ) : (
    <div className={className}>{content}</div>
  )
}

const Past = ({ date, time, readOnly, type, duration, onSubmit, onCancel, onEdit }) => {
  return (
    <BaseDay showTime date={date} time={time} borderColor="gray1" bgColor="gray1" borderType="solid">
      <BaseDayContent type={readOnly ? "" : "action"} meetingType={type} duration={duration} onEdit={onEdit} />
      {!readOnly && (
        <div className="d-flex position-relative">
          <button className="session-calendar-day_button icon-16-px -submit -primary" onClick={onSubmit}>
            <Check />
          </button>
          <button className="session-calendar-day_button icon-16-px -secondary" onClick={onCancel}>
            <Cross />
          </button>
        </div>
      )}
    </BaseDay>
  )
}

const PastWithFinishRequest = ({ groupSessionId, ...params }) => {
  const { getRequest } = useContext(QueryContext)
  const apiRequest = getRequest(finishGroupSession)
  const request = async () => {
    await apiRequest({
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
    })
  }
  return <Past {...params} onSubmit={request} />
}

const Upcoming = ({ date, time, readOnly, type, duration, onCancel, onEdit }) => {
  return (
    <BaseDay showTime date={date} time={time} borderColor="gray1" bgColor="gray1" borderType="solid">
      <BaseDayContent type={readOnly ? "" : "action"} meetingType={type} duration={duration} onEdit={onEdit} />
      {!readOnly && (
        <div className="d-flex position-relative">
          <button className="session-calendar-day_button icon-16-px -secondary" onClick={onCancel}>
            <Cross />
          </button>
        </div>
      )}
    </BaseDay>
  )
}

const UpcomingWithDeleteRequest = ({ groupSessionId, ...params }) => {
  const { groupSessionId: original_session_id } = useContext(DataContext)
  const { getRequest } = useContext(QueryContext)
  const apiRequest = getRequest(deleteGroupSession)

  const request = async () => {
    await apiRequest({
      entitiesIds: {
        [Entities.GroupSessions]: groupSessionId,
      },
      params: { original_session_id },
    })
  }

  return <Upcoming {...params} onCancel={request} />
}

const Unplanned = ({ date, time, type, duration, onSubmit, onEdit }) => {
  return (
    <BaseDay showTime date={date} time={time} borderColor="orange" borderType="dashed">
      <BaseDayContent type="unplanned" meetingType={type} duration={duration} onEdit={onEdit}>
        <span className="session-calendar-day_content-comment">unplanned</span>
      </BaseDayContent>
      <div className="d-flex position-relative">
        <button className="session-calendar-day_button icon-16-px -warning" onClick={onSubmit}>
          <Pencil />
        </button>
      </div>
    </BaseDay>
  )
}

const Canceled = ({ groupSessionId, date, time, readOnly, type, duration, status }) => {
  return (
    <BaseDay showTime date={date} time={time} borderColor="gray" borderType="solid">
      <BaseDayContent type={readOnly ? "" : "canceled"} meetingType={type} duration={duration} />
      <DayStatus groupSessionId={groupSessionId} status={status}>
        <Cross />
      </DayStatus>
    </BaseDay>
  )
}

const Finished = ({ groupSessionId, date, time, type, duration }) => {
  return (
    <BaseDay showTime date={date} time={time} borderColor="gray" borderType="solid">
      <BaseDayContent meetingType={type} duration={duration} />
      <DayStatus groupSessionId={groupSessionId} status={WeekDayStatuses.Finished}>
        <Check />
      </DayStatus>
    </BaseDay>
  )
}

const Add = ({ date, time, onSubmit }) => {
  return (
    <BaseDay date={date} time={time} borderColor="gray" borderType="dashed">
      <div className="session-calendar-day_content -action">
        <button className="session-calendar-day_content-button" onClick={onSubmit}>
          <span className="session-calendar-day_content-button-icon">
            <Plus />
          </span>
        </button>
      </div>
    </BaseDay>
  )
}

const OutOfLimit = ({ date, time }) => {
  return (
    <BaseDay date={date} time={time} borderColor="gray" borderType="dashed">
      <div className="session-calendar-day_content -disabled">
        <span className="session-calendar-day_content-button-icon">
          <Plus />
        </span>
        <span className="session-calendar-day_content-comment -small">Out of limit</span>
      </div>
    </BaseDay>
  )
}

const Empty = ({ date, time }) => {
  return (
    <BaseDay date={date} time={time} borderColor="gray" borderType="solid">
      <div className="session-calendar-day_content -disabled -empty">
        <span className="session-calendar-day_content-button-icon">
          <Minus />
        </span>
      </div>
    </BaseDay>
  )
}

const modalTitleMap = {
  add: "Unplanned session",
  add_ps: "Unplanned session",
  past: "Session",
  upcoming: "Session editing",
}

const FormSwitch = ({ type, day, onHide }) => {
  const {
    statusInfo: { schedule },
  } = useContext(DataContext)

  let Form = null

  if (type === "add") Form = CreateSessionDetailsForm
  else if (type === "add_ps") Form = UnplannedSessionDetailsForm
  else if (type === "upcoming") Form = EditSessionDetailsForm
  else if (type === "past") {
    // if (day.ps_duration) Form = SessionDetailsShortForm; else
    Form = EditSessionDetailsForm
  }

  return Form ? <Form onHide={onHide} day={day} maxBillableMin={schedule.max_duration || 0} /> : Form
}

const FormEditModal = ({ show, onHide, type, day }) => {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header className="px-4 pt-4 pb-3" closeButton>
        <Modal.Title>
          {modalTitleMap[type]} <span className="modal-subtitle">{moment(day?.date).format("ddd, MMMM D")}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Col>
          <FormSwitch type={type} day={day} onHide={onHide} />
        </Col>
      </Modal.Body>
    </Modal>
  )
}

const CancelModalContent = ({ day, onHide }) => {
  const { statusInfo, groupInfo } = useContext(DataContext)
  const {
    schedule: { max_paid_noshows, fact_paid_noshows },
  } = statusInfo
  const { group, meeting_format } = groupInfo
  return (
    <>
      <div className="school-session_cancel-modal-title mb-4">
        You’re canceling Group session
        <br />
        <span className="font-weight-semibold">
          {group.name} on {meeting_format === "online" ? "Web" : meetingTypes[meeting_format]},{" "}
          {formatDateKeepZone(day.time, "MMMM D, h:mma")}
        </span>
      </div>
      <CancelSessionForm
        day={day}
        maxCancelCount={max_paid_noshows}
        currentCancelCount={fact_paid_noshows}
        onHide={onHide}
      />
    </>
  )
}

const FormCancelModal = ({ day, show, onHide }) => {
  const { isMobileViewport } = useViewport()

  return (
    <Modal size="lg" show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header className="-empty" closeButton />
      <Modal.Body className={`py-4 ${isMobileViewport ? "" : "px-5"}`}>
        <Col className="px-3 py-2">
          <CancelModalContent day={day} onHide={onHide} />
        </Col>
      </Modal.Body>
    </Modal>
  )
}

const ReadonlyDates = ({ days }) => {
  return days.map(({ status, date, time, meeting_format: type, duration }) => {
    if (status === WeekDayStatuses.Tile) return <Empty key={date} time={time} date={date} />
    else if (status === WeekDayStatuses.Pending)
      return <Past key={date} date={date} time={time} type={type} duration={duration} readOnly />
    else if (status === WeekDayStatuses.Finished)
      return <Finished key={date} date={date} time={time} type={type} duration={duration} readOnly />
    else if (status === WeekDayStatuses.Canceled || status === WeekDayStatuses.NoShow)
      return <Canceled key={date} date={date} time={time} type={type} duration={duration} status={status} readOnly />
    else return <Fragment key={date} />
  })
}

const EditableDays = ({ days }) => {
  const [showModal, setShowModal] = useState(null)
  const [editableDay, setEditableDay] = useState({})

  const { statusInfo } = useContext(DataContext)
  const {
    schedule: { fact_sessions, max_sessions },
  } = statusInfo

  const onShowModal = (type, day, modalType) => () => {
    setEditableDay({ type, day })
    setShowModal(modalType)
  }

  const onHideModal = () => {
    setShowModal(null)
    setTimeout(() => setEditableDay({}), 250)
  }

  const checkActionExist = useCallback((status, actions) => {
    return actions.indexOf(status) !== -1
  }, [])

  return (
    <>
      {days.map(day => {
        const { status, date, time, meeting_format: type, duration, actions, group_session_id } = day
        if (status === WeekDayStatuses.Tile) {
          if (checkActionExist("can_add", actions)) {
            if (max_sessions !== null && fact_sessions >= max_sessions)
              return <OutOfLimit key={date} date={date} time={time} />
            else return <Add key={date} date={date} time={time} onSubmit={onShowModal("add", day, "edit")} />
          } else return <Empty key={date} date={date} time={time} />
        } else if (status === WeekDayStatuses.Pending) {
          if (checkActionExist("can_cancel", actions) && checkActionExist("can_finish", actions))
            return (
              <PastWithFinishRequest
                key={date}
                date={date}
                time={time}
                type={type}
                groupSessionId={group_session_id}
                duration={duration}
                onEdit={onShowModal("past", day, "edit")}
                onCancel={onShowModal("cancel", day, "cancel")}
              />
            )
          else if (checkActionExist("can_cancel", actions) && !checkActionExist("can_finish", actions))
            return (
              <Upcoming
                key={date}
                date={date}
                time={time}
                type={type}
                duration={duration}
                onEdit={onShowModal("upcoming", day, "edit")}
                onCancel={onShowModal("cancel", day, "cancel")}
              />
            )
          else if (checkActionExist("can_delete", actions))
            return (
              <UpcomingWithDeleteRequest
                key={date}
                date={date}
                time={time}
                type={type}
                groupSessionId={group_session_id}
                duration={duration}
                onEdit={onShowModal("upcoming", day, "edit")}
              />
            )
          else return <Upcoming key={date} date={date} time={time} type={type} duration={duration} readOnly />
        } else if (status === WeekDayStatuses.AddPS)
          return (
            <Unplanned
              key={date}
              date={date}
              time={time}
              type={type}
              duration={duration}
              onEdit={onShowModal("add_ps", day, "edit")}
              onSubmit={onShowModal("add_ps", day, "edit")}
            />
          )
        else if (status === WeekDayStatuses.Finished)
          return (
            <Finished
              key={date}
              groupSessionId={group_session_id}
              date={date}
              time={time}
              type={type}
              duration={duration}
            />
          )
        else if (status === WeekDayStatuses.Canceled || status === WeekDayStatuses.NoShow)
          return (
            <Canceled
              key={date}
              groupSessionId={group_session_id}
              date={date}
              time={time}
              type={type}
              duration={duration}
              status={status}
            />
          )
        else return <Fragment key={date} />
      })}
      <FormEditModal show={showModal === "edit"} day={editableDay.day} type={editableDay.type} onHide={onHideModal} />
      <FormCancelModal show={showModal === "cancel"} day={editableDay.day} onHide={onHideModal} />
    </>
  )
}

const CalendarSelector = ({ days }) => {
  const { statusInfo } = useContext(DataContext)
  if (statusInfo === void 0) return null
  return statusInfo.readonly ? <ReadonlyDates days={days} /> : <EditableDays days={days} />
}

const NeedAction = ({ children }) => {
  return (
    <div className="card shadow-none fullwidth px-3 py-2 action-needed-tip flex-row align-items-center">
      <div className="action-needed-tip_icon flex-shrink-0 mr-1">
        <ReportOverdue />
      </div>
      <div className="action-needed-tip_text">{children}</div>
    </div>
  )
}

const NeedActionPencilSpaces = ({ days }) => {
  const needActionDates = days.filter(el => el.status === WeekDayStatuses.AddPS)

  return needActionDates.map(({ date }, idx) => (
    <NeedAction key={idx}>
      <span className="font-weight-semibold">{moment(date).format("dddd, MMM D")}</span> Unplanned session was tracked
      on Pencil Spaces. Your input needed.
    </NeedAction>
  ))
}

const NeedActionAttendance = ({ dates }) => {
  const datesRow = useMemo(() => dates.map(date => moment(date).format("dddd")).join(", "), [dates])
  return (
    <NeedAction>
      No students who attended the session{dates.length > 1 ? "s" : ""} on{" "}
      <span className="font-weight-semibold">{datesRow}</span>
    </NeedAction>
  )
}

const WeekCalendar = ({ className: weekClass }) => {
  const { statusInfo, weekInfo } = useContext(DataContext)
  const { getValidationValueByName } = useContext(ValidationContext)
  if (weekInfo === void 0 || statusInfo === void 0) return null

  const { days } = weekInfo
  const { status } = statusInfo

  const weekWarnings = getValidationValueByName(ValidationNames.AttendanceWarnings)

  const wrapperClassName = className(
    "card",
    "p-4",
    weekClass,
    status === "action_needed" || (weekWarnings.length && "-action-needed")
  )

  return (
    <div className={wrapperClassName}>
      <Col>
        <Row as="h6" className="session-calendar-title">
          <span className="mr-1">Week of</span>
          <WeekDates />
        </Row>
        <Row>
          <div className="d-flex flex-grow-1 flex-nowrap overflow-auto justify-content-between mx-n4 px-4">
            <CalendarSelector days={days} />
          </div>
        </Row>
        {status === "action_needed" && (
          <Row className="mt-3">
            <NeedActionPencilSpaces days={days} />
          </Row>
        )}
        {weekWarnings.length > 0 && (
          <Row className="mt-3">
            <NeedActionAttendance dates={weekWarnings} />
          </Row>
        )}
      </Col>
    </div>
  )
}

export default WeekCalendar
