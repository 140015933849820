import React from "react"
import { Row, Button } from "react-bootstrap"
import { isEmpty, reduce, compose, join, find, map } from "lodash/fp"

import {
  getLabelByValue,
  getScheduleSummary,
  getProgramsSummary,
  getPriceSummary,
} from "../../../helpers/presentational"
import Chip from "../../Chip"

const getTextValues = ({ values, options }) => {
  const result = []
  const {
    grade,
    program_ids,
    struggle_with,
    special_treatments,
    executive_function,
    school,
    current_school,
    online,
    offline,
    zip,
    timetable,
    max_price,
  } = values
  const { grades, programs, specialTreatments, struggleWithQuestions } = options
  if (grade) {
    result.push({
      text: getLabelByValue(grades)(grade),
      fieldsToReset: ["grade", "program_ids", "struggle_with"],
    })
  }
  if (program_ids && Array.isArray(program_ids) && program_ids[0]) {
    const text = getProgramsSummary(program_ids[0], programs)
    result.push({ text, fieldsToReset: ["program_ids", "struggle_with"] })
  }
  if (!isEmpty(special_treatments)) {
    const text = special_treatments.map(getLabelByValue(specialTreatments)).join(", ")
    result.push({ text, fieldsToReset: ["special_treatments"] })
  }
  if (executive_function) {
    result.push({
      text: "Executive function skills support",
      fieldsToReset: ["executive_function", "struggle_with"],
    })
  }
  if (!isEmpty(struggle_with)) {
    const answers = reduce((result, value) => [...result, ...value.answers], [])(struggleWithQuestions)
    const text = compose(
      join(", "),
      map("text"),
      map(id => find(["id", id])(answers))
    )(struggle_with)
    result.push({ text, fieldsToReset: ["struggle_with"] })
  }
  if (school) {
    result.push({ text: `Experience with ${school}`, fieldsToReset: ["school"] })
  }
  if (current_school) {
    result.push({ text: `Exclude ${current_school}`, fieldsToReset: ["current_school"] })
  }
  if (online) {
    result.push({ text: "Online", fieldsToReset: ["online"] })
  }
  if (offline && zip) {
    result.push({ text: `Near ${zip}`, fieldsToReset: ["offline", "zip"] })
  }
  if (!isEmpty(timetable)) {
    result.push({ text: getScheduleSummary(timetable), fieldsToReset: ["timetable"] })
  }
  if (max_price) {
    result.push({ text: getPriceSummary(max_price), fieldsToReset: ["max_price"] })
  }
  return result
}

const FilterValues = ({ values, options, onClearFields, onClearAll }) => {
  const textValues = getTextValues({ values, options })

  if (!textValues.length) {
    return null
  }

  return (
    <Row className="mb-3 mt-n4 pt-1">
      {textValues.map(({ text, fieldsToReset }, i) => (
        <Chip key={i} onClose={() => onClearFields(fieldsToReset)}>
          {text}
        </Chip>
      ))}
      <Button type="button" variant="link" className="btn-link-primary btn-sm ml-2 mb-2" onClick={onClearAll}>
        Clear all
      </Button>
    </Row>
  )
}

export default FilterValues
