import React from "react"
import { toggleId, elText } from "../../../helpers/profile_form"
import { flow, filter, map, join, includes } from "lodash/fp"

const Tutoring = ({ tutoring, fields, divisions }) => {
  const divisionNames = (divisionNames, choosedDevisions = []) =>
    flow(
      filter(divisionName => includes(divisionName[1], choosedDevisions)),
      map(a => a[0]),
      join(", ")
    )(divisionNames)

  return (
    <>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">School</label>
        <div className="col-lg-16">
          <div className="col-lg-24 form-control-plaintext">{tutoring.school}</div>
        </div>
        <div className="clearfix col-lg-2">
          <span
            className="cursor-pointer"
            onClick={() => {
              toggleId(fields, tutoring.id)
            }}
          >
            {elText(tutoring, fields.value)}
          </span>
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-6 col-form-label">Division</label>
        <div className="col-lg-16 form-control-plaintext">{divisionNames(divisions, tutoring.divisions)}</div>
      </div>
    </>
  )
}

export default Tutoring
