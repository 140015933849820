export const ValidationNames = {
  Agenda: "agenda",
  NextSteps: "nextSteps",
  StrengthsChallenges: "strengthsChallenges",
  ProgramProgresses: "programProgresses",
  StudentStrengthsChallenges: "studentStrengthsChallenges",
  StudentProgramProgresses: "studentProgramProgresses",
  GroupProgressInfo: "groupProgressInfo",
  GroupProgressInfoInvalid: "groupProgressInfoInvalid",
  AllSessionFinished: "allSessionFinished",
  AttendanceWarnings: "attendanceWarnings",
  CommonInvalid: "commonInvalid",
}

export const noEventDayStatus = "tile"

export const finalStatuses = ["finished", "canceled", "no show"]

export const cancelledStatuses = finalStatuses.filter(status => status !== "finished")

export const reportValidationStatuses = ["in_progress", "action_needed", "report_overdue"]

export const WeekDayStatuses = {
  Tile: "tile",
  Pending: "pending",
  Finished: "finished",
  Canceled: "canceled",
  NoShow: "no show",
  AddPS: "add_ps",
}

export const WeekSessionStatuses = {
  Pending: "pending",
  Finished: "finished",
  ReportReady: "report_ready",
  ReportSent: "report_sent",
  Cancelled: "cancelled",
  CancelledNoShow: "cancelled_no_show",
}
