import React from "react"
import { Row, Col } from "react-bootstrap"
import schema from "./schema"
import { useForm } from "react-final-form"

const Filter = ({ values, options }) => {
  const form = useForm()

  const onSubmit = fieldsToReset => {
    form.mutators.clearFieldsData(fieldsToReset)
    form.mutators.setFieldData("page", 1)
    form.submit()
  }

  return (
    <>
      <Row className="mt-2 justify-content-xxl-between">
        {schema.map(
          ({ id, name, completed, disabled, fieldsToReset, FilterComponent, FilterComponentProps, ...props }) => (
            <Col key={id} className="mb-2 flex-md-grow-0">
              <FilterComponent
                {...props}
                {...FilterComponentProps}
                className="dropdown-toggle-no-icon fullwidth"
                options={options}
                fieldsToReset={fieldsToReset}
                completed={completed(values)}
                disabled={disabled(values)}
                getButtonText={() => name}
                onSubmit={() => onSubmit(fieldsToReset)}
              />
            </Col>
          )
        )}
      </Row>
    </>
  )
}

export default Filter
