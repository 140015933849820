import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap"
import { isEmpty, omitBy } from "lodash/fp"
import { parse } from "query-string"

import RequestModal from "./RequestModal"
import RequestSuccessModal from "./RequestSuccessModal"
import apiClient, { replaceId, showAllErrorsInAlert } from "../../common/apiClient"
import { getSearchParamsFromUrl } from "../find_tutor/serializeParams"
import useViewport from "../../hooks/useViewport"

const getRequestParamsFromUrl = () => {
  const params = getSearchParamsFromUrl()
  if (isEmpty(params)) {
    return null
  }
  if (params.zip) {
    params.zip = `${params.zip}`
  }
  params.program_ids = params.program_ids && Array.isArray(params.program_ids) ? params.program_ids : []
  return params
}

const serializeFormValuesToSend = values => {
  const fieldsToExclude = []
  if (values.online) {
    fieldsToExclude.push("address_1", "address_2", "city", "state", "zip")
  }
  if (values.recurrence) {
    fieldsToExclude.push("duration")
  } else {
    fieldsToExclude.push("ended_at", "timetable")
  }
  const result = omitBy((_, key) => fieldsToExclude.includes(key))(values)
  return result
}

const ScheduleSession = props => {
  const [showRequestModal, setShowRequestModal] = useState(false)
  const [showRequestSuccessModal, setShowRequestSuccessModal] = useState(false)
  const [newRequestUrl, setNewRequestedUrl] = useState("")
  const [currentRequest, setCurrentRequest] = useState(props.dataSources.request)

  const { isMobileViewport } = useViewport()

  useEffect(() => {
    const { open_session_request } = parse(window.location.search, { parseBooleans: true })
    if (open_session_request) {
      setShowRequestModal(true)
    }
  }, [])

  const sendRequest = async values => {
    try {
      const params = serializeFormValuesToSend(values)
      const { data } = await apiClient.post(props.urls.save, params)
      setNewRequestedUrl(`${props.urls.path_to_requests}?id=${data.id}`)
      setShowRequestModal(false)
      setShowRequestSuccessModal(true)
      setCurrentRequest(data)
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const editRequest = async values => {
    try {
      const params = serializeFormValuesToSend(values)
      const { data } = await apiClient.put(replaceId(props.urls.edit, currentRequest.id), params)
      setShowRequestModal(false)
      setCurrentRequest(data)
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  return (
    <>
      <Button className="sub-nav_item mr-3" href="#" onClick={() => setShowRequestModal(true)}>
        {isMobileViewport ? "Request session" : currentRequest ? "Edit requested session" : "Request session"}
      </Button>

      <RequestModal
        {...props}
        dataSources={{ ...props.dataSources, request: currentRequest || getRequestParamsFromUrl() }}
        hourRate={currentRequest ? currentRequest.hourly_rate : props.dataSources.tutor.hourly_rate}
        timeZone={props.dataSources.parent.time_zone}
        show={showRequestModal}
        handleClose={() => setShowRequestModal(false)}
        onSubmit={values => (currentRequest ? editRequest(values) : sendRequest(values))}
      />

      <RequestSuccessModal
        show={showRequestSuccessModal}
        url={newRequestUrl}
        handleClose={() => setShowRequestSuccessModal(false)}
      />
    </>
  )
}

export default ScheduleSession
