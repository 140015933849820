import React, { useContext, useEffect, useMemo } from "react"
import { DataContext, QueryContext } from "../../../../../contexts"
import { getOpeningApplications } from "../../../configurations/domain"
import { Entities } from "../../../../../constants"
import { ApplicationsList } from "./applications_list"

export const TutorsList = () => {
  const { openingId, applications, tutorListFilters } = useContext(DataContext)
  const { getQueryState } = useContext(QueryContext)
  const { request, loading } = getQueryState(getOpeningApplications)

  useEffect(() => {
    if (tutorListFilters)
      request({
        entitiesIds: {
          [Entities.Openings]: openingId,
        },
        params: tutorListFilters,
      })
  }, [tutorListFilters, request, openingId])

  const visibleApplications = useMemo(() => applications.filter(({ show }) => show), [applications])
  const hiddenApplications = useMemo(() => applications.filter(({ show }) => !show), [applications])

  return (
    <div className="min-h-400-px d-flex flex-column gap-1">
      {loading ? (
        <div className="d-flex align-items-center justify-content-center opening-tutor-card_loader">
          <div className="spinner-border text-primary" />
        </div>
      ) : null}
      <h4 className="my-0 font-weight-bolder">
        {applications.length} tutor{applications.length > 1 && "s"}
      </h4>

      {applications.length === 0 && (
        <div className="d-flex align-items-center justify-content-center flex-grow-1">
          <h1 className="text-gray text-center mb-0 font-weight-normal">No tutors applied</h1>
        </div>
      )}
      {applications.length > 0 && (
        <div className="d-flex flex-column gap-2">
          <ApplicationsList applications={visibleApplications} />

          {hiddenApplications.length > 0 ? (
            <>
              <h4 className="mt-4 mb-0 font-weight-bolder">Hidden tutors</h4>
              <ApplicationsList applications={hiddenApplications} />
            </>
          ) : null}
        </div>
      )}
    </div>
  )
}
