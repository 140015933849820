import React, { useState, useEffect, useMemo } from "react"
import { Row, Col } from "react-bootstrap"
import cn from "classnames"
import schema from "./schema"
import { useForm } from "react-final-form"

import { getQueryStringOmitParams } from "../../../common/searchOptions"
import { Button } from "react-bootstrap"
import useViewport from "../../../hooks/useViewport"

const mobileFilters = schema.filter(({ hideOnMobile }) => hideOnMobile)

const Filter = ({ values, options, urls: { sign_up: signUpUrl } }) => {
  const form = useForm()
  const { isPhoneViewport } = useViewport()
  const [showFilters, setShowFilters] = useState(!isPhoneViewport)

  const showSignUpLink = values.grade && values.program_ids && values.program_ids.length > 0
  const queryString = getQueryStringOmitParams(["page", "limit"], ["zip"])

  const selectedFiltersCount = useMemo(() => {
    return schema.reduce(
      (prevValue, { completed, hideOnMobile }) => (completed(values) && !hideOnMobile ? prevValue + 1 : prevValue),
      0
    )
  }, [values])

  useEffect(() => {
    setShowFilters(!isPhoneViewport)
  }, [isPhoneViewport])

  const onSubmit = fieldsToReset => {
    form.mutators.clearFieldsData(fieldsToReset)
    form.mutators.setFieldData("page", 1)
    form.submit()
  }

  return (
    <>
      {showFilters && isPhoneViewport && (
        <Button className="fullwidth" variant="outline-dropdown" onClick={() => setShowFilters(false)}>
          Hide filters
        </Button>
      )}
      {!showFilters && isPhoneViewport && (
        <Button className="fullwidth selected" variant="outline-dropdown" onClick={() => setShowFilters(true)}>
          Filters: {selectedFiltersCount} selected
        </Button>
      )}
      {showFilters && (
        <Row className="mt-2">
          {schema.map(
            ({
              id,
              completed,
              disabled,
              fieldsToReset,
              FilterComponent,
              FilterComponentProps,
              hideOnMobile,
              ...props
            }) => (
              <Col key={id} className={cn("mb-2 flex-md-grow-0", hideOnMobile && "d-none d-md-block")}>
                <FilterComponent
                  {...props}
                  {...FilterComponentProps}
                  className="fullwidth"
                  options={options}
                  completed={completed(values)}
                  disabled={disabled(values)}
                  onSubmit={() => onSubmit(fieldsToReset)}
                />
              </Col>
            )
          )}
        </Row>
      )}

      <Row className="mt-2 d-md-none">
        {mobileFilters.map(
          ({ id, completed, disabled, fieldsToReset, FilterComponent, FilterComponentProps, ...props }) => (
            <Col key={id} className={cn("mb-2 flex-md-grow-0")}>
              <FilterComponent
                {...props}
                {...FilterComponentProps}
                className="fullwidth"
                options={options}
                completed={completed(values)}
                disabled={disabled(values)}
                onSubmit={() => onSubmit(fieldsToReset)}
              />
            </Col>
          )
        )}
      </Row>

      {showSignUpLink && (
        <Row className="justify-content-center mt-4">
          <p>
            Want to find an even better fit? <a href={signUpUrl + queryString}>Sign up</a> to use advanced filters.
          </p>
        </Row>
      )}
    </>
  )
}

export default Filter
