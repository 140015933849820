import React from "react"
import { Field } from "react-final-form"
import { Form } from "react-bootstrap"

import FormSelect from "../../blocks/FormSelect"
import FormRow from "../../blocks/FormRow"
import { required, validateZip } from "../../../helpers/validators"
import { formatZip, formatPhone, valueOrNull } from "../../../helpers/formatters"
import { serializeTimeZones, serializeStates } from "../../../helpers/serializers"
import { showFieldError } from "../../../helpers/forms"

const FormFields = ({ timeZones, states }) => {
  const timeZoneOptions = serializeTimeZones(timeZones)
  const stateOptions = serializeStates(states)

  return (
    <>
      <Field name="email">
        {({ input }) => (
          <FormRow label="Email">
            <Form.Control {...input} disabled />
          </FormRow>
        )}
      </Field>

      <Field name="first_name" validate={required}>
        {({ input, meta }) => (
          <FormRow label="First name" required>
            <Form.Control {...input} maxLength="255" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="last_name" validate={required}>
        {({ input, meta }) => (
          <FormRow label="Last name" required>
            <Form.Control {...input} maxLength="255" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="phone" format={formatPhone} parse={valueOrNull}>
        {({ input, meta }) => (
          <FormRow label="Phone">
            <Form.Control {...input} maxLength="30" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="time_zone" parse={valueOrNull}>
        {({ input, meta }) => (
          <FormRow label="Time zone">
            <FormSelect
              {...input}
              options={timeZoneOptions}
              placeholder="Pick time zone"
              isInvalid={showFieldError(meta)}
            />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="address_1" parse={valueOrNull}>
        {({ input, meta }) => (
          <FormRow label="Address 1">
            <Form.Control {...input} maxLength="255" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="address_2" parse={valueOrNull}>
        {({ input, meta }) => (
          <FormRow label="Address 2">
            <Form.Control {...input} maxLength="255" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="city" parse={valueOrNull}>
        {({ input, meta }) => (
          <FormRow label="City">
            <Form.Control {...input} maxLength="255" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="state" parse={valueOrNull}>
        {({ input, meta }) => (
          <FormRow label="State" ColProps={{ md: 12, lg: 5 }}>
            <FormSelect {...input} options={stateOptions} placeholder="Pick state" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>

      <Field name="zip" validate={validateZip} parse={formatZip}>
        {({ input, meta }) => (
          <FormRow label="Zip" ColProps={{ md: 12, lg: 5 }}>
            <Form.Control {...input} maxLength="255" isInvalid={showFieldError(meta)} />
            <Form.Control.Feedback type="invalid">{meta.error || meta.submitError}</Form.Control.Feedback>
          </FormRow>
        )}
      </Field>
    </>
  )
}

export default FormFields
