import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"

import FormFieldErrorFeedback from "./FormFieldErrorFeedback"
import apiClient from "../common/apiClient"
import Alerter from "../common/alerter"

const OTHER_PROGRAM_NAME = "Custom"

const SelectProgramForTutor = ({
  selProgramId,
  selCustomProgramName,
  selProgramIdErrors,
  selCustomProgramErrors,
  programsApiPath,
  modelName = "session",
}) => {
  const [showOtherInput, setShowOtherInput] = useState(false)
  const [otherInputValue, setOtherInputValue] = useState(selCustomProgramName || "")
  const [programsOptions, setProgramsOptions] = useState([])

  const [programIdValue, setProgramIdValue] = useState(selProgramId || "")
  const onSelProgramIdChange = ({ target: { value } }) => {
    const selected = programsOptions.find(({ value: programValue }) => programValue === value)
    setShowOtherInput(selected?.title === OTHER_PROGRAM_NAME)
    setProgramIdValue(value)
  }

  const loadTutorPrograms = useCallback(() => {
    apiClient
      .get(programsApiPath, { params: { add_custom: true } })
      .then(({ data: { items } }) => {
        const selected = items.find(el => el.value === selProgramId)
        setShowOtherInput(selected?.title === OTHER_PROGRAM_NAME)
        setProgramsOptions(items)
      })
      .catch(() => Alerter.error("Autocomplete network/server error"))
  }, [selProgramId, programsApiPath])

  useEffect(() => loadTutorPrograms(), [loadTutorPrograms])

  return (
    <div>
      <div
        className={
          "form-group row select required session_default_program_id" +
          (selProgramIdErrors ? " form-group-invalid" : "")
        }
      >
        <label
          className="col-form-label col-sm-7 col-md-5 col-lg-4 select required"
          htmlFor="session_default_program_id"
        >
          Default program
          <abbr title="required">*</abbr>
        </label>
        <div className="col-form-input col-sm-17 col-md-14 col-lg-10 col-xl-8">
          <select
            className={"form-control select required" + (selProgramIdErrors ? " is-invalid" : "")}
            name={`${modelName}[default_program_id]`}
            id="session_default_program_id"
            value={programIdValue}
            onChange={onSelProgramIdChange}
          >
            <option value="" data-title=""></option>
            {programsOptions.map(program => (
              <option key={program.value} value={program.value} data-title={program.title}>
                {program.title}
              </option>
            ))}
          </select>
          <FormFieldErrorFeedback message={selProgramIdErrors} />
          {showOtherInput && (
            <>
              <input
                className={"form-control mt-3 required" + (selCustomProgramErrors ? " is-invalid" : "")}
                required
                aria-required="true"
                type="text"
                value={otherInputValue}
                name={`${modelName}[custom_program]`}
                onChange={({ target: { value } }) => setOtherInputValue(value)}
                placeholder="Program name"
              />
              <FormFieldErrorFeedback message={selCustomProgramErrors} />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

SelectProgramForTutor.propTypes = {
  selProgramId: PropTypes.string,
  selProgramIdErrors: PropTypes.string,
}

export default SelectProgramForTutor
