import React from "react"
import { Col, Row } from "react-bootstrap"
import { ListFilters } from "./list_filters"
import { ListActions } from "./list_actions"
import TutorsPageProviders, { TutorCardConfigsProvider } from "../../providers/tutors_page"
import InitialLoadingSpinner from "../../../blocks/InitialLoadingSpinner"
import { TutorsList } from "./tutors_list"
import useViewport from "../../../../hooks/useViewport"
import { className } from "../../../../helpers/className"

export const TutorsContent = () => {
  return (
    <>
      <Row>
        <Col xs="auto">
          <ListFilters />
        </Col>
        <Col xs="auto" className="flex-grow-1" />
        <Col xs="auto">
          <ListActions />
        </Col>
      </Row>
      <Row>
        <Col xs="24">
          <TutorsList />
        </Col>
      </Row>
    </>
  )
}

export const TutorsPage = () => {
  const { isMobileViewport } = useViewport()

  return (
    <TutorsPageProviders>
      <InitialLoadingSpinner>
        <TutorCardConfigsProvider>
          <div className={className("card gap-1", isMobileViewport ? "p-3" : "p-4")}>
            <TutorsContent />
          </div>
        </TutorCardConfigsProvider>
      </InitialLoadingSpinner>
    </TutorsPageProviders>
  )
}
