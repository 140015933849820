import Dropdown from "../../../blocks/Dropdown"
import { Field, useForm } from "react-final-form"
import React, { useCallback, useContext, useState } from "react"
import { tutorsAutocomplete } from "../../configurations/domain"
import { DataContext, DataContextActions, QueryContext } from "../../../../contexts"

export const TutorField = ({ name }) => {
  const { getHookState } = useContext(QueryContext)
  const { tutors } = useContext(DataContext)
  const { updateState } = useContext(DataContextActions)
  const { request } = getHookState(tutorsAutocomplete)
  const [searchString, setSearchString] = useState("")
  const [isSearching, setIsSearching] = useState(false)
  const { mutators } = useForm()

  const search = useCallback(
    searchValue => {
      setSearchString(searchValue)
      if (searchValue.length === 0) {
        updateState("tutors", [])
        return
      }
      if (searchValue.length > 2) {
        setIsSearching(true)
        request({
          params: { q: searchValue },
          onComplete: () => setIsSearching(false),
        })
      }
    },
    [request, updateState]
  )

  const onFieldChange = onChange => event => {
    onChange(event)
    const rate = event.target?.value?.rate
    if (mutators?.setFieldData && typeof rate !== "undefined") mutators.setFieldData("rate", parseFloat(rate))
  }

  return (
    <Field type="text" name={name}>
      {({ input, meta }) => (
        <Dropdown
          onChange={onFieldChange(input.onChange)}
          selected={input.value}
          placeholder="Select tutor"
          error={meta.touched ? meta.error : null}
          buildLabel={option => option.name || option.title}
          buildValue={JSON.stringify}
          buildOption={option => option.title}
          onSearch={e => search(e.target.value)}
          onSearchReset={() => setSearchString("")}
          options={searchString && searchString.length > 2 && !isSearching ? tutors : undefined}
          loading={isSearching}
        />
      )}
    </Field>
  )
}
