import React, { useContext, useMemo } from "react"
import { OpeningContext } from "./context"
import { GradeSelect, ProgramSelect, StrugglesWithSelect, LearnDifference, LocationSelect } from "../../common"

const Step2Configuration = () => {
  const { opening, setOpening, options, tutorApplications } = useContext(OpeningContext)

  const disabled = useMemo(() => tutorApplications.some(({ group }) => group), [tutorApplications])

  return (
    <div className="p-4">
      {!disabled && <div className="mb-3">Pick items below to configure the session</div>}

      <GradeSelect disabled={disabled} opening={opening} setOpening={setOpening} options={options} />
      <ProgramSelect disabled={disabled} opening={opening} setOpening={setOpening} options={options} />
      <StrugglesWithSelect disabled={disabled} opening={opening} setOpening={setOpening} options={options} />
      <LearnDifference disabled={disabled} opening={opening} setOpening={setOpening} options={options} />
      <LocationSelect disabled={disabled} opening={opening} setOpening={setOpening} options={options} />
    </div>
  )
}

export default Step2Configuration
