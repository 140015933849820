import React, { useMemo, useState } from "react"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import FormFields from "../programs_and_trainings/FormFields"
import { putAndRedirect } from "../../../common/apiClient"
import PendingChanges from "./PendingChanges"
import { filter, isEmpty } from "lodash/fp"

const getInitialValues = user => ({
  program_ids: user.programs.map(({ id }) => id),
  disabilities: isEmpty(user.profile.disabilities) ? [] : user.profile.disabilities.map(({ name }) => name),
  documents: user.profile.certificates.map(({ id, description, training, document }) => ({
    id,
    description,
    training,
    filename: document && document.filename,
  })),
})

const isDraft = ({ review_status }) => review_status === "draft"

const ProgramsAndTrainings = ({ dataSources }) => {
  const initialValues = useMemo(() => getInitialValues(dataSources.user), [dataSources.user])

  const [loadingFiles, setLoadingFiles] = useState({})
  const isLoading = _.findKey(loadingFiles)

  const onSubmit = values => {
    putAndRedirect(dataSources.urls.save, dataSources.urls.next, values)
  }

  const programsToShow = useMemo(() => filter(isDraft)(dataSources.user.programs), [dataSources])
  const certificatesToShow = useMemo(() => filter(isDraft)(dataSources.user.profile.certificates), [dataSources])
  const disabilitiesToShow = useMemo(() => filter(isDraft)(dataSources.user.profile.disabilities), [dataSources])

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} mutators={{ ...arrayMutators }}>
      {({ form, handleSubmit, submitting, errors }) => (
        <form onSubmit={handleSubmit} className="container">
          <div className="row">
            <PendingChanges
              dataSources={dataSources}
              programsToShow={programsToShow}
              certificatesToShow={certificatesToShow}
              disabilitiesToShow={disabilitiesToShow}
            />
          </div>

          <div className="row">
            <div className="col-lg-18">
              {!isEmpty(programsToShow) || !isEmpty(certificatesToShow) || !isEmpty(disabilitiesToShow) ? (
                <h4 className="mt-5 mb-3">Approved</h4>
              ) : null}
              <FormFields
                form={form}
                dataSources={dataSources}
                admin={true}
                loadingFiles={loadingFiles}
                setLoadingFiles={setLoadingFiles}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-primary mt-4"
            disabled={isLoading || submitting || Object.keys(errors).length > 0}
          >
            Next
          </button>
        </form>
      )}
    </Form>
  )
}
export default ProgramsAndTrainings
