import "bootstrap-datepicker"
import "../components/bootstrap-timepicker"
import moment from "moment"

document.addEventListener("turbolinks:load", () => {
  const dateInputs = $(".datepicker-week")

  const getMondayFromRange = date => {
    const val = moment(date.split(" - ")[0], "MM-DD-YYYY").startOf("isoWeek").add(22, "hours").toDate()
    return val
  }

  const getWeekLabel = date => {
    if (!date) {
      return null
    }
    const firstDate = moment(date, "YYYY-MM-DD").startOf("isoWeek").format("MM/DD/YYYY")
    const lastDate = moment(date, "YYYY-MM-DD").endOf("isoWeek").format("MM/DD/YYYY")
    return firstDate + " - " + lastDate
  }

  // if (!md.mobile()) {
  dateInputs.each((idx, item) => {
    let jItem = $(item)
    jItem.addClass("input-datepicker").attr("type", "text")

    const toVal = () => {
      if (item.value) {
        // const val = moment(item.value).format('YYYY-MM-DD')
        hiddenInput.val(item.value)
      }
    }
    const hiddenInput = $(item).clone().attr("type", "hidden").removeAttr("class").removeAttr("id")

    $(item).after(hiddenInput).removeAttr("name")

    toVal()

    jItem
      .datepicker({
        autoclose: true,
        maxViewMode: 0,
        weekStart: 1,
        orientation: "bottom auto",
        todayBtn: "linked",
        format: {
          toDisplay: function (date) {
            const val = getWeekLabel(date)
            return val
          },
          toValue: date => getMondayFromRange(date),
        },
      })
      .on("show", function () {
        $(".datepicker").addClass("-week")
      })
      .on("changeDate", function (e) {
        if (jItem.data("updating") === true) {
          return
        }
        jItem.data("updating", true)
        const monday = moment(e.date).startOf("isoWeek").toDate()
        $(this).datepicker("clearDate").datepicker("setDates", monday)
        jItem.data("updating", false)
        // hiddenInput.val(moment(monday).format('YYYY-MM-DD'))
        hiddenInput.val(getWeekLabel(monday))
      })
  })

  // }
})
