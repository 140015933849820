import { showErrors } from "@/common/alerter"
import apiClient from "@/common/apiClient"
import { useCallback, useEffect, useRef, useState } from "react"

export const useGetUpcomingSession = ({ tutorId }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  // Ref to track if the component is mounted
  const isMounted = useRef(true)

  const fetchUpcomingSession = useCallback(async () => {
    try {
      if (tutorId) {
        const response = await apiClient.get(`/api/v2/tutors/${tutorId}/upcoming_session`)
        if (isMounted.current) {
          // Only update state if mounted
          setData(response.data || "")
        }
      }
    } catch (e) {
      if (isMounted.current) {
        // Only show errors if mounted
        showErrors(e)
      }
    } finally {
      if (isMounted.current) {
        // Only update loading state if mounted
        setLoading(false)
      }
    }
  }, [tutorId])

  useEffect(() => {
    if (tutorId) {
      fetchUpcomingSession()
    }

    // Cleanup function to set isMounted to false when unmounting
    return () => {
      isMounted.current = false
    }
  }, [fetchUpcomingSession, tutorId])

  return { loading, data }
}
