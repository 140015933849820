import { useCallback, useEffect, useMemo, useReducer, useRef } from "react"

const defaultAnimationTimeout = 250
const reducer = (state, data) => ({ ...state, ...data })

export const useDialogState = ({ open = false, data = null, animationTimeout = defaultAnimationTimeout } = {}) => {
  const timerRef = useRef()

  const [state, dispatch] = useReducer(reducer, { open, data, closing: false })

  const openDialog = useCallback((data = null, name) => dispatch({ open: name || true, data }), [])

  const closeDialog = useCallback(() => {
    dispatch({ open: false, closing: true })
  }, [])

  useEffect(() => {
    clearTimeout(timerRef.current)
    if (!state.closing) timerRef.current = setTimeout(() => dispatch({ data: null, closing: false }), animationTimeout)
  }, [animationTimeout, state.closing])

  return useMemo(
    () => ({ dialogState: { open: state.open, data: state.data }, openDialog, closeDialog }),
    [closeDialog, openDialog, state]
  )
}
