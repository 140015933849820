import React from "react"
import arrayMutators from "final-form-arrays"
import { FieldArray } from "react-final-form-arrays"
import Button from "react-bootstrap/Button"
import { Form } from "react-final-form"
import { isEmpty } from "lodash/fp"

import Disability from "./Disability"
import { postAndRedirect } from "../../../common/apiClient"
import { getLabelByValue } from "../../../helpers/presentational"
import { notEmptyArray } from "../../../helpers/validators"
import {
  RejectionConfirmationDialog,
  useRejectionDialog,
} from "../rejection_confirmation_dialog/RejectionConfirmationDialog"

const getRequestPayload = ids => ({ entity: { type: "Disability", ids } })

const DisabilitiesToReview = ({ disabilitiesToShow, specialTreatments, urls }) => {
  const { show, dialogProps } = useRejectionDialog()

  const onApprove = values => postAndRedirect(urls.approve, urls.redirect, getRequestPayload(values.disabilities))

  const onReject = values => {
    show(values.disabilities, urls, "Disability", "disability")
  }

  if (isEmpty(disabilitiesToShow)) {
    return null
  }

  return (
    <div className="col-lg-18 pending-area">
      <h5>Learning Differences</h5>

      <Form initialValues={{ disabilities: [] }} onSubmit={() => {}} mutators={{ ...arrayMutators }}>
        {({ values, invalid }) => (
          <>
            <FieldArray name="disabilities" validate={notEmptyArray}>
              {({ fields }) => (
                <>
                  {disabilitiesToShow.map(({ id, name }) => (
                    <Disability key={name} id={id} label={getLabelByValue(specialTreatments)(name)} fields={fields} />
                  ))}
                </>
              )}
            </FieldArray>
            <hr className="thin" />
            <Button
              disabled={invalid}
              variant="outline-primary"
              onClick={() => onApprove(values)}
              data-test="learning-differences-approve"
            >
              Approve selected
            </Button>
            <Button disabled={invalid} variant="secondary" className="reject" onClick={() => onReject(values)}>
              Reject selected
            </Button>
          </>
        )}
      </Form>

      <RejectionConfirmationDialog {...dialogProps} />
    </div>
  )
}

export default DisabilitiesToReview
