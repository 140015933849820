import React from "react"
import { Form } from "react-final-form"
import Chip from "../../../Chip"
import LocationDropdown from "./LocationDropdown"
import { isMeetingInfoValid, isValidOffline } from "../../../../helpers/validators"
import { getOpeningLocationTypeTitle } from "../utils"

const LocationValue = ({ opening, onRemoveLocation, disabled }) => {
  if (!opening.meeting_format) return null
  const title = getOpeningLocationTypeTitle(opening.meeting_format)
  if (!title) return null
  return (
    <Chip disabled={disabled} onClose={onRemoveLocation}>
      {title}
    </Chip>
  )
}

export const LocationSelect = ({ opening, setOpening, options, disabled = false, completed = false }) => {
  const onChange = values => {
    setOpening(values)
  }

  const clear = () => {
    const changedOpening = {
      meeting_format: null,
      meeting_info: "",
    }
    setOpening(changedOpening)
  }

  return (
    <div className="session-configuration_row">
      <div className="session-configuration_control">
        <Form initialValues={opening} onSubmit={onChange}>
          {({ values }) => {
            return (
              <LocationDropdown
                className="dropdown-toggle-no-icon fullwidth"
                completed={
                  completed || (values.meeting_format && values.meeting_format !== "pencil_spaces")
                    ? !!values.meeting_format && (isMeetingInfoValid(values) || isValidOffline(values))
                    : !!values.meeting_format
                }
                getButtonText={() => "Location"}
                disabled={disabled}
                drop="down"
                submitButtonText="OK"
                options={options}
                onSubmit={onChange}
              />
            )
          }}
        </Form>
      </div>
      <div className="session-configuration_values">
        <LocationValue disabled={disabled} opening={opening} onRemoveLocation={clear} />
      </div>
    </div>
  )
}
