import { useCallback, useState } from "react"

export const useFilter = initialFilter => {
  const [filter, setFilter] = useState(initialFilter)

  const handleFilterChange = useCallback(e => {
    const { name, value } = e.target
    setFilter(prev => ({ ...prev, [name]: value }))
  }, [])

  const handleResetFilters = useCallback(() => {
    setFilter(initialFilter)
  }, [initialFilter])

  return { filter, handleFilterChange, handleResetFilters }
}

export const usePagination = (initialPage, initialPageSize) => {
  const [{ page, pageSize }, setPagination] = useState({
    page: initialPage,
    pageSize: initialPageSize,
  })

  const handlePaginationChange = useCallback(({ page, pageSize }) => {
    setPagination({ page, pageSize })
  }, [])

  return { page, pageSize, handlePaginationChange }
}
