import { GaugeContainer, GaugeReferenceArc, GaugeValueArc, GaugeValueText, useGaugeState } from "@mui/x-charts/Gauge"
import React, { useEffect, useRef, useState } from "react"

const GaugePointer = React.memo(() => {
  const { valueAngle, outerRadius, cx, cy } = useGaugeState()

  if (valueAngle === null) {
    return null
  }

  const baseWidth = 13
  const halfBaseWidth = baseWidth / 2
  const targetOffset = 5

  const target = {
    x: cx + (outerRadius - targetOffset) * Math.sin(valueAngle),
    y: cy - (outerRadius - targetOffset) * Math.cos(valueAngle),
  }

  return (
    <g>
      <path
        d={`M ${target.x} ${target.y}
     L ${cx - baseWidth * Math.cos(valueAngle)} ${cy - baseWidth * Math.sin(valueAngle) - halfBaseWidth - targetOffset}
     A ${baseWidth} ${baseWidth} 0 1 0 ${cx + baseWidth * Math.cos(valueAngle)} ${
          cy + baseWidth * Math.sin(valueAngle) - halfBaseWidth - targetOffset
        }
     Z`}
        fill="#3c00f5"
        strokeLinejoin="round"
        stroke="#3c00f5"
        strokeWidth="4"
      />
    </g>
  )
})

GaugePointer.displayName = "GaugePointer"

export default function GaugeComponent({ value, text }) {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const containerRef = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect()
        setDimensions({ width, height: width * 0.8 })
      }
    }

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <div ref={containerRef} style={{ width: "100%", height: "100%", display: "flex", flex: 1 }}>
      <GaugeContainer
        width={dimensions.width}
        height={dimensions.height}
        startAngle={-90}
        endAngle={90}
        innerRadius={dimensions.width / 2.5}
        outerRadius={dimensions.width / 1.8}
        value={value}
        aria-label={`Gauge showing value ${value}`}
        sx={{
          text: {
            transform: "translate(0, 30px)",
            fontSize: "16px",
          },
          "> svg": {
            overflow: "visible",
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 20px",
            aspectRatio: "1 / 1",
          },
        }}
      >
        <GaugeReferenceArc fill="#eeeef5" />
        <GaugeValueArc sx={{ fill: "#4bc9f2" }} />
        <GaugePointer />
        <GaugeValueText
          text={text}
          fontSize={15}
          fontWeight={600}
          style={{ textAnchor: "middle", dominantBaseline: "auto" }}
        />
      </GaugeContainer>
    </div>
  )
}
