import React, { useCallback, useMemo, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Form as FForm, Field } from "react-final-form"
import FormRow from "../../../../components/blocks/FormRow"
import { className } from "../../../../helpers/className"
import { valueOrNull } from "../../../../helpers/formatters"
import { resetSpecificFieldData } from "../../../../helpers/forms"
import useViewport from "../../../../hooks/useViewport"
import DurationField from "../../../blocks/DurationField"
import { DateRangeFields, LocationFields, SchoolField, SubjectField } from "../../../fields"
import { SendReportFields } from "../fields"
import { EducatableField } from "../fields/educatable"
import { FormFieldNames, fieldViewProps } from "./constants"
import { mapFormFieldsToRequestFields } from "./utils"

const defaultLabelProps = { xs: 24, lg: 8, className: "v-2 col-form-label px-0" }

const MultipleValuesSetter = ({ initial, children }) => {
  const [show, setShow] = useState(initial)

  const updateOpen = useCallback(() => setShow(true), [])

  if (show) return children
  return (
    <div className="d-flex align-items-center gap-1 py-2">
      <span>Multiple values</span>
      <button className="btn btn-link" onClick={updateOpen}>
        Set new value
      </button>
    </div>
  )
}

export const ScheduleForm = ({
  initialValues,
  multipleValues,
  validate,
  loading,
  disabled,
  type = "create",
  onSubmit,
  onCancel,
  children,
}) => {
  const { isMobileViewport } = useViewport()

  const submit = useCallback(
    values => {
      onSubmit(mapFormFieldsToRequestFields(values))
    },
    [onSubmit]
  )

  const labelProps = useMemo(
    () => ({
      ...defaultLabelProps,
      className: className(defaultLabelProps.className, disabled && "text-gray"),
    }),
    [disabled]
  )

  const dateLabelProps = useMemo(
    () => ({
      ...labelProps,
      className: className(labelProps.className, !isMobileViewport && "mt-2 pt-4"),
    }),
    [isMobileViewport, labelProps]
  )

  return (
    <FForm
      initialValues={initialValues}
      validate={validate}
      onSubmit={submit}
      mutators={{ resetEducatable: resetSpecificFieldData({ name: FormFieldNames.Educatable, value: null }) }}
    >
      {({ handleSubmit, values, pristine }) => (
        <Row as="form" onSubmit={handleSubmit}>
          <Col xs={24} lg={18} xl={type === "create" ? 12 : 18}>
            {/*  no multiple values for create type */}
            {type === "create" && (
              <>
                <FormRow label="School" {...fieldViewProps} LabelProps={defaultLabelProps}>
                  <SchoolField name={FormFieldNames.School} />
                </FormRow>

                <FormRow label="Student or group" {...fieldViewProps} LabelProps={defaultLabelProps}>
                  <EducatableField
                    disabled={!values[FormFieldNames.School]?.title}
                    school={values[FormFieldNames.School]}
                  />
                </FormRow>
              </>
            )}

            <FormRow label="Subject" {...fieldViewProps} LabelProps={defaultLabelProps}>
              <SubjectField name={FormFieldNames.Subject} multipleValues={multipleValues[FormFieldNames.Subject]} />
              {children}
            </FormRow>

            <FormRow label="Date range" {...fieldViewProps} LabelProps={dateLabelProps}>
              <DateRangeFields
                disabled={disabled}
                startPlaceholder={multipleValues[FormFieldNames.StartOn] ? "Multiple values" : "Date"}
                endPlaceholder={multipleValues[FormFieldNames.EndOn] ? "Multiple values" : "Date"}
                startRequired={true}
                endRequired={true}
              />
            </FormRow>

            <FormRow label="Default location" {...fieldViewProps} LabelProps={labelProps}>
              <MultipleValuesSetter initial={!multipleValues[FormFieldNames.MeetingFormat]}>
                <LocationFields disabled={disabled} multipleValues={multipleValues} />
              </MultipleValuesSetter>
            </FormRow>

            <FormRow {...fieldViewProps} LabelProps={labelProps}>
              {{
                label: (
                  <Form.Label column {...labelProps}>
                    <Row>
                      <Col as="span" xs="auto" lg={24}>
                        Schedule note
                      </Col>
                      <Col as="span" xs="auto" lg={24} className="text-gray font-weight-normal">
                        Visible to tutors
                      </Col>
                    </Row>
                  </Form.Label>
                ),
                col: (
                  <Field type="text" {...fieldViewProps} name={FormFieldNames.ScheduleNote} parse={valueOrNull}>
                    {({ input }) => (
                      <Form.Control
                        as="textarea"
                        {...input}
                        className="v-2"
                        rows="3"
                        disabled={disabled}
                        placeholder={multipleValues[FormFieldNames.ScheduleNote] ? "Multiple values" : ""}
                      />
                    )}
                  </Field>
                ),
              }}
            </FormRow>

            <hr />

            <FormRow label="Maximum sessions" {...fieldViewProps} LabelProps={labelProps}>
              <Row>
                <Col xs={24} lg={7}>
                  <Field type="number" name={FormFieldNames.MaxSessions} parse={valueOrNull}>
                    {({ input, meta }) => (
                      <>
                        <Form.Control
                          {...input}
                          className="v-2"
                          placeholder={multipleValues[FormFieldNames.MaxSessions] ? "Multiple values" : "10"}
                          disabled={disabled}
                          isInvalid={meta.touched && meta.error}
                        />

                        {meta.touched && meta.error ? (
                          <Form.Control.Feedback type="invalid" className="mt-1">
                            {meta.error}
                          </Form.Control.Feedback>
                        ) : null}
                      </>
                    )}
                  </Field>
                </Col>
              </Row>
            </FormRow>

            <FormRow label="Max session duration" {...fieldViewProps} LabelProps={labelProps}>
              <Row>
                <Col xs={24} lg={7}>
                  <Field type="text" name={FormFieldNames.Duration}>
                    {({ input }) => (
                      <DurationField
                        {...input}
                        className="v-2"
                        placeholder={multipleValues[FormFieldNames.Duration] ? "Multiple values" : "Duration"}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </FormRow>

            <FormRow label="School rate" {...fieldViewProps} LabelProps={labelProps}>
              <Row>
                <Col xs={24} lg={7}>
                  <Field type="number" name={FormFieldNames.Rate} parse={valueOrNull}>
                    {({ input, meta }) => (
                      <>
                        <Form.Control
                          {...input}
                          className="v-2"
                          placeholder={multipleValues[FormFieldNames.Rate] ? "Multiple values" : "10"}
                          disabled={disabled}
                          isInvalid={meta.touched && meta.error}
                        />

                        {meta.touched && meta.error ? (
                          <Form.Control.Feedback type="invalid" className="mt-1">
                            {meta.error}
                          </Form.Control.Feedback>
                        ) : null}
                      </>
                    )}
                  </Field>
                </Col>
              </Row>
            </FormRow>

            <FormRow label="Send reports to" {...fieldViewProps} LabelProps={labelProps}>
              <SendReportFields disabled={disabled} multipleValues={multipleValues} />
            </FormRow>

            <FormRow label="Report" {...fieldViewProps} LabelProps={labelProps}>
              <span className={className("py-2 my-1", disabled && "text-gray")}>Weekly</span>
            </FormRow>

            <FormRow label="Status" {...fieldViewProps} LabelProps={labelProps}>
              <span className={className("py-2 my-1", disabled && "text-gray")}>Active</span>
            </FormRow>

            <hr />

            <Row className="align-items-center">
              <Col className="flex-grow-0 mr-3">
                <button className="btn btn-primary" type="submit" disabled={disabled || pristine || loading}>
                  {type === "create" ? "Create" : "Save"}
                </button>
              </Col>

              {onCancel && (
                <Col>
                  <button className="btn btn-link text-black text-no-wrap" type="button" onClick={onCancel}>
                    Cancel
                  </button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </FForm>
  )
}
