import React, { useMemo } from "react"
import { isEmpty, sortBy } from "lodash/fp"
import { DEGREE_ABBREVIATION } from "../../../common/constants"

const DegreesInfo = ({ degrees }) => {
  const mainDegree = useMemo(() => {
    const sortedDegrees = sortBy(({ degree }) => Object.keys(DEGREE_ABBREVIATION).indexOf(degree))(degrees)
    const { degree, description } = sortedDegrees[0] || {}
    return [DEGREE_ABBREVIATION[degree], description].filter(Boolean).join(", ")
  }, [degrees])

  if (isEmpty(degrees)) {
    return null
  }

  return (
    <div className="degrees-info">
      <div className="degrees-info_degree">{mainDegree}</div>
      {degrees.length > 1 && <div className="degrees-info_more-degrees">{`+${degrees.length - 1} more`}</div>}
    </div>
  )
}

export default DegreesInfo
