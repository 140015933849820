import { useState, useEffect } from "react"
import { MEDIA_SM_BREAKPOINT, MEDIA_MD_BREAKPOINT, MEDIA_LG_BREAKPOINT } from "../common/constants"
import ServerSideRendering from "../common/serverSideRendering"

const { window } = ServerSideRendering.wrapWindow()

export default () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return {
    width,
    isPhoneViewport: width < MEDIA_SM_BREAKPOINT,
    isTabletViewport: width >= MEDIA_SM_BREAKPOINT && width < MEDIA_MD_BREAKPOINT,
    isLargeTabletViewport: width >= MEDIA_SM_BREAKPOINT && width < MEDIA_LG_BREAKPOINT,
    isMobileViewport: width < MEDIA_LG_BREAKPOINT,
  }
}
