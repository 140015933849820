import React, { useContext } from "react"
import { CreateScheduleForm } from "../forms"
import { Card, Col, Row } from "react-bootstrap"
import useViewport from "../../../hooks/useViewport"
import { className } from "../../../helpers/className"
import { QueryContext } from "../../../contexts"
import { postSchedule } from "../configurations/domain"
import { useNavigate } from "react-router-dom"
import { NewScheduleProvider } from "../providers/providers"

const NewSchedulePageContent = () => {
  const { isMobileViewport } = useViewport()
  const { getHookState } = useContext(QueryContext)
  const { request, loading } = getHookState(postSchedule)
  const navigate = useNavigate()

  const onSubmit = data => {
    request({
      data: { schedule: data },
      onSuccess: ({ id }) => navigate(`/${id}`),
    })
  }

  return (
    <div className="container p-0 d-flex flex-column flex-grow-1">
      <Row className="school-session">
        <Col className="p-0">
          <div className="container users-navbar_container -white">
            <h2 className="users-navbar_title m-0">Create Schedule</h2>
          </div>
          <div className="container mt-4 px-0">
            <Row className="mx-n2 px-2 mb-4">
              <Col as="main" xs={24}>
                <Card className={className(isMobileViewport ? "px-3 py-4" : "p-5")}>
                  <h5 className="font-weight-semibold mb-4">Schedule settings</h5>
                  <CreateScheduleForm onSubmit={onSubmit} loading={loading} />
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const NewSchedulePage = () => (
  <NewScheduleProvider>
    <NewSchedulePageContent />
  </NewScheduleProvider>
)

export default NewSchedulePage
