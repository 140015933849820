import React from "react"
import { Field } from "react-final-form"
import { FieldArray } from "react-final-form-arrays"
import { RedSmallCloseButton } from "../../SmallCloseButton"
import { required } from "../../../helpers/validators"
import { deleteId } from "../../../helpers/profile_form"
import { requiredClass } from "../../../helpers/profile_form"

const addHandler = (e, fields) => {
  e.preventDefault()
  fields.push({ day: "", from: "", to: "" })
}

const FormFields = ({ dataSources: { user } }) => {
  return (
    <div>
      <div className="row">
        <h4 className="col-sm-24">Calendar</h4>
      </div>

      <div className="form-group row">
        <label className="col-lg-6 col-form-label">Working hours</label>
        <div className="col-lg-18">
          <FieldArray name="profile.working_hours" initialValue={user.profile.working_hours}>
            {({ fields }) => (
              <div>
                {fields.map((name, index) => (
                  <div className="row form-group" key={index}>
                    <div className="col-lg-5">
                      <Field name={`${name}.day`} validate={required}>
                        {({ input, meta }) => (
                          <select {...input} className={`form-control ${requiredClass(meta)}`}>
                            <option key="empty" />
                            <option key="1" value="monday">
                              monday
                            </option>
                            <option key="2" value="tuesday">
                              tuesday
                            </option>
                            <option key="3" value="wednesday">
                              wednesday
                            </option>
                            <option key="4" value="thursday">
                              thursday
                            </option>
                            <option key="5" value="friday">
                              friday
                            </option>
                            <option key="6" value="saturday">
                              saturday
                            </option>
                            <option key="7" value="sunday">
                              sunday
                            </option>
                          </select>
                        )}
                      </Field>
                    </div>
                    <div className="col-lg-5">
                      <Field name={`${name}.from`} validate={required}>
                        {({ input, meta }) => (
                          <input {...input} type="text" className={`form-control ${requiredClass(meta)}`} />
                        )}
                      </Field>
                    </div>
                    <div className="col-lg-5">
                      <Field name={`${name}.to`} validate={required}>
                        {({ input, meta }) => (
                          <input {...input} type="text" className={`form-control ${requiredClass(meta)}`} />
                        )}
                      </Field>
                    </div>

                    <div className="col-lg-2">
                      <RedSmallCloseButton
                        className="float-right"
                        onClose={() => {
                          deleteId(fields, index)
                        }}
                      />
                    </div>
                  </div>
                ))}
                <div className="row">
                  <a href="#" className="col-lg-24" onClick={e => addHandler(e, fields)}>
                    + Add hours
                  </a>
                </div>
              </div>
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  )
}

export default FormFields
