import React, { useState } from "react"
import { Button } from "react-bootstrap"
import apiClient, { showAllErrorsInAlert } from "../../common/apiClient"
import RetryChargeModal from "./RetryChargeModal"

const RetryCharge = ({ retryUrl, session }) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const requestRetry = async () => {
    try {
      setIsLoading(true)
      await apiClient.post(retryUrl)
      setShowModal(true)
    } catch (e) {
      showAllErrorsInAlert(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button variant="link" className="btn-sm btn-link-primary" disabled={isLoading} onClick={requestRetry}>
        Retry
      </Button>
      <RetryChargeModal show={showModal} session={session} />
    </>
  )
}

export default RetryCharge
