import Alerter from "@/common/alerter"
import apiClient from "@/common/apiClient"
import BRTThemeProvider from "@/providers/theme"
import { Assignment, Error } from "@mui/icons-material"
import { Alert, Box, CircularProgress, Container, CssBaseline, Paper, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { allowedOrigins } from "./constants"

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}))

const Header = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const AssignmentIframe = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "70vh",
  border: "none",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}))

export const StudentAssignmentsPage = ({ currentUser, programName, studentName }) => {
  const userRole = currentUser?.role
  const [loading, setLoading] = useState(true)
  const [assignmentLink, setAssignmentLink] = useState(null)
  const [error, setError] = useState(null)
  const id = useRef(window.location.pathname.split("/").pop())
  const iframeRef = useRef(null)

  const fetchAssignmentLink = useCallback(async () => {
    try {
      setLoading(true)
      const endpoint =
        userRole === "student"
          ? `/api/v2/students/assignment_link?student_assignment_id=${id.current}`
          : `/api/v2/student_assignment/${id.current}/assignment_link`
      const response = await apiClient.get(endpoint)
      setAssignmentLink(response?.data?.assignment_link)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [userRole])

  const handleSubmitTask = useCallback(
    async event => {
      if (!allowedOrigins.has(event.origin) || event.data !== "submit-task") {
        return
      }

      try {
        const endpoint =
          userRole === "student"
            ? "/api/v2/students/score_assignment"
            : `/api/v2/student_assignment/${id.current}/score_assessment`
        const data = userRole === "student" ? { student_assignment_id: id.current } : {}
        await apiClient.post(endpoint, data)
        Alerter.success("Assignment submitted successfully")
      } catch (error) {
        setError(error)
        Alerter.error("Error submitting assignment")
      }
    },
    [userRole]
  )

  useEffect(() => {
    fetchAssignmentLink()
  }, [fetchAssignmentLink])

  useEffect(() => {
    window.addEventListener("message", handleSubmitTask)
    return () => {
      window.removeEventListener("message", handleSubmitTask)
    }
  }, [handleSubmitTask])

  const renderContent = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      )
    }

    if (error) {
      return (
        <Alert severity="error" icon={<Error />}>
          {error.message ? `Error loading assignment: ${error.message}` : "An unexpected error occurred."}
        </Alert>
      )
    }

    if (!assignmentLink) {
      return (
        <Alert severity="info" icon={<Assignment />}>
          No Assignments found
        </Alert>
      )
    }

    return (
      <>
        <Header>
          <Typography variant="h4" component="h1" gutterBottom>
            {studentName}
          </Typography>
          <Typography variant="h6" component="h2" color="textSecondary">
            {programName}
          </Typography>
        </Header>
        <AssignmentIframe
          ref={iframeRef}
          src={assignmentLink}
          title="Ann Edu Activities"
          aria-label="Assignment content"
        />
      </>
    )
  }

  return (
    <BRTThemeProvider>
      <CssBaseline />
      <Container maxWidth="xl" disableGutters sx={{ mb: 2 }}>
        <ContentPaper elevation={3}>{renderContent()}</ContentPaper>
      </Container>
    </BRTThemeProvider>
  )
}

export default StudentAssignmentsPage
