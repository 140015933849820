import React, { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { partition } from "lodash"
import { Group } from "../group"
import AsyncAutocompleteField from "../../../AsyncAutocompleteField"

const GroupTutoring = ({ onSelectGroup, groups, selectedGroupIds, onGroupRemove, tutorList = [] }) => {
  const change = event => {
    onSelectGroup(event.target.value)
  }

  const [notSelectedGroups, selectedGroups] = partition(groups, g => selectedGroupIds.indexOf(g.id) === -1)

  return (
    <>
      <Form.Group as={Col} lg={8} className="mb-3">
        <select className="form-control select" onChange={change}>
          <option key="empty">Select group to add</option>
          {notSelectedGroups.map(g => (
            <option key={g.id} value={g.id}>
              {g.name}
            </option>
          ))}
        </select>
      </Form.Group>

      {selectedGroups.map(g => (
        <Group key={g.id} group={g} tutorList={tutorList} onRemove={onGroupRemove} initialOpenState={true} />
      ))}
    </>
  )
}

const IndividualTutoring = ({ selected, onSelect, schoolId }) => {
  const [touched, setTouched] = useState(false)
  const [error, setError] = useState("")
  const select = student => {
    if (student === null) {
      if (touched || selected !== null) setError("Student selection required")
    } else {
      setError("")
    }
    setTouched(true)
    onSelect(student)
  }

  return (
    <Form.Group as={Col} lg={8} className="mb-3">
      <AsyncAutocompleteField
        source="/api/admin/autocomplete/students"
        onSelect={select}
        validationErrors={error}
        selected={selected}
        placeholder="Select student to add"
        externalParams={{ school_id: schoolId }}
      />
    </Form.Group>
  )
}

const LessonType = ({ setGroup, group }) => {
  return (
    <>
      <Form.Group
        as={Col}
        lg={18}
        className="d-flex align-items-start align-items-md-center mb-3 flex-column flex-md-row"
      >
        <Form.Check
          id="group"
          type="radio"
          checked={group}
          onChange={() => {
            setGroup(true)
          }}
          className="text-nowrap mr-5"
          label="Group tutoring"
          custom
        />
        <Form.Check
          id="individual"
          type="radio"
          checked={!group}
          onChange={() => {
            setGroup(false)
          }}
          className="text-nowrap"
          label="Individual tutoring"
          custom
        />
      </Form.Group>
    </>
  )
}

const StepTitle = ({ selected, schools, setSchool }) => {
  const [change, setChange] = useState(false)
  // const startChange = () => {
  //   setChange(true)
  // }
  const cancelChange = () => {
    setChange(false)
  }
  const selectSchool = ({ target }) => {
    const { value } = target
    const selected = schools.find(el => el.value === value)
    setSchool(selected)
    cancelChange()
  }
  return (
    <Form.Group as={Col} className="mb-3">
      <Row>
        {change ? (
          <>
            <Col xs={8}>
              <select className="form-control select" value={selected ? selected.value : null} onChange={selectSchool}>
                <option key="empty">Select school to add</option>
                {schools.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </Col>
            <Col xs={4}>
              <button className="btn btn-warning-light btn-sm" onClick={cancelChange}>
                Cancel
              </button>
            </Col>
          </>
        ) : (
          <>
            {selected && (
              <Col xs={8}>
                <h4 className="mb-0 mt-2">{selected.label}</h4>
              </Col>
            )}
            {/* <Col xs={4}>
              <button
                className="btn btn-warning-light btn-sm"
                onClick={startChange}>
                  Change
              </button>
            </Col> */}
          </>
        )}
      </Row>
    </Form.Group>
  )
}

export const Students = ({ opening, setOpening, groups, schools, tutorList = [], canChangeType = true }) => {
  const setGroup = group => {
    setOpening({ group })
  }

  const onSelectGroup = groupId => {
    const { groupIds = [] } = opening
    groupIds.push(parseInt(groupId))
    setOpening({ groupIds, student: null })
  }

  const onGroupRemove = id => {
    const { groupIds = [] } = opening
    const list = groupIds.filter(groupId => groupId !== id)
    setOpening({ groupIds: list })
  }

  const selectStudent = student => {
    setOpening({ student, groupIds: [] })
  }

  const selectSchool = school => {
    setOpening({ school })
  }

  const { school, group, groupIds, student } = opening

  return (
    <div className="p-4">
      <StepTitle selected={school} schools={schools} setSchool={selectSchool} />
      {canChangeType && (
        <Row>
          <LessonType group={group} setGroup={setGroup} />
        </Row>
      )}
      <Row xs>
        {group ? (
          <GroupTutoring
            groups={groups}
            selectedGroupIds={groupIds}
            onSelectGroup={onSelectGroup}
            tutorList={tutorList}
            onGroupRemove={canChangeType ? onGroupRemove : void 0}
          />
        ) : (
          <IndividualTutoring selected={student} onSelect={selectStudent} schoolId={opening.school.value} />
        )}
      </Row>
    </div>
  )
}
