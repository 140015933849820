import React, { useCallback, useState } from "react"
import useViewport from "../../../../hooks/useViewport"

const ActionsList = ({ onPickTutor, onEditSessions, onRemove, onEditConnections, onEditSchedules }) => {
  return (
    <div className="opening-students__list-actions">
      <button className="btn btn-link text-decoration-none" onClick={onPickTutor}>
        Pick tutor
      </button>
      <button className="btn btn-link text-decoration-none" onClick={onEditSessions}>
        Edit sessions
      </button>
      <button className="btn btn-link text-decoration-none" onClick={onEditSchedules}>
        Edit Schedule settings
      </button>
      <button className="btn btn-link text-decoration-none" onClick={onEditConnections}>
        Edit connections
      </button>
      <button className="btn btn-link text-decoration-none text-danger" onClick={onRemove}>
        Remove from opening
      </button>
    </div>
  )
}

export const StudentListActions = ({
  selected,
  onCancelSelection,
  onPickTutor,
  onEditSessions,
  onRemove,
  onEditConnections,
  onEditSchedules,
}) => {
  const { isMobileViewport } = useViewport()
  const [open, setOpen] = useState(false)
  const Actions = useCallback(
    () => (
      <ActionsList
        onPickTutor={onPickTutor}
        onEditSessions={onEditSessions}
        onRemove={onRemove}
        onEditConnections={onEditConnections}
        onEditSchedules={onEditSchedules}
      />
    ),
    [onEditSessions, onPickTutor, onRemove, onEditConnections, onEditSchedules]
  )
  return (
    <div className="opening-students__list-actions-wrapper">
      <div className="d-flex align-items-center justify-content-between flex-grow-1 flex-shrink-0">
        <div className="d-flex align-items-center gap-2">
          <span className="font-weight-semibold text-black flex-shrink-0">
            {selected.length} group{selected.length > 1 ? "s" : ""} selected
          </span>
          {isMobileViewport ? (
            <button className="btn btn-link text-decoration-none" onClick={() => setOpen(!open)}>
              {open ? "Hide" : "Show"} actions
            </button>
          ) : (
            <Actions />
          )}
        </div>
        <button className="btn btn-link text-decoration-none text-black-50" onClick={onCancelSelection}>
          Cancel
        </button>
      </div>
      {isMobileViewport && open && <Actions />}
    </div>
  )
}
