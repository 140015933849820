import React, { useMemo, useCallback } from "react"
import { Link, generatePath } from "react-router-dom"
import { EducatableTypes, ScheduleActions } from "../../constants"
import { DashboardRow } from "./dashboard_row"
import { checkActionExist } from "../../helpers"
import { RoutesPatterns } from "../../configurations/routing"

export const Students = ({ schedule }) => {
  const canChange = useMemo(() => checkActionExist(schedule.available_actions, ScheduleActions.Update), [schedule])

  const buildLink = useCallback(pattern => generatePath(pattern, { scheduleId: schedule.id }), [schedule.id])
  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h6 className="font-weight-semibold mb-0 flex-grow-1">Students</h6>

        {canChange ? (
          <Link to={buildLink(RoutesPatterns.students)} className="btn btn-link">
            Edit
          </Link>
        ) : null}
      </div>

      {schedule.educatable.type === EducatableTypes.Group ? (
        <DashboardRow title="Group name">
          <a href={"/admin/opening_groups/" + schedule.educatable.real_id} target="_blank" rel="noreferrer">
            {schedule.educatable.name}
          </a>
        </DashboardRow>
      ) : null}

      <DashboardRow title={schedule.educatable.type === EducatableTypes.Group ? "Students" : "Student"}>
        {schedule.educatable.type === EducatableTypes.Group
          ? schedule.educatable.students.map(student => (
              <div key={student.id} className="mb-3">
                {student.first_name + " " + student.last_name}
              </div>
            ))
          : schedule.educatable.name}
      </DashboardRow>
    </>
  )
}
