import React from "react"
import moment from "moment"

import { INTERVIEW_DURATION } from "../../../common/constants"

const InterviewSlot = ({ start, tag: Tag = "h6", timePosition = "end" }) => {
  const momentStart = moment.parseZone(start)
  const momentEnd = moment.parseZone(start).add(INTERVIEW_DURATION, "m")

  if (!momentStart.isValid || !momentEnd.isValid()) {
    return null
  }

  if (timePosition === "start") {
    return (
      <Tag>
        <span className="font-weight-normal">
          {momentStart.format("h:mm a")} - {momentEnd.format("h:mm a")}
        </span>
        <span className="font-weight-medium pl-2"> {momentStart.format("dddd, MMMM D")}</span>
      </Tag>
    )
  }

  return (
    <Tag>
      <span className="font-weight-medium">{momentStart.format("dddd, MMMM D")} </span>
      <span className="font-weight-normal pl-2">
        {momentStart.format("h:mm a")} - {momentEnd.format("h:mm a")}
      </span>
    </Tag>
  )
}

export default InterviewSlot
