import React, { useCallback, useContext } from "react"
import { Form } from "react-final-form"
import { DataContext, QueryContext } from "../../../contexts"
import FormRow from "../../blocks/FormRow"
import { EducatableField } from "../forms/fields/educatable"
import { EducatableTypes } from "../constants"
import { getSchedule, updateSchedule } from "../configurations/domain"
import { Entities } from "../../../constants"
import Dialog from "../../blocks/Dialog"
import { SchoolField } from "../../fields"
import { StudentsDataProvider } from "../providers/students"

export const ChangeEducatableDialog = ({ show, onHide }) => {
  const { schedule } = useContext(DataContext)
  const { getHookState } = useContext(QueryContext)
  const { request } = getHookState(updateSchedule)

  const submit = useCallback(
    values => {
      request({
        entitiesIds: {
          [Entities.Schedules]: schedule.id,
        },
        data: {
          schedule: {
            educatable_id: parseInt(values.educatable.value || values.educatable.id, 10),
          },
        },
        onSuccess: onHide,
        next: [getSchedule],
      })
    },
    [schedule, request, onHide]
  )

  return (
    <Dialog title="Change group/student" open={show} onClose={onHide} className="change_educatable_dialog">
      <StudentsDataProvider>
        <Form
          initialValues={{
            school: schedule.school,
            educatable: schedule.educatable,
          }}
          onSubmit={submit}
        >
          {({ handleSubmit, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <FormRow label="School" ColProps={{ lg: 18 }}>
                <SchoolField disabled />
              </FormRow>

              <FormRow label="Group/student" ColProps={{ lg: 18 }} LabelProps={{ className: "v-2" }}>
                <EducatableField showGroupStudents={false} school={values.school} />
              </FormRow>

              <div className="btrust-dialog__actions">
                <button className="btn btn-primary text-no-wrap" type="submit" disabled={pristine}>
                  Change {schedule.educatable.type === EducatableTypes.Group ? "group" : "student"}
                </button>
                <button className="btn btn-outline-primary text-no-wrap" type="button" onClick={onHide}>
                  Cancel
                </button>
              </div>
            </form>
          )}
        </Form>
      </StudentsDataProvider>
    </Dialog>
  )
}
