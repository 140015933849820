import React, { useState } from "react"
import { Editor as DraftEditor } from "react-draft-wysiwyg"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import htmlToDraft from "html-to-draftjs"
import draftToHtml from "draftjs-to-html"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const config = {
  options: ["inline", "blockType", "list", "textAlign", "link", "history"],
  inline: {
    options: ["bold", "italic", "underline"],
  },
  blockType: {
    options: ["Normal", "H3"],
  },
}

export const editorStateToHtml = editorState => {
  const state = convertToRaw(editorState.getCurrentContent())
  if (state.blocks.length === 1 && state.blocks[0].text === "") return ""
  return draftToHtml(state)
}

const Editor = ({ initialContentState, onChange = () => {} }) => {
  const [editorState, setEditorState] = useState(() => {
    const contentBlock = htmlToDraft(initialContentState)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      return EditorState.createWithContent(contentState)
    }
    return EditorState.createEmpty()
  })

  return (
    <DraftEditor
      editorState={editorState}
      onEditorStateChange={setEditorState}
      onChange={() => onChange(editorState)}
      toolbar={config}
      wrapperClassName="dashboard_text-editor-wrapper"
      editorClassName="dashboard_text-editor-text"
    />
  )
}

export default Editor
