import { useState, useCallback, useEffect } from "react"
import Alerter from "../../../../common/alerter"
import apiClient from "../../../../common/apiClient"

export const useSaveOpeningRequest = ({ id, onSuccess }) => {
  const [loading, setLoading] = useState(false)

  const request = data => {
    setLoading(true)
    apiClient
      .put(`/admin/openings/${id}`, data)
      .then(() => {
        Alerter.success("Opening successfully created")
        if (onSuccess) onSuccess()
      })
      .catch(({ response: { data }, message }) => {
        // eslint-disable-next-line no-console
        console.error(message)
        for (const key of Object.keys(data.errors)) {
          const value = data.errors[key]
          for (const error of value) {
            Alerter.error(`${key}: ${error}`)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { loading, request }
}

export const useStatusOpening = ({ id, status, onSuccess }) => {
  const [loading, setLoading] = useState(false)

  const request = async () => {
    try {
      setLoading(true)
      await apiClient.put(`/admin/openings/${id}/${status}`)
      Alerter.success(`Opening: ${status} successful`)
      if (onSuccess) onSuccess()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      Alerter.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return { loading, request }
}

export const useOpeningGroups = schoolId => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const request = useCallback(async () => {
    const url = `/api/admin/opening_groups.json?school_id=${schoolId}`
    try {
      setLoading(true)
      const { data: result } = await apiClient.get(url)
      setData(result)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      Alerter.error(e?.message)
    } finally {
      setLoading(false)
    }
  }, [schoolId])

  useEffect(() => {
    if (!schoolId) return
    request()
  }, [schoolId, request])

  return { loading, data }
}
