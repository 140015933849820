import React from "react"
import { Dropdown } from "react-bootstrap"
import { getLabelByValue } from "../../helpers/presentational"

const options = [
  {
    value: null,
    label: "Recommended",
  },
  {
    value: "price_asc",
    label: "Price: low to high",
  },
  {
    value: "price_desc",
    label: "Price: high to low",
  },
]

const TutorsListSortDropdown = ({ value, onChange }) => {
  const normalizedValue = value || null

  return (
    <Dropdown>
      Sort by:&nbsp;
      <Dropdown.Toggle variant="" className="btn-link btn-link-primary">
        {getLabelByValue(options)(normalizedValue)}
      </Dropdown.Toggle>
      <Dropdown.Menu className="-fullwidth">
        {options.map(option => (
          <Dropdown.Item
            key={option.value}
            active={option.value === normalizedValue}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TutorsListSortDropdown
