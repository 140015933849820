import React, { useState, useMemo } from "react"
import { Button } from "react-bootstrap"

import InterviewModal from "./InterviewModal"
import InterviewSuccessModal from "./InterviewSuccessModal"
import apiClient, { replaceId, showAllErrorsInAlert } from "../../../common/apiClient"
import { serializeTimeZones } from "../../../helpers/serializers"
import { getLabelByValue } from "../../../helpers/presentational"
import useViewport from "../../../hooks/useViewport"

const ScheduleInterview = props => {
  const [showInterviewModal, setShowInterviewModal] = useState(false)
  const [showInterviewSuccessModal, setShowInterviewSuccessModal] = useState(false)
  const [newInterviewUrl, setNewInterviewUrl] = useState("")
  const [currentInterview, setCurrentInterview] = useState(props.dataSources.interview)

  const { isMobileViewport } = useViewport()

  const tutorTimeZone = useMemo(() => {
    const timeZones = serializeTimeZones(props.dataSources.timeZones)
    return getLabelByValue(timeZones)(props.dataSources.tutor.time_zone)
  }, [props.dataSources])

  const sendInterview = async values => {
    try {
      const { data } = await apiClient.post(props.urls.save, values)
      setNewInterviewUrl(`${props.urls.path_to_interviews}?id=${data.id}`)
      setShowInterviewModal(false)
      setShowInterviewSuccessModal(true)
      setCurrentInterview(data)
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const editInterview = async values => {
    try {
      const { data } = await apiClient.put(replaceId(props.urls.edit, currentInterview.id), values)
      setShowInterviewModal(false)
      setCurrentInterview(data)
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  return (
    <>
      <Button className="sub-nav_item mr-3" onClick={() => setShowInterviewModal(true)}>
        {isMobileViewport
          ? "Request interview"
          : currentInterview
          ? "Edit interview request"
          : "Schedule a free interview"}
      </Button>

      <InterviewModal
        {...props}
        dataSources={{ ...props.dataSources, interview: currentInterview }}
        tutorTimeZone={tutorTimeZone}
        show={showInterviewModal}
        handleClose={() => setShowInterviewModal(false)}
        onSubmit={values => (currentInterview ? editInterview(values) : sendInterview(values))}
      />

      <InterviewSuccessModal
        show={showInterviewSuccessModal}
        url={newInterviewUrl}
        handleClose={() => setShowInterviewSuccessModal(false)}
      />
    </>
  )
}

export default ScheduleInterview
