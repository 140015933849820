import React from "react"
import { Modal, Button } from "react-bootstrap"

const RetryChargeModal = ({ show, session }) => {
  const reload = () => {
    document.location.reload()
  }

  return (
    <Modal show={show} size="md" onHide={reload} centered>
      <Modal.Title className="px-4 pt-4 text-center">
        <h6>Retry charge</h6>
      </Modal.Title>
      <Modal.Body className="p-4">
        <p className="pb-2">
          App is retrying to charge CC of {session.parentName} for ${session.sum} for session from {session.date}.
        </p>
        <p className="pb-2">This process may take several seconds, please refresh page to check payment status</p>

        <div className="mt-4 text-center ">
          <Button variant="outline-primary" onClick={reload}>
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RetryChargeModal
