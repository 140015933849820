import React from "react"
import { Badge as BootstrapBadge } from "react-bootstrap"
import { TutorAvatar } from "../../opening_module/pages/tutors/tutor_avatar"
import { formatConnectionDates } from "../helpers"
import { ReactComponent as DollarIcon } from "../../../assets/images/dollar.svg"
import { formatMoney } from "../../../helpers/formatters"
import { ReactComponent as FlashIcon } from "../../../assets/images/flash.svg"
import { DEGREE_ABBREVIATION } from "../../../common/constants"
import { ConnectionLevels } from "../constants"
import useViewport from "../../../hooks/useViewport"
import { className } from "../../../helpers/className"

export const TutorCard = ({ connection, onEditClick }) => {
  const { isPhoneViewport, isTabletViewport } = useViewport()
  const { tutor } = connection
  const isMobile = isPhoneViewport || isTabletViewport

  return (
    <div className={className(`d-flex flex-row gap-2 border-top pt-4`, isMobile && "flex-column")}>
      <TutorAvatar url={tutor.avatar_url} />
      <div className="d-flex flex-grow-1 flex-column">
        <div className="d-flex align-items-baseline justify-content-between">
          <h5 className="m-0 flex-grow-1">
            {tutor.first_name} {tutor.last_name}
          </h5>
          {onEditClick && (
            <button className="btn btn-link" onClick={() => onEditClick()}>
              Edit
            </button>
          )}
        </div>

        <div className="d-flex flex-wrap gap-05 my-2">
          {/* <Badge variant="success">Active</Badge> */}
          {/* <Badge variant="warning">Connection terminated</Badge> */}
          <Badge>{formatConnectionDates(connection)}</Badge>
          <Badge>{connection.position_level === ConnectionLevels.Main ? "Main tutor" : "Substitute"}</Badge>
        </div>

        <div className="font-size-medium font-weight-medium">
          {tutor.profile.degrees.map(degree => (
            <span key={degree.degree + degree.college}>
              {DEGREE_ABBREVIATION[degree.degree]}, {degree.college}
            </span>
          ))}
        </div>

        <div className="d-flex gap-2 mt-3 ml-n1 font-size-medium font-weight-semibold">
          <IconTag iconColor="primary" icon={DollarIcon} text={`Rate: ${formatMoney(connection.rate)}/hr`} />

          <IconTag
            iconColor="secondary"
            icon={FlashIcon}
            text={
              connection.max_paid_noshows === 0
                ? "No paid no shows"
                : `Used paid no shows: ${connection.fact_paid_noshows} of ${connection.max_paid_noshows}`
            }
          />
        </div>
      </div>
    </div>
  )
}

const IconTag = ({ icon: Icon, text, iconColor }) => (
  <div className="d-flex gap-025">
    <div className={`icon-24-px text-${iconColor}`}>
      <Icon />
    </div>
    {text}
  </div>
)

const Badge = ({ children, variant = "default" }) => (
  <BootstrapBadge
    className={`default-badge -${variant} text-gray-chat mе-2 font-weight-bold text-uppercase font-size-extra-extra-small`}
  >
    {children}
  </BootstrapBadge>
)
