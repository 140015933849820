import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

import Filter from "./Filter"
import Chat from "./Chat"

import useViewport from "../../hooks/useViewport"

const Chats = ({ chats, showChatFilter, onSelect, onFilter, isParentLoading, selectedChatId }) => {
  const [selectedChat, setSelectedChat] = useState(0)
  const { isMobileViewport } = useViewport()

  const onSelectChat = value => {
    setSelectedChat(value)
    onSelect(value)
  }

  useEffect(() => {
    if (chats.length > 0 && !isMobileViewport) {
      if (chats.find(chat => chat.id === selectedChatId)) {
        onSelectChat(selectedChatId)
      } else if (selectedChat === 0) {
        onSelectChat(chats[0].id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatId])

  return (
    <Container className="py-2 px-lg-2 px-0 panel-wrapper">
      {showChatFilter && <Filter onChange={onFilter} />}
      {!isParentLoading &&
        chats.map((chat, idx) => (
          <Chat
            key={`${chat.id}_${idx}`}
            chat={chat}
            isSelected={chat.id === selectedChat}
            onSelect={onSelectChat}
            showBothParties={showChatFilter}
          />
        ))}
    </Container>
  )
}

export default Chats
