import moment from "moment"
import { isMeetingInfoValid, isValidOffline } from "../../../../helpers/validators"

const steps = [
  {
    key: "students",
    title: "Students",
    validator: opening => {
      let result = true
      const { group, student, groupIds } = opening
      if (group) result = groupIds.length > 0
      else result = student !== null
      return result
    },
  },
  {
    key: "configuration",
    title: "Configuration",
    validator: opening => {
      const { meeting_format, grade, defaultProgramId, defaultProgramName, custom_program } = opening
      const location =
        meeting_format && meeting_format !== "pencil_spaces"
          ? !!meeting_format && (isMeetingInfoValid(opening) || isValidOffline(opening))
          : !!meeting_format
      const program_valid = defaultProgramName === "Other" ? custom_program?.length > 0 : defaultProgramId
      return Boolean(location && grade && program_valid)
    },
  },
  {
    key: "schedule",
    title: "Schedule",
    validator: opening => {
      const {
        // started_at
        // ended_at
        recurrence,
        duration,
        schedule,
        schedule_flexible,
        schedule_preference,
      } = opening
      if (schedule_flexible) {
        // if (!started_at || !ended_at) return false
        if (!schedule_preference) return false
        return true
      } else {
        // if (!started_at) return false
        if (recurrence) {
          const keys = Object.keys(schedule)
          if (keys.length === 0) return false
          // else if (!ended_at) return
          return keys
            .map(key => schedule[key])
            .reduce((isValid, { time, duration }) => isValid && Boolean(time && duration), true)
        } else {
          return Boolean(duration)
        }
      }
    },
  },
  {
    key: "overview",
    title: "Overview",
    validator: opening => {
      const { tutor_rate, max_paid_noshows, ttg_start_at, ttg_end_at, school_rate } = opening

      let datesCorrect = true
      // const today = moment().startOf("day").toDate()
      if (!ttg_start_at) datesCorrect = false
      if (!moment(ttg_start_at).isValid()) datesCorrect = false
      // if (moment(ttg_start_at).isBefore(today)) datesCorrect = false

      if (ttg_end_at) {
        if (!moment(ttg_end_at).isValid()) datesCorrect = false
        // if (moment(ttg_end_at).isBefore(today)) datesCorrect = false
      }

      return (
        tutor_rate &&
        tutor_rate > 0 &&
        school_rate &&
        school_rate > 0 &&
        school_rate > tutor_rate &&
        typeof max_paid_noshows === "number" &&
        max_paid_noshows >= 0 &&
        datesCorrect
      )
    },
  },
]

const stepValid = (step, opening) => {
  const index = steps.findIndex(el => el.key === step)
  if (index === -1) return false
  const list = steps.slice(0, index + 1)
  const validators = list.map(({ validator }) => validator || (() => true))
  return validators.reduce((isValid, validator) => isValid && validator(opening), true)
}

export { steps, stepValid }
