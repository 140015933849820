import { ReactComponent as ArrowRight } from "@/assets/images/arrow-right.svg"
import { humanizeString } from "@/helpers/formatters"
import { CardContent, CardHeader, Link, Stack, Typography } from "@mui/material"
import React from "react"
import ProgramDetails from "../ProgramDetails"
import { StyledButton, StyledCard } from "../StyledComponents"

const LessonResource = ({ lesson, index }) => (
  <Link
    component="a"
    key={`${lesson.resource_url}-${index}`}
    href={lesson.resource_url}
    target="_blank"
    rel="noopener noreferrer"
    variant="body1"
    underline="hover"
    color="primary"
    aria-label={`Lesson link: ${lesson.name}`}
  >
    <ArrowRight />
    <Typography variant="body1" component="span" sx={{ ml: 1 }}>
      {lesson.name || humanizeString(lesson.resource_type)}
    </Typography>
  </Link>
)

export const LessonTutorView = ({ data }) => (
  <StyledCard>
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      {data.lesson_resources && data.lesson_resources.length > 0 ? (
        <Stack spacing={1}>
          {data.lesson_resources.map((lesson, index) => (
            <LessonResource key={`${lesson.resource_url}-${index}`} lesson={lesson} index={index} />
          ))}
        </Stack>
      ) : (
        <Typography variant="subtitle1" color="text.secondary">
          No lesson resources available.
        </Typography>
      )}
    </CardContent>
  </StyledCard>
)

export const LessonStudentView = ({ data }) => (
  <StyledCard>
    <CardHeader title={<ProgramDetails programName={data.program_name} assignmentName={data.assignment_name} />} />
    <CardContent>
      {data?.assignment_url ? (
        <StyledButton
          variant="contained"
          color="primary"
          href={data.assignment_url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ marginTop: 2 }}
          aria-label={`Start ${data.assignment_name}`}
          disableElevation
        >
          Start {data.assignment_name}
        </StyledButton>
      ) : (
        <Typography variant="subtitle1" color="text.secondary">
          No lessons available.
        </Typography>
      )}
    </CardContent>
  </StyledCard>
)
