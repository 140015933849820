import React from "react"
import { Button, Container, Col, Modal, Row } from "react-bootstrap"

const MessageConfirmationModal = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Title className="text-center p-5">
        <h1 className="text-primary display-4 font-weight-bold">
          <strong>Great!</strong>
        </h1>
      </Modal.Title>
      <Modal.Body className="pb-5 px-5 text-center">
        <Container>
          <Row className="pb-5">
            <Col className="offset-sm-4 col-sm-16">
              <h5 className="rule-height">
                <strong>Your message was sent successfully.</strong>
              </h5>
            </Col>
          </Row>
        </Container>
        <Button type="button" variant="outline-primary" onClick={onClose}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default MessageConfirmationModal
