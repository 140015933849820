import React, { useState, useEffect, useRef, useMemo } from "react"
import { Container, Button, Card } from "react-bootstrap"
import { Form, Field } from "react-final-form"
import { every } from "lodash/fp"

import apiClient from "../../../common/apiClient"
import UsersNavbar from "../../blocks/UsersNavbar"
import Filter from "./Filter"
import { serializeSpecialTreatments, serializeGradesFromHierarchy } from "../../../helpers/serializers"
import WizardModal from "./WizardModal"
import CalendlyModal from "./CalendlyModal"
import TutorsList from "../TutorsList"
import FilterValues from "./FilterValues"
import { chain, required, validateZip } from "../../../helpers/validators"
import { setFieldData, clearFieldData, clearFieldsData } from "../../../helpers/forms"
import BasePagination from "../../blocks/BasePagination"
import { limit } from "../../../common/searchOptions"
import { programsHierarchyToList } from "../../../helpers/programsHierarchy"
import IndeterminateProgressBar from "../../blocks/IndeterminateProgressBar"
import {
  getQueryStringFromParams,
  formValuesToSearchParams,
  getSearchParamsFromUrl,
  searchParamsToFormValues,
} from "../serializeParams"
import schema from "./schema"

const validate = values => ({
  zip: values.offline ? chain(required, validateZip)(values.zip) : undefined,
})

const isFilterEmpty = values => every(({ completed }) => !completed(values))(schema)

const FindTutor = ({ urls: { schools: schoolUrl, find: findTutorUrl }, dataSources, calendly_url }) => {
  const cardRef = useRef()

  const [showWizard, setShowWizard] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({})
  const [formValues, setFormValues] = useState({})
  const [tutors, setTutors] = useState([])
  const [tutorsCount, setTutorsCount] = useState(0)

  const programs = useMemo(() => programsHierarchyToList(dataSources.hierarchy), [dataSources])

  const options = useMemo(
    () => ({
      schoolUrl,
      students: dataSources.user.students,
      hierarchy: dataSources.hierarchy,
      programs: programsHierarchyToList(dataSources.hierarchy),
      struggleWithQuestions: dataSources.struggleWithQuestions,
      grades: serializeGradesFromHierarchy(dataSources.hierarchy),
      specialTreatments: serializeSpecialTreatments(dataSources.specialTreatments),
    }),
    [dataSources, schoolUrl]
  )

  useEffect(() => {
    const { page = 1, ...params } = getSearchParamsFromUrl(["zip"])
    setSearchParams({ ...params, page, limit })

    const initialFormValues = searchParamsToFormValues({ ...params, page }, programs)
    setFormValues(initialFormValues)

    findTutors(findTutorUrl, { ...params, page, limit })
    setShowCalendly(!!calendly_url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findTutorUrl, programs])

  const findTutors = async (url, params) => {
    setIsLoading(true)
    const queryString = getQueryStringFromParams(params)
    try {
      const {
        data: { tutors, count },
      } = await apiClient.get(url + queryString)
      setTutors(tutors)
      setTutorsCount(count)
    } catch (_) {
      setTutors([])
      setTutorsCount(0)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit = values => {
    setFormValues(values)
    const isPageChanged = searchParams.page !== values.page
    const newSearchParams = formValuesToSearchParams(
      { limit, ...values, page: isPageChanged ? values.page : 1 },
      programs
    )
    setSearchParams(newSearchParams)
    findTutors(findTutorUrl, newSearchParams)
    const queryString = getQueryStringFromParams(newSearchParams)
    window.history.replaceState(null, null, queryString)
  }

  const onRestart = form => {
    window.history.replaceState(null, null, window.location.pathname)
    form.reset({})
    form.mutators.setFieldData("page", 1)
    form.submit()
    setTutors([])
    setTutorsCount(0)
  }

  const onCloseModal = form => {
    setShowWizard(false)
    form.mutators.setFieldData("page", 1)
    form.submit()
  }

  const onClearFields = (form, fields) => {
    form.mutators.clearFieldsData(fields)
    form.mutators.setFieldData("page", 1)
    form.submit()
  }

  const scrollToTop = () => {
    cardRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  const [showCalendly, setShowCalendly] = useState(null)

  const onCloseCalendlyModal = () => setShowCalendly(false)

  const onOkCalendlyModal = () => {
    window.open(calendly_url, "_blank")
    setShowCalendly(false)
  }

  return (
    <div>
      <Form
        initialValues={formValues}
        mutators={{ setFieldData, clearFieldData, clearFieldsData }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ values, form }) => (
          <Container className="panel-wrapper">
            <UsersNavbar
              title={`Find a Certified, Verified Tutor | Braintrust ${values.page > 1 ? ". Page: " + values.page : ""}`}
            >
              <Button type="button" variant="outline-primary" className="anl-btn" onClick={() => setShowWizard(true)}>
                Help me find a tutor
              </Button>
              <WizardModal show={showWizard} options={options} onClose={() => onCloseModal(form)} />
            </UsersNavbar>
            <Card className="mb-5" ref={cardRef}>
              <Card.Header>
                <Filter values={formValues} options={options} />
              </Card.Header>
              {isLoading && <IndeterminateProgressBar />}
              <Card.Body>
                <FilterValues
                  values={formValues}
                  options={options}
                  onClearFields={fields => onClearFields(form, fields)}
                  onClearAll={() => onRestart(form)}
                />
                {!isLoading && (
                  <Field name="order_by">
                    {({ input }) => (
                      <TutorsList
                        sort={input.value}
                        page={values.page}
                        tutors={tutors}
                        count={tutorsCount}
                        options={options}
                        onSortChanged={value => {
                          input.onChange(value)
                          form.submit()
                          scrollToTop()
                        }}
                        shouldSplitResults={!isFilterEmpty(values)}
                      />
                    )}
                  </Field>
                )}
                {!isLoading && (
                  <Field name="page">
                    {({ input }) => (
                      <BasePagination
                        currentPage={input.value}
                        limit={limit}
                        count={tutorsCount}
                        onPageChanged={value => {
                          input.onChange(value)
                          form.submit()
                          scrollToTop()
                        }}
                      />
                    )}
                  </Field>
                )}
              </Card.Body>
            </Card>
          </Container>
        )}
      </Form>
      {showCalendly && <CalendlyModal show={true} onOk={onOkCalendlyModal} onClose={onCloseCalendlyModal} />}
    </div>
  )
}

export default FindTutor
