import React from "react"
import cn from "classnames"
import { Button } from "react-bootstrap"

import { scheduleStringToObject, scheduleObjectToString } from "../../helpers/serializers"
import { SHORT_WEEKDAYS, DAYPERIODS } from "../../common/constants"

// schedule values - array of strings in format 'd-dp',
// d - sun, mon, tue, wed, thu, fri, sat
// dp - m,d,e (morning, day, evening)

const SchedulePicker = ({ value = [], onChange }) => {
  const innerValue = value ? value.map(scheduleStringToObject) : []

  const isActive = (dayValue, periodValue) =>
    !!innerValue.find(({ day, period }) => day === dayValue && period === periodValue)

  const onChoose = (day, period) => {
    const strValue = scheduleObjectToString({ day, period })
    if (isActive(day, period)) {
      const newValue = value.filter(item => item !== strValue)
      onChange(newValue)
    } else {
      onChange([...value, strValue])
    }
  }

  return (
    <table className="table table-borderless">
      <thead>
        <tr>
          {SHORT_WEEKDAYS.map(({ value, label }) => (
            <th key={value} className="text-center form-label">
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {DAYPERIODS.map(({ value: periodValue, label: periodLabel }) => (
          <tr key={periodValue}>
            {SHORT_WEEKDAYS.map(({ value: dayValue }) => (
              <th key={`${periodValue}-${dayValue}`} className="text-center">
                <div className="mx-1">
                  <Button
                    size="sm"
                    variant="warning-light"
                    className={cn("fullwidth", isActive(dayValue, periodValue) && "selected")}
                    onClick={() => onChoose(dayValue, periodValue)}
                  >
                    {periodLabel}
                  </Button>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default SchedulePicker
