import React from "react"
import { Modal, Button } from "react-bootstrap"

const RequestSuccessModal = ({ show, url, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header className="borderless px-4 pt-4 pb-2" closeButton />
      <Modal.Body className="px-5 pb-5">
        <div className="d-flex flex-column align-items-center">
          <h1 className="text-primary display-4 font-weight-bold mb-5">Woohoo!</h1>
          <h5 className="font-weight-bold rule-height text-center mb-3">
            Your request has been sent off to this amazing teacher.
          </h5>
          <h5 className="font-weight-bold rule-height text-center mb-5">
            Keep an eye on your inbox or sessions tab for scheduling confirmation.
          </h5>
          <div className="mb-5">
            <a href={url}>Review session request</a>
          </div>
          <Button variant="outline-primary" onClick={handleClose}>
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RequestSuccessModal
