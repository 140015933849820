import React, { useContext } from "react"
import { TutorCardApplicationContext } from "../../../../contexts"
import moment from "moment/moment"
import { TutorActionList } from "./tutor_action_list"
import { QuickNote } from "./quick_note"
import { TutorCardContent } from "./tutor_card_content"
import { MatchedGroups } from "./matched_groups"

export const TutorCard = () => {
  const application = useContext(TutorCardApplicationContext)

  return (
    <div className="opening-tutor-card gap-1">
      <TutorCardContent />

      <div className="opening-tutor-card_aside">
        <TutorActionList />

        <MatchedGroups />

        <div>
          <div className="font-size-small text-gray-chat font-weight-medium">Tutor applied on</div>
          {moment(application.created_at).format("MMM Do, YYYY")}
        </div>

        {application.note ? (
          <div>
            <div className="font-size-small text-gray-chat font-weight-medium">Tutor&apos;s message</div>
            {application.note}
          </div>
        ) : null}

        <QuickNote />
      </div>
    </div>
  )
}
