import React, { useEffect, useState, useMemo, useContext, useCallback } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import moment from "moment"
import MonthSchedule from "./month_schedule"
import { ScheduleProviders } from "../providers/providers"
import InitialLoadingSpinner from "../../blocks/InitialLoadingSpinner"
import ToggleScheduleAssistant from "./schedule_assistant"
import ConfirmationModalProvider from "../../blocks/ConfirmationModal"
import { DataContext, DomainTransactionContext, QueryContext } from "../../../contexts"
import { checkActionExist } from "../helpers"
import { ScheduleActions } from "../constants"
import { getMonthScheduleDays, getMonthScheduleSessions } from "../configurations/domain"

const Assistant = () => {
  const { schedule, scheduleSessions } = useContext(DataContext)
  const { getQueryState } = useContext(QueryContext)
  const { buildTransaction } = useContext(DomainTransactionContext)
  const { retry: getDaysRequest } = getQueryState(getMonthScheduleDays)
  const { retry: getScheduleRequest } = getQueryState(getMonthScheduleSessions)
  const onSuccess = useCallback(async () => {
    const transaction = buildTransaction()
    const requests = [getDaysRequest({ transaction }), getScheduleRequest({ transaction })]
    await Promise.all(requests)
  }, [buildTransaction, getDaysRequest, getScheduleRequest])
  return (
    <ConfirmationModalProvider>
      <ToggleScheduleAssistant
        withConfirmation
        sessions={scheduleSessions}
        schedules={[schedule]}
        onCreate={onSuccess}
        onDelete={onSuccess}
      />
    </ConfirmationModalProvider>
  )
}

const SchedulePage = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [ready, setReady] = useState(() => {
    const date = params.get("month")
    return date && moment(date).isValid()
  })
  const { schedule } = useContext(DataContext)

  useEffect(() => {
    if (!ready) {
      const month = params.get("month")
      if ((!month || !moment(month).isValid()) && schedule) {
        const searchParams = new URLSearchParams(params)
        const { start_on, end_on } = schedule
        let date
        if (moment(start_on).isAfter(new Date())) date = start_on
        if (end_on && moment(end_on).isBefore(new Date())) date = end_on
        searchParams.set("month", moment(date).format("YYYY-MM-DD"))
        navigate(`?${searchParams}`, { replace: true })
      } else setReady(true)
    }
  }, [params, ready, navigate, schedule])

  const initial = useMemo(() => {
    const date = params.get("month")
    const scheduleDate = date && moment(date).isValid() ? moment(date).startOf("month").format("YYYY-MM-DD") : null
    return { scheduleDate }
  }, [params])

  const canChangeSchedule = useMemo(
    () => checkActionExist(schedule.available_actions, ScheduleActions.ManageSessions),
    [schedule]
  )

  if (!ready) return null

  return (
    <ScheduleProviders initial={initial}>
      <InitialLoadingSpinner>
        {canChangeSchedule && <Assistant />}
        <MonthSchedule />
      </InitialLoadingSpinner>
    </ScheduleProviders>
  )
}

export default SchedulePage
