import React, { useState, useMemo, useCallback, forwardRef } from "react"
import { Form, Row, Col, Button } from "react-bootstrap"
import { Field, useFormState, useForm } from "react-final-form"
import { filter, map } from "lodash/fp"
import DatePicker from "react-datepicker"

import DurationField from "../blocks/DurationField"
import { SESSION_DURATIONS, WEEKDAYS } from "../../common/constants"
import { formatTime, minutesToHours, parseTime } from "../../helpers/dates"
import { getParentSessionMaxTime, getParentSessionMinTime } from "./SessionSchedule"
import { valueOrNull } from "../../helpers/formatters"
import FormRow from "../blocks/FormRow"
import { className } from "../../helpers/className"
import useViewport from "../../hooks/useViewport"
import { buildId } from "../../helpers/forms"
import { ReactComponent as Chevron } from "../../assets/images/chevron1"

export const weeklyScheduleControlName = "timetable"

const weekDaysOptions = map(({ value }) => ({ value, label: value }))(WEEKDAYS)

const isSessionInvalid = (name, day, values) =>
  !values[name] || !values[name][day] || !values[name][day].time || !values[name][day].duration

const WeeklyScheduleControl = ({ name = weeklyScheduleControlName, disabled, labelClass }) => {
  const { values } = useFormState()
  const form = useForm()

  const [weekDays, setWeekDays] = useState(values[name] ? Object.keys(values[name]) : [])

  const copySessionData = targetDay => {
    const { time, duration } = values[name][targetDay]
    weekDays.forEach(day => {
      if (targetDay !== day) {
        form.mutators.setFieldData(`${name}.${day}.time`, time)
        form.mutators.setFieldData(`${name}.${day}.duration`, duration)
      }
    })
  }

  const sortedWeekDays = useMemo(() => filter(({ value }) => weekDays.includes(value))(WEEKDAYS), [weekDays])

  const onWeekDayClick = value => {
    if (weekDays.includes(value)) {
      setWeekDays(weekDays.filter(day => day !== value))
      form.mutators.clearFieldData(`${name}.${value}`)
    } else {
      setWeekDays([...weekDays, value])
    }
  }

  const labelClassName = labelClass || "col-lg-6"

  return (
    <>
      <Form.Row className={weekDays.length > 0 && "mb-2"}>
        <Form.Label column className={labelClassName}>
          Days of week
        </Form.Label>
        <Form.Group as={Col} lg={18} className="d-flex align-items-center mb-1 flex-wrap">
          {weekDaysOptions.map(({ label, value }) => (
            <Button
              key={value}
              size="sm"
              variant="warning-light"
              disabled={disabled}
              className={className("mb-2 mr-2 text-nowrap", weekDays.includes(value) && "selected")}
              onClick={() => onWeekDayClick(value)}
            >
              {label.slice(0, 3)}
            </Button>
          ))}
        </Form.Group>
      </Form.Row>

      {sortedWeekDays.map(({ label, value }) => (
        <Form.Row key={value}>
          <Form.Label column className={labelClassName}>
            {label}
          </Form.Label>
          <Form.Group as={Col} lg={6} className="d-flex flex-column -hide-disabled-times">
            <Field name={`${name}.${value}.time`} parse={valueOrNull}>
              {({ input }) => (
                <DatePicker
                  {...input}
                  wrapperClassName="fullwidth"
                  placeholderText="Time"
                  selected={parseTime(input.value)}
                  disabled={disabled}
                  onChange={value => input.onChange(formatTime(value))}
                  dateFormat="h:mm aa"
                  customInput={<Form.Control />}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={getParentSessionMinTime()}
                  maxTime={getParentSessionMaxTime()}
                  timeIntervals={15}
                  timeCaption="Time"
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group as={Col} lg={6}>
            <Field name={`${name}.${value}.duration`} parse={valueOrNull}>
              {({ input }) => <DurationField {...input} disabled={disabled} placeholder="Pick duration" />}
            </Field>
          </Form.Group>
          {!disabled && sortedWeekDays[0].value === value && sortedWeekDays.length > 1 && (
            <Col lg={6}>
              <Button
                variant="outline-primary"
                size="sm"
                type="button"
                disabled={isSessionInvalid(name, value, values)}
                onClick={() => copySessionData(value)}
              >
                Use for all sessions
              </Button>
            </Col>
          )}
        </Form.Row>
      ))}
    </>
  )
}

const DatePickerInput = forwardRef(function DatePickerInput(props, ref) {
  const id = useMemo(buildId, [])
  return (
    <label htmlFor={id} className="d-block mb-0">
      <Form.Control ref={ref} {...props} id={id} className="v-2" data-test="calendar-assistant-time" />
      <div className="icon-24-px icon-gray-color input-side-icon-24 cursor-text">
        <Chevron />
      </div>
    </label>
  )
})

export const ModernWeeklyScheduleControl = ({
  showTimeDuration,
  maxDuration,
  name = weeklyScheduleControlName,
  disabled,
  formRowProps,
}) => {
  const { isMobileViewport } = useViewport()
  const { values } = useFormState()
  const form = useForm()
  const schedule = useMemo(() => values[name] || {}, [name, values])

  const copySessionData = useCallback(
    targetDay => {
      const { time, duration } = schedule[targetDay]
      Object.keys(schedule).forEach(day => {
        if (targetDay !== day) {
          form.mutators.setFieldData(`${name}.${day}.time`, time)
          form.mutators.setFieldData(`${name}.${day}.duration`, duration)
        }
      })
    },
    [form.mutators, schedule, name]
  )

  const sortedWeekDays = useMemo(
    () => filter(({ value }) => Object.keys(schedule).includes(value))(WEEKDAYS),
    [schedule]
  )

  const onWeekDayClick = useCallback(
    value => {
      if (Object.keys(schedule).includes(value)) {
        form.mutators.clearFieldData(`${name}.${value}`)
      } else {
        if (!values[name]) form.mutators.setFieldData(name, {})
        form.mutators.setFieldData(`${name}.${value}`, {})
      }
    },
    [form.mutators, name, schedule, values]
  )

  const [hours, minutes] = minutesToHours(maxDuration || 0)

  return (
    <>
      <FormRow label="Days of week" {...formRowProps}>
        <div className="week-days-row py-1">
          {weekDaysOptions.map(({ label, value }) => (
            <Button
              key={label}
              size="sm"
              variant="warning-light"
              disabled={disabled}
              className={className("flex-grow-1 text-nowrap", Object.keys(schedule).includes(value) && "selected")}
              onClick={() => onWeekDayClick(value)}
              data-test={`calendar-assistant-week-day-${value}`}
            >
              {label.slice(0, 2)}
            </Button>
          ))}
        </div>
      </FormRow>

      {showTimeDuration &&
        sortedWeekDays.map(({ label, value }, idx) => (
          <FormRow
            key={label}
            label={label}
            {...formRowProps}
            LabelProps={{
              ...(formRowProps.LabelProps || {}),
              className: className(
                formRowProps.LabelProps?.className,
                "font-weight-light",
                (idx === 0 || isMobileViewport) && "mt-4"
              ),
            }}
          >
            <Row>
              <Col
                xs={24}
                lg={idx === 0 && !disabled && sortedWeekDays[0].value === value && sortedWeekDays.length > 1 ? 24 : 16}
              >
                <Row className="align-items-center">
                  <Col
                    lg={!disabled && sortedWeekDays[0].value === value && sortedWeekDays.length > 1 ? 8 : 12}
                    className={isMobileViewport ? "mb-2" : ""}
                  >
                    {(idx === 0 || isMobileViewport) && <span className="text-gray-chat field-subtitle">Time</span>}
                    <Field name={`${name}.${value}.time`} parse={valueOrNull}>
                      {({ input }) => (
                        <DatePicker
                          {...input}
                          wrapperClassName="fullwidth"
                          placeholderText="Fill time"
                          selected={parseTime(input.value, "h:mm aa")}
                          disabled={disabled}
                          dateFormat="h:mm aa"
                          customInput={<DatePickerInput />}
                          showTimeSelect
                          showTimeSelectOnly
                          autoComplete="off"
                          minTime={getParentSessionMinTime()}
                          maxTime={getParentSessionMaxTime()}
                          timeIntervals={15}
                          timeCaption="Time"
                        />
                      )}
                    </Field>
                  </Col>
                  <Col lg={!disabled && sortedWeekDays[0].value === value && sortedWeekDays.length > 1 ? 8 : 12}>
                    {(idx === 0 || isMobileViewport) && <span className="text-gray-chat field-subtitle">Duration</span>}
                    <Field name={`${name}.${value}.duration`} parse={valueOrNull}>
                      {({ input }) => (
                        <DurationField
                          {...input}
                          autoComplete="off"
                          className="v-2"
                          disabled={disabled}
                          placeholder="Fill duration"
                          options={SESSION_DURATIONS.filter(duration => duration <= (maxDuration || Infinity))}
                          dataTest="calendar-assistant-duration"
                        />
                      )}
                    </Field>
                  </Col>
                  {!disabled && sortedWeekDays[0].value === value && sortedWeekDays.length > 1 && (
                    <Col xs="auto" className={className(!isMobileViewport ? "pl-3 mt-4" : "mt-2")}>
                      <Button
                        variant="link"
                        size="sm"
                        type="button"
                        disabled={isSessionInvalid(name, value, values)}
                        onClick={() => copySessionData(value)}
                      >
                        Use for all
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </FormRow>
        ))}
      {showTimeDuration && Boolean(maxDuration) && Object.keys(values[name] || {}).length > 0 && (
        <FormRow
          label=" "
          {...formRowProps}
          ColProps={{ ...formRowProps.ColProps, className: `${formRowProps.ColProps.className} min-h-initial` }}
        >
          <Row>
            <Col xs={24} as="span" className="form-control_caption">
              Sessions can be no longer than{hours > 0 && ` ${hours}h`}
              {minutes > 0 && ` ${minutes}min`}
            </Col>
          </Row>
        </FormRow>
      )}
    </>
  )
}

export default WeeklyScheduleControl
