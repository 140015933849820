import React from "react"
import { Col, Form, Row } from "react-bootstrap"

const AuthenticationForm = ({ setPassword, password }) => {
  return (
    <Row>
      <Col xs={24} md={12} lg={8}>
        <span className="v-2 col-form-label px-0 form-label col-form-label col-lg-8 col-24">Password</span>
        <Form.Control
          type="password"
          className="v-2 mb-4"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </Col>
    </Row>
  )
}

export default AuthenticationForm
