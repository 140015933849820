import React, { useState } from "react"
import { Dropdown } from "react-bootstrap"
import OpeningModal from "./OpeningModal"
import { serializeGradesFromHierarchy, serializeStates } from "../../../../helpers/serializers"
import { programsHierarchyToList } from "../../../../helpers/programsHierarchy"
import { serializeSpecialTreatments } from "../../../../helpers/serializers"
import { useSaveOpeningRequest, useOpeningGroups } from "./hooks"
import { OpeningContext } from "./context"
import { formatDateKeepZone } from "../../../../helpers/dates"

const defaultData = {
  group: true,
  student: null,
  groupIds: [],
  school: null,
  grade: null,
  defaultProgramId: null,
  struggleWith: [],
  meeting_format: null,
  meeting_info: "",
  address_1: "",
  address_2: "",
  city: "",
  state: "",
  zip: "",
  recurrence: false,
  schedule: {},
  started_at: undefined,
  ended_at: undefined,
  additionalInformation: "",
  duration: null,
  executive_function: null,
  special_treatments: [],
  tutor_rate: "",
  school_rate: "",
  schedule_flexible: false,
  schedule_preference: "",
  custom_program: "",
  defaultProgramName: "",
  is_per_hour_rate: true,
  max_sessions: "",
  max_paid_noshows: 0,
  max_duration: "",
  ttg_start_at: formatDateKeepZone(new Date(), "YYYY-MM-DD"),
  ttg_end_at: "",
}

const deepClone = structure => JSON.parse(JSON.stringify(structure))

const Index = ({
  schools,
  hierarchy,
  struggleWithQuestions,
  states,
  timeZone,
  specialTreatments,
  initialData = {},
}) => {
  const [opening, setOpening] = useState(() => ({
    ...deepClone(defaultData),
  }))

  const { request: openingRequest, data, openingLoading } = useOpeningGroups()

  const [options] = useState(() => ({
    hierarchy,
    timeZone,
    struggleWithQuestions,
    states: serializeStates(states),
    grades: serializeGradesFromHierarchy(hierarchy),
    programs: programsHierarchyToList(hierarchy),
    specialTreatments: serializeSpecialTreatments(specialTreatments),
  }))

  const [showModal, setShowModal] = useState(false)

  const { loading, request } = useSaveOpeningRequest({
    onSuccess: () => {
      onClose()
      setTimeout(() => window.location.reload(), 1000)
    },
  })

  const openModal = school => {
    updateOpening({ ...initialData, school })
    openingRequest(school.value)
    setShowModal(true)
  }

  const updateOpening = data => {
    setOpening({ ...opening, ...data })
  }

  const onClose = () => {
    setOpening({ ...deepClone(defaultData) })
    setShowModal(false)
  }

  const submit = () => {
    const requestData = {
      school_id: parseInt(opening.school.value, 10), //Int
      group: opening.group, //Bool
      meeting_format: opening.meeting_format,
      meeting_info: opening.meeting_info,
      recurrence: opening.recurrence, //Bool
      ...(opening.recurrence
        ? {
            schedule: opening.schedule, //Json
          }
        : {
            duration: opening.duration ? parseInt(opening.duration, 10) : void 0, //Int
          }),
      opening_groups_ids: opening.groupIds, //Int[]
      student_id: opening.student ? parseInt(opening.student.value) : void 0, //Int
      struggle_with: opening.struggleWith, //Int[]
      default_program_id: opening.defaultProgramId, //Int
      started_at: opening.started_at,
      ended_at: opening.recurrence || opening.schedule_flexible ? opening.ended_at : null,
      special_treatments: opening.special_treatments,
      information: opening.additionalInformation,
      address_1: opening.address_1,
      address_2: opening.address_2,
      city: opening.city,
      state: opening.state,
      zip: opening.zip,
      grade: opening.grade,
      executive_function: opening.executive_function,
      additional_emails: opening.additionalEmails,
      sessions_notify: opening.sessionsNotify,
      tutor_rate: opening.tutor_rate,
      school_rate: opening.school_rate,
      schedule_flexible: opening.schedule_flexible,
      schedule_preference: opening.schedule_flexible ? opening.schedule_preference : null,
      custom_program: opening.custom_program,
      is_per_hour_rate: opening.is_per_hour_rate,
      max_sessions: opening.max_sessions || null,
      max_paid_noshows: opening.max_paid_noshows || 0,
      max_duration: opening.max_duration || null,
      ttg_start_at: formatDateKeepZone(opening.ttg_start_at, "YYYY-MM-DD"),
      ttg_end_at: opening.ttg_end_at ? formatDateKeepZone(opening.ttg_end_at, "YYYY-MM-DD") : opening.ttg_end_at,
    }
    request({ opening: requestData })
  }

  const openingContextValue = {
    opening,
    options,
    setOpening: updateOpening,
  }

  return (
    <React.Fragment>
      <Dropdown>
        <Dropdown.Toggle variant="" className="sub-nav_item">
          Create new opening
        </Dropdown.Toggle>
        <Dropdown.Menu className="-fullwidth">
          {schools.map(school => (
            <Dropdown.Item
              key={school.value}
              onClick={() => {
                openModal(school)
              }}
            >
              {school.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <OpeningContext.Provider value={openingContextValue}>
        <OpeningModal
          groups={data}
          show={showModal}
          opening={opening}
          onClose={onClose}
          schools={schools}
          onSubmit={submit}
          loading={openingLoading}
          submitting={loading}
        />
      </OpeningContext.Provider>
    </React.Fragment>
  )
}

export default Index
