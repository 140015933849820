import React from "react"
import { Button, Card, Badge } from "react-bootstrap"
import { compact } from "lodash/fp"
import cn from "classnames"

import {
  getOfflineLocationText,
  getProgramTitle,
  getPrivateFullName,
  getFullName,
  getStruggleWithFullSummary,
} from "../../../helpers/presentational"
import ScheduleSummary from "../../schedule_session/ScheduleSummary"
import { formatDateKeepZone } from "../../../helpers/dates"
import MessageButton from "../../chat/MessageButton"
import { CONFIRMED_REQUEST, DECLINED_REQUEST, PENDING_REQUEST } from "../../../common/constants"

const SessionRequestCard = ({
  sessionRequest,
  options,
  focused,
  saveMessageUrl,
  enableMessageButtonUrl,
  onRequestDelete,
  onRequestEdit,
}) => {
  const recurrenceText = sessionRequest.recurrence ? "Recurring" : "Single"
  const tutor = sessionRequest.tutor
  const programs = compact([
    ...sessionRequest.program_ids.map(id => getProgramTitle(id, options.programs)),
    sessionRequest.executive_function && "Executive function",
  ])

  const struggleWithSummary = getStruggleWithFullSummary(sessionRequest.struggle_with, options).join(", ")

  return (
    <Card className={cn("card-outline mb-4", focused && "focused")} id={sessionRequest.id}>
      <Card.Body>
        <div className="d-flex justify-content-between flex-column-reverse flex-md-row">
          <h5 className="mb-2">{recurrenceText} tutoring session</h5>
          <div className="d-flex mb-2">
            <Button variant="link" className="link-error mr-4" onClick={onRequestDelete}>
              Delete
            </Button>
            {sessionRequest.status === PENDING_REQUEST && (
              <Button variant="link" className="link-primary" onClick={onRequestEdit}>
                Edit request
              </Button>
            )}
          </div>
        </div>
        <ScheduleSummary values={sessionRequest} />
        <div className="mb-1">
          Tutor:{" "}
          <a href={tutor.profile_link} rel={"noreferrer"} target={"_blank"}>
            {getPrivateFullName(tutor)}.
          </a>
        </div>
        <div className="mb-1">Programs: {programs.join(", ")}</div>
        {sessionRequest.struggle_with && struggleWithSummary && (
          <div className="mb-1">Struggle with: {struggleWithSummary}</div>
        )}
        <div className="mb-2">
          Location: {sessionRequest.online ? "Online" : getOfflineLocationText(sessionRequest, options)}
        </div>
        {sessionRequest.additional_info && <div className="mb-2">Notes: {sessionRequest.additional_info}</div>}

        <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mt-3">
          <div>
            {sessionRequest.status === PENDING_REQUEST && (
              <Badge variant="info" className="default-badge mb-2 mb-md-0">
                Waiting for tutor accept
              </Badge>
            )}
            {sessionRequest.status === CONFIRMED_REQUEST && (
              <Badge variant="success" className="default-badge mb-2 mb-md-0">
                Confirmed by tutor
              </Badge>
            )}
            {sessionRequest.status === DECLINED_REQUEST && (
              <Badge variant="error" className="default-badge mb-2 mb-md-0">
                Declined by tutor
              </Badge>
            )}
            <MessageButton
              buttonText="Message tutor"
              className="btn-sm mb-2 mb-md-0 mt-3"
              dataSources={{
                urls: { save_message: saveMessageUrl, enable_message_button: enableMessageButtonUrl },
                from: {
                  id: sessionRequest.parent.id,
                  name: getFullName(sessionRequest.parent),
                  email: sessionRequest.parent.email,
                },
                recipient: {
                  id: sessionRequest.tutor.id,
                  name: getPrivateFullName(sessionRequest.tutor),
                  averageResponseTime: "unknown",
                },
                subject: "",
                changeSubject: true,
              }}
            />
          </div>
          <div className="text-muted ml-auto flex-shrink-0">
            Request sent {formatDateKeepZone(sessionRequest.request_sent_at, "MM/DD/YYYY")}, updated{" "}
            {formatDateKeepZone(sessionRequest.updated_at, "MM/DD/YYYY")}
          </div>
        </div>
      </Card.Body>
      {sessionRequest.decline_reason && (
        <Card.Footer>
          <span className="font-weight-medium mr-1">Tutor comment:</span> {sessionRequest.decline_reason}
        </Card.Footer>
      )}
    </Card>
  )
}

export default SessionRequestCard
