import React, { useCallback, useContext, useMemo } from "react"
import { SearchParamsKeys } from "../../../constants"
import { TableSelectionContext, TableStateContext } from "../../../contexts"
import { defaultPage, defaultPerPage, directionList } from "../constants"
import { useMemoryState } from "../../../hooks/useMemoryState"

export const StatefulTableStateProvider = ({
  name,
  initialPage = defaultPage,
  initialPerPage = defaultPerPage,
  useStorage = useMemoryState,
  children,
}) => {
  const stateName = useMemo(() => name || "tableState", [name])
  const {
    state: tableState,
    add,
    remove,
  } = useStorage(stateName, {
    [SearchParamsKeys.Page]: initialPage,
    [SearchParamsKeys.RowsPerPage]: initialPerPage,
  })

  const { resetSelectionOnChange, onSelect } = useContext(TableSelectionContext)

  const state = useMemo(() => tableState[stateName], [stateName, tableState])

  const [sortColumn, sortDirection = null] = useMemo(() => (state[SearchParamsKeys.Sort] || "").split(":"), [state])

  const checkAndReset = useCallback(() => {
    if (resetSelectionOnChange && onSelect) onSelect()
  }, [onSelect, resetSelectionOnChange])

  const onPageChange = useCallback(
    page => {
      add({ [SearchParamsKeys.Page]: parseInt(page, 10) })
      checkAndReset()
    },
    [add, checkAndReset]
  )

  const onPerPageChange = useCallback(
    perPage => {
      add({ [SearchParamsKeys.RowsPerPage]: perPage, [SearchParamsKeys.Page]: 1 }, [SearchParamsKeys.Page])
      checkAndReset()
    },
    [add, checkAndReset]
  )

  const onSortChange = useCallback(
    field => {
      const index = sortColumn === field ? directionList.indexOf(sortDirection) : 0
      const nextDirection = directionList[index + 1]
      const result = { [SearchParamsKeys.Sort]: null }
      if (nextDirection) {
        result[SearchParamsKeys.Sort] = `${field}:${nextDirection}`
        add(result)
      } else remove([SearchParamsKeys.Sort])
      checkAndReset()
    },
    [sortColumn, sortDirection, remove, checkAndReset, add]
  )

  const result = {
    initial: false,
    state,
    page: state[SearchParamsKeys.Page],
    rowsPerPage: state[SearchParamsKeys.RowsPerPage],
    sortColumn,
    sortDirection,
    onPageChange,
    onPerPageChange,
    onSortChange,
  }

  return <TableStateContext.Provider value={result}>{children}</TableStateContext.Provider>
}

export const TableStateProvider = ({ inherit = true, name, initialPage, initialPerPage, useStorage, children }) => {
  const props = useContext(TableStateContext)
  if (props.initial || !inherit) {
    return (
      <StatefulTableStateProvider
        name={name}
        initialPage={initialPage}
        initialPerPage={initialPerPage}
        useStorage={useStorage}
      >
        {children}
      </StatefulTableStateProvider>
    )
  }

  return children
}
