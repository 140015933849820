import React from "react"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { putAndRedirect } from "../../../common/apiClient"
import FormFields from "../employment_and_tutoring/FormFields"
import PendingChanges from "./PendingChanges"

const draftEmployments = user => user.profile.employment_histories.filter(el => el.review_status === "draft")

const draftTutorings = user => user.profile.tutoring_histories.filter(el => el.review_status === "draft")

const EmploymentAndTutoring = ({ dataSources }) => {
  const onSubmit = values => {
    putAndRedirect(dataSources.urls.save, dataSources.urls.next, values)
  }

  const employmentsToShow = draftEmployments(dataSources.user)
  const tutoringsToShow = draftTutorings(dataSources.user)

  return (
    <div className="container experience-form">
      <div className="row">
        <PendingChanges
          dataSources={dataSources}
          employmentsToShow={employmentsToShow}
          tutoringsToShow={tutoringsToShow}
          divisions={dataSources.divisions}
        />
      </div>

      <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }}>
        {({ handleSubmit, submitting }) => (
          <>
            <div className="row">
              <div className="col-lg-18">
                {employmentsToShow.length > 0 || tutoringsToShow.length > 0 ? <h4>Approved</h4> : null}
                <FormFields dataSources={dataSources} admin={true} />
              </div>
              <div className="col-lg-6 mb-4"></div>
            </div>
            <button type="button" className="btn btn-outline-primary mt-4" onClick={handleSubmit} disabled={submitting}>
              Save
            </button>
          </>
        )}
      </Form>
    </div>
  )
}
export default EmploymentAndTutoring
