import React, { useState, useMemo, useEffect } from "react"

import SingleChoiceDropdown from "../../blocks/SingleChoiceDropdown"
import {
  UPCOMING_INTERVIEW,
  COMPLETED_INTERVIEW,
  CANCELLED_INTERVIEW,
  ALL_PAST_INTERVIEW,
} from "../../../common/constants"
import { getLabelByValue } from "../../../helpers/presentational"
import apiClient, { replaceId, showAllErrorsInAlert } from "../../../common/apiClient"
import Alerter from "../../../common/alerter"
import IndeterminateProgressBar from "../../blocks/IndeterminateProgressBar"
import TutorInterviewCard from "./TutorInterviewCard"
import { map, find, reject } from "lodash/fp"
import EditDetailsModal from "./EditDetailsModal"
import useFocusedInterview from "../useFocusedInterview"

const statusOptions = [
  { value: UPCOMING_INTERVIEW, label: "Upcoming" },
  { value: COMPLETED_INTERVIEW, label: "Completed" },
  { value: CANCELLED_INTERVIEW, label: "Canceled" },
  { value: ALL_PAST_INTERVIEW, label: "All Past" },
]

const getStatusFilterText = status => `Show: ${getLabelByValue(statusOptions)(status).toLowerCase()} interviews`

const Interviews = ({ urls }) => {
  const [status, setStatus] = useState(UPCOMING_INTERVIEW)
  const [isLoading, setIsLoading] = useState(false)
  const [interviews, setInterviews] = useState([])
  const [interviewIdToEdit, setInterviewIdToEdit] = useState(null)

  const focusedInterviewId = useFocusedInterview()

  const currentInterview = useMemo(() => {
    if (interviewIdToEdit) {
      return find(["id", interviewIdToEdit])(interviews)
    }
    return null
  }, [interviewIdToEdit, interviews])

  useEffect(() => {
    getInterviews(status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeStatus = value => {
    getInterviews(value)
    setStatus(value)
  }

  const getInterviews = async status => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(urls.get, { params: { filter: status } })
      setInterviews(data)
    } catch (e) {
      Alerter.error("An error occurred while loading data")
    } finally {
      setIsLoading(false)
    }
  }

  const onInterviewEdit = async (id, values) => {
    try {
      const { data } = await apiClient.put(replaceId(urls.update, id), values)
      setInterviews(prevInterviews => map(item => (item.id === id ? data : item))(prevInterviews))
      setInterviewIdToEdit(null)
      Alerter.success("Interview was successfully updated")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const onInterviewComplete = async id => {
    try {
      await apiClient.put(replaceId(urls.complete, id))
      setInterviews(prevInterviews => reject(["id", id])(prevInterviews))
      setInterviewIdToEdit(null)
      Alerter.success("Interview was successfully completed")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  const onInterviewCancel = async id => {
    try {
      await apiClient.delete(replaceId(urls.cancel, id))
      setInterviews(prevInterviews => reject(["id", id])(prevInterviews))
      Alerter.success("Interview was successfully declined")
    } catch (e) {
      showAllErrorsInAlert(e)
    }
  }

  return (
    <>
      {isLoading && <IndeterminateProgressBar />}
      <div className="p-4 p-md-5">
        <SingleChoiceDropdown
          value={status}
          className="mb-4"
          buttonText={getStatusFilterText(status)}
          options={statusOptions}
          onChange={onChangeStatus}
        />
        {!isLoading && !interviews.length && <p className="text-center m-0">No interviews found</p>}
        {!isLoading &&
          interviews.length > 0 &&
          interviews.map(interview => (
            <TutorInterviewCard
              key={interview.id}
              interview={interview}
              focused={focusedInterviewId === interview.id}
              readonly={status !== UPCOMING_INTERVIEW}
              saveMessageUrl={urls.save_message}
              enableMessageButtonUrl={urls.enable_message_button}
              onEdit={() => setInterviewIdToEdit(interview.id)}
              onComplete={() => onInterviewComplete(interview.id)}
              onCancel={() => onInterviewCancel(interview.id)}
            />
          ))}
      </div>

      {currentInterview && (
        <EditDetailsModal
          show={true}
          initialValues={currentInterview}
          onSubmit={values => onInterviewEdit(currentInterview.id, values)}
          onClose={() => setInterviewIdToEdit(null)}
        />
      )}
    </>
  )
}

export default Interviews
