import React, { useMemo } from "react"
import Content from "./content"
import Statistic from "./statistic"
import Notes from "./notes"
import InitialLoadingSpinner from "../../../blocks/InitialLoadingSpinner"
import useViewport from "../../../../hooks/useViewport"
import { className } from "../../../../helpers/className"

export const OpeningInformationPage = () => {
  const { isMobileViewport } = useViewport()
  const sidebarClassName = useMemo(
    () => className("position-sticky d-flex flex-column", isMobileViewport ? "gap-1" : "gap-2"),
    [isMobileViewport]
  )
  return (
    <InitialLoadingSpinner>
      <div className="entity-outlet">
        <div className="entity-outlet_side-wrapper">
          <div className="entity-outlet_side -info">
            <div className={sidebarClassName} style={{ top: 20 }}>
              <div className="card p-4">
                <Statistic />
              </div>
              <div className="card p-4">
                <Notes />
              </div>
            </div>
          </div>
          <div className="entity-outlet_side -content">
            <Content />
          </div>
        </div>
      </div>
    </InitialLoadingSpinner>
  )
}
