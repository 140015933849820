import React, { useState } from "react"
import { Form } from "react-bootstrap"
import FormRow from "../blocks/FormRow"

const colProps = { sm: 17, md: 14, lg: 19, xl: 8 }
const labelProps = { sm: 7, md: 5, lg: 4 }

const FieldNames = Object.freeze({
  Format: "meeting_format",
  Info: "meeting_info",
})

const SessionTypeFields = ({ formName, ps_link, initialState = {}, errors = {} }) => {
  const formatTypes = [
    { value: "online", label: "Online" },
    { value: "offline", label: "In Person" },
  ]

  const [state, setState] = useState({
    [FieldNames.Format]: void 0,
    [FieldNames.Info]: void 0,
    ...initialState,
  })

  if (ps_link || initialState.is_new) formatTypes.push({ value: "pencil_spaces", label: "Pencil Spaces" })

  const updateState =
    name =>
    ({ target: { value } }) => {
      const nextState = { ...state, [name]: value }
      setState(nextState)
    }

  return (
    <>
      <FormRow
        label="Location"
        required
        ColProps={{ ...colProps, className: "d-flex" }}
        LabelProps={labelProps}
        RowProps={{ className: "mb-3 align-items-baseline" }}
      >
        {formatTypes.map(({ value, label }) => (
          <Form.Check
            name={`${formName}[${FieldNames.Format}]`}
            type="radio"
            className="mr-3"
            id={`${FieldNames.Format}-${value}`}
            onChange={updateState(FieldNames.Format)}
            checked={state[FieldNames.Format] === value}
            key={value}
            value={value}
            label={label}
            custom
          />
        ))}
      </FormRow>
      {state[FieldNames.Format] !== "pencil_spaces" && (
        <FormRow
          label={state[FieldNames.Format] === "offline" ? "Address" : "Meeting link"}
          ColProps={colProps}
          LabelProps={labelProps}
          RowProps={{ className: "mb-3" }}
        >
          <Form.Control
            name={`${formName}[${FieldNames.Info}]`}
            onChange={updateState(FieldNames.Info)}
            value={state[FieldNames.Info]}
            isInvalid={Boolean(errors[FieldNames.Info])}
          />
          <Form.Control.Feedback type="invalid">{errors[FieldNames.Info]}</Form.Control.Feedback>
        </FormRow>
      )}
      {state[FieldNames.Format] === "pencil_spaces" && (
        <FormRow label={"Meeting link"} ColProps={colProps} LabelProps={labelProps} RowProps={{ className: "mb-3" }}>
          {ps_link ? (
            <a href={ps_link}>{ps_link}</a>
          ) : (
            <Form.Control value={"Will be provided by Pencil Spaces"} readOnly />
          )}
        </FormRow>
      )}
    </>
  )
}

export default SessionTypeFields
