import React, { useContext } from "react"
import { TutorCardApplicationContext } from "../../../../contexts"
import { ReactComponent as UsersIcon } from "../../../../../../assets/images/users.svg"

export const MatchedGroups = () => {
  const application = useContext(TutorCardApplicationContext)

  return (
    <>
      {application.connections?.map(connection => (
        <div key={connection.id} className="opening-tutor-card_matched-group">
          <UsersIcon />
          Matched with {connection.schedule.educatable.name}
        </div>
      ))}
    </>
  )
}
