import React from "react"
import { ReactComponent as AddPersonIcon } from "../../../../assets/images/person-add.svg"

export const PickTutorButton = ({ connections, onClick }) => {
  if (connections.length === 0)
    return (
      <button
        className="btn btn-link font-weight-medium font-size-small text-capitalize d-flex align-items-center gap-1 border-0"
        onClick={onClick}
      >
        <div className="opening-students__icon-box d-flex align-items-center justify-content-center">
          <AddPersonIcon />
        </div>
        Pick tutor
      </button>
    )
  else {
    return (
      <div className="d-flex flex-column gap-1">
        {connections.map(({ tutor }, idx) => (
          <a
            key={`${tutor.id}_${idx}`}
            className="d-flex align-items-center gap-1"
            href={`/admin/tutors/${tutor.id}/profile`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="opening-students__icon-box">
              <img src={tutor.avatar_url} width="40" />
            </span>
            <span>
              {tutor.first_name} {tutor.last_name}
            </span>
          </a>
        ))}
      </div>
    )
  }
}
