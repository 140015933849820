import React, { useMemo } from "react"
import moment from "moment"
import { useParams } from "react-router-dom"

export const WeekRange = () => {
  const { report_date } = useParams()

  const result = useMemo(() => {
    if (!report_date) return ""
    const date = moment(report_date)
    return [date.clone().startOf("week"), date.clone().endOf("week")]
      .map(date => date.format(moment().isSame(date, "year") ? "MMM&nb\\sp;D" : "YYYY&nb\\sp;MMM&nb\\sp;D"))
      .join("&nbsp;-&nbsp;")
  }, [report_date])
  return <span dangerouslySetInnerHTML={{ __html: result }} />
}
