/**
 * Checks if the provided ID matches the root ID or any of the lesson IDs.
 *
 * @param {Object} obj - The main object containing the root ID and lessons.
 * @param {string} idToCheck - The ID to verify.
 * @returns {boolean} - Returns true if the ID matches the root or any lesson ID, else false.
 */
export function hasId(obj, idToCheck) {
  if (!obj || !idToCheck) {
    return false
  }

  // Check if the ID matches the root ID
  if (obj.id === idToCheck) {
    return true
  }

  // Check if the ID exists in any of the lessons
  if (Array.isArray(obj.lessons)) {
    return obj.lessons.some(lesson => lesson.id === idToCheck)
  }

  return false
}
