import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { initialStatus, tutorStatuses } from "../constants"
import { ReactComponent as EmptyList } from "../../../assets/images/empty_list_icon.svg"
import { OpeningCard } from "./OpeningCard"
import ApplyForOpportunityModal from "./ApplyForOpportunityModal"
import { DataContext, QueryContext } from "../../../contexts"
import { getAvailableOpenings } from "../providers"
import { Nav } from "react-bootstrap"

const Tabs = {
  New: "new",
  Applied: "applied",
}

export const OpportunitiesList = () => {
  const [filter, setFilter] = useState(initialStatus)
  const [selectedOpening, setSelectedOpening] = useState(null)
  const { getQueryState } = useContext(QueryContext)
  const { request, loading } = getQueryState(getAvailableOpenings)
  const { availableOpenings } = useContext(DataContext)
  const [selectedTab, setSelectedTab] = useState(Tabs.New)

  const updateFilter = event => {
    setFilter(event.target.value)
  }

  useEffect(() => {
    if (request) {
      request({
        params: {
          filter: selectedTab === Tabs.New ? filter : "all",
          status: selectedTab,
        },
      })
    }
  }, [request, filter, selectedTab])

  return (
    <div className="panel panel_body pt-4 min-h-400-px d-block">
      <Nav variant="tabs" className="brain-trust-tabs tutor-dashboard__opportunities-tabs">
        <Nav.Item className="brain-trust-tab">
          <Nav.Link
            as="button"
            className="brain-trust-tab-content"
            active={selectedTab === Tabs.New}
            onClick={() => setSelectedTab(Tabs.New)}
          >
            New
          </Nav.Link>
        </Nav.Item>

        <Nav.Item className="brain-trust-tab">
          <Nav.Link
            as="button"
            className="brain-trust-tab-content"
            active={selectedTab === Tabs.Applied}
            onClick={() => setSelectedTab(Tabs.Applied)}
          >
            Applied
          </Nav.Link>
        </Nav.Item>
      </Nav>

      {selectedTab === Tabs.New ? (
        <Row className="mb-4 align-items-center justify-content-between">
          <Col className="px-0">
            <h4>Available opportunities for you</h4>
          </Col>
          <Col xs={24} md={12} lg={8} className="px-0">
            <select className="form-control select required" value={filter} onChange={updateFilter}>
              {tutorStatuses.map(([title, value], idx) => (
                <option key={idx} value={value}>
                  {title}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      ) : null}

      {loading ? (
        <div className="spinner-wrapper">
          <div className="spinner-border text-primary" />
        </div>
      ) : !availableOpenings || availableOpenings.length === 0 ? (
        <div className="dashboard_empty_list mt-4">
          <EmptyList />
          <div className="dashboard_empty_list-text mt-4">
            <span className="dashboard_empty_list-text -bold">No active opportunities so far</span>
            <span>Check a bit later. We will find something interesting for you.</span>
          </div>
        </div>
      ) : (
        availableOpenings.map((opening, idx) => (
          <OpeningCard
            key={idx}
            opening={opening}
            cardClassName={idx === availableOpenings.length - 1 ? "" : "mb-4"}
            sliceAdditionalInformation
            onApplyClick={setSelectedOpening}
          />
        ))
      )}
      <ApplyForOpportunityModal opening={selectedOpening} onClose={() => setSelectedOpening(null)} />
    </div>
  )
}
