import React, { useMemo } from "react"
import { EducatableTypes } from "../../../schedule_module/constants"

export const EducatableSpecialNeeds = ({ educatable }) => {
  const list = useMemo(() => {
    if (educatable.type === EducatableTypes.Group)
      return educatable.students.map(({ special_treatments }) => special_treatments)
    else if (educatable.type === EducatableTypes.Student) return [educatable.special_treatments]
    else return []
  }, [educatable])
  if (list.length === 0) return null
  return (
    <div className="d-flex flex-column gap-025">
      {list.map((needs, idx) => (
        <span key={needs + idx}>{Array.isArray(needs) ? needs?.join(", ") : needs}</span>
      ))}
    </div>
  )
}
