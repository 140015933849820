/* eslint-disable no-console */
import { showErrors } from "@/common/alerter"
import { Methods } from "@/constants"
import {
  ApiContext,
  DataContext,
  DomainTransactionContext,
  QueryContext,
  RequestsParamsCacheContext,
  TransportContext,
} from "@/contexts"
import { useKeyBuilder } from "@/hooks/useKeyBuilder"
import { useCallback, useContext, useEffect, useReducer, useRef } from "react"

export const urlBuilder = ({ role, apiBase, entities, entitiesIds = {}, action = "", permissions = {} }) => {
  const { deny = [], allow = [] } = permissions
  if ((deny.length > 0 && deny.includes(role)) || (allow.length > 0 && !allow.includes(role))) {
    throw new Error(`Role ${role} is not permitted`)
  }
  if (entities.length === 0) throw new Error("Request url is not set")

  const entityRouteString = entities.reduce(
    (acc, name) => `${acc}${name ? `/${name}` : ""}${entitiesIds[name] ? `/${entitiesIds[name]}` : ""}`,
    ""
  )
  return `${apiBase ? `/${apiBase}` : ""}${entityRouteString}${action ? `/${action}` : ""}`
}

const dispatchRequest = ({
  method = Methods.Get,
  name,
  url,
  urlBuilderConfig,
  getNext,
  onSuccess,
  onFailure,
  onComplete,
  debug,
  debugAction,
  proxify,
  dispatch,
  transportRequest,
  apiBase,
  role,
  getCacheRef,
  setCacheRef,
  buildTransaction,
}) => {
  if (!transportRequest) throw new Error("Request transport method is missed")

  const defaultEntitiesIds = urlBuilderConfig
    ? Object.fromEntries(urlBuilderConfig.entities.map(key => [key, null]))
    : {}

  const request = async ({
    method: reqMethod,
    url: reqUrl,
    debug: reqDebug,
    data,
    params,
    next,
    transaction: requestTransaction,
    entitiesIds = defaultEntitiesIds,
    onSuccess: onRequestSuccess,
    onFailure: onRequestFailure,
    onComplete: onRequestComplete,
  } = {}) => {
    dispatch({ name, state: { loading: true, complete: false } })
    const transaction = requestTransaction || buildTransaction()
    transaction.start()

    try {
      let requestUrl =
        reqUrl ||
        (urlBuilderConfig
          ? urlBuilder({
              ...urlBuilderConfig,
              entitiesIds,
              apiBase,
              role,
            })
          : url)

      if (!requestUrl) throw new Error("Request url is not set")

      const reqConfig = {
        url: requestUrl,
        method: reqMethod || method,
        ...(data && { data }),
        ...(params && { params }),
      }

      const response = await (debug || reqDebug
        ? new Promise(resolve =>
            setTimeout(() => {
              let result = { data: debug || reqDebug }
              if (debugAction) result = debugAction({ reqConfig, result })
              resolve(result)
            }, 500)
          )
        : transportRequest(reqConfig))

      if (name) setCacheRef.current(name, { ...reqConfig, entitiesIds })

      const requestNext = getNext(next)
      if (requestNext.length > 0) await Promise.all(requestNext.map(fn => fn({ transaction })))

      await transaction.add(() => {
        if (onRequestSuccess) onRequestSuccess(response?.data || null)
        if (onSuccess) onSuccess(response?.data || null)
      })
      dispatch({ name, state: { failed: false } })
    } catch (e) {
      dispatch({ name, state: { failed: true } })
      showErrors(e)
      transaction.rollback()
      if (onRequestFailure) onRequestFailure(e)
      if (onFailure) onFailure(e)
      transaction.destroy()
    } finally {
      if (onRequestComplete) onRequestComplete()
      if (onComplete) onComplete()
      dispatch({ name, state: { loading: false, complete: true } })
    }
  }

  const retry = async ({ transaction }) => {
    if (!name) {
      console.warn(`Hook without name can't be retried. Previous request params was not saved.`)
      return
    }
    const params = getCacheRef.current(name)
    if (!params) throw new Error(`Hook ${name} never executed yet. Initial request params is empty.`)
    return await (proxify ? proxify(request) : request)({ ...params, transaction })
  }

  dispatch({
    name,
    state: {
      complete: false,
      loading: false,
      failed: false,
      request: proxify ? proxify(request) : request,
      retry,
    },
  })
}

const hookBuilder = ({
  hook,
  handlers,
  keyBuilder,
  dispatch,
  config,
  getHookStateRef,
  getCacheRef,
  setCacheRef,
  transportRequest,
  apiBase,
  role,
  buildTransaction,
}) => {
  const {
    entities,
    action,
    method,
    onComplete,
    onSuccess,
    onFailure,
    next: nextConfigs = [],
    permissions = {},
    debug,
    debugAction,
  } = config
  const key = keyBuilder(config)

  if (getHookStateRef.current(config) && Object.keys(getHookStateRef.current(config)).length > 0) {
    console.warn(`${key} already exists`)
  }

  const handlersWrapper = Object.entries({ ...handlers, onComplete, onSuccess, onFailure }).reduce(
    (acc, [name]) => ({
      ...acc,
      [name]: data => {
        if (config[name]) config[name](config, data)
        if (handlers[name]) handlers[name](config, data)
      },
    }),
    {}
  )

  const resultPermissions = { allow: [], deny: [], ...permissions }
  const urlBuilderConfig = { entities, action, permissions: resultPermissions }
  const getNext = (configs = []) =>
    [...nextConfigs, ...configs].map(childConfig => async ({ transaction }) => {
      const hookState = getHookStateRef.current(childConfig)
      if (!hookState || Object.keys(hookState).length === 0) {
        const childKey = keyBuilder(childConfig)
        throw new Error(`Hook ${childKey} is not initialized yet.`)
      }
      const { retry } = hookState
      return await retry({ transaction })
    })

  hook({
    name: key,
    method,
    handlers,
    urlBuilderConfig,
    getNext,
    dispatch,
    transportRequest,
    apiBase,
    role,
    getCacheRef,
    setCacheRef,
    debug,
    debugAction,
    buildTransaction,
    ...handlersWrapper,
  })
}

const queryListReducer = (commonState, { name, state }) => ({
  ...commonState,
  [name]: {
    ...commonState[name],
    ...state,
  },
})

export const useQueryList = ({ configs, handlers, connectToParent }) => {
  const { getQueryState: parentGetHookState } = useContext(QueryContext)
  const { buildTransaction } = useContext(DomainTransactionContext)
  const transportRequest = useContext(TransportContext)
  const { current: apiBase } = useContext(ApiContext)
  const { setCache, getCache } = useContext(RequestsParamsCacheContext)
  const { role } = useContext(DataContext)
  const keyBuilder = useKeyBuilder()
  const [state, dispatch] = useReducer(queryListReducer, {})

  const getCacheRef = useRef(getCache)
  const setCacheRef = useRef(setCache)
  const getHookStateRef = useRef()

  const getHookState = useCallback(
    config => {
      const key = keyBuilder(config)
      return connectToParent ? state[key] || parentGetHookState(config) : state[key] || {}
    },
    [connectToParent, keyBuilder, state, parentGetHookState]
  )

  useEffect(() => {
    getHookStateRef.current = getHookState
    getCacheRef.current = getCache
    setCacheRef.current = setCache

    if (configs.length > 0 && Object.keys(state).length === 0) {
      configs.forEach(config => {
        hookBuilder({
          hook: dispatchRequest,
          getHookStateRef,
          getCacheRef,
          setCacheRef,
          handlers,
          keyBuilder,
          dispatch,
          config,
          transportRequest,
          apiBase,
          role,
          buildTransaction,
        })
      })
    }
  }, [
    apiBase,
    configs,
    getCache,
    getHookState,
    handlers,
    keyBuilder,
    state,
    role,
    setCache,
    transportRequest,
    buildTransaction,
  ])

  return [state, getHookState]
}

export default useQueryList
