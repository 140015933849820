import React from "react"
import { Form } from "react-bootstrap"
import { Row, Col } from "react-bootstrap"
import { BREAK_REASONS } from "../../common/constants"

const Reasons = ({ reasonIndex, setReasonIndex, otherReason, setOtherReason }) => {
  return (
    <>
      {BREAK_REASONS.map((el, index) => (
        <Row key={index}>
          <Col>
            <Form.Check type="radio" id={`reason-${index}`}>
              <Form.Check.Input
                type="radio"
                checked={reasonIndex === index}
                onChange={() => {
                  setReasonIndex(index)
                }}
              />
              <Form.Check.Label className="collection_radio_buttons">{el}</Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>
      ))}
      <Row>
        <Col>
          <Form.Check type="radio" id="reason-other">
            <Form.Check.Input
              type="radio"
              checked={reasonIndex === -1}
              onChange={() => {
                setReasonIndex(-1)
              }}
            />
            <Form.Check.Label className="collection_radio_buttons">Other</Form.Check.Label>
          </Form.Check>
          <div className="mb-4 ml-5">
            <Form.Control
              as="textarea"
              rows="3"
              className={reasonIndex !== -1 ? "invisible" : "visible"}
              disabled={reasonIndex !== -1}
              value={otherReason}
              onChange={ev => {
                setOtherReason(ev.target.value)
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Reasons
