import React from "react"
import { Badge } from "react-bootstrap"
import { isEmpty } from "lodash/fp"
import pluralize from "pluralize"

import Avatar from "../../blocks/Avatar"
import Rating from "../../blocks/Rating"
import { getPrivateFullName, getInitials, getFullName } from "../../../helpers/presentational"
import { formatMoney } from "../../../helpers/formatters"
import BackgroundCheckLabel from "../../find_tutor/tutor_card/BackgroundCheckLabel"
import DegreesInfo from "../../find_tutor/tutor_card/DegreesInfo"
import LearningDifferenceCertificates from "../../find_tutor/tutor_card/LearningDifferenceCertificates"
import AdditionalRating from "./AdditionalRating"

const TutorProfileCardContent = ({ tutor, showFullTutorName = false }) => {
  const name = showFullTutorName ? getFullName(tutor) : getPrivateFullName(tutor)
  const initials = getInitials(tutor)
  const { profile, hourly_rate, rating, labels } = tutor

  return (
    <div className="tutor-profile-card_content">
      <div className="tutor-profile-card_avatar">
        <Avatar initials={initials} photoUrl={profile.photo_url} className="-sm d-none d-md-block d-lg-none" />
        <Avatar initials={initials} photoUrl={profile.photo_url} className="-lg d-md-none d-lg-block" />
      </div>
      <div className="tutor-profile-card_main-info">
        <div className="tutor-profile-card_title">
          <h5 className="tutor-profile-card_name">{name}</h5>
          <BackgroundCheckLabel />
          {!tutor.viewer_has_partnership && (
            <div className="tutor-profile-card_hour-rate d-lg-none">{formatMoney(hourly_rate)} / hour</div>
          )}
        </div>

        <div className="tutor-profile-card_degrees">
          <DegreesInfo degrees={profile.degrees} />
        </div>

        <div className="tutor-profile-card_bio">{profile.bio}</div>
        {tutor.stripe_id && (
          <div className="tutor-profile-card_certificates d-lg-block">
            {tutor.stripe_id !== "-" ? `Stripe: ${tutor.stripe_id}` : "No Stripe account"}
          </div>
        )}
        {!isEmpty(labels) && (
          <div className="tutor-profile-card_badges">
            {labels.map((label, i) => (
              <Badge key={i} variant="success" className="tutor-profile-card_badge default-badge">
                {label[0]}
              </Badge>
            ))}
          </div>
        )}

        <div className="tutor-profile-card_certificates d-none d-lg-block">
          <LearningDifferenceCertificates {...tutor.profile} />
        </div>

        <div className="tutor-profile-card_details d-lg-none">
          <div className="tutor-profile-card_rate-info">
            <div className="tutor-profile-card_rating">
              {!!rating && <span className="tutor-profile-card_rating-text">Rating</span>}
              <Rating value={rating} showAllStars={false} readonly />
              {tutor.reviews_on_me_count > 0 && (
                <a href="#reviews" className="tutor-profile-card_reviews d-md-none" data-turbolinks="false">
                  {pluralize("review", tutor.reviews_on_me_count, true)}
                </a>
              )}
            </div>
            <div className="tutor-profile-card_additional-rating">
              <AdditionalRating reviewsCount={tutor.reviews_on_me_count} {...tutor.rating_stat} />
            </div>
            {tutor.reviews_on_me_count > 0 && (
              <a href="#reviews" data-turbolinks="false" className="tutor-profile-card_reviews d-none d-md-block">
                {pluralize("review", tutor.reviews_on_me_count, true)}
              </a>
            )}
          </div>
          <div className="tutor-profile-card_qualifications">
            <div className="tutor-profile-card_qualifications-text">Qualifications</div>
            <div className="tutor-profile-card_certificates">
              <LearningDifferenceCertificates {...tutor.profile} />
            </div>
          </div>
        </div>
      </div>

      <div className="tutor-profile-card_rate-info d-none d-lg-flex">
        {!tutor.viewer_has_partnership && (
          <div className="tutor-profile-card_hour-rate">{formatMoney(hourly_rate)} / hour</div>
        )}
        <div className="tutor-profile-card_rate-card">
          <div className="tutor-profile-card_rating">
            {!!rating && <div className="tutor-profile-card_rating-text">Rating</div>}
            <Rating value={rating} readonly />
          </div>
          <div className="tutor-profile-card_additional-rating">
            <AdditionalRating reviewsCount={tutor.reviews_on_me_count} {...tutor.rating_stat} />
          </div>
          {tutor.reviews_on_me_count > 0 && (
            <a href="#reviews" className="tutor-profile-card_reviews" data-turbolinks="false">
              {pluralize("review", tutor.reviews_on_me_count, true)}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default TutorProfileCardContent
