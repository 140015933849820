import React, { useContext, useState } from "react"
import { Row, Modal } from "react-bootstrap"
import OpeningTabs from "./OpeningTabs"
import Step1Students from "./Step1Students"
import { OpeningContext } from "./context"
import { steps, stepValid } from "./logic"
import Step2Configuration from "./Step2Configuration"
import Step3Schedule from "./Step3Schedule"
import Step4Overview from "./Step4Overview"
import { useStatusOpening, useOpeningGroups } from "./hooks"

const StatusOpeningButton = ({ openingId, status, onSuccess }) => {
  const { loading, request } = useStatusOpening({
    id: openingId,
    status,
    onSuccess,
  })
  return (
    <button className="btn btn-outline-primary mr-2" onClick={request} disabled={loading}>
      {loading ? (
        <div className="spinner-wrapper">
          <div className="spinner-border text-primary" />
        </div>
      ) : (
        <span>{status}</span>
      )}
    </button>
  )
}

const Opening = ({ schools, onSubmit, submitting }) => {
  const { opening, canEdit, canReopen } = useContext(OpeningContext)
  const [show, setShow] = useState(false)
  const [reopen, setReopen] = useState(false)
  const { data: groups, loading } = useOpeningGroups(opening?.school?.id || null)

  const [activeStep, setActiveStep] = useState(steps[0].key)

  const isSubmitDisabled = () => {
    return !stepValid(activeStep, opening)
  }

  const close = () => {
    setShow(false)
    setReopen(false)
    window.location.reload()
  }

  if (loading) {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  return (
    <>
      <OpeningTabs activeStep={activeStep} setActiveStep={setActiveStep} opening={opening} />
      {activeStep === "students" && <Step1Students groups={groups} schools={schools} />}
      {activeStep === "configuration" && <Step2Configuration />}
      {activeStep === "schedule" && <Step3Schedule />}
      {activeStep === "overview" && <Step4Overview />}
      <Row className="mx-4 justify-content-between">
        <div>
          {submitting ? (
            <button type="button" className="btn btn-outline-primary" disabled>
              <div className="spinner-wrapper">
                <div className="spinner-border text-primary" />
              </div>
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-primary"
              disabled={!canEdit || isSubmitDisabled()}
              onClick={onSubmit}
            >
              Save
            </button>
          )}
        </div>
        {canEdit && (
          <div>
            <button className="btn btn-outline-primary -error" onClick={() => setShow(true)}>
              Close opening
            </button>
          </div>
        )}
        {canReopen && (
          <div>
            <button className="btn btn-outline-primary -error" onClick={() => setReopen(true)}>
              Reopen
            </button>
          </div>
        )}
      </Row>
      <Modal show={show} onHide={close}>
        <Modal.Body>
          <p>
            Are you sure you want to close this opening? It will no longer be available for tutors in the app, Closing
            opening does not affect sessions scheduled
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Row>
            <StatusOpeningButton status="close" onSuccess={close} openingId={opening.id} />
            <button className="btn btn-outline-primary" onClick={close}>
              Back
            </button>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal show={reopen} onHide={close}>
        <Modal.Body>
          <p>This will make the opening visible to tutors, and you will be able to edit it</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Row>
            <StatusOpeningButton status="reopen" onSuccess={close} openingId={opening.id} />
            <button className="btn btn-outline-primary" onClick={close}>
              Back
            </button>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Opening
