import React, { useEffect, useState } from "react"
import { Card, Col, Image, Row, Table } from "react-bootstrap"

import Chats from "./Chats"
import Messages from "./Messages"
import { getQueryStringFromParams } from "../find_tutor/serializeParams"
import IndeterminateProgressBar from "../blocks/IndeterminateProgressBar"
import apiClient from "../../common/apiClient"
import NoMessageImage from "../../assets/images/chat_bubble.svg"
import useViewport from "../../hooks/useViewport"

const Chat = ({ dataSources }) => {
  const [selectedChat, setSelectedChat] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [chats, setChats] = useState([])
  const { isPhoneViewport } = useViewport()

  const onSelectChat = value => {
    setSelectedChat(value)
  }

  const onFilterChange = (filter_name, filter_role, filter_sort) => {
    setSelectedChat(false)
    findThreads(dataSources.urls.find_chats, false, { name: filter_name, role: filter_role, sort: filter_sort })
  }

  useEffect(() => {
    findThreads(dataSources.urls.find_chats, dataSources.selected_chat, {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const findThreads = async (url, selected_chat, params) => {
    setIsLoading(true)
    const queryString = getQueryStringFromParams(params)
    try {
      const {
        data: { chats },
      } = await apiClient.get(url + queryString)
      setChats(chats)
      if (selected_chat) {
        setSelectedChat(selected_chat)
      } else if (chats.length > 0 && !isPhoneViewport) {
        setSelectedChat(chats[0].id)
      }
    } catch (_) {
      setChats([])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="panel-wrapper">
      <Card className="bg-card-transparent-mobile bg-lg-card-white vertical-stretch mb-3 mb-md-5">
        <Row className="messages_dashboard">
          <Col lg={{ span: 8 }} className={"chats bg-lg-light" + (!selectedChat ? "" : " hidden-mobile")}>
            {isLoading && <IndeterminateProgressBar className="chats-progress-bar" />}
            {!isLoading && <div className="chat-progress-bar-spacer" />}
            <Chats
              chats={chats}
              showChatFilter={dataSources.from.role === "admin"}
              onSelect={onSelectChat}
              onFilter={onFilterChange}
              isParentLoading={isLoading}
              selectedChatId={selectedChat}
            />
          </Col>
          <Col
            lg={{ span: 16 }}
            className={"chat_thread card shadow-none messages-mobile" + (!selectedChat ? " hidden-mobile" : "")}
          >
            {typeof chats !== "undefined" && chats.length > 0 && selectedChat !== false && (
              <Messages
                chat={chats.find(chat => chat.id === selectedChat)}
                showBothParties={dataSources.from.role === "admin"}
                dataSources={dataSources}
                onClose={() => setSelectedChat(false)}
                chatCount={chats.length}
                isParentLoading={isLoading}
              />
            )}
            {chats.length === 0 && !isLoading && (
              <Table className="no-chat-messages">
                <tbody>
                  <tr>
                    <td>
                      <Image src={NoMessageImage} />
                      <br />
                      <h1>You have no messages</h1>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default Chat
