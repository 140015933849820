import React, { useMemo, useState } from "react"
import { Form } from "react-final-form"
import arrayMutators from "final-form-arrays"
import { Modal } from "react-bootstrap"

import apiClient, { showBaseErrorsInAlert } from "../../../common/apiClient"
import FormFields from "./FormFields"
import { getSubmitErrors } from "../../../helpers/forms"
import ProfileValidationProvider from "../providers/ProfileValidation"

const pendingProfileStatus = ["pending", "changes_pending", "on_review"]

const SessionPreferences = ({ dataSources }) => {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => setShowModal(true)
  const hideModal = () => setShowModal(false)

  const onSubmit = async profile => {
    try {
      await apiClient.put(dataSources.urls.save, { profile })
      window.location.replace(dataSources.urls.next)
    } catch (e) {
      showBaseErrorsInAlert(e)
      return { profile: getSubmitErrors(e) }
    }
  }

  const isPending = useMemo(
    () => pendingProfileStatus.some(status => status === dataSources.user?.profile_status),
    [dataSources.user?.profile_status]
  )

  const validation = values => {
    const result = {}
    if (dataSources.user?.profile_status === "pending" || dataSources.user?.profile_status === "changes_pending") {
      if (!values.tutor_private && !values.tutor_school) result.sessionTypeMissed = "Required"
    }
    return result
  }

  return (
    <Form onSubmit={onSubmit} mutators={{ ...arrayMutators }} validate={validation}>
      {({ handleSubmit, submitting, values, errors, form }) => (
        <form onSubmit={handleSubmit} className="container">
          <div className="row mb-2">
            <div className="col col-lg-17">
              <p className="profile-preference-title">
                Braintrust works in partnership with both families and schools to provide students with tutoring
                support. Please let us know which types of tutoring you’re interested in so we can send the right type
                of opportunities your way!
              </p>
            </div>
          </div>
          <ProfileValidationProvider errors={errors}>
            <FormFields dataSources={dataSources} values={values} />
          </ProfileValidationProvider>
          {!values.tutor_school && isPending ? (
            <button
              type="button"
              className="btn btn-primary"
              disabled={submitting || Object.keys(errors).length > 0}
              onClick={openModal}
            >
              Next
            </button>
          ) : (
            <button type="submit" className="btn btn-primary" disabled={submitting || Object.keys(errors).length > 0}>
              Next
            </button>
          )}
          <Modal animation={false} show={showModal} onHide={hideModal}>
            <Modal.Body>
              <p className="font-size-big font-weight-medium my-2">
                Are you sure you’re not interested in school partnerships?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-light" onClick={() => form.submit()}>
                Continue
              </button>
              <button className="btn btn-primary" onClick={hideModal}>
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </form>
      )}
    </Form>
  )
}
export default SessionPreferences
