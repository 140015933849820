import { apiClient } from "@/common/apiClient"
import { useEffect, useState } from "react"

const useFetchSchools = () => {
  const [schools, setSchools] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const loadSchools = async () => {
      try {
        const response = await apiClient.get("/api/admin/schools")
        setSchools(response.data)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    loadSchools()
  }, [])

  return { schools, loading, error }
}

export default useFetchSchools
