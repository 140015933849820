import React from "react"
import { Button, Modal } from "react-bootstrap"
import ConsultantImage from "../../../assets/images/consultant.jpg"

const CalendlyModal = ({ show, onOk, onClose }) => {
  return (
    <Modal size="lg" show={show} onHide={onClose} centered>
      <Modal.Header className="borderless px-4 pt-4 pb-2" closeButton />
      <Modal.Body className="px-5 pb-5">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex flex-column-reverse flex-lg-row align-items-center ">
            <div>
              <h5 className="rule-height text-left pt-4 pt-lg-0 pb-4 mr-lg-5 text-center text-lg-left">
                Would you like to schedule a complimentary consultation with an educational specialist to find the right
                match for your child?
              </h5>
              <div className="d-flex flex-column flex-lg-row align-items-stretch align-items-lg-center">
                <Button variant="outline-primary" className="mt-2 mr-lg-3" onClick={onOk}>
                  Schedule a consultation
                </Button>
                <Button variant="outline-danger" className="mt-2" onClick={onClose}>
                  I’m ok. Show my matches
                </Button>
              </div>
            </div>

            <img src={ConsultantImage} width="160px" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CalendlyModal
