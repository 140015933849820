import React, { useRef, useState, useEffect, useMemo } from "react"
import moment from "moment"
import { Form } from "react-bootstrap"
import { checkDateIsValid, formatDateTimeKeepZone } from "../helpers/dates"

const hourValidator = value => {
  let result = value
  if (result < 1) result = 1
  if (result > 12) result = 12
  return result
}

const minuteValidator = value => {
  let result = value
  if (result < 0) result = 0
  if (result > 60) result = 60
  return result
}

const TimePicker = ({
  name,
  value: initialValue,
  date: initialDate = moment().format("YYYY-MM-DD"),
  onChange,
  onBlur,
  onFocus,
  className = "",
  placeholder = "",
  invalid,
  children,
}) => {
  const [showModalValue, setShowModalValue] = useState(false)
  const isValid = useMemo(() => checkDateIsValid(new Date(initialValue)), [initialValue])
  const [editableValue, setEditableValue] = useState(() => {
    if (isValid) return moment(new Date(formatDateTimeKeepZone(initialValue))).toDate()
    return placeholder
  })
  const [value, setValue] = useState(() => {
    const initial = moment(formatDateTimeKeepZone(initialDate))
    const currentDay = moment().year(initial.year()).month(initial.month()).date(initial.date())
    return moment(isValid ? editableValue : currentDay)
      .seconds(0)
      .milliseconds(0)
      .toDate()
  })

  const [tempValue, setTempValue] = useState(null)
  const [editType, setEditType] = useState(null)
  const hoursInputRef = useRef(null)
  const modalRef = useRef(null)
  const readonlyInputRef = useRef(null)

  const updateMeridiem = () => {
    const date = moment(value)
    const dayOfMonth = date.get("date")
    date.add(date.format("a") === "am" ? 12 : -12, "h")
    date.set("date", dayOfMonth).seconds(0).milliseconds(0)
    setValue(date.toDate())
  }

  const updateByClick = (type, action) => () => {
    const date = moment(formatDateTimeKeepZone(value))
    const dayOfMonth = date.get("date")
    let count = 1
    if (type === "m") {
      const remain = date.minutes() % 15
      if (action === "add") count = 15 - remain
      if (action === "remove") count = remain || 15
    }
    date.add(action === "add" ? count : -count, type)
    date.set("date", dayOfMonth).seconds(0).milliseconds(0)
    setValue(date.toDate())
  }

  const updateByChange =
    validator =>
    ({ target: { value } }) => {
      if (value === "") {
        setTempValue(value)
        return
      }
      const result = parseInt(value, 10)
      if (Number.isNaN(result)) return
      setTempValue(validator(result))
    }

  const updateTempValue = type => () => {
    setEditType(type)
    setTempValue(parseInt(moment(formatDateTimeKeepZone(value)).format(type), 10))
  }

  const resetTempValue = type => () => {
    if (tempValue !== "") {
      const currentDay = moment(formatDateTimeKeepZone(value))
      const date = currentDay.get("date")

      let newValue = tempValue
      if (type === "hours") {
        if (currentDay.format("a") === "pm") {
          if (newValue < 12) newValue += 12
        } else {
          if (newValue === 12) newValue = 0
        }
      }

      setValue(currentDay.set(type, newValue).set("date", date).seconds(0).milliseconds(0).toDate())
    }
    setTempValue(null)
  }

  const showModal = () => {
    if (readonlyInputRef.current === document.activeElement) {
      const result = !showModalValue
      setShowModalValue(result)
      if (result) {
        setTimeout(() => {
          if (onFocus) onFocus({ target: { name, value } })
          if (hoursInputRef?.current) hoursInputRef.current.focus()
        }, 4)
      }
    }
  }

  const hideModal = withChanges => () => {
    if (withChanges) {
      setEditableValue(value)
      onChange({ target: { name, value } })
    } else {
      const initial = moment(formatDateTimeKeepZone(initialDate))
      const currentDay = moment().year(initial.year()).month(initial.month()).date(initial.date())
      setValue(checkDateIsValid(editableValue) ? editableValue : moment(currentDay).seconds(0).milliseconds(0).toDate())
    }
    setShowModalValue(false)
    setTimeout(() => {
      if (onBlur) onBlur({ target: { name, value } })
      if (readonlyInputRef?.current) readonlyInputRef.current.focus()
    }, 4)
  }

  const clickListener = event => {
    if (modalRef && modalRef.current && showModalValue) {
      if (!modalRef.current.contains(event.target)) hideModal(false)()
    }
  }

  const onEnter = event => {
    if (event.charCode !== 13) return
    event.stopPropagation()
    event.preventDefault()
    if (showModalValue) hideModal(true)()
    else showModal()
  }

  useEffect(() => {
    document.addEventListener("click", clickListener)
    return () => document.removeEventListener("click", clickListener)
  })

  return (
    <>
      <Form.Control
        readOnly
        ref={readonlyInputRef}
        isInvalid={invalid}
        className={`v-2 time-picker_result ${className}`}
        placeholder={placeholder}
        value={checkDateIsValid(editableValue) ? moment(editableValue).format("LT") : ""}
        onClick={showModal}
      />
      {children}
      {showModalValue && (
        <div ref={modalRef} className="time_picker_modal card p-2" onKeyPress={onEnter}>
          <div className="time_picker_modal-row-wrapper">
            <div className="d-flex flex-column time_picker_modal-row">
              <button className="time-picker_update -less" type="button" onClick={updateByClick("h", "add")} />
              <input
                type="text"
                className="time-picker_value"
                ref={hoursInputRef}
                value={tempValue !== null && editType === "h" ? tempValue || "" : moment(value).format("h")}
                onFocus={updateTempValue("h")}
                onChange={updateByChange(hourValidator)}
                onBlur={resetTempValue("hours")}
              />
              <button className="time-picker_update" type="button" onClick={updateByClick("h", "remove")} />
            </div>
            <div className="d-flex flex-column time_picker_modal-row">
              <button className="time-picker_update -less" type="button" onClick={updateByClick("m", "add")} />
              <input
                type="text"
                className="time-picker_value"
                value={
                  tempValue !== null && editType === "m"
                    ? tempValue === null
                      ? ""
                      : tempValue
                    : moment(value).format("mm")
                }
                onFocus={updateTempValue("m")}
                onChange={updateByChange(minuteValidator)}
                onBlur={resetTempValue("minutes")}
              />
              <button className="time-picker_update" type="button" onClick={updateByClick("m", "remove")} />
            </div>
            <div className="d-flex flex-column time_picker_modal-row">
              <button className="time-picker_update -less" type="button" onClick={updateMeridiem} />
              <div className="time-picker_value">{moment(value).format("A")}</div>
              <button className="time-picker_update" type="button" onClick={updateMeridiem} />
            </div>
          </div>
          <button className="time_picker_submit" type="button" onClick={hideModal(true)}>
            OK
          </button>
        </div>
      )}
    </>
  )
}

export default TimePicker
