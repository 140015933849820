import React, { useCallback, useContext, useMemo, useState } from "react"
import { TableSelectionContext } from "../../../contexts"
import noop from "../../../helpers/noop"

const InheritSelectionProvider = ({ children, ...props }) => {
  return <TableSelectionContext.Provider value={props}>{children}</TableSelectionContext.Provider>
}

const StatefulSelectionProvider = ({
  initial = [],
  selectable = false,
  resetSelectionOnChange = false,
  onSelect: select = noop,
  checkSelection: check = (list, item) => list.includes(item),
  selectionDataMapper: mapper = data => data,
  children,
}) => {
  const [selected, setSelected] = useState(initial)

  const updateSelected = useCallback(
    list => {
      setSelected(list)
      select(null, list, ...list)
    },
    [select]
  )

  const resetSelection = useCallback(() => {
    updateSelected([])
  }, [updateSelected])

  const checkSelection = useCallback(
    (...checkedList) => {
      return checkedList.map(mapper).every(item => check(selected, item))
    },
    [check, mapper, selected]
  )

  const onSelect = useCallback(
    (event, ...checkedList) => {
      const { checked } = event.target
      const newSelectedList = []
      const mappedList = checkedList.map(mapper)
      if (checked) {
        for (const data of [...selected, ...mappedList]) {
          if (!check(newSelectedList, data)) newSelectedList.push(data)
        }
      } else newSelectedList.push(...selected.filter(data => !check(mappedList, data)))

      setSelected(newSelectedList)
      select(event, newSelectedList, ...checkedList)
    },
    [check, mapper, select, selected]
  )

  const result = useMemo(
    () => ({
      selectable,
      selected,
      onSelect,
      resetSelectionOnChange,
      checkSelection,
      updateSelected,
      resetSelection,
      selectionDataMapper: mapper,
    }),
    [checkSelection, mapper, onSelect, resetSelection, resetSelectionOnChange, selectable, selected, updateSelected]
  )

  return <TableSelectionContext.Provider value={result}>{children}</TableSelectionContext.Provider>
}

export const TableSelectionProvider = ({
  initial,
  selectable,
  resetSelectionOnChange,
  onSelect: initialOnSelect,
  checkSelection: initialCheckSelection,
  selectionDataMapper,
  children,
  inherit,
}) => {
  const props = useContext(TableSelectionContext)

  const exist = useMemo(() => {
    const values = Object.values(props)
    return values.length > 0 && values.every(el => el !== void 0)
  }, [props])

  if ((props.selectable || selectable) && (!exist || !inherit)) {
    return (
      <StatefulSelectionProvider
        initial={initial}
        selectable={selectable}
        checkSelection={initialCheckSelection}
        resetSelectionOnChange={resetSelectionOnChange}
        onSelect={initialOnSelect}
        selectionDataMapper={selectionDataMapper}
      >
        {children}
      </StatefulSelectionProvider>
    )
  }

  return <InheritSelectionProvider {...props}>{children}</InheritSelectionProvider>
}
