import PropTypes from "prop-types"
import React, { useMemo } from "react"
import { LessonStudentView, LessonTutorView } from "./Lesson"
import { LoadingSkeleton } from "./LoadingSkeleton"
import NoResources from "./NoResources"
import { StudentAdministeredStudentView, StudentAdministeredTutorView } from "./StudentAdministered"
import { TutorAdministeredStudentView, TutorAdministeredTutorView } from "./TutorAdministered"

const COMPONENTS = {
  student_administered: {
    admin: StudentAdministeredTutorView,
    tutor: StudentAdministeredTutorView,
    student: StudentAdministeredStudentView,
  },
  tutor_administered: {
    admin: TutorAdministeredTutorView,
    tutor: TutorAdministeredTutorView,
    student: TutorAdministeredStudentView,
  },
  lesson: {
    admin: LessonTutorView,
    tutor: LessonTutorView,
    student: LessonStudentView,
  },
}

const getComponent = (resourceType, userRole) => {
  return COMPONENTS[resourceType]?.[userRole] || NoResources
}

export const ResourcesComponent = React.memo(({ loading, data, userRole }) => {
  const content = useMemo(() => {
    if (loading || !data) {
      return <LoadingSkeleton />
    }

    let resourceType = ""

    if (data.assignment_type === "Lesson" || data.lesson_resources) {
      resourceType = "lesson"
    } else if (data.assignment_type === "ProgramAssessment") {
      resourceType = data.is_tutor_administered ? "tutor_administered" : "student_administered"
    }

    if (!resourceType || !data.assignment_name) {
      return <NoResources data={data} />
    }

    const Component = getComponent(resourceType, userRole)
    return <Component data={data} />
  }, [loading, data, userRole])

  return <div className="max-w-md mx-auto">{content}</div>
})

ResourcesComponent.displayName = "ResourcesComponent"

ResourcesComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    assignment_name: PropTypes.string,
    assignment_url: PropTypes.string,
    assignment_type: PropTypes.string,
    lesson_resources: PropTypes.any,
    is_tutor_administered: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  }),
  userRole: PropTypes.oneOf(["admin", "tutor", "student"]).isRequired,
}

export default ResourcesComponent
