import React from "react"
import { UserDashboardProvider } from "../providers"
import { UserDashboardContent } from "./UserDashboardContent"

export const UserDashboard = () => {
  return (
    <UserDashboardProvider>
      <UserDashboardContent />
    </UserDashboardProvider>
  )
}
