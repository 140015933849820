import React from "react"
import { connect } from "react-redux"
import Subskill from "./Subskill"
import { size, values, filter, kebabCase } from "lodash"
import { MASTERED_EARLIER, MASTERED, WORKING_ON, STUDIED_EARLIER } from "../subskill_statuses"

const Skill = ({ skill, default_count, mastered_count, working_on_count, studied_earlier_count, readOnlyMode }) => (
  <details className="report" data-test={`skill-header-${kebabCase(skill.name)}`}>
    <summary className="report_summary">
      <div className="report_title">{skill.name}</div>
      {mastered_count > 0 && <div className="report_count color -mastered">+{mastered_count}</div>}
      {working_on_count > 0 && <div className="report_count color -working-on">+{working_on_count}</div>}
      {studied_earlier_count > 0 && <div className="report_count color -studied-earlier">+{studied_earlier_count}</div>}
      {!readOnlyMode && (
        <div className="report_mastered-count">
          {default_count + studied_earlier_count + mastered_count}/{skill.subskills.length}
        </div>
      )}
    </summary>

    <div className="report_subskills">
      {skill.subskills.map(subskill => (
        <Subskill key={subskill.id} subskill={subskill} readOnlyMode={readOnlyMode} />
      ))}
    </div>
  </details>
)

export default connect((state, { skill }) => ({
  default_count: size(
    skill.subskills.filter(subskill => subskill.status === MASTERED_EARLIER && !state.selectedSubskills[subskill.id])
  ),
  mastered_count: size(
    filter(values(state.selectedSubskills), {
      status: MASTERED,
      skill_id: skill.id,
    })
  ),
  working_on_count: size(
    filter(values(state.selectedSubskills), {
      status: WORKING_ON,
      skill_id: skill.id,
    })
  ),
  studied_earlier_count: size(
    filter(values(state.selectedSubskills), {
      status: STUDIED_EARLIER,
      skill_id: skill.id,
    })
  ),
  readOnlyMode: state.readOnlyMode,
}))(Skill)
